/*************************************************
 * Tvastar
 * @exports
 * @file GovernanceAction.js
 * @author Prakash // on 06/01/2024
 * @copyright © 2024 Tvastar. All rights reserved.
 *************************************************/
import ApiCalls from '../../components/ApiCalls'

import { ACTION_TYPES } from '../types'
import {
	LIST_GOVERNANCE_POLICIES,

	GET_MASTER_GOVERNANCE_RULES,
	GET_POLICY_DETAILS,
	CREATE_GOVERNANCE_POLICY,
	EDIT_GOVERNANCE_POLICY,
	DELETE_GOVERNANCE_POLICY,
	NEW_GOVERNANCE_GET_TOTAL_COUNT,
	NEW_GOVERNANCE_GET_DAY_WISE_COUNT,
	NEW_GOVERNANCE_GET_TRANSITION_TREND,
	GET_GOVERNANCE_VIOLATIONS,
	NEW_GOVERNANCE_GENERATE_IAM_POLICY,
	NEW_GOVERNANCE_GET_GOVERNANCE_SERVICES,
	NEW_GOVERNANCE_GET_GOV_META_DATA,
	NEW_GOVERNANCE_LIST_GOVERNANCE_RESULTS,
	GET_DAY_WISE_COUNT,
	LIST_GOVERNANCE_TYPE,

	LIST_ASSETS
} from '../../config'

// import { ACTION_TYPES } from '../types'
// import { myLog } from '../../utils/utility'
// import _ from 'lodash'

export const setNewInitialRuleDetails = ruleDetails => {
	return async dispatch => {
		dispatch({
			type: ACTION_TYPES.SET_NEW_EDIT_RULE_DETAILS,
			payload: ruleDetails,
		})
	}
}
/**
 * Action to set resource props details
 */
 export const setGovernancePropsDetails = (key, value) => {
	return async (dispatch, getState) => {
		const governancePropsDetails = JSON.parse(JSON.stringify(getState().governance.governancePropsDetails))
		governancePropsDetails[key] = value		
		dispatch({
			type: ACTION_TYPES.SET_GOVERNANCE_PROPS_DETAILS,
			payload: governancePropsDetails,
		})
	}
}

/**
 * Action to set new edit rule details
 */
 export const setNewEditRuleDetails = (key, value) => {
	return async (dispatch, getState) => {
		let editRuleDetails = JSON.parse(JSON.stringify(getState().governance.editNewRuleDetails))
		editRuleDetails[key] = value
		dispatch({
			type: ACTION_TYPES.SET_NEW_EDIT_RULE_DETAILS,
			payload: editRuleDetails,
		})
	}
}

/**
 * Action to set new edit rule details
 */
export const cleareNewEditRuleDetails = (key, value) => {
	return async (dispatch, getState) => {
		dispatch({
			type: ACTION_TYPES.SET_NEW_EDIT_RULE_DETAILS,
			payload: {}
		})
	}
}

/**
 * Action to list-governance-type
 * @param {Object} body
 * @param {Function}
 */
export const listGovernanceType = (params) => {
	return async () => {
		try {

			const url = LIST_GOVERNANCE_TYPE;
			// Assuming your API endpoint for the POST request is 'YOUR_API_ENDPOINT'
			const response = await ApiCalls.get(url)

			// You can dispatch an action with the fetched data here
			// dispatch({ type: 'SET_NEW_RULES_LIST', payload: response.data });

			// Return the response data so you can use it in the component
			return response.data;
		} catch (error) {
			return error.response && error.response.data ? error.response.data : error.response && error.response.data ? error.response.data : error.response
		}
	};
}

/**
 * Action to list-governance-policies
 * @param {Object} body
 * @param {Function}
 */
export const listGovernancePolicies = (params) => {
	return async () => {
		try {

			const url = LIST_GOVERNANCE_POLICIES;
			// Assuming your API endpoint for the POST request is 'YOUR_API_ENDPOINT'
			const response = await ApiCalls.post(url, params)

			// You can dispatch an action with the fetched data here
			// dispatch({ type: 'SET_NEW_RULES_LIST', payload: response.data });

			// Return the response data so you can use it in the component
			return response.data;
		} catch (error) {
			return error.response && error.response.data ? error.response.data : error.response && error.response.data ? error.response.data : error.response
		}
	};
}

/**
 * Action to get-master-governance-rules
 * @param {Object} body
 * @param {Function}
 */
 export const getMasterGovernanceRules = (params) => {
	
	return async () => {
		try {

			const url = GET_MASTER_GOVERNANCE_RULES;
			// Assuming your API endpoint for the POST request is 'YOUR_API_ENDPOINT'
			const response = await ApiCalls.post(url, params)

			// You can dispatch an action with the fetched data here
			// dispatch({ type: 'SET_NEW_RULES_LIST', payload: response.data });

			// Return the response data so you can use it in the component
			return response.data;
		} catch (error) {
			return error.response && error.response.data ? error.response.data : error.response && error.response.data ? error.response.data : error.response
		}
	}
}

/**
 * Action to get-policy-details
 * @param {Object} body
 * @param {Function}
 */
 export const getPolicyDetails = (params) => {
	return async () => {
		try {

			const url = GET_POLICY_DETAILS;
			// Assuming your API endpoint for the POST request is 'YOUR_API_ENDPOINT'
			const response = await ApiCalls.post(url, params)

			// You can dispatch an action with the fetched data here
			// dispatch({ type: 'SET_NEW_RULES_LIST', payload: response.data });

			// Return the response data so you can use it in the component
			return response.data;
		} catch (error) {
			return error.response && error.response.data ? error.response.data : error.response && error.response.data ? error.response.data : error.response
		}
	}
}

/**
 * Action to create-governance-policy
 * @param {Object} body
 * @param {Function}
 */
 export const createGovernancePolicy = (params) => {
	return async () => {
		try {

			const url = CREATE_GOVERNANCE_POLICY;
			// Assuming your API endpoint for the POST request is 'YOUR_API_ENDPOINT'
			const response = await ApiCalls.post(url, params)

			// You can dispatch an action with the fetched data here
			// dispatch({ type: 'SET_NEW_RULES_LIST', payload: response.data });

			// Return the response data so you can use it in the component
			return response.data;
		} catch (error) {
			return error.response && error.response.data ? error.response.data : error.response && error.response.data ? error.response.data : error.response
		}
	}
}

/**
 * Action to edit-governance-policy
 * @param {Object} body
 * @param {Function}
 */
 export const editGovernancePolicy = (params) => {
	return async () => {
		try {

			const url = EDIT_GOVERNANCE_POLICY;
			// Assuming your API endpoint for the POST request is 'YOUR_API_ENDPOINT'
			const response = await ApiCalls.post(url, params)

			// You can dispatch an action with the fetched data here
			// dispatch({ type: 'SET_NEW_RULES_LIST', payload: response.data });

			// Return the response data so you can use it in the component
			return response.data;
		} catch (error) {
			return error.response && error.response.data ? error.response.data : error.response && error.response.data ? error.response.data : error.response
		}
	}
}

/**
 * Action to delete-governance-policy
 * @param {Object} body
 * @param {Function}
 */
 export const deleteGovernancePolicy = (params) => {
	return async () => {
		try {

			const url = DELETE_GOVERNANCE_POLICY;
			// Assuming your API endpoint for the POST request is 'YOUR_API_ENDPOINT'
			const response = await ApiCalls.post(url, params)

			// You can dispatch an action with the fetched data here
			// dispatch({ type: 'SET_NEW_RULES_LIST', payload: response.data });

			// Return the response data so you can use it in the component
			return response.data;
		} catch (error) {
			return error.response && error.response.data ? error.response.data : error.response && error.response.data ? error.response.data : error.response
		}
	}
}

/**
 * Action to get-total-count
 * @param {Object} body
 * @param {Function}
 */
 export const newGovernanceGetTotalCount = (params) => {
	return async () => {
		try {

			const url = NEW_GOVERNANCE_GET_TOTAL_COUNT;
			// Assuming your API endpoint for the POST request is 'YOUR_API_ENDPOINT'
			const response = await ApiCalls.post(url, params)

			// You can dispatch an action with the fetched data here
			// dispatch({ type: 'SET_NEW_RULES_LIST', payload: response.data });

			// Return the response data so you can use it in the component
			return response.data;
		} catch (error) {
			return error.response && error.response.data ? error.response.data : error.response && error.response.data ? error.response.data : error.response
		}
	}
}

/**
 * Action to get-day-wise-count
 * @param {Object} body
 * @param {Function}
 */
 export const newGovernanceGetDayWiseCount = (params) => {
	return async () => {
		try {

			const url = NEW_GOVERNANCE_GET_DAY_WISE_COUNT;
			// Assuming your API endpoint for the POST request is 'YOUR_API_ENDPOINT'
			const response = await ApiCalls.post(url, params)

			// You can dispatch an action with the fetched data here
			// dispatch({ type: 'SET_NEW_RULES_LIST', payload: response.data });

			// Return the response data so you can use it in the component
			return response.data;
		} catch (error) {
			return error.response && error.response.data ? error.response.data : error.response && error.response.data ? error.response.data : error.response
		}
	}
}

/**
 * Action to get-transition-trend
 * @param {Object} body
 * @param {Function}
 */
 export const newGovernanceGetTransitionTrend = (params) => {
	return async () => {
		try {

			const url = NEW_GOVERNANCE_GET_TRANSITION_TREND;
			// Assuming your API endpoint for the POST request is 'YOUR_API_ENDPOINT'
			const response = await ApiCalls.post(url, params)

			// You can dispatch an action with the fetched data here
			// dispatch({ type: 'SET_NEW_RULES_LIST', payload: response.data });

			// Return the response data so you can use it in the component
			return response.data;
		} catch (error) {
			return error.response && error.response.data ? error.response.data : error.response && error.response.data ? error.response.data : error.response
		}
	}
}

/**
 * Action to get-governance-violations
 * @param {Object} body
 * @param {Function}
 */
 export const getGovernanceViolations = (params) => {
	return async () => {
		try {

			const url = GET_GOVERNANCE_VIOLATIONS;
			// Assuming your API endpoint for the POST request is 'YOUR_API_ENDPOINT'
			const response = await ApiCalls.post(url, params)

			// You can dispatch an action with the fetched data here
			// dispatch({ type: 'SET_NEW_RULES_LIST', payload: response.data });

			// Return the response data so you can use it in the component
			return response.data;
		} catch (error) {
			return error.response && error.response.data ? error.response.data : error.response && error.response.data ? error.response.data : error.response
		}
	}
}

/**
 * Action to generate-iam-policy
 * @param {Object} body
 * @param {Function}
 */
 export const generateIamPolicy = (params) => {
	return async () => {
		try {

			const url = NEW_GOVERNANCE_GENERATE_IAM_POLICY;
			// Assuming your API endpoint for the POST request is 'YOUR_API_ENDPOINT'
			const response = await ApiCalls.post(url, params)

			// You can dispatch an action with the fetched data here
			// dispatch({ type: 'SET_NEW_RULES_LIST', payload: response.data });

			// Return the response data so you can use it in the component
			return response.data;
		} catch (error) {
			return error.response && error.response.data ? error.response.data : error.response && error.response.data ? error.response.data : error.response
		}
	}
}

/**
 * Action to get-master-governance-rules
 * @param {Object} body
 * @param {Function}
 */
 export const getGovernanceServices = (params) => {
	return async () => {
		try {

			const url = NEW_GOVERNANCE_GET_GOVERNANCE_SERVICES;
			// Assuming your API endpoint for the POST request is 'YOUR_API_ENDPOINT'
			const response = await ApiCalls.post(url, params)

			// You can dispatch an action with the fetched data here
			// dispatch({ type: 'SET_NEW_RULES_LIST', payload: response.data });

			// Return the response data so you can use it in the component
			return response.data;
		} catch (error) {
			return error.response && error.response.data ? error.response.data : error.response && error.response.data ? error.response.data : error.response
		}
	}
}

/**
 * Action to get-gov-meta-data
 * @param {Object} body
 * @param {Function}
 */
 export const getGovMetaData = (params) => {
	return async () => {
		try {

			const url = NEW_GOVERNANCE_GET_GOV_META_DATA;
			// Assuming your API endpoint for the POST request is 'YOUR_API_ENDPOINT'
			const response = await ApiCalls.post(url, params)

			// You can dispatch an action with the fetched data here
			// dispatch({ type: 'SET_NEW_RULES_LIST', payload: response.data });

			// Return the response data so you can use it in the component
			return response.data;
		} catch (error) {
			return error.response && error.response.data ? error.response.data : error.response && error.response.data ? error.response.data : error.response && error.response.data ? error.response.data : error.response
		}
	}
}

/**
 * Action to list-goveranance-results
 * @param {Object} body
 * @param {Function}
 */
export const listGovernanceResults = (params) => {
	return async () => {
		try {

			const url = NEW_GOVERNANCE_LIST_GOVERNANCE_RESULTS;
			// Assuming your API endpoint for the POST request is 'YOUR_API_ENDPOINT'
			const response = await ApiCalls.post(url, params)

			// You can dispatch an action with the fetched data here
			// dispatch({ type: 'SET_NEW_RULES_LIST', payload: response.data });

			// Return the response data so you can use it in the component
			return response.data;
		} catch (error) {
			return error.response && error.response.data ? error.response.data : error.response && error.response.data ? error.response.data : error.response
		}
	}
}
/**
 * Action to get-day-wise-count
 * @param {Object} body
 * @param {Function}
 */
export const governanceGetDayWiseCount = (params) => {
	return async () => {
		try {

			const url = GET_DAY_WISE_COUNT;
			// Assuming your API endpoint for the POST request is 'YOUR_API_ENDPOINT'
			const response = await ApiCalls.post(url, params)

			// You can dispatch an action with the fetched data here
			// dispatch({ type: 'SET_NEW_RULES_LIST', payload: response.data });

			// Return the response data so you can use it in the component
			return response.data;
		} catch (error) {
			return error.response && error.response.data ? error.response.data : error.response && error.response.data ? error.response.data : error.response
		}
	}
}

/**
 * Action to get all detectors list
 * @param {Object} body
 * @param {Function}
 */
export const listAssets = (params) => {
	return async dispatch => {
		try {

			const url = LIST_ASSETS;
			// Assuming your API endpoint for the POST request is 'YOUR_API_ENDPOINT'
			const response = await ApiCalls.post(url, params)

			// You can dispatch an action with the fetched data here
			// dispatch({ type: 'SET_DETECTORS_LIST', payload: response.data });

			// Return the response data so you can use it in the component
			return response.data;
		} catch (error) {
			return error.response && error.response.data ? error.response.data : error.response && error.response.data ? error.response.data : error.response
		}
	}
}