/*************************************************
 * Tvastar
 * @exports
 * @file ObjectListInputs.js
 * @author Prakash // on 7/4/23
 * @copyright © 2023 Tvastar. All rights reserved.
 *************************************************/
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'; // Import useDispatch hook to dispatch actions
import MultiSelectSection from '../../common/MultiSelectSection';

const ObjectListInputs = (props) => {
	const [state, setState] = useState({
        objArray: [0],
        hasErrorInRuleForm: false,
        hasError: props.hasError,
    })

	const editNewRuleDetails = useSelector(state => state?.governance?.editNewRuleDetails)

	useEffect(() => {
        let masterData = props.masterData
        
        let variables = masterData.variables
        variables.forEach(item => {
            item.value_object_list.forEach(objList => {
                if(item.component === "select" || item.component === "multiselect") {
                    if(item.options) {
                        setState(prevState => ({ ...prevState, [item.variable+"_options"]: item.options, [item.variable+"_0"]: item.default ? item.default : "" }))
                    }
                }
            })
        })

        if(editNewRuleDetails && editNewRuleDetails.policies) {
            if(editNewRuleDetails.policies.filter(e => e.rule_category === masterData.rule_category).length) {
                let savedData = editNewRuleDetails.policies.filter(f => f.rule_id === masterData.rule_id)
                if(savedData.length) {
                    setState(prevState => ({ ...prevState, savedData, callSavedData: true }))
                }
            }
        }
    }, [props, editNewRuleDetails])

    useEffect(() => {
        if(state.callSavedData) {
            setState(prevState => ({ ...prevState, callSavedData: false }))
            let objArray = []
            let savedData = state.savedData
            savedData.forEach((row, index) => {
                row.variables.forEach(item => {
                    if(item.component === "value_object_list") {
                        item.value_object_list.forEach((listArray, objInd) => {
                            objArray.push(objInd)
                            listArray.forEach(objLst => {
                                if(objLst.component === "text") {
                                    setState(prevState => ({ ...prevState, ["input_"+objLst.key+"_"+objInd+"_"+props.item]: objLst.value }))
                                }
                            })
                        })
                    }
                })
            })
            setState(prevState => ({ ...prevState, objArray }))
        }
	}, [props.item, state.callSavedData, state.savedData])

	useEffect(() => {
        if(state.onChangeInput) {
            setState(prevState => ({ ...prevState, onChangeInput: false }))
            let totalArray = []
            state.objArray.forEach(objInd => {
                let inputValue = []
                props.varb.value_object_list.forEach((objLst, index) => {
                    let dataRow = {}
                    Object.entries(objLst).forEach(([oKey, oValue]) => {
                        if(oKey === "value") {
                            dataRow.value = state["input_"+objLst.key+"_"+objInd+"_"+props.item]
                        } else {
                            dataRow[oKey] = oValue
                        }
                    })
                    inputValue.push(dataRow)
    
                })
                totalArray.push(inputValue)
            })
            
            props.objInputChange(totalArray)
        }
    }, [state.onChangeInput, props, state])

	const addNewList = (item) => {
		let rowList = state.objArray
        if(rowList) {
            let value = rowList[rowList.length - 1]
            value = value+1 
            rowList.push(value);
        }
        
        setState(prevState => ({ ...prevState, objArray: rowList }))
	}    

    const removeNewList = (objInd) => {
        props.varb.value_object_list.forEach(objLst => {
            if(objLst.component === "text") {
                setState(prevState => ({ ...prevState, ["input_"+objLst.key+"_"+objInd+"_"+props.item]: ""  }))
            }
        })        

        let rowList = state.objArray.filter(e => e !== objInd);
        setState(prevState => ({ ...prevState, objArray: rowList, onChangeInput: true }))
    }

	const handleChildClick = (event, type, dropdownType, section) => {	
		event.stopPropagation();

		let clickedChild = []
		if(type === 'child') {
            if(state.clickedChild && state.clickedChild.length) {
                state.clickedChild.forEach(row => {
                    setState(prevState => ({ ...prevState, [row]: false }))
                })
            }
            clickedChild.push(section)
            setState(prevState => ({ ...prevState, [section]: dropdownType === "singleDropDown" && state[section] ? false : true, clickedChild }))
        } else if(type === 'parent') {
            if(state.clickedChild && state.clickedChild.length) {
                state.clickedChild.forEach(row => {
                    setState(prevState => ({ ...prevState, [row]: false }))
                })
            }
            
            setState(prevState => ({ ...prevState, clickedChild }))
        }
	}

    return (
        state.objArray.map(objInd => {
            return(
                <div key={'objInd_'+objInd} className="flex flex-wrap w-100" onClick={(event) => handleChildClick(event, 'parent')}>
                    {props.varb.value_object_list.map(objLst => {
                        return(
                            !props.viewMode ?
                                <React.Fragment key={'objLst.key_'+objLst.key}>
                                {objLst.component === "text" ?
                                    <input 
                                        type="text" 
                                        className={`inputField textBoxWidth mr-2`}
                                        style={{minHeight: "38px"}}
                                        placeholder={objLst.label}
                                        value={state["input_"+objLst.key+"_"+objInd+"_"+props.item]}
                                        onChange={e => setState(prevState => ({ ...prevState, ["input_"+objLst.key+"_"+objInd+"_"+props.item]: e.target.value, onChangeInput: true }))}
                                    />
                                    : objLst.component === "select" ?
                                        <div className='border-lightGray bg-darkGray rounded-5'
                                            onClick={(event) => {
                                                event.preventDefault();
                                                handleChildClick(event, 'child', 'singleDropDown', "policyType")
                                            }}
                                        >
                                            <p className={`flex mt-2 pl-2 mb-2 ${state["select_"+objLst.key+"_"+objInd+"_"+props.item] !== '' ? 'text-white' : 'text-lightGray'}`}>{state["select_"+objLst.key+"_"+objInd+"_"+props.item] ? state["select_"+objLst.key+"_"+objInd+"_"+props.item] : 'Select'}</p>
                                            {state.policyType ?
                                                <MultiSelectSection
                                                    // fields={["value", "label"]}
                                                    options={state[props.varb.variable+"_options"] ? state[props.varb.variable+"_options"] : []}
                                                    selectedValues={state["select_"+objLst.key+"_"+objInd+"_"+props.item] ? state["select_"+objLst.key+"_"+objInd+"_"+props.item] : ''}
                                                    callbackMultiSelect={(value) => {
                                                        setState(prevState => ({ ...prevState, ["select_"+objLst.key+"_"+objInd+"_"+props.item]: value }))
                                                    }}
                                                    singleSelection={true}
                                                    hideSearch={true}
                                                    topClass={'auto'}
                                                    widthClass={'w-100'}
                                                />
                                            : null}
                                        </div>
                                    : null}
                                </React.Fragment>
                            :
                                <span className="badge border-gray3 mr-2 mt-2 self-center f14 text-info px-2">{state["input_"+objLst.key+"_"+objInd+"_"+props.item]}</span>
                        )
                    })}
                    {state.objArray.length > 1 ?
                        <span className={`far fa-trash cursor-pointer f18 self-center ml-4 mt-3`} onClick={() => removeNewList(objInd)}></span>
                    : null}
                    <span className={`far fa-plus cursor-pointer f18 mt-3 self-center mr-4 ml-2`} onClick={() => addNewList(props.item)}></span>
                </div>
            )
        })
    )
}
export default ObjectListInputs
