
/* eslint-disable no-mixed-spaces-and-tabs */
/*************************************************
 * Tvastar
 * @exports
 * @file LandingPage.js
 * @author Prakash // on 07/03/2024
 * @copyright © 2019-24 Tvastar. All rights reserved.
 *************************************************/
import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux'; // Import useDispatch hook to dispatch actions

import { setCommonPropsDetails, listClusterFilters } from '../../../actions/commonActionNew'
import { listQueues, getQueueDetails, getJobDetails, getQueueAvailabilityDetails } from '../../../actions/Collider/ClusterAction'

import ApexSingleDonutChart from '../../common/charts/ApexSingleDonutChart';
import ApexDonutWithToolTipChart from '../../common/charts/ApexDonutWithToolTipChart';
import { momentDateFormat } from '../../../utils/utility';
import FilterSection from '../../common/FiltersSection';
import {Icon} from "@iconify/react";

const LandingPage = () => {
    const clickOutside = useRef();
    // State variables for filters
    const [state, setState] = useState({
        showLoading: true,
        startRecordRunning: 0, 
        currentPageRunning: 1,
        totalPagesRunning: 1,
        perPage: 20,
        
        startRecordPending: 0, 
        currentPagePending: 1,
        totalPagesPending: 1,
        topSectionFilters: ['provider', 'account', 'region', 'cluster' ],
    })

    const providers = useSelector(state => state?.filters?.providers || false);

    const dispatch = useDispatch(); // Create a dispatch function

    useEffect(() => {
        if(state.showLoading) {
            setTimeout(() => { setState(prevState => ({ ...prevState,  showLoading: false })) }, 5000);
        }
    }, [state.showLoading])

    // list all providers
    // useEffect(() => {
    //     if (providers) {
    //         setState(prevState => ({ ...prevState, providers: providers, selectedProvider: prevState.selectedProvider ? prevState.selectedProvider : (providers && providers.length ? (providers.filter(e => e.provider_name === 'AWS').length ? 'AWS' : providers[0].provider_name) : ""), callClusterList: true }));
    //     }
    // }, [providers]);

    const handleSelectedFilters = (filter) => {
        setState(prevState => ({ 
            ...prevState,
            selectedProvider: filter.selectedProvider,
            selectedAccount: filter.selectedAccount ? filter.selectedAccount : [],
            selectedRegion: filter.selectedRegion ? filter.selectedRegion : [],
            selectedClusters: filter.selectedClusters ? filter.selectedClusters : [],
            showLoading: true,
            callQueueList: true
        }))
    }

    const onReset = () => {
        setState(prevState => ({ ...prevState, selectedProvider: providers && providers.length ? (providers.filter(e => e.provider_name === 'AWS').length ? 'AWS' : providers[0].provider_name) : "", selectedAccount: [], selectedRegion: [], selectedClusters: [], showLoading: true, callSearch: true, startRecordRunning: 0, currentPageRunning: 1, totalPagesRunning: 1, startRecordPending: 0, currentPagePending: 1, totalPagesPending: 1, perPage: 20, callClusterList: true }));
    }                

    useEffect(() => {
        if (state.callClusterList) {
            setState(prevState => ({ ...prevState, callClusterList: false }))
            let params = {};
            params.aggregate_by = 'cluster_name';
            params.provider = state.selectedProvider.toLowerCase();

            dispatch(listClusterFilters(params))
                .then((response) => {
                    if(response) {
                        setState(prevState => ({ ...prevState, clusters: response, selectedClusters: response.length ? response[0] : '',  callQueueList: true }));
                    }
            })
        }
    }, [dispatch, state.callClusterList, state.selectedProvider, state.selectedAccount, state.selectedRegion, state.onLoad])

    useEffect(() => {
        if (state.callQueueList) {
            setState(prevState => ({ ...prevState, callQueueList: false }))
            let params = {};
            params.provider = state.selectedProvider.toLowerCase();
            
            if(state.selectedAccount && state.selectedAccount.length) {
                params.account_id = state.selectedAccount
            }
            if(state.selectedRegion && state.selectedRegion.length) {
                params.region = state.selectedRegion
            }
            if(state.selectedClusters) {
                params.cluster_name = state.selectedClusters
            }
            dispatch(listQueues(params))
                .then((response) => {
                    if(response) {
                        setState(prevState => ({ ...prevState, queues: response, selectedQueue: response.length ? response[0] : '', callGetQueueDetails: true, callGetQueueAvailability: true, callGetJobDetails: true, showLoading: true }));
                    }
            })
        }
    }, [dispatch, state.callQueueList, state.selectedProvider, state.selectedAccount, state.selectedRegion, state.selectedClusters])

    useEffect(() => {
        if (state.callGetQueueDetails) {
            setState(prevState => ({ ...prevState, callGetQueueDetails: false }))
            let params = {};
            params.provider = state.selectedProvider.toLowerCase();
            
            if(state.selectedAccount && state.selectedAccount.length) {
                params.account_id = state.selectedAccount
            }
            if(state.selectedRegion && state.selectedRegion.length) {
                params.region = state.selectedRegion
            }
            if(state.selectedClusters) {
                params.cluster_name = state.selectedClusters
            }
            if(state.selectedQueue) {
                params.queue_name = state.selectedQueue
            }
            dispatch(getQueueDetails(params))
                .then((response) => {
                    let results = []
                    if(response) {
                        results = response.results ? response.results[0] : []
                        setState(prevState => ({ ...prevState, queueDetails: results, structureQueueDetails: true, showLoading: false }));
                    }
            })
        }
    }, [dispatch, state.callGetQueueDetails, state.selectedProvider, state.selectedAccount, state.selectedRegion, state.selectedClusters, state.selectedQueue])
    useEffect(() => {
        if (state.callGetQueueAvailability) {
            setState(prevState => ({ ...prevState, callGetQueueAvailability: false }))
            let params = {};
            params.provider = state.selectedProvider.toLowerCase();
            
            if(state.selectedAccount && state.selectedAccount.length) {
                params.account_id = state.selectedAccount
            }
            if(state.selectedRegion && state.selectedRegion.length) {
                params.region = state.selectedRegion
            }
            if(state.selectedClusters) {
                params.cluster_name = state.selectedClusters
            }
            dispatch(getQueueAvailabilityDetails(params))
                .then((response) => {
                    if(response) {
                        // results = response.results ? response.results[0] : []
                        setState(prevState => ({ ...prevState, queueAvailility: response.queue_details, showLoading: false }));
                    }
            })
        }
    }, [dispatch, state.callGetQueueAvailability, state.selectedProvider, state.selectedAccount, state.selectedRegion, state.selectedClusters, state.selectedQueue])

    useEffect(() => {
        if (state.callGetJobDetails) {
            setState(prevState => ({ ...prevState, callGetJobDetails: false }))
            let params = {};
            params.provider = state.selectedProvider.toLowerCase();
            
            if(state.selectedAccount && state.selectedAccount.length) {
                params.account_id = state.selectedAccount
            }
            if(state.selectedRegion && state.selectedRegion.length) {
                params.region = state.selectedRegion
            }
            if(state.selectedClusters) {
                params.cluster_name = state.selectedClusters
            }
            if(state.selectedQueue) {
                params.queue_name = state.selectedQueue
            }
            dispatch(getJobDetails(params))
                .then((response) => {
                    let results = []
                    if(response) {
                        results = response.results ? response.results : []
                        let runningJobs = results['Running Jobs'] ? results['Running Jobs'] : {}
                        let pendingJobs = results['Pending Jobs'] ? results['Pending Jobs'] : {}

                        let totalPagesRunning = 1
                        if(runningJobs.jobs_summary && runningJobs.jobs_summary.length > state.perPage) {
                            totalPagesRunning = Math.ceil(runningJobs.jobs_summary.length / state.perPage)
                        }
                        let totalPagesPending = 1
                        if(pendingJobs.jobs_summary && pendingJobs.jobs_summary.length > state.perPage) {
                            totalPagesPending = Math.ceil(pendingJobs.jobs_summary.length / state.perPage)
                        }
                        setState(prevState => ({
                            ...prevState,
                            jobDetails: results,
                            runningJobs,
                            pendingJobs,
                            filterdJobDetails: results,
                            totalPagesRunning,
                            totalPagesPending
                        }));
                    }
            })
        }
    }, [dispatch, state.callGetJobDetails, state.selectedProvider, state.selectedAccount, state.selectedRegion, state.selectedClusters, state.selectedQueue, state.perPage])

    useEffect(() => {
        if (state.structureQueueDetails) {
            setState(prevState => ({ ...prevState, structureQueueDetails: false }))
            state.queueDetails && state.queueDetails.chart_details && Object.entries(state.queueDetails.chart_details).forEach(([key, value]) => {
                let donut = []
                let donut_total_count = 0				
                value.data.forEach((row, rIndex) => {
                    let donutRow = {}
                    donutRow.item_count = row > 0 ? row : 0
                    donutRow.item_name = {label: value.labels[rIndex], tooltip: value.tooltip[rIndex] }
                    donut.push(donutRow)
                    donut_total_count += row 
                })
        
                let donutData = {
                    'items': donut,
                    'label': key,
                    'total_count': donut_total_count
                }        
                //services Donut start
                setState(prevState => ({ ...prevState, ['donutGraph_'+key]: donutData }))
            })
        }
    }, [state.structureQueueDetails, state.queueDetails])

    // Function to navigate between pages
    const navigateRunningPage = (action, currentPageRunning) => {
        // Update startRecordRunning and currentPageRunning based on action
        let startRecordRunning = state.startRecordRunning
        if(action === 'next' && currentPageRunning !== state.totalPagesRunning) {
            startRecordRunning = startRecordRunning + state.perPage;
            setState(prevState => ({ ...prevState, currentPageRunning: currentPageRunning+ 1 }));
        } else if(action === 'previous' && currentPageRunning !== 0) {
            setState(prevState => ({ ...prevState, currentPageRunning: currentPageRunning - 1 }));
            startRecordRunning = startRecordRunning - state.perPage;
        } else if(action === 'start' && currentPageRunning !== 0) {
            setState(prevState => ({ ...prevState, currentPageRunning: 1 }));
            startRecordRunning = 0;
        } else if(action === 'end' && currentPageRunning !== 0) {
            setState(prevState => ({ ...prevState, currentPageRunning: state.totalPagesRunning }));
            startRecordRunning = (state.totalPagesRunning - 1) * state.perPage;
        }
        setState(prevState => ({ ...prevState, startRecordRunning }))
    }
    const navigatePendingPage = (action, currentPagePending) => {
        // Update startRecordPending and currentPagePending based on action
        let startRecordPending = state.startRecordPending
        if(action === 'next' && currentPagePending !== state.totalPagesPending) {
            startRecordPending = startRecordPending + state.perPage;
            setState(prevState => ({ ...prevState, currentPagePending: currentPagePending+ 1 }));
        } else if(action === 'previous' && currentPagePending !== 0) {
            setState(prevState => ({ ...prevState, currentPagePending: currentPagePending - 1 }));
            startRecordPending = startRecordPending - state.perPage;
        } else if(action === 'start' && currentPagePending !== 0) {
            setState(prevState => ({ ...prevState, currentPagePending: 1 }));
            startRecordPending = 0;
        } else if(action === 'end' && currentPagePending !== 0) {
            setState(prevState => ({ ...prevState, currentPagePending: state.totalPagesPending }));
            startRecordPending = (state.totalPagesPending - 1) * state.perPage;
        }
        setState(prevState => ({ ...prevState, startRecordPending }))
    }

    // const gotoPage = (pageNumber) => {
    //     // Go to the specified page and update state        
    //     if(pageNumber > 0  && pageNumber <= state.totalPagesRunning) {
    //         let startRecord = (pageNumber - 1) * state.perPage
    //         setState(prevState => ({ ...prevState, currentPage: pageNumber, pageNumber, startRecord }))
    //     } else  if(pageNumber > state.totalPagesRunning) {
    //         setState(prevState => ({ ...prevState, pageNumber: state.currentPage }))
    //     } else {
    //         setState(prevState => ({ ...prevState, pageNumber }))
    //     }
    // }

    const handleChildClick = (event, type, dropdownType, section) => {		
        
		if (clickOutside.current && !clickOutside.current.contains(event.target)) {
            dispatch(setCommonPropsDetails('clickedonParent', true))
		}
        
		event.stopPropagation();
		let clickedChild = []
		if(type === 'child') {
            if(state.clickedChild && state.clickedChild.length) {
                state.clickedChild.forEach(row => {
                    setState(prevState => ({ ...prevState, [row]: false }))
                })
            }
            clickedChild.push(section)
            setState(prevState => ({ ...prevState, [section]: dropdownType === "singleDropDown" && state[section] ? false : true, clickedChild }))
        } else if(type === 'parent') {
            if(state.clickedChild && state.clickedChild.length) {
                state.clickedChild.forEach(row => {
                    setState(prevState => ({ ...prevState, [row]: false }))
                })
            }
            
            setState(prevState => ({ ...prevState, clickedChild }))
        }
	}

    return (
        <div onClick={(event) => handleChildClick(event, 'parent')}>
            <div className={`${state.showLoading ? '' : 'hidden'}`}>
                <div className='loading-wave'>
                    <div className='overlayEqualizerLoader'>
                        <div className="loading-bar"></div>
                        <div className="loading-bar"></div>
                        <div className="loading-bar"></div>
                        <div className="loading-bar"></div>
                    </div>
                </div>
            </div>
            <div className="bg-nero w-full h-fit relative stickyUiChange">
                <div className="grid grid-cols-12 items-center pb-4">
                    <div className="xl:col-span-3 col-span-12 self-center xl:mt-4">
                        <p className="text-white mx-4 text-xl md:mt-0 mt-4 font-semibold">Queue Status</p>
                        <small className="mx-4 text-white">Consolidated view of queue status</small>
                    </div>
                    <div className="xl:col-span-9 col-span-12 items-center lg:mt-4 mt-6 lg:ml-4" ref={clickOutside}>
                        <FilterSection
                            filters={state.topSectionFilters}
                            onLoadFields={['selectedClusters']}
                            selectedFilters={(filter) => handleSelectedFilters(filter)}
                            showSelectedCluster={true}
                            clickedonParent={state.clickedonParent}
                            filterAdvancedSearch={false}
                            onClickAdvancedSearch={() => setState(prevState => ({ ...prevState, showAdvancedFilters: true }))}
                            onReset={onReset}
                        />
                    </div>
                </div>
            </div>
            <div className='flex items-center justify-center min-h-screen m-2'>
                <div className="w-full lg:w-1/5 sm:w-1/3 bg-dark3 pt-2 h-screen overflow-auto rounded mr-2">
                    <p className="text-lg text-white ml-1 mb-0">Queues</p>
                    {state.queues && state.queues.length ?
                        state.queues.map((item, i) => {
                            return(
                                <React.Fragment  key={'queue_'+i}>
                                <div className={`px-2 cursor-pointer bg-dark3 text-info ${state.selectedQueue === item ? 'bg-dark3 text-info pointer-events-none' : ""}`}>
                                    <div className="flex overflow-hidden">
                                        <p className={`m-0 text-sm pl pr-1 pt-2 pb-1 ${state.selectedQueue && state.selectedQueue === item ? 'text-info' : 'text-white'}`}
                                            onClick={() => {
                                                if(state.selectedQueue !== item) {
                                                    setState(prevState => ({ ...prevState, selectedQueue: item, showLoading: true, callGetQueueDetails: true, callGetJobDetails:  true }))
                                                }
                                            }}
                                        >{item}</p>
                                    </div>
                                    <p className="text-green mb-1 flex text-sm">
                                        <span className="text-lightGray mr-2">Cores Available</span>
                                        {state.queueAvailility && state.queueAvailility.filter(e => e.queue_name === item).length && state.queueAvailility.filter(e => e.queue_name === item)[0].cpu_cores ? state.queueAvailility.filter(e => e.queue_name === item)[0].cpu_cores.available : ""}  
                                    </p>
                                    <div className="flex flex-wrap mb-1">
                                        <p className="mr-1 mb-0"></p>
                                    </div>
                                </div>
                                </React.Fragment>
                            )
                        })
                    : 
                        <div className='flex justify-center m-4 text-white'>
                            <p>There are no data on this criteria. Please try adjusting your filter.</p>
                        </div>
                    }
                </div>
                <div className="w-full lg:w-4/5 sm:w-2/3 flex justify-center pl-0 h-screen overflow-auto ">
                    <div className="bg-dark3 pt-2 w-full vh100 overflow-auto p-3">
                        <div className="flex flex-wrap">
                            {state.queueDetails && state.queueDetails.chart_details ?
                                Object.keys(state.queueDetails.chart_details).map((key, kIndex) => {
                                    return(
                                        <React.Fragment key={'key_'+kIndex}>
                                        {state['donutGraph_'+key] ? 
                                            <div className="w-full lg:w-1/4 sm:w-1/2 sm:mb-3 flex flex-col items-center">
                                                <p className="text-white text-center">{key}</p>
                                                <div className="flex justify-center">
                                                    <ApexDonutWithToolTipChart
                                                        graphData={state['donutGraph_'+key]}
                                                        legend={false}
                                                        showTotal={true}
                                                        showTotalLable={true}
                                                        totalLableType={'integer'}
                                                        showGraphHeading={false}
                                                        height={150}
                                                        width={150}
                                                        legendWidth={200}
                                                        size={'70%'}
                                                        gradient={false}
                                                        gradientColor={['#A88CCC', '#CD5A9F']}
                                                        colors={['#5F5BA2', '#9A77D1', '#3F73AD', '#24A597']}
                                                        // className={'transparentTooltip'}
                                                    />
                                                </div>
                                            </div>
                                        : null}
                                        </React.Fragment>
                                    )
                                })
                            : null}
                        </div>
                        <div className='flex flex-wrap mx-0 mt-2'>
                            <div className='w-full lg:w-1/2 pb-2 pl-0'>
                                <div className='bg-black p-3 h-full rounded'>
                                    <div className="flex justify-between mb-1">
                                        <p className='mb-0 text-white w-1/3'>Running Jobs</p>
                                        <ul className="flex mb-0 justify-end w-2/3">
                                            <li className="flex items-center mr-2">
                                                <span className="block w-2.5 h-2.5 bg-purple3 rounded-full"></span> 
                                                <span className="text-lightGray ml-1">vCPUs</span>
                                            </li>
                                            <li className="flex items-center mr-2">
                                                <span className="block w-2.5 h-2.5 bg-purple rounded-full"></span> 
                                                <span className="text-lightGray ml-1">CPU Cores</span>
                                            </li>
                                            <li className="flex items-center mr-2">
                                                <span className="block w-2.5 h-2.5 bg-darkThemePurple rounded-full"></span> 
                                                <span className="text-lightGray ml-1">Memory</span>
                                            </li>
                                            <li className="flex items-center">
                                                <span className="block w-2.5 h-2.5 bg-high rounded-full"></span> 
                                                <span className="text-lightGray ml-1">Node</span>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="flex justify-between mb-2">
                                        <p className='mb-0 w-1/2'>{state.runningJobs && state.runningJobs.description ? state.runningJobs.description : ""}</p>
                                        {state.runningJobs && state.runningJobs.jobs_summary && state.runningJobs.jobs_summary.length > state.perPage ? (
                                            <div className="text-xs w-1/2 flex items-center justify-end">
                                                <span className='mx-3 text-white'>Page <strong>{state.currentPageRunning} of {state.totalPagesRunning}</strong> </span>
                                                <div className="pagination text-sm">
                                                    <button><Icon icon="system-uicons:push-left" width="20" height="20" className={`ml-2 cursor-pointer ${state.currentPageRunning === 1 ? 'pointer-events-none text-lightGray' : 'text-info'}`} onClick={() => navigateRunningPage('start', state.currentPageRunning)} /></button>
                                                    <button><Icon icon="prime:angle-left" width="20" height="20" className={`ml-2 cursor-pointer ${state.currentPageRunning === 1 ? 'pointer-events-none text-lightGray' : 'text-info'}`} onClick={() => navigateRunningPage('previous', state.currentPageRunning)} /></button>
                                                    <button><Icon icon="prime:angle-right" width="20" height="20" className={`ml-3 cursor-pointer ${state.currentPageRunning === state.totalPagesRunning ? 'pointer-events-none text-lightGray' : 'text-info'}`} onClick={() => navigateRunningPage('next', state.currentPageRunning)}/></button>
                                                    <button><Icon icon="system-uicons:push-right" width="20" height="20" className={`ml-2 cursor-pointer ${state.currentPageRunning === state.totalPagesRunning ? 'pointer-events-none text-lightGray' : 'text-info'}`} onClick={() => navigateRunningPage('end', state.currentPageRunning)} /></button>
                                                </div>
                                            </div>
                                        ) : null}
                                    </div>
                                    {state.runningJobs && state.runningJobs.jobs_summary && state.runningJobs.jobs_summary.length ?
                                        state.runningJobs.jobs_summary.slice(state.startRecordRunning, state.startRecordRunning + state.perPage).map((row, rIndex )=> {
                                            return(
                                                <div key={'job_' + rIndex} className={`flex w-full pt-2 ${rIndex ? 'border-t border-mediumDarkGray' : ''}`}>
                                                    <div className='flex mr-2 px-2 mb-2 pb-1 w-3/4'>
                                                        <p className="mb-0 flex flex-wrap">
                                                            <span className="mb-0 mr-2 text-white text-xs font-bold text-lightGray">{row.start_time ? momentDateFormat(row.start_time, 'DD MMM YYYY HH:mm') : null} (utc time)</span>
                                                            <span className={`mb-0 mr-2 f12 text-high`}>{row.job_name ? row.job_name : ''}</span>
                                                            <span className="mb-0 mr-2 text-xs font-bold">job id <span className='text-white'>{row.job_id ? row.job_id : ''}</span></span>
                                                            <span className="mb-0 mr-2 text-xs font-bold">submitted by <span className='text-white'>{row.submitted_by ? row.submitted_by : ''}.</span></span>
                                                            <span className="mb-0 text-xs text-lightGray font-bold mr-2">Allocated cpu cores {row.cpu_cores ? row.cpu_cores : 0}</span>
                                                            <span className="mb-0 text-xs text-lightGray font-bold">memory {row.memory ? row.memory : 0}</span>
                                                            {row.memory_unit ? (
                                                                <span className="mb-0 text-xs text-lightGray font-bold ml-1">({row.memory_unit})</span>
                                                            ) : null}
                                                            <span className="mb-0 text-xs text-lightGray font-bold mx-2">nodes {row.nodes ? row.nodes : 0}</span>
                                                            <span className="mb-0 text-xs text-lightGray font-bold mr-2">vCPUs {row.vcpus ? row.vcpus : 0}</span>
                                                            <span className="mb-0 text-xs text-lightGray font-bold">wall time {row.wall_time ? row.wall_time : 0}</span>
                                                            {row.expected_completion_time ? (
                                                                <>
                                                                <span className="ml-2 mb-0 text-xs text-lightGray font-bold">expected completion time</span>
                                                                <span className="ml-2 mb-0 text-xs text-lightGray font-bold">{row.expected_completion_time} (utc time)</span>
                                                                </>
                                                            ) : null}
                                                        </p>
                                                    </div>
                                                    <div className='w-1/4 flex items-center'>
                                                        <div className='w-full flex justify-between'>
                                                            <div className="pr-2 mb-1 w-1/2">
                                                                <div className="bg-mediumDarkGray rounded-xl h-2 mb-1" id={'item_critical_' + rIndex}>
                                                                    <div className={`h-full bg-purple3 rounded-xl`} style={{ width: `${(row.used_vcpus_percentage && row.used_vcpus_percentage)}%` }}></div>
                                                                </div>
                                                                <div className="bg-mediumDarkGray rounded-xl h-2 mt-3" id={'item_medium_' + rIndex}>
                                                                    <div className={`h-full bg-darkThemePurple rounded-xl`} style={{ width: `${row.used_memory_percentage && row.used_memory_percentage}%` }}></div>
                                                                </div>
                                                            </div>
                                                            <div className="pr-2 mb-1 w-1/2">
                                                                <div className="bg-mediumDarkGray rounded-xl h-2 mb-1" id={'item_high_' + rIndex}>
                                                                    <div className={`h-full bg-darkThemePurple rounded-xl`} style={{ width: `${row.used_cpu_cores_percentage && row.used_cpu_cores_percentage}%` }}></div>
                                                                </div>
                                                                <div className="bg-mediumDarkGray rounded-xl h-2 mt-3" id={'item_low_' + rIndex}>
                                                                    <div className={`h-full bg-purple3 rounded-xl`} style={{ width: `${row.used_nodes_percentage && row.used_nodes_percentage}%` }}></div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        })
                                    : null}
                                </div>
                            </div>
                            <div className='w-full lg:w-1/2 pb-2 lg:pl-2 pl-0'>
                                <div className='bg-black p-3 h-full rounded'>
                                    <div className="flex justify-between mb-1">
                                        <p className='mb-0 text-white w-1/3'>Pending Jobs</p>
                                        <ul className="flex mb-0 justify-end w-2/3">
                                            <li className="flex items-center mr-2">
                                                <span className="block w-2.5 h-2.5 bg-purple3 rounded-full"></span> 
                                                <span className="text-lightGray ml-1">vCPUs</span>
                                            </li>
                                            <li className="flex items-center mr-2">
                                                <span className="block w-2.5 h-2.5 bg-purple rounded-full"></span> 
                                                <span className="text-lightGray ml-1">CPU Cores</span>
                                            </li>
                                            <li className="flex items-center mr-2">
                                                <span className="block w-2.5 h-2.5 bg-darkThemePurple rounded-full"></span> 
                                                <span className="text-lightGray ml-1">Memory</span>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="flex justify-between mb-2">
                                        <p className='mb-0 w-1/2'>{state.pendingJobs && state.pendingJobs.description ? state.pendingJobs.description : ""}</p>
                                        {state.pendingJobs && state.pendingJobs.jobs_summary && state.pendingJobs.jobs_summary.length > state.perPage ? (
                                            <div className="text-xs w-1/2 flex items-center justify-end">
                                                <span className='mx-3 text-white'>Page <strong>{state.currentPagePending} of {state.totalPagesPending}</strong> </span>
                                                <div className="pagination text-sm">
                                                    <button><Icon icon="system-uicons:push-left" width="20" height="20" className={`ml-2 cursor-pointer ${state.currentPagePending === 1 ? 'pointer-events-none text-lightGray' : 'text-info'}`} onClick={() => navigatePendingPage('start', state.currentPagePending)} /></button>
                                                    <button><Icon icon="prime:angle-left" width="20" height="20" className={`ml-2 cursor-pointer ${state.currentPagePending === 1 ? 'pointer-events-none text-lightGray' : 'text-info'}`} onClick={() => navigatePendingPage('previous', state.currentPagePending)} /></button>
                                                    <button><Icon icon="prime:angle-right" width="20" height="20" className={`ml-3 cursor-pointer ${state.currentPagePending === state.totalPagesPending ? 'pointer-events-none text-lightGray' : 'text-info'}`} onClick={() => navigatePendingPage('next', state.currentPagePending)}/></button>
                                                    <button><Icon icon="system-uicons:push-right" width="20" height="20" className={`ml-2 cursor-pointer ${state.currentPagePending === state.totalPagesPending ? 'pointer-events-none text-lightGray' : 'text-info'}`} onClick={() => navigatePendingPage('end', state.currentPagePending)} /></button>
                                                </div>
                                            </div>
                                        ) : null}
                                    </div>
                                    {state.pendingJobs && state.pendingJobs.jobs_summary && state.pendingJobs.jobs_summary.length ?
                                        state.pendingJobs.jobs_summary.slice(state.startRecordPending, state.startRecordPending + state.perPage).map((row, rIndex )=> {
                                            return(
                                                <div key={'job_'+rIndex} className={`flex w-full pt-2 ${rIndex ? 'border-t border-mediumDarkGray': ''}`}>
                                                    <div className='flex mr-2 px-2 mb-2 pb-1 w-3/4'>
                                                        <p className="mb-0 flex flex-wrap">
                                                            <span className="mb-0 mr-2 text-white text-xs font-bold text-lightGray">{row.start_time ? momentDateFormat(row.start_time, 'DD MMM YYYY HH:mm') : null} (utc time)</span>
                                                            <span className={`mb-0 mr-2 f12 text-high`}>{row.job_name ? row.job_name : ''}</span>
                                                            <span className="mb-0 mr-2 text-xs font-bold">job id <span className='text-white'>{row.job_id ? row.job_id : ''}</span></span>
                                                            <span className="mb-0 mr-2 text-xs font-bold">submitted by <span className='text-white'>{row.submitted_by ? row.submitted_by : ''}.</span></span>
                                                            <span className="mb-0 text-xs text-lightGray font-bold mr-2">Requested cpu cores {row.cpu_cores ? row.cpu_cores : 0}</span>
                                                            <span className="mb-0 text-xs text-lightGray font-bold">memory {row.memory ? row.memory : 0}</span>
                                                            {row.memory_unit ? (
                                                                <span className="mb-0 text-xs text-lightGray font-bold ml-1">({row.memory_unit})</span>
                                                            ) : null}
                                                            <span className="mb-0 text-xs text-lightGray font-bold mx-2">nodes {row.nodes ? row.nodes : 0}</span>
                                                            <span className="mb-0 text-xs text-lightGray font-bold mr-2">vCPUs {row.vcpus ? row.vcpus : 0}</span>
                                                            <span className="mb-0 text-xs text-lightGray font-bold">wall time {row.wall_time ? row.wall_time : 0}</span>
                                                        </p>
                                                    </div>
                                                    <div className='w-1/4 self-center'>
                                                        <div className='flex justify-between'>
                                                            <div className='mr-2'>
                                                                <ApexSingleDonutChart
                                                                    labelColor={"#999999"}
                                                                    valueColor={"#999999"}
                                                                    graphData={{label: 'vCPUs', value: row.vcpus ? row.vcpus : 0}}
                                                                    legend={false}
                                                                    showTotal={false}
                                                                    showTotalLable={true}
                                                                    showGraphHeading={false}
                                                                    height={25}
                                                                    width={25}
                                                                    legendWidth={200}
                                                                    size={'70%'}
                                                                    gradient={false}
                                                                    colors={['#854097']}
                                                                    className={'transparentTooltip'}
                                                                />
                                                            </div>
                                                            <div className='mr-2'>
                                                                <ApexSingleDonutChart
                                                                    labelColor={"#999999"}
                                                                    valueColor={"#999999"}
                                                                    graphData={{label: 'CPU Cores', value: row.cpu_cores ? row.cpu_cores : 0}}
                                                                    legend={false}
                                                                    showTotal={false}
                                                                    showTotalLable={true}
                                                                    showGraphHeading={false}
                                                                    height={25}
                                                                    width={25}
                                                                    legendWidth={200}
                                                                    size={'70%'}
                                                                    gradient={false}
                                                                    colors={['#693EBC']}
                                                                    className={'transparentTooltip'}
                                                                />
                                                            </div>
                                                            <div className='mr-2'>
                                                                <ApexSingleDonutChart
                                                                    labelColor={"#999999"}
                                                                    valueColor={"#999999"}
                                                                    graphData={{label: 'Memory'+(row.memory_unit ? ' ('+row.memory_unit+')' : ""), value: row.memory ? row.memory : 0}}
                                                                    legend={false}
                                                                    showTotal={false}
                                                                    showTotalLable={true}
                                                                    showGraphHeading={false}
                                                                    height={25}
                                                                    width={25}
                                                                    legendWidth={200}
                                                                    size={'70%'}
                                                                    gradient={false}
                                                                    colors={['#775BA2']}
                                                                    className={'transparentTooltip'}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        })
                                    : null}
                                </div>
                            </div>
                        </div>  
                    </div>
                </div>
            </div>
        </div>
    );
};

export default LandingPage