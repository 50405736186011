/*************************************************
 * Collider
 * @exports
 * @file UserAction.js
 * @author Prakash // on 18/01/2024
 * @copyright © 2024 Collider. All rights reserved.
 *************************************************/
import ApiCalls from '../components/ApiCalls'
import {
	GET_ONBOARDED_ACCOUNTS_LIST,
	ONBOARD_AWS_ACCOUNT,
	LIST_ACCOUNT_MODULES,
	// ONBOARD_GCP_ACCOUNT,
	DELETE_ONBOARDED_ACCOUNT,
	LIST_ONBOARDING_CLUSTERS,
	ONBOARD_CLUSTERS,
	GET_ONBOARDED_CLUSTER_DETAILS
} from '../config'
// import { myLog, momentTime, momentDate, convertBytes, convertSeconds, convertBits, countFormater } from '../../utils/utility'
//import Cookies from 'js-cookie'
// import { ACTION_TYPES } from '../types';

/**
 * Action to /account-onboarding/get-onboarded-accounts-list
 * @param {Object} body
 * @param {Function} callback
 */
export const getOnboardedAccountsDetails = (params) => {
	return async () => {
		try {
			const url = GET_ONBOARDED_ACCOUNTS_LIST;
			// Assuming your API endpoint for the POST request is 'YOUR_API_ENDPOINT'
			const response = await ApiCalls.post(url, params)

			// You can dispatch an action with the fetched data here
			//   dispatch({ type: 'FETCH_TROUBLESHOOT_RESULTS_SUCCESS', payload: response.data });

			// Return the response data so you can use it in the component
			return response.data;
		} catch (error) {
			return error.response && error.response.data ? error.response.data : error.response && error.response.data ? error.response.data : error.response
		}
	};
}

/**
 * Action to /account-onboarding/onboard-aws-account
 * @param {Object} body
 * @param {Function} callback
 */
export const onboardAwsAccount = (params) => {
	return async () => {
		try {
			const url = ONBOARD_AWS_ACCOUNT;
			// Assuming your API endpoint for the POST request is 'YOUR_API_ENDPOINT'
			const response = await ApiCalls.post(url, params)

			// You can dispatch an action with the fetched data here
			//   dispatch({ type: 'FETCH_TROUBLESHOOT_RESULTS_SUCCESS', payload: response.data });

			// Return the response data so you can use it in the component
			return response.data;
		} catch (error) {
			return error.response && error.response.data ? error.response.data : error.response && error.response.data ? error.response.data : error.response
		}
	};
}

/**
 * Action to /account-onboarding/list_modules
 * @param {Object} body
 * @param {Function} callback
 */
export const listAccountModules = (params) => {
	return async () => {
		try {
			const url = LIST_ACCOUNT_MODULES;
			// Assuming your API endpoint for the POST request is 'YOUR_API_ENDPOINT'
			const response = await ApiCalls.post(url, params)

			// You can dispatch an action with the fetched data here
			//   dispatch({ type: 'FETCH_TROUBLESHOOT_RESULTS_SUCCESS', payload: response.data });

			// Return the response data so you can use it in the component
			return response.data;
		} catch (error) {
			return error.response && error.response.data ? error.response.data : error.response && error.response.data ? error.response.data : error.response
		}
	};
}

/**
 * Action to /cluster-onboarding/list-clusters
 * @param {Object} body
 * @param {Function} callback
 */
export const listOnboardingClusters = (params) => {
	return async () => {
		try {
			const url = LIST_ONBOARDING_CLUSTERS;
			// Assuming your API endpoint for the POST request is 'YOUR_API_ENDPOINT'
			const response = await ApiCalls.post(url, params)

			// You can dispatch an action with the fetched data here
			//   dispatch({ type: 'FETCH_TROUBLESHOOT_RESULTS_SUCCESS', payload: response.data });

			// Return the response data so you can use it in the component
			return response.data;
		} catch (error) {
			return error.response && error.response.data ? error.response.data : error.response && error.response.data ? error.response.data : error.response
		}
	};
}

/**
 * Action to /cluster-onboarding/onboard-cluster
 * @param {Object} body
 * @param {Function} callback
 */
export const onboardClusters = (params) => {
	return async () => {
		try {
			const url = ONBOARD_CLUSTERS;
			// Assuming your API endpoint for the POST request is 'YOUR_API_ENDPOINT'
			const response = await ApiCalls.post(url, params)

			// You can dispatch an action with the fetched data here
			//   dispatch({ type: 'FETCH_TROUBLESHOOT_RESULTS_SUCCESS', payload: response.data });

			// Return the response data so you can use it in the component
			return response.data;
		} catch (error) {
			return error.response && error.response.data ? error.response.data : error.response && error.response.data ? error.response.data : error.response
		}
	};
}

/**
 * Action to /cluster-onboarding/get-onboarded-cluster-details
 * @param {Object} body
 * @param {Function} callback
 */
export const getOnboardedClusterDetails = (params) => {
	return async () => {
		try {
			const url = GET_ONBOARDED_CLUSTER_DETAILS;
			// Assuming your API endpoint for the POST request is 'YOUR_API_ENDPOINT'
			const response = await ApiCalls.post(url, params)

			// You can dispatch an action with the fetched data here
			//   dispatch({ type: 'FETCH_TROUBLESHOOT_RESULTS_SUCCESS', payload: response.data });

			// Return the response data so you can use it in the component
			return response.data;
		} catch (error) {
			return error.response && error.response.data ? error.response.data : error.response && error.response.data ? error.response.data : error.response
		}
	};
}

/**
 * Action to /account-onboarding/onboard-gcp-account
//  * @param {Object} body
//  * @param {Function} callback
 */
// export const onboardGcpAccount = (params) => {
// 	return async () => {
// 		try {
// 			const url = ONBOARD_GCP_ACCOUNT;
// 			// Assuming your API endpoint for the POST request is 'YOUR_API_ENDPOINT'
// 			const response = await ApiCalls.post(url, params)

// 			// You can dispatch an action with the fetched data here
// 			//   dispatch({ type: 'FETCH_TROUBLESHOOT_RESULTS_SUCCESS', payload: response.data });

// 			// Return the response data so you can use it in the component
// 			return response.data;
// 		} catch (error) {
// 			// Handle error and dispatch an action in case of failure
// 			//   dispatch({ type: 'FETCH_TROUBLESHOOT_RESULTS_FAILURE', payload: error });

// 			// Throw the error to be caught in the component if needed
// 			// throw error;
// 			if(error && error.response && (error.response.status === 403 || error.response.data.token_expired)) {
// 				//localStorage.setItem('ACCESS_TOKEN', '');
// 				//window.location.href = '/signin';
// 				refreshPage()
// 			}
// 			return error
// 		}
// 	};
// }


/**
 * Action to /account-onboarding/delete-onboarded-account
 * @param {Object} body
 * @param {Function} callback
 */
export const deleteOnboardedAccount = (params) => {
	return async () => {
		try {
			const url = DELETE_ONBOARDED_ACCOUNT;
			// Assuming your API endpoint for the POST request is 'YOUR_API_ENDPOINT'
			const response = await ApiCalls.post(url, params)

			// You can dispatch an action with the fetched data here
			//   dispatch({ type: 'FETCH_TROUBLESHOOT_RESULTS_SUCCESS', payload: response.data });

			// Return the response data so you can use it in the component
			return response.data;
		} catch (error) {
			return error.response && error.response.data ? error.response.data : error.response && error.response.data ? error.response.data : error.response
		}
	};
}