/* eslint-disable no-mixed-spaces-and-tabs */
/*************************************************
 * Collider
 * @exports
 * @file CreateNewBudget.js
 * @author Prakash // on 05/12/2023
 * @copyright © 2023 Collider. All rights reserved.
 *************************************************/
import React, { useEffect } from 'react';
import LeftSection from './LeftSection';
import RightSection from './RightSection';
import { useLocation, useNavigate } from 'react-router-dom'

const CreateNewBudget = () => {
    const location = useLocation();
    const receivedData = location.state;
    const navigate = useNavigate();

    useEffect(() => {
        const handleBeforeUnload = (event) => {
        const message = 'Are you sure you want to leave the form? Your changes may not be saved.';
        event.returnValue = message; // Standard for most browsers
        return message; // For some older browsers
        };

        const handlePopstate = () => {
        const message = 'Are you sure you want to leave the form? Your changes may not be saved.';
        if (window.confirm(message)) {
            // Handle user confirmation, e.g., navigate away or perform other actions
        }
        };

        const handleBeforeRedirect = (location) => {
        const message = 'Are you sure you want to leave the form? Your changes may not be saved.';
        if (window.confirm(message)) {
            // Handle user confirmation, e.g., navigate away or perform other actions
            navigate(location.pathname); // Redirect manually after confirmation
        } else {
            // Prevent the redirect if the user cancels
            return false;
        }
        };

        window.addEventListener('beforeunload', handleBeforeUnload);
        window.addEventListener('popstate', handlePopstate);

        // Use window.onbeforeunload to handle redirects
        const unblock = () => {};
        window.onbeforeunload = () => handleBeforeRedirect({ pathname: '/' });

        return() => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
            window.removeEventListener('popstate', handlePopstate);
            window.onbeforeunload = null; // Remove the onbeforeunload handler when the component is unmounted
            unblock(); // Remove the history.block when the component is unmounted
        };
    }, [navigate]); // Re-run the effect when navigate changes


    return (
        <div className='flex flex-wrap'>
            <div className={`lg:w-1/2 w-full flex flex-col h-100`}>
                <LeftSection 
                    receivedData={receivedData}
                />
            </div>
            <div className={`lg:w-1/2 w-full lg:pl-2 flex flex-col h-100`}>
                <RightSection 
                    receivedData={receivedData}
                />
            </div>
        </div>
    )
}

export default CreateNewBudget