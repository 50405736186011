
/* eslint-disable no-mixed-spaces-and-tabs */
/*************************************************
 * Tvastar
 * @exports
 * @file AlertDetails.js
 * @author Prakash // on 05/02/2024
 * @copyright © 2024 Tvastar. All rights reserved.
 *************************************************/
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'; // Import useDispatch hook to dispatch actions
import _ from 'lodash'
import { momentConvertionUtcToLocalTime, capitalizeFirstLetter } from '../../../utils/utility'
import {Icon} from "@iconify/react";


const AlertDetails = (props) => {
    const [state, setState] = useState({
        expandAlertDetails: true
    })
    const alertDetails = useSelector(state => state?.hpc?.hpcPropsDetails?.alertDetails || false);

    useEffect(() => {
        if(!_.isEqual(alertDetails, state.alertDetails)) {
            setState(prevState => ({
                ...prevState, 
                alertDetails,
                showSpinner: true,
                expandAlertDetails: true,
                expandAssetDetails: false
            }))

            setTimeout(() => setState(prevState => ({ ...prevState, showSpinner: false })), 2000)
        }
    }, [alertDetails, state.alertDetails])

    return (
        <div className="rounded-md bg-dark3 p-3">
            {state.showSpinner ?
                <div className='flex justify-center m-4'>
                    <svg className="animate-spin h-5 w-5 mr-2 text-white" fill="currentColor" viewBox="0 0 24 24">
                        <circle cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="2" fill="none" />
                        <path d="M4 12a8 8 0 0112-6.9" />
                    </svg>
                </div>
            :
                alertDetails.details && Object.entries(alertDetails.details).length ?
                    <React.Fragment>
                    <div className={`bg-dark p-3 mb-2`}>
                        <div className="flex text-white mb-4" onClick={() => setState(prevState => ({ ...prevState, expandAlertDetails: !state.expandAlertDetails }))}>
                            {state.expandAlertDetails ?
                                <Icon icon="zondicons:minus-outline" className='mr-1 self-center cursor-pointer' width={16} height={16} />
                            :
                                <Icon icon="tabler:circle-plus" className='mr-1 self-center cursor-pointer' width={20} height={20} />
                            }
                            <span className='self-center text-md font-semibold'>Alert Details</span>
                        </div>
                        {state.expandAlertDetails ?
                            <div className="">
                                <div className="flex justify-between mb-2 pb-2">
                                    {alertDetails.details.type === 'alert' ?
                                        <div>
                                            <p className="mb-0 text-md text-white">{alertDetails && alertDetails.details && alertDetails.details.event_name ? alertDetails.details.event_name : ''}</p>
                                            <p className="mb-0 text-sm">{alertDetails && alertDetails.details && alertDetails.details.description ? alertDetails.details.description : ''}</p>
                                        </div>
                                    : 
                                        <div>
                                            <div className="flex flex-wrap">
                                                <p className="mb-0 text-md text-white">{alertDetails && alertDetails.details && alertDetails.details.resource_type ? alertDetails.details.resource_type : ''}</p>
                                                <p className="mb-0 text-md text-white">{alertDetails && alertDetails.details && alertDetails.details.event_type ? '_'+alertDetails.details.event_type : ''}</p>
                                                <p className="mb-0 text-md text-white">{alertDetails && alertDetails.details && alertDetails.details.asset_name ? '_'+alertDetails.details.asset_name : ''}</p>
                                            </div>
                                            <p className="mb-0 text-xs">{alertDetails && alertDetails.details && alertDetails.details.event_type ? alertDetails.details.event_type : ''}</p>
                                        </div>
                                    }
                                    <div>
                                        <p className="b-block mb-1">Severity:</p>
                                        <span className={`mr-2 text-white text-xs ${alertDetails.details.severity ? 'bg-'+alertDetails.details.severity.toLowerCase() : 'bg-lightGray' } rounded-full px-2 py-1`}>  
                                            {alertDetails.details.severity ? capitalizeFirstLetter(alertDetails.details.severity) : 'Unknown'}
                                        </span>
                                    </div>
                                </div>
                                {alertDetails.details.type === 'group' ?
                                    <React.Fragment>
                                    <div className="flex flex-wrap mb-1 border-t border-mediumDarkGray">
                                        <div className="py-1 lg:w-1/2 md:w-1/2 w-full">
                                            <div className="flex flex-wrap"> 
                                                <p className="b-block mb-0 mr-2 text-white">Severity</p>
                                                <p className={`self-center mb-0 mr-2 badge ${alertDetails.details.first_event_severity ? 'risk-badge-'+alertDetails.details.first_event_severity.toLowerCase() : 'badge-secondary' }`}>  
                                                    {alertDetails.details.first_event_severity ? capitalizeFirstLetter(alertDetails.details.first_event_severity) : 'Unknown'}
                                                </p>
                                                <p className="b-block mb-0 mr-2 text-white">for the first alert observed on</p>
                                                <p className="mb-0 text-white">{alertDetails && alertDetails.details && alertDetails.details.first_event ? momentConvertionUtcToLocalTime(alertDetails.details.first_event, 'DD MMM YYYY HH:mm') : ''}</p>
                                            </div>
                                        </div>
                                        <div className="py-1 lg:w-1/2 md:w-1/2 w-full lg:pl-3 md:pl-3 pl-0">
                                            <p className="b-block mb-0">Threshold / Statistic / Period:</p>
                                            <p className="mb-0 text-warning">
                                                {alertDetails.details.first_event_threshold && alertDetails.details.first_event_threshold}
                                                {alertDetails.details.unit ? (alertDetails.details.unit ? (alertDetails.details.unit.toLowerCase() === 'percent' ? ' %' : alertDetails.details.unit) : '') : ''}
                                                {alertDetails.details.first_event_statistic ? (' : ')+alertDetails.details.first_event_statistic : ''}
                                                {alertDetails.details.first_event_period ? (' : ')+alertDetails.details.first_event_period : ''}
                                            </p>
                                        </div>
                                    </div>
                                    <div className="flex flex-wrap mb-1 border-t border-mediumDarkGray">
                                        <div className="py-1 lg:w-1/2 md:w-1/2 w-full">
                                            <div className="flex flex-wrap"> 
                                                <p className="b-block mb-0 mr-2 text-white">Severity</p>
                                                <p className={`self-center mb-0 mr-2 badge ${alertDetails.details.last_event_severity ? 'risk-badge-'+alertDetails.details.last_event_severity.toLowerCase() : 'badge-secondary' }`}>  
                                                    {alertDetails.details.last_event_severity ? capitalizeFirstLetter(alertDetails.details.last_event_severity) : 'Unknown'}
                                                </p>
                                                <p className="b-block mb-0 mr-2 text-white">for the last alert observed on</p>
                                                <p className="mb-0 text-white">{alertDetails && alertDetails.details && alertDetails.details.last_event ? momentConvertionUtcToLocalTime(alertDetails.details.last_event, 'DD MMM YYYY HH:mm') : ''}</p>
                                            </div>
                                        </div>
                                        <div className="py-1 lg:w-1/2 md:w-1/2 w-full lg:pl-3 md:pl-3 pl-0">
                                            <p className="b-block mb-0">Threshold / Statistic / Period:</p>
                                            <p className="mb-0 text-warning">
                                                {alertDetails.details.last_event_threshold && alertDetails.details.last_event_threshold}
                                                {alertDetails.details.unit ? (alertDetails.details.unit ? (alertDetails.details.unit.toLowerCase() === 'percent' ? ' %' : alertDetails.details.unit) : '') : ''}
                                                {alertDetails.details.last_event_statistic ? (' : ')+alertDetails.details.last_event_statistic : ''}
                                                {alertDetails.details.last_event_period ? (' : ')+alertDetails.details.last_event_period : ''}
                                            </p>
                                        </div>
                                    </div>
                                    <div className="flex flex-wrap mb-1 border-t border-mediumDarkGray">
                                        <div className="py-1 lg:w-1/2 md:w-1/2 w-full">
                                            <div className="flex flex-wrap"> 
                                                <p className="b-block mb-0 mr-2 text-white">Severity</p>
                                                <p className={`self-center mb-0 mr-2 badge ${alertDetails.details.highest_event_severity ? 'risk-badge-'+alertDetails.details.highest_event_severity.toLowerCase() : 'badge-secondary' }`}>  
                                                    {alertDetails.details.highest_event_severity ? capitalizeFirstLetter(alertDetails.details.highest_event_severity) : 'Unknown'}
                                                </p>
                                                <p className="b-block mb-0 mr-2 text-white">for the highest alert observed on</p>
                                                <p className="mb-0 text-white">{alertDetails && alertDetails.details && alertDetails.details.highest_event ? momentConvertionUtcToLocalTime(alertDetails.details.highest_event, 'DD MMM YYYY HH:mm') : ''}</p>
                                            </div>
                                        </div>
                                        <div className="py-1 lg:w-1/2 md:w-1/2 w-full lg:pl-3 md:pl-3 pl-0">
                                            <p className="b-block mb-0">Threshold / Statistic / Period:</p>
                                            <p className="mb-0 text-warning">
                                                {alertDetails.details.highest_event_threshold && alertDetails.details.highest_event_threshold}
                                                {alertDetails.details.unit ? (alertDetails.details.unit ? (alertDetails.details.unit.toLowerCase() === 'percent' ? ' %' : alertDetails.details.unit) : '') : ''}
                                                {alertDetails.details.highest_event_statistic ? (' : ')+alertDetails.details.highest_event_statistic : ''}
                                                {alertDetails.details.highest_event_period ? (' : ')+alertDetails.details.highest_event_period : ''}
                                            </p>
                                        </div>
                                    </div>
                                    </React.Fragment>

                                : null}
                                {alertDetails.details.type === 'alert' ?
                                    <div className="flex flex-wrap mb-1 border-t border-mediumDarkGray">
                                        <div className="py-1 lg:w-1/2 md:w-1/2 w-full">
                                            <p className="b-block mb-0">Alert Time:</p>
                                            <p className="mb-0 text-white">
                                                {alertDetails.details.type === 'alert' ?
                                                    alertDetails.details.event_time ? momentConvertionUtcToLocalTime(alertDetails.details.event_time, 'DD MMM YYYY HH:mm') : ''
                                                :
                                                    alertDetails.details.created_at ? momentConvertionUtcToLocalTime(alertDetails.details.created_at, 'DD MMM YYYY HH:mm') : ''
                                                }
                                            </p>
                                        </div>
                                        <div className="py-1 lg:w-1/2 md:w-1/2 w-full lg:pl-3 md:pl-3 pl-0">
                                            <p className="b-block mb-0">Alert Metrics:</p>
                                            {alertDetails.details.type === 'alert' ?
                                                <p className="mb-0 text-white">
                                                    {alertDetails.details.event_source ? alertDetails.details.event_source : ''}
                                                    {alertDetails.details.event_type ? (' : ')+alertDetails.details.event_type : ''}
                                                </p>
                                            : null}
                                        </div>
                                    </div>
                                : null}
                                {alertDetails.details.type === 'alert' ?
                                    <div className="flex flex-wrap mb-1 border-t border-mediumDarkGray">
                                        <div className="py-1 lg:w-1/2 md:w-1/2 w-full">
                                            <p className="b-block mb-0">Threshold / Statistic / Period:</p>
                                            <p className="mb-0 text-warning">
                                                {alertDetails.details.threshold && alertDetails.details.threshold}{alertDetails.details.unit ? (alertDetails.details.unit ? (alertDetails.details.unit.toLowerCase() === 'percent' ? ' %' : alertDetails.details.unit) : '') : ''}
                                                {alertDetails.details.statistic ? (' : ')+alertDetails.details.statistic : ''}
                                                {alertDetails.details.period ? (' : ')+alertDetails.details.period : ''}
                                            </p>
                                        </div>
                                        <div className="py-1 lg:w-1/2 md:w-1/2 w-full lg:pl-3 md:pl-3 pl-0">
                                            <p className="b-block mb-0">Provider / Account / Region :</p>
                                            <p className="mb-0 text-white">
                                                {alertDetails.details.provider ? alertDetails.details.provider.toUpperCase() : ''}
                                                {alertDetails.details.account_id ? (' : ')+alertDetails.details.account_id : ''}
                                                {alertDetails.details.region ? (' : ')+alertDetails.details.region : ''}
                                            </p>
                                        </div>
                                    </div>
                                : null}
                                {alertDetails.details.type !== 'alert' ?
                                    <div className="flex flex-wrap mb-1 border-t border-mediumDarkGray">
                                        <div className="py-1 lg:w-1/2 md:w-1/2 w-full lg:pl-3 md:pl-3 pl-0">
                                            <p className="b-block mb-0">Provider / Account / Region :</p>
                                            <p className="mb-0 text-white">
                                                {alertDetails.details.provider ? alertDetails.details.provider.toUpperCase() : ''}
                                                {alertDetails.details.account_id ? (' : ')+alertDetails.details.account_id : ''}
                                                {alertDetails.details.region ? (' : ')+alertDetails.details.region : ''}
                                            </p>
                                        </div>
                                    </div>
                                : null}
                            </div>
                        : null}
                    </div>

                    {/* {state.assetDetails && state.assetDetails.length ? */}
                        <div className={`bg-dark p-3 rounded-md mb-2 mt-4`}>
                            <div className="flex text-white mb-4" onClick={() => setState(prevState => ({ ...prevState, expandAssetDetails: !state.expandAssetDetails }))}>
                                {state.expandAssetDetails ?
                                    <Icon icon="zondicons:minus-outline" className='mr-1 self-center cursor-pointer' width={16} height={16} />
                                :
                                    <Icon icon="tabler:circle-plus" className='mr-1 self-center cursor-pointer' width={20} height={20} />
                                }
                                <span className='self-center text-md font-semibold'>Asset Details</span>
                            </div>
                            {state.expandAssetDetails ?
                                // state.assetDetails.map((item, iIndex) => {
                                    // return(
                                    <div key={'asset_0'} className="m-2 pt-2 rounded-md">
                                        <div className="flex flex-wrap mb-2">
                                            <div className="py-1 lg:w-1/2 md:w-1/2 w-full">
                                                <p className="b-block mb-0">Name:</p>
                                                <p className="mb-0 text-white break-all">{alertDetails && alertDetails.details && alertDetails.details.asset_name ? alertDetails.details.asset_name : ''}</p>
                                            </div>
                                            <div className="py-1 lg:w-1/2 md:w-1/2 w-full lg:pl-3 md:pl-3 pl-0">
                                                <p className="b-block mb-0">Id:</p>
                                                <p className="mb-0 text-white break-all">{alertDetails && alertDetails.details && alertDetails.details.asset_id ? alertDetails.details.asset_id : ''}</p>
                                            </div>
                                        </div>
                                        <div className="flex flex-wrap mb-2 border-t border-mediumDarkGray">
                                            <div className="py-1 lg:w-1/2 md:w-1/2 w-full">
                                                <p className="b-block mb-0">Resource:</p>
                                                <p className="mb-0 text-white break-all">
                                                    {alertDetails.details.provider ? alertDetails.details.provider.toUpperCase() : ""}
                                                    {alertDetails.details.account_id ? (" : ")+alertDetails.details.account_id : ""}
                                                    {alertDetails.details.region ? (" : ")+alertDetails.details.region : ""}
                                                </p>
                                            </div>
                                            <div className="py-1 lg:w-1/2 md:w-1/2 w-full lg:pl-3 md:pl-3 pl-0">
                                                <p className="b-block mb-0">Services:</p>
                                                <p className="mb-0 text-white break-all">
                                                    {alertDetails.details.resource_type ? alertDetails.details.resource_type : ''}
                                                    <span className='ml-2'>{alertDetails.details.service_name ? alertDetails.details.service_name : ''}</span>
                                                </p>
                                            </div>
                                        </div>                                                        
                                        <div className="flex flex-wrap mb-2 border-t border-mediumDarkGray">
                                            <div className="py-1 w-full">
                                                <p className="b-block mb-0">ARN:</p>
                                                <p className="mb-0 text-white break-all">{alertDetails && alertDetails.details && alertDetails.details.asset_arn ? alertDetails.details.asset_arn : ''}</p>
                                            </div>
                                        </div>
                                        {alertDetails && alertDetails.details && alertDetails.details.category ? 
                                            <div className="flex flex-wrap mb-2 border-t border-mediumDarkGray">
                                                <div className="py-1 w-full">
                                                    <p className="b-block mb-1">Category:</p>
                                                    <div className={`flex flex-wrap`}>
                                                        {Array.isArray(alertDetails.details.category) ?
                                                            alertDetails.details.category.map(item => {
                                                                return (
                                                                    <small key={'cat_'+item} className={`mr-1 mb-1 bg-lightGray rounded-full px-2 py-1`}>{item}</small> 
                                                                )
                                                            })
                                                        : 
                                                            <small className={`mr-1 mb-1 bg-lightGray rounded-full px-2 py-1 break-all`}>{alertDetails.details.category}</small> 
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        : null}
                                    </div>
                                //     )
                                // })
                            : null}
                        </div>
                    {/* : null} */}
                    </React.Fragment>
                :
                    <div className='flex justify-center m-4 text-white'>
                        <p>There are no data on this criteria. Please try adjusting your filter.</p>
                    </div>
            }
        </div>
    )
}

export default AlertDetails