import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import MultiSelectSection from '../../../common/MultiSelectSection';
import { getAllTagsKeys, getAllTagsValues } from '../../../../actions/commonActionNew'
import {Icon} from "@iconify/react";

const TagsSection = (props) => {
    const [state, setState] = useState({
        inputComponent: props.inputComponent,
        selectedPage: props.selectedPage,
        selectedOption: props.selectedOption,
        selectedFilters: props.selectedFilters,
        selectedProvider: props.selectedProvider
    })

    const dispatch = useDispatch()
    const tagKeys = useSelector(state => state.filters.tagKeys);

    useEffect(() => {
        let params = {}
        params.provider = state.selectedProvider.toLowerCase()
        dispatch(getAllTagsKeys(params))

    }, [dispatch, state.selectedProvider])

	useEffect(() => {
        if(state.tag_key) {
            let params = {}
            params.tags = [{ "key": state.tag_key }]
            dispatch(getAllTagsValues(params))
                .then((response) => {
                    setState(prevState => ({ ...prevState, tagValues: response }))
                })
        }
	}, [dispatch, state.tag_key])

	const addNewTag = () => {
		let selectedTags = state.selectedFilters ? state.selectedFilters : []

		if(state.tag_key && state.tag_key !== "" && state.tag_value && state.tag_value.length) {
			state.tag_value.forEach(tag => {
				if(tag !== "All" && (!(selectedTags.filter(e => e.key === state.tag_key && e.value !== tag).length) || selectedTags.filter(e => e.key !== state.tag_key))) {
					let dataRow = {}
                    dataRow.label = "Tags"
					dataRow.key = state.tag_key
					dataRow.value = tag
                    dataRow.userView = state.selectedOption+" : "+state.tag_key + " : "+ tag
					selectedTags.push(dataRow)
				}
			})
		}

		setState(prevState => ({ ...prevState, selectedFilters: selectedTags, tag_key: "",  tag_value: [], tagValues: undefined }))
	}

	const removeTagSection = (tag) => {
		// filteredResult = state.selectedTags.filter(e => e.key !== tag.key && e.value !== tag.value)
		let filteredResult = state.selectedFilters.filter(e => e !== tag)
		setState(prevState => ({ ...prevState, selectedFilters: filteredResult }))
	}

    const applyFilter = () => {
        if(state.selectedFilters && state.selectedFilters.length) {
            return props.selectedInput(state.selectedOption, state.selectedFilters)
        } else {
            return props.selectedInput(false);
        }
    }

    const closeFilter = () => {
        return props.selectedInput(false);
    }

    const handleChildClick = (event, type, dropdownType, section) => {	
		event.stopPropagation();

		let clickedChild = []
		if(type === 'child') {
            if(state.clickedChild && state.clickedChild.length) {
                state.clickedChild.forEach(row => {
                    setState(prevState => ({ ...prevState, [row]: false }))
                })
            }
            clickedChild.push(section)
            setState(prevState => ({ ...prevState, [section]: dropdownType === "singleDropDown" && state[section] ? false : true, clickedChild }))
        } else if(type === 'parent') {
            if(state.clickedChild && state.clickedChild.length) {
                state.clickedChild.forEach(row => {
                    setState(prevState => ({ ...prevState, [row]: false }))
                })
            }
            
            setState(prevState => ({ ...prevState, clickedChild }))
        }
	}

    return (
        <div className='mt-2 p-3 bg-dark3' onClick={(event) => handleChildClick(event, 'parent')}>
            <p className="text-white mb-2">{state.selectedOption}</p>
            <div className="flex flex-wrap mb-3">
                <div className='w-2/5'>
                    <div onClick={(event) => {
                        if(!state.showTagKey) {
                            event.preventDefault();
                            handleChildClick(event, 'child', 'singleDropDown', 'showTagKey')
                        }
                    }}>
                        <p className={`flex bg-transparent border border-lightGray rounded-md px-2 py-1 justify-between truncate ${state.tag_key ? 'text-white' : 'text-lightGray'}`}>
                            {state.tag_key ? state.tag_key : 'Select'}
                            <Icon icon="icon-park-solid:down-one" className={`${state.tag_key ? "text-white" : "text-lightGray"} self-center`} width="16" height="16" />
                        </p>
                        {state.showTagKey ?
                            <MultiSelectSection 
                                // fields={["account_id", "account_name"]}
                                // className={}
                                removeTop={true}
                                options={tagKeys ? tagKeys : []}
                                selectedValues={state.tag_key ? state.tag_key : ''}
                                callbackMultiSelect={(value) => {
                                    if(!value || typeof(value) === 'string') {
                                        setState(prevState => ({ ...prevState, tag_key: value }))
                                    } else {
                                        value.preventDefault()
                                        handleChildClick(value, "search", 'singleDropDown', "")
                                    }
                                }}
                                singleSelection={true}
                                widthClass={'minWidth220'}
                                removeTopOptions={true}
                            />
                        : null}
                    </div>
                </div>
                <div className='w-2/5 pl-3'>
                    {state.tag_key && !state.tagValues ?
                        <svg className="animate-spin h-5 w-5 mr-2 text-white" fill="currentColor" viewBox="0 0 24 24">
                            <circle cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="2" fill="none" />
                            <path d="M4 12a8 8 0 0112-6.9" />
                        </svg>
                    :
                        <div onClick={(event) => {
                            event.preventDefault();
                            handleChildClick(event, 'child', 'muliselectDropDown', "showSecurityGraoupDropdown")
                        }}>
                            <p className={`flex bg-transparent border border-lightGray rounded-md px-2 py-1 justify-between truncate ${state.tag_value && state.tag_value.length ? 'text-white' : 'text-lightGray'}`}>{state.tag_value && state.tag_value.length ? state.tag_value.length+' Selected' : 'Select'}
                            <Icon icon="icon-park-solid:down-one" className={`${state.tag_value && state.tag_value.length ? "text-white" : "text-lightGray"} self-center`} width="16" height="16" />
                            </p>
                            {state.showSecurityGraoupDropdown ?
                                <MultiSelectSection
                                    removeTop={true}
                                    options={state.tagValues ? state.tagValues : []}
                                    selectedValues={state.tag_value ? state.tag_value : []}
                                    callbackMultiSelect={(value) => {
                                        setState(prevState => ({ ...prevState, tag_value: value }))
                                    }}
                                    widthClass={'minWidth220'}
                                    removeTopOptions={true}
                                />
                            : null}
                        </div>
                    }
                </div>
                {state.tag_key && state.tagValues && state.tagValues.length ?
                    <Icon icon="iconoir:plus" width="25" height="25"  className='text-white ml-3 cursor-pointer self-center' onClick={() => addNewTag()} />
                : null}
            </div>
            
            {state.selectedFilters && state.selectedFilters.length ? 
                <div className='flex flex-wrap'>
                {state.selectedFilters.map(tag => {
                    return(
                        <span className="flex bg-mediumDarkGray px-2 py-1 rounded-full mr-2 mt-2 self-center text-white">{tag.key +" : "+tag.value}
                            <Icon icon="ion:close-circle-sharp" width="16" height="16" className='text-white ml-2 self-center' onClick={ () => removeTagSection(tag) } />
                        </span>
                    )
                })}
                </div>
            : null}
            
            <div className='flex justify-end mt-2 mb-3'>
                <button className={`cursor-pointer btn bg-info px-2 py-1 rounded-md text-white mr-3`} onClick={ () => applyFilter()}>Apply</button>
                <button className={`cursor-pointer btn bg-lightGray px-2 py-1 rounded-md text-white`} onClick={ () => closeFilter()}>Cancel</button>
            </div>
        </div>
    );
}
export default TagsSection