/*************************************************
 * Collider
 * @exports
 * @file aiopsReducer.js
 * @author Prakash // on 05/02/2023
 * @copyright © 2023 Collider. All rights reserved.
 *************************************************/
import { ACTION_TYPES } from '../../actions/types'

const INITIAL_STATE = {
    costPropsDetails: {},
    listDepartments: [],
    departmentInitiativeProjectsAccounts: [],
    cloudCommitVsSpend: {},
    currentCloudCommitSpendObservations: {},
    costQueryResult: {},
}

/**
 * To store assets list in redux store
 * @param {Object} state
 * @param {Object} action
 */
export const costReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
        case ACTION_TYPES.SET_COST_PROPS_DETAILS:
            return {
                ...state,
                costPropsDetails: { ...action.payload },
            }
        case ACTION_TYPES.SET_COST_TAG_KEYS:
            return {
                ...state,
                costTagKeys: [ ...action.payload],
            }
        case ACTION_TYPES.SET_LIST_DEPARTMENT:
            return {
                ...state,
                listDepartments: [ ...action.payload],
            }
        case ACTION_TYPES.SET_LIST_DEPARTMENTS_INITIATIVES_PROJECTS_ACCOUNTS:
            return {
                ...state,
                departmentInitiativeProjectsAccounts: [ ...action.payload],
            }
        case ACTION_TYPES.SET_CLOUD_COMMITMENT_VS_SPEND:
            return {
                ...state,
                cloudCommitVsSpend: { ...action.payload},
            }
        case ACTION_TYPES.SET_GET_OBSERVATIONS:
            return {
                ...state,
                currentCloudCommitSpendObservations: { ...action.payload},
            }
        case ACTION_TYPES.SET_COST_QUERY_RESULT:
            return {
                ...state,
                costQueryResult: { ...action.payload},
            }
		default:
			return state
	}
}
