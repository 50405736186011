/*************************************************
 * Kiosk
 * @exports
 * @file Api.js
 * @author Prakash // on 19/12/2019
 * @copyright © 2019 Kiosk. All rights reserved.
 *************************************************/
import axios from 'axios';
import { msalConfig, tokenRequest } from '../authConfig'
import { PublicClientApplication } from "@azure/msal-browser";
import { KIOSK_END_POJNT } from '../config'
import { momentDateFormat, subMinutes } from '../utils/utility';
// import { momentDateFormat } from '../utils/utility';
// import { apiConfigScopes } from '../apiConfig';

const msalInstance = new PublicClientApplication(msalConfig);

let tokenAcquired = false;

// Define an asynchronous function to initialize MSAL and acquire the token
const initializeMsal = async () => {
    try {
        await msalInstance.initialize();
        const account = msalInstance.getAllAccounts()[0];
        if(account.idTokenClaims) {
            if(account.idTokenClaims.exp) {
                let date = new Date(account.idTokenClaims.exp * 1000); // Convert seconds to milliseconds
                if(subMinutes(momentDateFormat(date,'YYYY-MM-DD HH:mm:ss'), 10) < momentDateFormat(new Date(), 'YYYY-MM-DD HH:mm:ss')) {
                    tokenAcquired = false 
                } else if(localStorage.getItem('ACCESS_TOKEN')){
                    tokenAcquired = true
                }
            }
        }

        if (!tokenAcquired && account) {
            let accessTokenRequest = tokenRequest
            accessTokenRequest.account = msalInstance.getAllAccounts()[0];
            const accessTokenResponse = await msalInstance.acquireTokenSilent(accessTokenRequest);
            const accessToken = accessTokenResponse?.idToken;
            const expiresOn = accessTokenResponse?.expiresOn;
    
            localStorage.setItem('ACCESS_TOKEN', accessToken ?? '');
            localStorage.setItem('ACCESS_TOKEN_EXPIRES_ON', expiresOn ?? '');
            tokenAcquired = true;

            return accessToken
    
        } else {
            return localStorage.getItem('ACCESS_TOKEN')
        }
    } catch (error) {
        console.error('MSAL initialization error:', error);
    }
}

const ApiCalls = axios.create({ 
    baseURL: KIOSK_END_POJNT 
}) // Replace with your API's base URL });

ApiCalls.interceptors.request.use(async (config) => {
    config.headers['Content-Type'] = 'application/json'
    let accessToken = await initializeMsal()
    config.headers.Authorization = `Bearer ${accessToken}`;
    return config
})

export default ApiCalls;