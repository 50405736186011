import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useDispatch } from 'react-redux';
 import { Store as CommonNotification } from 'react-notifications-component';
// import { Spinner } from 'reactstrap';
import { capitalizeFirstLetter, getDayFromSelectedDuration, momentConvertionLocalToUtcTime, momentConvertionUtcToLocalTime, momentDateGivenFormat, subDays, subHours, capitalizeTheFirstLetterOfEachWord, removeUnderScore, isValidDateTime, convertToLowerCase } from '../../../utils/utility';
import { getWorkStationMetrics, listStackEvents, applyWorkstationActions } from '../../../actions/CatalogAction';
import Select from 'react-select'
import MetricsTab from '../../common/Mertics/MetricsTab';
import Lifecycle from './Lifecycle'
import Search from '../../common/SearchWithHiddenInput'
import PeakDipMetrics from './PeakDipMetrics'
import {Icon} from "@iconify/react";

let monitoringDurationOptions = [
	{value: "2h", label: "Last 2 Hrs"},
	{value: "6h", label: "Last 6 Hrs"},
	{value: "12h", label: "Last 12 Hrs"},
	{value: "24h", label: "Last 24 Hrs"},
	{value: "7d", label: "Last 7 Days"},
	{value: "14d", label: "Last 14 Days"},
	{value: "30d", label: "Last Month"},
	// {value: "60d", label: "Last 2 Months"},
	// {value: "90d", label: "Last 3 Months"}
]


const WorkstationSidePanel = ({ selectedDetails, closeSidePanel, selectedAction, detailsFormat, showOnlyDeatils }) => {
    const dispatch = useDispatch(); // Create a dispatch function
    const clickOut = useRef();
    
    const [state, setState] = useState({
        showLoading: false,
        selectedTab: "Details",
        logsList: [],
        filteredLogsList: [],

        startRecord: 0, 
        currentPage: 1,
        totalPages: 1,
        perPage: 20,
        selectedGraphDuration: "2h"
    })
    
    const handleKeyDown = useCallback((event) => {
        if (event.key === 'Escape' || event.keyCode === 27) {
            closeSidePanel();
        }
    }, [closeSidePanel])
    
    useEffect(() => {
        // Add event listener for keydown
        window.addEventListener('keydown', handleKeyDown);
    
        // Cleanup event listener on component unmount
        return () => {
          window.removeEventListener('keydown', handleKeyDown);
        };
    }, [handleKeyDown])

    useEffect(() => {
        setState(prevState => ({ ...prevState, instance_type : selectedDetails.instance_type }))
        if(selectedDetails && selectedDetails.volume_details && Array.isArray(selectedDetails.volume_details)) {
            selectedDetails.volume_details.forEach(row => {
                setState(prevState => ({ ...prevState, ["volume_size_"+row.volume_id] : row.volume_size }))
            })
        }
    }, [selectedDetails])

    useEffect(() => {
        if (state.selectedTab === "Monitoring") {
            let duration = state.selectedGraphDuration

            let period = duration.substring(duration.length, duration.length - 1);
            let durationBtw = getDayFromSelectedDuration(duration);
            let startTime = '';
            if (period === 'd') {
                startTime = momentConvertionLocalToUtcTime(subDays(new Date(), durationBtw), 'YYYY-MM-DD HH:mm:ss');
            } else {
                startTime = momentConvertionLocalToUtcTime(subHours(new Date(), durationBtw), 'YYYY-MM-DD HH:mm:ss');
            }
            let endTime = momentConvertionLocalToUtcTime(new Date(), 'YYYY-MM-DD HH:mm:ss');

            let params = {}
            // params.start_time = asset_id && asset_id.start_time
            // params.end_time = asset_id && asset_id.end_time
            params.duration = duration
            params.asset_id = selectedDetails && selectedDetails.asset_id

            // Fetch job metrics data here using params and update the state
            if(params.asset_id) {
                dispatch(getWorkStationMetrics(params))
                    .then((response) => {
                        if(response) {
                            setState(prevState => ({
                                ...prevState,
                                metricDetails: response,
                                showMetricLoading: false,
                                startTime,
                                endTime
                            }));
                        }
                    })
            } else {
                setState(prevState => ({
                    ...prevState,
                    metricDetails: [],
                    showMetricLoading: false,
                    startTime,
                    endTime
                }))
            }
        } else if (state.selectedTab === "Events") {
            let params = {}
            // params.start_time = asset_id && asset_id.start_time
            // params.end_time = asset_id && asset_id.end_time
            // params.duration = "2h"
            params.workstation_id = selectedDetails && selectedDetails.workstation_id
            // Fetch job metrics data here using params and update the state
            if(params.workstation_id) {
                dispatch(listStackEvents(params))
                    .then((response) => {
                        if(response) {
                            let results = response.results ? response.results : []
                            setState(prevState => ({
                                ...prevState,
                                listEvents: results,
                                filteredListEvents: results,
                                showEventsLoading: false,
                            }));
                        }
                    })
            } else {
                setState(prevState => ({
                    ...prevState,
                    listEvents: [],
                    filteredListEvents: [],
                    showEventsLoading: false,
                }))
            }
        }
    }, [state.selectedTab, dispatch, selectedDetails, state.selectedGraphDuration])

    // Function to navigate between pages
    const navigatePage = (action, currentPage) => {
        // Update startRecord and currentPage based on action
        let startRecord = state.startRecord
        if(action === 'next' && currentPage !== state.totalPages) {
            startRecord = startRecord + state.perPage;
            setState(prevState => ({ ...prevState, currentPage: currentPage+ 1 }));
        } else if(action === 'previous' && currentPage !== 0) {
            setState(prevState => ({ ...prevState, currentPage: currentPage - 1 }));
            startRecord = startRecord - state.perPage;
        } else if(action === 'start' && currentPage !== 0) {
            setState(prevState => ({ ...prevState, currentPage: 1 }));
            startRecord = 0;
        } else if(action === 'end' && currentPage !== 0) {
            setState(prevState => ({ ...prevState, currentPage: state.totalPages }));
            startRecord = (state.totalPages - 1) * state.perPage;
        }
        setState(prevState => ({ ...prevState, startRecord }))
    }

    // const gotoPage = (pageNumber) => {
    //     // Go to the specified page and update state        
    //     if(pageNumber > 0  && pageNumber <= state.totalPages) {
    //         let startRecord = (pageNumber - 1) * state.perPage
    //         setState(prevState => ({ ...prevState, currentPage: pageNumber, pageNumber, startRecord }))
    //     } else  if(pageNumber > state.totalPages) {
    //         setState(prevState => ({ ...prevState, pageNumber: state.currentPage }))
    //     } else {
    //         setState(prevState => ({ ...prevState, pageNumber }))
    //     }
    // }

    const actionFunction = (action) => {
        if(action === 'delete') {
            action = 'unsubscribe'
        }
        
        let params = {}
        params.workstation_id = selectedDetails.workstation_id
        // if(selectedAction === "stop & ")
        params.action = action
        if(selectedAction === "modify") {
            params.instance_type = state.instance_type ? state.instance_type : (selectedDetails && selectedDetails.instance_type)
            let volume_details = []
            if(selectedDetails && selectedDetails.volume_details && Array.isArray(selectedDetails.volume_details)) {
                selectedDetails.volume_details.forEach((row, vIndex) => {
                    let dataRow = {}
                    dataRow.volume_id = row.volume_id
                    dataRow.volume_size = state["volume_size_"+vIndex] ? parseInt(state["volume_size_"+vIndex]) : (row.volume_size ? parseInt(row.volume_size) : 0 )
                    volume_details.push(dataRow)
                })
            }
            params.volume_details = volume_details
        }
        
        if(params.workstation_id) {
            dispatch(applyWorkstationActions(params)).then((response) => {
                setState(prevState => ({ ...prevState, showActionLoading: false, showUpdateActionLoading: false }))
                let messageType = 'success'
                let message = response.message
                if(!response.status) {   
                    messageType = 'danger'  
                } else {
                    setState(prevState => ({ ...prevState, hideActionBtn: true }))
                    setTimeout(() => closeSidePanel('refresh'), 5000);
                }
                // setState(prevState => ({ ...prevState, saveLoading: false, message, messageType, createErrorMessage }))

                CommonNotification.addNotification({
                    //title: 'Wonderful!',
                    message: message,
                    type: messageType,
                    insert: 'top',
                    container: 'top-center',
                    // animationIn: ['animate__animated', 'animate__fadeIn'],
                    // animationOut: ['animate__animated', 'animate__fadeOut'],
                    dismiss: {
                        duration: 5000,
                        onScreen: false,
                        pauseOnHover: true,
                        showIcon: true,
                    }
                });
            })
        }
    }

    const handleInputChange = (label, value) => {
        setState(prevState => ({ ...prevState, [label]: value }))        
	}

    const handleChildClick = (event) => {
        if (clickOut.current && !clickOut.current.contains(event.target)) {
            closeSidePanel()
        }
    }
    
    return (
        <div className='advanced-search' style={{ zIndex: 999 }} onClick={(event) => handleChildClick(event, 'parent')}>
            <div className={`${state.showLoading ? '' : 'hidden'}`}>
                <div className='loading-wave'>
                    <div className='overlayEqualizerLoader'>
                        <div className="loading-bar"></div>
                        <div className="loading-bar"></div>
                        <div className="loading-bar"></div>
                        <div className="loading-bar"></div>
                    </div>
                </div>
            </div>
            <div className='search w-4/5 bg-muted min-h-screen overflow-auto' ref={clickOut}>
                <div className='header-search bg-black flex justify-between p-2'>
                    <div className='w-full'>
                        <div className='flex flex-wrap'>
                            <div className='text-white lg:w-1/4 w-full self-center'>
                                <span className='font-bold text-xl text-white'>Workstation Details</span>
                            </div>
                            <div className='w-full lg:w-3/4 text-md'>
                                <div className='flex flex-wrap'>
                                    {selectedDetails && selectedDetails.image_name ?
                                        <div className="w-full lg:w-1/2">
                                                <p className='mb-1 text-backGroundGray min-w-[300px]'>Name<span className='text-cyon ml-2'>{selectedDetails.image_name}</span></p>
                                        </div>
                                    : null}
                                    {selectedDetails && selectedDetails.workstation_id ?
                                        <div className="w-full lg:w-1/2 lg:pl-3 pl-0">
                                            <p className='mb-1 text-backGroundGray'>Id<span className='text-cyon ml-2'>{selectedDetails.workstation_id}</span></p>
                                        </div>
                                    : null}
                                    {selectedDetails && selectedDetails.asset_name ?
                                        <div className="w-full lg:w-1/2">                             
                                            <p className='mb-1 text-backGroundGray min-w-[300px]'>Asset <span className='text-cyon ml-2'>{selectedDetails.asset_name}</span> </p>
                                        </div>
                                    : null}
                                    <div className="w-full lg:w-1/2 lg:pl-3 pl-0">
                                        <p className="mb-0 flex text-backGroundGray min-w-[300px]">Resources 
                                            <span className='text-cyon ml-2'>
                                            {selectedDetails && selectedDetails.provider ? selectedDetails.provider.toUpperCase() : ""}
                                            {selectedDetails && selectedDetails.account_id ? (" : ")+selectedDetails.account_id : ""}
                                            {selectedDetails && selectedDetails.region ? (" : ")+selectedDetails.region : ""}
                                            </span>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='self-center'>
                        <Icon icon="jam:close" className='text-white cursor-pointer' width="25" height="25" onClick={() => closeSidePanel()} />
                    </div>
                </div>
                <div className={`px-2`}>
                    <ul className="flex flex-wrap text-sm font-bold text-center">
                        <li className="me-2">
                            <span className={`inline-block py-2 px-4 text-white rounded-t-lg ${state.selectedTab === 'Details' ? ' active border-b-2 border-black7' : 'cursor-pointer'}`} onClick={() => setState(prevState => ({ ...prevState, selectedTab: 'Details' }))}>Details</span>
                        </li>
                        {!showOnlyDeatils ?
                            <React.Fragment>
                            {selectedDetails && selectedDetails.asset_id ?
                                <React.Fragment>
                                <li className="me-2">
                                    <span className={`inline-block py-2 px-4 text-white rounded-t-lg ${state.selectedTab === 'Monitoring' ? ' active border-b-2 border-black7' : 'cursor-pointer'}`} onClick={() => setState(prevState => ({ ...prevState, selectedTab: 'Monitoring', showMetricLoading: true  }))}>Monitoring</span>
                                </li>
                                <li className="me-2">
                                    <span className={`inline-block py-2 px-4 text-white rounded-t-lg ${state.selectedTab === 'Utilization' ? ' active border-b-2 border-black7' : 'cursor-pointer'}`} onClick={() => setState(prevState => ({ ...prevState, selectedTab: 'Utilization', showUtilizationLoading: true  }))}>Utilization</span>
                                </li>
                                <li className="me-2">
                                    <span className={`inline-block py-2 px-4 text-white rounded-t-lg ${state.selectedTab === 'Lifecycle' ? ' active border-b-2 border-black7' : 'cursor-pointer'}`} onClick={() => setState(prevState => ({ ...prevState, selectedTab: 'Lifecycle', showLifecycleLoading: true }))}>Lifecycle</span>
                                </li>
                                </React.Fragment>
                            : null}
                            <li className="me-2">
                                <span className={`inline-block py-2 px-4 text-white rounded-t-lg ${state.selectedTab === 'Events' ? ' active border-b-2 border-black7' : 'cursor-pointer'}`} onClick={() => setState(prevState => ({ ...prevState, selectedTab: 'Events', showEventsLoading: true }))}>Events</span>
                            </li>
                            </React.Fragment>
                        : null}
                    </ul>
                </div>
                {state.selectedTab === "Details" ?
                    <React.Fragment>
                    <div className='m-2 overflow-auto'>
                        <div className="rounded bg-dark3 p-3">
                            {detailsFormat && detailsFormat.length && detailsFormat.map((row, index) => {
                                return(
                                    <div key={'df_'+index} className={`${!index ? 'mt-2' : 'mt-4'}`}>
                                        <p className={`mb-1 text-cyon text-xl font-bold pb-1 border-b border-lightGray`}>{capitalizeTheFirstLetterOfEachWord(row.label)}</p>
                                        <small className='text-lightGray'>{row.description}</small>
                                        {row.fields && row.fields.map((fld, fIndex) => {
                                            return(
                                                <React.Fragment key={'fld_'+fIndex}>
                                                {fld === 'volume_details' ?
                                                    selectedDetails[fld] && Array.isArray(selectedDetails[fld]) && selectedDetails[fld].map((vol, vIndex) => {
                                                        return(
                                                            <React.Fragment key={'key_'+vIndex}>
                                                            {Object.entries(vol).length ?
                                                                Object.entries(vol).map(([key, value], cIndex) => {
                                                                    return(
                                                                        <div key={'vol_'+cIndex} className="flex mt-3">
                                                                            <span className="minWidth220 text-lightGray text-sm self-center">{removeUnderScore(key)}</span>
                                                                            {key === "volume_size" && selectedAction === "modify" ?
                                                                                <input 
                                                                                    className="border border-lightGray rounded-md bg-transparent px-2 py-1 text-white" 
                                                                                    placeholder="Enter job name" 
                                                                                    value={state['volume_size_'+vIndex] || state['volume_size_'+vIndex] === "" ? state['volume_size_'+vIndex] : (value ? value : 0)}
                                                                                    onChange={e => handleInputChange('volume_size_'+vIndex, e.target.value)}
                                                                                    type="text" 
                                                                                    name="job_name"
                                                                                />
                                                                            : typeof value === 'boolean' ?
                                                                                <span className={`text-white`}>{value ? 'True' : 'False'}</span>
                                                                            : isValidDateTime(value) ?
                                                                                <span className="text-white">{momentConvertionUtcToLocalTime(value, "DD MMM YYYY HH:mm")}<span className='ml-2 text-info'>(local time)</span></span>
                                                                            : convertToLowerCase(key) === "status" ?
                                                                                <span className={`self-center border border-mulbery rounded-full px-2 bg-mulbery text-white text-sm break-all`}>{value}</span>
                                                                            : convertToLowerCase(key) === "tags" ?
                                                                                <div className="flex flex-wrap gap-2">
                                                                                    {value.map((item, vIndex) => {
                                                                                        return(
                                                                                            <span key={'bad_'+vIndex} className='border border-cyon bg-cyon px-2 rounded-full py-1 text-white mb-1 text-sm break-all'>{item.key +' : '+item.value}</span>
                                                                                        )
                                                                                    })}
                                                                                </div>
                                                                            : Array.isArray(value) ?
                                                                                <div className="flex flex-wrap">
                                                                                    {value.map((item, aInex) => {
                                                                                        return(
                                                                                            <React.Fragment key={'kval_'+aInex}>
                                                                                            {typeof item === 'string' ?
                                                                                                <span className='border border-cyon bg-cyon px-2 rounded-full py-1 text-white mr-2 mb-1 text-sm'>{item}</span>
                                                                                            : typeof item === 'object' ?
                                                                                                Object.entries(item).map(([iKey, iValue], ikIndex) => {
                                                                                                    return(
                                                                                                    <span key={'kobj_'+ikIndex} className='border border-cyon bg-cyon px-2 rounded-full py-1 text-white mr-2 mb-1 text-sm break-all'>
                                                                                                        {iKey+' '+iValue}
                                                                                                    </span>
                                                                                                    )
                                                                                                })
                                                                                            : null}
                                                                                            </React.Fragment>
                                                                                        )
                                                                                    })}
                                                                                </div>
                                                                            : 
                                                                                <span className="text-white break-all">{key === 'provider' ? value.toUpperCase() : value}</span>
                                                                            }
                                                                        </div>
                                                                    )
                                                                })
                                                            : null}
                                                            </React.Fragment>
                                                        )
                                                    })
                                                :
                                                    <div className="flex mt-3">
                                                        <span className="minWidth220 text-lightGray text-sm self-center">{removeUnderScore(fld)}</span>
                                                        {selectedDetails && selectedDetails[fld] ?
                                                        <React.Fragment>
                                                        {typeof selectedDetails[fld] === 'boolean' ?
                                                            <span className={`text-white`}>{selectedDetails[fld] ? 'True' : 'False'}</span>
                                                        : isValidDateTime(selectedDetails[fld]) ?
                                                            <span className="text-white">{momentConvertionUtcToLocalTime(selectedDetails[fld], "DD MMM YYYY HH:mm")}<span className='ml-2 text-info'>(local time)</span></span>
                                                        : convertToLowerCase(fld) === "status" ?
                                                            <span className={`self-center border px-2 rounded-full border-outline-${convertToLowerCase(selectedDetails[fld])} === "failed" ? "red" : ${convertToLowerCase(selectedDetails[fld])} === "stopped" ? "yellow" : ${convertToLowerCase(selectedDetails[fld])} === "hibernated" ? "yellow" : "info"} text-${convertToLowerCase(selectedDetails[fld])} === "failed" ? "red" : ${convertToLowerCase(selectedDetails[fld])} === "stopped" ? "yellow" : ${convertToLowerCase(selectedDetails[fld])} === "hibernated" ? "yellow" : "info"}  ml-2`}>{selectedDetails[fld]}</span>
                                                            // <span className={`self-center self-center badge badge-outline-${convertToLowerCase(selectedDetails[fld])}`}>{selectedDetails[fld]}</span>
                                                        : Array.isArray(selectedDetails[fld]) ?
                                                            <div className="flex flex-wrap self-center w-full lg:w-auto">
                                                                {selectedDetails[fld].map((item, dIndex) => {
                                                                    return(
                                                                        <React.Fragment key={'kfld_'+dIndex}>
                                                                        {typeof item === "string" ?
                                                                            <span className="border border-cyon bg-cyon px-2 py-1 rounded-full text-white mr-2 mb-1 text-sm text-nowrap">{item}</span>
                                                                        : 
                                                                            Object.entries(item).map(([key, value], keIndex) => {
                                                                                return(
                                                                                    <span key={'kvFld_'+keIndex} className='border border-cyon bg-cyon px-2 py-1 rounded-full text-white mr-2 mb-1 text-sm break-all'>{key +' : '+value}</span>
                                                                                )
                                                                            })
                                                                        }
                                                                        </React.Fragment>
                                                                    )
                                                                })}
                                                            </div>
                                                        :
                                                            <React.Fragment>
                                                            {fld === "instance_type" && selectedAction === "modify" ?
                                                                <input
                                                                    type='text'
                                                                    placeholder='Enter instance type'
                                                                    className={`border border-lightGray rounded-md bg-transparent px-2 py-1 text-white w-1/5`}
                                                                    value={state.instance_type || state.instance_type === "" ? state.instance_type : (selectedDetails.instance_type ? selectedDetails.instance_type : '')}
                                                                    onChange={e => handleInputChange('instance_type', e.target.value)}
                                                                />
                                                            : 
                                                                <span className="text-white break-all">{selectedDetails[fld]}</span>
                                                            }
                                                            </React.Fragment>
                                                        }
                                                        </React.Fragment>
                                                        : null}
                                                    </div>
                                                }
                                                </React.Fragment>
                                            )
                                        })}
                                    </div>
                                )
                            })}
                        
                            
                            {selectedDetails.installed_softwares && selectedDetails.installed_softwares.length ?
                                typeof selectedDetails.installed_softwares[0] === "string" ?
                                    <div className="flex mt-3">
                                        <span className="text-cyon minWidth220 self-center">Installed Softwares</span>
                                        {selectedDetails.installed_softwares.map((item, soIndex) => {
                                            return(
                                                <span key={'soft_'+soIndex} className='border border-cyon bg-cyon px-2 rounded-full py-1 text-black mr-2 mb-1 text-sm'>{item}</span>
                                            )
                                        })}
                                    </div>
                                :
                                    <React.Fragment>
                                    <p className={`mb-1 text-cyon text-lg pb-1 border-bottom mt-4`}>Installed Softwares</p>
                                    <div className="relative overflow-x-auto mt-2">
                                        <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                                            <thead className="text-md text-white bg-dark2">
                                                <tr>
                                                    <th scope="col" className="px-6 py-3">Software Name</th>
                                                    <th scope="col" className="px-6 py-3">Version</th>
                                                </tr>
                                            </thead>
                                            <tbody className='text-black bg-white'>
                                                {selectedDetails.installed_softwares && selectedDetails.installed_softwares.length ?
                                                    selectedDetails.installed_softwares.map((row, sftIndex)=> {
                                                        return(
                                                            <tr key={'sft_'+sftIndex} className={`bg-white border-b dark:bg-gray-800 dark:border-gray-700`}>
                                                            {Object.entries(row).map(([key, value], sfinIndex) => {
                                                                return(
                                                                    <td className="px-6 py-3 text-sm" key={'sftin_'+sfinIndex}>{value}</td>
                                                                )
                                                            })}
                                                            </tr>
                                                        )
                                                    })
                                                : null}
                                            </tbody>
                                        </table>
                                    </div>
                                    </React.Fragment>
                            : null}
                        </div>
                    </div>
                    {selectedAction ?
                        <div className='flex justify-end mr-3 mb-5'>
                            {selectedAction === "modify" ?
                                <React.Fragment>
                                    {state.showUpdateActionLoading || state.showActionLoading ?
                                        <button className={`flex px-2 py-1 rounded-md bg-cyon text-white mr-2`}>
                                            {state.showUpdateActionLoading ?
                                                <svg className="animate-spin h-5 w-5 mr-2 text-white" fill="currentColor" viewBox="0 0 24 24">
                                                    <circle cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="2" fill="none" />
                                                    <path d="M4 12a8 8 0 0112-6.9" />
                                                </svg>
                                            : null}
                                            Stop & Update
                                        </button>
                                    :
                                        !state.hideAction?
                                            <button className={`px-2 py-1 rounded-md bg-cyon text-white mr-2`} onClick={() => {
                                                setState(prevState => ({ ...prevState, showActionLoading: false, showUpdateActionLoading: true, hideActionBtn: false }))
                                                actionFunction("stop/update")
                                            }}>
                                                Stop & Update
                                            </button>
                                        : null
                                    }
                                    {state.showUpdateActionLoading || state.showActionLoading ? 
                                        <button className={`flex px-2 py-1 rounded-md bg-green text-white`}>
                                            {state.showActionLoading ? 
                                                <svg className="animate-spin h-5 w-5 mr-2 text-white" fill="currentColor" viewBox="0 0 24 24">
                                                    <circle cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="2" fill="none" />
                                                    <path d="M4 12a8 8 0 0112-6.9" />
                                                </svg>
                                            : null}
                                            Updating
                                        </button>
                                    :
                                        !state.hideActionBtn ?
                                            <button className={`px-2 py-1 rounded-md bg-green text-white`} onClick={() => {
                                                setState(prevState => ({ ...prevState, showActionLoading: true, showUpdateActionLoading: false, hideActionBtn: false }))
                                                actionFunction("update")
                                            }}>
                                                Update
                                            </button>
                                        : null
                                    }
                                </React.Fragment>
                            
                            :            
                                state.showActionLoading ?                 
                                    <button className={`px-2 py-1 rounded-md bg-${selectedAction === 'Delete' ? 'danger' : 'green'} text-white`}>
                                        <React.Fragment>
                                        {state.showActionLoading ?
                                            <svg className="animate-spin h-5 w-5 mr-2 text-white" fill="currentColor" viewBox="0 0 24 24">
                                                <circle cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="2" fill="none" />
                                                <path d="M4 12a8 8 0 0112-6.9" />
                                            </svg>
                                        : null}
                                        {capitalizeFirstLetter(selectedAction)}
                                        </React.Fragment>
                                    </button>
                                : 
                                    !state.hideActionBtn ?
                                        <button className={`px-2 py-1 rounded-md bg-${selectedAction === 'Delete' ? 'danger' : 'green'} text-white`} onClick={() => {
                                            setState(prevState => ({ ...prevState, showActionLoading: true, hideActionBtn: false }))
                                            actionFunction(selectedAction)
                                        }}>
                                            {capitalizeFirstLetter(selectedAction)}
                                        </button>     
                                    : null
                                
                            }
                            <button className={`px-2 py-1 rounded-md bg-lightGray text-white ml-2`} onClick={() => closeSidePanel()}>Cancel</button>
                        </div>
                    : null}
                    </React.Fragment>
                : state.selectedTab === "Monitoring" ?
                    <div className='m-2 flex-grow-1 overflow-auto vh100'>
                        <div className='flex justify-between mb-1'>
                            <p className='mb-1 self-center'>Showing data for 
                                <span className='text-cyon ml-1'>
                                    {state.endTime && state.startTime ?
                                        momentDateGivenFormat(state.startTime, 'DD MMM YYYY HH:mm') +' - '+ momentDateGivenFormat(state.endTime, 'DD MMM YYYY HH:mm')
                                    : null}
                                </span>
                                <span className='ml-2'>(local time)</span>
                            </p>
                            <div className="flex">
                                <label className='mb-0 mr-2 self-center text-white'>Duration</label>
                                <div style={{ minWidth: "130px" }}>
                                    <Select
                                        placeholder={'Select'}
                                        isSearchable={false}
                                        className='f13 p-0 colorBlack colorDark3'
                                        components={{
                                            IndicatorSeparator: () => null,
                                        }}
                                        value={{
                                            value: state.selectedGraphDuration ? state.selectedGraphDuration : 'Select',
                                            label: state.selectedGraphDuration ? monitoringDurationOptions.filter(e => e.value === state.selectedGraphDuration)[0].label : <span className='placeholder'>Select</span>
                                        }}
                                        options={monitoringDurationOptions && monitoringDurationOptions.map(row => ({
                                            value: row.value,
                                            label: row.label,
                                        }))}
                                        onChange={event => {
                                            setState(prevState => ({ ...prevState, metricDetails: [], showMetricLoading: true, selectedGraphDuration: event.value }))
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                        {state.showMetricLoading ?
                            <div className="w-full flex justify-center mt-4">
                                <svg className="animate-spin h-5 w-5 mr-3 text-white" fill="currentColor" viewBox="0 0 24 24">
                                    <circle cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="2" fill="none" />
                                    <path d="M4 12a8 8 0 0112-6.9" />
                                </svg>
                            </div>
                        : state.metricDetails ?
                            <MetricsTab
                                metricDetails={state.metricDetails}
                                selectedDetails={selectedDetails}
                                height={175}
                                showStatistics={["min", "avg", "max"]}
                            />
                        : null}
                    </div>
                : state.selectedTab === "Events" ?
                    <div className='m-2 flex-grow-1 overflow-auto vh100'>
                        {state.showEventsLoading ?
                            <div className="w-full flex justify-center mt-4">
                                <svg className="animate-spin h-5 w-5 text-white" fill="currentColor" viewBox="0 0 24 24">
                                    <circle cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="2" fill="none" />
                                    <path d="M4 12a8 8 0 0112-6.9" />
                                </svg>
                            </div>
                        : state.listEvents && state.listEvents.length ?
                            <div className='bg-dark3 p-2 mx-2 rounded'>
                                <div className="flex mb-2 justify-between">
                                    <div className="flex mr-2 w-70">
                                        <p className="text-xs m-0 self-center pt-1">Showing {state.filteredListEvents && state.filteredListEvents.length} of total {state.listEvents && state.listEvents.length} event(s)</p>
                                    </div>
                                    <div className="flex justify-end w-1/3 mb-n2">
                                        {state.listEvents && state.listEvents.length ?
                                            <Search
                                                data={state.listEvents ? state.listEvents : []}
                                                applyTags={false}
                                                applyLiteDarkTags={true}
                                                topClassName={'bg-transparent self-center text-white border border-lightGray'}
                                                searchClassName={'px-2'}
                                                searchIconColor={'text-white '}
                                                searchPlaceHolder={'Search....'}
                                                className={'bg-transparent text-white'}
                                                widthHeight={25}
                                                // applyTags={false}
                                                // applyLiteDarkTags={true}
                                                // topClassName={'bg-transparent self-center w-100 mr-1 border f12'}
                                                // searchClassName={'px-2 f12'}
                                                // searchIconColor={'text-lightGray f12'}
                                                // searchPlaceHolder={'Search....'}
                                                // className={"bg-transparent text-white pl-0 form-control-sm f12"}
                                                filteredData={(filteredListEvents) => {
                                                    let totalPages = 1
                                                    if(filteredListEvents.length > state.perPage) {
                                                        totalPages = Math.ceil(filteredListEvents.length / state.perPage)
                                                    }
                                                    setState(prevState => ({ 
                                                        ...prevState,  
                                                        // refreshClusters: true,
                                                        filteredListEvents,
                                                        totalPages
                                                    }))
                                                    // setState(prevState => ({ ...prevState, filteredListEvents }))
                                                }}
                                            />
                                        : null}
                                        {state.filteredListEvents && state.filteredListEvents.length > state.perPage ?
                                            <div className="pagination hidden">
                                                <span className="mx-3">Page <strong>{state.currentPage} of {state.totalPages}</strong> </span>
                                                <button><i className={`fal fa-arrow-to-left cursor-pointer ${state.currentPage === 1 ? 'disabled text-muted' : 'text-info'}`} onClick={() => navigatePage('start', state.currentPage)}></i></button> 
                                                <button><i className={`fal fa-angle-left cursor-pointer ${state.currentPage === 1 ? 'disabled text-muted' : 'text-info'}`} onClick={() => navigatePage('previous', state.currentPage)}></i></button> 
                                                {/* <span>
                                                    Go to
                                                    <input
                                                        type='number'
                                                        value={state.pageNumber || state.pageNumber === "" ? state.pageNumber : state.currentPage}
                                                        onChange={e => {
                                                            const page = e.target.value ? Number(e.target.value) : ""
                                                            gotoPage(page)
                                                        }}
                                                        className="ml-1 inputClass"
                                                    />
                                                </span> */}
                                                <button><i className={`fal fa-angle-right cursor-pointer ${state.currentPage === state.totalPages ? 'disabled text-muted' : 'text-info'}`} onClick={() => navigatePage('next', state.currentPage)}></i></button> 
                                                <button><i className={`fal fa-arrow-to-right cursor-pointer ${state.currentPage === state.totalPages ? 'disabled text-muted' : 'text-info'}`} onClick={() => navigatePage('end', state.currentPage)}></i></button>
                                            </div>
                                        : null}
                                    </div>
                                </div>
                                {state.filteredListEvents && state.filteredListEvents.length ?
                                    state.filteredListEvents.slice(state.startRecord, state.startRecord + state.perPage).map((item, i) => {
                                        return(
                                            <div key={"fil_"+i} className={`px-2 pt-2 pb-1 ${!i ? "" : "border-t border-darkGray"}`}> 
                                                <div className="flex cursor-pointer text-wrap">
                                                    <p className="mb-0">
                                                        <span className="mb-0 mr-2 text-white text-sm font-weight-bold">{item.timestamp ? momentConvertionUtcToLocalTime(item.timestamp, 'DD MMM YYYY HH:mm') : ""} </span>
                                                        {item.resource_type ?
                                                            <span className={`mb-0 mr-2 text-sm ml-2`}>{item.resource_type}</span>
                                                        : null}
                                                        {item.resource_status ?
                                                            <span className={`mb-0 mr-2 text-sm text-info ml-2`}>{item.resource_status}</span>
                                                        : null}
                                                        {item.user_id ?
                                                            <span className={`mb-0 mr-2 text-sm ml-2`}>{item.user_id}</span>
                                                        : null}
                                                        {item.resource_status_reason ?
                                                            <span className="mb-0 ml-2 text-sm text-white font-weight-bold">{item.resource_status_reason}</span>
                                                        : null}
                                                    </p>
                                                </div>
                                            </div>
                                        )
                                    })
                                :
                                    <div className='flex justify-center m-4 text-white'>
                                        There are no events on this criteria. Please try adjusting your filter.
                                    </div>
                                }
                            </div>
                        :
                            <div className='flex justify-center m-4 text-white'>
                                Therer are no events on this criteria.
                            </div>
                        }
                    </div>
                : state.selectedTab === "Lifecycle" ?
                    <div className='flex-grow-1 overflow-auto vh100'>
                        <Lifecycle 
                            selectedDetails={selectedDetails}
                        />
                    </div>
                : state.selectedTab === "Utilization" ?
                    <div className='m-2 overflowYAuto vh100'>
                        <div className='p-2'>
                            <PeakDipMetrics 
                                selectedDetails={selectedDetails}
                            />
                        </div>
                    </div>
                :null}
            </div>
        </div>
    );
};

export default WorkstationSidePanel;