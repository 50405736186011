import React, { useEffect, useState} from 'react';
import moment from 'moment'
import { DayPicker } from 'react-day-picker';
import 'react-day-picker/dist/style.css';
import { getDayFromSelectedDuration, momentDateGivenFormat } from '../../../../utils/utility'
import MultiSelectSection from '../../../common/MultiSelectSection';

const DurationFilterSection = (props) => {
    const [state, setState] = useState({
        showDate: false,
        showDatepicker: false,
        // selectedDuration: '+7d',
        inputComponent: props.inputComponent,
        previousCustomDays: moment().subtract('180', 'days'),
    })

    const [selected, setSelected] = useState(null);

    const handleSelect = (date) => {
		setSelected(date)
    }

	useEffect(() => {
		if(state.selectedDuration === 'custom') {
			setState(prevState => ({ ...prevState, from: undefined, to: undefined, showDatepicker: true, showDate: true }))
            setSelected()
		} else {            
            let days = getDayFromSelectedDuration(state.selectedDuration)
			let beginDate = moment().subtract(days, 'days')
			let closeDate = moment()
			//console.log(closeDate._d)
			setState(prevState => ({ ...prevState, from: beginDate._d, to: closeDate._d}))
		}
	}, [state.selectedDuration])

    const applyFilter = () => {
        let obj = {}
        obj['from'] = state.selectedDuration !== 'custom' ? '' :  momentDateGivenFormat(selected.from, "YYYY-MM-DD");
        obj['to'] = state.selectedDuration !== 'custom' ? '' : momentDateGivenFormat(selected.to, "YYYY-MM-DD");
        obj['start_time'] = state.selectedDuration !== 'custom' ? '' :  moment(selected.from).format("YYYY-MM-DD 00:00:00")
		obj['end_time'] = state.selectedDuration !== 'custom' ? '' :  moment(selected.to).format("YYYY-MM-DD 23:59:59")
        obj['selectedDuration'] = state.selectedDuration
        
        return props.selectedInput(obj)
    }
    
    
    const { from, to } = state;
    return (
        <div className='mt-2 p-3 bg-dark3'>
            <p className="text-white mb-2">Duration</p>
            <input type="text" className={`border border-lightGray rounded-md bg-transparent px-2 py-1 text-white`}
                // onChange={e => {setState(prevState => ({ ...prevState, searchText: e.target.value }, () => this.filterOnchange()))}}
                // onKeyUp={this.onKeyUp}
                value={state.selectedDuration && state.inputComponent[0].options.filter(e => e.value === state.selectedDuration).length ? state.inputComponent[0].options.filter(e => e.value === state.selectedDuration)[0].label : ""}
                /*{state.selectedDefaultOption === '' ? state.searchText : state.selectedDefaultOption +': ' } */
                onClick={ () => setState(prevState => ({ ...prevState, showDurationDropDown: true,   }))}
                placeholder={"Select Duration"}
            />
            {state.showDurationDropDown ?
                <div className='top80'>
                    <MultiSelectSection
                        fields={["value", "label"]}
                        options={state.inputComponent[0] && state.inputComponent[0].options ? state.inputComponent[0] && state.inputComponent[0].options : []}
                        widthClass={'minWidth220'}
                        selectedValues={state.selectedDuration ? state.selectedDuration : ''}
                        callbackMultiSelect={(value) => {
                            if(typeof(value) === 'string') {
                                if(value !== 'custom') {
                                    setState(prevState => ({ ...prevState, showDurationDropDown: false, selectedDuration: value, showDatepicker: false, showDate: false }))
                                } else {
                                    setState(prevState => ({ ...prevState, showDurationDropDown: false, selectedDuration: value, showDatepicker: true, showDate: true }))
                                }
                            } else {
                                // value.preventDefault()
                                // handleChildClick(value, "search", 'singleDropDown', "")
                            }
                        }}
                        singleSelection={true}
                        removeTopOptions={true}
                    />
                </div>
            : null}
            <div className={`flex my-2 ${state.showDate ? '' : 'hidden'}`}>
                <div className="mr-3 mt-2 w-1/5">
                    <p className="text-white mb-2">From Date</p>
                    <input 
                        type="text" 
                        className={`border border-lightGray rounded-md bg-transparent px-2 py-1 text-white`}
                        style={{minHeight: "38px"}}
                        placeholder={"From date"}
                        value={state.from === undefined ? '' :  momentDateGivenFormat(state.from, "DD MMM YYYY")}
                        onClick={ () => setState(prevState => ({ ...prevState, showDatepicker: state.showDatepicker ? false : true }))}
                    />
                </div>
                <div className="mt-2 w-1/5">
                    <p className="text-whtie mb-2">To Date</p>
                    <input 
                        type="text" 
                        className={`border border-lightGray rounded-md bg-transparent px-2 py-1 text-white`}
                        style={{minHeight: "38px"}}
                        placeholder={"To date"}
                        value={state.to === undefined ? '' :  momentDateGivenFormat(state.to, "DD MMM YYYY")}
                        onClick={ () => setState(prevState => ({ ...prevState, showDatepicker: state.showDatepicker ? false : true }))}
                    />
                </div>
                {/* <div className={`mr-1 align-self-center ${state.from !== undefined || state.to !== undefined ? '' : 'displayNone'} `}>
                    <label className="text-white m-0 w-100">&nbsp;</label>
                    <p className="mb-0 text-cyon mt-3 ml-4" onClick={handleResetClick}>Reset</p>
                </div> */}
            </div>
            
            {state.showDatepicker ?
                <div className='absolute bg-white border border-lightGray z-10'>
                    <DayPicker
                        mode={'range'}
                        // toMonth={new Date()}
                        disabled={[{
                            after: new Date(),
                            // before: (new Date(state.previousCustomDays))
                        }]}
                        // className="Selectable"
                        numberOfMonths= {2}																			
                        selected={selected}
                        // onSelect={setSelected}
                        onSelect={handleSelect}
                        // tabIndex={0}
                    />								
                    <div className="flex mx-2 justify-end">
                        <button className={`btn px-2 py-1 bg-info rounded-md mr-3 mb-0 text-white mb-1 ${selected && selected.from && selected.to ? 'cursor-pointer' : 'pointer-events-none'}`} onClick={() => {
                            setState(prevState => ({ ...prevState, from: selected.from, to: selected.to, showDatepicker: false }))
                            applyFilter()
                        }}>Apply</button>
                        <p className="mb-0 text-info mb-1 self-center" onClick={() => {
                            setSelected()
                            props.selectedInput(false)
                        }}>Reset</p>
                    </div>
                </div>
            : 
                <div className="flex mx-2 justify-end">
                    <button className={`btn px-2 py-1 bg-info rounded-md mr-3 mb-0 text-white mb-1 ${state.selectedDuration ? 'cursor-pointer' : 'pointer-events-none'}`} onClick={() => {
                        setState(prevState => ({ ...prevState, showDatepicker: false }))
                        applyFilter()
                    }}>Apply</button>
                    <p className="mb-0 text-info mb-1 self-center" onClick={() => {
                        setSelected()
                        props.selectedInput(false)
                    }}>Reset</p>
                </div>
            }
            
        </div>
    );
}

export default DurationFilterSection;
