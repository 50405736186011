/* eslint-disable no-mixed-spaces-and-tabs */
/*************************************************
 * Collider
 * @exports
 * @file DetailsSection.js
 * @author Rajasekar // on 09/06/2023
 * @copyright © 2019 Collider. All rights reserved.
 *************************************************/
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import ApexChart from 'react-apexcharts'
import _ from 'lodash'
import ResizeableDarkThemeTable from '../../designComponents/Table/ResizeableDarkThemeTable'

import { UncontrolledTooltip } from 'reactstrap'
import { trimmedSplitUserName1, trimmedSplitUserName2, momentConvertionUtcToLocalTime } from '../../../utils/utility'
import ViewAssetDeailSidePanel from './ViewAssetDeailSidePanel'

class DetailsSection extends Component {
	constructor(props) {
		super(props)

		this.tabRef = React.createRef()

		this.state = {
			showRelatedIssues: false,
			showEvents: false,
			tabItemsToShow: 3,
		}
	}

	componentDidMount = () => {
		this.setTabs()
		const _this = this
		window.addEventListener('resize', () => {
			_this.setTabs()
		})
	}

	componentDidUpdate(prevProps) {
		if (prevProps !== this.props) {
			this.setTabs()
			this.setState({ relatedDurtaion: this.props.relatedDurtaion })
			let showAssetDetails = false
			let showEvents = false
			
			if(this.props.selectedTab === 'asset_details') {
				showAssetDetails =  true				
			} else if(this.props.selectedTab === 'events') {
				showEvents = true
			}
			this.setState({ showAssetDetails, showEvents })
		}
	}

	setTabs = () => {
		if (this.tabRef.current) {
			const maxTabWidth = this.tabRef.current.clientWidth - 40
			let reqTabWidth = 0
			let allowedTabLength = 0
			for (let i = 0; i < this.tabRef.current.childNodes.length; i++) {
				// myLog('reqqq', this.tabRef.current.childNodes[i].clientWidth)
				reqTabWidth += this.tabRef.current.childNodes[i].clientWidth
				if (reqTabWidth < maxTabWidth) {
					allowedTabLength++
				}
			}
			// myLog(maxTabWidth, reqTabWidth, allowedTabLength)
			this.setState({ tabItemsToShow: allowedTabLength })
		}
	}

	/**
	 * Returns fail count of the related issues
	 */
	getRelatedIssuesFailCount = () => {
		let failCount = 0
		if (this.props.relatedIssuesData && this.props.relatedIssuesData.length) {
			const failedArray = this.props.relatedIssuesData.filter(item => item.status === 'fail')
			failCount = failedArray.length
		}
		return failCount
	}

	/**
	 * Returns pass count of the related issues
	 */
	getRelatedIssuesPassCount = () => {
		let passCount = 0
		if (this.props.relatedIssuesData && this.props.relatedIssuesData.length) {
			const passedArray = this.props.relatedIssuesData.filter(item => item.status === 'pass')
			passCount = passedArray.length
		}
		return passCount
	}

	/**
	 * To find risk factor of a issue
	 */
	findRisk = from => {
		let riskCount = 0		
		if (this.props.relatedIssuesData && this.props.relatedIssuesData.length) {
			let filteredData = this.props.relatedIssuesData
			//filteredData = filteredData.filter(item => item.status !== 'NA')
			let relatedRiskCount = []
			filteredData.length &&
			_.chain(filteredData)
			.groupBy('risk')
			.map((value, key) => ({ label: key, sub: value }))
			.value()
			.forEach((risk, i) => {
				relatedRiskCount.push(risk)
				// risk.sub.forEach((item, i) => {
				// 	relatedRiskCount.push(item)
				// })
			})
			relatedRiskCount.forEach(item => {
				if(item.label === from) {
					riskCount = item.sub.length
				}
			})
			// const highArray = this.props.relatedIssuesData.filter(item => item.risk === from)
			// riskCount = highArray.length
		}
		return riskCount
	}
	/**
	 * Toggles events duration dropdown
	 */
	toggleEventsDropdown = () => {
		this.setState({ isEventsDropdownOpen: !this.state.isEventsDropdownOpen })
	}
	/**
	 * Get total count of billing for last month
	 */
	billingLastMonthCost = () => {
		let cost = 0
		this.props.selectedRowBillingDetails &&
			this.props.selectedRowBillingDetails.length &&
			this.props.selectedRowBillingDetails.forEach(item => {
				if (item.last_month) cost = item.last_month['total-unblended-cost']
			})
		return cost
	}
	/**
	 * Get total count of billing for last three months
	 */
	billingLastThreeMonthCost = () => {
		let cost = 0
		this.props.selectedRowBillingDetails &&
			this.props.selectedRowBillingDetails.length &&
			this.props.selectedRowBillingDetails.forEach(item => {
				if (item.duration) cost = item.duration['total-unblended-cost']
			})
		return cost
	}
	/**
	 * Get total count of billing for current month
	 */
	billingCurrentMonthCost = () => {
		let cost = 0
		this.props.selectedRowBillingDetails &&
			this.props.selectedRowBillingDetails.length &&
			this.props.selectedRowBillingDetails.forEach(item => {
				if (item.current_month) cost = item.current_month['total-unblended-cost']
			})
		return cost
	}

	selectedTableAction = index => {
		this.setState({ selectedTableAction: index})
	}

	handleClickGroup = (val) => {
		this.setState({ selectedTableDrop: this.state.selectedTableDrop === val ? '' : val })
	}

	toggleState = (state) => {
		this.setState(prevState => ({
			[state]: !prevState[state],
		}))
	}
	
	render() {
		return (
			<React.Fragment>
				<div className='bg-black'>
					<div className='px-3 center pt-2 mb-2'>
						<span className='m-0 font-semibold self-center text-white'>Product :</span>
						{Array.isArray(this.props.selectedTableRow.category) ? (
							this.props.selectedTableRow.category.map((item, index) => {
								return (
									<span key={index} className='bg-lightGray px-2 py-1 ml-1 text-white text-xs rounded-full'>
										{item}
									</span>
								)
							})
						) : (
							<span className='bg-lightGray px-2 py-1 ml-1 text-white text-xs rounded-full'>{this.props.selectedTableRow.category}</span>
						)}
					</div>

					{this.state.showAssetDetails && this.props.selectedTableRow && this.props.selectedTableRow.tags && this.props.selectedTableRow.tags.length ? (
						<React.Fragment>
							<p className='px-3 m-0 font-semibold'>Tags :</p>
							<ul className='flex flex-wrap my-1 px-3'>
								{this.props.selectedTableRow.tags.map((tag, index) => {
									const keys = tag.key ? tag.key.split(':') : []
									const values = tag.value ? tag.value.split('-') : []
									delete values[values.length - 1]
									return (
										<li key={index} className='mb-2'>
											<span className='text-white bg-nero rounded-full px-2 py-1'>
												{keys[keys.length - 1]}: {values.length > 1 ? values.join('-') : tag.value}
											</span>
										</li>
									)
								})}
							</ul>
						</React.Fragment>
					) : 
						<p className='px-3 m-0 mb-2 font-semibold'>Tags : <small className="m-0 p-0 text-xs">No Tags are associated for the Resource</small></p>
					}
					<p className='text-info flex justify-end cursor-pointer mx-2 pb-2' onClick={() => this.setState({ showAssetDetailsSidePanel: true })}>View more details</p>
				</div>

				<div className={`mt-2`}>
					<ul className="flex flex-wrap text-sm font-bold text-center pt-2 bg-white rounded-t">
						{this.props.detailsTabNames && this.props.detailsTabNames.length &&
							this.props.detailsTabNames.map((item, index) => {
								if (index < this.state.tabItemsToShow)
									return (
										<li className="me-2 pb-1">
											<span className={`inline-block px-4 font-semibold text-black rounded-t-lg ${this.props.selectedDetailsTab === index ? ' active border-b-2 border-black7' : 'cursor-pointer'}`} onClick={() => {
												if (item.value === 'events') {
													this.props.toggleDetailsSectionLoading('events', true)
													this.props.getAssetEvents(this.props.selectedTableRow, this.state.eventsDuration)
													this.props.setSelectedDetailstabIndex(index)}
												}
											}>
												{item.name}
											</span>
										</li>
									)
								return null
						})}
					</ul>
				</div>
				{this.state.showAssetDetailsSidePanel ?				
					<ViewAssetDeailSidePanel
						closeSidePanel={() => this.setState({ showAssetDetailsSidePanel: false })}
						selectedAsset={this.props.selectedTableRow}
					/>
				: null}
				<div className='tab-content pb-2' id='myTabContent'>
					{this.props.selectedRowAssetDetails.length && !this.props.showDetailsAssetSectionLoading && this.state.showAssetDetails ? (
						<div className={`tab-pane fade show active`}>
							<div class="bg-white rounded-md overflow-hidden mt-3 mx-2">
								<div class="flex items-center justify-center py-1">
									{this.props.selectedTableRow && this.props.selectedTableRow.service_name ?
										<span className="bg-lightGray rounded-lg text-black px-2 py-1 text-wrap mr-2 self-center"> {this.props.selectedTableRow.service_name}</span>
									: null}
									{this.props.selectedTableRow && this.props.selectedTableRow.asset_name ?
										<span>:&nbsp;{this.props.selectedTableRow.asset_name}</span>
									: null}
								</div>
								<div class="flex bg-green items-center justify-center py-1">
									<span class="text-white">Active</span>
								</div>
							</div>
							<div className='m-2 bg-white rounded-md p-2'>
								<p className='text-black flex justify-center font-semibold'>Network</p>
								<div className='flex mt-2'>
									<p className='w-1/3 text-lightGray'>Provider ID</p>
									<p className='minWidth220 text-black font-semibold'>{this.props.selectedRowAssetDetails && this.props.selectedRowAssetDetails.length && this.props.selectedRowAssetDetails[0].provider ? this.props.selectedRowAssetDetails[0].provider.toUpperCase() : ''}</p>
								</div>
								<div className='flex mt-2'>
									<p className='w-1/3 text-lightGray'>Service</p>
									<p className='minWidth220 text-black font-semibold'>{this.props.selectedRowAssetDetails && this.props.selectedRowAssetDetails.length && this.props.selectedRowAssetDetails[0].service_name ? this.props.selectedRowAssetDetails[0].service_name : ''}</p>
								</div>
								<div className='flex mt-2'>
									<p className='w-1/3 text-lightGray'>Resource Type</p>
									<p className='minWidth220 text-black font-semibold'>{this.props.selectedRowAssetDetails && this.props.selectedRowAssetDetails.length && this.props.selectedRowAssetDetails[0].resource_type ? this.props.selectedRowAssetDetails[0].resource_type : ''}</p>
								</div>
								
								<div className='flex mt-2'>
									<p className='w-1/3 text-lightGray'>Vpc ID</p>
									<p className='minWidth220 text-black font-semibold'>{this.props.selectedRowAssetDetails && this.props.selectedRowAssetDetails.length && this.props.selectedRowAssetDetails[0].vpc_id ? this.props.selectedRowAssetDetails[0].vpc_id : ''}</p>
								</div>
							</div>

							{this.props.selectedRowAssetDetails[0].action_by ? 
								<div className='bg-white m-2 p-2 rounded-md'>
									<div className='flex flex-wrap flex-grow-1 fs-13 mb-2'>
										{this.props.selectedRowAssetDetails[0].action_by ? (
											<p className='mb-1 text-black'>Created by 
												<span className='border border-lightGray rounded-full px-2 py-1 text-black mx-2'>{trimmedSplitUserName1(this.props.selectedRowAssetDetails[0].action_by)}</span> 
												<span className="text-cyon mx-2">{trimmedSplitUserName2(this.props.selectedRowAssetDetails[0].action_by)}</span> 
												on <span className="text-lightGray mx-2">{this.props.selectedRowAssetDetails[0].asset_time ? momentConvertionUtcToLocalTime(this.props.selectedRowAssetDetails[0].asset_time, 'DD MMM YYYY HH:mm') : ''}</span>
											</p>
										) : null}
									</div>
									<div className='flex flex-wrap flex-grow-1 fs-13 mb-2 border-t border-lightGray pt-4'>
										{this.props.selectedRowAssetDetails[0].action_by ? (
											<p className='mb-1 text-black'>Modified by 
												<span className='border border-lightGray rounded-full px-2 py-1 text-black mx-2'>{trimmedSplitUserName1(this.props.selectedRowAssetDetails[0].action_by)}</span> 
												<span className="text-cyon mx-2">{trimmedSplitUserName2(this.props.selectedRowAssetDetails[0].action_by)}</span> 
												on <span className="text-lightGray mx-2">{this.props.selectedRowAssetDetails[0].action_date ? momentConvertionUtcToLocalTime(this.props.selectedRowAssetDetails[0].action_date, 'DD MMM YYYY HH:mm') : ''}</span>
											</p>
										) : null}
									</div>
								</div>
							: null
							}
						</div>
					) : this.props.showDetailsAssetSectionLoading &&
					  this.state.showAssetDetails ? (
						<div className='flex justify-center m-4'>
							<svg className="animate-spin h-5 w-5 mr-2 text-black" fill="currentColor" viewBox="0 0 24 24">
								<circle cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="2" fill="none" />
								<path d="M4 12a8 8 0 0112-6.9" />
							</svg>
						</div>
					) : !this.props.showDetailsAssetSectionLoading &&
					  !this.props.selectedRowAssetDetails.length &&
					  this.state.showAssetDetails ? (
						<div className='flex justify-center m-4'>
							<p>No asset details found</p>
						</div>
					) : null}

					{!this.props.showDetailsEventsSectionLoading &&
					this.state.showEvents ? (
						<div className={`tab-pane fade ${this.state.showEvents ? 'show active' : ''}`} id='monitoring'>
							<React.Fragment>
								<div class="bg-white rounded-md overflow-hidden mt-3 mx-2">
									<div class="flex items-center justify-center py-1">
										{this.props.selectedTableRow && (this.props.selectedTableRow.service_name || this.props.selectedTableRow.asset_name) ?
											<span className="bg-lightGray rounded-lg text-black px-2 py-1 text-wrap mr-2 self-center"> {this.props.selectedTableRow.service_name}</span>
										: null}
										{this.props.selectedTableRow && this.props.selectedTableRow.asset_name ?
											<span>:&nbsp;{this.props.selectedTableRow.asset_name}</span>
										: null}
									</div>
									<div class="flex bg-green items-center justify-center py-1">
										<span class="text-white">Active</span>
									</div>
								</div>
								{this.props.eventSeries && this.props.eventSeries.length ? (
									<div className='mt-3 mx-2'>
										<div className='p-2 bg-white rounded-md'>
											<p className='mb-0 text-black'>Trends</p>
											<p className='mb-2 text-xs'>Resource level events trend</p>
											<div className='p-2 bg-backGroundGray rounded'>
												<div className='mt-n4 mb-n4 transparentTooltip'>
													<ApexChart type="area" series={this.props.eventSeries} options={this.props.eventOptions} height={120}/>
												</div>
											</div>
										</div>
									</div>
								) : null}
								
								{this.props.topEventsData && Object.keys(this.props.topEventsData).length ? (
									<div className='mt-3 mx-2'>
										<div className='p-2 bg-white rounded'>
											<b className='mb-0 text-black'>Top Events</b>
											{/* <p className='mb-2 text-xs'><small>Earum aut eos. Sed praesentium et hic voluptate itaque ut.</small></p> */}
										
											<div className={`${this.props.topEventsData.results && this.props.topEventsData.results.length ? "flex flex-wrap" : ""} lineHeight5`}>
												{this.props.topEventsData.results && this.props.topEventsData.results.length ? 
													this.props.topEventsData.results.map((item, i) => {
														let totalCount =  this.props.eventGraphData ? this.props.eventGraphData.total_events : 0
														let progressPercentage = ((item.event_count * 100) / totalCount) +'%'
														return (
															<React.Fragment key={i}>
																<div className='w-2/5 text-right'>
																	<p className="f10 text-black">{item.last_event_name}</p>
																</div>
																<div className="w-2/5 flex pl-3">
																	{totalCount > 0 ?
																		<div className='stock-progress'>
																			<div className='stock-1' style={{width:progressPercentage}} id={`eventProgress-${i}`}></div>
																			<UncontrolledTooltip placement='top' target={`eventProgress-${i}`}>{item.event_count}</UncontrolledTooltip>
																		</div>
																		: 
																		<div className='stock-progress'>
																			<div className='stock-5'></div>
																		</div>
																	}
																</div>
																<div className="col-sm-2 text-left"><small>{totalCount}</small></div>
															</React.Fragment>
														)
													})
												:
													<div className='flex justify-center m-4'>
														<p>No top events found</p>
													</div>
												}
											</div>
										</div>
									</div>
								) : null}

							<div className='mt-3 mx-2'>
								<div className='p-2 bg-white rounded'>
									<b className='mb-0 text-black'>Total Events </b>
									{this.props.recentEventsData && this.props.recentEventsData.length ?
										<React.Fragment>
										<p className='mb-2 text-xs'>
											<small>Total of {this.props.eventGraphData && this.props.eventGraphData.total_events && this.props.eventGraphData.total_events} events have occured in this resource during this period..</small>
										</p>
										<div className={`mr-1`}> {/* className removed  assetDetailsSection */}
											<ResizeableDarkThemeTable
												columns={[
													{
														Header: 'Event',
														accessor: 'action',
														width: 120
													},
													{
														Header: 'Date',
														accessor: 'action_date',
														Cell: cellInfo => momentConvertionUtcToLocalTime(cellInfo.row.original.last_action_date ? cellInfo.row.original.last_action_date : cellInfo.row.original.action_date, 'DD MMM YYYY HH:mm'),
														width: 120
													},
													{
														Header: 'Action By',
														accessor: 'action_by',
														width: 180
													},
												]}
												
												data={this.props.recentEventsData}
												//tooltipFor={[0, 1, 2]}
												riskTooltip={[]}
												dashboard={this.props.recentEventsData.length ? true : false }
												perPage={10}
												// rounded={'rounded'}
												tableSize={'table-xs'}
												// tableHead={'bg-primary'}
												// selectedColumn={'bg-light'}
												columnFontSize={'f10'}
												onClickRow={tableRow => {}}
											/>
										</div>
										</React.Fragment>
									: 
										<div className='flex justify-center m-4'>
											<p>No total events found</p>
										</div>
									}
								</div>
							</div>
							</React.Fragment>
						</div>
					) : this.props.showDetailsEventsSectionLoading &&
					  this.state.showEvents ? (
						<div className='flex justify-center m-4'>
							<svg className="animate-spin h-5 w-5 mr-2 text-black" fill="currentColor" viewBox="0 0 24 24">
								<circle cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="2" fill="none" />
								<path d="M4 12a8 8 0 0112-6.9" />
							</svg>
						</div>
					) : !this.props.showDetailsEventsSectionLoading &&
					  this.state.showEvents ? (
						<div className='flex justify-center m-4'>
							<p>No events found</p>
						</div>
					) : null}
				</div>
			</React.Fragment>
		)
	}
}
/**
 * Type of the props used in the component
 */
DetailsSection.propTypes = {
	selectedTableRow: PropTypes.object,
	detailsTabNames: PropTypes.array,
	getAssetDetails: PropTypes.func,
	getRelatedIssues: PropTypes.func,
	getMonitoringSnap: PropTypes.func,
	selectedDetailsTab: PropTypes.number,
	setSelectedDetailstabIndex: PropTypes.func,
	showDetailsTabListDropdown: PropTypes.bool,
	toggleDetailsSectionLoading: PropTypes.func,
	selectedRowAssetDetails: PropTypes.array,
	selectedRowBillingDetails: PropTypes.array,
	showDetailsMonitoringSectionLoading: PropTypes.bool,
	showDetailsAssetSectionLoading: PropTypes.bool,
	showDetailsRelatedIssueLoading: PropTypes.bool,
	showDetailsEventsSectionLoading: PropTypes.bool,
	showDetailsBillingSectionLoading: PropTypes.bool,
	showSuppressListLoading: PropTypes.bool,
	showRelatedIssues: PropTypes.bool,
	relatedIssuesData: PropTypes.array,
	selectedRowMonitoringSnapshot: PropTypes.object,
	dayWiseCountGraphData: PropTypes.object,
	recentEventsData: PropTypes.array,
	topEventsData: PropTypes.object,
	eventGraphData: PropTypes.object,
	getAssetEvents: PropTypes.func,
}
//export default DetailsSection

export default DetailsSection