import React, { useState, useEffect, useCallback, useRef } from 'react';
// import { useDispatch } from 'react-redux';
// import { Spinner } from 'reactstrap';
import { momentConvertionUtcToLocalTime, capitalizeTheFirstLetterOfEachWord, removeUnderScore, isValidDateTime, convertToLowerCase } from '../../../utils/utility';
import {Icon} from "@iconify/react";
// import { getJobMetrics } from '../../../actions/Collider/JobStatusAction';

// import MetricsTab from '../../common/Mertics/MetricsTab';


const CatalogDetailsSidePanel = ({ selectedDetails, closeSidePanel, detailsFormat }) => {
    // const dispatch = useDispatch(); // Create a dispatch function
    const clickOut = useRef();
    
    const [state, setState] = useState({
        showLoading: false,
        minMaxHeight: "770px",
        selectedTab: "Details",
        startRecord: 0,
        currentPage: 1,
        totalPages: 1,
        perPage: 20,
        logsList: [],
        filteredLogsList: []
    })
    
    const handleKeyDown = useCallback((event) => {
        if (event.keyCode === 27) {
            closeSidePanel();
        }
    }, [closeSidePanel]);

    useEffect(() => {
        document.addEventListener('keydown', handleKeyDown);
        getWindowSize();
    }, [handleKeyDown]);

    const getWindowSize = () => {
        let browserBorder = 100;
        let headerHeight = 120;
        let minMaxHeight = (window.outerHeight - browserBorder - headerHeight) + "px";
        setState(prevState => ({ ...prevState, minMaxHeight }))
    };

    // useEffect(() => {
    //     if (state.selectedTab === "Observability") {
    //         let params = {}
    //         params.start_time = selectedDetails && selectedDetails.start_time
    //         params.end_time = selectedDetails && selectedDetails.end_time
    //         params.asset_id = selectedDetails && selectedDetails.asset_id
    //         // Fetch job metrics data here using params and update the state
    //         dispatch(getJobMetrics(params))
    //                 .then((response) => {
    //                     setState(prevState => ({
    //                         ...prevState,
    //                         metricDetails: response,
    //                         showMetricLoading: false,
    //                     }));
    //             })
    //     }
    // }, [state.selectedTab, dispatch, selectedDetails])

    const handleChildClick = (event) => {
        if (clickOut.current && !clickOut.current.contains(event.target)) {
            closeSidePanel()
        }
    }

    return (
        <div className='advanced-search' style={{ zIndex: 999 }} onClick={(event) => handleChildClick(event, 'parent')}>
            <div className={`${state.showLoading ? '' : 'hidden'}`}>
                <div className='loading-wave'>
                    <div className='overlayEqualizerLoader'>
                        <div className="loading-bar"></div>
                        <div className="loading-bar"></div>
                        <div className="loading-bar"></div>
                        <div className="loading-bar"></div>
                    </div>
                </div>
            </div>
            <div className='search w-3/5 bg-muted min-h-screen overflow-auto' ref={clickOut}>
                <div className='header-search bg-black flex justify-between p-2'>
                    <div className='text-white w-full self-center'>
                        <span className='font-bold text-xl text-white'>Catalog Details</span>
                    </div>
                    <div className='self-center'>
                        <Icon icon="jam:close" className='text-white cursor-pointer' width="25" height="25" onClick={() => closeSidePanel()} />
                    </div>
                </div>
                <div className='m-2 overflow-auto'>
                    {state.selectedTab === "Details" ?
                        <div className="rounded-md bg-dark3 p-3">
                            {detailsFormat && detailsFormat.length && detailsFormat.map((row, index) => {
                                return(
                                    <div className={`${!index ? 'mt-2' : 'mt-4'}`}>
                                        <p className={`mb-1 text-cyon text-xl font-bold pb-1 border-b border-lightGray`}>{capitalizeTheFirstLetterOfEachWord(row.label)}</p>
                                        <small className='text-lightGray'>{row.description}</small>
                                        {row.fields && row.fields.map(fld => {
                                            return(
                                                <div className="flex mt-3">
                                                    <span className="text-lightGray minWidth220 self-center">{removeUnderScore(fld)}</span>
                                                    {selectedDetails && selectedDetails[fld] ?
                                                        <React.Fragment>
                                                        {typeof selectedDetails[fld] === 'boolean' ?
                                                            <span className={`text-white`}>{selectedDetails[fld] ? 'True' : 'False'}</span>
                                                        : isValidDateTime(selectedDetails[fld]) ?
                                                            <span className="text-white">{momentConvertionUtcToLocalTime(selectedDetails[fld], "DD MMM YYYY HH:mm")}<span className='ml-2 text-info'>(local time)</span></span>
                                                        : convertToLowerCase(fld) === "status" ?
                                                            <span className={`self-center bordere border-${convertToLowerCase(selectedDetails[fld])} px-2 py-1`}>{selectedDetails[fld]}</span>
                                                        : Array.isArray(selectedDetails[fld]) ?
                                                            <div className="flex flex-wrap self-center ">
                                                                {selectedDetails[fld].map(item => {
                                                                    return(
                                                                        typeof item === "string" ?
                                                                            <span className='border border-cyon bg-cyon px-2 py-1 text-white mr-2 mb-1'>{item}</span>
                                                                        : 
                                                                            Object.entries(item).map(([key, value]) => {
                                                                                return(
                                                                                    <span className='border border-cyon bg-cyon px-2 py-1 text-white mr-2 mb-1'>{key +' : '+value}</span>
                                                                                )
                                                                            })
                                                                    )
                                                                })}
                                                            </div>
                                                        :
                                                            <React.Fragment>
                                                            <span className="text-white break-all">{selectedDetails[fld]}</span>
                                                            </React.Fragment>
                                                        }
                                                        </React.Fragment>
                                                    : null}
                                                </div>
                                            )
                                        })}
                                    </div>
                                )
                            })}
                            
                            <p className={`mb-1 text-cyon text-lg pb-1 border-b border-lightGray mt-4`}>Installed Softwares</p>
                            <div className="relative overflow-x-auto mt-2">
                                <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                                    <thead className="text-md text-white bg-dark2">
                                        <tr>
                                            <th scope="col" className="px-6 py-3">Software Name</th>
                                            <th scope="col" className="px-6 py-3">Version</th>
                                        </tr>
                                    </thead>
                                    <tbody className='text-black bg-white'>
                                        
                                        {selectedDetails.installed_softwares && selectedDetails.installed_softwares.length ?
                                            selectedDetails.installed_softwares.map((row, index)=> {
                                                return(
                                                    <tr key={'sft_'+index} className={`bg-white border-b dark:bg-gray-800 dark:border-gray-700`}>
                                                    {Object.entries(row).map(([key, value]) => {
                                                        return(
                                                            <td className="px-6 py-3 text-sm" key={'sftin_'+index}>{value}</td>
                                                        )
                                                    })}
                                                    </tr>
                                                )

                                            })
                                        : null}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    : null}
                </div>
            </div>
        </div>
    );
};

export default CatalogDetailsSidePanel;