import React, { useState, useEffect, useRef, useCallback } from 'react';
import { momentDateGivenFormat } from '../../../utils/utility'
import { useDispatch } from 'react-redux';
import moment from 'moment';
import { Spinner } from 'reactstrap';
import { DateRange } from 'react-date-range';
import _ from 'lodash'
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';

import MultiSelectSection from '../../common/MultiSelectSection';
import ApexBarChart from '../../common/charts/ApexBarChart'
import ApexDonutChart from '../../common/charts/ApexDonutChart';
import ApexLineChartSpendBoard from '../../common/charts/ApexLineChartSpendBoard';

import { listUserDetails, listClusterFilters } from '../../../actions/commonActionNew';
import { getHpcReorts } from '../../../actions/Collider/HpcReportAction'

const DiskReportSection = (props) => {
    const sectionRef = useRef();
    const durationRef = useRef();
    const tableRef = useRef();
    const [state, setState] = useState({
        showLoading: true,
        durationOptions: [
            {label: "Month to Date", value: 'currentMonth'},
            {label: "Year to Date", value: 'currentYear'},
            {label: "Last Month", value: 'lastMonth'},
            {label: "Current Quarter", value: "quarterly"},
			{label: "Current Half Year", value: "half-yearly"},
            {label: "Yearly", value: 'yearly'},
            {label: "Custom", value: "custom"}
        ],
    })

    const [dateState, setDateState] = useState([{
          startDate: new Date(),
          endDate: null,
          key: 'selection'
    }]);

    const dispatch = useDispatch(); // Create a dispatch function
    
    const handleKeyDown = useCallback((event) => {
        if (event.keyCode === 27) {
            props.closeSidePanel()
        }
    }, [props]);

    useEffect(() => {
        let selectedAccount = props.selectedAccount
        let selectedRegion = props.selectedRegion
        let selectedClusters = props.selectedClusters
        let datePickerStartDate = props.datePickerStartDate
        let datePickerEndDate = props.datePickerEndDate
        let selectedUser = ''

        if(props.selectedReportInput) {
            if(props.selectedReportInput && props.selectedReportInput.account_id && props.selectedReportInput.account_id.length) {
                selectedAccount = props.selectedReportInput.account_id
            }

            if(props.selectedReportInput && props.selectedReportInput.region && props.selectedReportInput.region.length) {
                selectedRegion = props.selectedReportInput.region
            }
            
            if(props.selectedReportInput && props.selectedReportInput.cluster_name && props.selectedReportInput.cluster_name.length) {
                selectedClusters = props.selectedReportInput.cluster_name
            }
            
            if(props.selectedReportInput && props.selectedReportInput.user_name && props.selectedReportInput.user_name.length) {
                selectedUser = props.selectedReportInput.user_name
            }
        }

        if(selectedAccount && selectedAccount.length) {
            setState(prevState => ({ ...prevState, selectedAccount}))
        }

        if(selectedRegion && selectedRegion.length) {
            setState(prevState => ({ ...prevState, selectedRegion}))
        }

        if(selectedClusters && selectedClusters.length) {
            setState(prevState => ({ ...prevState, selectedClusters}))
        }

        if(selectedUser) {
            setState(prevState => ({ ...prevState, selectedUser}))
        }

        setState(prevState => ({ 
            ...prevState,
            showLoading: true,
            selectedUser,
            datePickerStartDate,
            datePickerEndDate,
            callHpcReports: true,
            callClusterList: true
        }))
    }, [props])

    useEffect(() => {
        document.addEventListener('keydown', handleKeyDown);
    }, [handleKeyDown])

    useEffect(() => {
        if(props.selectedProvider) {
            setState(prevState => ({ ...prevState, callClusterList: false }));
            let params = {};
            params.provider = props.selectedProvider.toLowerCase();
            
            if(state.selectedAccount && state.selectedAccount.length) {
                params.account_id = state.selectedAccount
            }
            if(state.selectedRegion && state.selectedRegion.length) {
                params.region = state.selectedRegion
            }
            params.aggregate_by = 'cluster_name'
            dispatch(listClusterFilters(params))
                .then((response) => {
                    if(response) {
                        setState(prevState => ({ ...prevState, clusters: response }));
                    }
            })
        }
    }, [dispatch, props.selectedProvider, state.selectedAccount, state.selectedRegion])

    useEffect(() => {
        if(props.selectedProvider) {
            setState(prevState => ({ ...prevState, callClusterList: false }));
            let params = {};
            params.provider = props.selectedProvider.toLowerCase();
            
            if(state.selectedAccount && state.selectedAccount.length) {
                params.account_id = state.selectedAccount
            }
            if(state.selectedRegion && state.selectedRegion.length) {
                params.region = state.selectedRegion
            }
            if(state.selectedClusters && state.selectedClusters.length) {
                params.region = state.selectedClusters
            }
            dispatch(listUserDetails(params))
                .then((response) => {
                    if(response) {
                        let results = response && response.results && response.results.length ? response.results : []
                        setState(prevState => ({ ...prevState, users: results }));
                    }
            })
        }
    }, [dispatch, props.selectedProvider, state.selectedAccount, state.selectedRegion, state.selectedClusters])

    useEffect(() => {
        if(state.callHpcReports) {
            setState(prevState => ({ ...prevState, callHpcReports: false }))
            let params = {}
            params.provider = props.selectedProvider.toLowerCase()
            if(state.selectedAccount && state.selectedAccount.length) {
                params.account_id = state.selectedAccount
            }
            
            if(state.selectedRegion && state.selectedRegion.length) {
                params.region = state.selectedRegion
            }
            
            if(state.selectedClusters && state.selectedClusters.length) {
                params.cluster_name = state.selectedClusters
            }

            if(state.selectedUser) {
                params.user_name = state.selectedUser
            } else if(state.selectedUserDetails && state.selectedUserDetails.user_name) {
                params.user_name = state.selectedUserDetails.user_name
            }

            if(props.selectedReport && props.selectedReport.report_id) {
                params.report_id = props.selectedReport.report_id
            }
            
            params.start_time = state.datePickerStartDate
            params.end_time = state.datePickerEndDate            
            
            if(props.selectedReportInput && !state.searchInput) {
                if(props.selectedReportInput && props.selectedReportInput.account_id && props.selectedReportInput.account_id.length) {
                    params.account_id = props.selectedReportInput.account_id
                }

                if(props.selectedReportInput && props.selectedReportInput.region && props.selectedReportInput.region.length) {
                    params.region = props.selectedReportInput.region
                }
                
                if(props.selectedReportInput && props.selectedReportInput.cluster_name && props.selectedReportInput.cluster_name.length) {
                    params.cluster_name = props.selectedReportInput.cluster_name
                }
                
                if(props.selectedReportInput && props.selectedReportInput.user_name && props.selectedReportInput.user_name.length) {
                    params.user_name = props.selectedReportInput.user_name
                }
            }
    
            dispatch(getHpcReorts(params))
                .then((response) => {
                    if(response) {
                        let results = response.results ? response.results : {}
                        setState(prevState => ({ ...prevState, reportDetails: results, structureReports: true, showLoading: false }))
                    }
                })
        }
    }, [state.callHpcReports, dispatch, state.datePickerEndDate, state.datePickerStartDate, state.searchInput, state.selectedAccount, state.selectedClusters, state.selectedRegion, state.selectedUser, state.selectedUserDetails, props])

    useEffect(() => {
        if(state.structureReports) {
            setState(prevState => ({ ...prevState , structureReports: false }))
            let graph1 = state.reportDetails.graph1 ? state.reportDetails.graph1 : {}
            let graphData1 = {}
            graphData1.description = graph1.description ? graph1.description : ''
            graphData1.graph_type = graph1.graph_type ? graph1.graph_type : ''
            if(graph1.graph_type === 'donut') {
                let donut = []
                let donut_total_count = 0
                let data = graph1.data ? graph1.data : []
                graph1 && graph1.labels && graph1.labels.forEach((lab, index) => {
                    let donutRow = {}
                    donutRow.item_name = lab
                    donutRow.item_count = data[index] ? data[index] : 0
                    donut.push(donutRow)
                    donut_total_count += data[index] ? data[index] : 0 
                })
                donut = _.orderBy(donut, ['item_count'], ['desc'])
        
                graphData1.graphValue = {
                    'items': donut,
                    'label': 'Total',
                    'total_count': donut_total_count
                }
            } else {
                graphData1.labels = graph1.labels ? graph1.labels : []
                graphData1.data = graph1.data ? graph1.data : []
                graphData1.tooltip = graph1.tooltip ? graph1.tooltip : {}
                // graphData1.unit = '%'
                // graphData1.prefixUnit = '£'
            }



            let graph2 = state.reportDetails.graph2 ? state.reportDetails.graph2 : {}
            let graphData2 = {}
            graphData2.description = graph2.description ? graph2.description : ''
            graphData2.graph_type = graph2.graph_type ? graph2.graph_type : ''
            if(graph2.graph_type === 'donut') {
                let donut = []
                let donut_total_count = 0
                let data = graph2.data ? graph2.data : []
                graph2 && graph2.labels && graph2.labels.forEach((lab, index) => {
                    let donutRow = {}
                    donutRow.item_name = lab
                    donutRow.item_count = data[index] ? data[index] : 0
                    donut.push(donutRow)
                    donut_total_count += data[index] ? data[index] : 0 
                })
                donut = _.orderBy(donut, ['item_count'], ['desc'])
        
                graphData2.graphValue = {
                    'items': donut,
                    'label': 'Total',
                    'total_count': donut_total_count
                }
            } else {
                let data = {}
                data.labels = graph2.trends && graph2.trends.length && graph2.trends[0].dates ? graph2.trends[0].dates : []

                if(graph2.trends && graph2.trends.length) {
                    graph2.trends.forEach(trd => {
                        Object.entries(trd).forEach(([key, value]) => {
                            if(key !== 'dates') {
                                data[key] = value
                            }
                        })
                    })
                }
                graphData2.data = data
            }

            setState(prevState => ({ ...prevState, graphData1, graphData2 }))

            let tableDetails = state.reportDetails.table ? state.reportDetails.table : {}

            let tableData = tableDetails.table_data ? tableDetails.table_data : []

            let tableFields = tableDetails.fields ? tableDetails.fields : []
            let dynamicTableColumn = []
            tableFields.forEach(row => {
                let width = 150
                if(row.length > 10) {
                    width = 200
                }
                let dataRow = {}
                dataRow.Header = row
                dataRow.accessor = row
                // dataRow.Cell = cellInfo => (
                //     <span className={`badge ${
                //         cellInfo.row.original.severity && cellInfo.row.original.severity !== '' ?
                //             'risk-badge-'+cellInfo.row.original.severity.toLowerCase()
                //         : 'badge-secondary'
                //         }`}>
                //         {cellInfo.row.original.severity && cellInfo.row.original.severity !== '' ? (cellInfo.row.original.severity.toLowerCase() === 'low' ? 'L' : cellInfo.row.original.severity.toLowerCase() === 'medium' ? 'M' :  cellInfo.row.original.severity.toLowerCase() === 'high' ? 'H' : cellInfo.row.original.severity.toLowerCase() === 'critical' ? 'C' : '-') : '-'}
                //     </span>)
                dataRow.width = width
                dynamicTableColumn.push(dataRow)
            })

            setState(prevState => ({ ...prevState, tableData, filterTableData: tableData, dynamicTableColumn, tableDetails }))
        }
    }, [state.structureReports, state.reportDetails])

    const downloadPDF = async (index, item) => {
        const chartContainer1 = document.getElementById('chartContainer1');
        const chartContainer2 = document.getElementById('chartContainer2');
        const chartContainer3 = document.getElementById('chartContainer3');
        // const table = document.getElementById('table');
        const table = tableRef.current;
    
        if (!chartContainer1) {
          console.error("Chart container not found.");
          return;
        }
    
        try {
            const canvas1 = await html2canvas(chartContainer1);
            const canvas2 = await html2canvas(chartContainer2);
            const canvas3 = chartContainer3 ? await html2canvas(chartContainer3) : ''
            
            const tableData = await html2canvas(table)
            // const pdf = new jsPDF();
            const pdf = new jsPDF('p', 'mm', 'a4');
            
            // let selectedPeriod = state.periodOptions.filter(e => e.value === state['selected_Period_' + index]).length ? state.periodOptions.filter(e => e.value === state['selected_Period_' + index])[0].label : state['selected_Period_' + index]
            let selectedPeriod = ''
            
            pdf.text(`${selectedPeriod} report for the`, 10, 10) 
            // pdf.text(`${selectedPeriod} report for the user ${item.userName}`, 10, 10) 
            // pdf.text(`spanning from ${momentDateGivenFormat(props.startDate, 'DD MMM YYYY')} to ${momentDateGivenFormat(props.endDate, 'DD MMM YYYY')}`, 10, 20)
            // pdf.text(`Report for User - ${item.userName}`, 15, 10);
            // pdf.text(`Period from - ${momentConvertionUtcToLocalTime(props.startDate, 'DD MMM YYYY') + ' to '+momentConvertionUtcToLocalTime(props.endDate, 'DD MMM YYYY') }`, 15, 10);
            // pdf.text(`Frequency from - ${momentConvertionUtcToLocalTime(props.startDate, 'DD MMM YYYY') + ' to '+momentConvertionUtcToLocalTime(props.endDate, 'DD MMM YYYY') }`, 15, 10);
            if(canvas3) {
                pdf.addImage(canvas1.toDataURL('image/png'), 'PNG', 5, 30, 65, 60);
                pdf.addImage(canvas2.toDataURL('image/png'), 'PNG', 75, 30, 65, 60);
                pdf.addImage(canvas3.toDataURL('image/png'), 'PNG', 140, 30, 65, 60);
                // pdf.addImage(tableData.toDataURL('image/png'), 'PNG', 5, 100, 200, 80);
            } else {
                pdf.addImage(canvas1.toDataURL('image/png'), 'PNG', 5, 30, 100, 60);
                pdf.addImage(canvas2.toDataURL('image/png'), 'PNG', 110, 30, 100, 60);
                // pdf.addImage(tableData.toDataURL('image/png'), 'PNG', 5, 100, 200, 80);
            }

            const pageHeight = 295; // A4 page height in mm
            const pageWidth = 210; // A4 page width in mm
            const imgHeight = (100 + tableData.height) * pageWidth / tableData.width;
            let heightLeft = imgHeight;
        
            const pdfPageCount = Math.ceil(heightLeft / pageHeight);
            
            for (let page = 1; page <= pdfPageCount; page++) {
                // Get the height of the content for the current page
                const yStart = (page - 1) * pageHeight;
                const height = Math.min(heightLeft, pageHeight);
                heightLeft -= height;
          
                // Add a new page to the PDF
                pdf.addPage();
          
                // Draw the content on the current page
                pdf.addImage(tableData, 'image/png', 0, yStart, pageWidth, height, undefined, 'FAST');
            }

            // html2canvas(table).then(tab => {
            //     const imgData = tab.toDataURL('image/png');
            //     const pdf = new jsPDF('p', 'mm', 'a4');
            //     pdf.addImage(imgData, 'PNG', 10, 10, 190, 100);
            // });
        
            const timestamp = new Date().getTime();
            pdf.save(`report_${timestamp}.pdf`);

            setState(prevState => ({ ...prevState, showDownloadLoading: false }))

        } catch (error) {
          console.error("Error capturing charts:", error);
          setState(prevState => ({ ...prevState, showDownloadLoading: false }))
        }
    }

    const handleSelect = (date) => {
        let datePickerStartDate = momentDateGivenFormat(date.selection.startDate, 'YYYY-MM-DD 00:00:00');
        let datePickerEndDate = ""
        if(date.selection.startDate !== date.selection.endDate) {
            datePickerEndDate = momentDateGivenFormat(date.selection.endDate, 'YYYY-MM-DD 23:59:59');
        }
        
        if(datePickerStartDate && datePickerEndDate) {
            setState(prevState => ({
                ...prevState,
                datePickerStartDate,
                datePickerEndDate,
                selectedDuration: "custom",
                callHpcReports: true
            }));    
        }

        setDateState([date.selection])
    };

    useEffect(() => {
        const currentMonth = new Date().getMonth(); // Month is zero-based (0 for January, 1 for February, ..., 11 for December)

       	let datePickerStartDate = props.datePickerStartDate
        let datePickerEndDate = props.datePickerEndDate
        if(state.selectedDuration === "currentMonth") {
            datePickerStartDate = momentDateGivenFormat(new Date(), 'YYYY-MM-01 00:00:00')
            datePickerEndDate = momentDateGivenFormat(new Date(), 'YYYY-MM-DD 23:59:59')
        } else if(state.selectedDuration === "currentYear") {
            datePickerStartDate = momentDateGivenFormat(new Date(), 'YYYY-01-01 00:00:00')
            datePickerEndDate = momentDateGivenFormat(new Date(), 'YYYY-MM-DD 23:59:59')
        } else if(state.selectedDuration === "lastMonth") {
            datePickerStartDate = moment().subtract(1, 'months').startOf('month').format('YYYY-MM-01 00:00:00')            
            datePickerEndDate = moment().subtract(1, 'months').endOf('month').format('YYYY-MM-DD 23:59:59')
        } else if(state.selectedDuration === "quarterly") {
            if (currentMonth >= 0 && currentMonth <= 2) {
                datePickerStartDate = momentDateGivenFormat(new Date(), 'YYYY-01-01 00:00:00')
                datePickerEndDate = momentDateGivenFormat(new Date(), 'YYYY-03-31 23:59:59')
            } else if (currentMonth > 2 && currentMonth < 6) {
                datePickerStartDate = momentDateGivenFormat(new Date(), 'YYYY-04-01 00:00:00')
                datePickerEndDate = momentDateGivenFormat(new Date(), 'YYYY-06-30 23:59:59')
            } else if (currentMonth > 5 && currentMonth < 9) {
                datePickerStartDate = momentDateGivenFormat(new Date(), 'YYYY-07-01 00:00:00')
                datePickerEndDate = momentDateGivenFormat(new Date(), 'YYYY-09-30 23:59:59')
            } else {
                datePickerStartDate = momentDateGivenFormat(new Date(), 'YYYY-10-01 00:00:00')
                datePickerEndDate = momentDateGivenFormat(new Date(), 'YYYY-12-31 23:59:59')
            }
        } else if(state.selectedDuration === "half-yearly") {
            if (currentMonth >= 0 && currentMonth <= 5) {
                datePickerStartDate = momentDateGivenFormat(new Date(), 'YYYY-01-01 00:00:00')
                datePickerEndDate = momentDateGivenFormat(new Date(), 'YYYY-06-30 23:59:59')
            } else {
                datePickerStartDate = momentDateGivenFormat(new Date(), 'YYYY-07-01 00:00:00')
                datePickerEndDate = momentDateGivenFormat(new Date(), 'YYYY-12-31 23:59:59')
            }
        } else if(state.selectedDuration === "yearly") {
            datePickerStartDate = momentDateGivenFormat(new Date(), 'YYYY-01-01 00:00:00')
            datePickerEndDate = momentDateGivenFormat(new Date(), 'YYYY-12-31 23:59:59')
        }
        if(state.selectedDuration !== 'custom') {
            setState(prevState => ({ ...prevState, datePickerStartDate, datePickerEndDate, callHpcReports: true }));

            let dateSelection= {
				startDate: new Date(datePickerStartDate),
				endDate: new Date(datePickerEndDate),
				key: 'selection'
			}

			setDateState([dateSelection])
        }

	}, [state.selectedDuration, props])

	const handleChildClick = (event, type, dropdownType, section) => {	
        if (sectionRef.current && !sectionRef.current.contains(event.target)) {
            props.closeSidePanel()
        }
        
		if (durationRef.current && !durationRef.current.contains(event.target)) {
            setState(prevState => ({ ...prevState, showDateRangePicker: false }));
		}
		event.stopPropagation();
		let clickedChild = []
		if(type === 'child') {
            if(state.clickedChild && state.clickedChild.length) {
                state.clickedChild.forEach(row => {
                    setState(prevState => ({ ...prevState, [row]: false }))
                })
            }
            clickedChild.push(section)
            setState(prevState => ({ ...prevState, [section]: dropdownType === "singleDropDown" && state[section] ? false : true, clickedChild }))
        } else if(type === 'parent') {
            if(state.clickedChild && state.clickedChild.length) {
                state.clickedChild.forEach(row => {
                    setState(prevState => ({ ...prevState, [row]: false }))
                })
            }
            
            setState(prevState => ({ ...prevState, clickedChild }))
        }
	}

    return (
        <div className="advanced-search" style={{ zIndex: 9999999 }} onClick={(event) => handleChildClick(event, 'parent')}>
            <div className="search-content bg-muted w-82 overflow-auto" ref={sectionRef}>
                <div className="header-search bd-highlight bg-black flex justify-between p-2">
                    <div className="flex bd-highlight text-white">
                        <h5>Reports</h5>
                        <p className='ml-4 mb-0 self-center text-info'>Report Id: <span className='text-white'>{props.selectedReport && props.selectedReport.report_id ? props.selectedReport.report_id : ''}</span></p>
                        <p className='ml-4 mb-0 self-center text-info'>Description: <span className='text-white'>{props.selectedReport && props.selectedReport.title ? props.selectedReport.title : ''}</span></p>
                    </div>

                    <div className="text-right flex-fill bd-highlight self-center">
                        <i className="far fa-times fa-lg text-white cursor-pointer" onClick={() => props.closeSidePanel()}></i>
                    </div>
                </div>                
                {state.showLoading ?
                    <div className='flex justify-center m-4'>
                        <Spinner className='text-center' color='light' />
                    </div>
                :
                    <React.Fragment>
                    <div className='row mx-0'>
                        <div className='flex justify-between col-md-12 '>
                            <p className='mb-0 flex flex-wrap self-center mb-2'>
                                <small className='self-end'>Showing report</small>
                                {props && props.selectedProvider ?
                                    <React.Fragment>
                                    <small className='ml-1 self-end'>obtained from the</small>
                                    <small className='ml-1 self-end text-info'>{props.selectedProvider.toUpperCase()}</small>
                                    <small className='ml-1 self-end'>provider</small>
                                    </React.Fragment>
                                : null}
                                <React.Fragment>
                                <small className='ml-1 self-end mr-2'>with the account</small>
                                <div className='self-center mt-1' style={{minWidth: '150px', maxWidth: '150px'}} onClick={(event) => {
                                    event.preventDefault();
                                    handleChildClick(event, 'child', 'muliselectDropDown', 'isAccountOpen')
                                }}>
                                    <p className={`flex mb-0 boderDottedBottom ${state.selectedAccount && state.selectedAccount.length ? 'text-white' : 'text-lightGray'}`}>{state.selectedAccount && state.selectedAccount.length ? state.selectedAccount.length+' Selected' : 'Select'}</p>
                                    {state.isAccountOpen ?
                                        <MultiSelectSection 
                                            fields={["account_id", "account_name"]}
                                            options={props.accounts}
                                            widthClass={'widthMaxContent'}
                                            selectedValues={state.selectedAccount ? state.selectedAccount : []}
                                            callbackMultiSelect={(value) => setState(prevState => ({ ...prevState, selectedAccount: value, callClusterList: true }))}
                                            topClass={'auto'}
                                        />
                                    : null}
                                </div>
                                
                                <small className='ml-1 self-end mr-2'>in the</small>
                                <div className='self-center mt-1' style={{minWidth: '150px', maxWidth: '150px'}} onClick={(event) => {
                                    event.preventDefault();
                                    handleChildClick(event, 'child', 'muliselectDropDown', 'isRegionOpen')
                                }}>
                                    <p className={`flex mb-0 boderDottedBottom ${state.selectedRegion && state.selectedRegion.length ? 'text-white' : 'text-lightGray'}`}>{state.selectedRegion && state.selectedRegion.length ? state.selectedRegion.length+' Selected' : 'Select'}</p>
                                    {state.isRegionOpen ?
                                        <MultiSelectSection 
                                            fields={["region", "name"]}
                                            options={props.regions}
                                            widthClass={'widthMaxContent'}
                                            selectedValues={state.selectedRegion ? state.selectedRegion : []}
                                            callbackMultiSelect={(value) => setState(prevState => ({ ...prevState, selectedRegion: value, callClusterList: true }))}
                                            topClass={'auto'}
                                        />
                                    : null}
                                </div>
                                <small className='ml-1 self-end mr-2'>region</small>
                                
                                <small className='ml-1 self-end mr-2'>in the</small>
                                <div className='self-center mt-1' style={{minWidth: '150px', maxWidth: '150px'}} onClick={(event) => {
                                    if(!state.isClusterOpen) {
                                        event.preventDefault();
                                        handleChildClick(event, 'child', 'singleDropDown', 'isClusterOpen')
                                    }
                                }}>
                                    <p className={`flex mb-0 boderDottedBottom ${state.selectedClusters ? 'text-white' : 'text-lightGray'}`}>{state.selectedClusters ? state.selectedClusters : 'Select'}</p>
                                    {state.isClusterOpen ?
                                        <MultiSelectSection 
                                            // fields={["region", "name"]}
                                            options={state.clusters}
                                            widthClass={'widthMaxContent'}
                                            selectedValues={state.selectedClusters ? state.selectedClusters : []}
                                            callbackMultiSelect={(value) => setState(prevState => ({ ...prevState, selectedClusters: value, callClusterList: true }))}
                                            topClass={'auto'}
                                            singleSelection={true}
                                        />
                                    : null}
                                </div>
                                <small className='ml-1 self-end mr-2'> cluster</small>
                                <small className='ml-1 self-end mr-2'> by the user</small>
                                
                                <div className='self-center mt-1' style={{minWidth: '150px', maxWidth: '150px'}} onClick={(event) => {
                                    event.preventDefault();
                                    handleChildClick(event, 'child', 'singleDropDown', 'isUserOpen')
                                }}>
                                    <p className={`flex mb-0 boderDottedBottom ${state.selectedUser ? 'text-white' : 'text-lightGray'}`}>{state.selectedUser ? state.selectedUser : 'Select'}</p>
                                    {state.isUserOpen ?
                                        <MultiSelectSection 
                                            fields={["userName", "userName"]}
                                            options={state.users}
                                            widthClass={'widthMaxContent'}
                                            selectedValues={state.selectedUser ? state.selectedUser : []}
                                            callbackMultiSelect={(value) => setState(prevState => ({ ...prevState, selectedUser: value, callClusterList: true }))}
                                            topClass={'auto'}
                                            singleSelection={true}
                                        />
                                    : null}
                                </div>
                                <p className="mb-0 ml-4 text-info mt-1 self-center cursor-pointer" onClick={() => setState(prevState => ({ ...prevState, searchInput: true, callHpcReports: true, showLoading: true }))}>Search</p>
                                </React.Fragment>
                            </p>
                            <p className='text-info mb-1 ml-3 cursor-pointer self-center' onClick={() => {
                                if(!state.showDownloadLoading) {
                                    setState(prevState => ({ ...prevState, showDownloadLoading: true })) 
                                    downloadPDF()
                                }
                            }}>
                                {state.showDownloadLoading ?
                                    <Spinner size='sm' color='light' className='mx-2' />
                                : null}    
                                Download&nbsp;PDF
                                
                            </p>
                        </div>
                        {state.selectedUserDetails && state.selectedUserDetails.user_name ?
                            <p className='flex justify-end mb-1 col-lg-12 col-md-12 col-sm-12 cursor-pointer' onClick={() => setState(prevState => ({ ...prevState, selectedUserDetails: {}, callHpcReports: true, showLoading: true, graphData1: {}, graphData2: {} }))}>Back to user Report</p>
                        : null}
                        <div className={`col-lg-5 col-md-5 col-sm-5`}>&nbsp;</div>
                        <div className={`col-lg-5 col-md-5 col-sm-5 flex justify-end mb-1`}>
                            <small className='ml-1 self-end mr-2'>Trend duration</small>
                            <div className='self-center mt-1' style={{minWidth: '200px', maxWidth: '200px'}} onClick={(event) => {
                                event.preventDefault();
                                handleChildClick(event, 'child', 'singleDropDown', 'isDurationOpen')
                            }}>
                                <p className={`flex mt-1 mb-0 border-lightGray px-2 py-1 rounded-5 ${state.datePickerStartDate ? 'text-white' : 'text-lightGray'}`}>
                                    {state.datePickerStartDate ?
                                        <span> {momentDateGivenFormat(state.datePickerStartDate, 'DD MMM YYYY') +' - '+ momentDateGivenFormat(state.datePickerEndDate, 'DD MMM YYYY')}</span>
                                    : 'Select'}
                                </p>
                                {state.isDurationOpen ?
                                    <MultiSelectSection
                                        fields={["value", "label"]}
                                        options={state.durationOptions}
                                        widthClass={'widthMaxContent'}
                                        selectedValues={state.selectedDuration ? state.selectedDuration : ''}
                                        callbackMultiSelect={(value) => {
                                            if(value !== 'custom') {
                                                setState(prevState => ({ ...prevState, selectedDuration: value }))
                                            } else {
                                                setState(prevState => ({ ...prevState, showDateRangePicker: value === 'custom' ? true : false }))
                                            }
                                        }}
                                        singleSelection={true}
                                        topClass={'auto'}
                                    />
                                : null}
                                {state.showDateRangePicker ?
                                    <div className="position-absolute z999" 
                                    // style={{"z-index": "999999999","right": "20px","height": "450px"}} 
                                    ref={durationRef}>
                                        <div className="RangePickerWithStartEndTime text-black5 pt-2 ml-n1">
                                            <div className='headerDateRange' style={{zIndex: '999999999','right': '30px', 'top': '50px'}}>
                                                <DateRange
                                                    editableDateInputs={true}
                                                    onChange={handleSelect}
                                                    moveRangeOnFirstSelection={false}
                                                    ranges={dateState}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                : null}
                            </div>
                        </div>

                        <div className={`col-lg-5 col-md-5 col-sm-5`} id={'chartContainer1'}>
                            {state.graphData1 && Object.entries(state.graphData1).length ?
                                <div className='card p-3 h-100'>
                                    <small className='mt-1 mb-0 small text-lightGray'>{state.graphData1.description ? state.graphData1.description :''}</small>
                                    <div className='mb-n4'> 
                                        {state.graphData1.graph_type === 'horizontal' ?
                                            <ApexBarChart
                                                graphData={state.graphData1}
                                                sparkline={false}
                                                yaxis={true}
                                                yaxisLabel={true}
                                                xaxis={true}
                                                xaxisFormat={'string'}
                                                xaxisLabel={true}
                                                axisLabelColor={'#999999'}
                                                paddingLeft={0}
                                                legend={false}
                                                stacked={false}
                                                height={275}
                                                horizontal={true}
                                                barHeight={'40%'}
                                                barEndShape={'rounded'}
                                                barRadius={8}
                                                columnWidth={'25%'}
                                                showTitle={true}
                                                //'#FFCD00', '#ff8200', '#d2291c', '#43b02a', '#0057b8', '#8e3a80'
                                                gradient={true}
                                                gradientColor={['#0057b8', '#8e3a80']}
                                                hideTooltipValue={true}
                                                // backgroundBarShape={'F'}
                                                backgroundBarColors={['#333947']}
                                                showBackgroundBarColors={false}
                                                className={'transparentTooltip'}
                                                dataLabels={true}
                                                dataLabelsTextAnchor={'middle'}
                                                dataLabelPosition={''}
                                            />
                                        : state.graphData1.graph_type === 'vertical_stacked' ?
                                            <ApexLineChartSpendBoard
                                                graphData={state.graphData1}
                                                sparkline={false}
                                                yaxis={false}
                                                yaxisLabel={true}
                                                xaxis={true}
                                                xaxisFormat={'categoryString'}
                                                xaxisLabel={true}
                                                axisLabelFontSize={'9px'}
                                                axisLabelColor={'#B8BBBE'}
                                                paddingLeft={-25}
                                                legend={false}
                                                stacked={true}
                                                height={275}
                                                horizontal={false}
                                                barHeight={'40%'}
                                                barEndShape={'flat'}
                                                columnWidth={'25%'}
                                                gradient={true}
                                                gradientColor={['#039BE5', '#5F5BA2']}
                                                hideTooltipValue={true}
                                                backgroundBarShape={'flat'}
                                                backgroundBarColors={['#333947']}
                                                showBackgroundBarColors={true}
                                                className={"transparentTooltip"}
                                                colors={["#9A77D1", "#2D323E"]}
                                            />
                                        : state.graphData1.graph_type === 'donut' ?
                                            <div className='flex justify-center'>
                                                <ApexDonutChart
                                                    graphData={state.graphData1.graphValue}
                                                    legend={false}
                                                    showTotal={true}
                                                    showTotalLable={true}
                                                    showGraphHeading={false}
                                                    height={250}
                                                    width={250}
                                                    size={'70%'}
                                                    gradient={false}
                                                    gradientColor={['#A88CCC', '#D88ACF']}
                                                    colors={['#A88CCC', '#D88ACF',	'#FE93B4',	'#FFAE90',	'#EDD482',	'#CFF69C',	'#97F9A3',	'#77ECC8',	'#7BCCE7']}
                                                    className={"transparentTooltip"}
                                                />
                                            </div>
                                        : null}
                                    </div>
                                </div>
                            : null}
                        </div>
                        <div className={`'col-lg-5 col-md-5 col-sm-5`} id={'chartContainer2'}>
                            {state.graphData2 && Object.entries(state.graphData2).length ?
                                <div className='card p-3 h-100'>
                                    <small className='mt-1 mb-0 small text-lightGray'>{state.graphData2.description ? state.graphData2.description :''}</small>
                                    <div className='mb-n4'>
                                        {state.graphData2.graph_type === 'horizontal' ?
                                            <ApexBarChart
                                                graphData={state.graphData2}
                                                sparkline={false}
                                                yaxis={true}
                                                yaxisLabel={true}
                                                xaxis={true}
                                                xaxisFormat={'string'}
                                                xaxisLabel={true}
                                                axisLabelColor={'#999999'}
                                                paddingLeft={0}
                                                legend={false}
                                                stacked={false}
                                                height={275}
                                                horizontal={true}
                                                barHeight={'40%'}
                                                barEndShape={'rounded'}
                                                barRadius={8}
                                                columnWidth={'25%'}
                                                showTitle={true}
                                                //'#FFCD00', '#ff8200', '#d2291c', '#43b02a', '#0057b8', '#8e3a80'
                                                gradient={true}
                                                gradientColor={['#0057b8', '#8e3a80']}
                                                hideTooltipValue={true}
                                                // backgroundBarShape={'F'}
                                                backgroundBarColors={['#333947']}
                                                showBackgroundBarColors={false}
                                                className={'transparentTooltip'}
                                                dataLabels={true}
                                                dataLabelsTextAnchor={'middle'}
                                                dataLabelPosition={''}
                                            />
                                        : state.graphData2.graph_type === 'vertical_stacked' ?
                                            <ApexLineChartSpendBoard
                                                graphData={state.graphData2.data}
                                                sparkline={false}
                                                yaxis={true}
                                                yaxisLabel={true}
                                                xaxis={true}
                                                xaxisFormat={'categoryString'}
                                                xaxisLabel={true}
                                                axisLabelFontSize={'9px'}
                                                axisLabelColor={'#B8BBBE'}
                                                paddingLeft={-5}
                                                legend={false}
                                                stacked={true}
                                                height={275}
                                                horizontal={false}
                                                barHeight={'40%'}
                                                barEndShape={'flat'}
                                                columnWidth={"25%"}
                                                gradient={true}
                                                gradientColor={['#039BE5', '#5F5BA2']}
                                                hideTooltipValue={true}
                                                backgroundBarShape={'flat'}
                                                backgroundBarColors={['#333947']}
                                                showBackgroundBarColors={true}
                                                className={"transparentTooltip"}
                                                colors={['#693EBC', '#775BA2', '#9A77D1', '#039BE5', '#43B02A', '#F75E3F']}
                                            />
                                        : state.graphData2.graph_type === 'donut' ?
                                            <div className='flex justify-center'>
                                                <ApexDonutChart
                                                    graphData={state.graphData2.graphValue}
                                                    legend={false}
                                                    showTotal={true}
                                                    showTotalLable={true}
                                                    showGraphHeading={false}
                                                    height={250}
                                                    width={250}
                                                    size={'70%'}
                                                    gradient={false}
                                                    gradientColor={['#A88CCC', '#D88ACF']}
                                                    colors={['#A88CCC', '#D88ACF',	'#FE93B4',	'#FFAE90',	'#EDD482',	'#CFF69C',	'#97F9A3',	'#77ECC8',	'#7BCCE7']}
                                                    className={"transparentTooltip"}
                                                />
                                            </div>
                                        : null}
                                    </div>
                                </div>
                            : null}
                        </div>

                        <div className={`'col-lg-2 col-md-2 col-sm-2 self-center`} id={'chartContainer3'}>
                            <div className='flex justify-between'>
                                <div className={`dash-box bg-transparent borderWidth2px border-plum text-lightDarkGray cursor-pointer w-50 m-0`}>
                                    <p className='mb-0'>{state.reportDetails && state.reportDetails.disk_usage && state.reportDetails.disk_usage.disk_capacity ? state.reportDetails.disk_usage.disk_capacity : 0}</p>
                                    <small>Disk&nbsp;Capacity</small>
                                </div>
                                <div className={`dash-box bg-transparent borderWidth2px border-plum text-lightDarkGray cursor-pointer w-50 m-0 ml-2`}>
                                    <p className='mb-0'>{state.reportDetails && state.reportDetails.disk_usage && state.reportDetails.disk_usage.total_disk_usage ? state.reportDetails.disk_usage.total_disk_usage : 0}</p>
                                    <small>Disk&nbsp;Usage</small>
                                </div>
                            </div>
                            <div className={`dash-box bg-transparent borderWidth2px border-cyon text-lightDarkGray cursor-pointer p-0 m-0 w-100 mt-3`}>
                                <p className='mb-0'>{state.reportDetails && state.reportDetails.disk_usage && state.reportDetails.disk_usage.average_disk_usage ? state.reportDetails.disk_usage.average_disk_usage : 0}</p>
                                <small>Avg Disk Usage Per User</small>
                            </div>
                        </div>
                    </div>
                                
                    <div className='row mx-0'>
                        <div className='col-sm-12'>
                            <div className='bg-white mt-2'>
                                <div className='col-lg-12 col-12 px-3 py-1 mb-4' ref={tableRef} id={'table'}>
                                    <p className='text-black mb-1'>{state.tableDetails && state.tableDetails.description ? state.tableDetails.description : ''}</p>
                                    <div className='table-responsive mt-2'>
                                        <table className='table table-striped mb-0 border-0'>
                                            <thead className='text-white bg-dark2'>
                                                <tr>
                                                    {state.tableDetails && state.tableDetails.fields ?
                                                        state.tableDetails.fields.map(head => {
                                                            return(
                                                                <th className='f18'>{head}</th>
                                                            )
                                                        })
                                                    
                                                    : null}
                                                </tr>
                                            </thead>
                                            <tbody className='text-black bg-whtie'>
                                                {state.tableDetails && state.tableDetails.table_data ?
                                                    state.tableDetails.table_data.map((row, index)=> {
                                                        return(
                                                            <tr className={`${!index ? '' : 'border-lightGray-top'}`} onClick={() => {
                                                                if(row.user_name) {
                                                                    setState(prevState => ({ ...prevState, selectedUserDetails: row, callHpcReports: true, showLoading: true, graphData1: {}, graphData2: {} }))
                                                                }
                                                            }}>
                                                                {state.tableDetails && state.tableDetails.fields ?
                                                                    state.tableDetails.fields.map((head, hIndex) => {
                                                                        return(
                                                                            <td className={`text-black ${!hIndex ? '' : ''} f16`}>{row[head]}</td>
                                                                        )
                                                                    })
                                                                : null}
                                                            </tr>
                                                        )

                                                    })
                                                : null}
                                            </tbody>
                                        </table>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                    </React.Fragment>
                }
            </div>
        </div>
    
    )
}
export default DiskReportSection;
    