/*************************************************
 * Tvastar
 * @exports
 * @file ApexTrendStackedBarChart.js
 * @author Prakash // on 07/03/2022
 * @copyright © 2022 Tvastar. All rights reserved.
 *************************************************/
import React, { useCallback, useEffect, useState } from 'react'
import { momentConvertionUtcToLocalTime, momentDateGivenFormat } from '../../../utils/utility'
import Chart from 'react-apexcharts'


const ApexTrendStackedBarChart = (props) => {
    const [state, setState] = useState({ })

    const zoomData = useCallback((xaxis) => {
        var startDate = momentDateGivenFormat(new Date(xaxis.min), 'YYYY-MM-DD HH:mm:ss')
        var endDate = momentDateGivenFormat(new Date(xaxis.max), 'YYYY-MM-DD HH:mm:ss')
        props.zoomPeriod(startDate, endDate)
    }, [props])
    
    useEffect(() => {
        let graphDetails = props.graphData;
        if(graphDetails){
            let graphLabels = graphDetails.labels
            let graphData = graphDetails.data
            let colors = props.colors ? props.colors : ['#5F5BA2', '#9A77D1', '#3F73AD', '#24A597']
            let totalCount = 0
            let series = []
            if(graphData) {
                let dataArray = []
                graphData.forEach((val, index) => {
                    let dataItems = {}
                    let time = momentConvertionUtcToLocalTime(graphLabels[index], 'YYYY-MM-DD HH:mm:ss')
                    dataItems.x = time
                    dataItems.y = val
                    dataArray.push(dataItems)
                })

                // if(graphDetails.start_time && graphDetails.start_time !== "" && !graphLabels.includes(momentDateGivenFormat(graphDetails.start_time, 'YYYY-MM-DD HH:mm:00'))) {
                //     let dataItems = {}
                //     dataItems.x = momentConvertionUtcToLocalTime(graphDetails.start_time, 'YYYY-MM-DD HH:mm:00')
                //     dataItems.y = 0 
                //     dataArray.push(dataItems) //to initialize the start time in the graph
                // }
                
                // if(graphDetails.end_time && graphDetails.end_time !== "" && !graphLabels.includes(momentDateGivenFormat(graphDetails.end_time, 'YYYY-MM-DD HH:mm:00'))) {
                //     let dataItems = {}
                //     dataItems.x = momentConvertionUtcToLocalTime(graphDetails.end_time, 'YYYY-MM-DD HH:mm:00')
                //     dataItems.y = 0
                //     dataArray.push(dataItems) //to initialize the end time in the graph
                // }

                let seriesRow = {}
                seriesRow.name = ""
                seriesRow.data = dataArray
                
                series = [seriesRow]
            }
            
            // let backgroundColors = {}
            // if(props.showBackgroundBarColors) {
            //     backgroundColors = {
            //         ranges: [{
            //             from: 1,
            //             to: 0.9,
            //             color: 'red'
            //         }],
            //         // backgroundBarColors: props.backgroundBarColors ? props.backgroundBarColors : ['#EDEDED'],
            //         backgroundBarOpacity: 0,
            //         // backgroundBarRadius: props.backgroundBarShape && props.backgroundBarShape === 'rounded' ? (props.barRadius ? props.barRadius : 10) : 0,
            //         backgroundBarRadius: 0
            //     }
            // }

            let eventFunction = {}
            if(props.getZoomPeriod) {
                eventFunction.zoomed = (chartContext, { xaxis, yaxis }) => {
                    zoomData(xaxis)
                }
            }
            
            let options = {
                tooltip: {
                    enabled: true,
                    custom: function({series, seriesIndex, dataPointIndex, w}) {
                        let val  = series[seriesIndex][dataPointIndex];
                        let labelName = w.globals.initialSeries[seriesIndex].name
                        return '<div class="arrow_box">' +
                            '<span> '+ momentDateGivenFormat(w.globals.initialSeries[0].data[dataPointIndex].x, 'DD MMM YYYY HH:mm') + 
                            ' <span style="color:'+ w.globals.colors[seriesIndex] +'">' +  labelName + '</span> ' + val + ' </span>' +
                            '</div>'
                    },
                    style: {
                        fontSize: '9px',
                    },
                },
                chart: {
                    redrawOnParentResize: true,
                    toolbar: {
                        show: false,
                    },
                    zoom: {
                        enabled: props.zoomEnabled ? true : false,
                    },
                    sparkline: {
                        enabled: props.sparkline
                    },
                    animations: {
                        enabled: false,
                    },
                    type: 'column',
                    height: 150,
                    stacked: props.stacked,
                    
                    events: eventFunction
                },   
                colors: colors,             
                plotOptions: {
                    bar: {
                        horizontal: props.horizontal,
                        barHeight: props.barHeight,
                        borderRadius: props.barEndShape && props.barEndShape === 'rounded' ? (props.barRadius ? props.barRadius : 10) : 0,
                        startingShape: 'flat',
                        endingShape: props.barEndShape ? props.barEndShape : 'flat',
                        columnWidth: props.columnWidth ? props.columnWidth : '60%',
                        distributed: false,
                        // rangeBarOverlap: false,
                        // colors: backgroundColors
                                
                    },       
                },
                dataLabels: {
                    enabled: false
                },
                grid: {
                    show: false,			        
                    xaxis: {
                        lines: {
                            show: false
                        },
                    },
                    yaxis: {
                        lines: {
                            show: false
                        }
                    },
                    padding: {
                        top: 0,
                        right: 0,
                        bottom: 0,
                        left: -5
                    },
                },                
                xaxis: {
                    show: props.xaxis,
                    tooltip: {
                        enabled: false
                    },
                    axisTicks: {
                        show: false
                    },
                    type: 'datetime',
                    axisBorder: {
                        show: true,
                        color: '#434B5E',
                    },
                    labels: {
                        datetimeUTC: false,
                        style: {
                            colors: props.axisLabelColor,
                            fontSize: '11px',
                            fontWeight: 'bolder',
                        },
                    },
                    // offsetX: 15,
                },                

                yaxis: {
                    show: props.yaxis,
                    tickAmount: props.yaxisTickAmount ? props.yaxisTickAmount : null,
                    axisBorder: {
                        show: false,
                        color: '#434B5E',
                    },
                    labels: {
                        offsetX: -10,
                        style: {
                            colors: props.axisLabelColor,
                            fontSize: '11px',
                            fontWeight: 'bolder',
                        },
                        formatter: function(val, index) {
                            if ((val / 1000) >= 1) {
                                val = val / 1000
                            }
                            if(val % 1 !== 0) {
                                val = val.toFixed(1);
                                return val;
                            }
                            return val
                        },
                    },
                },
                legend: {
                    show: props.legend,
                    markers: {
                        radius: 12,
                    },
                    labels: {
                        colors: colors,
                        useSeriesColors: true
                    },
                },
            }
            setState(prevState => ({ ...prevState, series, options, totalCount }))
        }
    }, [props, zoomData])

    return(
        <div className={`${props.className}`}>
            {/* <div className={`${props.graphRiskCountSection ? '' : 'hidden'}`}>
                <p className="mb-1 f16 text-white">Risk Count</p>
                <div class="flex">
                    {this.state.series && this.state.series.length > 0 && this.state.series.map((riskDetails, i) => {
                        let text = riskDetails.name
                        let color = riskDetails.color
                        return (
                            <div key={i} class="mr-4" style={{color: color}}>
                                <h6 class="m-0">{riskDetails.count}</h6>
                                <p class="m-0 text-nowrap small">{text}</p>
                            </div>
                            // <div key={i} className={`flex riskcout flex-column text-left`} style={{color: color}}>
                            //     <h5 className="mb-0"></h5>
                            //     <p className="mt-1 mb-0"></p>
                            // </div>
                        )
                    })}
                </div>
            </div> */}
            {state.series ?
                <Chart options={state.options} series={state.series} type="bar" height={props.height} />
            : null}
        </div>
    )
}

export default ApexTrendStackedBarChart