/*************************************************
 * Collider
 * @exports
 * @file ApexListBarChart.js
 * @author Prakash // on 12/06/2023
 * @copyright © 2020 Collider. All rights reserved.
 *************************************************/
import React, { useCallback, useEffect, useState } from 'react'
import { capitalizeTheFirstLetterOfEachWord, momentConvertionUtcToLocalTime, unitTimeConvertionDetail } from '../../../../utils/utility'
import Chart from 'react-apexcharts'

const ApexListBarChart = (props) => {

    const [state, setState] = useState({})

    const drawGraph = useCallback((graphData) => {
        let graphLables = graphData.labels
        let data = []
        for(let x=0;x<graphLables.length;x++) {
            let dataItems = {}
            if(props.xaxisFormat === 'datetime') {
                dataItems.x = momentConvertionUtcToLocalTime(graphLables[x], 'DD MMM Y HH:mm')
            } else if(props.xaxisFormat === 'date') {
                dataItems.x = momentConvertionUtcToLocalTime(graphLables[x], 'DD MMM Y')
            } else {
                dataItems.x = graphLables[x]
            }
            dataItems.y  = graphData.data[x]
            data.push(dataItems)
        }
        let series = [
            {
                name: props.graphData.name ? props.graphData.name : '',
                data: data,
                unit: props.graphData.unit ? props.graphData.unit : '',
                additionalTooltip: props.graphData.tooltipValues ? props.graphData.tooltipValues : '',
                convertTime: props.graphData.convertTime ? props.graphData.convertTime : '',
            }
        ]
        
        let fillData = {}
        if(props.gradient) {
            fillData = {
                colors:[props.gradientColor ? props.gradientColor[0] : "#26B68F"],
                type: 'gradient', // gradient
                gradient: {
                    type: props.horizontal ? 'horizontal' : 'vertical', // The gradient in the horizontal direction
                    gradientToColors: [props.gradientColor ? props.gradientColor[1] : '#bee9de'], // The color at the end of the gradient
                    opacityFrom: .9, // transparency
                    opacityTo: 1,
                    stops:[0, 120]
                }
            }
        }

        let options = {
            tooltip: {
                enabled: props.tooltip,
                style: {
                    fontSize: '9px',
                },
                custom: function({series, seriesIndex, dataPointIndex, w}) {
                    let val  = series[seriesIndex][dataPointIndex]
                    let labelName = capitalizeTheFirstLetterOfEachWord(w.globals.initialSeries[seriesIndex].name.replace("_", " "))
                    let unit = w.globals.initialSeries[seriesIndex].unit !== '' ? w.globals.initialSeries[seriesIndex].unit : ''
                    let additionalTooltip = w.globals.initialSeries[seriesIndex].additionalTooltip !== '' ? w.globals.initialSeries[seriesIndex].additionalTooltip[dataPointIndex] : ''
                    let convertTime = w.globals.initialSeries[seriesIndex].convertTime
                    if(unit === "seconds" && convertTime) {
                        val = unitTimeConvertionDetail(val, unit)
                    } else {
                        val = w.globals.initialSeries[0].data[dataPointIndex].y
                    }
                    let retrunData = '<div class="arrow_box">'
                    if(additionalTooltip === '' && !Object.entries(additionalTooltip).length) {
                        retrunData += ' <span>' +  w.globals.initialSeries[0].data[dataPointIndex].x + '</span> '
                        if(labelName !== '') {
                            retrunData += '<span style="color:'+ w.globals.colors[seriesIndex] +'">' +  labelName + '</span> '+ val
                        } else {
                            retrunData += '<span style="color:#039BE5"> ' + val + '</span>'
                        }
                        if(unit !== '' && !convertTime) {
                            retrunData += '<span> ' + unit + '</span>'
                        }
                    } else if(additionalTooltip !== '' && Object.entries(additionalTooltip).length) {
                        if(!!(val % 1)) {
                           val = val.toFixed(2)
                        }
                        retrunData += '<div class="small">'
                        retrunData += '<span class="small text-lightGray">Name</span> : '+w.globals.initialSeries[0].data[dataPointIndex].x+ '</div>'
                        Object.entries(additionalTooltip).forEach(([key, value]) => {
                            if(value !== '') {
                                retrunData += '<div class="small"> <span class="small text-lightGray">'+capitalizeTheFirstLetterOfEachWord(key.replace('_', ' '))+'</span> : '+value+'</div>'
                            }
                        })
                        retrunData += '</div>'
                    }
                    retrunData += '</div>'

                    return retrunData
                }
            },				
            chart: {
                redrawOnParentResize: true,
                toolbar: {
                    show: false,
                },
                zoom: {
                    enabled: false,
                },
                sparkline: {
                    enabled: props.sparkline
                },
                animations: {
                    enabled: props.animations
                },
                type: 'bar',
                height: 150,
                stacked: props.stacked,
            },
            colors: props.colors ? props.colors : ['#26B68F', '#2ED1BE', '#67E1D6', '#00B5D4'],
            plotOptions: {
                bar: {
                    horizontal: props.horizontal,
                    barHeight: props.barHeight,
                    columnWidth: props.columnWidth ? props.columnWidth : '60%',
                    distributed: props.chartDistributed ? true : false,
                            
                },       
            },
            dataLabels: {
                enabled: false
            },     
            fill: fillData,        
            grid: {
                show: false
            },
            xaxis: {
                show: props.xaxis,
                tooltip: {
                    enabled: false
                },
                type: props.xaxisFormat !== 'string' ? 'datetime' : 'category',
                axisBorder: {
                    show: true,
                    color:'#434B5E'
                },
                axisTicks: {
                    show: false
                },
                labels: {
                    show: props.xaxisLabel,
                    style: {
                        colors: props.axisLabelColor,
                        fontSize: props.axisLabelFontSize ?  props.axisLabelFontSize : '13px',
                        fontWeight: 'bolder',
                    }
                },
                
                crosshairs: {
                    show: false,
                    width: 0,
                    position: 'back',
                    opacity: 0,
                    stroke: {
                        color: '#b6b6b6',
                        width: 0,
                        dashArray: 0,
                    },
                    fill: {
                        type: 'solid',
                        color: '#B1B9C4',
                        gradient: {
                            colorFrom: '#D8E3F0',
                            colorTo: '#BED1E6',
                            stops: [0, 100],
                            opacityFrom: 0.4,
                            opacityTo: 0.5,
                        },
                    },
                    dropShadow: {
                        enabled: false,
                        top: 0,
                        left: 0,
                        blur: 1,
                        opacity: 0.4,
                    },
                },
            },
            yaxis: {
                show: props.yaxis,
                // props.tickAmount: props.yaxisTickAmont ? props.yaxisTickAmont : 4,
                axisBorder: {
                    show: true,
                    color: '#969AA7',
                },
                labels: {
                    show: props.yaxisLabel,
                    style: {
                        colors: props.axisLabelColor,
                        fontSize: '13px',
                        fontWeight: 'bolder',
                    }
                }
            },
            legend: {
                show: props.legend,
            },
        }

        setState(prevState => ({ ...prevState, series, options }))
    }, [props])
    
    useEffect(() => {
        let graphData = props.graphData;
        if(graphData === 'mockData') {
            graphData = {}
            graphData.data = [45,67,34,71,41,93,64,77,36,34,44,10,1,3,91,45,32,3,37,21,65,45,38,59,16,3,66,85,30,62,49]
            graphData.labels = ["2021-05-12 00:00:00", "2021-05-13 00:00:00", "2021-05-14 00:00:00", "2021-05-15 00:00:00", "2021-05-16 00:00:00", "2021-05-17 00:00:00", "2021-05-18 00:00:00", "2021-05-19 00:00:00", "2021-05-20 00:00:00", "2021-05-21 00:00:00", "2021-05-22 00:00:00", "2021-05-23 00:00:00", "2021-05-24 00:00:00", "2021-05-25 00:00:00", "2021-05-26 00:00:00", "2021-05-27 00:00:00", "2021-05-28 00:00:00", "2021-05-29 00:00:00", "2021-05-30 00:00:00", "2021-05-31 00:00:00", "2021-06-01 00:00:00", "2021-06-02 00:00:00", "2021-06-03 00:00:00", "2021-06-04 00:00:00", "2021-06-05 00:00:00", "2021-06-06 00:00:00", "2021-06-07 00:00:00", "2021-06-08 00:00:00", "2021-06-09 00:00:00", "2021-06-10 00:00:00", "2021-06-11 00:00:00"]
        }
        if(graphData && Object.entries(graphData).length) {
            drawGraph(graphData)
        }
    }, [props, drawGraph])

    
    return (
        state.series ?
            <div className={`${props.className}`}>
                <Chart options={state.options} series={state.series} type="bar" height={props.height} width={props.width ? props.width : "100%"}/>
            </div>
        : null
    )
}

export default ApexListBarChart