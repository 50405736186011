/* eslint-disable no-mixed-spaces-and-tabs */
/*************************************************
 * kiosk
 * @exports
 * @file ProjectDetails.js
 * @author Prakash // on 23/03/2024
 * @copyright © 2024 Collider. All rights reserved.
 *************************************************/
import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux'; // Import useDispatch hook to dispatch actions
import { listProjectDetails } from '../../../actions/Collider/ClusterAction'
import ApexLineChartSpendBoard from '../../common/charts/ApexLineChartSpendBoard';
import DetailsSidePanel from './DetailsSidePanel';
import { momentConvertionUtcToLocalTime, momentDateGivenFormat, removeUnderScore } from '../../../utils/utility';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import ResizeableDarkThemeTable from '../../designComponents/Table/ResizeableDarkThemeTable';
import {Icon} from "@iconify/react";

const ProjectDetails = (props) => {
    const [state, setState] = useState({
        showLoading: true,

        startRecord: 0,
        currentPage: 1,
        totalPages: 1,
        perPage: 1,

        projectDetails: [],
        selectedprojectDetails: [],
        filteredProjectDetails: [],
        callSearch: true,
        periodOptions: [
            {label: "Daily", value: "daily"},
            {label: "Weekly", value: "weekly"},
			{label: "Monthly", value: "monthly"},
			{label: "Quarterly", value: "quarterly"},
			{label: "Half Yearly", value: "half-yearly"}
		],
        graphOrderColors: [['#854097'],['#693EBC'],['#775BA2'],['#9A77D1']]
        // order: ['jobs_executed', 'cumulative_CPU_cores', 'compute_cost', 'concurrent_CPU_cores']
    })

    const dispatch = useDispatch(); // Create a dispatch function

    useEffect(() => {
        if(state.showLoading) {
            setTimeout(() => { setState(prevState => ({ ...prevState,  showLoading: false })) }, 5000);
        }
    }, [state.showLoading])
  
    useEffect(() => {
        if(state.callSearch) {
            setState(prevState => ({ ...prevState, callSearch: false, startRecord: 0,  currentPage: 1 }))
            let params = {}
            params.provider = props.selectedProvider && props.selectedProvider.toLowerCase()
            params.reports = true
            params.start_time = props.startDate
            params.end_time = props.endDate
            if(props.selectedProject && props.selectedProject.length) {
                params.project = props.selectedProject
            }
            if(props.selectedProjectTags && props.selectedProjectTags.length) {
                params.project_tags = props.selectedProjectTags
            }
            if(props.selectedAccount && props.selectedAccount.length) {
                params.account_id = props.selectedAccount
            }
            if(props.selectedRegion && props.selectedRegion.length) {
                params.region = props.selectedRegion
            }
            if(props.selectedClusters && props.selectedClusters.length) {
                params.cluster_name = props.selectedClusters
            }
            if(state.nextToken && state.nextToken.length) {
                params.next_token = state.nextToken
            }
            params.size = state.perPage

            dispatch(listProjectDetails(params))
                .then((response) => {
                    if(response) {
                        let results = response && response.results && response.results.length ? response.results : []
                        let totalResults = []
                        let startRecord = state.startRecord
                        let currentPage = state.currentPage 
                        if(state.nextToken && state.nextToken.length) {
                            totalResults = state.projectDetails &&  state.projectDetails.length ? state.projectDetails : []
                            startRecord = startRecord + state.perPage;
                            currentPage = currentPage+ 1
                        }

                        if(results && results.length) {
                            totalResults = totalResults.concat(results)
                        }

                        let totalPages = 1
                        if(totalResults.length > state.perPage) {
                            totalPages = Math.ceil(totalResults.length / state.perPage)
                        }
                        
                        setState(prevState => ({ ...prevState, projectResponse: response, projectDetails: totalResults, filteredProjectDetails: totalResults, detailsFormat: response && response.project_details ? response.project_details : [], showLoading: false, callConcurrentCpuSection: false, callTrenSection: true, calltoGetTrendTableDetails: true, totalPages, startRecord, currentPage, nextToken: response.next_token, totalRecords: response.total }));

                        props.callBack()
                    }
                })
        }
    }, [state.callSearch, dispatch, state.perPage, props, state.projectDetails, state.nextToken, state.startRecord, state.currentPage])

    useEffect(() => {
        if(state.callConcurrentCpuSection) {
            setState(prevState => ({ ...prevState, callConcurrentCpuSection: false }))

            state.filteredProjectDetails.forEach((item, index) => {
                let graphDetails = item.concurrent_CPU_cores ? item.concurrent_CPU_cores : []
                let graphData = {}
                graphData.labels = graphDetails.dates ? graphDetails.dates : []
                graphData.concurrent_CPU_cores = graphDetails.data ? graphDetails.data : []
                    
                setState(prevState => ({ ...prevState, ["concurrent_CPU_cores_"+index]: graphData, ['selected_Period_'+index]: 'daily' }))
            })
        }
    }, [state.callConcurrentCpuSection, state.filteredProjectDetails])

    useEffect(() => {
        if(state.callTrenSection) {
            setState(prevState => ({ ...prevState, callTrenSection: false }))
            state.filteredProjectDetails.forEach((item, index) => {
                if(item.trend) {
                    let trendDetails = item.trend
                    Object.entries(trendDetails).length && Object.entries(trendDetails).forEach(([trKey, trValue]) => { 
                        let graphData = {}
                        if(trValue.dates) {
                            graphData.labels = trValue.dates ? trValue.dates : []    
                        } else {
                            graphData.labels = item.dates ? item.dates : []
                        }
                        graphData[trKey] = trValue.data
                        graphData.tooltipLabel = item.title[trKey]
                        setState(prevState => ({ ...prevState, [trKey+'_'+index]: graphData }))
                    })
                }
                // if(item.trend) {
                //     let trendDetails = item.trend
                    
                //     Object.entries(trendDetails).length && Object.entries(trendDetails).forEach(([key, value]) => {
                //         let graphData = {}
                //         graphData.labels = item.dates ? item.dates : []
                //         graphData[key] = value

                //         setState(prevState => ({ ...prevState, [key+'_'+index]: graphData }))
                //     })
                // }
            })
        }
    }, [state.callTrenSection, state.filteredProjectDetails])

    const onChangePeriod = (period, selectedPeriodIndex, item) => {
        setState(prevState => ({ 
            ...prevState, 
            ["concurrent_CPU_cores_"+selectedPeriodIndex]: {},
            ["reports_"+selectedPeriodIndex]: [],
            ['filteredReports_'+selectedPeriodIndex]: []
        }))
    
        state.filteredProjectDetails.forEach((item, index) => {
            if(item.trend && index === selectedPeriodIndex) {
                let trendDetails = item.trend
                Object.entries(trendDetails).length && Object.entries(trendDetails).forEach(([key, value]) => {
                    setState(prevState => ({ ...prevState, [key+'_'+index]: {} }))
                })
            }
        })

        let params = {}
        params.provider = props.selectedProvider.toLowerCase()
        params.start_time = props.startDate
        params.end_time = props.endDate
        params.reports = true
        if(props.selectedProject && props.selectedProject.length) {
            params.project = props.selectedProject
        }
        if(props.selectedProjectTags && props.selectedProjectTags.length) {
            params.project_tags = props.selectedProjectTags
        }
        if(props.selectedAccount && props.selectedAccount.length) {
            params.account_id = props.selectedAccount
        }
        if(props.selectedRegion && props.selectedRegion.length) {
            params.region = props.selectedRegion
        }
        if(period) {
            params.duration_aggregate_by = period
        }
        if(item.cluster_name) {
            params.cluster_name = item.cluster_name
        }

        dispatch(listProjectDetails(params))
            .then((response) => {
                if(response) {
                    let results = response && response.results && response.results.length ? response.results : []
                    setState(prevState => ({
                        ...prevState,
                        selectedprojectDetails: results,
                        selectedFilteredProjectDetails: results,
                        // callSelectedConcurrentCpuSection: true,
                        callSelectedTrenSection: true,
                        calltoGetSelectedTrendTableDetails: true,
                        selectedPeriodIndex,
                        ['showLoading_'+selectedPeriodIndex]: false
                    }));
                }
        })
    }

    useEffect(() => {
        if(state.callSelectedConcurrentCpuSection) {
            setState(prevState => ({ ...prevState, callSelectedConcurrentCpuSection: false }))

            state.selectedFilteredProjectDetails.forEach((item, index) => {
                let graphDetails = item.concurrent_CPU_cores ? item.concurrent_CPU_cores : []
                let graphData = {}
                graphData.labels = graphDetails.dates ? graphDetails.dates : []
                graphData.concurrent_CPU_cores = graphDetails.data ? graphDetails.data : []
                    
                setState(prevState => ({ ...prevState, ["concurrent_CPU_cores_"+state.selectedPeriodIndex]: graphData }))
            })
        }
    }, [state.callSelectedConcurrentCpuSection, state.selectedFilteredProjectDetails, state.selectedPeriodIndex])

    useEffect(() => {
        if(state.callSelectedTrenSection) {            
            setState(prevState => ({ ...prevState, callTrenSection: false }))

            state.selectedFilteredProjectDetails.forEach(item => {
                if(item.trend) {
                    let trendDetails = item.trend
                    Object.entries(trendDetails).length && Object.entries(trendDetails).forEach(([trKey, trValue]) => { 
                        let graphData = {}
                        if(trValue.dates) {
                            graphData.labels = trValue.dates ? trValue.dates : []    
                        } else {
                            graphData.labels = item.dates ? item.dates : []
                        }
                        graphData[trKey] = trValue.data
                        graphData.tooltipLabel = item.title[trKey]
                        setState(prevState => ({ ...prevState, [trKey+'_'+state.selectedPeriodIndex]: graphData }))
                    })
                }
                // if(item.trend) {
                //     let trendDetails = item.trend
                //     Object.entries(trendDetails).length && Object.entries(trendDetails).forEach(([key, value]) => {
                //         let graphData = {}
                //         graphData.labels = item.dates ? item.dates : []
                //         graphData[key] = value

                //         setState(prevState => ({ ...prevState, [key+'_'+state.selectedPeriodIndex]: graphData }))
                //     })
                    
                // }
            })
        }
    }, [state.callSelectedTrenSection, state.selectedFilteredProjectDetails, state.selectedPeriodIndex])

    useEffect(() => {
        if(state.calltoGetTrendTableDetails) {
            setState(prevState => ({ ...prevState, calltoGetTrendTableDetails: false, ['showLoading_'+state.selectedItemIndex]: false })) 
            
            if(state.filteredProjectDetails && state.filteredProjectDetails.length) {
                state.filteredProjectDetails.forEach((item, index) => {

                    if(item.reports && item.reports.length) {
                        let columns = []
                        let dataRow = {}
                        dataRow.Header = 'Date'
                        dataRow.Cell = cellInfo => (
                            <React.Fragment>
                                <span>{cellInfo.row.original.date ? momentConvertionUtcToLocalTime(cellInfo.row.original.date, (item.monthly_breakup ? "MMM YY" : "DD MMM YY")) : ""}</span>
                            </React.Fragment>
                        )
                        dataRow.width = 180
                        columns.push(dataRow)
                        item.order && item.order.forEach(ord => {
                            dataRow = {}
                            dataRow.Header = removeUnderScore(ord)
                            dataRow.accessor = ord
                            dataRow.width = 220
                            columns.push(dataRow)
                        })

                        setState(prevState => ({ ...prevState, ["reports_"+index]: item.reports, ['filteredReports_'+index]: item.reports, ['report_columns_'+index]: columns }))
                    } else {
                        setState(prevState => ({ ...prevState, ["reports_"+index]: [], ['filteredReports_'+index]: [], ['report_columns_'+index]: [] }))
                    }
                })
                
            }
        }
    }, [state.calltoGetTrendTableDetails, state])

    useEffect(() => {
        if(state.calltoGetSelectedTrendTableDetails) {
            setState(prevState => ({ ...prevState, calltoGetSelectedTrendTableDetails: false }))
            
            if(state.selectedFilteredProjectDetails && state.selectedFilteredProjectDetails.length) {
                state.selectedFilteredProjectDetails.forEach((item, index) => {
                    let reports= []

                    let labels = item.dates ? item.dates : []
                    labels.forEach((lab, labIndex) =>{ 
                        let reportRow = {}
                        reportRow.timestamp = lab
                        Object.entries(item.trend).forEach(([key, value]) => {
                            reportRow[key] = value[labIndex] ? value[labIndex] : 0
                        })
                        reports.push(reportRow)
                    })

                    if(reports.length) {
                        const reportColumns = Object.entries(reports[0]).map(([tKey, tValue]) =>{
                            let header = ''
                            let width = '100%'
                            let textColor = ''
                            let className = 'text-truncate'
                            let accessor = ''
                            let Cell = ''
                            if(tKey === 'timestamp') {
                                header = 'Date'
                                width = 180
                                accessor = tKey
                                Cell = cellInfo => (<span>{cellInfo.row.original.timestamp ? momentDateGivenFormat(cellInfo.row.original.timestamp, 'DD MMM YYYY')  : ''}</span>)
                            } else {
                                header = removeUnderScore(tKey)
                                width = 200
                                className = 'text-truncate'
                                accessor = tKey
                                Cell = cellInfo => cellInfo.row.original[tKey]
                            }
                            return {
                                Header: header,
                                accessor: accessor,
                                width: width,
                                Cell: Cell,
                                textColor: textColor,
                                className: className
                            }
                        })
                        
                        setState(prevState => ({ ...prevState, ["reports_"+state.selectedPeriodIndex]: reports, ['filteredReports_'+state.selectedPeriodIndex]: reports, ['report_columns_'+state.selectedPeriodIndex]: reportColumns }))
                    } else {
                        const reportColumns = []	
                        setState(prevState => ({ ...prevState, ['reports_'+state.selectedPeriodIndex]: reports, ['filteredReports_'+state.selectedPeriodIndex]: reports, ['report_columns_'+state.selectedPeriodIndex]: reportColumns }))
                    }
                })
            }
        }
    }, [state.calltoGetSelectedTrendTableDetails, state])

    const handleClickOutside = (event) => {}

    // Function to navigate between pages
    const navigatePage = (action, currentPage) => {
        // Update startRecord and currentPage based on action
        let startRecord = state.startRecord
        if(action === 'next' && currentPage === state.totalPages && state.nextToken && state.nextToken.length) {
            setState(prevState => ({ ...prevState, showLoading: true, callSearch: true }))
        } else if(action === 'next' && currentPage !== state.totalPages) {
            startRecord = startRecord + state.perPage;
            setState(prevState => ({ ...prevState, currentPage: currentPage+ 1 }));
        } else if(action === 'previous' && currentPage !== 0) {
            setState(prevState => ({ ...prevState, currentPage: currentPage - 1 }));
            startRecord = startRecord - state.perPage;
        } else if(action === 'start' && currentPage !== 0) {
            setState(prevState => ({ ...prevState, currentPage: 1 }));
            startRecord = 0;
        } else if(action === 'end' && currentPage !== 0) {
            setState(prevState => ({ ...prevState, currentPage: state.totalPages }));
            startRecord = (state.totalPages - 1) * state.perPage;
        }
        setState(prevState => ({ ...prevState, startRecord }))
    }

    // const gotoPage = (pageNumber) => {
    //     // Go to the specified page and update state        
    //     if(pageNumber > 0  && pageNumber <= state.totalPages) {
    //         let startRecord = (pageNumber - 1) * state.perPage
    //         setState(prevState => ({ ...prevState, currentPage: pageNumber, pageNumber, startRecord }))
    //     } else  if(pageNumber > state.totalPages) {
    //         setState(prevState => ({ ...prevState, pageNumber: state.currentPage }))
    //     } else {
    //         setState(prevState => ({ ...prevState, pageNumber }))
    //     }
    // }

    const downloadPDF = async (index, item) => {
        const chartContainer1 = document.getElementById('chartContainer_'+index+'_0');
        const chartContainer2 = document.getElementById('chartContainer_'+index+'_1');
        const chartContainer3 = document.getElementById('chartContainer_'+index+'_2');
        const chartContainer4 = document.getElementById('chartContainer_'+index+'_3');
        const chartContainer5 = document.getElementById('chartContainer_'+index+'_4');
        const chartContainer6 = document.getElementById('chartContainer_'+index+'_5');
        // const chartContainer2 = document.getElementById('chartContainer2');
    
        if (!chartContainer1) {
          console.error("Chart container not found.");
          return;
        }
    
        try {
            const canvas1 = await html2canvas(chartContainer1);
            const canvas2 = await html2canvas(chartContainer2);
            const canvas3 = await html2canvas(chartContainer3);
            const canvas4 = await html2canvas(chartContainer4);
            const canvas5 = await html2canvas(chartContainer5);
            const canvas6 = await html2canvas(chartContainer6);
            //   const canvas2 = await html2canvas(chartContainer2);
            const pdf = new jsPDF();
            // const pdfChartHeight = 200;
            // // Add the chart to the PDF
            // const imgData = canvas1.toDataURL('image/png');
            // const imgWidth = pdf.internal.pageSize.getWidth() - 20; // Set width to full page width
            // const imgHeight = (pdfChartHeight * imgWidth) / canvas1.width;

            // const imgData = canvas.toDataURL('image/png');
            // const pdfChartWidth = pdf.internal.pageSize.getWidth() - 20;
            // const pdfChartHeight = 100;
            // const pageHeight = pdf.internal.pageSize.getHeight() - 20;

            // const contentHeight = chartContainer1.offsetHeight;
            // const totalPages = Math.ceil(pageHeight / pdfChartHeight);

            // const marginBottom = 20;
            // pdf.setPage(pdf.internal.pages.length);
            // pdf.rect(0, pdf.internal.pageSize.getHeight() - marginBottom, pdf.internal.pageSize.getWidth(), marginBottom, 'F');

            // pdf.setPage(pdf.internal.pages.length);
            // pdf.rect(0, pdf.internal.pageSize.getHeight() - marginBottom, pdf.internal.pageSize.getWidth(), marginBottom, 'F');
            
            // let selectedPeriod = state.periodOptions.filter(e => e.value === state['selected_Period_' + index]).length ? state.periodOptions.filter(e => e.value === state['selected_Period_' + index])[0].label : state['selected_Period_' + index]

            pdf.text(`Report for the project ${item.project}`, 10, 10) 
            pdf.text(`spanning from ${momentDateGivenFormat(props.startDate, 'DD MMM YYYY')} to ${momentDateGivenFormat(props.endDate, 'DD MMM YYYY')}`, 10, 20)

            pdf.addImage(canvas1.toDataURL('image/png'), 'PNG', 10, 30, 100, 60);
            pdf.addImage(canvas2.toDataURL('image/png'), 'PNG', 110, 30, 100, 60);
            pdf.addImage(canvas3.toDataURL('image/png'), 'PNG', 10, 100, 100, 60);
            pdf.addImage(canvas4.toDataURL('image/png'), 'PNG', 110, 100, 100, 60);
            pdf.addImage(canvas5.toDataURL('image/png'), 'PNG', 10, 170, 100, 60);
            pdf.addImage(canvas6.toDataURL('image/png'), 'PNG', 110, 170, 100, 60);
            
            const timestamp = new Date().getTime();
            pdf.save(`project_report_${timestamp}.pdf`);

            setState(prevState => ({ ...prevState, downloading: false }))

        } catch (error) {
          console.error("Error capturing charts:", error);
          setState(prevState => ({ ...prevState, downloading: false }))
        }
    };

    return (
        <div className='bg-transparent' onClick={handleClickOutside}>
            <div className={`${(state.showLoading || state.downloading) ? '' : 'hidden'}`}>
                <div className='loading-wave'>
                    <div className='overlayEqualizerLoader'>
                        <div className="loading-bar"></div>
                        <div className="loading-bar"></div>
                        <div className="loading-bar"></div>
                        <div className="loading-bar"></div>
                    </div>
                </div>
            </div>
            {state.showSidePanel ? 
                <DetailsSidePanel
                    closeSidePanel={() => setState(prevState => ({ ...prevState, showSidePanel: false, selectedDetails: {}, selectedType: '' }))}                    
                    selectedProvider={props.selectedProvider}
                    selectedAccount={props.selectedAccount}
                    selectedRegion={props.selectedRegion}
                    startDate={props.startDate}
                    endDate={props.endDate}
                    selectedDetails={state.selectedDetails}
                    selectedType={state.selectedType}
                    detailsFormat={state.detailsFormat ? state.detailsFormat : []}
                />
            : null}
            <div className='overflow-auto'>
                <div className='flex justify-between'>
                    <p className='mb-0 self-center'>
                        <small>Showing {state.filteredProjectDetails && state.filteredProjectDetails.length} out of total {state.totalRecords} project(s)</small>
                    </p>
                    {state.totalRecords > state.perPage ?
                        <div className='flex justify-end text-white'>
                            <span className='mx-3'>Page <strong>{state.currentPage} of {Math.ceil(state.totalRecords/state.perPage)}</strong> </span>
                            <div className="pagination text-sm">
                                <button><Icon icon="system-uicons:push-left" width="20" height="20" className={`ml-2 cursor-pointer ${state.currentPage === 1 ? 'pointer-events-none text-lightGray' : 'text-info'}`} onClick={() => navigatePage('start', state.currentPage)} /></button> 
                                <button><Icon icon="prime:angle-left" width="20" height="20" className={`ml-2 cursor-pointer ${state.currentPage === 1 ? 'pointer-events-none text-lightGray' : 'text-info'}`} onClick={() => navigatePage('previous', state.currentPage)} /></button> 
                                {/* <span>
                                    Go to
                                    <input
                                        type='number'
                                        value={state.pageNumber || state.pageNumber === "" ? state.pageNumber : state.currentUpcoming}
                                        onChange={e => {
                                            const page = e.target.value ? Number(e.target.value) : ""
                                            gotoPage(page)
                                        }}
                                        className="ml-1 inputClass"
                                    />
                                </span> */}
                                <button><Icon icon="prime:angle-right" width="20" height="20" className={`ml-3 cursor-pointer ${state.currentPage === state.totalPages ? (state.nextToken && state.nextToken.length ? 'text-info' : 'pointer-events-none text-lightGray') : 'text-info'}`} onClick={() => navigatePage('next', state.currentPage)}/></button> 
                                <button><Icon icon="system-uicons:push-right" width="20" height="20" className={`ml-2 cursor-pointer ${state.currentPage === state.totalPages ? 'pointer-events-none text-lightGray' : 'text-info'}`} onClick={() => navigatePage('end', state.currentPage)} /></button>
                            </div>
                        </div>
                    : null}
                </div>
                {state.filteredProjectDetails && state.filteredProjectDetails.length ?
                    state.filteredProjectDetails.slice(state.startRecord, state.startRecord + state.perPage).map((item, index) => {
                        return(
                            <div className={`bg-dark3 px-3 rounded mb-2 pb-2`} key={"row_"+(state.startRecord + index)}>
                                <div className='flex flex-wrap'>
                                    {!state['showExpandedSectoin_'+(state.startRecord + index)] ?
                                        <React.Fragment>
                                        <div className="w-full lg:w-1/4 md:w-1/2">
                                            <div className='py-2'>
                                                <div className="flex">
                                                    <div className="py-1 w-full">
                                                        <p className="b-block mb-0">Project</p>
                                                        <p className="mb-0 text-white">
                                                            { item.project ? item.project : ''}
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {state['showLoading_'+(state.startRecord + index)] ?
                                            <div className="w-full lg:w-3/4 flex justify-center mt-4">
                                                <svg className="animate-spin h-5 w-5 mr-3 text-white" fill="currentColor" viewBox="0 0 24 24">
                                                    <circle cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="2" fill="none" />
                                                    <path d="M4 12a8 8 0 0112-6.9" />
                                                </svg>
                                            </div>
                                        :
                                            <React.Fragment>
                                            {item.trend && Object.entries(item.trend).length ? 
                                                item.order && item.order.slice(0,4).map((key, keyIndex) => {
                                                    return(
                                                        <React.Fragment key={'tr_'+keyIndex}>
                                                        {state[key+'_'+(state.startRecord + index)] && Object.entries(state[key+'_'+(state.startRecord + index)]).length ?
                                                            <div className="w-full lg:w-1/6 md:w-1/2 pr-3 cursor-pointer">
                                                                <div className='flex'>
                                                                    <div className='mt-n2 mb-n2 mr-4 self-center w-full'>
                                                                        <ApexLineChartSpendBoard
                                                                            graphData={state[key+'_'+(state.startRecord + index)]}
                                                                            sparkline={false}
                                                                            yaxis={true}
                                                                            yaxisLabel={true}
                                                                            xaxis={true}
                                                                            xaxisFormat={'datetime'}
                                                                            xaxisTitle={item.title && item.title[key] ? item.title[key] : key}
                                                                            xaxisLabel={true}
                                                                            axisLabelColor={'#666666'}
                                                                            paddingLeft={10}
                                                                            legend={false}
                                                                            stacked={false}
                                                                            height={200}
                                                                            customHpcDashboardTooltip={true}
                                                                            className={'transparentTooltip'}
                                                                            // colors={['#43b02a', '#009FDF']}
                                                                            colors={state.graphOrderColors[keyIndex]}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        : null}
                                                        </React.Fragment>
                                                    )
                                                })
                                            : null}
                                            
                                            <div className="w-full lg:w-1/12 md:w-1/2 pr-0 cursor-pointer self-center">
                                                <div className="border-b border-lightGray mx-2 w-full mb-4 flex">
                                                    <select className="form-control w-full bg-transparent text-white truncate" 
                                                        onChange={(e) => {
                                                            const selectedValue = e.target.value;
                                                            setState((prevState) => ({
                                                                ...prevState,
                                                                ['selected_Period_' + (state.startRecord + index)]: selectedValue,
                                                                ['showLoading_'+(state.startRecord + index)]: true
                                                            }));
                                                            onChangePeriod(selectedValue, (state.startRecord + index), item);

                                                        }}
                                                        value={state['selected_Period_'+(state.startRecord + index)]}
                                                    >
                                                        {state.periodOptions && state.periodOptions.map(item => {
                                                            return(
                                                                <option key={'opt_'+item.value} className='text-black' value={item.value}>{item.label}</option>
                                                            )
                                                        })}
                                                    </select>
                                                </div>
                                                <p className='mb-0 flex justify-center text-cyon cursor-pointer' onClick={() => setState(prevState => ({ ...prevState, selectedDetails: item, selectedType: 'Project', showSidePanel: true }))}>Details</p>
                                                <span className='flex justify-center'>
                                                    {!state['showExpandedSectoin_'+(state.startRecord + index)] ?
                                                        <Icon icon="basil:expand-outline" width="20" height="20" className={`text-cyon mt-3 cursor-pointer`}
                                                            onClick={() => {
                                                                setState(prevState => ({ ...prevState, ['showExpandedSectoin_'+(state.startRecord + index)]: true }))
                                                            }} 
                                                        />
                                                    :
                                                        <Icon icon="la:compress" width="20" height="20" className={`text-cyon mt-3 cursor-pointer`}
                                                            onClick={() => {
                                                                setState(prevState => ({ ...prevState, ['showExpandedSectoin_'+(state.startRecord + index)]: false }))
                                                            }} 
                                                        />
                                                    }
                                                </span>
                                            </div>
                                            </React.Fragment>
                                        }
                                        </React.Fragment>
                                    : 
                                        <div className="w-full pr-0">
                                            <div className='flex justify-between py-2'>
                                                <div className="flex justify-between w-full lg:w-3/5">
                                                    <div className="flex">
                                                        <div className="py-1 w-full">
                                                            <p className="b-block mb-0">Project</p>
                                                        </div>
                                                    </div>
                                                    {/* <div className="flex pt-2">
                                                        <div className="py-1 w-100">
                                                            <p className="b-block mb-0">Asset</p>
                                                            <p className="mb-0 text-white">{item.asset_name ? item.asset_name : ''}</p>
                                                        </div>
                                                    </div>
                                                    <div className="flex pt-2">
                                                        <div className="py-1 w-100">
                                                            <p className="b-block mb-0">Resources</p>
                                                            <p className="mb-0 text-white">
                                                                {item.account_id ?
                                                                    <span>{' '+item.account_id}</span>
                                                                : null}
                                                                {item.region ?
                                                                    <span>{' '+item.region}</span>
                                                                : null}
                                                                {item.resource_type ?
                                                                    <span>{' '+item.resource_type}</span>
                                                                : null}
                                                            </p>
                                                        </div>
                                                    </div> */}
                                                </div>
                                                <div className='flex justify-end self-center mr-4'>
                                                    <div className="border-b border-lightGray mx-2 w-fit self-center flex">
                                                        <select className="form-control w-fit bg-transparent text-white"
                                                            onChange={(e) => {
                                                                const selectedValue = e.target.value;
                                                                setState((prevState) => ({
                                                                    ...prevState,
                                                                    ['selected_Period_' + (state.startRecord + index)]: selectedValue,
                                                                    ['showLoading_'+(state.startRecord + index)]: true
                                                                }));
                                                                onChangePeriod(selectedValue, (state.startRecord + index), item);

                                                            }}
                                                            value={state['selected_Period_'+(state.startRecord + index)]}
                                                        >
                                                            {state.periodOptions && state.periodOptions.map(item => {
                                                                return(
                                                                    <option key={'opt_'+item.value} className='text-black' value={item.value}>{item.label}</option>
                                                                )
                                                            })}
                                                        </select>
                                                    </div>
                                                    <p className='mb-0 flex justify-center text-cyon self-center cursor-pointer mx-3' onClick={() => setState(prevState => ({ ...prevState, selectedDetails: item, selectedType: 'Project', showSidePanel: true }))}>Details</p>
                                                    <Icon icon="la:compress" width="20" height="20" className={`text-cyon cursor-pointer self-center`}
                                                        onClick={() => {
                                                            setState(prevState => ({ ...prevState, ['showExpandedSectoin_'+(state.startRecord + index)]: false }))
                                                        }} 
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    }
                                </div>
                                {state['showExpandedSectoin_'+(state.startRecord + index)] ?
                                    state['showLoading_'+(state.startRecord + index)] ?
                                        <div className="w-full lg:w-3/4 flex justify-center mt-4">
                                            <svg className="animate-spin h-5 w-5 mr-3 text-white" fill="currentColor" viewBox="0 0 24 24">
                                                <circle cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="2" fill="none" />
                                                <path d="M4 12a8 8 0 0112-6.9" />
                                            </svg>
                                        </div>
                                    :
                                        <div className='bg-dark p-3 mx-3 mt-2'>
                                            <div className="w-full pr-0">
                                                <div className='flex justify-end'>
                                                    <p className='text-info mb-1 cursor-pointer' onClick={() => {
                                                        setState(prevState => ({ ...prevState, downloading: true })) 
                                                        downloadPDF((state.startRecord + index), item)
                                                    }}>Download PDF</p>
                                                </div>
                                                <div className='flex flex-wrap'>
                                                    {item.trend && Object.entries(item.trend).length ? 
                                                        item.order && item.order.map((key, keyIndex) => {
                                                            return(
                                                                state[key+'_'+(state.startRecord + index)] && Object.entries(state[key+'_'+(state.startRecord + index)]).length ?
                                                                    <div className="w-full lg:w-1/2 lg:px-1 pr-0 mb-3" id={'chartContainer_'+(state.startRecord + index)+'_'+keyIndex}>
                                                                        <div className='bg-dark3 p-3 rounded'>
                                                                            <p className='mb-2 text-white text-md'>
                                                                                {item.title && item.title[key] ? item.title[key] : removeUnderScore(key)}
                                                                                {item.total && item.total[key] && (item.total[key].total || item.total[key].total === 0) ?
                                                                                    <span className='ml-4 text-lightGray'><span className='text-lightGray'>Total</span> {item.total[key].total} </span>
                                                                                : null}
                                                                                {key === "avg_cumulative_CPU_hours" && item.total && item.total[key] && (item.total[key].avg || item.total[key].avg === 0) ?
                                                                                    <span className='ml-2 text-lightGray'><span className='text-lightGray'>Avg </span>{item.total[key].avg}</span>
                                                                                : null}
                                                                                {item.total && item.total[key] && (item.total[key].max || item.total[key].max === 0) ?
                                                                                    <span className='ml-2 text-lightGray'><span className='text-lightGray'>Max </span>{item.total[key].max}</span>
                                                                                : null}
                                                                            </p>
                                                                            <div className='mt-n2 mr-4 self-center'> 
                                                                                <ApexLineChartSpendBoard
                                                                                    graphData={state[key+'_'+(state.startRecord + index)]}
                                                                                    sparkline={false}
                                                                                    yaxis={true}
                                                                                    yaxisLabel={true}
                                                                                    yaxisTitle={item.labels && item.labels[key] ? item.labels[key] : ''}
                                                                                    xaxis={true}
                                                                                    xaxisFormat={'datetime'}
                                                                                    xaxisLabel={true}
                                                                                    axisLabelColor={'#666666'}
                                                                                    paddingLeft={10}
                                                                                    legend={false}
                                                                                    stacked={false}
                                                                                    height={300}
                                                                                    customHpcDashboardTooltip={true}
                                                                                    className={'transparentTooltip'}
                                                                                    // colors={['#43b02a', '#009FDF']}
                                                                                    colors={state.graphOrderColors[keyIndex]}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                : null
                                                            )
                                                        } )
                                                    : null}
                                                    {state['reports_'+(state.startRecord + index)] ?
                                                        <div className="w-full pr-0 mt-3">
                                                            <div className='' id={'table_'+(state.startRecord + index)}>
                                                                <ResizeableDarkThemeTable
                                                                    id={'table_'+(state.startRecord + index)}
                                                                    data={state['filteredReports_'+(state.startRecord + index)].length ? state['filteredReports_'+(state.startRecord + index)] : state['filteredReports_'+(state.startRecord + index)]}
                                                                    columns = {state['report_columns_'+(state.startRecord + index)]}
                                                                    onClickRow={tableRow => {}}
                                                                    dashboard = {state['filteredReports_'+(state.startRecord + index)].length ? true : false}
                                                                    //tooltipFor={[]}
                                                                    marginRight={10}
                                                                    tableHead={'bg-black text-white'}
                                                                    tableBody={'bg-dark3 text-white'}
                                                                    tableType={'table-dark-hover'}
                                                                    perPageClass={`bg-transparent text-lightGray`}
                                                                    textColor={'text-white'}
                                                                    selectedColor={'bg-transparent'}
                                                                    sortByColumn={"date"}
                                                                    riskTooltip={[]}
                                                                />
                                                            </div>
                                                        </div>
                                                    : null}
                                                </div>
                                            </div>
                                        </div>
                                : null}
                            </div>
                        )
                    })
                : 
                    <div className='flex justify-center m-4 text-white'>
                        There are no data on this criteria. Please try adjusting your filter.
                    </div>
                }
                
                {state.totalRecords > state.perPage ?
                    <div className='flex justify-end text-white'>
                        <span className='mx-3'>Page <strong>{state.currentPage} of {Math.ceil(state.totalRecords/state.perPage)}</strong> </span>
                        <div className="pagination text-sm">
                            <button><Icon icon="system-uicons:push-left" width="20" height="20" className={`ml-2 cursor-pointer ${state.currentPage === 1 ? 'pointer-events-none text-lightGray' : 'text-info'}`} onClick={() => navigatePage('start', state.currentPage)} /></button> 
                            <button><Icon icon="prime:angle-left" width="20" height="20" className={`ml-2 cursor-pointer ${state.currentPage === 1 ? 'pointer-events-none text-lightGray' : 'text-info'}`} onClick={() => navigatePage('previous', state.currentPage)} /></button> 
                            {/* <span>
                                Go to
                                <input
                                    type='number'
                                    value={state.pageNumber || state.pageNumber === "" ? state.pageNumber : state.currentUpcoming}
                                    onChange={e => {
                                        const page = e.target.value ? Number(e.target.value) : ""
                                        gotoPage(page)
                                    }}
                                    className="ml-1 inputClass"
                                />
                            </span> */}
                            <button><Icon icon="prime:angle-right" width="20" height="20" className={`ml-3 cursor-pointer ${state.currentPage === state.totalPages ? (state.nextToken && state.nextToken.length ? 'text-info' : 'pointer-events-none text-lightGray') : 'text-info'}`} onClick={() => navigatePage('next', state.currentPage)}/></button> 
                            <button><Icon icon="system-uicons:push-right" width="20" height="20" className={`ml-2 cursor-pointer ${state.currentPage === state.totalPages ? 'pointer-events-none text-lightGray' : 'text-info'}`} onClick={() => navigatePage('end', state.currentPage)} /></button>
                        </div>
                    </div>
                : null}
            </div>
        </div>
    )
}
export default ProjectDetails