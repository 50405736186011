/* eslint-disable no-mixed-spaces-and-tabs */
/*************************************************
 * kiosk
 * @exports
 * @file CloudCommitmentSpend.js
 * @author Prakash // on 10/01/2024
 * @copyright © 2024 kiosk. All rights reserved.
 *************************************************/
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux'; // Import useDispatch hook to dispatch actions
import { capitalizeAllLetter, momentDateGivenFormat } from '../../../utils/utility';
import ApexBarChart from '../../common/charts/ApexBarChart';
import ApexNonStackedBarChart from '../../common/charts/ApexNonStackedBarChart';
import { getCloudCommitmentVsSpend } from '../../../actions/cost/BudgetAction'
import { setCostPropsDetails } from '../../../actions/cost/CostAction';

const CloudCommitmentSpend = (props) => {
    const [state, setState] = useState({
        showLoading: true,
        currencyUnit: props.currencyUnit,
        callCloudProviderDetailApi: true
    })

    const dispatch = useDispatch(); // Create a dispatch function

    const cloudCommitVsSpend = useSelector(state => state?.cost?.costPropsDetails?.cloudCommitVsSpend);

    useEffect(() => {
        setState(prevState => ({ ...prevState, callCloudProviderDetailApi: false }))
        if(cloudCommitVsSpend && cloudCommitVsSpend.hasOwnProperty(state.currencyUnit)) {
            setState(prevState => ({ ...prevState, cloudCommitment: cloudCommitVsSpend[state.currencyUnit], structureCloudProvider: true, showLoading: false }));
        }
    }, [cloudCommitVsSpend, state.currencyUnit])

    useEffect(() => {
        if(state.callCloudProviderDetailApi) {
            setState(prevState => ({ ...prevState, callCloudProviderDetailApi: false }))
            props.updateCurrency(state.currencyUnit)
            
            if(cloudCommitVsSpend && cloudCommitVsSpend.hasOwnProperty(state.currencyUnit)) {
                setState(prevState => ({ ...prevState, cloudCommitment: cloudCommitVsSpend[state.currencyUnit], structureCloudProvider: true, showLoading: false }));
            } else {
                let depParams = {
                    'start_time': momentDateGivenFormat(props.datePickerStartDate, 'YYYY-MM-DD HH:mm:ss'),
                    'end_time': momentDateGivenFormat(props.datePickerEndDate, 'YYYY-MM-DD HH:mm:ss')
                }
                if(props.without_tax) {
                    depParams.without_tax = props.without_tax
                }
                if(props.without_savings) {
                    depParams.without_savings = props.without_savings
                }
                if(props.without_credits_discounts) {
                    depParams.without_credits_discounts = props.without_credits_discounts
                }
                depParams.currency_unit = state.currencyUnit
                dispatch(getCloudCommitmentVsSpend(depParams))
                    .then((response) => {
                        if(response && !response.error) {
                            let results =  response && response.results ? response.results : []
                            setState(prevState => ({ ...prevState, cloudCommitment: results, structureCloudProvider: true, showLoading: false }))

                            if(results && Object.entries(results).length) {
                                let obj = cloudCommitVsSpend ? cloudCommitVsSpend : {}
                                obj[state.currencyUnit] = results
                                dispatch(setCostPropsDetails('cloudCommitVsSpend', obj))
                            }
                        } else {
                            setState(prevState => ({ ...prevState, cloudCommitment: {}, structureCloudProvider: true, showLoading: false }))
                        }
                    })
            }
        }
    }, [state.callCloudProviderDetailApi, state, cloudCommitVsSpend, state.currencyUnit, dispatch, props])

    
    useEffect(() => {
		if(state.structureCloudProvider) {
            setState(prevState => ({ ...prevState, structureCloudProvider: false }))
            let labels = ['Commitment', 'Spend']
            if(state.cloudCommitment && Object.entries(state.cloudCommitment).length) {
                Object.entries(state.cloudCommitment).forEach(([key, value]) => {
                    value.forEach(row => {
                        let graphData = {}
                        if(row.type === 'monthly') {
                            graphData.labels = row.dates ? row.dates : []
                            graphData.commitment = row.commitment ? row.commitment : []
                            graphData.spend = row.spend ? row.spend : []
                        } else {
                            graphData.labels = labels
                            graphData.data = [row.commitment ? row.commitment : 0, row.spend ? row.spend : 0]
                        }
                        setState(prevState => ({ ...prevState, [key+'_'+row.type]: graphData, [key+'_'+row.type+'_description']: row.description ? row.description : '', showCloudCommitment: true }))
                    })
                })
            }
		}
	}, [state.cloudCommitment, state.structureCloudProvider])

    return (
        <div className='m-2'>
            <div className='bg-dark2 rounded-md p-2'>
                <div className='flex justify-between col-lg-12'>
                    <p className='mb-1 text-white f16'>Cloud Provider Commitment</p>
                    {/* <div className='col-lg-12 flex justify-between cursor-pointer py-2 px-3' onClick={() => setState(prevState => ({ ...prevState, showCloudCommitment: !state.showCloudCommitment }))}>
                        <p className='mb-1 text-white f16'>Cloud Provider Commitment</p>
                        <span className={`ml-4 fa fa-angle-${state.showCloudCommitment ? 'up' : 'down'} f22 text-lightGray`}></span>
                    </div> */}
                    <div className='flex ml-3 mb-1 text-right text-lightGray'>Currency is in 
                        <div className='flex justify-start'>
                            <div className="flex mx-1 self-center">
                                <input type="radio"
                                    className=""
                                    checked={state.currencyUnit === 'usd' ? true : false}  
                                    onChange={() => setState(prevState => ({ ...prevState, currencyUnit: 'usd', showLoading: true, callCloudProviderDetailApi: true }))}
                                    value={state.currencyUnit ? true : false}
                                />
                            </div>
                            <p className="mb-0 mr-1 text-white self-center text-lightGray" onClick={() => setState(prevState => ({ ...prevState, currencyUnit: 'usd', showLoading: true, callCloudProviderDetailApi: true }))}>USD</p>
                            <div className="flex mx-1 self-center">
                                <input type="radio"
                                    className=""
                                    checked={state.currencyUnit === 'gbp' ? true : false}  
                                    onChange={() => setState(prevState => ({ ...prevState, currencyUnit: 'gbp', showLoading: true, callCloudProviderDetailApi: true }))}
                                    value={state.currencyUnit ? true : false}
                                />
                            </div>
                            <p className="mb-0 mr-1 text-white self-center text-lightGray" onClick={() => setState(prevState => ({ ...prevState, currencyUnit: 'gbp', showLoading: true, callCloudProviderDetailApi: true }))}>GBP</p>
                        </div>
                    </div>
                </div>
                {state.showCloudCommitment ?
                    <div className='flex flex-wrap'>
                        {state.cloudCommitment && Object.entries(state.cloudCommitment).length ?
                            Object.keys(state.cloudCommitment).map(key => {
                                return (
                                    <React.Fragment key={key}>
                                    <div className={`mb-3 lg:w-1/3 w-full flex flex-col flex-grow pr-3`}>
                                        <div className='bg-dark3 p-3 rounded-md' id="highSpending">
                                            <div className='flex justify-between'>
                                                <div className='text-white'>
                                                    <p className='mb-0'>{capitalizeAllLetter(key)} Yearly Commitment</p>
                                                    <small>{state[key+'_yearly_description'] ? state[key+'_yearly_description'] : <span>&nbsp;</span> }</small>
                                                </div>
                                            </div>
                                            <div className='-mb-4'>
                                                {state[key+'_yearly'] && Object.entries(state[key+'_yearly']).length ?
                                                    <ApexBarChart
                                                        graphData={state[key+'_yearly']}
                                                        sparkline={false}
                                                        yaxis={true}
                                                        yaxisLabel={true}
                                                        xaxis={true}
                                                        xaxisFormat={'string'}
                                                        xaxisLabel={true}
                                                        showTitle={true} //xaxis title
                                                        axisLabelColor={'#666666'}
                                                        paddingLeft={0}
                                                        legend={false}
                                                        legendPostion={'bottom'}
                                                        legendHorizontalAlign={'center'}
                                                        legendUseSeriesColor={true}
                                                        stacked={false}
                                                        height={275}
                                                        horizontal={true}
                                                        barHeight={'40%'}
                                                        barEndShape={'rounded'}
                                                        columnWidth={'25%'}
                                                        gradient={false}
                                                        gradientColor={['#009fdf', '#CD5A9F']}
                                                        hideTooltipValue={true}
                                                        backgroundBarShape={'rounded'}
                                                        backgroundBarColors={['#333947']}
                                                        showBackgroundBarColors={false}
                                                        className={'transparentTooltip'}
                                                        dataLabels= {true}
                                                        dataLabelsTextAnchor = {'end'}
                                                        dataLabelPosition={'right'}
                                                        colors={['#854097', '#693EBC', '#775BA2', '#9A77D1']}
                                                        dataLabelsOffsetX={0}
                                                        chartDistributed={true}
                                                        dataPointSelection={false}
                                                    />
                                                : null}
                                            </div>
                                        </div>
                                    </div>
                                    <div className={`mb-3 lg:w-2/3 w-full flex flex-col flex-grow`}>
                                        <div className='bg-dark3 p-3 rounded-md h-100' id="highSpending">
                                            <div className='flex justify-between'>
                                                <div className='text-white'>
                                                    <p className='mb-0'>{capitalizeAllLetter(key)} Monthly Commitment</p>
                                                    <small>{state[key+'_monthly_description'] ? state[key+'_monthly_description'] : <span>&nbsp;</span> }</small>
                                                </div>
                                            </div>
                                            <div className='-mt-2 -mb-3'>
                                                {state[key+'_monthly'] && Object.entries(state[key+'_monthly']).length ?
                                                    <ApexNonStackedBarChart
                                                        graphData={state[key+'_monthly']}
                                                        sparkline={false}
                                                        yaxis={true}
                                                        yaxisLabel={true}
                                                        xaxis={true}
                                                        xaxisFormat={'datetime'}
                                                        xaxisLabel={true}
                                                        axisLabelColor={'#666666'}
                                                        paddingLeft={-5}
                                                        legend={true}
                                                        stacked={false}
                                                        height={270}
                                                        horizontal={false}
                                                        // barHeight={'40%'}
                                                        barEndShape={'flat'}
                                                        columnWidth={'55%'}
                                                        gradient={true}
                                                        stroke={'hide'}
                                                        // colors={['#5F5BA2', '#9A77D1', '#3F73AD', '#24A597']} //['#ffadad', '#ffd6a5',	'#caffbf',	'#9bf6ff']
                                                        // colors={['#FFCD00', '#ff8200', '#0057b8', '#8e3a80']}
                                                        colors={['#854097', '#693EBC', '#775BA2', '#9A77D1']}
                                                        hideTooltipValue={true}
                                                        backgroundBarShape={'flat'}
                                                        backgroundBarColors={['#333947']}
                                                        showBackgroundBarColors={false}
                                                        className={'transparentTooltip'}
                                                    />
                                                : null}
                                            </div>
                                        </div>
                                    </div>
                                    </React.Fragment>
                                )
                            })
                        : null}
                    </div>
                : 
                    <div className='flex justify-center m-4'>
                        <svg className="animate-spin h-5 w-5 mr-2 text-white" fill="currentColor" viewBox="0 0 24 24">
                            <circle cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="2" fill="none" />
                            <path d="M4 12a8 8 0 0112-6.9" />
                        </svg>
                    </div>
                }
            </div>
        </div>
    )
}
export default CloudCommitmentSpend