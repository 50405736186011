/*************************************************
 * Collider
 * @exports
 * @file OnboardAccountSidePanel.js
 * @author Prakash // on 18/01/2024
 * @copyright © 2024 Collider. All rights reserved.
 *************************************************/
import React, { useEffect, useState, useCallback, useRef} from 'react';
import { useDispatch, useSelector } from 'react-redux'; // Import useDispatch hook to dispatch actions
import { listAllProviders, listAllRegions} from '../../../actions/commonActionNew'
import { onboardAwsAccount, listAccountModules } from '../../../actions/AccountAction'
import MultiSelectSection from '../../common/MultiSelectSection';
import { Store as CommonNotification } from 'react-notifications-component';
import {Icon} from "@iconify/react";
import { convertToLowerCase }from "../../../utils/utility"

const OnboardAccountSidePanel = ({ closeSidePanel, refreshApi, pageMode, selectedAccountDetails }) => {
    const clickOut = useRef()

    const [state, setState] = useState({
        clusters: [],
        callAccountFilters: true,
        onLoad: true,
    })

    const dispatch = useDispatch(); // Create a dispatch function
    const providers = useSelector(state => state?.filters?.providers || false)

    const handleKeyDown = useCallback((event) => {
        if (event.key === 'Escape' || event.keyCode === 27) {
            closeSidePanel();
        }
    }, [closeSidePanel])
    
    useEffect(() => {
        // Add event listener for keydown
        window.addEventListener('keydown', handleKeyDown);
    
        // Cleanup event listener on component unmount
        return() => {
          window.removeEventListener('keydown', handleKeyDown);
        };
    }, [handleKeyDown]);

    useEffect(() => {
        if(providers) {
            setState(prevState => ({
                ...prevState,
                providers: providers,
				selectedProvider: providers && providers.length ? (providers.filter(e => e.provider_name === 'AWS').length ? 'AWS' : providers[0].provider_name) : ""
            }));
        } else {
            let params = {}
            dispatch(listAllProviders(params))
                .then((response) => {
                    if(response) {
                        setState(prevState => ({ ...prevState, providers: response }));
                    }
                })
        }
    }, [dispatch, providers]);

    useEffect(() => {
        if(state.selectedProvider) {
            // setState(prevState => ({ ...prevState, callAccountFilters: false }))
            let params = { "provider" : convertToLowerCase(state.selectedProvider) };
            dispatch(listAllRegions(params))
                .then((response) => {
                    if(response) {
                        // let roleDetails = []
                        // if(pageMode !== 'Create') {
                        //     roleDetails = response.results ? response.results.filter(e => e.account_id === selectedAccountDetails.roleId).length ? response.results.filter(e => e.account_id === selectedAccountDetails.account_id) : [] : []
                        // }
                        let regions = response[0]?.provider_regions ? response[0].provider_regions : []
                        setState(prevState => ({ ...prevState, regions }));
                    }
                })
        }
    }, [state.callAccountFilters, dispatch, pageMode, state.selectedProvider, selectedAccountDetails])

    useEffect(() => {
        if(state.callAccountFilters) {
            setState(prevState => ({ ...prevState, callAccountFilters: false }))
            let params = {};
            dispatch(listAccountModules(params))
                .then((response) => {
                    if(response) {
                        // let roleDetails = []
                        // if(pageMode !== 'Create') {
                        //     roleDetails = response.results ? response.results.filter(e => e.account_id === selectedAccountDetails.roleId).length ? response.results.filter(e => e.account_id === selectedAccountDetails.account_id) : [] : []
                        // }
                        let modules = response.results ? response.results : []

                        setState(prevState => ({ ...prevState, rolesList: response.results ? response.results : [], modules }));
                    }
                })
        }
    }, [state.callAccountFilters, dispatch, pageMode, selectedAccountDetails])

    useEffect(() => {
        if(state.callSaveFunction) {
            setState(prevState => ({ ...prevState, callSaveFunction: false }))
            let hasError = false
            let params = {}
            params.accounts_info = [{}]
            if(!state.account_id || !state.account_name || !state.role_arn || !state.selectedModule) {
                hasError = true
            }
            // if(pageMode === 'Create') {
            //     if(!state.emailId || !validateEmail(state.emailId)) {
            //         hasError = true
            //     }
            //     params.emailId = state.emailId
            // }
            params.accounts_info[0].provider = convertToLowerCase(state.selectedProvider)
            params.accounts_info[0].region = convertToLowerCase(state.selectedRegion)
            params.accounts_info[0].account_id = state.account_id
            params.accounts_info[0].account_name = state.account_name
            params.accounts_info[0].role_arn = state.role_arn
            if(state.selectedModule && state.selectedModule.length) {
                let modules = []
                state.selectedModule.forEach(module => {
                    modules.push(module)
                })
                params.accounts_info[0].modules = modules
            }
            console.log(params)
            // if(pageMode === 'Edit') {
            //     params.update_user_id = selectedAccountDetails.userId
            // }

            setState(prevState => ({ ...prevState, hasError, saveLoading: hasError ? false : true }))

           if(!hasError) {
                if(pageMode === 'Create') {
                    dispatch(onboardAwsAccount(params))
                        .then((response) => {
                            let messageType = 'danger'
                            let message = response.message ? response.message : 'Error in saving Account'

                            if(response && response.status) {
                                refreshApi()
                                messageType = 'success'
                                message = response.message ? response.message : 'Account Created Successfully'

                                setTimeout(() => closeSidePanel(), 2000)
                            } else {
                                setState(prevState => ({ ...prevState, saveLoading: false }))
                            }

                            CommonNotification.addNotification({
                                //title: 'Wonderful!',
                                message: message,
                                type: messageType,
                                insert: 'top',
                                container: 'top-center',
                                // animationIn: ['animate__animated', 'animate__fadeIn'],
                                // animationOut: ['animate__animated', 'animate__fadeOut'],
                                dismiss: {
                                duration: 5000,
                                onScreen: false,
                                pauseOnHover: true,
                                showIcon: true,
                                }
                            });
            
                            // setState(prevState => ({ ...prevState, createRespons: response }))
                        })
                } 
                // else {
                //     dispatch(updateAccount(params))
                //         .then((response) => {
                //             let messageType = 'danger'
                //             let message = response.message ? response.message : 'Error in updating Account'
                //             if(response && response.status) {
                //                 messageType = 'success'
                //                 message = response.message ? response.message : 'Account Updated Successfully'

                //                 refreshApi()
                //             }

                //             setTimeout(() => setState(prevState => ({ ...prevState, saveLoading: false })), 2000)
            
                //             // setState(prevState => ({ ...prevState, saveLoading: false, message, messageType, createErrorMessage }))
            
                //             CommonNotification.addNotification({
                //                 //title: 'Wonderful!',
                //                 message: message,
                //                 type: messageType,
                //                 insert: 'top',
                //                 container: 'top-center',
                //                 // animationIn: ['animate__animated', 'animate__fadeIn'],
                //                 // animationOut: ['animate__animated', 'animate__fadeOut'],
                //                 dismiss: {
                //                 duration: 5000,
                //                 onScreen: false,
                //                 pauseOnHover: true,
                //                 showIcon: true,
                //                 }
                //             });
                //         })
                // }
            }
        }
    }, [state.callSaveFunction, dispatch, pageMode, closeSidePanel, refreshApi, state, selectedAccountDetails])    

    const handleChildClick = (event, type, dropdownType, section) => {	
        
        if (clickOut.current && !clickOut.current.contains(event.target)) {
            closeSidePanel()
        }
		event.stopPropagation();
		let clickedChild = []
		if(type === 'child') {
            if(state.clickedChild && state.clickedChild.length) {
                state.clickedChild.forEach(row => {
                    setState(prevState => ({ ...prevState, [row]: false }))
                })
            }
            clickedChild.push(section)
            setState(prevState => ({ ...prevState, [section]: dropdownType === "singleDropDown" && state[section] ? false : true, clickedChild }))
        } else if(type === 'parent') {
            if(state.clickedChild && state.clickedChild.length) {
                state.clickedChild.forEach(row => {
                    setState(prevState => ({ ...prevState, [row]: false }))
                })
            }
            
            setState(prevState => ({ ...prevState, clickedChild }))
        }
	}

    const removefromList = (value, listField) => {
        let filteredListItem = state[listField].filter((e) => e !== value);
    
        let array = [];
        filteredListItem.forEach((row) => {
          let rowValue = {
            value: row,
            label: row,
          };
          array.push(rowValue);
        });
    
        setState((prevState) => ({ ...prevState, [listField]: filteredListItem }));
      };

    return (
        <div className='advanced-search' style={{ zIndex: 999 }} onClick={(event) => handleChildClick(event, 'parent')}>
            <div className={`${state.showLoading ? '' : 'hidden'}`}>
                <div className='loading-wave'>
                    <div className='overlayEqualizerLoader'>
                        <div className="loading-bar"></div>
                        <div className="loading-bar"></div>
                        <div className="loading-bar"></div>
                        <div className="loading-bar"></div>
                    </div>
                </div>
            </div>
            <div className='search w-3/5 bg-nero overflow-auto' ref={clickOut}>
                <div className='header-search bg-black flex justify-between p-2'>
                    <p className='text-white text-xl'>{pageMode === "View" ? '' : (pageMode === "Edit" ? "Edit" : "Onboard")} {pageMode === "View" ? 'Details' : 'Account'}</p>

                    <div className='self-center'>
                        <Icon icon="jam:close" className='text-white cursor-pointer' width="25" height="25" onClick={() => closeSidePanel()} />
                    </div>
                </div>
                <div className='m-2'>
                    <div className='rounded-md bg-dark3 p-3'>
                        <div className='flex flex-wrap mt-2 pt-2 border border-darkGray'>
                            <div className='py-1 lg:w-1/2 md:w-1/2 w-full'>
                                <div className='flex justify-between'>
                                    <p className="b-block mb-0 text-lightGray">Provider</p>
                                    {state.hasError && !state.selectedProvider ?
                                        <span className='text-danger self-center'>required</span>
                                    : null}
                                </div>
                                <div className={`w-50 ${state.pageType === 'View' ? 'disabled' : ''}`} onClick={(event) => {
                                    if (!state.showProvider) {
                                        event.preventDefault();
                                        handleChildClick(event,"child","singleDropDown", "showProvider");
                                      }
                                }}>
                                    <p className={`flex bg-transparent border border-lightGray rounded-md px-2 py-1 justify-between mb-0 truncate ${state.selectedProvider ? 'text-white' : 'text-lightGray'}`}>
                                        {state.selectedProvider ? state.selectedProvider : 'Select'}
                                        <Icon icon="icon-park-solid:down-one" className={`${state.selectedProvider && state.selectedProvider.length ? "text-white" : "text-lightGray"} self-center`} width="16" height="16" />
                                    </p>
                                    {state.showProvider && pageMode !== 'View' ?
                                        <div className='relative'>
                                            <MultiSelectSection 
                                                // fields={["provider_id", "provider_name"]}
                                                options={["AWS"]}
                                                selectedValues={state.selectedProvider ? state.selectedProvider : ''}
                                                callbackMultiSelect={(value) => {
                                                    if(!value || typeof(value) === 'string') {
                                                        setState(prevState => ({ ...prevState, selectedProvider: value }))
                                                    } else {
                                                        value.preventDefault()
                                                        handleChildClick(value, "search", 'singleDropDown', "")
                                                    }
                                                    // setState(prevState => ({ 
                                                    //     ...prevState, 
                                                    //     selectedProvider: value,
                                                    // }))
                                                }}
                                                singleSelection={true}
                                                widthClass={'minWidth220'}
                                                removeTopOptions={true}
                                            />
                                        </div>
                                    : null}
                                </div>
                            </div>
                        </div>
                        <div className='flex flex-wrap mt-2 pt-2 border border-darkGray'>
                            <div className='py-1 lg:w-1/2 md:w-1/2 w-full'>
                                <p className='b-block mb-0 flex'>
                                    Account Id
                                    {pageMode !== 'View' ?
                                        <span className="fa fa-star text-purplishRed ml-1 f8 self-center"></span>
                                    : null}
                                    {state.hasError && !state.account_id ?
                                        <span className='mb-0 text-red ml-2'>required</span>
                                    : null}
                                </p>
                                {pageMode !== 'View' ?
                                    <div className='flex'>
                                        <input
                                            type='text'
                                            placeholder='enter account id'
                                            className={`border border-lightGray rounded-md bg-transparent px-2 py-1 text-white w-full`}
                                            value={state.account_id ? state.account_id : ''}
                                            onChange={e => setState(prevState => ({ ...prevState, account_id: e.target.value }))}
                                        />
                                    </div>
                                : 
                                    <p className='text-white b-block mb-0'>{state.account_id}</p>
                                }
                            </div>
                            <div className='py-1 lg:w-1/2 md:w-1/2 w-full lg:pl-3 md:pl-3 pl-0'>
                                <p className='b-block mb-0 flex'>
                                    Account Name
                                    {pageMode !== 'View' ?
                                        <span className="fa fa-star text-purplishRed ml-1 f8 self-center"></span>
                                    : null}
                                    {state.hasError && !state.account_name ?
                                        <span className='mb-0 text-red ml-2'>required</span>
                                    : null}
                                </p>
                                {pageMode !== 'View' ?
                                    <div className='flex'>
                                        <input
                                            type='text'
                                            placeholder='enter account name'
                                            className={`border border-lightGray rounded-md bg-transparent px-2 py-1 text-white w-full`}
                                            value={state.account_name ? state.account_name : ''}
                                            onChange={e => setState(prevState => ({ ...prevState, account_name: e.target.value }))}
                                        />
                                    </div>
                                : 
                                    <p className='text-white b-block mb-0'>{state.account_name}</p>
                                }
                            </div>
                        </div>
                        <div className='flex flex-wrap mt-2 pt-2 border border-darkGray'>
                            <div className='py-1 lg:w-1/2 md:w-1/2 w-full'>
                                <div className='flex justify-between'>
                                    <p className="b-block mb-0 text-lightGray">Modules</p>
                                    {state.hasError && !state.selectedModule ?
                                        <span className='text-danger self-center'>required</span>
                                    : null}
                                </div>
                                <div className={`w-50 ${state.pageType === 'View' ? 'disabled' : ''}`} onClick={(event) => {
                                    event.preventDefault();
                                    handleChildClick(event, 'child', 'muliselectDropDown', "showModules")
                                }}>
                                    <p className={`flex bg-transparent border border-lightGray rounded-md px-2 py-1 justify-between mb-0 truncate ${state.selectedModule ? 'text-white' : 'text-lightGray'}`}>
                                        {state.selectedModule && state.selectedModule.length ? state.selectedModule.length + ' Selected' : 'Select'}
                                        <Icon icon="icon-park-solid:down-one" className={`${state.selectedModule && state.selectedModule.length ? "text-white" : "text-lightGray"} self-center`} width="16" height="16" />
                                    </p>
                                    {state.showModules && pageMode !== 'View' ?
                                        <div className='relative'>
                                            <MultiSelectSection 
                                                // fields={["application_id", "application_name"]}
                                                removeTop={true}
                                                options={state.modules ? state.modules : []}
                                                selectedValues={state.selectedModule ? state.selectedModule : ''}
                                                callbackMultiSelect={(value) => {
                                                    setState(prevState => ({ ...prevState, selectedModule: value }))
                                                }}
                                                widthClass={'minWidth220'}
                                                removeTopOptions={true}
                                            />
                                        </div>
                                    : null}
                                </div>
                                <div className='flex flex-wrap'>
                                    {state.selectedModule && state.selectedModule.length ? 
                                        state.selectedModule.map(row => {
                                            return(
                                                <span key={row} className='border border-mediumDarkGray px-2 rounded-full py-1 text-info mr-2 mb-1 text-sm text-nowrap flex'>{row}
                                                    <Icon icon="ion:close-circle-sharp" width="16" height="16"  className='text-white ml-2 self-center' onClick={() => removefromList(row, 'selectedModule') }/>
                                                </span>
                                            )
                                        })
                                    : null}
                                </div>
                            </div>
                            <div className='py-1 lg:w-1/2 md:w-1/2 w-full lg:pl-3 md:pl-3 pl-0'>
                                <p className='b-block mb-0 flex'>
                                    Role ARN
                                    {pageMode !== 'View' ?
                                        <span className="fa fa-star text-purplishRed ml-1 f8 self-center"></span>
                                    : null}
                                    {state.hasError && !state.role_arn ?
                                        <span className='mb-0 text-red ml-2'>required</span>
                                    : null}
                                </p>
                                {pageMode !== 'View' ?
                                    <div className='flex'>
                                        <input
                                            type='text'
                                            placeholder='arn:aws:iam::<account-id>:role/<role-name>'
                                            className={`border border-lightGray rounded-md bg-transparent px-2 py-1 text-white w-full`}
                                            value={state.role_arn ? state.role_arn : ''}
                                            onChange={e => setState(prevState => ({ ...prevState, role_arn: e.target.value }))}
                                        />
                                    </div>
                                : 
                                    <p className='text-white b-block mb-0'>{state.role_arn}</p>
                                }
                            </div>
                        </div>
                        <div className='flex flex-wrap mt-2 pt-2 border border-darkGray'>
                            <div className='py-1 lg:w-1/2 md:w-1/2 w-full'>
                                <div className='flex justify-between'>
                                    <p className="b-block mb-0 text-lightGray">Region</p>
                                    {state.hasError && !state.selectedRegion ?
                                        <span className='text-danger self-center'>required</span>
                                    : null}
                                </div>
                                <div className={`w-50 ${state.pageType === 'View' ? 'disabled' : ''}`} onClick={(event) => {
                                    if (!state.showRegion) {
                                        event.preventDefault();
                                        handleChildClick(event,"child","singleDropDown", "showRegion");
                                      }
                                }}>
                                    <p className={`flex bg-transparent border border-lightGray rounded-md px-2 py-1 justify-between mb-0 truncate ${state.selectedRegion ? 'text-white' : 'text-lightGray'}`}>
                                        {state.selectedRegion ? state.selectedRegion : 'Select'}
                                        <Icon icon="icon-park-solid:down-one" className={`${state.selectedRegion && state.selectedRegion.length ? "text-white" : "text-lightGray"} self-center`} width="16" height="16" />
                                    </p>
                                    {state.showRegion && pageMode !== 'View' ?
                                        <div className='relative'>
                                            <MultiSelectSection 
                                                fields={["region", "name"]}
                                                options={state.regions}
                                                selectedValues={state.selectedRegion ? state.selectedRegion : ''}
                                                callbackMultiSelect={(value) => {
                                                    if(!value || typeof(value) === 'string') {
                                                        setState(prevState => ({ ...prevState, selectedRegion: value }))
                                                    } else {
                                                        value.preventDefault()
                                                        handleChildClick(value, "search", 'singleDropDown', "")
                                                    }
                                                    // setState(prevState => ({ 
                                                    //     ...prevState, 
                                                    //     selectedRegion: value,
                                                    // }))
                                                }}
                                                singleSelection={true}
                                                widthClass={'minWidth220'}
                                                removeTopOptions={true}
                                            />
                                        </div>
                                    : null}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='flex bottom-4 right-4 justify-end bg-transparent my-2'>
                        <div className={`flex justify-end w-full`}>
                            {state.saveLoading ?
                                <button className={`btn bg-info text-md px-2 py-1 rounded-md mr-2`}>
                                    <svg className="animate-spin h-5 w-5 mr-2 text-white" fill="currentColor" viewBox="0 0 24 24">
                                        <circle cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="2" fill="none" />
                                        <path d="M4 12a8 8 0 0112-6.9" />
                                    </svg>
                                </button>
                            :  pageMode !== 'View' ? 
                                <button className={`btn bg-info text-md px-2 py-1 rounded-md mr-2 text-white`} onClick={ () => setState(prevState => ({ ...prevState, saveLoading: true, callSaveFunction: true }))}>
                                    {pageMode === 'Edit' ? 'Update' : 'Save'}
                                </button>
                            : null}
                            <button className={`btn bg-lightGray px-2 py-1 text-md rounded-md text-white`} onClick={ () => closeSidePanel()}>Cancel</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default OnboardAccountSidePanel