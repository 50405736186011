import React, { useCallback, useEffect, useState } from 'react';
import FileUploadModal from './FileUploadModal';
import { useDispatch, useSelector } from 'react-redux';
import { Store as CommonNotification } from 'react-notifications-component';
import { createFolder, deleteFile, downloadFile, getFileContent, extractFile, renameFile } from '../../../../actions/files/FileAction';
import EditFile from './EditFile';
import { setCommonPropsDetails } from '../../../../actions/commonActionNew';
import { noSpace } from '../../../../utils/utility';
import {Icon} from "@iconify/react";

export default function FileActions({ handleFileBrowserRefresh, state, getLastObjectFromBreadcrumb, fromScreen, selectedFile }) {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [newState, setNewState] = useState({
    showDeleteLoading: false
  })

  const dispatch = useDispatch()

  const currentLocation = useSelector(state => state?.filters?.commonPropsDetails?.currentLocation || false);
  const doubleClickOpenFile = useSelector(state => state?.filters?.commonPropsDetails?.doubleClickOpenFile || false);

  const toggleModal = () => {
    setModalIsOpen(!modalIsOpen);
  };

  const createFolderFunction = () => {
    setNewState(prevNewState => ({ ...prevNewState, showCreateLoading: true, hasError: false }))
    let params = {}
    params.folder_name = newState.folder_name
    let currentPath = currentLocation.path ? currentLocation.path : ''
    params.path = currentPath+'/'+newState.folder_name
    params.provider = state.selectedProvider ? state.selectedProvider.toLowerCase() : ''
    params.cluster_name = state.selectedClusters
    if(currentLocation.owner) {
      params.owner = currentLocation.owner
    }
    if(newState.folder_name) {
      dispatch(createFolder(params))
        .then((response) => {
          if(response && !response.error) {
            setNewState(prevNewState => ({ ...prevNewState, folderResponse: response }))
            let messageType = 'danger'
            let message = response && response.message ? response.message : "Folder Creation Failed"

            if(response.status) {
              message = response && response.data && response.data.message ? response.data.message : "Folder Created Successfully"
                messageType = 'success'
                setNewState(prevNewState => ({ ...prevNewState, createModal: !newState.createModal, showCreateLoading: false, folder_name: "" }))
                // toggle()
                setTimeout(() => { setNewState(prevNewState => ({ ...prevNewState, showCreateLoading: false }))}, 1000)
            } else {
                setNewState(prevNewState => ({ ...prevNewState, showCreateLoading: false }))
            }


            CommonNotification.addNotification({
                //title: 'Wonderful!',
                message: message,
                type: messageType,
                insert: 'top',
                container: 'top-center',
                // animationIn: ['animate__animated', 'animate__fadeIn'],
                // animationOut: ['animate__animated', 'animate__fadeOut'],
                dismiss: {
                    duration: 5000,
                    onScreen: false,
                    pauseOnHover: true,
                    showIcon: true,
                }
            });
          }
        })
    } else {
      setNewState(prevNewState => ({ ...prevNewState, hasError: true, showCreateLoading : false }))
    }
  }

  const handleFileRename = () => {
    if(state.statsDetail && state.statsDetail.data && state.selectedProvider) {
      let data = state.statsDetail.data
      let params = {}
      params.provider = state.selectedProvider.toLowerCase()
      params.account_id = state.selectedAccount ? state.selectedAccount : []
      params.region = state.selectedRegion ? state.selectedRegion : []
      params.cluster_name = state.selectedClusters
      params.old_path = data.path

      let fileName = params.old_path;
      // Split the file name into parts based on the "/"
      // let fileType = fileName.substring(fileName.lastIndexOf(".") + 1);
      // Remove the last string (file name)
      let newPath = fileName.substring(0, fileName.lastIndexOf("/"));
      // Add the new file name to the end

      let newFileName = newPath
      newFileName += '/'+newState.fileName
      // newFileName += '.'+fileType
      params.new_path = newFileName
      // setNewState(prevNewState => ({ ...prevNewState, showRenameBtnLoading: false }))
      dispatch(renameFile(params))
        .then((response) => {
          if(response && !response.error) {
            let messageType = 'danger'
            let message = response && response.message ? response.message : "File rename failed"

            if(response.status) {
              message = response && response && response.message ? response.message : "file renamed successfull"
                messageType = 'success'
                setTimeout(() => { setNewState(prevNewState => ({ ...prevNewState, showRenameBtnLoading: false, renameModal: false }))}, 1000)
                handleFileBrowserRefresh()
                // if(response.data && response.data.result) {
                //   let fileUrl = response.data.result
                //   window.open(fileUrl, '_blank')
                // }
            } else {
                setNewState(prevNewState => ({ ...prevNewState, showRenameBtnLoading: false }))
            }

            CommonNotification.addNotification({
                //title: 'Wonderful!',
                message: message,
                type: messageType,
                insert: 'top',
                container: 'top-center',
                // animationIn: ['animate__animated', 'animate__fadeIn'],
                // animationOut: ['animate__animated', 'animate__fadeOut'],
                dismiss: {
                    duration: 5000,
                    onScreen: false,
                    pauseOnHover: true,
                    showIcon: true,
                }
            });
          }
        })
    }
  }

  const handleFileExtract = () => {
    if(state.statsDetail && state.statsDetail.data && state.selectedProvider) {
      let data = state.statsDetail.data
      let params = {}
      params.provider = state.selectedProvider.toLowerCase()
      params.account_id = state.selectedAccount ? state.selectedAccount : []
      params.region = state.selectedRegion ? state.selectedRegion : []
      params.cluster_name = state.selectedClusters
      params.path = data.path
      // if(data.owner) {
      //   params.owner = data.owner
      // }
      dispatch(extractFile(params))
        .then((response) => {
          if(response && !response.error) {
            let messageType = 'danger'
            let message = response && response.message ? response.message : "Extract failed"

            if(response.status) {
                setTimeout(() => { setNewState(prevNewState => ({ ...prevNewState, showExtactLoading: false }))}, 1000)
                message = response && response.message ? response.message : "Extracted"
                messageType = 'success'
                handleFileBrowserRefresh()
                // if(response.data && response.data.result) {
                //   let fileUrl = response.data.result
                //   window.open(fileUrl, '_blank')
                // }
            } else {
                setNewState(prevNewState => ({ ...prevNewState, showExtactLoading: false }))
            }

            CommonNotification.addNotification({
                //title: 'Wonderful!',
                message: message,
                type: messageType,
                insert: 'top',
                container: 'top-center',
                // animationIn: ['animate__animated', 'animate__fadeIn'],
                // animationOut: ['animate__animated', 'animate__fadeOut'],
                dismiss: {
                    duration: 5000,
                    onScreen: false,
                    pauseOnHover: true,
                    showIcon: true,
                }
            });
          }
        })
    }
  }

  const handleFileDownload = () => {
    if(state.statsDetail && state.statsDetail.data &&state.statsDetail.data.type === 'file' && state.selectedProvider) {
      let data = state.statsDetail.data
      let params = {}
      params.provider = state.selectedProvider.toLowerCase()
      params.account_id = state.selectedAccount ? state.selectedAccount : []
      params.region = state.selectedRegion ? state.selectedRegion : []
      params.cluster_name = state.selectedClusters
      params.path = data.path
      if(data.owner) {
        params.owner = data.owner
      }
      dispatch(downloadFile(params))
        .then((response) => {
          if(response && !response.error) {
            let messageType = 'danger'
            let message = response && response.message ? response.message : "Download Failed"

            if(response.status) {
              message = response && response.data && response.data.message ? response.data.message : "Download initiated Successfully"
                messageType = 'success'
                setTimeout(() => { setNewState(prevNewState => ({ ...prevNewState, showDownloadLoading: false }))}, 1000)
                if(response.data && response.data.result) {
                  let fileUrl = response.data.result
                  window.open(fileUrl, '_blank')
                }
            } else {
                setNewState(prevNewState => ({ ...prevNewState, showDownloadLoading: false }))
            }

            CommonNotification.addNotification({
                //title: 'Wonderful!',
                message: message,
                type: messageType,
                insert: 'top',
                container: 'top-center',
                // animationIn: ['animate__animated', 'animate__fadeIn'],
                // animationOut: ['animate__animated', 'animate__fadeOut'],
                dismiss: {
                    duration: 5000,
                    onScreen: false,
                    pauseOnHover: true,
                    showIcon: true,
                }
            });
          }
        })
    }
  }

  const handleFileEdit = useCallback(() => {
    if(state.statsDetail && state.statsDetail.data &&state.statsDetail.data.type === 'file' && state.selectedProvider) {
      let data = state.statsDetail.data
      let params = {}
      params.provider = state.selectedProvider.toLowerCase()
      params.account_id = state.selectedAccount ? state.selectedAccount : []
      params.region = state.selectedRegion ? state.selectedRegion : []
      params.cluster_name = state.selectedClusters
      params.path = data.path
      if(data.owner) {
        params.owner = data.owner
      }
      dispatch(getFileContent(params))
        .then((response) => {
          if(response && !response.error) {
            if(response.status) {
              if(!fromScreen) {
                setNewState(prevNewState => ({ ...prevNewState, fileContent: response, showEditLoading: false }))
              } else {
                selectedFile(response)
              }
            } else {
              let messageType = 'danger'
              let message = response && response.message ? response.message : "Download Failed"
                setNewState(prevNewState => ({ ...prevNewState, showEditLoading: false }))
                CommonNotification.addNotification({
                  //title: 'Wonderful!',
                  message: message,
                  type: messageType,
                  insert: 'top',
                  container: 'top-center',
                  // animationIn: ['animate__animated', 'animate__fadeIn'],
                  // animationOut: ['animate__animated', 'animate__fadeOut'],
                  dismiss: {
                      duration: 5000,
                      onScreen: false,
                      pauseOnHover: true,
                      showIcon: true,
                  }
              });
            }            
          }
        })
    }
  }, [dispatch, state, fromScreen, selectedFile])

  const deleteFunction = () => {
    if(state.statsDetail && state.statsDetail.data &&state.statsDetail.data.type === 'file' && state.selectedProvider) {
      let data = state.statsDetail.data
      let params = {}
      params.provider = state.selectedProvider.toLowerCase()
      params.account_id = state.selectedAccount ? state.selectedAccount : []
      params.region = state.selectedRegion ? state.selectedRegion : []
      params.cluster_name = state.selectedClusters
      params.path = data.path
      if(data.owner) {
        params.owner = data.owner
      }
      if(params.path) {
          dispatch(deleteFile(params)).then((response) => {
              let messageType = 'success'
              let message = response.data && response.data.message ? response.data.message : 'Error in deleting in file'
              if(!response.status) {   
                  messageType = 'danger'
                  setNewState(prevState => ({ ...prevState, showDeleteLoading: false }))
              } else {
                  message = response.data && response.data.message ? response.data.message : 'File Deleted Successfully'
                  setNewState(prevState => ({ ...prevState, deleteModal: false, showDeleteLoading: false }))
              }

              CommonNotification.addNotification({
                  //title: 'Wonderful!',
                  message: message,
                  type: messageType,
                  insert: 'top',
                  container: 'top-center',
                  // animationIn: ['animate__animated', 'animate__fadeIn'],
                  // animationOut: ['animate__animated', 'animate__fadeOut'],
                  dismiss: {
                      duration: 5000,
                      onScreen: false,
                      pauseOnHover: true,
                      showIcon: true,
                  }
              });
          })   
      } else {
        setNewState(prevState => ({ ...prevState, showDeleteLoading: false }))
      }
    }
  }

  useEffect(() => {
    if(doubleClickOpenFile) {
      dispatch(setCommonPropsDetails('doubleClickOpenFile', false))
      if(state.statsDetail && state.statsDetail.data && state.statsDetail.data.type === 'file' && !newState.showEditLoading) {
        setNewState(prevNewState => ({ ...prevNewState, showEditLoading: true }))
        handleFileEdit()
      }
    }
  },[doubleClickOpenFile, state.statsDetail, handleFileEdit, dispatch, newState.showEditLoading])

  return (
    <div className="flex flex-wrap justify-between self-center">
      <div className="w-full flex justify-end">
        <div className='flex mb-1'>
          <button type="button" className={`${state.statsDetail && state.statsDetail.data && state.statsDetail.data.type === 'file' ? 'bg-white text-black border-mediumDarkGray cursor-pointer' : 'bg-lightGray cursor-none text-mediumDarkGray'} px-1 mr-2`} onClick={() => {
            if(state.statsDetail && state.statsDetail.data && state.statsDetail.data.type === 'file' && !newState.showExtactLoading) {
              handleFileExtract()
              setNewState(prevNewState => ({ ...prevNewState, showExtactLoading : true}))
            }
          }}>
            {newState.showExtactLoading ?
                <span className='flex'>
                  <svg className="animate-spin h-5 w-5 mr-3 text-blue-500" fill="currentColor" viewBox="0 0 24 24">
                      <circle cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="2" fill="none" />
                      <path d="M4 12a8 8 0 0112-6.9" />
                  </svg>
                  Extracting 
                </span>
            : 
              <span className='flex'><Icon icon="flowbite:envelope-open-solid" width='12' height='12' className="mr-1 self-center" />Extract</span>
            }
          </button>
          <button type="button" className={`${state.statsDetail && state.statsDetail.data && state.statsDetail.data.type === 'file' ? 'bg-white text-black border-mediumDarkGray cursor-pointer' : 'bg-lightGray cursor-none text-mediumDarkGray'} px-1 mr-2`} onClick={() => {
              if(state.statsDetail && state.statsDetail.data && state.statsDetail.data.name && !newState.renameModal) {
                setNewState(prevNewState => ({ ...prevNewState, renameModal: true, fileName: state.statsDetail && state.statsDetail.data && state.statsDetail.data.name ? state.statsDetail.data.name : "" }))
              }
            }}
          >
            <span className='flex'>Rename</span>
          </button>
          <button type="button" className={`${state.statsDetail && state.statsDetail.data && state.statsDetail.data.type === 'file' ? 'bg-white text-black border-mediumDarkGray cursor-pointer' : 'bg-lightGray cursor-none text-mediumDarkGray'} px-1 mr-2`} onClick={() => {
            if(state.statsDetail && state.statsDetail.data && state.statsDetail.data.type === 'file' && !newState.showDownloadLoading) {
              handleFileDownload()
              setNewState(prevNewState => ({ ...prevNewState, showDownloadLoading : true}))
            }
          }}>
            {newState.showDownloadLoading ?
              <span className='flex'>
                <svg className="animate-spin h-5 w-5 mr-3 text-blue-500" fill="currentColor" viewBox="0 0 24 24">
                    <circle cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="2" fill="none" />
                    <path d="M4 12a8 8 0 0112-6.9" />
                </svg>
                Downloading
              </span>
            : 
              <span className='flex'><Icon icon="fa:download" width='12' height='12' className="mr-1 self-center" />Download</span>
            }
          </button>
          <button type="button" className={`${state.statsDetail && state.statsDetail.data && state.statsDetail.data.type === 'file' ? 'bg-white text-black border-mediumDarkGray cursor-pointer' : 'bg-lightGray cursor-none text-mediumDarkGray'} px-1 mr-2`} onClick={() => {
            if(state.statsDetail && state.statsDetail.data && state.statsDetail.data.type === 'file') {
              setNewState(prevNewState => ({ ...prevNewState, deleteModal: true, showDeleteLoading: false }))
            }
          }}>
            <span className='flex'><Icon icon="mdi:trash" width='12' height='12' className="mr-1 self-center" />Delete</span>
          </button>
          <button type="button" className={`${state.statsDetail && state.statsDetail.data && state.statsDetail.data.type === 'file' ? 'bg-white text-black border-mediumDarkGray cursor-pointer' : 'bg-lightGray cursor-none text-mediumDarkGray'} px-1 mr-2`} onClick={() => {
            if(state.statsDetail && state.statsDetail.data && state.statsDetail.data.type === 'file' && !newState.showEditLoading) {
              setNewState(prevNewState => ({ ...prevNewState, showEditLoading : true}))
              handleFileEdit()
            }
          }}>
            <div className='flex'>
              {newState.showEditLoading ?
                <span className='flex'>
                  <svg className="animate-spin h-5 w-5 mr-3 text-blue-500" fill="currentColor" viewBox="0 0 24 24">
                      <circle cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="2" fill="none" />
                      <path d="M4 12a8 8 0 0112-6.9" />
                  </svg>
                  Opening
                </span>
              : 
                <span className='flex'><Icon icon="lets-icons:edit-fill" width='12' height='12' className="mr-1 self-center" />Open</span>
              }
            </div>
          </button>
          <button type="button" className={`cursor-pointer bg-white text-black px-1`} 
            onClick={() => setNewState(prevNewState => ({ ...prevNewState, createModal: true }))}
          >
            Create Folder
          </button>
          <div className="flex space-x-0 ml-3" role="group">
            <button type="button" className={`cursor-pointer bg-white text-black px-1 border-r border-black`} onClick={handleFileBrowserRefresh}>
              <Icon icon="ooui:reload" width="12" height="12" />
            </button>
            <button type="button" className={`cursor-pointer bg-white text-black border-mediumDarkGray-left px-1  `} onClick={toggleModal}>
              <span className='flex'><Icon icon="fa:upload" width='12' height='12' className="mr-1 self-center" />Upload</span>
            </button>
          </div>
          {/* <button className="btn btn-secondary btn-sm mr-3" type="button" onClick={handleFileBrowserRefresh}>
            <i className="fa fa-sync"></i>
          </button>
          <button className="btn btn-secondary btn-sm mr-3" type="button" onClick={toggleModal}>
            <i className="fa fa-upload"></i> Upload
          </button> */}
          <FileUploadModal
            isOpen={modalIsOpen}
            toggle={toggleModal}
            setModalIsOpen={setModalIsOpen}
            handleFileBrowserRefresh={handleFileBrowserRefresh}
            state={state}
            getLastObjectFromBreadcrumb={getLastObjectFromBreadcrumb}
          />

          <div id="rename-modal" tabIndex="-1" aria-hidden="true" className={`flex ${newState.renameModal ? '' : 'hidden'} overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 h-[calc(100%-1rem)] max-h-full`}>
            <div className="relative p-4 w-full max-w-md max-h-full">
                {/* Modal content */}
                <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
                    {/* Modal header */}
                    <div className="flex items-center justify-between p-4 md:p-5 border-b rounded-t dark:border-gray-600">
                        <h3 className="text-lg font-semibold text-gray-900 dark:text-white">
                            Rename File
                        </h3>
                        <button type="button" className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white" data-modal-toggle="crud-modal" onClick={() => setNewState(prevNewState => ({ ...prevNewState, renameFile: !newState.renameFile,showRenameBtnLoading: false, name: '' }))}>
                            <svg className="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
                            </svg>
                            <span className="sr-only">Close modal</span>
                        </button>
                    </div>
                    {/* Modal body */}
                    <form className="p-4 md:p-5">
                        <div className="grid gap-4 mb-4 grid-cols-2">
                            <div className="col-span-2">
                              <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Name</label>
                              <input type="text" name="name" id="name" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500" 
                                placeholder='rename file' 
                                required="" 
                                value={newState.fileName ? newState.fileName : ''}
                                onChange={e => setNewState(prevNewState => ({ ...prevNewState, fileName: noSpace(e.target.value)}))}
                              />
                            </div>
                        </div>
                        <div className='flex justify-end'>
                          <button type="button" className="text-white inline-flex items-center bg-info hover:bg-hoevrInfo font-medium rounded-lg text-sm px-5 py-2.5 text-center" onClick={() => {
                            setNewState(prevNewState => ({ ...prevNewState, showRenameBtnLoading: true }))
                            handleFileRename()
                          }}>
                            <span className='flex'>
                              {newState.showRenameBtnLoading ?
                                <svg className="animate-spin h-5 w-5 mr-3 text-blue-500" fill="currentColor" viewBox="0 0 24 24">
                                    <circle cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="2" fill="none" />
                                    <path d="M4 12a8 8 0 0112-6.9" />
                                </svg>
                              : null}
                              Submit
                            </span>
                          </button>
                          <button type="button" className="text-white inline-flex items-center bg-gray3 hover:bg-gray4 font-medium rounded-lg text-sm px-5 py-2.5 text-center ml-2" onClick={() => {
                            setNewState(prevNewState => ({ ...prevNewState, renameModal: !newState.renameModal, showRenameBtnLoading: false, name: '' }))
                          }}>
                              Cancel
                          </button>
                        </div>
                    </form>
                </div>
            </div>
          </div>

          {/* Main modal */}
          <div id="crud-modal" tabIndex="-1" aria-hidden="true" className={`flex ${newState.createModal ? '' : 'hidden'} overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 h-[calc(100%-1rem)] max-h-full`}>
              <div className="relative p-4 w-full max-w-md max-h-full">
                  {/* Modal content */}
                  <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
                      {/* Modal header */}
                      <div className="flex items-center justify-between p-4 md:p-5 border-b rounded-t dark:border-gray-600">
                          <h3 className="text-lg font-semibold text-gray-900 dark:text-white">
                              Create Folder
                          </h3>
                          <button type="button" className="bg-transparent rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center text-black" data-modal-toggle="crud-modal" onClick={() => setNewState(prevNewState => ({ ...prevNewState, createModal: !newState.createModal, hasError: false, folder_name: '' }))}>
                              <svg className="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                                  <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
                              </svg>
                              <span className="sr-only">Close modal</span>
                          </button>
                      </div>
                      {/* Modal body */}
                      <form className="p-4 md:p-5">
                          <div className="grid gap-4 mb-4 grid-cols-2">
                              <div className="col-span-2">
                                <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Name</label>
                                <input type="text" name="name" id="name" className="border border-lightGray px-2 py-1 rounded-md text-black w-full" 
                                  placeholder='Enter Folder name' 
                                  required="" 
                                  value={newState.folder_name ? newState.folder_name : ''}
                                  onChange={e => setNewState(prevNewState => ({ ...prevNewState, folder_name: e.target.value }))}
                                />
                              </div>
                          </div>
                          <div className='flex justify-end'>
                            <button type="button" className="text-white inline-flex items-center bg-info hover:bg-hoevrInfo font-medium rounded-lg text-sm px-5 py-2.5 text-center" onClick={() => {
                              if(!newState.showCreateLoading) {
                                createFolderFunction()
                              }
                            }}>
                              <span className='flex'>
                                {newState.showCreateLoading ?
                                  <svg className="animate-spin h-5 w-5 mr-3 text-blue-500" fill="currentColor" viewBox="0 0 24 24">
                                      <circle cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="2" fill="none" />
                                      <path d="M4 12a8 8 0 0112-6.9" />
                                  </svg>
                                : null}
                                Create
                              </span>
                            </button>
                            <button type="button" className="text-white inline-flex items-center bg-gray3 hover:bg-gray4 font-medium rounded-lg text-sm px-5 py-2.5 text-center ml-2" onClick={() => setNewState(prevNewState => ({ ...prevNewState, createModal: !newState.createModal, hasError: false, folder_name: '' }))}>
                                Cancel
                            </button>
                          </div>
                      </form>
                  </div>
              </div>
          </div>

          <div id="popup-modal" tabIndex="-1" className={`flex ${newState.deleteModal ? '' : 'hidden'} overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 h-[calc(100%-1rem)] max-h-full`}>
            <div className="relative p-4 w-full max-w-md max-h-full">
                <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
                    <button type="button" className="absolute top-3 end-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white" data-modal-hide="popup-modal" onClick={() => setNewState(prevNewState => ({ ...prevNewState, deleteModal: false, showDeleteLoading: false }))}>
                        <svg className="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                            <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
                        </svg>
                        <span className="sr-only">Close modal</span>
                    </button>
                    <div className="p-4 md:p-5 text-center">
                        <svg className="mx-auto mb-4 text-gray-400 w-12 h-12 dark:text-gray-200" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                            <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M10 11V6m0 8h.01M19 10a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"/>
                        </svg>
                        <h3 className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">Are you sure you want to delete "{state.statsDetail && state.statsDetail.data && state.statsDetail.data.name ? state.statsDetail.data.name : ''}"?</h3>
                        <button data-modal-hide="popup-modal" type="button" className="py-2 px-2 rounded-md text-white bg-green mr-2" onClick={() => {
                            setNewState(prevNewState => ({ ...prevNewState, showDeleteLoading: true }))
                            if(!newState.showDeleteLoading) {
                              deleteFunction()
                            }
                        }}>
                            Yes, I'm sure
                        </button>
                        <button data-modal-hide="popup-modal" type="button" className="py-2 px-2 rounded-md text-white bg-red"onClick={() => {
                            if(!newState.showDeleteLoading) {
                              setNewState(prevNewState => ({ ...prevNewState, deleteModal: false, showDeleteLoading: false }))
                            }
                        }}>
                          No, cancel
                        </button>
                    </div>
                </div>
            </div>
          </div>
        </div>
      </div>
      {newState.fileContent && newState.fileContent.data ?
        <EditFile
          state={state}
          newState={newState}
          // closeSidePanel={() => setState(prevState => ({...prevState, selectedDetails: {} }))}
          closeSidePanel={() => setNewState(prevState => ({ ...prevState, fileContent: false }))}
        />
      : null}
    </div>
  );
}