import React from 'react';
import TreeNode from './TreeNode';

const TreeView = ({ data, onNodeToggle, onNodeToggleFolder, onNodeClick, parent }) => {
  return (
    <>
      {data.map((node) => (
        <ul key={node.path} className={`${parent ? 'pl-0' : ''}`}>
          <TreeNode
            key={node.path}
            node={node}
            onNodeToggle={onNodeToggle}
            onNodeToggleFolder={onNodeToggleFolder}
            onNodeClick={onNodeClick}
          />
        </ul>
      ))}
    </>
  );
};

export default TreeView;