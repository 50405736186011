/*************************************************
 * Collider
 * @exports
 * @file CostAction.js
 * @author parakash // on 3-02-2023
 * @copyright © 20214Collider. All rights reserved.
 *************************************************/

import ApiCalls from '../../components/ApiCalls'

import {
    QOS_LIST_ACCOUNTS,
    QOS_CREATE_ACCOUNT,
    QOS_UPDATE_ACCOUNT,
    QOS_DELETE_ACCOUNT,
	GET_ACCOUNT_POLICY
} from '../../config'

/**
 * Action to list-accounts
 * @param {Object} body
 * @param {Function} callback
*/
export const qosListAccounts = (params) => {
	return async () => {
		try {

			const url = QOS_LIST_ACCOUNTS;
			// Assuming your API endpoint for the POST request is 'YOUR_API_ENDPOINT'
			const response = await ApiCalls.post(url, params)

			// You can dispatch an action with the fetched data here
			//   dispatch({ type: 'FETCH_TROUBLESHOOT_RESULTS_SUCCESS', payload: response.data });

			// Return the response data so you can use it in the component
			return response.data;
		} catch (error) {
			return error.response && error.response.data ? error.response.data : error.response && error.response.data ? error.response.data : error.response
		}
	};
}

/**
 * Action to create-account
 * @param {Object} body
 * @param {Function} callback
*/
export const qosCreateAccout = (params) => {
	return async () => {
		try {

			const url = QOS_CREATE_ACCOUNT;
			// Assuming your API endpoint for the POST request is 'YOUR_API_ENDPOINT'
			const response = await ApiCalls.post(url, params)

			// You can dispatch an action with the fetched data here
			//   dispatch({ type: 'FETCH_TROUBLESHOOT_RESULTS_SUCCESS', payload: response.data });

			// Return the response data so you can use it in the component
			return response.data;
		} catch (error) {
			return error.response && error.response.data ? error.response.data : error.response && error.response.data ? error.response.data : error.response
		}
	};
}

/**
 * Action to update-account
 * @param {Object} body
 * @param {Function} callback
*/
export const qosUpdateAccount = (params) => {
	return async () => {
		try {

			const url = QOS_UPDATE_ACCOUNT;
			// Assuming your API endpoint for the POST request is 'YOUR_API_ENDPOINT'
			const response = await ApiCalls.post(url, params)

			// You can dispatch an action with the fetched data here
			//   dispatch({ type: 'FETCH_TROUBLESHOOT_RESULTS_SUCCESS', payload: response.data });

			// Return the response data so you can use it in the component
			return response.data;
		} catch (error) {
			return error.response && error.response.data ? error.response.data : error.response && error.response.data ? error.response.data : error.response
		}
	};
}

/**
 * Action to delete-account
 * @param {Object} body
 * @param {Function} callback
*/
export const qosDeleteAccount = (params) => {
	return async () => {
		try {

			const url = QOS_DELETE_ACCOUNT;
			// Assuming your API endpoint for the POST request is 'YOUR_API_ENDPOINT'
			const response = await ApiCalls.post(url, params)

			// You can dispatch an action with the fetched data here
			//   dispatch({ type: 'FETCH_TROUBLESHOOT_RESULTS_SUCCESS', payload: response.data });

			// Return the response data so you can use it in the component
			return response.data;
		} catch (error) {
			return error.response && error.response.data ? error.response.data : error.response && error.response.data ? error.response.data : error.response
		}
	};
}

/**
 * Action to delete-account
 * @param {Object} body
 * @param {Function} callback
*/
export const getAccountPolicy = (params) => {
	return async () => {
		try {

			const url = GET_ACCOUNT_POLICY;
			// Assuming your API endpoint for the POST request is 'YOUR_API_ENDPOINT'
			const response = await ApiCalls.post(url, params)

			// You can dispatch an action with the fetched data here
			//   dispatch({ type: 'FETCH_TROUBLESHOOT_RESULTS_SUCCESS', payload: response.data });

			// Return the response data so you can use it in the component
			return response.data;
		} catch (error) {
			return error.response && error.response.data ? error.response.data : error.response && error.response.data ? error.response.data : error.response
		}
	};
}