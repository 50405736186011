/*************************************************
 * Collider
 * @exports
 * @file ReviewSection.js
 * @author Prakash // on 30/06/2023
 * @copyright © 2023 Collider. All rights reserved.
 *************************************************/
import React, { useEffect, useState, useCallback } from 'react';
import { useSelector } from 'react-redux';
// import { useCallback } from 'react-redux'; // Import useDispatch hook to dispatch actions

const ReviewSection = () => {
    const [state, setState] = useState({})
    const createUpdateCluster = useSelector(
        (state) => state?.hpc?.hpcPropsDetails?.createUpdateCluster || false
      );

    const callPageVist = useCallback(() => {
        let clusterConfiguration = createUpdateCluster ? createUpdateCluster : {};
        // console.log("cluster", clusterConfiguration)
          if (clusterConfiguration) {
            Object.entries(clusterConfiguration).forEach(([key, value]) => {
             setState((prevState) => ({ ...prevState, [key]: value }));
            });
          }
      }, [createUpdateCluster]);

      useEffect(() => callPageVist(), [callPageVist]);
    const handleChildClick = (event, type, dropdownType, section) => {
        event.stopPropagation();

        let clickedChild = [];
        if (type === "child") {
          if (state.clickedChild && state.clickedChild.length) {
            state.clickedChild.forEach((row) => {
              setState((prevState) => ({ ...prevState, [row]: false }));
            });
          }
          clickedChild.push(section);
          setState((prevState) => ({
            ...prevState,
            [section]:
              dropdownType === "singleDropDown" && state[section] ? false : true,
            clickedChild,
          }));
        } else if (type === "parent") {
          if (state.clickedChild && state.clickedChild.length) {
            state.clickedChild.forEach((row) => {
              setState((prevState) => ({ ...prevState, [row]: false }));
            });
          }
          setState((prevState) => ({ ...prevState, clickedChild }));
        }
      };

    return ( 
        <div onClick={(event) => handleChildClick(event, "parent")}>
            <div className="rounded bg-dark p-3 mb-3">
                <div className="search-content bg-muted w-4/5">
                    <div className="rounded bg-dark3 p-3 mb-3">
                        <p className={`mb-1 text-cyon f20 pb-1 border-bottom`}>Cluster Configuration</p>
                        <div className={'rounded bg-dark2 p-2 mb-2'}>
                            <div className="flex mt-3">
                                <span className="minWidth220 mr-3 self-center text-lightGray">Cluster name</span>
                                <span className={`self-center text-white`}>{createUpdateCluster && createUpdateCluster.cluster_name ? createUpdateCluster.cluster_name : ""}</span>
                            </div>
                            <div className="flex mt-3">
                                <span className="minWidth220 mr-3 self-center text-lightGray">Operating System</span>
                                <span className={`self-center text-white`}>{createUpdateCluster && createUpdateCluster.cluster_configuration && createUpdateCluster.cluster_configuration.os ? createUpdateCluster.cluster_configuration.os : ""}</span>
                            </div>
                            <div className="flex mt-3">
                                <span className="minWidth220 mr-3 self-center text-lightGray">Custom AMI</span>
                                <span className={`self-center text-white`}>{createUpdateCluster && createUpdateCluster.cluster_configuration && createUpdateCluster.cluster_configuration.custom_ami ? createUpdateCluster.cluster_configuration.custom_ami : "Nil"}</span>
                            </div>
                            <div className="flex mt-3">
                                <span className="minWidth220 mr-3 self-center text-lightGray">Simultaneous multitreading</span>
                                <span className={`self-center text-white`}>{createUpdateCluster && createUpdateCluster.cluster_configuration && createUpdateCluster.cluster_configuration.disable_simultaneous_multitreading ? "Enabled" : "Disabled"}</span>
                            </div>
                            <div className="flex mt-3">
                                <span className="minWidth220 mr-3 self-center text-lightGray">Detailed monitoring</span>
                                <span className={`self-center text-white`}>{createUpdateCluster && createUpdateCluster.cluster_configuration && createUpdateCluster.cluster_configuration.detailed_monitoring ? "Enabled" : "Disabled"}</span>
                            </div>
                            <div className="flex mt-3">
                                <span className="minWidth220 mr-3 self-center text-lightGray">Cloudwatch dashboard</span>
                                <span className={`self-center text-white`}>{createUpdateCluster && createUpdateCluster.cluster_configuration && createUpdateCluster.cluster_configuration.cloudwatch_dashboard_enabled ? "Enabled" : "Disabled"}</span>
                            </div>
                            <div className="flex mt-3">
                                <span className="minWidth220 mr-3 self-center text-lightGray">Cloudwatch logs</span>
                                <span className={`self-center text-white`}>{createUpdateCluster && createUpdateCluster.cluster_configuration && createUpdateCluster.cluster_configuration.cloudwatch_logs_enabled ? "Enabled" : "Disabled"}</span>
                            </div>
                            <div className="flex mt-3">
                                        <span className="minWidth220 mr-3 self-center text-lightGray">Scaledown idle time (minutes)</span>
                                        <span className={`self-center text-white`}>{createUpdateCluster && createUpdateCluster.cluster_configuration && createUpdateCluster.cluster_configuration.scaledown_idle_time ? createUpdateCluster.cluster_configuration.scaledown_idle_time : ""}</span>
                                    </div>
                            {/* <div className="flex mt-3">
                                <span className="minWidth220 mr-3 self-center text-lightGray">Tags</span>
                                <span className={`self-center text-white`}>{createUpdateCluster && createUpdateCluster.cluster_configuration && createUpdateCluster.cluster_configuration.cloudwatch_dashboard_enabled ? createUpdateCluster.cluster_configuration.cloudwatch_dashboard_enabled : ""}</span>
                            </div> */}
                            {createUpdateCluster && createUpdateCluster.tags ?
                                <React.Fragment>
                                    <p className={`mb-1 mt-2 text-purple f18 pb-1 border-bottom`}>Tags</p>
                                    <div className="p-3 rounded bg-dark">
                                    {createUpdateCluster.tags.map((buk, bukIndex) => {
                                        return(
                                            <div className={`flex ${(bukIndex && bukIndex+1 !== createUpdateCluster.tags.length) ? 'mb-2' : ''}`}>
                                            <div className="">
                                                <span className="minWidth220 mr-3 self-center text-lightGray">Key</span>
                                                <span className={`self-center text-white`}>{buk.key ? buk.key : ""}</span>
                                            </div>
                                            <div className="ml-3">
                                                <span className="minWidth220 mr-3 self-center text-lightGray">Value</span>
                                                <span className={`self-center text-white`}>{buk.value ? buk.value : ""}</span>
                                            </div>
                                            </div>
                                        )
                                    })}
                                    </div>
                                </React.Fragment>
                            : null}
                        </div>
                    </div>

                    <div className="rounded bg-dark3 p-3 mb-3">
                        <p className={`mb-1 text-cyon f20 pb-1 border-bottom`}>Authentication (Directory) Configuration</p>
                        <div className={'rounded bg-dark2 p-2 mb-2'}>
                            <div className="flex mt-3">
                                <span className="minWidth220 mr-3 self-center text-lightGray">Domain name</span>
                                <span className={`self-center text-white`}>{createUpdateCluster && createUpdateCluster.directory_configuration && createUpdateCluster.directory_configuration.domain_name ? createUpdateCluster.directory_configuration.domain_name : ""}</span>
                            </div>
                            <div className="flex mt-3">
                                <span className="minWidth220 mr-3 self-center text-lightGray">Domain address</span>
                                <span className={`self-center text-white`}>{createUpdateCluster && createUpdateCluster.directory_configuration && createUpdateCluster.directory_configuration.domain_address ? createUpdateCluster.directory_configuration.domain_address : ""}</span>
                            </div>
                            <div className="flex mt-3">
                                <span className="minWidth220 mr-3 self-center text-lightGray">Password secret (ARN)</span>
                                <span className={`self-center text-white`}>{createUpdateCluster && createUpdateCluster.directory_configuration && createUpdateCluster.directory_configuration.password_secret_arn ? createUpdateCluster.directory_configuration.password_secret_arn : ""}</span>
                            </div>
                            <div className="flex mt-3">
                                <span className="minWidth220 mr-3 self-center text-lightGray">Domain read only user</span>
                                <span className={`self-center text-white`}>{createUpdateCluster && createUpdateCluster.directory_configuration && createUpdateCluster.directory_configuration.domain_read_only_user ? createUpdateCluster.directory_configuration.domain_read_only_user : ""}</span>
                            </div>
                            <div className="flex mt-3">
                                <span className="minWidth220 mr-3 self-center text-lightGray">Request certificate</span>
                                <span className={`self-center text-white`}>{createUpdateCluster && createUpdateCluster.directory_configuration && createUpdateCluster.directory_configuration.ldap_tls_request_certificate ? createUpdateCluster.directory_configuration.ldap_tls_request_certificate : ""}</span>
                            </div>
                            <div className="flex mt-3">
                            { createUpdateCluster && createUpdateCluster.directory_configuration && createUpdateCluster.directory_configuration.additional_sssd_configs ? ( //Exisiting EFS
                            <React.Fragment>
                                <span className="minWidth220 mr-3 self-center text-lightGray">Additional SSSD options</span>
                                {Object.entries(createUpdateCluster.directory_configuration.additional_sssd_configs).map(([key, value]) => {
                                    return (
                                        <div className="flex flex-wrap" key={key}>
                                            <span className='border border-info rounded-full mb-3 px-2 bg-info text-white mr-2 py-1 break-all'>{key + ' : ' + String(value)}</span>
                                        </div>
                                    );
                                })}
                            </React.Fragment>
                            ) : null}
                                {/* <span className="minWidth220 mr-3 self-center text-lightGray">Tags</span>
                                <span className={`self-center text-white`}>{createUpdateCluster && createUpdateCluster.directory_configuration && createUpdateCluster.directory_configuration.additional_sssd_configs ? createUpdateCluster.cluster_configuration.additional_sssd_configs : ""}</span> */}
                            </div>
                        </div>
                    </div>

                    <div className="rounded bg-dark3 p-3 mb-3">
                        <p className={`mb-1 text-cyon f20 pb-1 border-bottom`}>Headnode Configuration</p>
                        <div className={'rounded bg-dark2 p-2 mb-2'}>
                            <div className="flex mt-3">
                                <span className="minWidth220 mr-3 self-center text-lightGray">Instance Type</span>
                                <span className={`self-center text-white`}>{createUpdateCluster && createUpdateCluster.cluster_configuration && createUpdateCluster.cluster_configuration.instance_type ? createUpdateCluster.cluster_configuration.instance_type : ""}</span>
                            </div>
                            <div className="flex mt-3">
                                <span className="minWidth220 mr-3 self-center text-lightGray">Subnet Id</span>
                                    {createUpdateCluster && createUpdateCluster.cluster_configuration && createUpdateCluster.cluster_configuration.subnet_id ? 
                                        <span className={`border border-info rounded-full mb-3 px-2 bg-info text-white mr-2 py-1 break-all`}> {createUpdateCluster.cluster_configuration.subnet_id} </span>
                                        : null}
                            </div>
                            <div className="flex mt-3">
                                <span className="minWidth220 mr-3 self-center text-lightGray">Security Group IDs</span>
                                <div className="flex flex-wrap">
                                {createUpdateCluster && createUpdateCluster.cluster_configuration && createUpdateCluster.cluster_configuration.security_group_ids ?
                                    createUpdateCluster.cluster_configuration.security_group_ids.map(ids => {
                                        return(
                                            <span className='border border-info rounded-full mb-3 px-2 bg-info text-white mr-2 py-1 break-all'>{ids}</span>
                                        )
                                    })
                                    : null}
                                </div>
                            </div>
                            <div className="flex mt-3">
                                <span className="minWidth220 mr-3 self-center text-lightGray">On node configured script</span>
                                <span className={`self-center text-white`}>{createUpdateCluster && createUpdateCluster.cluster_configuration && createUpdateCluster.cluster_configuration.on_node_configured_script ? createUpdateCluster.cluster_configuration.on_node_configured_script : ""}</span>
                            </div>
                            <div className="flex mt-3">
                                <span className="minWidth220 mr-3 self-center text-lightGray">On node updated script</span>
                                <span className={`self-center text-white`}>{createUpdateCluster && createUpdateCluster.cluster_configuration && createUpdateCluster.cluster_configuration.on_node_updated_script ? createUpdateCluster.cluster_configuration.on_node_updated_script : ""}</span>
                            </div>
                            <div className="flex mt-3">
                                <span className="minWidth220 mr-3 self-center text-lightGray">Key pair</span>
                                <span className={`self-center text-white`}>{createUpdateCluster && createUpdateCluster.cluster_configuration && createUpdateCluster.cluster_configuration.key_name ? createUpdateCluster.cluster_configuration.key_name : ""}</span>
                            </div>
                            <div className="flex mt-3">
                                <span className="minWidth220 mr-3 self-center text-lightGray">IAM Policies</span>   
                                {/* <span className={`self-center text-white`}>{createUpdateCluster && createUpdateCluster.cluster_configuration && createUpdateCluster.cluster_configuration.policies ? createUpdateCluster.cluster_configuration.policies : ""}</span> */}
                                <div className="flex flex-wrap">
                                {createUpdateCluster && createUpdateCluster.cluster_configuration && createUpdateCluster.cluster_configuration.policies ?
                                    createUpdateCluster.cluster_configuration.policies.map(ids => {
                                        return(
                                            <span className='border border-info rounded-full mb-3 px-2 bg-info text-white mr-2 py-1 break-all'>{ids}</span>
                                        )
                                    })
                                    : null}
                                </div>
                            </div>
                            <div className="flex mt-3">
                                <span className="minWidth220 mr-3 self-center text-lightGray">Root Volume Size (GB)</span>
                                <span className={`self-center text-white`}>{createUpdateCluster && createUpdateCluster.cluster_configuration && createUpdateCluster.cluster_configuration.volume_size ? createUpdateCluster.cluster_configuration.volume_size : ""}</span>
                            </div>
                            <div className="flex mt-3">
                                <span className="minWidth220 mr-3 self-center text-lightGray">Volume Type</span>
                                <span className={`self-center text-white`}>{createUpdateCluster && createUpdateCluster.cluster_configuration && createUpdateCluster.cluster_configuration.volume_type ? createUpdateCluster.cluster_configuration.volume_type : ""}</span>
                            </div>
                            <div className="flex mt-3">
                                <span className="minWidth220 mr-3 self-center text-lightGray">Volume Encryption</span>
                                <span className={`self-center text-white`}>{createUpdateCluster && createUpdateCluster.cluster_configuration && createUpdateCluster.cluster_configuration.volume_type ? "Enabled" : "Disabled"}</span>
                            </div>
                            {/* <p className={`mb-1 mt-2 text-purple f18 pb-1 border-bottom`}>S3 Access</p>
                                {createUpdateCluster && createUpdateCluster.cluster_configuration && createUpdateCluster.cluster_configuration.s3_access ?
                                    <div className="p-3 rounded bg-dark">
                                    {createUpdateCluster.cluster_configuration.s3_access.map((buk, bukIndex) => {
                                        return(
                                            <div className={`flex ${(bukIndex && bukIndex+1 !== createUpdateCluster.cluster_configuration.s3_access.length) ? 'mb-2' : ''}`}>
                                            <div className="">
                                                <span className="minWidth220 mr-3 self-center text-lightGray">Bucket</span>
                                                <span className={`self-center text-white`}>{buk.bucket_name ? buk.bucket_name : ""}</span>
                                            </div>
                                            <div className="ml-3">
                                                <span className="minWidth220 mr-3 self-center text-lightGray">Write Access</span>
                                                <span className={`self-center text-white`}>{buk.enable_s3_write_access ? "True" : "False"}</span>
                                            </div>
                                            </div>
                                        )
                                    })}
                                    </div>
                                : null} */}
                        </div>
                    </div>

                    <div className="rounded bg-dark3 p-3 mb-3">
                        <p className={`mb-1 text-cyon f20 pb-1 border-bottom`}>Queue Configuration</p>
                        {createUpdateCluster && createUpdateCluster.queue_configuration && createUpdateCluster.queue_configuration[0].queue_name ? ( 
                            <React.Fragment>
                                {createUpdateCluster.queue_configuration.map((item, iIndex) => {
                                return(
                                    <div className={`rounded bg-dark2 p-2 mb-2 ${!iIndex ? 'mt-2' : ''}`}>
                                    <div className="flex mt-3">
                                        <span className="minWidth220 mr-3 self-center text-lightGray">Queue name</span>
                                        <span className={`self-center text-white`}>{item && item.queue_name ? item.queue_name : ""}</span>
                                    </div>
                                    <div className="flex mt-3">
                                        <span className="minWidth220 mr-3 self-center text-lightGray">Update strategy</span>
                                        <span className={`self-center text-white`}>{item && item.update_strategy ? item.update_strategy : ""}</span>
                                    </div>
                                    <div className="flex mt-3">
                                        <span className="minWidth220 mr-3 self-center text-lightGray">Subnet IDs</span>
                                        <div className="flex flex-wrap">
                                            {item && item.subnet_ids ?
                                                item.subnet_ids.map(ids => {
                                                    return(
                                                        <span className='border border-info rounded-full mb-3 px-2 bg-info text-white mr-2 py-1 break-all'>{ids}</span>
                                                    )
                                                })
                                            : null}
                                        </div>
                                    </div>
                                    {/* <div className="flex mt-3">
                                        <span className="minWidth220 mr-3 self-center text-lightGray">Security Group IDs</span>
                                        <div className="flex flex-wrap">
                                            {item && item.security_group_ids ?
                                                item.security_group_ids.map(ids => {
                                                    return(
                                                        <span className='border border-info rounded-full mb-3 px-2 bg-info text-white mr-2 py-1 break-all'>{ids}</span>
                                                    )
                                                })
                                            : null}
                                        </div>
                                    </div> */}
                                    <div className="flex mt-3">
                                        <span className="minWidth220 mr-3 self-center text-lightGray">Policies</span>
                                        <div className="flex flex-wrap">
                                            {item && item.policies ?
                                                item.policies.map(pol => {
                                                    return(
                                                        <span className='border border-info rounded-full mb-3 px-2 bg-info text-white mr-2 py-1 break-all'>{pol}</span>
                                                    )
                                                })
                                            : null}
                                        </div>
                                    </div>
                                    <div className="flex mt-3">
                                        <span className="minWidth220 mr-3 self-center text-lightGray">Script Location</span>
                                        <span className={`self-center text-white`}>{item && item.on_node_configured_script ? item.on_node_configured_script : ""}</span>
                                    </div>

                                    <p className={`mb-1 mt-2 text-purple f18 pb-1 border-bottom`}>Volume Details</p>
                                    <div className="flex mt-3">
                                        <span className="minWidth220 mr-3 self-center text-lightGray">Type</span>
                                        <span className={`self-center text-white`}>{item && item.volume_type ? item.volume_type : ""}</span>
                                    </div>
                                    <div className="flex mt-3">
                                        <span className="minWidth220 mr-3 self-center text-lightGray">Size</span>
                                        <span className={`self-center text-white`}>{item && item.volume_size && item.volume_size}</span>
                                    </div>
                                    <div className="flex mt-3">
                                        <span className="minWidth220 mr-3 self-center text-lightGray">Encryption</span>
                                        <span className={`self-center text-white`}>{item && item.volume_encrypted ? "Enabled" : "Disabled"}</span>
                                    </div>

                                    <p className={`mb-1 mt-2 text-purple f18 pb-1 border-bottom`}>Compute Resources</p>
                                    {item && item.compute_resources ?
                                        item.compute_resources.map(comp => {
                                            return (
                                                <div className='bg-dark p-2 rounded mb-2'>
                                                <div className="flex mt-3">
                                                    <span className="minWidth220 mr-3 self-center text-lightGray">Name</span>
                                                    <span className={`self-center text-white`}>{comp && comp.compute_resource_name ? comp.compute_resource_name : ""}</span>
                                                </div>
                                                <div className="flex mt-3">
                                                    <span className="minWidth220 mr-3 self-center text-lightGray">Instance Type</span>
                                                    <span className={`self-center text-white`}>{comp && comp.instance_type && comp.instance_type.length ? comp.instance_type : ""}</span>
                                                </div>
                                                <div className="flex mt-3">
                                                    <span className="minWidth220 mr-3 self-center text-lightGray">Min Instance Count</span>
                                                    <span className={`self-center text-white`}>{comp && comp.min_instance_count && comp.min_instance_count}</span>
                                                </div>
                                                <div className="flex mt-3">
                                                    <span className="minWidth220 mr-3 self-center text-lightGray">Max Instance Count</span>
                                                    <span className={`self-center text-white`}>{comp && comp.max_instance_count && comp.max_instance_count}</span>
                                                </div>
                                                <div className="flex mt-3">
                                                    <span className="minWidth220 mr-3 self-center text-lightGray">Efa</span>
                                                    <span className={`self-center text-white`}>{comp && comp.efa_enabled ? "True" : "False"}</span>
                                                </div>
                                                <div className="flex mt-3">
                                                    <span className="minWidth220 mr-3 self-center text-lightGray">Placement Group</span>
                                                    <span className={`self-center text-white`}>{comp && comp.placement_group_enabled ? "True" : "False"}</span>
                                                </div>
                                                <div className="flex mt-3">
                                                    <span className="minWidth220 mr-3 self-center text-lightGray">Simultaneous Multithreading</span>
                                                    <span className={`self-center text-white`}>{comp && comp.disable_simultaneous_multithreading ? "True" : "False"}</span>
                                                </div>
                                                </div>
                                            )
                                        })
                                    : null}
                                    {/* <p className={`mb-1 mt-2 text-purple f18 pb-1 border-bottom`}>S3 Access</p>
                                    {item && item.s3_access ?
                                        <div className="p-3 rounded bg-dark">
                                        {item.s3_access.map((buk, bukIndex) => {
                                            return(
                                                <div className={`flex ${(bukIndex && bukIndex+1 !== item.s3_access.length) ? 'mb-2' : ''}`}>
                                                <div className="">
                                                    <span className="minWidth220 mr-3 self-center text-lightGray">Bucket</span>
                                                    <span className={`self-center text-white`}>{buk.bucket_name ? buk.bucket_name : ""}</span>
                                                </div>
                                                <div className="ml-3">
                                                    <span className="minWidth220 mr-3 self-center text-lightGray">Write Access</span>
                                                    <span className={`self-center text-white`}>{buk.enable_s3_write_access ? "True" : "False"}</span>
                                                </div>
                                                </div>
                                            )
                                        })}
                                        </div>
                                    : null} */}
                                    <p className={`mb-1 mt-2 text-purple f18 pb-1 border-bottom`}>Tags</p>
                                    {item && item.tags ?
                                        <div className="p-3 rounded bg-dark">
                                        {item.tags.map((buk, bukIndex) => {
                                            return(
                                                <div className={`flex ${(bukIndex && bukIndex+1 !== item.tags.length) ? 'mb-2' : ''}`}>
                                                <div className="">
                                                    <span className="minWidth220 mr-3 self-center text-lightGray">Key</span>
                                                    <span className={`self-center text-white`}>{buk.key ? buk.key : ""}</span>
                                                </div>
                                                <div className="ml-3">
                                                    <span className="minWidth220 mr-3 self-center text-lightGray">Value</span>
                                                    <span className={`self-center text-white`}>{buk.value ? buk.value : ""}</span>
                                                </div>
                                                </div>
                                            )
                                        })}
                                        </div>
                                    : null}
                                </div>
                                )
                            })}
                            </React.Fragment>
                        ) : null}
                    </div>
                    <div className="rounded bg-dark3 p-3 mb-3">
                        <p className={`mb-1 text-cyon f20 pb-1 border-bottom`}>Database Configuration</p>
                    {createUpdateCluster.database_configuration && createUpdateCluster.database_configuration.vpc_id ? (
                        <div className={'rounded bg-dark2 p-2 mb-2'}>
                            <div className="flex mt-3">
                                <span className="minWidth220 mr-3 self-center text-lightGray">Database name</span>
                                <span className={`self-center text-white`}>{createUpdateCluster && createUpdateCluster.database_configuration && createUpdateCluster.database_configuration.database ? createUpdateCluster.database_configuration.database : ""}</span>
                            </div>
                            <div className="flex mt-3">
                                <span className="minWidth220 mr-3 self-center text-lightGray">Username</span>
                                <span className={`self-center text-white`}>{createUpdateCluster && createUpdateCluster.database_configuration && createUpdateCluster.database_configuration.user_name ? createUpdateCluster.database_configuration.user_name : ""}</span>
                            </div>
                            <div className="flex mt-3">
                                <span className="minWidth220 mr-3 self-center text-lightGray">VPC Id</span>
                                {createUpdateCluster && createUpdateCluster.database_configuration && createUpdateCluster.database_configuration.vpc_id ? 
                                <span className={`border border-info rounded-full mb-3 px-2 bg-info text-white mr-2 py-1 break-all`}>{createUpdateCluster.database_configuration.vpc_id} </span>
                                : ""}
                            </div>
                            <div className="flex mt-3">
                                <span className="minWidth220 mr-3 self-center text-lightGray">Security Group IDs</span>
                                <div className="flex flex-wrap">
                                {createUpdateCluster && createUpdateCluster.database_configuration && createUpdateCluster.database_configuration.subnet_ids ?
                                    createUpdateCluster.database_configuration.subnet_ids.map(ids => {
                                            return(
                                                <span className='border border-info rounded-full mb-3 px-2 bg-info text-white mr-2 py-1 break-all'>{ids}</span>
                                            )
                                        })
                                    : null}
                                </div>
                            </div>
                            <div className="flex mt-3">
                                <span className="minWidth220 mr-3 self-center text-lightGray">Minimum Capacity</span>
                                <span className={`self-center text-white`}>{createUpdateCluster && createUpdateCluster.database_configuration && createUpdateCluster.database_configuration.min_capacity ? createUpdateCluster.database_configuration.min_capacity : ""}</span>
                            </div>
                            <div className="flex mt-3">
                                <span className="minWidth220 mr-3 self-center text-lightGray">Maximum Capacity</span>
                                <span className={`self-center text-white`}>{createUpdateCluster && createUpdateCluster.database_configuration && createUpdateCluster.database_configuration.max_capacity ? createUpdateCluster.database_configuration.max_capacity : ""}</span>
                            </div>
                        </div>
                    ) : (
                       <div className={'rounded bg-dark2 p-2 mb-2'}>
                            <div className="flex mt-3">
                                <span className="minWidth220 mr-3 self-center text-lightGray">Database Uri</span>
                                <span className={`self-center text-white`}>{createUpdateCluster && createUpdateCluster.database_configuration && createUpdateCluster.database_configuration.database ? createUpdateCluster.database_configuration.database : ""}</span>
                            </div>
                            <div className="flex mt-3">
                                <span className="minWidth220 mr-3 self-center text-lightGray">User Name</span>
                                <span className={`self-center text-white`}>{createUpdateCluster && createUpdateCluster.database_configuration && createUpdateCluster.database_configuration.user_name ? createUpdateCluster.database_configuration.user_name : ""}</span>
                            </div>
                            <div className="flex mt-3">
                                <span className="minWidth220 mr-3 self-center text-lightGray">Password secret (ARN)</span>
                                <span className={`self-center text-white`}>{createUpdateCluster && createUpdateCluster.database_configuration && createUpdateCluster.database_configuration.password_secret_arn ? createUpdateCluster.database_configuration.password_secret_arn : ""}</span>
                            </div>
                        </div>
                    )}
                    </div>

                    <div className="rounded bg-dark3 p-3 mb-3">
                        <p className={`mb-1 text-cyon f20 pb-1 border-bottom`}>Storage Configuration</p>
                        { createUpdateCluster && createUpdateCluster.storage_configuration && createUpdateCluster.storage_configuration[0]?.filesystem_name && createUpdateCluster.storage_configuration[0].create  ? ( //New EFS
                            <React.Fragment>
                                {createUpdateCluster.storage_configuration.map((buk, bukIndex) => {
                                return(
                                    <React.Fragment>
                                    <div className="flex mt-3">
                                        <span className="minWidth220 mr-3 self-center text-lightGray">File system name</span>
                                        <span className={`self-center text-white`}>{ buk.filesystem_name ? buk.filesystem_name : ""}</span>
                                    </div>
                                    <div className="flex mt-3">
                                        <span className="minWidth220 mr-3 self-center text-lightGray">Mount Directory</span>
                                        <span className={`self-center text-white`}>{ buk.mount_dir ? buk.mount_dir : ""}</span>
                                    </div>
                                    <div className="flex mt-3">
                                        <span className="minWidth220 mr-3 self-center text-lightGray">Encryption</span>
                                        <span className={`self-center text-white`}>{ buk.encrytion ? "Enabled" : "Disabled"}</span>
                                    </div>
                                    <div className="flex mt-3">
                                        <span className="minWidth220 mr-3 self-center text-lightGray">Deletion Policy</span>
                                        <span className={`self-center text-white`}>{  buk.deletion_policy ? buk.deletion_policy : ""}</span>
                                    </div>
                                </React.Fragment>
                                )
                            })}
                            </React.Fragment>
                        ) : createUpdateCluster.storage_configuration && createUpdateCluster.storage_configuration[0]?.root_volume_security_style && createUpdateCluster.storage_configuration[0].create ? ( //New Fsx
                            <div className={'rounded bg-dark2 p-2 mb-2'}>
                                {createUpdateCluster.storage_configuration.map((buk, bukIndex) => {
                                return(
                                <React.Fragment>
                                    <div className="flex mt-3">
                                        <span className="minWidth220 mr-3 self-center text-lightGray">Root Volume Security Style</span>
                                        <span className={`self-center text-white`}>{buk.root_volume_security_style ? buk.root_volume_security_style : ""}</span>
                                    </div>
                                    <div className="flex mt-3">
                                        <span className="minWidth220 mr-3 self-center text-lightGray">Storage Capacity</span>
                                        <span className={`self-center text-white`}>{buk.storage_capacity ? buk.storage_capacity : ""}</span>
                                    </div>
                                    <div className="flex mt-3">
                                        <span className="minWidth220 mr-3 self-center text-lightGray">Throughput Capacity</span>
                                        <span className={`self-center text-white`}>{buk.throughput_capacity ? buk.throughput_capacity : ""}</span>
                                    </div>

                                <p className={`mb-1 mt-2 text-purple f18 pb-1 border-bottom`}>Volume Details</p>

                                    <div className={`p-3 rounded bg-dark ${((bukIndex && bukIndex===0) || bukIndex+1 !== createUpdateCluster.storage_configuration.length) ? 'mb-2' : ''}`}>
                                        <div className="flex mt-3">
                                            <span className="minWidth220 mr-3 self-center text-lightGray">Volume name</span>
                                            <span className={`self-center text-white`}>{ buk.volume_name ? buk.volume_name : ""}</span>
                                        </div>
                                        <div className="flex mt-3">
                                            <span className="minWidth220 mr-3 self-center text-lightGray">Snapshot Policy</span>
                                            <span className={`self-center text-white`}>{ buk.snapshot_policy ? buk.snapshot_policy : ""}</span>
                                        </div>
                                        <div className="flex mt-3">
                                            <span className="minWidth220 mr-3 self-center text-lightGray">Storage Efficiency</span>
                                            <span className={`self-center text-white`}>{ buk.root_volume_security_style ? "Enabled" : "Disabled"}</span>
                                        </div>
                                        <div className="flex mt-3">
                                            <span className="minWidth220 mr-3 self-center text-lightGray">Tiering Policy Name</span>
                                            <span className={`self-center text-white`}>{ buk.tiering_policy_name ? buk.tiering_policy_name : ""}</span>
                                        </div>
                                        <div className="flex mt-3">
                                            <span className="minWidth220 mr-3 self-center text-lightGray">Tiering Policy Cooling Period</span>
                                            <span className={`self-center text-white`}>{ buk.cooling_period ? buk.cooling_period : ""}</span>
                                        </div>
                                        <div className="flex mt-3">
                                            <span className="minWidth220 mr-3 self-center text-lightGray">Volume Security Style</span>
                                            <span className={`self-center text-white`}>{ buk.volume_security_style ? buk.volume_security_style : ""}</span>
                                        </div>
                                        <div className="flex mt-3">
                                            <span className="minWidth220 mr-3 self-center text-lightGray">Volume Size</span>
                                            <span className={`self-center text-white`}>{ buk.root_volume_security_style ? "Enabled" : "Disabled"}</span>
                                        </div>
                                        <div className="flex mt-3">
                                            <span className="minWidth220 mr-3 self-center text-lightGray">Volume Type</span>
                                            <span className={`self-center text-white`}>{ buk.tiering_policy_name ? buk.tiering_policy_name : ""}</span>
                                        </div>
                                    </div>
                                </React.Fragment>
                                )
                                })}
                            </div>
                        ) : createUpdateCluster.storage_configuration && createUpdateCluster.storage_configuration[0]?.storage_id ? ( //Exisiting Fsx
                            <React.Fragment>
                            {createUpdateCluster.storage_configuration.map((buk, bukIndex) => {
                                return(
                                    <div className={`p-3 rounded bg-dark ${((bukIndex && bukIndex===0) || bukIndex+1 !== createUpdateCluster.storage_configuration.length) ? 'mb-2' : ''}`}>
                                        <div className="flex mt-3">
                                            <span className="minWidth220 mr-3 self-center text-lightGray">Storage Id</span>
                                            <span className={`self-center text-white`}>{ buk.storage_id ? buk.storage_id : ""}</span>
                                        </div>
                                        <div className="flex mt-3">
                                            <span className="minWidth220 mr-3 self-center text-lightGray">Volume name</span>
                                            <span className={`self-center text-white`}>{ buk.storage_name ? buk.storage_name : ""}</span>
                                        </div>
                                    </div>
                                )
                            })}
                            </React.Fragment>
                        ) : ( //Existing Efs
                            <div className={'rounded bg-dark2 p-2 mb-2'}>
                                {createUpdateCluster.storage_configuration && createUpdateCluster.storage_configuration.map((buk, bukIndex) => {
                                    return(
                                        <div className={`p-3 rounded bg-dark ${((bukIndex && bukIndex===0) || bukIndex+1 !== createUpdateCluster.storage_configuration.length) ? 'mb-2' : ''}`}>
                                            <div className="flex mt-3">
                                                <span className="minWidth220 mr-3 self-center text-lightGray">Filesystem name</span>
                                                <span className={`self-center text-white`}>{ buk.filesystem_name ? buk.filesystem_name : ""}</span>
                                            </div>
                                            <div className="flex mt-3">
                                                <span className="minWidth220 mr-3 self-center text-lightGray">Mount Directory</span>
                                                <span className={`self-center text-white`}>{ buk.mount_dir ? buk.mount_dir : ""}</span>
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>

    )
}

export default ReviewSection