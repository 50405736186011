/* eslint-disable no-mixed-spaces-and-tabs */
/*************************************************
 * Collider
 * @exports
 * @file LandingPage.js
 * @author Prakash // on 26/09/2023
 * @copyright © 2023 Collider. All rights reserved.
 * 
 *************************************************/
import React, { useEffect, useState, useCallback, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux'; // Import useDispatch hook to dispatch actions
import { useLocation } from 'react-router-dom';
import { getAccountNameFromId, momentConvertionUtcToLocalTime } from '../../../utils/utility';
import { listLifecyclePolicies, deleteLifecyclePolicies } from '../../../actions/CatalogAction'
import ResizeableDarkThemeTable from '../../designComponents/Table/ResizeableDarkThemeTable'
import Search from '../../common/SearchWithHiddenInput'
import ViewSidePanel from './ViewSidePanel';
 import { Store as CommonNotification } from 'react-notifications-component';
import { setCommonPropsDetails } from '../../../actions/commonActionNew'
import {Icon} from "@iconify/react";
import FilterSection from '../../common/FiltersSection';


const LandingPage = ()  => {
    const clickOutside = useRef()

    const [state, setState] = useState({
        showLoading: true,
        topSectionFilters: ['provider', 'account', 'region', 'lifecycle_policy_type'],
        category: [ 
            {label: "Asset", value: "asset"},
            {label: "Service", value: "service_name"},
            {label: "Tags", value: "tags"}
        ],
        selectedCategory: "asset",
        dynamicHeaders: [],
        
    });

    const dispatch = useDispatch(); // Create a dispatch function
	const location = useLocation()
    const currentPath = location.pathname;

    const userMenu = useSelector(state => state?.filters?.userMenu || false);
    const providers = useSelector(state => state?.filters?.providers || false);

    useEffect(() => {
        if(userMenu && Object.entries(userMenu).length) {
			let actionsAllowed = []
			userMenu.menu && userMenu.menu.forEach(row => {
                if(row.submenulist && row.submenulist.length) {
                    if(row.submenulist.filter(e => e.link === currentPath && e.action).length) {
                        actionsAllowed = row.submenulist.filter(e => e.link === currentPath)[0].action
                    }
				}
			})
            setState(prevState => ({ ...prevState, actionsAllowed }))
		}
    }, [userMenu, currentPath])
            
    const handleSelectedFilters = (filter) => {
        setState(prevState => ({ 
            ...prevState,
            selectedProvider: filter.selectedProvider,
            selectedProject: filter.selectedProject,
            selectedAccount: filter.selectedAccount ? filter.selectedAccount : [],
            selectedRegion: filter.selectedRegion ? filter.selectedRegion : [],
            selectedPolicy: filter.selectedPolicy ? filter.selectedPolicy : '',
            callSearch: true,
            showLoading: true
        }))
    }

    useEffect(() => {
        if(state.showLoading) {
            setTimeout(() => { setState(prevState => ({ ...prevState,  showLoading: false })) }, 5000);
        }
    }, [state.showLoading])

    const onReset = () => {
        setState(prevState => ({ ...prevState, selectedProvider: providers && providers.length ? (providers.filter(e => e.provider_name === 'AWS').length ? 'AWS' : providers[0].provider_name) : "", selectedAccount: [], selectedRegion: [], callSearch: true }));
    }


    useEffect(() => {
        if(state.callSearch) {
            setState(prevState => ({ ...prevState, callSearch: false, dynamicHeaders: [], lifeCyclePolicies: [], filteredPolicies: [] }))
            let params = {}
            params.provider = state.selectedProvider.toLowerCase();
            if(state.selectedAccount && state.selectedAccount.length && !state.selectedAccount.includes("All")) {
                params.account_id = state.selectedAccount
            }
            if(state.selectedRegion && state.selectedRegion.length && !state.selectedRegion.includes("All")) {
                params.region = state.selectedRegion
            }
            if(state.selectedCategory && state.selectedCategory !== "All") {
                params.category = state.selectedCategory
            }
            if(state.selectedPolicy) {
                params.policy_type = state.selectedPolicy
            }

            dispatch(listLifecyclePolicies(params))
                .then((response) => {
                    setState(prevState => ({ ...prevState, lifeCyclePolicies: response && response.results ? response.results : [], filteredPolicies: response && response.results ? response.results : [], showLoading: false, formHeaders: true }))
                })
        }
    }, [state.callSearch, dispatch, state.selectedAccount, state.selectedCategory, state.selectedProvider, state.selectedRegion, state.selectedPolicy])

    const onClickDelete = useCallback((row) => {
        
        let params = {}
        params.policy_id = row.policy_id
        dispatch(deleteLifecyclePolicies(params))
            .then((response) => { 
                let messageType = "danger"
                if(response.status) {
                    messageType = 'success'
                }
                if(response.status) {
                    setState(prevState => ({ ...prevState, disableSubmit: false, callSearch: true }))
                }

                let message = response && response.message ? response.message : 'Error in deleting lifecycle policy '
                
                CommonNotification.addNotification({
                    message: message,
                    type: messageType,
                    insert: "top",
                    container: "top-center",
                    dismiss: {
                        duration: 5000,
                        onScreen: false,
                        pauseOnHover: true,
                        showIcon: true,
                    }
                });
            })
    }, [dispatch])

    useEffect(() => {
        if(state.formHeaders) {
            setState(prevState => ({ ...prevState, formHeaders: false }))
            let columns = []
            let dataRow = {}
            dataRow.Header = 'Date'
            // dataRow.accessor = 'created_at'
            dataRow.Cell = cellInfo => (
                <React.Fragment>
                    <span>{cellInfo.row.original.modified_at ? momentConvertionUtcToLocalTime(cellInfo.row.original.modified_at, "DD MMM YYYY HH:mm") : cellInfo.row.original.created_at ? momentConvertionUtcToLocalTime(cellInfo.row.original.created_at, "DD MMM YYYY HH:mm") : ""}</span>
                </React.Fragment>
            )
            dataRow.width = 180
            columns.push(dataRow)

            dataRow = {}
            dataRow.Header = 'Provider'
            dataRow.accessor = d => d.provider + ': ' + getAccountNameFromId(d.account_id, state.accounts) + ': ' + d.region === 'NA' ? 'Global' : d.region
            dataRow.Cell = cellInfo => (
                <React.Fragment>
                <span className=''>
                    {cellInfo.row.original.provider.toUpperCase() + ': ' + getAccountNameFromId(cellInfo.row.original.account_id, state.accounts) + ': ' + (cellInfo.row.original.region === 'NA' ? 'Global' : cellInfo.row.original.region)}
                </span>
                </React.Fragment>
            )
            dataRow.width = 250
            columns.push(dataRow)

            dataRow = {}
            dataRow.Header = 'Resource'
            dataRow.accessor = 'resource_type'
            // dataRow.accessor = d => d.resource_type + ' : ' + d.service_name
            // dataRow.Cell = cellInfo => (
            //     <span className=''>{cellInfo.row.original.resource_type + ' : ' + cellInfo.row.original.service_name}
            //     </span>
            // )
            dataRow.width = 250
            columns.push(dataRow)

            if(state.selectedPolicy === 'Asset') {
                dataRow = {}
                dataRow.Header = 'Asset'
                dataRow.accessor = 'asset_name'		
                dataRow.width = 300
                columns.push(dataRow)
            } else if(state.selectedPolicy === 'Service') {
                dataRow = {}
                dataRow.Header = 'Service'
                dataRow.accessor = 'service_name'		
                dataRow.width = 300
                columns.push(dataRow)
            } else if(state.selectedPolicy === 'Tags') {
                dataRow = {}
                dataRow.Header = 'Tags'
                dataRow.accessor = 'tags'
                dataRow.Cell = cellInfo => (
                    <div className='flex'>
                        <span className=''>{cellInfo.row.original.tags && cellInfo.row.original.tags.length ? cellInfo.row.original.tags[0].key + ' : ' + cellInfo.row.original.tags[0].value : ''}
                        </span>
                        {cellInfo.row.original.tags && cellInfo.row.original.tags.length > 1 ?
                            <small className='ml-1 text-info'>more</small>
                        : null}
                    </div>
                )
                dataRow.width = 300
                columns.push(dataRow)
            }

            // if(state.actionsAllowed && (state.actionsAllowed.includes('update') || state.actionsAllowed.includes('delete'))) {
                dataRow  = {}
                dataRow.Header = 'Action'
                // dataRow.accessor = 'description'
                dataRow.Cell = cellInfo => (
                    <div className="flex">
                        {state.actionsAllowed && state.actionsAllowed.includes('update') ?
                            <Icon icon="octicon:pencil-24" width='16' height='16' className="mr-1 self-center cursor-pointer text-white" onClick={() => setState(prevState => ({ ...prevState, selectedDetails: cellInfo.row.original }))} />
                        : null}
                        {state.actionsAllowed && state.actionsAllowed.includes('delete') ?
                            <Icon icon="mdi:trash" width='16' height='16' className="mr-1 self-center cursor-pointer" onClick={(event) => {
                                event.stopPropagation();
                                onClickDelete(cellInfo.row.original)
                            }} />
                        : null}
                    </div>
                )
                dataRow.width = 150
                columns.push(dataRow)
            // }

            dataRow = {}
            dataRow.Header = 'Description'
            dataRow.accessor = 'description'		
            dataRow.width = 320
            columns.push(dataRow)
            

            setState(prevState => ({ ...prevState, dynamicHeaders: columns }))
        }
    }, [state.formHeaders, state.accounts, state.selectedPolicy, onClickDelete, state.actionsAllowed])
    
    const handleChildClick = (event, type, dropdownType, section) => {
		if (clickOutside.current && !clickOutside.current.contains(event.target)) {
            dispatch(setCommonPropsDetails('clickedonParent', true))
		}

        event.stopPropagation();
        let clickedChild = []
        if(type === 'child') {
            if(state.clickedChild && state.clickedChild.length) {
                state.clickedChild.forEach(row => {
                    setState(prevState => ({ ...prevState, [row]: false }))
                })
            }
            clickedChild.push(section)
            setState(prevState => ({ ...prevState, [section]: dropdownType === "singleDropDown" && state[section] ? false : true, clickedChild }))
        } else if(type === 'parent') {
            if(state.clickedChild && state.clickedChild.length) {
                state.clickedChild.forEach(row => {
                    setState(prevState => ({ ...prevState, [row]: false }))
                })
            }
            
            setState(prevState => ({ ...prevState, clickedChild }))
        }
    }

    return (
        <div onClick={(event) => handleChildClick(event, 'parent')}>
            <div className={`${state.showLoading ? '' : 'hidden'}`}>
                <div className='loading-wave'>
                    <div className='overlayEqualizerLoader'>
                        <div className="loading-bar"></div>
                        <div className="loading-bar"></div>
                        <div className="loading-bar"></div>
                        <div className="loading-bar"></div>
                    </div>
                </div>
            </div>
            
            {(state.selectedDetails && Object.entries(state.selectedDetails).length) || state.createLifecycle ? 
                <ViewSidePanel 
                    closeSidePanel={() => setState(prevState => ({...prevState, selectedDetails: {}, showLoading: false, createLifecycle: false }))}
                    selectedDetails={state.createLifecycle ? {} : state.selectedDetails}
                    refreshlist={() => {
                        setState(prevState => ({...prevState, callSearch: true }))
                    }}
                    pageType={state.createLifecycle ? "Create" : "Modify"}
                />
            : null}
            
            <div className="bg-nero w-full h-fit relative stickyUiChange">
                <div className="grid grid-cols-12 items-center pb-4">
                    <div className="xl:col-span-4 col-span-12 self-center xl:mt-4">
                        <p className="text-white mx-4 text-xl md:mt-0 mt-4 font-semibold">Lifecycle</p>
                        <p className="mx-4 text-white">Unified view of Metrics, Alerts, and Events in your Cloud HPC</p>
                    </div>
                    <div className="xl:col-span-8 col-span-12 items-center lg:mt-4 mt-6 lg:ml-4" ref={clickOutside}>
                        <FilterSection
                            filters={state.topSectionFilters}
                            topFilterOptions= {state.topFilterOptions}
                            selectedFilters={(filter) => handleSelectedFilters(filter)}
                            clickedonParent={state.clickedonParent}
                            filterAdvancedSearch={false}
                            onClickAdvancedSearch={() => setState(prevState => ({ ...prevState, showAdvancedFilters: true }))}
                            onReset={onReset}
                            selectedPolicy={'Asset'}
                        />
                    </div>
                </div>
            </div>
            <div className='m-2'>
                {!state.showLoading ?
                    <div className="flex flex-wrap">
                        <div className={`flex w-full justify-end mb-0 self-center`}>
                            {state.lifeCyclePolicies && state.lifeCyclePolicies.length ?
                                <Search
                                    data={state.lifeCyclePolicies ? state.lifeCyclePolicies : []}
                                    topClassName={'text-black bg-transparent border border-mediumDarkGray rounded-md'}
                                    searchClassName={'px-2'}
                                    searchIconColor={'text-white'}
                                    searchPlaceHolder={'Search....'}
                                    className={'bg-transparent text-white px-2 py-1'}
                                    widthHeight={25}
                                    filteredData={filteredPolicies => {
                                        setState(prevState => ({ ...prevState, filteredPolicies }))
                                    }}
                                />
                            : null}
                            {state.actionsAllowed && state.actionsAllowed.includes('create') ?
                                <button className={`btn bg-green rounded-md px-2 py-1 p-1 text-white ml-2`} onClick={() => setState(prevState => ({ ...prevState, createLifecycle: true }))}>Create&nbsp;Policy</button>
                            : null}
                        </div>
                    </div>
                : null}
                <div className='mt-2'>
                    {state.filteredPolicies && state.filteredPolicies.length && !state.showLoading ? (
                        <ResizeableDarkThemeTable
                            columns={state.dynamicHeaders}
                            perPage={20}
                            tableHead={'bg-black text-white'}
                            tableBodyColor={'bg-dark2 text-white'}
                            tableType={'table-dark-hover'}
                            perPageClass={`bg-transparent text-lightGray`}
                            textColor={'text-white'}
                            selectedColor={'bg-transparent'}
                            dashboard={state.filteredPolicies && state.filteredPolicies.length ? true : false}
                            sortByColumn={''}
                            riskTooltip={[0]}
                            onClickRow={tableRow => {}}
                            data={state.filteredPolicies}
                            selectedRecord={state.selectedTableRow}
                        />
                    ) : (!state.showLoading && state.filteredPolicies && !state.filteredPolicies.length) ||
                    (state.filteredPolicies && !state.filteredPolicies.length && !state.showLoading) ? (
                        <div className='flex justify-center m-4'>
                            <p>There are no data on this criteria. Please try adjusting your filter.</p>
                        </div>
                    ) : null}
                    {state.showLoading ? (
                        <div className='flex justify-center m-4'>
                            <svg className="animate-spin h-5 w-5 mr-2 text-white" fill="currentColor" viewBox="0 0 24 24">
                                <circle cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="2" fill="none" />
                                <path d="M4 12a8 8 0 0112-6.9" />
                            </svg>
                        </div>
                    ) : null}
                </div>
            </div>
        </div>
    )
}

export default LandingPage