/*************************************************
 * Collider
 * @exports
 * @file ResizeableDarkThemeTable.js
 * @author Prakash //on 15/06/2023
 * @copyright © 2022 Collider. All rights reserved.
 *************************************************/
import React, { useEffect, useState } from 'react'
import {Icon} from "@iconify/react";
import { useTable, useRowSelect, useBlockLayout, useResizeColumns, useSortBy, usePagination } from 'react-table'
import { UncontrolledTooltip } from 'reactstrap'
// import styled from "styled-components";
import { useSticky } from "react-table-sticky";

const toolTipModifiers = {
	flip: { behavior: ['top'] },
	preventOverflow: { boundariesElement: 'viewport' },
}

function ResizeableDarkThemeTable({
	columns,
	data,
	totalData=0,
	dashboard,
	perPage = 10,
	perPageClass=false,
	sortByColumn,
	sortByType = true,
	tooltipFor = false,
	riskTooltip = false,
	onClickRow,
	callToFetchNewRecord, //retrun function
	selectedRecord,
	tableHead = false,
	tableBody = false,
	tableBodyColor=false,
	tableSize = false,
	columnFontSize = false,
	rounded = false,
	tableType = false,
	textColor = false,
	selectedColor = false,
	deSelectBackground = false,
	emptyMessage = '',
	defaultSelectedRecord = 0,
	checkForNextSetoffRecords=false,
	paginationIndex=0,
	showPaginationTop=false,
	paginationIconClass=false,
	paginationIconWidth=false,
	fetchNextRecord= false,
	paginationColor=false
}) {
	const [state, setState] = useState({
		fetchNextRecord: false
	})
	let sortBy = []
	columns = React.useMemo(
		() => columns, [columns]
	)
	const defaultColumn = React.useMemo(
		() => ({
			// When using the useFlexLayout:
			minWidth: 30, // minWidth is only used as a limit for resizing
			width: 150, // width is used for both the flex-basis and flex-grow
			maxWidth: 600, // maxWidth is only used as a limit for resizing
		}),
		[]
	)
	if (sortByColumn && sortByColumn !== '') {
		if (!sortByType) {
			sortBy = [
				{
					id: sortByColumn,
					asc: true,
				},
			]
		} else {
			sortBy = [
				{
					id: sortByColumn,
					desc: true,
				},
			]
		}
	}

	useEffect(() => {
		if(state.fetchNextRecord) {
			setTimeout(() => { setState(prevState => ({ ...prevState, fetchNextRecord: false })) }, 2000); 
		}

	}, [state.fetchNextRecord])

	const [selectedRowIndex, setSelectedRowIndex] = useState(defaultSelectedRecord)
	const {
		getTableProps,
		getTableBodyProps,
		headerGroups,
		page,
		prepareRow,
		canPreviousPage,
		canNextPage,
		pageOptions,
		pageCount,
		gotoPage,
		nextPage,
		previousPage,
		setPageSize,
		// preGlobalFilteredRows,
		// setGlobalFilter,
		// state,
		// rows,
		// selectedFlatRows,
		// state: { pageIndex, pageSize, selectedRowIds },
		state: { pageIndex, pageSize },
	} = useTable(
		{
			columns,
			defaultColumn,
			data,
			initialState: {
				pageIndex: paginationIndex,
				pageSize: perPage,
				sortBy: sortBy,
			},
		},
		useBlockLayout,
		useResizeColumns,
		useSortBy,
		usePagination,
		useRowSelect,
		useSticky
	)

	const getNextPage = () => {
		// pageIndex + 2 <= pageOptions.length && checkForNextSetoffRecords
		if(pageIndex + 2 <= pageOptions.length) {
			nextPage()
		} else if(checkForNextSetoffRecords) {
			callToFetchNewRecord(pageIndex+1)
			fetchNextRecord = true
			setState(prevState => ({ ...prevState, fetchNextRecord: true }))
		}
	}

	return (
		<React.Fragment>
			{showPaginationTop && dashboard && (totalData > perPage || data.length > perPage) ? (
				<div className={`flex justify-end text-sm my-1 ${paginationColor ? paginationColor : ''}`}>
					Rows per page:{' '}
					<select
						className={`${perPageClass ? perPageClass : "bg-primary text-lightGray"}`}
						value={pageSize}
						onChange={e => {
							setPageSize(Number(e.target.value))
						}}
					>
						{[perPage * 1, perPage * 2, perPage * 3, perPage * 4, perPage * 5].map(pageSize => (
							<option className='text-lightGray' key={pageSize} value={pageSize}>
								{pageSize}
							</option>
						))}
					</select>
					<span className={`mx-3 self-center ${paginationColor ? paginationColor : ''}`}>
						Page {pageIndex + 1} of {pageOptions.length}{' '}
					</span>
					<button className='mr-1' onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
						<Icon icon="system-uicons:push-left" className=' mr-1' />
					</button>{' '}
					<button className='mr-1' onClick={() => previousPage()} disabled={!canPreviousPage}>
						<Icon icon="basil:arrow-left-outline" className=' mr-1' />
					</button>{' '}
					{!state.fetchNextRecord ?
						<button className='mr-1' onClick={() => getNextPage()}>
							{/* onClick={() => nextPage()} */}
							{/* disabled={!canNextPage} */}
							<Icon icon="basil:arrow-right-outline" className=' mr-1' />
						</button>
					:
						<svg className="animate-spin h-5 w-5 mr-3 text-blue-500" fill="currentColor" viewBox="0 0 24 24">
							<circle cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="2" fill="none" />
							<path d="M4 12a8 8 0 0112-6.9" />
						</svg>
					}{' '}
					<button onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
						<Icon icon="system-uicons:push-right" />
					</button>
				</div>
			) : null}
			<div className={`${rounded ? 'rounded-md' : ''} w-full`}>
				<div className="relative overflow-x-auto">
					<table
						{...getTableProps()}
						className={`w-full text-left table-sm
							${tableSize ? tableSize : 'table-sm'} 
							${tableType ? tableType : 'table-dark-hover'} 
							${textColor ? textColor : 'text-white'} 
						`}
					>
						<thead className={`text-md whitespace-nowrap text-white ${tableHead ? tableHead : 'bg-muted'}`}>
							{headerGroups.map((headerGroup, i) => (
								<tr key={i} {...headerGroup.getHeaderGroupProps()} className='flex-1 w-full'>
									{headerGroup.headers.map((column, index) => (
										<th scope="col" className={`px-4 py-2 text-secondary text-md font-bold ${column.textColor ? column.render("textColor") : ""}`} key={index} {...column.getHeaderProps()} onClick={() => setSelectedRowIndex(0)}>
											<div {...column.getResizerProps()}>
												<span 
													{...column.getSortByToggleProps({ title: 'Sort' })}
													style={{ marginRight: 10, cursor: 'pointer' }}
													className='flex'
													>
													{column.isSorted ?
														<Icon icon={`fa-solid:sort-${column.isSortedDesc ? 'down' : 'up'}`} color="white" width="16" height="16" className="pt-1" />
													: null}
													<span className={`${column.isSorted ? "ml-1" : ""}`}>{column.render('Header')}</span> 
													{column.HeaderUnit ?
														<span className="text-lightGray self-center ml-1">({column.HeaderUnit})</span>
													: null}
												</span>
											</div>
											{/* {headerGroup.headers.length - 1 === index ? null : (
												<div {...column.getResizerProps()} className={`flex-1 py-2 px-4 border`} />
											)} */}
										</th>
									))}
								</tr>
							))}
						</thead>
						<tbody className={` ${tableBodyColor ? tableBodyColor : ''} ${tableBody ? tableBody === "completed" : 'bg-secondary'} text-sm`} {...getTableBodyProps()}>
							{page.map((row, i) => {
								if (selectedRecord && !i && !selectedRowIndex && selectedRecord !== row.original) {
									onClickRow(row.original, '')
								}
								prepareRow(row)
								return (
									<tr className={`${deSelectBackground ? '' : selectedRowIndex === i ? (selectedColor ? selectedColor : 'bg-lightGray') : ''} w-full`} key={i}
										{...row.getRowProps()}
										onClick={() => {
											onClickRow(row.original, 'click')
											setSelectedRowIndex(i)
										}}
									>
										{row.cells.map((cell, index) => {
											return (
												<React.Fragment key={index}>
													{tooltipFor && tooltipFor.includes(index) && (
														<UncontrolledTooltip
															key={index}
															placement='top'
															modifiers={toolTipModifiers}
															target={`targetIdNotify${i}${index}`}
														>
															{cell.render('Cell')}
														</UncontrolledTooltip>
													)}
													{riskTooltip &&
														riskTooltip.includes(index) &&
														(cell.row.values.severity ? (
															<span className='uncontrolledTooltip'>
																<UncontrolledTooltip
																	/* isOpen={true} */
																	key={index}
																	placement='top'
																	modifiers={toolTipModifiers}
																	target={`targetIdNotify${i}${index}`}
																	//trigger="click"
																	//className="uncontrolledTooltip"
																	/* style={{backgroundColor:'red'}} */
																>
																	{cell.row.values.severity}
																</UncontrolledTooltip>
															</span>
														) : null)}
													<td
														key={index}
														className={`${columnFontSize} ${cell.column.textColor} ${
															!cell.column.className || cell.column.className === ''
																? 'truncate'
																: cell.column.className
														} px-4 py-2 font-medium text-sm self-center`}
														{...cell.getCellProps()}
														id={`targetIdNotify${i}${index}`}
													>
														{cell.render('Cell')}
													</td>
												</React.Fragment>
											)
										})}
									</tr>
								)
							})}

							{data.length ? null : (
								<tr className='bg-lightGray text-center'>
									<td colSpan={columns.length} className='text-truncate border-0'>
										{emptyMessage !== '' ? (
											<span>{emptyMessage}</span>
										) : (
											<span>There are no data on this criteria. Please try adjusting your filter.</span>
										)}
									</td>
								</tr>
							)}
						</tbody>
					</table>
				</div>
			</div>
			{dashboard && (totalData > perPage || data.length > perPage) ? (
				<div className={`flex justify-end text-sm my-1 ${paginationColor ? paginationColor : ''}`}>
					Rows per page:{' '}
					<select
						className={`${perPageClass ? perPageClass : "bg-primary text-lightGray"}`}
						value={pageSize}
						onChange={e => {
							setPageSize(Number(e.target.value))
						}}
					>
						{[perPage * 1, perPage * 2, perPage * 3, perPage * 4, perPage * 5].map(pageSize => (
							<option className='text-lightGray' key={pageSize} value={pageSize}>
								{pageSize}
							</option>
						))}
					</select>
					<span className={`mx-3 self-center ${paginationColor ? paginationColor : ''}`}>
						Page {pageIndex + 1} of {pageOptions.length}{' '}
					</span>
					<button className='mr-1' onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
						<Icon icon="system-uicons:push-left" className=' mr-1' />
					</button>{' '}
					<button className='mr-1' onClick={() => previousPage()} disabled={!canPreviousPage}>
						<Icon icon="basil:arrow-left-outline" className=' mr-1' />
					</button>{' '}
					{!state.fetchNextRecord ?
						<button className='mr-1' onClick={() => getNextPage()}>
							{/* onClick={() => nextPage()} */}
							{/* disabled={!canNextPage} */}
							<Icon icon="basil:arrow-right-outline" className=' mr-1' />
						</button>
					:
						<svg className="animate-spin h-5 w-5 mr-3 text-blue-500" fill="currentColor" viewBox="0 0 24 24">
							<circle cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="2" fill="none" />
							<path d="M4 12a8 8 0 0112-6.9" />
						</svg>
					}{' '}
					<button onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
						<Icon icon="system-uicons:push-right" />
					</button>
				</div>
			) : null}
		</React.Fragment>
	)
}

export default ResizeableDarkThemeTable
