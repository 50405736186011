/* eslint-disable no-mixed-spaces-and-tabs */
/*************************************************
 * Kiosk
 * @exports
 * @file LandingPage.js
 * @author Prakash // on 18/03/2024
 * @copyright ©2024 Kiosk. All rights reserved.
 *************************************************/
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux'; // Import useDispatch hook to dispatch actions
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { deleteDepartment, listDepartmentsMappings } from '../../../actions/cost/BudgetAction'
import { capitalizeTheFirstLetterOfEachWord } from '../../../utils/utility'

import { CSVLink } from 'react-csv';

import ResizeableDarkThemeTable from '../../designComponents/Table/ResizeableDarkThemeTable'

import Search from '../../common/SearchWithHiddenInput'
import { Store as CommonNotification } from 'react-notifications-component';
import { URL_PATH } from '../../../config/urlPath';
import { setHpcPropsDetails } from '../../../actions/Collider/HpcAction';
import {Icon} from "@iconify/react";

const LandingPage = () => {
    // State variables for filters
    const [state, setState] = useState({
        showLoading: true,
        callSearch: true
    })

    const navigate = useNavigate();
    const dispatch = useDispatch(); // Create a dispatch function
    const location = useLocation()
    const userMenu = useSelector(state => state?.filters?.userMenu || false);
    const currentPath = location.pathname;

    useEffect(() => {
        if(userMenu && Object.entries(userMenu).length) {
			let actionsAllowed = []
			userMenu.menu && userMenu.menu.forEach(row => {
                if(row.submenulist && row.submenulist.length) {
                    if(row.submenulist.filter(e => e.link === currentPath && e.action).length) {
                        actionsAllowed = row.submenulist.filter(e => e.link === currentPath)[0].action
                    }
				} 
                // else {
				// 	if(row.action) {
				// 		actionsAllowed = row.action
				// 	}
				// }
			})
            setState(prevState => ({ ...prevState, actionsAllowed }))
		}
    }, [userMenu, currentPath])

    useEffect(() => {
        if(state.showLoading) {
            setTimeout(() => { setState(prevState => ({ ...prevState,  showLoading: false })) }, 5000);
        }
    }, [state.showLoading])

    useEffect(() => {
        if(state.callSearch) {
            setState(prevState => ({ ...prevState, callSearch: false }));
            let params = {}
            if(state.selectedProvider && state.selectedProvider !== '') {
                params.provider = state.selectedProvider.toLowerCase()
            }
            if(state.selectedAccount && state.selectedAccount.length && !state.selectedAccount.includes("All")) {
                params.account_id = state.selectedAccount
            }
    
            dispatch(listDepartmentsMappings(params))
                .then((response) => {
                    if(response) {
                        let results = response.results ? response.results : []
                        setState(prevState => ({
                            ...prevState,
                            listResponse: results,
                            filterdListResponse: results,
                            showLoading: false
                        }));
                    }
                })
        }
    }, [state.callSearch, dispatch, state.selectedAccount, state.selectedProvider])

    useEffect(() => {
        let dataResult = state.listResponse && state.listResponse.length ? state.listResponse : [];
		if(dataResult.length) {
			let headers = []
			Object.entries(dataResult[0]).forEach(([key, value]) => {
				if(typeof value === 'string') {
					let headerRow = {}
					headerRow.label = capitalizeTheFirstLetterOfEachWord(key)
					headerRow.key = key
					headers.push(headerRow)
				}
			})

			setState(prevState => ({ ...prevState, headers }))
		}
    }, [state.listResponse])

    const navigateTo = () => {
        dispatch(setHpcPropsDetails('createMappingInputs', {}))
        navigate(URL_PATH.ADMIN_DEPARTMENT_MAPPING_CREATE, { state: { pageType: 'Create' } });
    }

    const deleteDepartmentFunction = () => {
        setState(prevState => ({ ...prevState, showDeleteLoading: true }))
        let params = state.deleteDepartment
        dispatch(deleteDepartment(params))
            .then((response) => {
                if(response) {
                    let messageType = 'danger'
                    let message = response && response.message ? response.message : "Error in deleting"

                    if(response.status) {
                        messageType = 'success'
                        setTimeout(() => { setState(prevState => ({ ...prevState, showLoading: true, callSearch: true, deleteModal: !state.deleteModal, showDeleteLoading: false }))}, 1000)
                    } else {
                        setState(prevState => ({ ...prevState, showDeleteLoading: false }))
                    }


                    CommonNotification.addNotification({
                        //title: 'Wonderful!',
                        message: message,
                        type: messageType,
                        insert: 'top',
                        container: 'top-center',
                        // animationIn: ['animate__animated', 'animate__fadeIn'],
                        // animationOut: ['animate__animated', 'animate__fadeOut'],
                        dismiss: {
                            duration: 5000,
                            onScreen: false,
                            pauseOnHover: true,
                            showIcon: true,
                        }
                    });
                } else {
                    setState(prevState => ({ ...prevState, showDeleteLoading: false }))
                }
            })
    }

    const getTotalProjects = (data) => {
        let totalProjects = 0;
        data.initiatives.forEach(initiative => {
          totalProjects += initiative.projects.length;
        });
        return totalProjects;
    }

    const getTotalAccounts = (data) => {
        let totalAccounts = []
        data.initiatives.forEach(initiative => {
            initiative.projects.forEach(project => {
                project.accounts.forEach(acc => {
                    totalAccounts.push(acc.billingUnit)
                })
                // totalAccounts += project.accounts.length;
            })
        });
        
        return  [...new Set(totalAccounts)].length;
    }

    return (
        <div>
            <div className={`${state.showLoading ? '' : 'hidden'}`}>
                <div className='loading-wave'>
                    <div className='overlayEqualizerLoader'>
                        <div className="loading-bar"></div>
                        <div className="loading-bar"></div>
                        <div className="loading-bar"></div>
                        <div className="loading-bar"></div>
                    </div>
                </div>
            </div>
            <div className="bg-nero w-full h-fit relative stickyUiChange">
                <div className="grid grid-cols-12 items-center pb-4">
                    <div className="xl:col-span-3 col-span-12 self-center xl:mt-4">
                        <p className="text-white mx-4 text-xl md:mt-0 mt-4 font-semibold">Manage Departments</p>
                        <p className="mx-4 text-white">Consolidated view of departments</p>
                    </div>
                </div>
            </div>
            <div className='p-2 min-h-screen overflow-auto'>
                <div className='flex flex-wrap justify- mb-2'>
                    <div className='lg:w-1/2 w-full self-center'>
                        <p className='mb-0 self-center'>Showing {state.filterdListResponse && state.filterdListResponse.length} out of total {state.listResponse && state.listResponse.length} department(s)</p>
                    </div>
                            
                    <div className='self-center lg:w-1/2 w-full'>
                        <div className='flex justify-end'>
                            {state.filterdListResponse && state.filterdListResponse.length ?
                                <CSVLink 
                                    data={state.filterdListResponse ? state.filterdListResponse : []} 
                                    headers={state.headers ? state.headers : []}
                                    filename={'job-lists.csv'}
                                    className={'self-center'}
                                    target='_blank'
                                >
                                    <Icon icon="material-symbols:download" className='text-gray5 self-center' width="22" height="22"/>
                                </CSVLink>
                            : null}
                            {state.listResponse && state.listResponse.length ?
                                <div className='self-center mr-2 w-60'>
                                    <Search
                                        data={state.listResponse ? state.listResponse : []}
                                        topClassName={'text-black bg-transparent border border-mediumDarkGray rounded-md'}
                                        searchClassName={'px-2'}
                                        searchIconColor={'text-white '}
                                        searchPlaceHolder={'Search....'}
                                        className={'bg-transparent text-white'}
                                        widthHeight={25}
                                        filteredData={(filterdListResponse) => setState(prevState => ({ ...prevState, filterdListResponse }))}
                                    />
                                </div>
                            : null}
                            {state.actionsAllowed && state.actionsAllowed.includes('create') ?
                                <button className={`btn bg-green rounded-md px-2 py-1 text-white ml-2 text-nowrap`} onClick={() => navigateTo()}>Create&nbsp;Department</button>
                            : null}
                        </div>                                    
                    </div>
                </div>
                
                {state.filterdListResponse && state.filterdListResponse.length ?
                    <ResizeableDarkThemeTable
                        columns={[
                            {
                                Header: 'Department',
                                accessor: 'department',
                                width: 250,
                            },
                            {
                                Header: 'Initiatives',
                                // accessor: 'job_id',
                                Cell: cellInfo => (<span>{cellInfo.row.original.initiatives && cellInfo.row.original.initiatives.length ? cellInfo.row.original.initiatives.length : ''}</span>),
                                width: 350,
                            },
                            {
                                Header: 'Projects',
                                Cell: cellInfo => (<span>
                                    {getTotalProjects(cellInfo.row.original)}
                                </span>),
                                width: 250,
                            },
                            {
                                Header: 'Accounts',
                                Cell: cellInfo => (<span>
                                    {getTotalAccounts(cellInfo.row.original)}
                                </span>),
                                width: 250,
                            },
                            {
                                Header: 'Action',
                                Cell: cellInfo => (
                                    <div className="flex self-center">
                                        {state.actionsAllowed && state.actionsAllowed.includes('update') ?
                                            <Link 
                                                to={URL_PATH.ADMIN_DEPARTMENT_MAPPING_CREATE} 
                                                state={{
                                                    selectedData: cellInfo.row.original,
                                                    pageType: 'Edit',

                                                }}
                                                className='text-info ml-3'>
                                                Edit
                                            </Link>
                                        : null}
                                        {state.actionsAllowed && state.actionsAllowed.includes('delete') ?
                                            <p className='ml-3 mb-0 text-danger cursor-pointer' onClick={() => setState(prevState => ({ ...prevState, deleteModal: true, deleteDepartment: cellInfo.row.original }))}>Delete</p>
                                        : null}
                                    </div>
                                ),
                                width: 200,
                            },
                            {
                                Header: ' ',
                                width: 1,
                                // accessor: 'end_time',
                                // Cell: cellInfo => (<span className='text-info cursor-pointer'>Details</span>)
                            },
                        ]}
                        data={state.filterdListResponse}
                        perPage={10}
                        tableHead={'bg-black text-white'}
                        tableBody={'text-black'}
                        tableBodyColor={'bg-black3'}
                        tableType={'table-light-hover'}
                        perPageClass={`bg-lightGray text-white rounded-md ml-2`}
                        textColor={'text-white'}
                        selectedColor={'bg-transparent'}
                        dashboard={true}
                        paginationColor={'text-white'}
                        sortByColumn={''}
                        riskTooltip={[0]}
                        onClickRow={tableRow => {}}
                    />
                : 
                    <div className='flex justify-center m-4 text-white'>
                        <p>There are no data on this criteria. Please try adjusting your filter.</p>
                    </div>
                }

                <div id="popup-modal" tabIndex="-1" className={`flex ${state.deleteModal ? '' : 'hidden'} overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 h-[calc(100%-1rem)] max-h-full`}>
                    <div className="relative p-4 w-full max-w-md max-h-full">
                        <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
                            <button type="button" className="absolute top-3 end-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white" data-modal-hide="popup-modal" onClick={() => setState(prevstate => ({ ...prevstate, deleteModal: false, showDeleteLoading: false, deleteDepartment: {} }))}>
                                <svg className="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
                                </svg>
                                <span className="sr-only">Close modal</span>
                            </button>
                            <div className="p-4 md:p-5 text-center">
                                <svg className="mx-auto mb-4 text-gray-400 w-12 h-12 dark:text-gray-200" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M10 11V6m0 8h.01M19 10a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"/>
                                </svg>
                                <h3 className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">Are you sure you want to delete the department "{state.deleteDepartment && state.deleteDepartment.department ? state.deleteDepartment.department : ''}"?</h3>

                                <div className='flex mt-4'>
                                <button data-modal-hide="popup-modal" type="button" className="py-2 px-2 rounded-md text-white bg-green mr-2" 
                                    onClick={() => {
                                        setState(prevState => ({ ...prevState, showDeleteLoading: true }))
                                        if(!state.showDeleteLoading) {
                                            deleteDepartmentFunction()
                                        }
                                    }}
                                >
                                    Yes, I'm sure
                                </button>
                                <button data-modal-hide="popup-modal" type="button" className="py-2 px-2 rounded-md bg-red text-white" onClick={() => {
                                    if(!state.showDeleteLoading) {
                                        setState(prevState => ({ ...prevState, deleteModal: !state.deleteModal, showDeleteLoading: false, name: '' }))
                                    }
                                }}>
                                No, cancel
                                </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default LandingPage    