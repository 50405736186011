/* eslint-disable react/jsx-curly-spacing */
/* eslint-disable no-mixed-spaces-and-tabs */
/*************************************************
 * Collider
 * @exports
 * @file LandingPage.js
 * @author Prakash // on 09/09/2024
 * @copyright © 2024 Collider. All rights reserved.
 **************************************************/
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux'; // Import useDispatch hook to dispatch actions
import { useLocation } from 'react-router-dom';
// import { listAllUserDetails } from '../../../actions/UserAction'

// import { capitalizeAllLetter, capitalizeTheFirstLetterOfEachWord, currentLocaltime, momentConvertionUtcToLocalTime, momentDateFormat, thousandSeparator } from '../../../utils/utility'
// import Search from '../../common/SearchWithHiddenInput'

// import { CSVLink } from 'react-csv';
// import { Store as CommonNotification } from 'react-notifications-component';
import MultiSelectSection from '../../common/MultiSelectSection';
import {Icon} from "@iconify/react";

const LandingPage = () => {	
	const[state, setState] = useState({
		showLoading: true,
		authenticationType: [{
			"auth_type": "azure_ad",
			"label": "Azure AD"
		},{
			"auth_type": "cognito",
			"label": "AWS Cognito"
		}],
		actionsAllowed: []
	})

	const userMenu = useSelector(state => state?.filters?.userMenu || false);

    const dispatch = useDispatch(); // Create a dispatch function\
    const location = useLocation();
	const currentPath = location.pathname;

	// useEffect(() => {
    //     if(userMenu && Object.entries(userMenu).length) {
	// 		let actionsAllowed = []
	// 		userMenu.menu && userMenu.menu.forEach(row => {
    //             if(row.submenulist && row.submenulist.length) {
    //                 if(row.submenulist.filter(e => e.link === currentPath && e.action).length) {
    //                     actionsAllowed = row.submenulist.filter(e => e.link === currentPath)[0].action
    //                 }
	// 			} 
	// 		})
    //         setState(prevState => ({ ...prevState, actionsAllowed }))
	// 	}
    // }, [userMenu, currentPath])

	useEffect(() => {
		setState(prevState => ({ ...prevState, callSearch: true }));
		setTimeout(() => { setState(prevState => ({ ...prevState, showLoading: false })) }, 3000)
	}, [])

	// useEffect(() => {
	// 	if(state.callSearch) {
	// 		setState(prevState => ({ ...prevState, callSearch: false }))
	// 		let params = {}
	// 		dispatch(listAllUserDetails(params))
	// 			.then((response) => {
	// 				if(response) {
	// 					setState(prevState => ({ ...prevState, listUsers: response, filterListUsers: response, showLoading: false }))
	// 				} else {
	// 					setState(prevState => ({ ...prevState, listUsers: [], filterListUsers: [], showLoading: false }))
	// 				}
	// 			})
	// 	}
	// }, [state.callSearch, dispatch])

	useEffect(() => {
        if(state.callSaveFunction) {
            setState(prevState => ({ ...prevState, callSaveFunction: false }))
            let hasError = false
            let params = {}
			params.auth_type = state.authType
            if(!state.authType) {
                hasError = true
            }

			if(state.authType === 'congnito') {
				if(!state.region || !state.userPoolId || !state.userPoolWebClientId) {
					hasError = true
				}
				params.region = state.region
				params.userPoolId = state.userPoolId
				params.userPoolId = state.userPoolId
			} else if(state.authType === 'auzre_ad') {
				if(!state.clientId ) {
					hasError = true
				}
				params.clientId = state.clientId
			}

            setState(prevState => ({ ...prevState, hasError, saveLoading: hasError ? false : true }))

        //    if(!hasError) {
        //         if(pageMode === 'Create') {
        //             dispatch(createUser(params))
        //                 .then((response) => {
        //                     let messageType = 'danger'
        //                     let message = response.message ? response.message : 'Error in saving Authentication'
                            
        //                     if(response && response.status) {
        //                         refreshApi()
        //                         messageType = 'success'
        //                         message = response.message ? response.message : 'Authentication Created Successfully'
                                
        //                         setTimeout(() => closeSidePanel(), 2000)
        //                     } else {
        //                         setState(prevState => ({ ...prevState, saveLoading: false }))
        //                     }
            
        //                     CommonNotification.addNotification({
        //                         //title: 'Wonderful!',
        //                         message: message,
        //                         type: messageType,
        //                         insert: 'top',
        //                         container: 'top-center',
        //                         // animationIn: ['animate__animated', 'animate__fadeIn'],
        //                         // animationOut: ['animate__animated', 'animate__fadeOut'],
        //                         dismiss: {
        //                         duration: 5000,
        //                         onScreen: false,
        //                         pauseOnHover: true,
        //                         showIcon: true,
        //                         }
        //                     });
            
        //                     // setState(prevState => ({ ...prevState, createRespons: response }))
        //                 })
        //         } else {
        //             dispatch(updateUser(params))
        //                 .then((response) => {
        //                     let messageType = 'danger'
        //                     let message = response.message ? response.message : 'Error in updating Authentication'
        //                     if(response && response.status) {
        //                         messageType = 'success'
        //                         message = response.message ? response.message : 'Authentication Updated Successfully'

        //                         refreshApi()
        //                     }

        //                     setTimeout(() => setState(prevState => ({ ...prevState, saveLoading: false })), 2000)
            
        //                     // setState(prevState => ({ ...prevState, saveLoading: false, message, messageType, createErrorMessage }))
            
        //                     CommonNotification.addNotification({
        //                         //title: 'Wonderful!',
        //                         message: message,
        //                         type: messageType,
        //                         insert: 'top',
        //                         container: 'top-center',
        //                         // animationIn: ['animate__animated', 'animate__fadeIn'],
        //                         // animationOut: ['animate__animated', 'animate__fadeOut'],
        //                         dismiss: {
        //                         duration: 5000,
        //                         onScreen: false,
        //                         pauseOnHover: true,
        //                         showIcon: true,
        //                         }
        //                     });
        //                 })
        //         }
        //     }
        }
    }, [state.callSaveFunction, dispatch])
	

    const handleChildClick = (event, type, dropdownType, section) => {	
		event.stopPropagation();
		let clickedChild = []
		if(type === 'child') {
            if(state.clickedChild && state.clickedChild.length) {
                state.clickedChild.forEach(row => {
                    setState(prevState => ({ ...prevState, [row]: false }))
                })
            }
            clickedChild.push(section)
            setState(prevState => ({ ...prevState, [section]: dropdownType === "singleDropDown" && state[section] ? false : true, clickedChild }))
        } else if(type === 'parent') {
            if(state.clickedChild && state.clickedChild.length) {
                state.clickedChild.forEach(row => {
                    setState(prevState => ({ ...prevState, [row]: false }))
                })
            }
            
            setState(prevState => ({ ...prevState, clickedChild }))
        }
	}
	/**
	 * Renders Admin settings Landing page
	*/
	return (
		<div onClick={(event) => handleChildClick(event, 'parent')}>
            <div className={`${state.showLoading ? '' : 'hidden'}`}>
                <div className='loading-wave'>
                    <div className='overlayEqualizerLoader'>
                        <div className="loading-bar"></div>
                        <div className="loading-bar"></div>
                        <div className="loading-bar"></div>
                        <div className="loading-bar"></div>
                    </div>
                </div>
            </div>
			
            <div className="bg-nero w-full h-fit relative stickyUiChange">
                <div className="grid grid-cols-12 items-center pb-4">
					<div className="col-span-12 self-center xl:mt-4">
                        <p className="text-white mx-4 text-xl md:mt-0 mt-4 font-semibold">Manage Authentication</p>
					</div>			
				</div>
			</div>
			<div className='m-2'>
				<div className='rounded-md bg-dark3 p-3'>
					<div className='flex flex-wrap mt-2 pt-2 border border-darkGray'>
						<div className='py-1 md:w-1/6 w-full'>
							<div className='flex justify-between'>
								<p className="b-block mb-0 text-lightGray">Authentication Typ</p>
								{state.hasError && (!state.authType) ?
									<span className='text-danger self-center'>required</span>
								: null}
							</div>
							<div className={`w-50 ${state.pageType === 'View' ? 'disabled' : ''}`} onClick={(event) => {
								event.preventDefault();
								handleChildClick(event, 'child', 'singleDropDown', "showApplications")
							}}>
								<p className={`flex bg-transparent border border-lightGray rounded-md px-2 py-1 justify-between mb-0 truncate ${state.authType ? 'text-white' : 'text-lightGray'}`}>
									{state.authType ? state.authenticationType.filter(e => e.auth_type === state.authType)[0].label : 'Select'}
									<Icon icon="icon-park-solid:down-one" className={`${state.authType ? "text-white" : "text-lightGray"} self-center`} width="16" height="16" />
								</p>
								{state.showApplications ?
									<div className='relative'>
										<MultiSelectSection 
											fields={["auth_type", "label"]}
											options={state.authenticationType}
											selectedValues={state.authType ? state.authType : ''}
											callbackMultiSelect={(value) => {
												if(!value || typeof(value) === 'string') {
													setState(prevState => ({ ...prevState, authType: value }))
												} else {
													value.preventDefault()
													handleChildClick(value, "search", 'singleDropDown', "")
												}
											}}
											singleSelection={true}
											widthClass={'minWidth220'}
											removeTopOptions={true}
										/>
									</div>
								: null}
							</div>
						</div>
					</div>
					{state.authType === 'cognito' ?
						<React.Fragment>
						<div className='flex mt-2'>
							<div className='py-1 md:w-1/3 w-full'>
								<p className='b-block mb-0 flex'> 
									Region
									{state.hasError && !state.region ?
										<span className='mb-0 text-red ml-2'>required</span>
									: null}
								</p>
								<div className='flex'>
									<input
										type='text'
										placeholder='Enter Region'
										className={`border border-lightGray rounded-md bg-transparent px-2 py-1 text-white w-full`}
										value={state.region ? state.region : ''}
										onChange={e => setState(prevState => ({ ...prevState, region: e.target.value }))}
									/>
								</div>
							</div>
						</div>
						<div className='flex mt-2'>
							<div className='py-1 md:w-1/3 w-full'>
								<p className='b-block mb-0 flex'>
									User Pool Id
									{state.hasError && !state.userPoolId ?
										<span className='mb-0 text-red ml-2'>required</span>
									: null}
								</p>
								<div className='flex'>
									<input
										type='text'
										placeholder='Enter User Pool Id'
										className={`border border-lightGray rounded-md bg-transparent px-2 py-1 text-white w-full`}
										value={state.userPoolId ? state.userPoolId : ''}
										onChange={e => setState(prevState => ({ ...prevState, userPoolId: e.target.value }))}
									/>
								</div>
							</div>
						</div>
						<div className='flex my-2'>
							<div className='py-1 md:w-1/3 w-full'>
								<p className='b-block mb-0 flex'>
									User Pool Web ClientId						
									{state.hasError && !state.userPoolWebClientId ?
										<span className='mb-0 text-red ml-2'>required</span>
									: null}
								</p>
								<div className='flex'>
									<input
										type='text'
										placeholder='Enter User Pool Web ClientId'
										className={`border border-lightGray rounded-md bg-transparent px-2 py-1 text-white w-full`}
										value={state.userPoolWebClientId ? state.userPoolWebClientId : ''}
										onChange={e => setState(prevState => ({ ...prevState, userPoolWebClientId: e.target.value }))}
									/>
								</div>
							</div>
						</div>
						</React.Fragment>
					: state.authType === 'azure_ad' ?
						<div className='flex flex-wrap gap-2'>
							<div className='py-1 md:w-1/3 w-full'>
								<p className='b-block mb-0 flex'> 
								 	client Id
									{state.hasError && !state.clientId ?
										<span className='mb-0 text-red ml-2'>required</span>
									: null}
								</p>
								<div className='flex'>
									<input
										type='text'
										placeholder='Enter client Id'
										className={`border border-lightGray rounded-md bg-transparent px-2 py-1 text-white w-full`}
										value={state.clientId ? state.clientId : ''}
										onChange={e => setState(prevState => ({ ...prevState, clientId: e.target.value }))}
									/>
								</div>
							</div>
						</div>
					: null}
				</div>
				<div className='flex bottom-4 right-4 justify-end bg-transparent my-2'>
					<div className={`flex justify-end w-full`}>
						{state.saveLoading ?
							<button className={`btn bg-info text-md px-2 py-1 rounded-md mr-2`}>
								<svg className="animate-spin h-5 w-5 mr-2 text-white" fill="currentColor" viewBox="0 0 24 24">
									<circle cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="2" fill="none" />
									<path d="M4 12a8 8 0 0112-6.9" />
								</svg>
							</button>
						: 
							<button className={`btn bg-info text-md px-2 py-1 rounded-md mr-2 text-white`} onClick={ () => setState(prevState => ({ ...prevState, saveLoading: true, callSaveFunction: true }))}>
								{state.pageMode === 'Edit' ? 'Update' : 'Save'}
							</button>
						}
						<button className={`btn bg-lightGray px-2 py-1 text-md rounded-md text-white`} onClick={ () => setState({ authenticationType: state.authenticationType, showLoading: true })}>Clear</button>
					</div>
				</div>
			</div>
		</div>
	)
}

export default LandingPage