import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux'; // Import useDispatch hook to dispatch actions
import { setCommonPropsDetails } from '../../../actions/commonActionNew'
import ClusterDetails from './ClusterDetails';
import QueueDetails from './QueueDetails';
import UserDetails from './UserDetails';
import { momentDateGivenFormat } from '../../../utils/utility';
import ProjectDetails from './ProjectDetails';
import FilterSection from '../../common/FiltersSection';

const LandingPage = () => {
    const clickOutside = useRef();

    // State variables for filters
    const [state, setState] = useState({
        showLoading: true,
        selectedType: 'cluster_name',
        selectedViewType: 'cluster_name',
        showPageLoading: true,
        selectedDuration: 'currentMonth',
        topSectionFilters: ['provider', 'account', 'region', 'cluster', 'type', 'duration'],
    })

    const dispatch = useDispatch(); // Create a dispatch function
    const providers = useSelector(state => state?.filters?.providers || false);
    
    useEffect(() => {        
        if(state.showLoading) {
            setTimeout(() => {
                setState(prevState => ({ ...prevState, showLoading: false }))
            }, 5000);
        }
    }, [state.showLoading]); // Empty dependency array ensures this runs only once on mount   

    // useEffect(() => {
    //     if(providers) {
    //         setState(prevState => ({ ...prevState, providers: providers, selectedProvider: prevState.selectedProvider ? prevState.selectedProvider : (providers && providers.length ? (providers.filter(e => e.provider_name === 'AWS').length ? 'AWS' : providers[0].provider_name) : '') }));
    //     }
    // }, [providers])

    useEffect(() => {
        let datePickerStartDate = ''
        let datePickerEndDate = ''
        if(state.selectedDuration === "currentMonth") {
            datePickerStartDate = momentDateGivenFormat(new Date(), 'YYYY-MM-01 00:00:00')
            datePickerEndDate = momentDateGivenFormat(new Date(), 'YYYY-MM-DD 23:59:59')
        } 
        setState(prevState => ({ ...prevState, datePickerStartDate, datePickerEndDate }));

	}, [state.selectedDuration])

    const handleSelectedFilters = (filter) => {
        setState(prevState => ({ 
            ...prevState,
            selectedProvider: filter.selectedProvider,
            selectedAccount: filter.selectedAccount ? filter.selectedAccount : [],
            selectedRegion: filter.selectedRegion ? filter.selectedRegion : [],
            selectedClusters: filter.selectedClusters ? filter.selectedClusters : [],
            selectedType: filter.selectedType ? filter.selectedType : '', 
            selectedViewType: filter.selectedType ? filter.selectedType : '', 
            selectedProject: filter.selectedProject ? filter.selectedProject : [],
            selectedProjectTags: filter.selectedProjectTags ? filter.selectedProjectTags : [],
            selectedUser: filter.selectedUser ? filter.selectedUser : [],
            datePickerStartDate: filter.datePickerStartDate,
            datePickerEndDate: filter.datePickerEndDate,
            showPageLoading: false,
            callSearch: true
        }))
    }

    const onReset = () => {
        setState(prevState => ({ ...prevState, selectedProvider: providers && providers.length ? (providers.filter(e => e.provider_name === 'AWS').length ? 'AWS' : providers[0].provider_name) : "", selectedAccount: [], selectedRegion: [], selectedClusters: [], selectedUser: [], selectedProject: '', selectedProjectTags : [], selectedDuration: 'currentMonth', datePickerStartDate: momentDateGivenFormat(new Date(), 'YYYY-MM-01 00:00:00'), datePickerEndDate: momentDateGivenFormat(new Date(), 'YYYY-MM-DD 23:59:59'), showPageLoading: false, callSearch: true }));
    }

    useEffect(() => {
        if(state.callSearch) {
            setState(prevState => ({ ...prevState, callSearch: false, showPageLoading: true }))
        }

    }, [state.callSearch])

    const handleChildClick = (event, type, dropdownType, section) => {
        if (clickOutside.current && !clickOutside.current.contains(event.target)) {
            dispatch(setCommonPropsDetails('clickedonParent', true))
        }

        event.stopPropagation();
        let clickedChild = []
        if(type === 'child') {
            if(state.clickedChild && state.clickedChild.length) {
                state.clickedChild.forEach(row => {
                    setState(prevState => ({ ...prevState, [row]: false }))
                })
            }
            clickedChild.push(section)
            setState(prevState => ({ ...prevState, [section]: dropdownType === "singleDropDown" && state[section] ? false : true, clickedChild }))
        } else if(type === 'parent') {
            if(state.clickedChild && state.clickedChild.length) {
                state.clickedChild.forEach(row => {
                    setState(prevState => ({ ...prevState, [row]: false }))
                })
            }
            
            setState(prevState => ({ ...prevState, clickedChild }))
        }
    };

    return (
        <div onClick={(event) => handleChildClick(event, 'parent')}>
            <div className={`${state.showLoading ? '' : 'hidden'}`}>
                <div className='loading-wave'>
                    <div className='overlayEqualizerLoader'>
                        <div className="loading-bar"></div>
                        <div className="loading-bar"></div>
                        <div className="loading-bar"></div>
                        <div className="loading-bar"></div>
                    </div>
                </div>
            </div>
            <div className="bg-nero w-full h-fit md: relative">
                <div className="grid grid-cols-12 items-center pb-4">
                    <div className="xl:col-span-3 col-span-12 self-center xl:mt-4">
                        <p className="text-white mx-4 text-xl md:mt-0 mt-4 font-semibold">SpendBoard</p>
                        <p className="mx-4 text-white">Consolidated view of spendboard</p>
                    </div>
                    <div className="xl:col-span-9 col-span-12 items-center lg:mt-4 mt-6 lg:ml-4" ref={clickOutside}>
                        <FilterSection
                            filters={state.topSectionFilters}
                            selectedFilters={(filter) => handleSelectedFilters(filter)}
                            clickedonParent={state.clickedonParent}
                            filterAdvancedSearch={false}
                            onClickAdvancedSearch={() => setState(prevState => ({ ...prevState, showAdvancedFilters: true }))}
                            onReset={onReset}
                        />
                    </div>
                </div>
            </div>
            <div className='p-2 flex-grow-1 overflow-auto'>
                <div className='mt-2'>
                    {state.showPageLoading && state.selectedProvider && state.datePickerStartDate ? 
                        state.selectedViewType === 'cluster_name' ?
                            <ClusterDetails 
                                selectedProvider={state.selectedProvider}
                                selectedAccount={state.selectedAccount}
                                selectedRegion={state.selectedRegion}
                                selectedClusters={state.selectedClusters}
                                selectedDuration={state.selectedDuration}
                                startDate={state.datePickerStartDate}
                                endDate={state.datePickerEndDate}
                                callBack={() => setState(prevState => ({ ...prevState, showLoading: false }))}
                            />
                        : state.selectedViewType === 'project' ?
                            <ProjectDetails 
                                selectedProvider={state.selectedProvider}
                                selectedProject={state.selectedProject}
                                selectedProjectTags={state.selectedProjectTags}
                                selectedAccount={state.selectedAccount}
                                selectedRegion={state.selectedRegion}
                                selectedClusters={state.selectedClusters}
                                selectedDuration={state.selectedDuration}
                                startDate={state.datePickerStartDate}
                                endDate={state.datePickerEndDate}
                                callBack={() => setState(prevState => ({ ...prevState, showLoading: false }))}
                            />
                        : state.selectedViewType === 'queue_name' ?
                            <QueueDetails 
                                selectedProvider={state.selectedProvider}
                                selectedAccount={state.selectedAccount}
                                selectedRegion={state.selectedRegion}
                                selectedClusters={state.selectedClusters}
                                selectedDuration={state.selectedDuration}
                                startDate={state.datePickerStartDate}
                                endDate={state.datePickerEndDate}
                                callBack={() => setState(prevState => ({ ...prevState, showLoading: false }))}
                            />
                        : state.selectedViewType === 'user' ?
                            <UserDetails 
                                selectedProvider={state.selectedProvider}
                                selectedAccount={state.selectedAccount}
                                selectedRegion={state.selectedRegion}
                                selectedClusters={state.selectedClusters}
                                selectedUser={state.selectedUser}
                                selectedDuration={state.selectedDuration}
                                startDate={state.datePickerStartDate}
                                endDate={state.datePickerEndDate}
                                callBack={() => setState(prevState => ({ ...prevState, showLoading: false }))}
                            />
                        : null
                    : null}
                </div>
            </div>
        </div>
    );
};

export default LandingPage