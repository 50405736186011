/*************************************************
 * Tvastar
 * @exports
 * @file QueryEvents.js  //copy of query list from the observability Events QueryDetail page
 * @author Prakash // on 13/09/2023
 * @copyright © 2023 Tvastar. All rights reserved.
 *************************************************/
import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux'; // Import useDispatch hook to dispatch actions
import { UncontrolledTooltip } from 'reactstrap'
import { getDayFromSelectedDuration, momentDateGivenFormat, currentLocaltime, subHours, subDays, momentConvertionLocalToUtcTime, alphaNumeric } from '../../../utils/utility'
import Select from 'react-select'
import MultiSelectSection from '../../common/MultiSelectSection';
import Search from '../../common/SearchComponent'	
import { listAllProviders, listAllAccounts, listAllRegions, setCommonPropsDetails } from '../../../actions/commonActionNew'
import { startQueryExection, setFavouriteQuery, unsetFavouriteQuery } from '../../../actions/QueryAction'
import { getCostQueries, getCostQueryResults } from '../../../actions/cost/CostQueryAction'

import { dynamicCallApi } from '../../../actions/DynamicLoadAction'
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { DateRange } from 'react-date-range';

import "react-datetime/css/react-datetime.css";
import Datetime from "react-datetime";
import _ from 'lodash'

import { Store as CommonNotification } from 'react-notifications-component';

import QueryResults from './QueryResults'
import { useCallback } from 'react';
import {Icon} from "@iconify/react";

const durationOptions = [
	{ value: '+1h', option: 'Last 1 hour' },
    { value: '+6h', option: 'Last 6 hours' },
    { value: '+12h', option: 'Last 12 hours' },
	{ value: '+3h', option: 'Last 3 hours' }, 
	{ value: '+24h', option: 'Last 24 hours' },
	{ value: '+3d', option: 'Last 3 days' },
	{ value: '+7d', option: 'Last 1 week' },
	{ value: '+14d', option: 'Last 2 weeks' },
	{ value: '+30d', option: 'Last 1 month' },
	{ value: 'custom', option: 'Custom Date' },
]

const queryDateTime = current => {
	return current.isBefore(currentLocaltime()) && current.isAfter(subDays(currentLocaltime(), 90))
}

const QueryEvents = () => {
    const clickOutside = useRef()
	const dateRef = useRef();
	const daterRangeListRef = useRef();

    // State variables for filters
    const [state, setState] = useState({
		listLoading: true,

		selectedProvider: "",
		selectedAccount: [],
		selectedRegion: [],
		selectedDuration: "+7d",		

		startRecord: 0, 
		currentPage: 1,
		totalPages: 1,
		overAllTotalPages: 1,
		perPage: 10,

		selectedCategory: "All",
	})
	

    const [dateState, setDateState] = useState([
        {
          startDate: new Date(),
          endDate: null,
          key: 'selection'
        }
    ]);
	// const providers = useSelector(state => state.filters.providers);
	const observabilityQueryList = useSelector(state => state.observability.observabilityQueryList)
	const providers = useSelector(state => state?.filters?.providers || false);
	const propAccounts = useSelector(state => state?.filters?.commonPropsDetails?.propAccounts || false);
    const propRegions = useSelector(state => state?.filters?.commonPropsDetails?.propRegions || false);

    const dispatch = useDispatch(); // Create a dispatch function

	useEffect(() => {
		setTimeout(() => { setState(prevState => ({ ...prevState, queryLoading: false }))}, 3000);
    }, [])
  
    useEffect(() => {
		if(providers) {
            setState(prevState => ({ ...prevState, providers: providers, selectedProvider: providers && providers.length ? (providers.filter(e => e.provider_name === 'AWS').length ? 'AWS' : providers[0].provider_name) : "", callGetQueries: true, getDurationDetails: true }));
        } else {
            let params = {}
            dispatch(listAllProviders(params))
                .then((response) => {
					if(response) {
                        setState(prevState => ({ ...prevState, providers: response, selectedProvider: response && response.length ? (response.filter(e => e.provider_name === 'AWS').length ? 'AWS' : response[0].provider_name) : '', callGetQueries: true, getDurationDetails: true }));
                    }
            })
        }
    }, [dispatch, providers]);

    // Call getFilterData whenever the providers state is updated
    useEffect(() => {
        if (state.selectedProvider) {
            let params = {};
            params.provider = state.selectedProvider.toLowerCase();
            let label = state.selectedProvider

            if(propAccounts && propAccounts.hasOwnProperty(label)) {
                setState(prevState => ({ ...prevState, accounts: propAccounts[label] }));
            } else {
                dispatch(listAllAccounts(params))
                    .then((response) => {
                        if(response) {
                            if(response.length) {
                                let obj = propAccounts ? propAccounts : {}
                                obj[label] = response
                                dispatch(setCommonPropsDetails('propAccounts', obj))
                                setState(prevState => ({ ...prevState, accounts: response }));
                            }
                        }
                    })
            }
        }
    }, [state.selectedProvider, propAccounts, dispatch]);

    useEffect(() => {
        if (state.selectedProvider) {
            let params = {};
            params.provider = state.selectedProvider.toLowerCase();
            let label = state.selectedProvider
            if(propRegions && propRegions.hasOwnProperty(label)) {
                setState(prevState => ({ ...prevState, regions: propRegions[label] }));
            } else {
                dispatch(listAllRegions(params))
                    .then((response) => {
                        if(response && response.length) {
							let results = response[0]?.provider_regions || []
							let obj = propRegions ? propRegions : {}
							obj[label] = results
							dispatch(setCommonPropsDetails('propRegions', obj))
							setState(prevState => ({ ...prevState, regions: results }));
						}
                    })
            }
        }
    }, [state.selectedProvider, propRegions, dispatch]);

	useEffect(() => {
		if(state.listLoading) {
			setTimeout(() => { setState(prevState => ({ ...prevState, listLoading: false }))}, 3000);
		}
	}, [state.listLoading])
	
	useEffect(() => {
		if(state.callGetQueries) {
			let params = {}
			params.aggregate_by = ["category", "sub_category"]
			params.provider = state.selectedProvider.toLowerCase()
			if(state.selectedAccount && state.selectedAccount.length) {
				params.account_id = state.selectedAccount.filter(e => e !== "All")
			}
			
			if(state.selectedRegion && state.selectedRegion.length) {
				params.region = state.selectedRegion.filter(e => e !== "All")
			}
			// if(observabilityQueryList && observabilityQueryList.length) {
			// 	setState(prevState => ({ ...prevState, queryListJson: observabilityQueryList, listLoading: false, formQueryList: true, callGetQueries: false }))
			// } else {
			dispatch(getCostQueries(params))
				.then((response) => {
					if(response) {
						setState(prevState => ({ ...prevState, queryListJson: response, listLoading: false, formQueryList: true, callGetQueries: false }))
					} else {
						setState(prevState => ({ ...prevState, queryListJson: [], listLoading: false, callGetQueries: false }))
					}
				})
			// }
		}
	}, [state.callGetQueries, dispatch, observabilityQueryList, state.selectedAccount, state.selectedProvider, state.selectedRegion])

	useEffect(() => {
		if(state.formQueryList && state.queryListJson && state.queryListJson.length) {
			let catArray = []
			let category = []
			state.queryListJson.forEach((item, index) => {
				item.category.forEach(cat => {
					let catRow = {}
					catRow.category = cat
					if(!category.includes(cat)) {
						category.push(cat)
					}
					if(item.sub_category) {
						catRow.sub_category = item.sub_category
					}
					catArray.push(catRow)
				})
			})
	
			let categories = []
			let totalCatCount = 0
			_.chain(catArray)
				.groupBy('category')
				.map((value, key) => ({ label: key, sub: value })).value()
				.forEach((category, i) => {
					let totalCount = 0
					let dataRow = {}
					dataRow.category = category.label
					let sub_category = []
					_.chain(category.sub)
					.groupBy('sub_category')
					.map((value, key) => ({ slabel: key, sSub: value })).value()
					.forEach((subCat, i) => {
						if(category.sub.filter(e => e.sub_category).length) {
							let subCatRow = {}
							subCatRow.sub_category = subCat.slabel
							subCatRow.count = subCat.sSub.length
							sub_category.push(subCatRow)
						}
						totalCatCount += subCat.sSub.length
						totalCount += subCat.sSub.length
					})
					dataRow.count = totalCount
					if(sub_category && sub_category.length) {
						dataRow.sub_category = sub_category
					}
					categories.push(dataRow)
				})
				
			categories = _.orderBy(categories, ['count'], ['desc'])

			
			let dataRow = {}
			dataRow.category = "All"
			dataRow.count = totalCatCount
			dataRow.sub_category = ""
			categories.unshift(dataRow)
	
			setState(prevState => ({ ...prevState, totalQueryList: state.queryListJson, queryList: state.queryListJson, categories, getAllQueries: true }))
		}
	}, [state.formQueryList, state.queryListJson])

	useEffect(() => {
		if(state.filterQueryList) {
			let data = state.totalQueryList
			if(state.selectedSubCategory && state.selectedSubCategory !== "") {
				data = data.filter(e => e.category.includes(state.selectedCategory) && e.sub_category === state.selectedSubCategory)
			} else if(state.selectedCategory && state.selectedCategory !== "" && state.selectedCategory !== "All") {
				data = data.filter(e => e.category.includes(state.selectedCategory))
			}
	
			let totalPages = 1
			if(data.length > state.perPage) {
				totalPages = Math.ceil(data.length / state.perPage)
			}
	
			setState(prevState => ({ ...prevState, filteredArray: data, totalPages, startRecord: 0, currentPage: 1, filterQueryList: false }))
		}
	}, [state.filterQueryList, state.perPage, state.selectedCategory, state.selectedSubCategory, state.totalQueryList])

	useEffect(() => {
		if(state.getAllQueries) {
			let data = state.totalQueryList	
			let totalPages = 1
			if(data.length > state.perPage) {
				totalPages = Math.ceil(data.length / state.perPage)
			}
	
			setState(prevState => ({ ...prevState, filteredArray: data, totalPages, startRecord: 0, currentPage: 1, getAllQueries: false }))
		}
	}, [state.getAllQueries, state.perPage, state.totalQueryList])
	
	const handleMultiSelectChange = (field, arrayValue, stateOptions) => {
		let value = arrayValue.map(item => item.value ? item.value : item)
		let selectedValue = []
		if(field === 'selectedAccount' || field === "selectedQueryAccount") {
			let prevState = state[field] ? state[field] : []
			if(value.includes('All')) {
				if(!prevState.includes('All')) {
					state.accounts.forEach(acc => {
						selectedValue.push(acc.account_id)
					})
				} else {
					const index = value.indexOf('All');
					if (index > -1) {
						value.splice(index, 1);
					}
					selectedValue = value
				}
			} else if(!prevState.includes('All')) {
				selectedValue = value
			}

			if(selectedValue.length && !selectedValue.includes('All')) {
				if(!selectedValue.includes('All') && selectedValue.length === (state.accounts.length -1)) {
					selectedValue.push('All')
				}
			}	
			setState(prevState => ({ ...prevState, [field]: selectedValue }))
		} else if(field === 'selectedRegion' || field === "selectedQueryRegion") {
			let prevState = state[field] ? state[field] : []
			if(value.includes('All')) {
				if(!prevState.includes('All')) {
					state.regions.forEach(reg => {
						selectedValue.push(reg.region)
					})
				} else {
					const index = value.indexOf('All');
					if (index > -1) {
						value.splice(index, 1);
					}
					selectedValue = value
				}
			} else if(!prevState.includes('All')) {
				selectedValue = value
			}

			if(selectedValue.length && !selectedValue.includes('All')) {
				if(!selectedValue.includes('All') && selectedValue.length === (state.regions.length -1)) {
					selectedValue.push('All')
				}
			}
			setState(prevState => ({ ...prevState, [field]: selectedValue }))
		} else {
			//for array			
			if(stateOptions) {
				let prevState = state[field] ? state[field] : []
                if(prevState.length === 1 && prevState[0] === "All") {
                    prevState = []
                }
				if(value.includes('All')) {
					if(!prevState.includes('All')) {
						if(stateOptions.filter(e => e.value).length) {
							selectedValue = stateOptions.map(e => e.value)
						} else {
							selectedValue = stateOptions
						}
					} else {
						const index = value.indexOf('All');
						if (index > -1) {
							value.splice(index, 1);
						}
						selectedValue = value
					}
				} else if(!prevState.includes('All')) {
					selectedValue = value
				}
	
				if(selectedValue.length && !selectedValue.includes('All')) {
					if(!selectedValue.includes('All') && selectedValue.length === (stateOptions.length -1)) {
						selectedValue.push('All')
					}
				}
			} else {
				selectedValue = value
			}
			setState(prevState => ({ ...prevState, [field]: selectedValue }))
		}
	}

	useEffect(() => {
        if(state.selectedDuration !== 'custom') {
			let duration = state.selectedDuration

            let period = duration.substring(duration.length, duration.length - 1);
            let durationBtw = getDayFromSelectedDuration(duration);
            let datePickerStartDate = ''
			
            if (period === 'd') {
                datePickerStartDate = momentConvertionLocalToUtcTime(subDays(new Date(), durationBtw), 'YYYY-MM-DD HH:mm:ss');
            } else {
                datePickerStartDate = momentConvertionLocalToUtcTime(subHours(new Date(), durationBtw), 'YYYY-MM-DD HH:mm:ss');
            }
            let datePickerEndDate = momentConvertionLocalToUtcTime(new Date(), 'YYYY-MM-DD HH:mm:ss');

            setState(prevState => ({ ...prevState, datePickerStartDate, datePickerEndDate }));

            let dateSelection= {
				startDate: new Date(datePickerStartDate),
				endDate: new Date(datePickerEndDate),
				key: 'selection'
			}

			setDateState([dateSelection])
        }

	}, [state.selectedDuration])

	const handleChildClick = (event, type, dropdownType, section) => {	
		if (clickOutside.current && !clickOutside.current.contains(event.target)) {
            dispatch(setCommonPropsDetails('clickedonParent', true))
		}
		if (dateRef.current && !dateRef.current.contains(event.target)) {
			setState(prevState => ({ ...prevState, showDateRangePicker: false }));
		}
		if (daterRangeListRef.current && !daterRangeListRef.current.contains(event.target)) {
			setState(prevState => ({ ...prevState, showDateRangePicker: false }));
		}
		event.stopPropagation();

		let clickedChild = []
		if(type === 'child') {
            if(state.clickedChild && state.clickedChild.length) {
                state.clickedChild.forEach(row => {
                    setState(prevState => ({ ...prevState, [row]: false }))
                })
            }
            clickedChild.push(section)
            setState(prevState => ({ ...prevState, [section]: dropdownType === "singleDropDown" && state[section] ? false : true, clickedChild }))
        } else if(type === 'parent') {
            if(state.clickedChild && state.clickedChild.length) {
                state.clickedChild.forEach(row => {
                    setState(prevState => ({ ...prevState, [row]: false }))
                })
            }
            
            setState(prevState => ({ ...prevState, clickedChild }))
        }
	}

	const handleSelect = (date) => {
		let datePickerStartDate = momentDateGivenFormat(date.selection.startDate, 'YYYY-MM-DD 00:00:00');
        let datePickerEndDate = ""
        if(date.selection.startDate !== date.selection.endDate) {
            datePickerEndDate = momentDateGivenFormat(date.selection.endDate, 'YYYY-MM-DD 23:59:59');
        }
        
        if(datePickerStartDate && datePickerEndDate) {
            setState(prevState => ({
                ...prevState,
                datePickerStartDate,
                datePickerEndDate,
				startTime: datePickerStartDate, 
				endTime: datePickerEndDate,
                showDateRangePicker: false,
				isDurationOpen: false
                // selectedGraphDuration: "custom",
                // metricDetails: [], 
                // showMetricLoading: true,
                // callMetricAPi: true
            }));
    
        }

        setDateState([date.selection])

	}

    // const handleSelectNew = (date) => {
	// 	// let startTime = momentDateGivenFormat(date[0], "YYYY-MM-DD 00:00:00")
	// 	// let endTime = momentDateGivenFormat(date[1], "YYYY-MM-DD 23:59:59")
	// 	// let startTime = date[0]
	// 	// let endTime = date[1]
	// 	let startTime = momentDateGivenFormat(date[0], "YYYY-MM-DD HH:mm:00")
	// 	let endTime = momentDateGivenFormat(date[1], "YYYY-MM-DD HH:mm:00")
	// 	setState(prevState => ({ ...prevState, datePickerStartDate: startTime, datePickerEndDate: endTime, startTime, endTime, showDateRangePicker: false }))
	// }

	useEffect(() => {
		if(state.getDurationDetails) {
			let duration = state.selectedDuration
			let period = duration.substring(duration.length, duration.length-1)
			let durationBtw = getDayFromSelectedDuration(duration)
			let startTime = ""
			if(period === "d") {
				startTime = momentDateGivenFormat(subDays(new Date(), durationBtw), 'YYYY-MM-DD HH:mm:ss')
			} else {
				startTime = momentDateGivenFormat(subHours(new Date(), durationBtw), 'YYYY-MM-DD HH:mm:ss')
			}
			
			let endTime = momentDateGivenFormat(new Date(), 'YYYY-MM-DD HH:mm:ss')

			let selectionRange = [{
				startDate: new Date(startTime),
				endDate: new Date(endTime),
				key: 'selection',
			}]

			setState(prevState => ({ ...prevState, startTime, endTime, selectionRange, queryStartTime: startTime, queryEndTime: endTime, getDurationDetails: false }))
		}
	}, [state.getDurationDetails, state.selectedDuration])

    // Function to navigate between pages
    const navigatePage = (action, currentPage) => {
        // Update startRecord and currentPage based on action
        let startRecord = state.startRecord
        if(action === 'next' && currentPage !== state.totalPages) {
            startRecord = startRecord + state.perPage;
            setState(prevState => ({ ...prevState, currentPage: currentPage+ 1 }));
        } else if(action === 'previous' && currentPage !== 0) {
            setState(prevState => ({ ...prevState, currentPage: currentPage - 1 }));
            startRecord = startRecord - state.perPage;
        } else if(action === 'start' && currentPage !== 0) {
            setState(prevState => ({ ...prevState, currentPage: 1 }));
            startRecord = 0;
        } else if(action === 'end' && currentPage !== 0) {
            setState(prevState => ({ ...prevState, currentPage: state.totalPages }));
            startRecord = (state.totalPages - 1) * state.perPage;
        }
        setState(prevState => ({ ...prevState, startRecord }))
    }

    // const gotoPage = (pageNumber) => {
    //     // Go to the specified page and update state        
    //     if(pageNumber > 0  && pageNumber <= state.totalPages) {
    //         let startRecord = (pageNumber - 1) * state.perPage
    //         setState(prevState => ({ ...prevState, currentPage: pageNumber, pageNumber, startRecord }))
    //     } else  if(pageNumber > state.totalPages) {
    //         setState(prevState => ({ ...prevState, pageNumber: state.currentPage }))
    //     } else {
    //         setState(prevState => ({ ...prevState, pageNumber }))
    //     }
    // }

	const querySelection = (i, item) => {
		if(state.selectedQuery !== item) {
			if(item.display_output && Object.entries(item.display_output).length) {
				Object.values(item.display_output).forEach(out => {
					if(out.default) {
						setState(prevState => ({ ...prevState, [item.query_id+"_"+out.output_field]: out.default }))
					}
					if(out.api_details && Object.entries(out.api_details).length) {
						let apiDetails = out.api_details
						let params = apiDetails.api_request
						if(apiDetails.additional_fields && apiDetails.additional_fields) {
							apiDetails.additional_fields.forEach(addFields => {
								if(addFields === "account_id" && state.selectedAccount && state.selectedAccount.length && !state.selectedAccount.includes("All")) {
									params.account_id = state.selectedAccount
								}
								if(addFields === "regions" && state.selectedRegion && state.selectedRegion.length && !state.selectedRegion.includes("All")) {
									params.region = state.selectedRegion
								}
							})
						}
						let manditoryFieldsFilled = true
						if(apiDetails.mandatory_fields && apiDetails.mandatory_fields.length) {
							apiDetails.mandatory_fields.forEach(manFields => {
								if(!state[item.query_id+"_"+manFields] || state[item.query_id+"_"+manFields] === "" || (Array.isArray(state[item.query_id+"_"+manFields]) && !state[item.query_id+"_"+manFields].length)) {
									manditoryFieldsFilled = false
								} else {
									params[manFields] = state[item.query_id+"_"+manFields]
								}
							})
						}
						if(manditoryFieldsFilled) {
							dynamicCallApiFunction(params, item, apiDetails, out.output_field, out.output_type)
						}
					} else if(out.type === "dropdown" && out.output_type === "list") {
						let options = ["All"]
						options =  options.concat(out.values)

						setState(prevState => ({ ...prevState, [item.query_id+"_drop_options_"+out.output_field]: options }))

						handleMultiSelectChange(item.query_id+"_"+out.output_field, options)
					}
				})
			}

			let selectedQueryIds = []
			let required_fields = item.required_fields ? item.required_fields : []
			required_fields.forEach(row => {
				selectedQueryIds.push(item.query_id+'_'+row)
			})

			setState(prevState => ({ 
				...prevState, 
				queryErrorMessage: "",
				selectedQuery: item,
				// selectedQueryAccount: state.selectedAccount,
				// selectedQueryRegion: state.selectedRegion,
				selectedQueryDuration: state.selectedDuration,
				queryStartTime: state.startTime,
				queryEndTime: state.endTime,
				showQueryDateTimePicker: false,
				hasError: false,
				formQueryBuilder: true,
				selectedQueryIds
			}))
		} else {
			setState(prevState => ({ ...prevState, selectedQuery: "", hasError: false }))
		}
	}

	// useEffect(() => {
	// 	const handleClickOutside = (event) => {
	// 	  // Check if the clicked element is one of the div elements or their descendants
	// 	  if (
	// 		divRefs.some((ref) => ref.current && ref.current.contains(event.target))
	// 	  ) {
	// 		// Clicked inside one of the div elements
	// 		return;
	// 	  }
	
	// 	  // Clicked outside of the div elements
	// 	  console.log('Clicked outside of the div elements');
	// 	};
	
	// 	// Add a click event listener to the document
	// 	document.addEventListener('click', handleClickOutside);
	
	// 	// Remove the event listener when the component unmounts
	// 	return() => {
	// 	  document.removeEventListener('click', handleClickOutside);
	// 	};
	//   }, [divRefs]);

	useEffect(() => {
		if(state.formQueryBuilder) {
			let querybuilder = []
			let selectedQuery = state.selectedQuery
			let query = selectedQuery.display_query
			if(query) {
				let splitString = query.split("$")
				let re = /{(.*?)}/;
				splitString.forEach(str => {
					let result = str.split(re);
					if(result.length === 1) {
						let dataRow = {}
						dataRow.input = false
						dataRow.string = result[0]
						querybuilder.push(dataRow)
					} else {
						result.forEach((item, index) => {
							if(item !== "") {
								if(index === 1) {
									let dataRow = {}
									dataRow.input = true
									dataRow.field = item
									dataRow.string = result[0]
									querybuilder.push(dataRow)
								} else {
									let dataRow = {}
									dataRow.input = false
									dataRow.string = item
									querybuilder.push(dataRow)
								}
							}
						})
					}
				})
				setState(prevState => ({ ...prevState, querybuilder }))
			}
		}
	}, [state.formQueryBuilder, state.selectedQuery])

	useEffect(() => {
		if(state.getQueryDurationDetails) {

			let duration = state.selectedQueryDuration
			let period = duration.substring(duration.length, duration.length-1)
			let durationBtw = getDayFromSelectedDuration(duration)
			let startTime = ""
			if(period === "d") {
				startTime = momentDateGivenFormat(subDays(new Date(), durationBtw), 'YYYY-MM-DD HH:mm:ss')
			} else {
				startTime = momentDateGivenFormat(subHours(new Date(), durationBtw), 'YYYY-MM-DD HH:mm:ss')
			}
			
			let endTime = momentDateGivenFormat(new Date(), 'YYYY-MM-DD HH:mm:ss')
		
			setState(prevState => ({ ...prevState, queryStartTime: startTime, queryEndTime: endTime, getQueryDurationDetails: false }))
		}
	}, [state.getQueryDurationDetails, state.selectedQueryDuration])
	
	const handleStartTimeChange = (date) => {
		let queryStartTime = momentDateGivenFormat(date, 'YYYY-MM-DD HH:mm:ss')
		let queryEndTime = state.queryEndTime
		if(new Date(queryStartTime).getTime() > new Date(queryEndTime).getTime() ) {
			queryEndTime = momentDateGivenFormat(new Date(), 'YYYY-MM-DD HH:mm:ss')
		}
		setState(prevState => ({ ...prevState, queryStartTime, queryEndTime}))
	}

	const handleEndTimeChange = (date) => {
		let queryStartTime = state.queryStartTime
		let queryEndTime = momentDateGivenFormat(date, 'YYYY-MM-DD HH:mm:ss')
		if(new Date(queryEndTime).getTime() > new Date(queryStartTime).getTime() ) {
			setState(prevState => ({ ...prevState, queryEndTime}))
		}
	}

	const getQueryResultFunction = useCallback((params) => {
		dispatch(getCostQueryResults(params))
			.then((response) => {
				if(response) {
					if(response.status === "RUNNING") {
						setTimeout(() => { getQueryResultFunction(response) }, 3000)
					} else if(response.status === "FAILED") {
						setState(prevState => ({ ...prevState, queryErrorMessage: response, queryLoading: false, executeQuery: false }))
					} else {
						setTimeout(() => {
							let results = response.results && response.results.length ? response.results : []
							if(results.length) {
								setState(prevState => ({ ...prevState, queryResult: response, queryLoading: false, showQueryResult: true, executeQuery: false }))
							} else {
								let dataRow = {
									message: "No records to show for the selected criteria"
								}
								setState(prevState => ({ ...prevState, queryErrorMessage: dataRow, queryLoading: false, executeQuery: false }))
							}
						}, 2000)
					}
				} else {
					setState(prevState => ({ ...prevState, queryErrorMessage: response, queryLoading: false }))
				}
			})
	}, [dispatch])

	const startQueryExectionFunction = useCallback((params) => {
		dispatch(startQueryExection(params))
			.then((response) => {
				if(response) {
					if(response.status === "FAILED") {
						setState(prevState => ({ ...prevState, queryErrorMessage: response, queryLoading: false, executeQuery: false }))
					} else {
						getQueryResultFunction(response)
					}
				} else {
					setState(prevState => ({ ...prevState, queryErrorMessage: response, queryLoading: false, executeQuery: false }))
				}
			})
	}, [dispatch, getQueryResultFunction])

	useEffect(() => {
		if(state.executeQuery) {
			let selectedQuery  = state.selectedQuery
			let hasError = false
	
			let params = {}
			params.query_id = selectedQuery.query_id
			params.selectedCategory = state.selectedCategory && state.selectedCategory !== "All" ? state.selectedCategory : selectedQuery.category
			
			if(selectedQuery.required_fields && selectedQuery.required_fields.length) {
				selectedQuery.required_fields.forEach(item => {
					if(item === "account_id" && state[state.selectedQuery.query_id+"_selectedQueryAccount"] && state[state.selectedQuery.query_id+"_selectedQueryAccount"].length && !state[state.selectedQuery.query_id+"_selectedQueryAccount"].includes("All")) {
						// params.account_id = state[state.selectedQuery.query_id+"_selectedQueryAccount"].filter(e => e !== "All")
						params.account_id = state[state.selectedQuery.query_id+"_selectedQueryAccount"]
					} else if(item === "account_id")  {
						params.account_id = "ALL"
					}
					if(item === "region" && state[state.selectedQuery.query_id+"_selectedQueryRegion"] && state[state.selectedQuery.query_id+"_selectedQueryRegion"].length && !state[state.selectedQuery.query_id+"_selectedQueryRegion"].includes("All")) {
						params.region = state[state.selectedQuery.query_id+"_selectedQueryRegion"]
					} else if(item === "region")  {
						params.region = "ALL"
					}
					if(item === "duration") {
						params.start_time = momentConvertionLocalToUtcTime(state.queryStartTime, "YYYY-MM-DD HH:mm:00")
						params.end_time = momentConvertionLocalToUtcTime(state.queryEndTime, "YYYY-MM-DD HH:mm:00")
					}
				})
			}
	
			if(selectedQuery.display_output && Object.entries(selectedQuery.display_output).length) {
				Object.entries(selectedQuery.display_output).forEach(([key, value]) => {
					if(value.type === "text") {
						if(value.output_type === "string") {
							if(!state[state.selectedQuery.query_id+"_"+value.output_field] || state[state.selectedQuery.query_id+"_"+value.output_field] === "") {
								hasError = true
							} else if(value.validation && Object.entries(value.validation).length) {
								let validation = value.validation
								if(validation.min && state[state.selectedQuery.query_id+"_"+value.output_field].length < validation.min) {
									hasError = true
									setState(prevState => ({ ...prevState, ['error_message_'+state.selectedQuery.query_id+"_"+value.output_field]: 'field should be greater than '+ validation.min +' and lesser than '+ validation.max }))
								} else if(validation.max && state[state.selectedQuery.query_id+"_"+value.output_field].length > validation.max) {
									hasError = true
									setState(prevState => ({ ...prevState, ['error_message_'+state.selectedQuery.query_id+"_"+value.output_field]: 'field should be greater than '+ validation.min +' and lesser than '+ validation.max }))
								} else {
									setState(prevState => ({ ...prevState, ['error_message_'+state.selectedQuery.query_id+"_"+value.output_field]: "" }))
									params[value.output_field] = state[state.selectedQuery.query_id+"_"+value.output_field]
								}
							} else {
								params[value.output_field] = state[state.selectedQuery.query_id+"_"+value.output_field]
							}
						} else if(value.output_type === "list") {
							if(!state[state.selectedQuery.query_id+"_"+value.output_field] || !state[state.selectedQuery.query_id+"_"+value.output_field].length) {
								hasError = true
							} else {
								if(value.api_details && value.include_all) {
									if(state[state.selectedQuery.query_id+"_"+value.output_field] && state[state.selectedQuery.query_id+"_"+value.output_field].includes("All")) {
										params[value.output_field] = ["All"]
									} else {
										params[value.output_field] = state[state.selectedQuery.query_id+"_"+value.output_field]
									}
								} else {
									if(state[state.selectedQuery.query_id+"_"+value.output_field].length > 1 && state[state.selectedQuery.query_id+"_"+value.output_field].includes("All")) {
										let data = state[state.selectedQuery.query_id+"_"+value.output_field]
										params[value.output_field] = data.filter(e => e !== 'All')
									} else {
										params[value.output_field] = state[state.selectedQuery.query_id+"_"+value.output_field]
									}
								}
							}
						}
					} else {
						let output = state[state.selectedQuery.query_id+"_"+value.output_field]
						// if(value.output_type === "list") {
						// 	output = state[value.output_field]
						// }
						if(value.output_type === "list") {
							if(!output || !output.length ) {
								hasError = true
							}
						} else {
							if(!value || value === "") {
								hasError = true
							}
						}
						if(output.includes("All") && value.output_type === "list") {
							if(value.include_all) {
								output = ["All"]
							} else {
								output = output.filter(e => e !== 'All')
							}
						}
						params[value.output_field] = output
					}
				})
			}
	
			setState(prevState => ({ ...prevState, hasError, executeQuery: hasError ? false : true }))
	
			if(!hasError) {
	
				let queryProps = {
					account_id: state[state.selectedQuery.query_id+"_selectedQueryAccount"],
					region: state[state.selectedQuery.query_id+"_selectedQueryRegion"],
					duration: state.selectedQueryDuration,
					params: params,
					start_time: state.queryStartTime,
					end_time: state.queryEndTime,
					selectedQuery: state.selectedQuery,
					querybuilder: state.querybuilder
				}
	
				if(selectedQuery.async) {
					setState(prevState => ({ ...prevState, queryLoading: true, queryProps, selectedInput: params, executeQuery: false }))
					startQueryExectionFunction(params)
				} else {
					setState(prevState => ({ ...prevState, queryLoading: true, queryProps, executeQuery: false }))
					getQueryResultFunction(params)
				}
			}
		}
	}, [state.executeQuery, state, startQueryExectionFunction, getQueryResultFunction])

	const onKeyDown = (e, field, stateField) => {
		let array = state[stateField] && Array.isArray(state[stateField]) ? state[stateField] : []
		//|| e.keyCode === 188
		if (e.keyCode === 13 || e.keyCode === 9) {
			if(state[field] && state[field] !== "") {
				array.push(state[field].trim())
				if(e.keyCode === 9) {
					e.preventDefault();
				}
				setState(prevState => ({ ...prevState, [stateField]: array, [field]: "" }))
			}
		}
	}

	const onBlur = (e, field, stateField) => {
		let array = state[stateField] ? state[stateField] : []
		if(state[field] && state[field] !== "") {
			array.push(state[field].trim())
			setState(prevState => ({ ...prevState, [stateField]: array, [field]: "" }))
		}
	}

	const removeSelectedBadge = (field, value, index) => {
		let filteredReslt = state[field]
		filteredReslt.splice(index, 1);
		setState(prevState => ({ ...prevState, [field]: filteredReslt }))
	}

	const setFavouriteQueryFunction = (index, queryId, type) => {
		let params = {
			query_id: queryId
		}

		if(type) {
			dispatch(setFavouriteQuery(params))
				.then((response) => {
					if(response) {
						updateQueryList()
						
						CommonNotification.addNotification({
							message: "Query "+queryId+" added to favourite",
							type: "success",
							insert: "top",
							container: "top-center",
							dismiss: {
								duration: 3000,
								onScreen: false,
								pauseOnHover: true,
								showIcon: true,
							}
						})
					}
				})
		} else {
			dispatch(unsetFavouriteQuery(params))
				.then((response) => {
					if(response) {
						updateQueryList()
						CommonNotification.addNotification({
							message: "Query "+queryId+" removed from favourite",
							type: "success",
							insert: "top",
							container: "top-center",
							dismiss: {
								duration: 3000,
								onScreen: false,
								pauseOnHover: true,
								showIcon: true,
							}
						})
					}
				})
		}
	}

	const updateQueryList = () => {
		let params = {}
		dispatch(getCostQueries(params))
			.then((response) => {
				if(response) {
					setState(prevState => ({ ...prevState, totalQueryList: response }),
						() => {
							let data = state.totalQueryList
							if(state.selectedSubCategory && state.selectedSubCategory !== "") {
								data = data.filter(e => e.category.includes(state.selectedCategory) && e.sub_category === state.selectedSubCategory)
							} else if(state.selectedCategory && state.selectedCategory !== "" && state.selectedCategory !== "All") {
								data = data.filter(e => e.category.includes(state.selectedCategory))
							}
	
							setState(prevState => ({ ...prevState, filteredArray: data }))
						}
					)
				}
			})
	}

	const onChangeTextString = (outputStructure, field, value) => {
		let hasError = false
		let validation = outputStructure.validation
		if(validation && Object.entries(validation).length) {
			if(validation.min && value.length < validation.min) {
				hasError = true
				setState(prevState => ({ ...prevState, ['error_message_'+field]: 'field should be greater than '+ validation.min +' and lesser than '+ validation.max }))
			} else if(validation.max && value.length > validation.max) {
				hasError = true
				setState(prevState => ({ ...prevState, ['error_message_'+field]: 'field should be greater than '+ validation.min +' and lesser than '+ validation.max }))
			} else {
				setState(prevState => ({ ...prevState, ['error_message_'+field]: "" }))
			}
			
			if(validation.data_type) {
				if(validation.data_type === "alphanumeric") {
					value = alphaNumeric(value)
				}
			}
		}
		
		setState(prevState => ({ ...prevState, [field]: value, hasError }))
	}

	const onChangeTextDropDown = (displayOutput, changedKey) => {
		let selectedQuery = state.selectedQuery
		let keysToReload = displayOutput[changedKey].api_details.keys_to_reload
		if(keysToReload && keysToReload.length) {
			keysToReload.forEach(item => {
				let out = displayOutput[item]
				// if(out.default) {
				// 	setState(prevState => ({ ...prevState, [selectedQuery.query_id+"_"+out.output_field]: selectedQuery.default }))
				// }
				if(out.api_details && Object.entries(out.api_details).length) {
					let apiDetails = out.api_details
					let params = apiDetails.api_request
					if(apiDetails.additional_fields && apiDetails.additional_fields) {
						apiDetails.additional_fields.forEach(addFields => {
							if(addFields === "account_id" && state.selectedAccount && state.selectedAccount.length && !state.selectedAccount.includes("All")) {
								params.account_id = state.selectedAccount
							}
							if(addFields === "regions" && state.selectedRegion && state.selectedRegion.length && !state.selectedRegion.includes("All")) {
								params.region = state.selectedRegion
							}
						})
					}
					let manditoryFieldsFilled = true
					if(apiDetails.mandatory_fields && apiDetails.mandatory_fields.length) {
						apiDetails.mandatory_fields.forEach(manFields => {
							if(!state[selectedQuery.query_id+"_"+manFields] || state[selectedQuery.query_id+"_"+manFields] === "" || (Array.isArray(state[selectedQuery.query_id+"_"+manFields]) && !state[selectedQuery.query_id+"_"+manFields].length)) {
								manditoryFieldsFilled = false
							} else {
								params[manFields] = state[selectedQuery.query_id+"_"+manFields]
							}
						})
					}
					
					if(manditoryFieldsFilled) {
						setState(prevState => ({ ...prevState, [state.selectedQuery.query_id+"_"+out.output_field]: out.output_type === "text" ? "" : [] }),
							() => dynamicCallApiFunction(params, selectedQuery, apiDetails, out.output_field, out.output_type)						
						)
					}
				}
			})
		}
	}

	const dynamicCallApiFunction = (params, selectedQuery, apiDetails, outputField, outputType) => {
		dispatch(dynamicCallApi(apiDetails.api_name, apiDetails.api_resource, params))
			.then((response) => {
				if(response) {
					let options = []
					if(outputType === "list") {
						let dataRow = {}
						dataRow.key = "All"
						dataRow.value = "All"
						options.push(dataRow)
					}
					if(response.results) {
						let results = response.results
						results.forEach(res => {
							if(apiDetails.field_name === "id_name_map") {
								let dataRow = {}
								dataRow.key = res[apiDetails.field_name]
								dataRow.value = res[apiDetails.field_name]
								// options.push(res[apiDetails.field_name])
								options.push(dataRow)
							} else if(res[apiDetails.field_name]){
								let dataRow = {}
								dataRow.key = res[apiDetails.field_name]
								dataRow.value = res[apiDetails.field_name]
								options.push(dataRow)
							}
						})
					}
					
					setState(prevState => ({ ...prevState, [selectedQuery.query_id+"_options_"+outputField]: options }))
					if(outputType === "list") {
						handleMultiSelectChange(outputField, options)
					}
				}
			})
	}
	return (
		<div onClick={(event) => handleChildClick(event, 'parent')}>
            <div className={`${state.showLoading ? '' : 'hidden'}`}>
                <div className='loading-wave'>
                    <div className='overlayEqualizerLoader'>
                        <div className="loading-bar"></div>
                        <div className="loading-bar"></div>
                        <div className="loading-bar"></div>
                        <div className="loading-bar"></div>
                    </div>
                </div>
            </div>
			
			<div className="bg-nero relative stickyUiChange">
                <div className="grid grid-cols-12 items-center pb-4">
                    <div className="xl:col-span-4 col-span-12 self-center xl:mt-4">
                        <p className="text-white mx-4 text-xl md:mt-0 mt-4 font-semibold">Queries</p>
                        <p className="mx-4 text-white">Cost queries on your Cloud</p>
                    </div>
					{!state.showQueryResult ?
						<div className="xl:col-span-8 col-span-12 items-center lg:mt-4 mt-6 lg:ml-4">
							<div className='flex mx-0 justify-end'>
								<div className={`bg-black5 p-2 text-white flex flex-col justify-between rounded-md cursor-pointer mr-3 w-[150px]`}
									onClick={(event) => {
										if(!state.isProviderOpen) {
											event.preventDefault();
											handleChildClick(event, 'child', 'singleDropDown', "isProviderOpen")
										}
									}}
								>
									<label className='mb-1 mt-1 flex'>Provider</label>
									<p className={`flex justify-between mt-2 mb-0 truncate ${state.selectedProvider ? 'text-white' : 'placeholder'}`}>
										{state.selectedProvider ? state.selectedProvider : 'Select'}
										<Icon icon="icon-park-solid:down-one" className={`${state.selectedProvider ? "text-white" : "text-lightGray"}`} width="16" height="16" />
									</p>
									{state.isProviderOpen ?
										<div className='relative'>
											<MultiSelectSection 
												fields={["provider_name", "provider_name"]}
												options={state.providers}
												selectedValues={state.selectedProvider ? state.selectedProvider : ''}
												callbackMultiSelect={(value) => {
													if(!value || typeof(value) === 'string') {
														setState(prevState => ({ ...prevState, selectedProvider: value, selectedAccount: [], selectedRegion: [], queryListJson: [], callGetQueries: true }))
													} else {
														value.preventDefault()
														handleChildClick(value, "search", 'singleDropDown', "")
													}
												}}
												singleSelection={true}
												widthClass={'minWidth220'}
												removeTopOptions={true}
											/>
										</div>
									: null}
								</div>
								<div className={`bg-black5 p-2 text-white flex flex-col justify-between rounded-md cursor-pointer mr-3 w-[150px]`}
									onClick={(event) => {
										event.preventDefault();
										handleChildClick(event, 'child', 'muliselectDropDown', "isAccountOpen")
									}}
								>
									<label className='mb-2 mt-1 flex'>Account</label>
									<p className={`flex justify-between mt-2 mb-0 truncate ${state.selectedAccount && state.selectedAccount.length ? 'text-white' : 'placeholder'}`}>
										{state.selectedAccount && state.selectedAccount.length ? state.selectedAccount.length+' Selected' : 'Select'}
										<Icon icon="icon-park-solid:down-one" className={`${state.selectedAccount && state.selectedAccount.length ? "text-white" : "text-lightGray"}`} width="16" height="16" />
									</p>
									{state.isAccountOpen ?
										<div className='relative'>
											<MultiSelectSection 
												fields={["account_id", "account_name"]}
												options={state.accounts}
												selectedValues={state.selectedAccount ? state.selectedAccount : []}
												callbackMultiSelect={(value) => {
													setState(prevState => ({ ...prevState, selectedAccount: value }))
												}}
												widthClass={'minWidth220'}
												removeTopOptions={true}
											/>
										</div>
									: null}
								</div>
								<div className={`bg-black5 p-2 text-white flex flex-col justify-between rounded-md cursor-pointer mr-3 w-[150px]`}
									onClick={(event) => {
										event.preventDefault();
										handleChildClick(event, 'child', 'muliselectDropDown', "isRegionOpen")
									}}
								>
									<label className='mb-2 mt-1 flex'>Region</label>
									<p className={`flex justify-between mt-2 mb-0 truncate ${state.selectedRegion && state.selectedRegion.length ? 'text-white' : 'placeholder'}`}>
										{state.selectedRegion && state.selectedRegion.length ? state.selectedRegion.length+' Selected' : 'Select'}
										<Icon icon="icon-park-solid:down-one" className={`${state.selectedRegion && state.selectedRegion.length ? "text-white" : "text-lightGray"}`} width="16" height="16" />
									</p>
									{state.isRegionOpen ?
										<div className='relative'>
											<MultiSelectSection 
												fields={["region", "name"]}
												options={state.regions}
												selectedValues={state.selectedRegion ? state.selectedRegion : []}
												callbackMultiSelect={(value) => setState(prevState => ({ ...prevState, selectedRegion: value }))}
												widthClass={'minWidth220'}
												removeTopOptions={true}
											/>
										</div>
									: null}
								</div>
								<div className={`bg-black5 p-2 text-white flex flex-col justify-between rounded-md cursor-pointer mr-3 w-[220px]`}
									onClick={(event) => {
										if(!state.isDurationOpen) {
											event.preventDefault();
											handleChildClick(event, 'child', 'singleDropDown', "isDurationOpen")
										}
									}}
								>
									<label className='mb-1 mt-1 flex'>Duration</label>
									<p className={`flex justify-between mt-2 mb-0 truncate ${state.selectedDuration ? 'text-white' : 'placeholder'}`}>
										<span> {momentDateGivenFormat(state.startTime, 'DD MMM YYYY') +' - '+ momentDateGivenFormat(state.endTime, 'DD MMM YYYY')}</span>
										<Icon icon="icon-park-solid:down-one" className={`${state.selectedDuration ? "text-white" : "text-lightGray"}`} width="16" height="16" />
									</p>
									{state.isDurationOpen ?
										<div className='relative'>
											<MultiSelectSection 
												fields={["value", "option"]}
												options={durationOptions}
												selectedValues={state.selectedDuration ? state.selectedDuration : ''}
												callbackMultiSelect={(value) => {
													if(!value || typeof(value) === 'string') {
														if(value !== 'custom') {
															setState(prevState => ({ ...prevState, selectedDuration: value, showDateRangePicker: false, getDurationDetails: true }))
														} else {
															setState(prevState => ({ ...prevState, showDateRangePicker: true, selectedDuration: value }))
														}
													} else {
														value.preventDefault()
														handleChildClick(value, "search", 'singleDropDown', "")
													}
												}}
												singleSelection={true}
												widthClass={'minWidth220'}
												removeTopOptions={true}
											/>
										</div>
									: null}
								</div>
								
								{state.showDateRangePicker ?
									<div className='relative'>
										<div className='absolute right-4 top-20' ref={dateRef}>
											<DateRange
												editableDateInputs={true}
												onChange={handleSelect}
												moveRangeOnFirstSelection={false}
												ranges={dateState}
											/>
										</div>
									</div>
								: null}
							</div>
						</div>
					: null}
				</div>
			</div>
			<div className='m-2'>
				{state.showQueryResult ?
					<QueryResults 
						providers={state.providers}
						accounts={state.accounts}
						regions={state.regions}
						selectedQuery = {state.selectedQuery}
						queryStartTime = {state.queryStartTime}
						queryEndTime = {state.queryEndTime}
						selectedInput = {state.selectedInput}
						querybuilder = {state.querybuilder}
						queryResult = {state.queryResult}
						selectedProvider = {state.selectedProvider}
						selectedAccount = {state.selectedAccount}
						selectedRegion = {state.selectedRegion}
						duration = {state.selectedQueryDuration}
						account_id = {state[state.selectedQuery.query_id+"_selectedQueryAccount"]}
						region = {state[state.selectedQuery.query_id+"_selectedQueryRegion"]}
						hideQueryResults = {() => setState(prevState => ({ ...prevState, showQueryResult: false }))}
					/>
				:
					<div className='w-full bg-black p-3 overflow-y-auto h-100'>
						{state.listLoading ?
							<div className='flex justify-center m-4'>
								<svg className="animate-spin h-5 w-5 mr-2 text-white" fill="currentColor" viewBox="0 0 24 24">
									<circle cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="2" fill="none" />
									<path d="M4 12a8 8 0 0112-6.9" />
								</svg>
							</div>
						:	
							<React.Fragment>
							<div className={`flex flex-wrap pb-2 justify-between`}>
								<p className="w-2/5 m-0 self-center pt-1">Showing {state.filteredArray && state.filteredArray.length} of total {state.queryList && state.queryList.length} querie(s)</p>
								<div className="flex justify-end w-3/5">
									{state.queryList && state.queryList.length ?
										<Search
											data={state.queryList ? state.queryList : []}
											topClassName={'text-black bg-transparent border border-mediumDarkGray rounded-md'}
											searchClassName={'px-2'}
											searchIconColor={'text-white'}
											searchPlaceHolder={'Search queries....'}
											className={'bg-transparent text-white px-2 py-1'}
											widthHeight={25}
											filteredData={(filteredArray) => {
												setState(prevState => ({ ...prevState, filteredArray, startRecord: 0, currentPage: 1 }))
											}}
										/>
									: null}
									{state.filteredArray && state.filteredArray.length > state.perPage ?
										<div className='flex justify-end self-center text-white pr-2'>
											<span className='mx-3'>Page <strong>{state.currentPage} of {state.totalPages}</strong> </span>
											<div className="pagination text-sm">
												<button><Icon icon="system-uicons:push-left" width="20" height="20" className={`ml-2 cursor-pointer ${state.currentPage === 1 ? 'pointer-events-none text-lightGray' : 'text-info'}`} onClick={() => navigatePage('start', state.currentPage)} /></button> 
												<button><Icon icon="prime:angle-left" width="20" height="20" className={`ml-2 cursor-pointer ${state.currentPage === 1 ? 'pointer-events-none text-lightGray' : 'text-info'}`} onClick={() => navigatePage('previous', state.currentPage)} /></button>
												<button><Icon icon="prime:angle-right" width="20" height="20" className={`ml-3 cursor-pointer ${state.currentPage === state.totalPages ? 'pointer-events-none text-lightGray' : 'text-info'}`} onClick={() => navigatePage('next', state.currentPage)}/></button> 
												<button><Icon icon="system-uicons:push-right" width="20" height="20" className={`ml-2 cursor-pointer ${state.currentPage === state.totalPages ? 'pointer-events-none text-lightGray' : 'text-info'}`} onClick={() => navigatePage('end', state.currentPage)} /></button>
											</div>
										</div>
									: null}
								</div>
							</div>
						
							<div className="flex flex-wrap">
								{state.categories ? 
									<div className="lg:w-1/6 md:w-3/12 w-full" id="scrollTop">
										<div className="bg-darkGray p-2">
											{state.categories.map((item, index) => {
												return(
													<div key={'cat_'+index}>
													{item.category === "All" ?
														<div className={`flex justify-between cursor-pointer mb-2 ${state.selectedCategory === item.category ? 'text-info disabled' : ''}`} onClick={() => setState(prevState => ({ ...prevState, selectedCategory: "All", selectedSubCategory: "", getAllQueries: true }))}>
															<p className="m-0 text-lg">{item.category} Queries</p>
														</div>
													:
														<React.Fragment>
														<div className={`cursor-pointer mb-2 ${!state.selectedSubCategory && item.category.includes(state.selectedCategory) ? 'text-info pointer-events-none' : (!item.count ? 'text-gray4 pointer-events-none' : 'text-white')}`}>
															<div className="flex justify-between mb-2" onClick={() => setState(prevState => ({ ...prevState, selectedCategory: item.category, selectedSubCategory: "", filterQueryList: true }))}>
																<p className={`flex mb-0 self-center ${item.category === state.selectedCategory ? 'text-info' : 'text-white'}`} id={'category_'+index}>
																	<Icon icon={`${item.category === state.selectedCategory ?'zondicons:minus-outline' : 'tabler:circle-plus'}`} className={`mr-1 self-center cursor-pointer ${item.category === state.selectedCategory ? 'text-info' : 'text-white'}`} width={16} height={16} />
																	{item.category}
																</p>
																<UncontrolledTooltip target={'category_'+index}>
																	<div className="bg-black p-2 rounded-md">{item.category}</div>
																</UncontrolledTooltip>
																<p className='mb-0 bg-mediumDarkGray text-white px-2 py-1 min-w-12 max-w-12'>{item.count !== '' ? item.count : ''}</p>
															</div>
														</div>
														{item.category.includes(state.selectedCategory) && item.sub_category && item.sub_category.length ?
															item.sub_category.map(subCat => {
																return(
																	<div className={`flex justify-between mb-2 ml-2 ${subCat.sub_category === state.selectedSubCategory ? 'text-info pointer-events-none' : 'cursor-pointer'}`} style={{maxWidth: "230px"}} onClick={() => setState(prevState => ({ ...prevState, selectedSubCategory: subCat.sub_category, filterQueryList: true }))}>
																		<p className="mb-0 self-center w-3/5" id={'sub_category_'+index}>{subCat.sub_category}</p>
																		<UncontrolledTooltip target={'sub_category_'+index}>
																			<div className="bg-black p-2 rounded-md">{subCat.sub_category}</div>
																		</UncontrolledTooltip>
																		<input className="bg-transparent border-b border-lightGray px-2 py-1 text-white w-1/4" style={{minWidth: "40px"}} type="text" placeholder="1" value={subCat.count !== '' ? subCat.count : ''} />
																	</div>
																)
															})															
														: null}
														</React.Fragment>
													}
													</div>
												)
											})}
										</div>
									</div>
								: null}
								<div className={`lg:w-5/6 md:w-9/12 w-full h-full pl-3`} id="scrollTop">
									{state.filteredArray && state.filteredArray.length ?
										state.filteredArray.slice(state.startRecord, state.startRecord + state.perPage).map((item, i) => {
											return(
												<div key={'filArr_'+i} className={`bg-darkGray p-3 rounded-md mb-2 overflow-unset`}>
													<div className="flex justify-between">
														<div id={'heading_'+i} className="p-3" onClick={() => querySelection(i, item)}>
															<div className="mb-1 flex justify-between">
																<div className="flex text-white text-lg">
																	<Icon icon={`${state.selectedQuery && state.selectedQuery.query_id === item.query_id ? 'zondicons:minus-outline' : 'tabler:circle-plus'}`} className={`mr-1 self-center cursor-pointer text-white`} width={16} height={16} />
																	{item.query_id +" - "+ (item.title ? item.title : "")}
																</div>
															</div>
															{/* <div className="mb-1 flex justify-between" data-toggle="collapse" data-target={'#collapse_event'}aria-expanded="true" aria-controls={'collapse_event'}>
																<div className="flex text-white">
																	<Icon icon={`${state.selectedQuery && state.selectedQuery.query_id === item.query_id ? 'zondicons:minus-outline' : 'tabler:circle-plus'}`} className={`mr-1 self-center cursor-pointer text-white`} width={16} height={16} />
																	{item.query_id +" - "+ (item.title ? item.title : "")}
																</div>
															</div> */}
															{!state.selectedQuery || state.selectedQuery.query_id !== item.query_id ?
																<p className="ml-4 mb-0 text-md"> 
																	<span className="mr-2 text-white">Query:</span>
																	{item.display_query}

																	{item.required_fields && item.required_fields.includes("account_id") && state.selectedAccount && state.selectedAccount.length && !state.selectedAccount.includes("All") ?
																			<span className="ml-1">for the account <span className="ml-1 text-info">{state.selectedAccount.join(", ")}</span></span>
																		:
																			<span className="ml-1">for <span className="ml-1 text-info">All</span> <span className="ml-1">the accounts</span></span>
																		}
																		{item.required_fields && item.required_fields.includes("region") && state.selectedRegion && state.selectedRegion.length && !state.selectedRegion.includes("All") ?
																			<span className="ml-1">for the region <span className="ml-1 text-info">{state.selectedRegion.join(", ")}</span></span>
																		:
																			<span className="ml-1">for <span className="ml-1 text-info">All</span> <span className="ml-1">the regions</span></span>
																		}																
																		{item.required_fields && item.required_fields.includes("duration") && state.selectedDuration ?
																			<span className="ml-1">for the duration 
																			{state.selectedDuration === "custom" ?
																				<span className="ml-1 text-info">{momentDateGivenFormat(state.startTime, "DD MMM YYYY hh:mm A") +" - "+momentDateGivenFormat(state.endTime, "DD MMM YYYY hh:mm A")}</span>
																			: 
																				<span className="ml-1 text-info">{state.selectedDuration && state.selectedDuration !== "" ? durationOptions.filter(e => e.value === state.selectedDuration)[0].option : ""}</span>
																			}
																			</span>
																		: null}
																</p>
															: null}
														</div>
														<span className={`text-right pr-3 ursorPointer mt-3`} 
															onClick={e => { 
																setState(prevState => ({
																	...prevState, 
																	['favourite_'+i]: typeof state['favourite_'+i] === 'undefined' ? (item.favourite ? false : true) : (state['favourite_'+i] ? false :  true)
																}))
																setFavouriteQueryFunction(i, item.query_id, typeof state['favourite_'+i] === 'undefined' ? (item.favourite ? false : true) : (state['favourite_'+i] ? false : true))
															}}
														>
															{(typeof state['favourite_'+i] === 'undefined' && item.favourite) || state['favourite_'+i]  ? 
																<Icon icon="emojione:star" className={`ml-1 self-center cursor-pointer`} width="16" height="16" />
															: 
																<Icon icon="ph:star-thin" className={`text-white ml-1 self-center cursor-pointer`} width="16" height="16" />
															}

															{/* <i className={`fal fa-star ${typeof state['favourite_'+i] === 'undefined' ? (item.favourite ? 'fillStar' : '') : (state['favourite_'+i] ? 'fillStar' : '')} `}></i> */}
														</span>
													</div>
													{state.selectedQuery && state.selectedQuery.query_id === item.query_id ?
														<div className='px-3'>
															{item.description && item.description !== "" ?
																<div className="text-md w-full mb-1 text-lightGray"> 
																	<span className="w-full">Description:</span>
																	{item.description.split("\n").map((newLine, dIndex) => {
																		return(
																			<span key={'des_'+dIndex} className="flex">
																			{newLine.split("\t").map((tab, i) => {
																				return(
																					<span key={'des_line_'+i} className={`${!i ? "" :""}`}>{tab}</span>
																				)
																			})}
																			</span>
																		)
																	})}
																</div>
															: null}
															<div className="bg-black p-2 rounded-md">
																{item.controls && Object.entries(item.controls).length ?
																	<div className="flex mt-2 p-3 rounded-md bg-mediumDarkGray">
																		{item.controls.hasOwnProperty("Tactic") ?
																			<div className={`mr-2 border-r border-black5 ${(!item.controls.hasOwnProperty("Action/Impact") && !item.controls.hasOwnProperty("Technique")) ? "w-full" : (!item.controls.hasOwnProperty("Action/Impact") || !item.controls.hasOwnProperty("Technique")) ? "w-1/2" : "w-1/5"}
																			`}>
																				<label className="text-white mb-2">Tactic</label>
																				{item.controls.Tactic.map((ctrls, cIndex) => {
																					return(
																						<p  key={'ctrls_'+cIndex} className="mb-0 flex flex-wrap">{ctrls}</p>
																					)
																				})}
																			</div>
																		: null}
																		{item.controls.hasOwnProperty("Technique") ?
																			<div className={`mr-2 border-right-black5 ${(!item.controls.hasOwnProperty("Action/Impact") && !item.controls.hasOwnProperty("Tactic")) ? "w-full" : (!item.controls.hasOwnProperty("Action/Impact") || !item.controls.hasOwnProperty("Tactic")) ? "w-1/2" : "w-1/5"}`}>
																				<label className="text-white mb-2">Technique</label>
																				{item.controls.Technique.map((ctrls, cIndex) => {
																					return(
																						<p  key={'ctrls_'+cIndex} className="mb-0 flex flex-wrap">{ctrls}</p>
																					)
																				})}
																			</div>
																		: null}
																		{item.controls.hasOwnProperty("Action/Impact") ?
																			<div className={`mr-2 ${(!item.controls.hasOwnProperty("Tactic") && !item.controls.hasOwnProperty("Technique")) ? "w-full" : (!item.controls.hasOwnProperty("Tactic") || !item.controls.hasOwnProperty("Technique")) ? "w-1/2" : "w-1/2"}`}>
																				<label className="text-white mb-2">Action/Impact</label>
																				{item.controls["Action/Impact"].map((ctrls, cIndex) => {
																					return(
																						<p  key={'ctrls_'+cIndex} className="mb-0 flex flex-wrap">{ctrls}</p>
																					)
																				})}
																			</div>
																		: null}
																	</div>
																: null}
																<div className={`p-3 bg-black rounded-md mt-2`}>
																	<div className="flex flex-wrap">
																		{state.querybuilder && state.querybuilder.map((qry, j) => {
																			return(
																				<span key={'qry_'+j}>
																				{!qry.input ?
																					<p className={`mb-0 ${!j ? "mr-2" : "mx-2"} self-end text-info mt-3`}>{qry.string}</p>
																				:
																				<React.Fragment>
																					{state.selectedQuery.display_output && state.selectedQuery.display_output[qry.field] && state.selectedQuery.display_output[qry.field].type === "text" ?
																						state.selectedQuery.display_output[qry.field].output_type === "string" ?
																							<React.Fragment>
																							{state.selectedQuery.display_output[qry.field].api_details && Object.entries(state.selectedQuery.display_output[qry.field].api_details).length ?
																								<div className='min-w-[250px]' onClick={(event) => {
																									event.preventDefault();
																									handleChildClick(event, 'child', 'singleDropDown', "show_"+state.selectedQuery.query_id+"_"+state.selectedQuery.display_output[qry.field].output_field)
																								}}>
																									<p className={`flex bg-transparent border-b border-lightGray px-2 py-1 justify-between px-2 mb-0 mx-2 truncate cursor-pointer ${state[state.selectedQuery.query_id+"_"+state.selectedQuery.display_output[qry.field].output_field] ? 'text-white' : 'placeholder'}`} onClick={() => setState(prevState => ({ ...prevState, showDateOptions: !prevState.showDateOptions }))}>
																										{state[state.selectedQuery.query_id+"_"+state.selectedQuery.display_output[qry.field].output_field] ? state[state.selectedQuery.query_id+"_"+state.selectedQuery.display_output[qry.field].output_field] : 'Select'}
																										<Icon icon="fa-solid:sort-down" width="16" height="16" className={`${state[state.selectedQuery.query_id+"_"+state.selectedQuery.display_output[qry.field].output_field] ? 'text-white' : 'text-lightGray'} self-center`} />
																									</p>
																									{state["show_"+state.selectedQuery.query_id+"_"+state.selectedQuery.display_output[qry.field].output_field] ?
																										<div className='relative'>
																											<MultiSelectSection 
																												fields={["value", "value"]}
																												removeTop={true}
																												removeTopOptions={true}
																												widthClass={'minWidth220'}
																												options={state[state.selectedQuery.query_id+"_options_"+state.selectedQuery.display_output[qry.field].output_field]}
																												selectedValues={state[state.selectedQuery.query_id+"_"+state.selectedQuery.display_output[qry.field].output_field] ? state[state.selectedQuery.query_id+"_"+state.selectedQuery.display_output[qry.field].output_field] : ''}
																												callbackMultiSelect={(value) => {
																													if(!value || typeof(value) === 'string') {
																														setState(prevState => ({ 
																															...prevState, 
																															[state.selectedQuery.query_id+"_"+state.selectedQuery.display_output[qry.field].output_field]: value
																														}))
																														onChangeTextDropDown(state.selectedQuery.display_output, qry.field)
																													} else {
																														value.preventDefault()
																														handleChildClick(value, "search", 'singleDropDown', "")
																													}
																												}}
																												singleSelection={true}
																											/>
																										</div>
																									: null}
																								</div>
																							:
																								<React.Fragment>
																								<input 
																									type="text" 
																									className={`bg-transparent border-b border-lightGray px-2 py-1 text-white w-full w-32 ${state.hasError && (!state[state.selectedQuery.query_id+"_"+state.selectedQuery.display_output[qry.field].output_field] || state[state.selectedQuery.query_id+"_"+state.selectedQuery.display_output[qry.field].output_field] === "" || state["error_message_"+state.selectedQuery.query_id+"_"+state.selectedQuery.display_output[qry.field].output_field] !== "")  ? 'dottedDangerTextboxLine' : ''}`}
																									placeholder={qry.field}
																									value={state[state.selectedQuery.query_id+"_"+state.selectedQuery.display_output[qry.field].output_field] ? state[state.selectedQuery.query_id+"_"+state.selectedQuery.display_output[qry.field].output_field] : ''}
																									onChange={e => onChangeTextString(state.selectedQuery.display_output[qry.field], state.selectedQuery.query_id+"_"+state.selectedQuery.display_output[qry.field].output_field, e.target.value)}
																								/>
																								{state.selectedQuery.display_output[qry.field].validation ?
																									<React.Fragment>
																										<Icon icon="fa:info-circle" className="text-white self-center cursor-pointer" id={state.selectedQuery.query_id+"_"+state.selectedQuery.display_output[qry.field].output_field} />
																									<UncontrolledTooltip target={state.selectedQuery.query_id+"_"+state.selectedQuery.display_output[qry.field].output_field}>
																									<div className="bg-black p-2 rounded-md">
																										{state.selectedQuery.display_output[qry.field].validation.data_type ?
																											<p className="mb-1 text-lightGray">
																												<span className="text-white mr-1">Data Type:</span>
																												{state.selectedQuery.display_output[qry.field].validation.data_type}
																											</p>
																										: null}
																										{state.selectedQuery.display_output[qry.field].validation.min ?
																											<p className="mb-1 text-lightGray">
																												<span className="text-white mr-1">Min length:</span>
																												{state.selectedQuery.display_output[qry.field].validation.min}
																											</p>
																										: null}
																										{state.selectedQuery.display_output[qry.field].validation.max ?
																											<p className="mb-1 text-lightGray">
																												<span className="text-white mr-1">Max length:</span>
																												{state.selectedQuery.display_output[qry.field].validation.max}
																											</p>
																										: null}
																									</div>
																									</UncontrolledTooltip>
																									</React.Fragment>
																								: null}
																								</React.Fragment>
																							}
																							</React.Fragment>
																						: state.selectedQuery.display_output[qry.field].output_type === "list" ?
																							<React.Fragment>
																							{state.selectedQuery.display_output[qry.field].api_details && Object.entries(state.selectedQuery.display_output[qry.field].api_details).length ?
																								<div className='min-w-[250px]' onClick={(event) => {
																									event.preventDefault();
																									handleChildClick(event, 'child', 'muliselectDropDown', 'show_'+state.selectedQuery.query_id+"_"+state.selectedQuery.display_output[qry.field].output_field)
																								}}>
																									<p className={`flex bg-transparent border-b border-lightGray px-2 py-1 justify-between px-2 mb-0 mx-2 truncate cursor-pointer ${state[state.selectedQuery.query_id+"_"+state.selectedQuery.display_output[qry.field].output_field] && state[state.selectedQuery.query_id+"_"+state.selectedQuery.display_output[qry.field].output_field].length ? 'text-white' : 'text-lightGray'}`}>
																										{state[state.selectedQuery.query_id+"_"+state.selectedQuery.display_output[qry.field].output_field] && state[state.selectedQuery.query_id+"_"+state.selectedQuery.display_output[qry.field].output_field].length ? state[state.selectedQuery.query_id+"_"+state.selectedQuery.display_output[qry.field].output_field].length+' Selected' : 'Select'}
																										<Icon icon="fa-solid:sort-down" width="16" height="16" className={`${state[state.selectedQuery.query_id+"_"+state.selectedQuery.display_output[qry.field].output_field] && state[state.selectedQuery.query_id+"_"+state.selectedQuery.display_output[qry.field].output_field].length ? 'text-white' : 'text-lightGray'} self-center`} />
																									</p>

																									{state['show_'+state.selectedQuery.query_id+"_"+state.selectedQuery.display_output[qry.field].output_field] ?
																										<div className='relative'>
																											<MultiSelectSection 
																												fields={["value", "label"]}
																												removeTopOptions={true}
																												widthClass={'minWidth220'}
																												options={state[state.selectedQuery.query_id+"_options_"+state.selectedQuery.display_output[qry.field].output_field]}
																												selectedValues={state[state.selectedQuery.query_id+"_"+state.selectedQuery.display_output[qry.field].output_field] ? state[state.selectedQuery.query_id+"_"+state.selectedQuery.display_output[qry.field].output_field] : []}
																												callbackMultiSelect={(value) => setState(prevState => ({ ...prevState, [state.selectedQuery.query_id+"_"+state.selectedQuery.display_output[qry.field].output_field]: value }))}
																											/>
																										</div>
																									: null}
																								</div>
																							:
																								<React.Fragment>
																								<input 
																									type="text" 
																									className={`bg-transparent border-b border-lightGray px-2 py-1 text-white w-full w-32 ${state.hasError && (!state[state.selectedQuery.query_id+"_"+state.selectedQuery.display_output[qry.field].output_field] || !state[state.selectedQuery.query_id+"_"+state.selectedQuery.display_output[qry.field].output_field].length)  ? 'dottedDangerTextboxLine' : ''}`}
																									placeholder={qry.field}
																									value={state[state.selectedQuery.query_id+"_listInput_"+state.selectedQuery.display_output[qry.field].output_field]}
																									onKeyDown={e => onKeyDown(e, state.selectedQuery.query_id+"_listInput_"+state.selectedQuery.display_output[qry.field].output_field, state.selectedQuery.query_id+"_"+state.selectedQuery.display_output[qry.field].output_field)}
																									onBlur={e => onBlur(e, state.selectedQuery.query_id+"_listInput_"+state.selectedQuery.display_output[qry.field].output_field, state.selectedQuery.query_id+"_"+state.selectedQuery.display_output[qry.field].output_field)}
																									onChange={e => setState(prevState => ({ ...prevState,[state.selectedQuery.query_id+"_listInput_"+state.selectedQuery.display_output[qry.field].output_field]: e.target.value }))}
																								/>
																								{state.selectedQuery.display_output[qry.field].validation ?
																									<React.Fragment>
																									<Icon icon="fa:info-circle" className="text-white self-center cursor-pointer" id={state.selectedQuery.query_id+"_"+state.selectedQuery.display_output[qry.field].output_field} />
																									<UncontrolledTooltip target={state.selectedQuery.query_id+"_"+state.selectedQuery.display_output[qry.field].output_field}>
																									<div className="bg-black p-2 rounded-md">
																										{state.selectedQuery.display_output[qry.field].validation.data_type ?
																											<p className="mb-1 text-lightGray">
																												<span className="text-white mr-1">Data Type:</span>
																												{state.selectedQuery.display_output[qry.field].validation.data_type}
																											</p>
																										: null}
																										{state.selectedQuery.display_output[qry.field].validation.min ?
																											<p className="mb-1 text-lightGray">
																												<span className="text-white mr-1">Min length:</span>
																												{state.selectedQuery.display_output[qry.field].validation.min}
																											</p>
																										: null}
																										{state.selectedQuery.display_output[qry.field].validation.max ?
																											<p className="mb-1 text-lightGray">
																												<span className="text-white mr-1">Max length:</span>
																												{state.selectedQuery.display_output[qry.field].validation.max}
																											</p>
																										: null}
																									</div>
																									</UncontrolledTooltip>
																									</React.Fragment>
																								: null}
																								</React.Fragment>
																							}

																							{state.selectedQuery.display_output[qry.field].api_details && Object.entries(state.selectedQuery.display_output[qry.field].api_details).length ? 
																								state[state.selectedQuery.query_id+"_"+state.selectedQuery.display_output[qry.field].output_field] && !state[state.selectedQuery.query_id+"_"+state.selectedQuery.display_output[qry.field].output_field].includes("All") ? 
																									state[state.selectedQuery.query_id+"_"+state.selectedQuery.display_output[qry.field].output_field].map((tab, addIndex) => {
																										return(
																											<span key={'tab_'+addIndex} className={`badge-square badge-secondary mt-3 f13 self-end p-1 ${!addIndex ? "ml-2" : "ml-1"} mr-1 mb-1`} >
																												{state[state.selectedQuery.query_id+"_options_"+state.selectedQuery.display_output[qry.field].output_field].map(res => {
																													return(
																													res.value === tab ?
																														res.value +(res.key !== res.value ? " ("+res.key+")" : "")
																													: null
																													)
																												})}
																												<Icon icon="ion:close-circle-sharp" width="16" height="16"  className='text-white ml-2 self-center' onClick={ () => removeSelectedBadge(state.selectedQuery.query_id+"_"+state.selectedQuery.display_output[qry.field].output_field, tab, addIndex) } />
																											</span>
																										)
																									})
																								: state[state.selectedQuery.query_id+"_"+state.selectedQuery.display_output[qry.field].output_field] && state[state.selectedQuery.query_id+"_"+state.selectedQuery.display_output[qry.field].output_field].includes("All") ?
																									<span className={`badge-square badge-secondary mt-3 f13 self-end p-1 ml-2 mr-1 mb-1`} > All </span>
																								: null
																							:
																								state[state.selectedQuery.query_id+"_"+state.selectedQuery.display_output[qry.field].output_field] && state[state.selectedQuery.query_id+"_"+state.selectedQuery.display_output[qry.field].output_field].map((tab, addIndex) => {
																									return(
																										<span key={'tab1_'+addIndex} className={`badge-square badge-secondary mt-3 f13 self-end p-1 ${!addIndex ? "ml-2" : "ml-1"} mr-1 mb-1`} >
																										{tab}
																											<Icon icon="ion:close-circle-sharp" width="16" height="16"  className='text-white ml-2 self-center' onClick={ () => removeSelectedBadge(state.selectedQuery.query_id+"_"+state.selectedQuery.display_output[qry.field].output_field, tab, addIndex) } />
																										</span>
																									)
																								})
																							}
																							</React.Fragment>
																						: null
																					: state.selectedQuery.display_output && state.selectedQuery.display_output[qry.field] && state.selectedQuery.display_output[qry.field].type === "dropdown" ?
																						state.selectedQuery.display_output[qry.field].output_type === "list" ?
																							<React.Fragment>
																							{state[state.selectedQuery.query_id+"_"+state.selectedQuery.display_output[qry.field].output_field] && !state[state.selectedQuery.query_id+"_"+state.selectedQuery.display_output[qry.field].output_field].includes("All") ? 
																							state[state.selectedQuery.query_id+"_"+state.selectedQuery.display_output[qry.field].output_field].map((tab, addIndex) => {
																								return(
																									<span key={'tab2_'+addIndex} className={`badge-square badge-secondary mt-3 f13 self-end p-1 ${!addIndex ? "ml-2" : "ml-1"} mr-1 mb-1`} >
																									{tab}
																										<Icon icon="ion:close-circle-sharp" width="16" height="16"  className='text-white ml-2 self-center' onClick={ () => removeSelectedBadge(state.selectedQuery.query_id+"_"+state.selectedQuery.display_output[qry.field].output_field, tab, addIndex) } />
																									</span>
																								)
																							})
																							: state[state.selectedQuery.query_id+"_"+state.selectedQuery.display_output[qry.field].output_field] && state[state.selectedQuery.query_id+"_"+state.selectedQuery.display_output[qry.field].output_field].includes("All") ?
																								<span className={`badge-square badge-secondary mt-3 f13 self-end p-1 ml-2 mr-1 mb-1`} > All </span>
																							: null}
																							</React.Fragment>
																						: state.selectedQuery.display_output[qry.field].output_type === "string" ?
																								<div className='min-w-[250px]' onClick={(event) => {
																								event.preventDefault();
																								handleChildClick(event, 'child', 'singleDropDown', "show_"+state.selectedQuery.query_id+"_"+state.selectedQuery.display_output[qry.field].output_field)
																							}}>
																								<p className={`flex bg-transparent border-b border-lightGray px-2 py-1 justify-between px-2 mb-0 mx-2 truncate cursor-pointer ${state[state.selectedQuery.query_id+"_"+state.selectedQuery.display_output[qry.field].output_field] ? 'text-white' : 'placeholder'}`}>
																									{state[state.selectedQuery.query_id+"_"+state.selectedQuery.display_output[qry.field].output_field] ? state[state.selectedQuery.query_id+"_"+state.selectedQuery.display_output[qry.field].output_field] : 'Select'}
																									
																									<Icon icon="fa-solid:sort-down" width="16" height="16" className={`${state[state.selectedQuery.query_id+"_"+state.selectedQuery.display_output[qry.field].output_field] ? 'text-white' : 'text-lightGray'} self-center`} />
																								</p>
																								{state["show_"+state.selectedQuery.query_id+"_"+state.selectedQuery.display_output[qry.field].output_field] ?
																									<div className='relative'>
																										<MultiSelectSection
																											widthClass={'minWidth220'}
																											removeTopOptions={true}
																											options={state.selectedQuery.display_output[qry.field].values}
																											selectedValues={state[state.selectedQuery.query_id+"_"+state.selectedQuery.display_output[qry.field].output_field] ? state[state.selectedQuery.query_id+"_"+state.selectedQuery.display_output[qry.field].output_field] : ''}
																											callbackMultiSelect={(value) => {
																												if(!value || typeof(value) === 'string') {
																													setState(prevState => ({ 
																														...prevState,
																														[state.selectedQuery.query_id+"_"+state.selectedQuery.display_output[qry.field].output_field]: value
																													}))
																												} else {
																													value.preventDefault()
																													handleChildClick(value, "search", 'singleDropDown', "")
																												}
																											}}
																											singleSelection={true}
																										/>
																									</div>
																								: null}
																							</div>
																						: null
																					: null}
																				</React.Fragment>
																				}
																			</span>
																			)
																		})}
																		{state.selectedQuery.required_fields ?
																			<React.Fragment>
																			{state.selectedQuery.required_fields.includes("account_id") ?
																				<React.Fragment>
																				<p className="mb-0 mr-2 text-info mt-3">for Account</p>
																				<div className="" style={{minWidth: "150px"}} onClick={(event) => {
																					event.preventDefault();
																					handleChildClick(event, 'child', 'muliselectDropDown', state.selectedQuery.query_id+"_show_selectedQueryAccount")
																				}}>
																					<p className={`flex bg-transparent border-b border-lightGray px-2 py-1 justify-between px-2 mb-0 mx-2 truncate cursor-pointer ${state[state.selectedQuery.query_id+"_selectedQueryAccount"] && state[state.selectedQuery.query_id+"_selectedQueryAccount"].length ? 'text-white' : 'placeholder'}`} style={{height: "38px"}} onClick={() => setState(prevState => ({ ...prevState, [state.selectedQuery.query_id+"_show_selectedQueryAccount"]: !state[state.selectedQuery.query_id+"_show_selectedQueryAccount"]  }))}>{state[state.selectedQuery.query_id+"_selectedQueryAccount"] && state[state.selectedQuery.query_id+"_selectedQueryAccount"].length ? state[state.selectedQuery.query_id+"_selectedQueryAccount"].length+' Selected' : 'Select'}</p>
																					{state[state.selectedQuery.query_id+"_show_selectedQueryAccount"] ?
																						<div className='relative'>
																							<MultiSelectSection 
																								fields={["account_id", "account_name"]}
																								removeTopOptions={true}
																								widthClass={'minWidth220'}
																								options={state.accounts}
																								selectedValues={state[state.selectedQuery.query_id+"_selectedQueryAccount"] ? state[state.selectedQuery.query_id+"_selectedQueryAccount"] : []}
																								callbackMultiSelect={(value) => setState(prevState => ({ ...prevState, [state.selectedQuery.query_id+"_selectedQueryAccount"]: value }))}
																								closeDiv={() => setState(prevState => ({ ...prevState, [state.selectedQuery.query_id+"_show_selectedQueryAccount"]: false }))}
																							/>
																						</div>
																					: null}
																				</div>
																				</React.Fragment>
																			: null}
																			{state.selectedQuery.required_fields.includes("region") ?
																				<React.Fragment>
																				<p className="mb-0 mx-2 text-info mt-3">Region</p>
																				<div className='' style={{minWidth: "150px"}} onClick={(event) => {
																					event.preventDefault();
																					handleChildClick(event, 'child', 'muliselectDropDown', state.selectedQuery.query_id+"_show_selectedQueryRegion")
																				}}>
																					<p className={`flex bg-transparent border-b border-lightGray px-2 py-1 justify-between px-2 mb-0 mx-2 truncate cursor-pointer ${state[state.selectedQuery.query_id+"_selectedQueryRegion"] && state[state.selectedQuery.query_id+"_selectedQueryRegion"].length ? 'text-white' : 'placeholder'}`} style={{height: "38px"}} onClick={() => setState(prevState => ({ ...prevState, [state.selectedQuery.query_id+"_show_selectedQueryRegion"]: !state[state.selectedQuery.query_id+"_show_selectedQueryRegion"]  }))}>{state[state.selectedQuery.query_id+"_selectedQueryRegion"] && state[state.selectedQuery.query_id+"_selectedQueryRegion"].length ? state[state.selectedQuery.query_id+"_selectedQueryRegion"].length+' Selected' : 'Select'}</p>
																					{state[state.selectedQuery.query_id+"_show_selectedQueryRegion"] ?
																						<div className='relative'>
																							<MultiSelectSection 
																								fields={["region", "name"]}
																								removeTopOptions={true}
																								widthClass={'minWidth220'}
																								options={state.regions}
																								selectedValues={state[state.selectedQuery.query_id+"_selectedQueryRegion"] ? state[state.selectedQuery.query_id+"_selectedQueryRegion"] : []}
																								callbackMultiSelect={(value) => setState(prevState => ({ ...prevState, [state.selectedQuery.query_id+"_selectedQueryRegion"]: value }))}
																								closeDiv={() => setState(prevState => ({ ...prevState, [state.selectedQuery.query_id+"_show_selectedQueryRegion"]: false }))}
																							/>
																						</div>
																					: null}
																				</div>
																				</React.Fragment>
																			: null}
																			{state.selectedQuery.required_fields.includes("duration") ?
																				<React.Fragment>
																				<p className="mb-0 mx-2 text-info mt-3">Duration</p>
																				<div className='' style={{minWidth: "250px"}} onClick={(event) => {
																					if(!state.showDateOptions) {
																						event.preventDefault();
																						handleChildClick(event, 'child', 'singleDropDown', "showDateOptions")
																					}
																				}}>
																					<p className={`flex bg-transparent border-b border-lightGray px-2 py-1 justify-between px-2 mb-0 mx-2 truncate cursor-pointer ${state.selectedQueryDuration ? 'text-white' : 'placeholder'}`} style={{height: "38px"}} onClick={() => setState(prevState => ({ ...prevState, showDateOptions: !prevState.showDateOptions }))}>{state.selectedQueryDuration ? durationOptions.filter(e => e.value === state.selectedQueryDuration)[0].option : 'Select'}</p>
																					{state.showDateOptions ?
																						<div className='relative'>
																							<MultiSelectSection 
																								fields={["value", "option"]}
																								removeTopOptions={true}
																								widthClass={'minWidth220'}
																								options={durationOptions}
																								selectedValues={state.selectedQueryDuration ? state.selectedQueryDuration : ''}
																								callbackMultiSelect={(value) => {
																									if(typeof(value) === 'string') {
																										if(value !== 'custom') {
																											setState(prevState => ({ 
																												...prevState,
																												selectedQueryDuration: value,
																												showQueryDateTimePicker: false,
																												getQueryDurationDetails: true
																											}))
																										} else {
																											setState(prevState => ({ 
																												...prevState,showQueryDateTimePicker: true, 
																												selectedQueryDuration: value
																											}))
																										}	
																									} else {
																										value.preventDefault()
																										handleChildClick(value, "search", 'singleDropDown', "")
																									}
																								}}
																								singleSelection={true}
																							/>
																						</div>
																					: null}
																				</div>
																				{state.showQueryDateTimePicker ?
																					<div className="flex mt-2">
																						<p className="mb-0 mx-2 text-info mt-2 pt-1">start&nbsp;time</p>
																						<Datetime 
																							value={momentDateGivenFormat(state.queryStartTime, 'DD MMM YYYY hh:mm A')}
																							onChange={handleStartTimeChange}
																							dateFormat={'DD MMM YYYY'}
																							timeFormat={'hh:mm A'}
																							inputProps={{ readOnly: true, class: "inputField" }}
																							className="w-100 mt-2"
																							isValidDate={queryDateTime}
																						/>
																						<p className="mb-0 mx-2 self-end text-info mt-2 pt-1">end&nbsp;time</p>
																						<Datetime
																							value={momentDateGivenFormat(state.queryEndTime, 'DD MMM YYYY hh:mm A')}
																							onChange={handleEndTimeChange}
																							dateFormat={'DD MMM YYYY'}
																							timeFormat={'hh:mm A'}
																							inputProps={{ readOnly: true, class: "inputField" }}
																							className="w-100 mt-2"
																							isValidDate={queryDateTime}
																						/>
																					</div>
																				: null}
																				</React.Fragment>
																			: null}
																			</React.Fragment>
																		: null}
																	</div>
																</div>
																
																<div className={`flex justify-end w-full mt-2 ${state.queryLoading ? "pointer-events-none" : ""}`}>
																	{state.queryErrorMessage && state.queryErrorMessage !== "" ?
																		// <div className="w-100">
																		<React.Fragment>
																			<p className="mb-0 self-center text-danger mr-3">{state.queryErrorMessage.message ? state.queryErrorMessage.message : state.queryErrorMessage.errorMessage ? state.queryErrorMessage.errorMessage : ""}</p>
																		</React.Fragment>
																	: null}
																	<button className={`flex btn bg-info px-2 py-1 rounded-md text-white self-center mr-2 
																		${state.hasError ? "" : ""}`} 
																		onClick={() => setState(prevState => ({ ...prevState, queryErrorMessage: "", executeQuery: true }))}>
																			{state.queryLoading ?
																				<svg className="animate-spin h-5 w-5 mr-2 text-white" fill="currentColor" viewBox="0 0 24 24">
																					<circle cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="2" fill="none" />
																					<path d="M4 12a8 8 0 0112-6.9" />
																				</svg>
																			: null}
																			Run Query
																		</button>
																	<button className={`btn bg-lightGray px-2 py-1 rounded-md text-white self-center`}onClick={() => setState(prevState => ({ ...prevState, ['expand_'+i]: false, selectedQuery: "" }))}>Close</button>
																</div>
															</div>
														</div>
													: null}
												</div>
											)
										})
									:
										<div className='flex justify-center m-4 text-white'>
											There are no data on this criteria. Please try adjusting your filter.
										</div>
									}
								</div>
							</div>
							</React.Fragment>
						}
					</div>
				}
			</div>
		</div>
	)
}

export default QueryEvents