import React, { useEffect, useState, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux"; // Import useDispatch hook to dispatch actions
import { setHpcPropsDetails } from "../../../../actions/Collider/HpcAction";

const ExistingDatabaseSection = (props) => {
  const [state, setState] = useState({});
  const createUpdateCluster = useSelector(
    (state) => state?.hpc?.hpcPropsDetails?.createUpdateCluster || false
  );
  const propsClusterFilters = useSelector(
    (state) => state?.hpc?.hpcPropsDetails?.propsClusterFilters || false
  );

  const dispatch = useDispatch(); // Create a dispatch function

  const callPageVist = useCallback(() => {
    let databaseConfiguration =
      createUpdateCluster && createUpdateCluster.database_configuration
        ? createUpdateCluster.database_configuration
        : {};

        if (databaseConfiguration) {
          Object.keys(databaseConfiguration).forEach(([key, value]) => {
            let clusterFields = [
              "database",
              "user_name",
              "password_secret_arn"
            ];
            if (clusterFields.includes(key)) {
              setState((prevState) => ({ ...prevState, [key]: value }));
            }
          });
        }
  }, [createUpdateCluster]);

  useEffect(() => callPageVist(), [callPageVist]);

  const handleDatabaseInput = (label, value) => {
    let obj = createUpdateCluster ? createUpdateCluster : {};
    let databaseConfiguration = obj.database_configuration
      ? obj.database_configuration
      : {};
    databaseConfiguration[label] = value;

    obj["database_configuration"] = databaseConfiguration;
    dispatch(setHpcPropsDetails("createUpdateCluster", obj));

    setState((prevState) => ({ ...prevState, [label]: value }));
  };

  const handleChildClick = (event, type, dropdownType, section) => {
    event.stopPropagation();

    let clickedChild = [];
    if (type === "child") {
      if (state.clickedChild && state.clickedChild.length) {
        state.clickedChild.forEach((row) => {
          setState((prevState) => ({ ...prevState, [row]: false }));
        });
      }
      clickedChild.push(section);
      setState((prevState) => ({
        ...prevState,
        [section]:
          dropdownType === "singleDropDown" && state[section] ? false : true,
        clickedChild,
      }));
    } else if (type === "parent") {
      if (state.clickedChild && state.clickedChild.length) {
        state.clickedChild.forEach((row) => {
          setState((prevState) => ({ ...prevState, [row]: false }));
        });
      }

      setState((prevState) => ({ ...prevState, clickedChild }));
    }
  };

  return (
  <div
    className="rounded bg-dark p-3"
    onClick={(event) => handleChildClick(event, "parent")}
  >
    <div className="flex">
      <div className="py-1 w-full">
        <div className="lg:w-1/3 md:w-1/2">
          <p className="block mb-0">Database Uri</p>
          <input
            type="text"
            placeholder="Enter database uri"
            className={`border border-lightGray rounded-md bg-transparent px-2 py-1 text-white w-full`}
            // maxlength='128'
            value={ createUpdateCluster && createUpdateCluster.database_configuration && createUpdateCluster.database_configuration.database ? createUpdateCluster.database_configuration.database : "" }
            onChange={(e) => 
              handleDatabaseInput("database", e.target.value)}
          />
        </div>
        {propsClusterFilters && propsClusterFilters.description && propsClusterFilters.description.database_configuration && propsClusterFilters.description.database_configuration.database ? (
          <p className="mt-1 mb-0 w-1/2 text-info">
            { propsClusterFilters.description.database_configuration.database }
          </p>
        ) : null}
      </div>
    </div>
    <div className="flex mt-3">
      <div className="py-1 w-full">
        <div className="lg:w-1/3 md:w-1/2">
          <p className="block mb-0">Username</p>
          <input
            type="text"
            placeholder="Enter username (Eg. admin)"
            className={`border border-lightGray rounded-md bg-transparent px-2 py-1 text-white w-full`}
            // maxlength='128'
            value={ createUpdateCluster && createUpdateCluster.database_configuration && createUpdateCluster.database_configuration.user_name ? createUpdateCluster.database_configuration.user_name : "" }
            onChange={(e) => 
              handleDatabaseInput("user_name", e.target.value)}
          />
        </div>
        {propsClusterFilters && propsClusterFilters.description && propsClusterFilters.description.database_configuration && propsClusterFilters.description.database_configuration.user_name ? (
          <p className="mt-1 mb-0 text-info">
            {propsClusterFilters.description.database_configuration.user_name}
          </p>
        ) : null}
      </div>
    </div>
    <div className="flex mt-3">
      <div className="py-1 w-full">
        <div className="w-2/3">
          <p className="block mb-0">
            Password secret amazon resource name (ARN)
          </p>
          <input
            type="text"
            placeholder="Enter secret arn"
            className={`border border-lightGray rounded-md bg-transparent px-2 py-1 text-white w-full`}
            // maxlength='128'
            value={ createUpdateCluster && createUpdateCluster.database_configuration && createUpdateCluster.database_configuration.password_secret_arn ? createUpdateCluster.database_configuration.password_secret_arn : "" }
            onChange={(e) => 
              handleDatabaseInput("password_secret_arn", e.target.value)}
          />
        </div>
        {propsClusterFilters && propsClusterFilters.description && propsClusterFilters.description.database_configuration && propsClusterFilters.description.database_configuration
          .password_secret_arn ? (
          <p className="mt-1 mb-0 text-info">
            { propsClusterFilters.description.database_configuration.password_secret_arn }
          </p>
        ) : null}
      </div>
    </div>
  </div>
);
};

export default ExistingDatabaseSection;
