/*************************************************
 * Collider
 * @exports
 * @file assetsAction.js
 * @author Rajasekar // on 05/06/2023
 * @copyright © 2019 Collider. All rights reserved.
 *************************************************/
// import axios from 'axios'
// //import Cookies from 'js-cookie'
import ApiCalls from '../../components/ApiCalls';
import {
	SYNCHRONIZER_END_POINT,
	LIST_ASSETS,
	GET_TOTAL_ASSET_COUNT,
	GET_DAILY_ASSET_COUNT,
	GET_ASSET_DETAIL_EXT,
	GET_ASSET_NETWORK_DETAILS,
	GET_ASSET_SG_DETAILS,
	GET_ASSET_STORAGE_DETAILS,
	

	LIST_CLUSTER_ASSETS,
	LIST_CLUSTER_NAMES,
	LIST_ADVANCED_SEARCH_ASSETS,
	GET_ADVANCED_TOTAL_ASSETS_COUNT
} from '../../config'
import { ACTION_TYPES } from '../types'

/**
 * Action to get all assets list
 * @param {Object} body
 * @param {Function} callback
 */
export const listAssets = (params) => {
	return async () => {
		try {

			const url = LIST_ASSETS;
			// Assuming your API endpoint for the POST request is 'YOUR_API_ENDPOINT'
			const response = await ApiCalls.post(url, params);

			// You can dispatch an action with the fetched data here
			//   dispatch({ type: 'FETCH_TROUBLESHOOT_RESULTS_SUCCESS', payload: response.data });

			// Return the response data so you can use it in the component
			return response.data;
		} catch (error) {
			return error.response && error.response.data ? error.response.data : error.response && error.response.data ? error.response.data : error.response
		}
	};
}



/**
 * Action to list-cluster-assets
 * @param {Object} body
 * @param {Function} callback
 */
export const listClusterAssets = (params) => {
	return async () => {
		try {

			const url = LIST_CLUSTER_ASSETS;
			// Assuming your API endpoint for the POST request is 'YOUR_API_ENDPOINT'
			const response = await ApiCalls.post(url, params);

			// You can dispatch an action with the fetched data here
			//   dispatch({ type: 'FETCH_TROUBLESHOOT_RESULTS_SUCCESS', payload: response.data });

			// Return the response data so you can use it in the component
			return response.data;
		} catch (error) {
			return error.response && error.response.data ? error.response.data : error.response && error.response.data ? error.response.data : error.response
		}
	}
}

/**
 * Action to listAdvancedSearchAssets
 * @param {Object} body
 * @param {Function} callback
 */
export const listAdvancedSearchAssets = (params) => {
	return async () => {
		try {
			const url = LIST_ADVANCED_SEARCH_ASSETS;
			// Assuming your API endpoint for the POST request is 'YOUR_API_ENDPOINT'
			const response = await ApiCalls.post(url, params);

			// You can dispatch an action with the fetched data here
			//   dispatch({ type: 'FETCH_TROUBLESHOOT_RESULTS_SUCCESS', payload: response.data });

			// Return the response data so you can use it in the component
			return response.data;
		} catch (error) {
			return error.response && error.response.data ? error.response.data : error.response && error.response.data ? error.response.data : error.response
		}
	}
}

/**
 * Action to getAdvancedSearchAssets
 * @param {Object} body
 * @param {Function} callback
 */
export const getAdvancedSearchAssetsCount = (params) => {
	return async () => {
		try {
			const url = GET_ADVANCED_TOTAL_ASSETS_COUNT;
			/// Assuming your API endpoint for the POST request is 'YOUR_API_ENDPOINT'
			const response = await ApiCalls.post(url, params);

			// You can dispatch an action with the fetched data here
			//   dispatch({ type: 'FETCH_TROUBLESHOOT_RESULTS_SUCCESS', payload: response.data });

			// Return the response data so you can use it in the component
			return response.data;
		} catch (error) {
			return error.response && error.response.data ? error.response.data : error.response && error.response.data ? error.response.data : error.response
		}
	}
}

/**
 * Action to get total Asset Count
 * @param {Object} body
 * @param {Function} callback
 */
export const getTotalAssetCount = (params, countFor) => {
	return async dispatch => {
		try {
			const url = GET_TOTAL_ASSET_COUNT;
			const response = await ApiCalls.post(url, params);

			const totalAssetCount = response && response.data
			// if (countFor === 'provider') {
			// 	dispatch({ type: ACTION_TYPES.SET_PROVIDER_COUNT, payload: totalAssetCount, })
			// }
			// if (countFor === 'status') {
			// 	dispatch({ type: ACTION_TYPES.SET_STATUS_COUNT, payload: totalAssetCount, })
			// }
			// if (countFor === 'category') {
			// 	dispatch({ type: ACTION_TYPES.SET_CATEGORY_COUNT, payload: totalAssetCount, })
			// }
			return totalAssetCount
		} catch (error) {
			return error.response && error.response.data ? error.response.data : error.response && error.response.data ? error.response.data : error.response
		}
	}
}
/**
 * Action to get daily Asset Count
 * @param {Object} body
 * @param {Function} callback
 */
export const getDailyAssetCount = (params) => {
	return async dispatch => {
		try {
			const url = GET_DAILY_ASSET_COUNT;
			const response = await ApiCalls.post(url, params);
			dispatch({ type: ACTION_TYPES.SET_ASSET_COUNT, payload: response && response.data })
			return response && response.data
		} catch (error) {
			return error.response && error.response.data ? error.response.data : error.response && error.response.data ? error.response.data : error.response
		}
	}
}
/**
 * Action to get Asset detail ext
 * @param {Object} body
 * @param {Function} callback
 */
export const getAssetDetailExt = (params) => {
	return async () => {
		try {
			const url = GET_ASSET_DETAIL_EXT;
			/// Assuming your API endpoint for the POST request is 'YOUR_API_ENDPOINT'
			const response = await ApiCalls.post(url, params);

			// You can dispatch an action with the fetched data here
			//   dispatch({ type: 'FETCH_TROUBLESHOOT_RESULTS_SUCCESS', payload: response.data });

			// Return the response data so you can use it in the component
			return response.data;
		} catch (error) {
			return error.response && error.response.data ? error.response.data : error.response && error.response.data ? error.response.data : error.response
		}
	}
}

/**
 * Action to get Asset network detail
 * @param {Object} body
 * @param {Function} callback
 */
export const getAssetNetworkDetails = (params) => {
	return async dispatch => {
		try {
			const url = SYNCHRONIZER_END_POINT + GET_ASSET_NETWORK_DETAILS;
			/// Assuming your API endpoint for the POST request is 'YOUR_API_ENDPOINT'
			const response = await ApiCalls.post(url, params);

			// You can dispatch an action with the fetched data here
			dispatch({ type: ACTION_TYPES.SET_ASSET_NETWORK_DETAILS, payload: response && response.data, })

			// Return the response data so you can use it in the component
			return response.data;
		} catch (error) {
			return error.response && error.response.data ? error.response.data : error.response && error.response.data ? error.response.data : error.response
		}
	}
}

/**
 * Action to get Asset storage detail
 * @param {Object} body
 * @param {Function} callback
 */
export const getAssetStorageDetails = (params) => {	
	return async dispatch => {
		try {
			const url = SYNCHRONIZER_END_POINT + GET_ASSET_STORAGE_DETAILS;
			/// Assuming your API endpoint for the POST request is 'YOUR_API_ENDPOINT'
			const response = await ApiCalls.post(url, params);

			// You can dispatch an action with the fetched data here
			dispatch({ type: ACTION_TYPES.SET_ASSET_STORAGE_DETAILS, payload: response && response.data, })

			// Return the response data so you can use it in the component
			return response.data;
		} catch (error) {
			return error.response && error.response.data ? error.response.data : error.response && error.response.data ? error.response.data : error.response
		}
	}
}

/**
 * Action to get Asset sg detail
 * @param {Object} body
 * @param {Function} callback
 */
export const getAssetSGDetails = (params) => {	
	return async dispatch => {
		try {
			const url = SYNCHRONIZER_END_POINT + GET_ASSET_SG_DETAILS;
			/// Assuming your API endpoint for the POST request is 'YOUR_API_ENDPOINT'
			const response = await ApiCalls.post(url, params);

			// You can dispatch an action with the fetched data here
			dispatch({ type: ACTION_TYPES.SET_ASSET_SG_DETAILS, payload: response && response.data, })

			// Return the response data so you can use it in the component
			return response.data;
		} catch (error) {
			return error.response && error.response.data ? error.response.data : error.response && error.response.data ? error.response.data : error.response
		}
	}
}
/**
//  * Action to set selected table row
//  */
// export const setSelectedTableRow = row => {
// 	return async dispatch => {
// 		dispatch({
// 			type: ACTION_TYPES.SET_SELECTED_TABLE_ROW,
// 			payload: row,
// 		})
// 	}
// }
/**
 * Action to set selected filter
 */
// export const setSelectedFilter = (filter, selectedValue) => {
// 	return async (dispatch, getState) => {
// 		let selectedFilters = getState().assets.selectedFilters
// 		selectedFilters[filter] = selectedValue
// 		dispatch({
// 			type: ACTION_TYPES.SET_SELECTED_FILTER,
// 			payload: selectedFilters,
// 		})
// 	}
// }


/**
 * Action to set resource props details
 */
 export const setResourcePropsDetails = (key, value) => {
	return async (dispatch, getState) => {
		const resourcesPropsDetails = JSON.parse(JSON.stringify(getState().assets.resourcesPropsDetails))
		resourcesPropsDetails[key] = value
		
		dispatch({
			type: ACTION_TYPES.SET_RESOURCES_PROPS_DETAILS,
			payload: resourcesPropsDetails,
		})
	}
}

/**
 * Action to list-cluster-names
 * @param {Object} body
 * @param {Function} callback
 */
export const listClusterNames = (params) => {
	return async dispatch => {
		try {
			const url = LIST_CLUSTER_NAMES;
			/// Assuming your API endpoint for the POST request is 'YOUR_API_ENDPOINT'
			const response = await ApiCalls.post(url, params);

			// You can dispatch an action with the fetched data here
			// dispatch({ type: ACTION_TYPES.SET_ASSET_SG_DETAILS, payload: response && response.data, })

			// Return the response data so you can use it in the component
			return response.data;
		} catch (error) {
			return error.response && error.response.data ? error.response.data : error.response && error.response.data ? error.response.data : error.response
		}
	}
}

/**
 * Action to get all assets list
 * @param {Object} body
 * @param {Function} callback
 */
export const listAssetsNew = (params) => {
	
	return async dispatch => {		
		try {
			const url = LIST_ASSETS;
			const response = await ApiCalls.post(url, params)

			dispatch({ type: ACTION_TYPES.SET_ASSETS_LIST, payload: response.data, })
			
			return response && response.data
		} catch (error) {
			return error.response && error.response.data ? error.response.data : error.response && error.response.data ? error.response.data : error.response
		}
	}
}