import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux'; // Import useDispatch hook to dispatch actions
import { setCommonPropsDetails } from '../../actions/commonActionNew'
import { getJobStatusApis, listJobDetails, cancelJob } from '../../actions/Collider/JobStatusAction'
import _ from 'lodash'
import { momentDateGivenFormat, momentConvertionLocalToUtcTime, momentConvertionUtcToLocalTime, currentLocaltime, numberFormater, capitalizeTheFirstLetterOfEachWord, capitalizeFirstLetter, convertToLowerCase } from '../../utils/utility'

import { CSVLink } from 'react-csv';

import ApexStackedBarChart from '../common/charts/ApexStackedBarChart'
import ApexBarChart from '../common/charts/ApexBarChart'
import ApexDonutChart from '../common/charts/ApexDonutChart'
import ResizeableDarkThemeTable from '../designComponents/Table/ResizeableDarkThemeTable'

import JobDetailsSidePanel from './JobDetailsSidePanel'
import Search from '../common/SearchWithHiddenInput'
import CreateJobPanel from './CreateJobPanel';
import AdvancedFilters from './AdvancedFilters';
import { Store as CommonNotification } from 'react-notifications-component';
import FilterSection from '../common/FiltersSection';
import {Icon} from "@iconify/react";
import MultiSelectSection from '../common/MultiSelectSection';

let refreshPage = null
const Dashboard = () => {
    const clickOutside = useRef()
    // State variables for filters
    const [state, setState] = useState({
        showLoading: true,
        selectedDuration: 'currentMonth',
        graphType: [{label:'Job', value:'job_name'}, {label:'User', value:'user_name'}],
        selectedGraphType: 'job_name',
        topSectionFilters: ['provider', 'project', 'account', 'region', 'cluster', 'duration']
    })

    const providers = useSelector(state => state?.filters?.providers || false);
    const dispatch = useDispatch(); // Create a dispatch function

    useEffect(() => {
        if(state.showLoading) {
            setTimeout(() => { setState(prevState => ({ ...prevState,  showLoading: false })) }, 5000);
        }
    }, [state.showLoading])

    // useEffect(() => {
    //     if(providers) {
    //         setState(prevState => ({ ...prevState, providers, selectedProvider: providers && providers.length ? (providers.filter(e => e.provider_name === 'AWS').length ? 'AWS' : providers[0].provider_name) : '', callSearch: true }));
    //     }
    // }, [providers])
    
    useEffect(() => {
       	let datePickerStartDate = ''
        let datePickerEndDate = ''
        if(state.selectedDuration === "currentMonth") {
            datePickerStartDate = momentDateGivenFormat(new Date(), 'YYYY-MM-01 00:00:00')
            datePickerEndDate = momentDateGivenFormat(new Date(), 'YYYY-MM-DD 23:59:59')
        }
        setState(prevState => ({ ...prevState, datePickerStartDate, datePickerEndDate }));
        
	}, [state.selectedDuration])
            
    const handleSelectedFilters = (filter) => {
        setState(prevState => ({ 
            ...prevState,
            selectedProvider: filter.selectedProvider,
            selectedProject: filter.selectedProject,
            selectedProjectTags: filter.selectedProjectTags,
            selectedAccount: filter.selectedAccount ? filter.selectedAccount : [],
            selectedRegion: filter.selectedRegion ? filter.selectedRegion : [],
            selectedClusters: filter.selectedClusters ? filter.selectedClusters : [],
            selectedQueue: filter.selectedQueue ? filter.selectedQueue : [],
            selectedUser: filter.selectedUser ? filter.selectedUser : [],
            datePickerStartDate: filter.datePickerStartDate,
            datePickerEndDate: filter.datePickerEndDate,
            callSearch: true
        }))
    }

    useEffect(() => {
        if(state.callSearch) {
            if(state.selectedProvider && state.datePickerStartDate) {
                clearTimeout(refreshPage)
                setState(prevState => ({ ...prevState, showLoading: true, callSearch: false, callGetJobStatusApisFunction: true, callListJobDetailsFunction: true }))
                // setTimeout(() => { setState(prevState => ({ ...prevState, showLoading: false })) }, 4000);
                
                const delayInMilliseconds = 5 * 60 * 1000; // 5 minutes
                if(state.selectedDuration !== 'custom' && state.selectedDuration !== 'lastMonth') {
                    refreshPage = setTimeout(() => setState(prevState => ({ ...prevState, callSearch: true })), delayInMilliseconds)
                }
            }
        }
    }, [state.callSearch, state.selectedProvider, state.datePickerStartDate, state.selectedDuration])

    const onReset = () => {
        setState(prevState => ({ ...prevState, selectedProvider: providers && providers.length ? (providers.filter(e => e.provider_name === 'AWS').length ? 'AWS' : providers[0].provider_name) : "", selectedProject: '', selectedProjectTags: [], selectedAccount: [], selectedRegion: [], selectedDuration: 'currentMonth', datePickerStartDate: momentDateGivenFormat(new Date(), 'YYYY-MM-01 00:00:00'), datePickerEndDate: momentDateGivenFormat(new Date(), 'YYYY-MM-DD 23:59:59'), callSearch: true }));
    }

    useEffect(() => {
        if(state.callGetJobStatusApisFunction) {
            let filterParams = {}
            if(state.selectedProvider && state.selectedProvider !== '') {
                filterParams.provider = state.selectedProvider.toLowerCase()
            }
            if(state.selectedProjectTags && state.selectedProjectTags.length) {
                filterParams.project_tags = state.selectedProjectTags
            }
            if(state.selectedAccount && state.selectedAccount.length && !state.selectedAccount.includes("All")) {
                filterParams.account_id = state.selectedAccount
            }
            if(state.selectedUser && state.selectedUser.length) {
                filterParams.user_name = state.selectedUser
            }
            
            if(state.selectedRegion && state.selectedRegion.length && !state.selectedRegion.includes("All")) {
                filterParams.region = state.selectedRegion
            }
            
            if(state.selectedClusters && state.selectedClusters.length && !state.selectedClusters.includes("All")) {
                filterParams.cluster_name = state.selectedClusters
            }
    
            let top = {
                'start_time': momentConvertionLocalToUtcTime(state.datePickerStartDate, 'YYYY-MM-DD HH:mm:ss'),
                'end_time': momentConvertionLocalToUtcTime(state.datePickerEndDate, 'YYYY-MM-DD HH:mm:ss'),
                'aggregate_by': [state.selectedGraphType],
                'top': 5
            }
            let topParams = {...filterParams, ...top}
    
            let trend = {
                'start_time': momentConvertionLocalToUtcTime(state.datePickerStartDate, 'YYYY-MM-DD HH:mm:ss'),
                'end_time': momentConvertionLocalToUtcTime(state.datePickerEndDate, 'YYYY-MM-DD HH:mm:ss'),
                'aggregate_by': ['status'],
                'radial': true
            }
            let trendParams = {...filterParams, ...trend}
    
            let total = { 
                'duration_aggregate_by': ['daily', 'monthly', 'weekly'],
            }
            let totalParams = {...filterParams, ...total}
    
            let params = {
                'top': topParams,
                'trend': trendParams,
                'total': totalParams
            }
    
            dispatch(getJobStatusApis(params))
                .then((response) => {
                    if(response) {
                        setState(prevState => ({
                            ...prevState,
                            statusSection: response,
                            topStatusResponse: response.top ? response.top : [],
                            statusTrendData: {},
                            topStatusData: {},
                            statusDonutData: {},
                            showLoading: false,
                            callGetJobStatusApisFunction: false
                        }));
                    }
                })
        }
    }, [state.callGetJobStatusApisFunction, dispatch, state.datePickerEndDate, state.datePickerStartDate, state.selectedAccount, state.selectedClusters, state.selectedGraphType, state.selectedProjectTags, state.selectedProvider, state.selectedRegion, state.selectedUser])


    useEffect(() => {
        if(state.callGetJobTobStatusApisFunction) {
            let filterParams = {}
            if(state.selectedProvider && state.selectedProvider !== '') {
                filterParams.provider = state.selectedProvider.toLowerCase()
            }
            if(state.selectedProjectTags && state.selectedProjectTags.length) {
                filterParams.project_tags = state.selectedProjectTags
            }
            if(state.selectedAccount && state.selectedAccount.length && !state.selectedAccount.includes("All")) {
                filterParams.account_id = state.selectedAccount
            }
            if(state.selectedUser && state.selectedUser.length) {
                filterParams.user_name = state.selectedUser
            }
            
            if(state.selectedRegion && state.selectedRegion.length && !state.selectedRegion.includes("All")) {
                filterParams.region = state.selectedRegion
            }
            
            if(state.selectedClusters && state.selectedClusters.length && !state.selectedClusters.includes("All")) {
                filterParams.cluster_name = state.selectedClusters
            }
    
            let top = {
                'start_time': momentConvertionLocalToUtcTime(state.datePickerStartDate, 'YYYY-MM-DD HH:mm:ss'),
                'end_time': momentConvertionLocalToUtcTime(state.datePickerEndDate, 'YYYY-MM-DD HH:mm:ss'),
                'aggregate_by': [state.selectedGraphType],
                'top': 5,
            }
            let topParams = {...filterParams, ...top}
    
            let params = {
                'top': topParams
            }
    
            dispatch(getJobStatusApis(params))
                .then((response) => {
                    if(response) {
                        setState(prevState => ({
                            ...prevState,
                            topStatusResponse: response.top ? response.top : [],
                            topStatusData: {},
                            selectedTopStatusFilter: '',
                            showLoading: false,
                            callTopStatusSection: true,
                            callGetJobTobStatusApisFunction: false
                        }));
                    }
                })
        }
    }, [state.callGetJobTobStatusApisFunction, dispatch, state.datePickerEndDate, state.datePickerStartDate, state.selectedAccount, state.selectedClusters, state.selectedGraphType, state.selectedProjectTags, state.selectedProvider, state.selectedRegion, state.selectedUser])

    useEffect(() => {
        if(state.statusSection && Object.entries(state.statusSection).length) {
            setState(prevState => ({
                ...prevState,
                callStatusCountSection: true,
                callStatusTrendSection: true,
                callTopStatusSection: true,
                callStatusDonutSection: true,
        }))
        }
    }, [state.statusSection])

    useEffect(() => {
        if(state.callStatusCountSection) {
            let total = 0
            state.statusSection && state.statusSection.trend && state.statusSection.trend.forEach(item => {
                total = item.total ? (item.total.Success ? item.total.Success : 0) + (item.total.Failed ? item.total.Failed : 0) : 0
            })
    
            let statusCount = 0
            let statusDailyCount = 0
            let statusWeeklyCount = 0
            let statusMonthlyCount = 0
    
            if(state.statusSection && state.statusSection.total) {
                let total = state.statusSection.total && state.statusSection.total.length ? state.statusSection.total[0] : {}
                statusCount = total.count ? total.count : 0
                statusDailyCount = total.daily && total.daily.count ? total.daily.count : 0
                statusWeeklyCount = total.weekly && total.weekly.count ? total.weekly.count : 0
                statusMonthlyCount = total.monthly && total.monthly.count ? total.monthly.count : 0
            }
    
            setState(prevState => ({ ...prevState, statusPeriodCount: total, statusCount, statusDailyCount, statusMonthlyCount, statusWeeklyCount, callStatusCountSection: false }));
        }
    }, [state.callStatusCountSection, state.statusSection])

    useEffect(() => {
        if(state.callStatusTrendSection) {
            let graphData = {}
            let totalSuccessRate = 0
            let totalFailureRate = 0
            state.statusSection && state.statusSection.trend && state.statusSection.trend.forEach(item => {
                graphData.labels = item.dates ? item.dates : []
                item.data && Object.entries(item.data).forEach(([key,value]) => {
                    if(key !== 'InProgress') {
                        graphData[key] = value
                    }
                })
    
                totalSuccessRate = item.rate && item.rate.success_rate ? item.rate.success_rate : ''
                totalFailureRate = item.rate && item.rate.failure_rate ? item.rate.failure_rate : ''
            })

            let donut = []
            let donut_total_count = 0
            
            let donutRow = {}
            donutRow.item_count = totalSuccessRate
            donutRow.item_name = "Success"
            donut.push(donutRow)
            donut_total_count += totalSuccessRate 

            donutRow = {}
            donutRow.item_count = totalFailureRate
            donutRow.item_name = "Failed"
            donut.push(donutRow)
            donut_total_count += totalFailureRate 
            
            donut = _.orderBy(donut, ['item_count'], ['desc'])
    
            let successFailureDonutData = {
                'items': donut,
                'label': 'Total',
                'total_count': donut_total_count
            }
    
            setState(prevState => ({ ...prevState, successFailureDonutData, statusTrendData: graphData, totalSuccessRate, totalFailureRate, callStatusTrendSection: false }));
        }
    }, [state.callStatusTrendSection, state.statusSection])

    useEffect(() => {
        if(state.callStatusDonutSection) {
            let donut = []
            let donut_total_count = 0
            
            state.statusSection && state.statusSection.trend && state.statusSection.trend.length && state.statusSection.trend[0].total && Object.entries(state.statusSection.trend[0].total).forEach(([key, value]) => {
                let donutRow = {}
                donutRow.item_count = value
                donutRow.item_name = key
                donut.push(donutRow)
                donut_total_count += value 
            })
            donut = _.orderBy(donut, ['item_count'], ['desc'])
    
            let statusDonutData = {
                'items': donut,
                'label': 'Total',
                'total_count': donut_total_count
            }
                
            setState(prevState => ({ ...prevState, statusDonutData, callStatusDonutSection: false }));
        }
    }, [state.callStatusDonutSection, state.statusSection])

    useEffect(() => {
        if(state.callTopStatusSection) {
            let top = state.topStatusResponse ? state.topStatusResponse : []
            let topStatusType = top.filter(e => e.label).map(e => e.label)
            topStatusType = _(topStatusType).sortBy().value()
    
            let filterData = []
            let type = state.selectedTopStatusFilter ? state.selectedTopStatusFilter : topStatusType[0]
            if(top && top.length) {
                filterData = top.filter(e => e.label === type)
                filterData = filterData.length ? filterData[0].data : []
            }
    
            let data = []
            let labels = []
            let dataUnit = ''
            let tooltip = []
    
            filterData.length && filterData.forEach(item => {
                let value = item.top && item.top.value ? item.top.value : 0
                if(type === 'RunTime' || type === 'RunTime' || type === 'RunTime') {
                    value = value.toFixed(2)
                }
                data.push(value)
                labels.push(item[state.selectedGraphType])
                dataUnit = item.top && item.top.unit ? item.top.unit : ''
                
                let tooltipRow = {}
                // tooltipRow.duration = item.duration ? unitTimeConvertion(item.duration, 'seconds') : '0  second'
                let tooltipValue = item.top && item.top.display_value ? item.top.display_value : parseInt(value)
                // if(item.top && item.top.unit && item.top.unit !== "count") {
                //     tooltipRow.unit = item.top.unit
                //     if(tooltipRow.unit === 'seconds') {
                //         tooltipValue = parseInt(value)
                //     }
                // } else if(item.top && item.top.unit && item.top.unit === "count") {
                //     tooltipValue = parseInt(value)
                // }
                tooltipRow.value = tooltipValue
                // if(item.time_saved) {
                    // tooltipRow.time_saved = item.time_saved ?  unitTimeConvertion(item.time_saved, 'seconds') : '0 second'
                // }
                tooltipRow.description = item.description ? item.description : ''
                tooltip.push(tooltipRow)
            })
    
            for(let i=filterData.length; i<5; i++) {
                data.push(0)
                labels.push(' ')
            }
    
            let graphData = {}
            graphData.data = data
            graphData.labels = labels        
            graphData.unit = dataUnit
            graphData.tooltipValues = tooltip
    
            setState(prevState => ({ ...prevState, topStatusData: graphData, selectedTopStatusFilter: type, callTopStatusSection: false, topStatusType }));
        }
    }, [state.callTopStatusSection, state.selectedTopStatusFilter, state.topStatusResponse, state.selectedGraphType])

    useEffect(()  => {
        if(state.callListJobDetailsFunction || state.callListJobDetailsRefresh) {
            setState(prevState => ({ ...prevState, callListJobDetailsFunction: false, callListJobDetailsRefresh: false  }));
            let params = {}
            if(state.selectedProvider) {
                params.provider = state.selectedProvider.toLowerCase()
            }
            if(state.selectedProjectTags && state.selectedProjectTags.length) {
                params.project_tags = state.selectedProjectTags
            }
            
            if(state.selectedAccount && state.selectedAccount.length && !state.selectedAccount.includes("All")) {
                params.account_id = state.selectedAccount
            }
            
            if(state.selectedRegion && state.selectedRegion.length && !state.selectedRegion.includes("All")) {
                params.region = state.selectedRegion
            }
            
            if(state.selectedClusters && state.selectedClusters.length && !state.selectedClusters.includes("All")) {
                params.cluster_name = state.selectedClusters
            }
            params.start_time = momentConvertionLocalToUtcTime(state.datePickerStartDate, 'YYYY-MM-DD HH:mm:ss')
            if(state.callListJobDetailsRefresh) {
                params.end_time = momentConvertionLocalToUtcTime(currentLocaltime(), 'YYYY-MM-DD HH:mm:ss')
            } else {
                params.end_time = momentConvertionLocalToUtcTime(state.datePickerEndDate, 'YYYY-MM-DD HH:mm:ss')
            }
            if(state.selectedUser && state.selectedUser.length) {
                params.user_name = state.selectedUser
            }

            if(state.selectedStatus) {
                params.status = state.selectedStatus
            }

            if(state.nextToken && state.nextToken.length) {
                params.next_token = state.nextToken
            }

            // params.size = 5
    
            dispatch(listJobDetails(params))
                .then((response) => {
					if(response) {
                        let totalResults = []
                        let startRecord = state.startRecord
                        let currentPage = state.currentPage 
                        if(state.nextToken && state.nextToken.length) {
                            totalResults = state.jobDetails &&  state.jobDetails.length ? state.jobDetails : []
                            startRecord = startRecord + state.perPage;
                            currentPage = currentPage+ 1
                        }

                        let results = response && response.results ? response.results : []
                        if(results && results.length) {
                            totalResults = totalResults.concat(results)
                        }
                        
                        let json = response.job_details ? response.job_details : []

                        let detailsFormat = _.orderBy(json, ['order'], ['asc'])                        
                        setState(prevState => ({ ...prevState, jobDetails: totalResults, filteredJobDetails: totalResults, startRecord, currentPage,  selectedFilters: params, detailsFormat, nextToken: response.next_token, totalRecords: response.total, refreshLoading: false }));
                    } else {
                        setState(prevState => ({ ...prevState, callListJobDetailsFunction: false, callListJobDetailsRefresh: false, refreshLoading: false }))
                    }
            })
        }
    }, [state.callListJobDetailsFunction, state.callListJobDetailsRefresh, dispatch, state.datePickerEndDate, state.datePickerStartDate, state.selectedAccount, state.selectedProvider, state.selectedRegion, state.selectedClusters, state.nextToken, state.currentPage, state.jobDetails, state.perPage, state.selectedStatus, state.startRecord, state.selectedUser, state.selectedProjectTags])

    useEffect(() => {
        let dataResult = state.filteredJobDetails && state.filteredJobDetails.length ? state.filteredJobDetails : [];
		if(dataResult.length) {
			let headers = []
			Object.entries(dataResult[0]).forEach(([key, value]) => {
				if(typeof value === 'string') {
					let headerRow = {}
					headerRow.label = capitalizeTheFirstLetterOfEachWord(key)
					headerRow.key = key
					headers.push(headerRow)
				}
			})

			setState(prevState => ({ ...prevState, jobheaders: headers }))
		}
    }, [state.jobDetails, state.filteredJobDetails])

    const handleChildClick = (event, type, dropdownType, section) => {
        if (clickOutside.current && !clickOutside.current.contains(event.target)) {
            dispatch(setCommonPropsDetails('clickedonParent', true))
        }

        event.stopPropagation();
        let clickedChild = []
        if(type === 'child') {
            if(state.clickedChild && state.clickedChild.length) {
                state.clickedChild.forEach(row => {
                    setState(prevState => ({ ...prevState, [row]: false }))
                })
            }
            clickedChild.push(section)
            setState(prevState => ({ ...prevState, [section]: dropdownType === "singleDropDown" && state[section] ? false : true, clickedChild }))
        } else if(type === 'parent') {
            if(state.clickedChild && state.clickedChild.length) {
                state.clickedChild.forEach(row => {
                    setState(prevState => ({ ...prevState, [row]: false }))
                })
            }
            
            setState(prevState => ({ ...prevState, clickedChild }))
        }
    }
    
    const cancelJobFunction = () => {
        setState(prevState => ({ ...prevState, showCancelLoding: true }))
        let params = state.cancelJobDetails
        dispatch(cancelJob(params))
            .then((response) => {
                if(response) {
                    let messageType = 'danger'
                    let message = response && response.message ? response.message : "Error in deleting"

                    if(response.status) {
                        messageType = 'success'
                        setTimeout(() => { setState(prevState => ({ ...prevState, callSearch: true, cancelModal: !state.cancelModal, showCancelLoding: false }))}, 1000)
                    } else {
                        setState(prevState => ({ ...prevState, showCancelLoding: false }))
                    }


                    CommonNotification.addNotification({
                        //title: 'Wonderful!',
                        message: message,
                        type: messageType,
                        insert: 'top',
                        container: 'top-center',
                        // animationIn: ['animate__animated', 'animate__fadeIn'],
                        // animationOut: ['animate__animated', 'animate__fadeOut'],
                        dismiss: {
                            duration: 5000,
                            onScreen: false,
                            pauseOnHover: true,
                            showIcon: true,
                        }
                    });
                } else {
                    setState(prevState => ({ ...prevState, showCancelLoding: false }))
                }
            })
    }

    useEffect(() => {
        if(state.selectedJobDetails && Object.entries(state.selectedJobDetails).length && !state.showJobDetails) {
            setState(prevState => ({ ...prevState, showJobDetails: true }))
        }
    }, [state.selectedJobDetails, state.showJobDetails])

    return (
        <div onClick={(event) => handleChildClick(event, 'parent')}>
            <div className={`${state.showLoading ? '' : 'hidden'}`}>
                <div className='loading-wave'>
                    <div className='overlayEqualizerLoader'>
                        <div className="loading-bar"></div>
                        <div className="loading-bar"></div>
                        <div className="loading-bar"></div>
                        <div className="loading-bar"></div>
                    </div>
                </div>
            </div>
            {state.showJobDetails ? (
                <JobDetailsSidePanel 
                    closeSidePanel={() => setState(prevState => ({...prevState, showJobDetails: false, selectedJobDetails: {} }))}
                    detailsFormat={state.detailsFormat}
                    selectedJobDetails={state.selectedJobDetails} 
                />
            ) : null}
            {state.showCreateJob ? (
                <CreateJobPanel 
                    closeSidePanel={() => setState(prevState => ({...prevState, showCreateJob: false }))}
                />
            ) : null}
            {state.showAdvancedFilters ?
                <AdvancedFilters
                    selectedFilters={(filter) => {
                        // handleSelectedFilters(filter)
                        dispatch(setCommonPropsDetails('filterSelectedFilters', filter))
                        setState(prevState => ({ ...prevState, showAdvancedFilters: false }))
                    }}
                    filterState={state}
                    closeSidePanel={() => setState(prevState => ({...prevState, showAdvancedFilters: false }))}
                />
            : null}                
            <div className="bg-nero w-full h-fit relative stickyUiChange">
                <div className="flex flex-wrap pb-4">
                    <div className="lg:w-1/5 w-full self-center xl:mt-4">
                        <p className="text-white mx-4 text-xl md:mt-0 mt-4 font-semibold">Job Status</p>
                        <small className="mx-4 text-white">Consolidated view of job status</small>
                    </div>
                    <div className="lg:w-4/5 w-full flex flex-wrap justify-end lg:mt-4 mt-6" ref={clickOutside}>
                        <FilterSection
                            filters={state.topSectionFilters}
                            selectedFilters={(filter) => handleSelectedFilters(filter)}
                            clickedonParent={state.clickedonParent}
                            filterAdvancedSearch={true}
                            onClickAdvancedSearch={() => setState(prevState => ({ ...prevState, showAdvancedFilters: true }))}
                            onReset={onReset}
                            screen="job_status"
                        />
                    </div>
                </div>
            </div>
            <div className="flex flex-wrap mt-4 items-center overflow-hidden justify-center">
                <div className="2xl:w-1/4 xl:w-1/4 lg:w-1/4 w-full flex xl:justify-end justify-center xl:ml-0">
                    <div className="flex xl:flex-row flex-col gap-6">
                        <div className="flex flex-row gap-4 items-center xl:justify-start justify-center">
                            {state.successFailureDonutData && Object.entries(state.successFailureDonutData).length ?
                                <div className='ml-5'>
                                    <ApexDonutChart
                                        graphData={state.successFailureDonutData}
                                        legend={false}
                                        showTotal={true}
                                        showTotalLable={true}
                                        totalLableType={'integer'}
                                        showGraphHeading={false}
                                        height={75}
                                        width={75}
                                        legendWidth={200}
                                        size={'70%'}
                                        gradient={false}
                                        gradientColor={['#A88CCC', '#CD5A9F']}
                                        formatedColors={{'failed': '#CD5A9F', 'success': '#009FDF'}}
                                        colors={['#8e3a80', '#CD5A9F']}
                                        className={'transparentTooltip'}
                                    />
                                </div>
                            : null}
                            <div className="flex flex-col gap-2">
                                <div className="flex flex-row gap-2 items-center">
                                    <div className="w-2 h-2 rounded-full bg-info"></div>
                                    <p className="text-sm text-info whitespace-nowrap">Success : {state.totalSuccessRate && state.totalSuccessRate ? state.totalSuccessRate : 0}%</p>
                                </div>
                                <div className="flex flex-row gap-2 items-center">
                                    <div className="w-2 h-2 rounded-full bg-frolyDark"></div>
                                    <p className="text-sm text-frolyDark whitespace-nowrap">Failed : {state.totalFailureRate && state.totalFailureRate ? state.totalFailureRate : 0}%</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="2xl:3/4 xl:w-3/4 lg:w-3/4 w-full lg:mt-0 mt-3 lg:pl-4 md:mx-2 lg:mx-0 overflow-x-auto">
                    <div className="flex flex-row gap-4 items-center lg:w-auto md:w-auto w-full">
                        {state.statusSection && state.statusSection.trend && state.statusSection.trend.length && state.statusSection.trend[0].total ? 
                            Object.entries(state.statusSection.trend[0].total).map(([key, value], index) => {
                                return(
                                    <div key={'key__'+index}>
                                        {convertToLowerCase(key) === "completed" || convertToLowerCase(key) === "finished" ?
                                            <div className={`border border-cyon bg-transparent min-w-24 h-20 rounded-md flex justify-center items-center p-3 ${state.selectedStatus === key ? 'bg-cyon text-white' : 'text-cyon'} cursor-pointer`} onClick={() => setState(prevState => ({ ...prevState, selectedStatus: state.selectedStatus !== key ? key : '', callListJobDetailsFunction: true }))}>
                                                <div className="flex flex-col justify-between items-center">
                                                    <p className='mb-0 text-sm'>{numberFormater(value)}</p>
                                                    <small className='mb-0 text-sm'>{capitalizeFirstLetter(key.toLowerCase())}</small>
                                                </div>
                                            </div>

                                        :
                                            <div className={`border border-mulbery bg-transparent min-w-24 h-20 rounded-md flex justify-center items-center p-3 ${state.selectedStatus === key ? 'bg-mulbery text-white' : 'text-mulbery'} cursor-pointer`} onClick={() => setState(prevState => ({ ...prevState, selectedStatus: state.selectedStatus !== key ? key : '', callListJobDetailsFunction: true }))}>
                                                <div className="flex flex-col justify-between items-center">
                                                    <p className='mb-0 text-sm'>{numberFormater(value)}</p>
                                                    <small className='mb-0 text-sm'>{capitalizeFirstLetter(key.toLowerCase())}</small>
                                                </div>
                                            </div>
                                        }
                                    </div>
                                )
                            })
                        : null}
                        {/* convertToLowerCase(key) === "completed" ?
                            <div key={'key__'+index} className="border border-cyon min-w-24 h-20 rounded-md flex justify-center items-center text-cyon p-3">
                                <div className="flex flex-col justify-between items-center">
                                    <p className="text-xs">{numberFormater(value)}</p>
                                    <p className="text-xs">{capitalizeFirstLetter(key.toLowerCase())}</p>
                                </div>
                            </div>
                        : 
                            <div key={'key__'+index} className="border border-frolyDark min-w-24 h-20 rounded-md flex justify-center items-center text-frolyDark p-3">
                                <div className="flex flex-col justify-between items-center">
                                    <p className="text-xs">{numberFormater(value)}</p>
                                    <p className="text-xs">{capitalizeFirstLetter(key.toLowerCase())}</p>
                                </div>
                            </div> */}
                    </div>
                </div>
            </div>
            <div className="grid grid-cols-12 mt-4 gap-4 items-center md:overflow-y-auto overflow-hidden">
                <div className="2xl:col-span-12 xl:col-span-12 col-span-12 flex xl:justify-end justify-end xl:ml-0 ml-5">
                    <p className="text-white text-sm me-4">
                        Last refreshed at <span className="text-info">{momentDateGivenFormat(new Date(), "HH:mm")} (local time)</span>
                    </p>
                </div>
            </div>
            <div className="grid grid-cols-12 ml-5 mt-3 md:me-0 me-3 gap-4">
                <div className="lg:col-span-6 col-span-12 md:me-0">
                    <div className="bg-black w-full h-full rounded-md p-3">
                        <div className="flex justify-between items-center md:flex-row flex-col">
                            <div className="flex flex-row lg:gap-6 gap-1">
                                <div className="flex flex-col gap-1 items-center"id='statusView_total'>
                                    <div className="lg:w-12 lg:h-12 w-8 h-8 rounded-md flex justify-center bg-successlight text-[#05c3de] items-center">{numberFormater(state.statusCount ? state.statusCount : 0)}</div>
                                    <p className="text-gray4 text-xs">Total</p>
                                </div>
                                {/* <UncontrolledTooltip target='statusView_total'>Total: {state.statusCount ? state.statusCount : 0}</UncontrolledTooltip> */}
                                <div className="flex flex-col gap-1 items-center">
                                    <div className="lg:w-12 lg:h-12 w-8 h-8 rounded-md flex justify-center bg-successlight text-[#05c3de] items-center">{numberFormater(state.statusMonthlyCount ? state.statusMonthlyCount : 0)}</div>
                                    <p className="text-gray4 text-xs">Current</p>
                                    <p className="text-gray4 text-xs">Month</p>
                                </div>
                                <div className="flex flex-col gap-1 items-center">
                                    <div className="lg:w-12 lg:h-12 w-8 h-8 rounded-md flex justify-center bg-successlight text-[#05c3de] items-center">{numberFormater(state.statusWeeklyCount ? state.statusWeeklyCount : 0)}</div>
                                    <p className="text-gray4 text-xs">Current</p>
                                    <p className="text-gray4 text-xs">Week</p>
                                </div>
                                <div className="flex flex-col gap-1 items-center">
                                    <div className="lg:w-12 lg:h-12 w-8 h-8 rounded-md flex justify-center bg-successlight text-[#05c3de] items-center">{numberFormater(state.statusDailyCount ? state.statusDailyCount : 0)}</div>
                                    <p className="text-gray4 text-xs">Daily</p>
                                </div>
                            </div>
                            {state.statusDonutData && Object.entries(state.statusDonutData).length ?
                                <div className='ml-5'>
                                    <ApexDonutChart
                                        graphData={state.statusDonutData}
                                        legend={false}
                                        showTotal={true}
                                        showTotalLable={true}
                                        totalLableType={'integer'}
                                        showGraphHeading={false}
                                        height={75}
                                        width={75}
                                        legendWidth={200}
                                        size={'70%'}
                                        gradient={false}
                                        gradientColor={['#A88CCC', '#D88ACF']}
                                        // colors={['#ffadad', '#ffd6a5',	'#caffbf',	'#9bf6ff']}
                                        formatedColors={{'cancelled': '#8e3a80', 'completed': '#43B02A', 'failed': '#c6007e', 'stopped':  '#CD5A9F', 'suspended': '#693EBC', 'timeout': '#775BA2'}}
                                        // formatedColors={{'cancelled': '#FFCD00', 'completed': '#43B02A', 'failed': '#D2291C', 'stopped':  '#ff8200', 'suspended': '#039BE5', 'timeout': '#df5667'}}
                                        colors={['#FFCD00', '#ff8200', '#d2291c', '#43b02a', '#0057b8', '#8e3a80']}
                                        className={'transparentTooltip'}
                                    />
                                </div>
                            : null}
                        </div>
                        <div className="w-full">
                            {state.statusTrendData && Object.entries(state.statusTrendData).length ?
                                <div className='mt-n5 mb-n3'>
                                    <ApexStackedBarChart 
                                        graphData={state.statusTrendData ? state.statusTrendData : {}}
                                        sparkline={false}
                                        yaxis={false}
                                        yaxisLabel={true}
                                        xaxis={true}
                                        xaxisFormat={'date'}
                                        xaxisLabel={true}
                                        axisLabelColor={'#B8BBBE'}
                                        paddingLeft={0}
                                        legend={true}
                                        stacked={true}
                                        height={240}
                                        horizontal={false}
                                        barHeight={'40%'}
                                        barEndShape={'flat'}
                                        columnWidth={'25%'}
                                        formatedColors={{'cancelled': '#8e3a80', 'completed': '#43B02A', 'failed': '#c6007e', 'stopped':  '#CD5A9F', 'suspended': '#693EBC', 'timeout': '#775BA2'}}
                                        colors={['#FFCD00', '#ff8200', '#d2291c', '#43b02a', '#0057b8', '#8e3a80']}
                                        gradient={true}
                                        gradientColor={['#039BE5', '#5F5BA2']}
                                        hideTooltipValue={true}
                                        backgroundBarShape={'flat'}
                                        backgroundBarColors={['#333947']}
                                        showBackgroundBarColors={false}
                                        className={'transparentTooltip'}
                                        showTooltipTotal={true}
                                    />
                                </div>
                            : null}
                        </div>
                    </div>
                </div>
                <div className="lg:col-span-6 col-span-12 md:me-4">
                    <div className="bg-black w-full h-full rounded-md p-3">
                        <div className="flex justify-between items-start md:flex-row flex-col">
                            <div className="flex flex-col  gap-2">
                                <p className="text-sm text-white">Top 5 Jobs</p>
                                <p className="text-xs text-white">Consolidated view of job status</p>
                                <small className='mt-1 mb-0 small text-lightGray'>View top 5 hpc jobs by selection - {state.selectedTopStatusFilter}</small>
                            </div>

                            <div className='flex'>
                                <div className='min-w-32'
                                    onClick={(event) => {
                                        if(!state.isGraphType) {
                                            event.preventDefault();
                                            handleChildClick(event, 'child', 'singleDropDown', "isGraphType")
                                        }
                                    }}
                                >
                                    <p className={`flex border border-lightGray rounded-md bg-transparent cursor-pointer px-2 py-1 justify-between ${state.selectedGraphType ? 'text-white' : 'text-lightGray'}`}>
                                        {state.selectedGraphType ? state.selectedGraphType : 'Select'}
                                        <Icon icon="icon-park-solid:down-one" className={`${state.selectedGraphType ? "text-white" : "text-lightGray"} self-center`} width="16" height="16" />
                                    </p>
                                    {state.isGraphType ?
                                        <div className='relative'>
                                            <MultiSelectSection 
                                                fields={["value", "label"]}
                                                options={state.graphType}
                                                widthClass={'minWidth220'}
                                                selectedValues={state.selectedGraphType ? state.selectedGraphType : ''}
                                                callbackMultiSelect={(value) => {
                                                    if(!value || typeof(value) === 'string') {
                                                        setState(prevState => ({ ...prevState, selectedGraphType: value, callGetJobTobStatusApisFunction: true }))
                                                    } else {
                                                        value.preventDefault()
                                                        handleChildClick(value, "search", 'singleDropDown', "")
                                                    }
                                                }}
                                                singleSelection={true}
                                                hideSearch={true}
                                                removeTopOptions={true}
                                            />
                                        </div>
                                    : null}
                                </div>
                                <div className='min-w-32 pl-3'
                                    onClick={(event) => {
                                        if(!state.isStatusOpen) {
                                            event.preventDefault();
                                            handleChildClick(event, 'child', 'singleDropDown', "isStatusOpen")
                                        }
                                    }}
                                >
                                    <p className={`flex border border-lightGray rounded-md bg-transparent cursor-pointer px-2 py-1 justify-between ${state.selectedTopStatusFilter ? 'text-white' : 'text-lightGray'}`}>
                                        {state.selectedTopStatusFilter ? state.selectedTopStatusFilter : 'Select'}
                                        <Icon icon="icon-park-solid:down-one" className={`${state.selectedTopStatusFilter ? "text-white" : "text-lightGray"} self-center`} width="16" height="16" />
                                    </p>
                                    {state.isStatusOpen ?
                                        <MultiSelectSection 
                                            // fields={["value", "label"]}
                                            options={state.topStatusType}
                                            widthClass={'minWidth220'}
                                            selectedValues={state.selectedTopStatusFilter ? state.selectedTopStatusFilter : ''}
                                            callbackMultiSelect={(value) => {
                                                if(!value || typeof(value) === 'string') {
                                                    setState(prevState => ({ ...prevState, selectedTopStatusFilter: value, showTopStatusFilter: false, topStatusData: {}, callTopStatusSection: true }))
                                                } else {
                                                    value.preventDefault()
                                                    handleChildClick(value, "search", 'singleDropDown', "")
                                                }
                                            }}
                                            singleSelection={true}
                                            className={'right-0'}
                                            hideSearch={true}
                                            removeTopOptions={true}
                                            
                                        />
                                    : null}
                                </div>
                            </div>
                        </div>
                        <div className="w-full">                            
                            {state.topStatusData && Object.entries(state.topStatusData).length ?
                                <div className=''> 
                                    <ApexBarChart
                                        graphData={state.topStatusData}
                                        screen={'job_status'}
                                        sparkline={false}
                                        yaxis={true}
                                        yaxisLabel={true}
                                        xaxis={true}
                                        xaxisFormat={'string'}
                                        xaxisLabel={true}
                                        axisLabelColor={'#999999'}
                                        paddingLeft={0}
                                        legend={false}
                                        stacked={false}
                                        height={270}
                                        horizontal={true}
                                        barHeight={'40%'}
                                        barEndShape={'rounded'}
                                        barRadius={8}
                                        columnWidth={'25%'}
                                        showTitle={true}
                                        //'#FFCD00', '#ff8200', '#d2291c', '#43b02a', '#0057b8', '#8e3a80'
                                        gradient={true}
                                        gradientColor={['#0057b8', '#8e3a80']}
                                        hideTooltipValue={true}
                                        // backgroundBarShape={'F'}
                                        backgroundBarColors={['#333947']}
                                        showBackgroundBarColors={false}
                                        className={'transparentTooltip'}
                                        dataLabels={true}
                                        dataLabelsTextAnchor={'middle'}
                                        dataLabelPosition={''}
                                    />
                                </div>
                            : null}
                        </div>
                    </div>
                </div>
            </div>
            <div className="bg-white me-4 ml-5 my-4 p-3 block">
                <div className="flex justify-between lg:items-center lg:flex-row flex-col">
                    <div>
                        <p className="text-black text-md font-bold flex flex-row gap-3">
                            Jobs List
                            <span className="text-sm text-info flex flex-row items-center cursor-pointer" onClick={() => setState(prevState => ({ ...prevState, nextToken: [], refreshLoading: true, callListJobDetailsRefresh: true }))}>
                                <Icon icon="material-symbols-light:refresh" color="#039be5" width="16" height="16" />
                                refresh
                            </span>
                        </p>
                        <small className="text-xs">
                            Showing {state.filteredJobDetails && state.filteredJobDetails.length} out of total {state.jobDetails && state.jobDetails.length} job(s)
                        </small>
                        {state.selectedFilters && state.selectedFilters.provider ?
                            <small className="ml-1 text-xs">obtained from the <span className="text-info"> {state.selectedFilters.provider.toUpperCase()} </span> provider</small>
                        : null}
                        {state.selectedFilters && state.selectedFilters.account_id && state.selectedFilters.account_id.length ?
                            <small className="ml-1 text-xs">
                                with the account 
                                <span className="ml-1 text-info"> {state.selectedFilters.account_id[0]} </span> 
                                {state.selectedFilters.account_id.length > 1 ?
                                    <span className="ml-1 text-info">more</span>
                                : null}
                            </small>
                        : null}
                        {state.selectedFilters && state.selectedFilters.region && state.selectedFilters.region.length ?
                            <small className="ml-1 text-xs">
                                in the
                                <span className="ml-1 text-info"> {state.selectedFilters.region[0]} </span> 
                                {state.selectedFilters.region.length > 1 ?
                                    <span className="ml-1 text-info">more</span>
                                : null}
                                <span className="ml-1">region</span>
                            </small>
                        : null}
                        {state.selectedFilters && state.selectedFilters.start_time ?
                            <small className="ml-1 text-xs">for the period starting from 
                                <span className="ml-1 text-info">{momentConvertionUtcToLocalTime(state.selectedFilters.start_time, 'YYYY-MM-DD HH:mm')}</span> to <span className="text-info">{momentConvertionUtcToLocalTime(state.selectedFilters.end_time, 'YYYY-MM-DD HH:mm')}</span> (local time)
                            </small>
                        : null}
                    </div>
                    {!state.refreshLoading ?
                        <div className='self-center'>
                            <div className='flex justify-end'>
                                {state.filteredJobDetails && state.filteredJobDetails.length ?
                                <div className='self-center mr-2'>
                                    <CSVLink 
                                        data={state.filteredJobDetails ? state.filteredJobDetails : []} 
                                        headers={state.jobheaders ? state.jobheaders : []}
                                        filename={'job-lists.csv'}
                                        className={'self-center'}
                                        target='_blank'
                                    >
                                        <span className='flex'>
                                            <Icon icon="material-symbols:download" className='text-gray5 self-center' width="22" height="22"/>
                                            {/* <i className='fas fa-download text-lightGray mr-2'/> */}
                                            {/* csv */}
                                        </span>
                                    </CSVLink>
                                    </div>
                                : null}
                                {state.jobDetails && state.jobDetails.length ?
                                    <div className='self-center mr-2'>
                                        <Search
                                            data={state.jobDetails ? state.jobDetails : []}
                                            applyTags={false}
                                            applyLiteDarkTags={true}
                                            topClassName={'text-black borde-0'}
                                            searchClassName={'px-2'}
                                            searchIconColor={'text-black '}
                                            searchPlaceHolder={'Search....'}
                                            className={'bg-gray1 text-black'}
                                            widthHeight={25}
                                            filteredData={(filteredJobDetails) => setState(prevState => ({ ...prevState, filteredJobDetails }))}
                                        />
                                    </div>
                                : null}
                                <button type="button" className="bg-green hover:bg-green-700 text-white ml-2 font-bold px-2 py-1 rounded inline-flex items-center self-center" onClick={() => setState(prevState => ({ ...prevState, showCreateJob: true }))}>
                                    <span className='text-white'>Create&nbsp;Job</span>
                                </button>
                            </div>                                    
                        </div>
                    : null}
                </div>
                {state.refreshLoading ?
                    <div className="flex justify-center min-h-48">
                        <div className="relative overflow-x-auto mt-3">
                            <svg className="animate-spin h-5 w-5 mr-3 text-black" fill="currentColor" viewBox="0 0 24 24">
                                <circle cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="2" fill="none" />
                                <path d="M4 12a8 8 0 0112-6.9" />
                            </svg>
                        </div>
                    </div>
                : 
                    <div className="w-full">
                        <div className="relative overflow-x-auto mt-3">
                            {state.filteredJobDetails && state.filteredJobDetails.length ?
                                <ResizeableDarkThemeTable
                                    columns={[
                                        {
                                            Header: 'Status',
                                            accessor: 'Status',
                                            Cell: cellInfo => (
                                                <div className='flex'>
                                                    {cellInfo.row.original.status ?
                                                        <span className={`border border-${convertToLowerCase(cellInfo.row.original.status)} w-fit h-fit rounded-lg text-xs text-${convertToLowerCase(cellInfo.row.original.status)} font-medium px-2 flex justify-center items-center`}>{cellInfo.row.original.status}</span>
                                                    : 
                                                        <span className={`badge badge-outline-secondary`}>No Status</span>
                                                    }
                                                    {cellInfo.row.original.error_code ?
                                                        <span className={`ml-2 text-info cursor-pointer fa fa-info-circle self-center`} onClick={() => setState(prevState => ({ ...prevState, errorJobDetails: cellInfo.row.original, errorModal: true }))}></span>
                                                    : null}
                                                </div>
                                                // cellInfo.row.original.status ?
                                                //     <span className={`border border-${convertToLowerCase(cellInfo.row.original.status)} w-fit h-fit rounded-lg text-xs text-success font-medium px-2 flex justify-center items-center`}>{cellInfo.row.original.status}</span>
                                                // : 
                                                //     <span className={`border border-secondary w-fit h-fit rounded-lg text-xs text-success font-medium px-2 flex justify-center items-center`}>No Status</span>
                                            ),
                                        },
                                        {
                                            Header: 'Job Name',
                                            accessor: 'job_name',
                                            width: 250,
                                        },
                                        {
                                            Header: 'Job Id',
                                            accessor: 'job_id',
                                            Cell: cellInfo => (<div className='flex'>
                                                <span className='text-info cursor-pointer' onClick={() =>  setState(prevState => ({ ...prevState, selectedJobDetails: cellInfo.row.original }))}> {cellInfo.row.original.job_id ? cellInfo.row.original.job_id : ''} </span>
                                                {cellInfo.row.original.job_cancel_request ?
                                                    <span className={`ml-2 text-danger cursor-pointer`} onClick={() => setState(prevState => ({ ...prevState, cancelJobDetails: cellInfo.row.original, cancelModal: true }))}>Cancel</span>
                                                : null}
                                            </div>),
                                            width: 250,
                                        },
                                        {
                                            Header: 'Queue',
                                            accessor: 'queue_name',
                                            width: 200,
                                        },
                                        {
                                            Header: 'Cluster',
                                            accessor: 'cluster_name',
                                            width: 150,
                                        },
                                        {
                                            Header: 'User',
                                            accessor: 'user_name',
                                            width: 150,
                                        },
                                        {
                                            Header: 'Elapsed time',
                                            accessor: 'execution_time',
                                            // width: 200,
                                            // Cell: cellInfo => (<span>{cellInfo.row.original.elapsed_time_in_secs ? cellInfo.row.original.elapsed_time_in_secs : ''}</span>)
                                        },
                                        {
                                            Header: 'Start Time',
                                            accessor: 'start_time',
                                            Cell: cellInfo => (<span>{cellInfo.row.original.start_time ? momentConvertionUtcToLocalTime(cellInfo.row.original.start_time, 'DD MMM YYYY HH:mm') : ''}</span>),
                                            // width: 180,
                                        },
                                        {
                                            Header: 'End Time',
                                            accessor: 'end_time',
                                            Cell: cellInfo => (<span>{cellInfo.row.original.end_time ?  momentConvertionUtcToLocalTime(cellInfo.row.original.end_time, 'DD MMM YYYY HH:mm') : ''}</span>),
                                            // width: 180,
                                        },
                                        {
                                            Header: ' ',
                                            width: 1,
                                            // accessor: 'end_time',
                                            // Cell: cellInfo => (<span className='text-info cursor-pointer'>Details</span>)
                                        },
                                    ]}
                                    data={state.filteredJobDetails}
                                    totalData={state.totalRecords}
                                    perPage={10}
                                    tableHead={'bg-black text-white'}
                                    tableBody={'bg-white'}
                                    tableType={'table-light-hover'}
                                    perPageClass={`bg-transparent text-lightGray`}
                                    textColor={'text-black'}
                                    selectedColor={'bg-backGroundGray'}
                                    dashboard={state.filteredJobDetails && state.filteredJobDetails.length ? true : false}
                                    sortByColumn={''}
                                    onClickRow={tableRow => {}}
                                    paginationIndex={state.paginationIndex ? state.paginationIndex : 0}
                                    checkForNextSetoffRecords={state.nextToken && state.nextToken.length ? true : false}
                                    callToFetchNewRecord={(paginationIndex) => {
                                        if(state.nextToken && state.nextToken.length) {
                                            setState(prevState => ({ ...prevState, callListJobDetailsFunction: true, paginationIndex }))
                                        }
                                    }}
                                    showPaginationTop={false}
                                />
                            : 
                                <div className='flex justify-center m-4'>
                                    <p>There are no data on this criteria. Please try adjusting your filter.</p>
                                </div>
                            }
                        </div>
                    </div>
                }
            </div>
            <div id="cancel-modal" className={`flex ${state.cancelModal ? '' : 'hidden'} overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 h-[calc(100%-1rem)] max-h-full`}>
                <div className="relative p-4 w-full max-w-md max-h-full">
                    <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
                        <button type="button" className="absolute top-3 end-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white" data-modal-hide="popup-modal" onClick={() => setState(prevState => ({ ...prevState, cancelModal: false, showCancelLoding: false }))}>
                            <svg className="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
                            </svg>
                            <span className="sr-only">Close modal</span>
                        </button>
                        <div className="p-4 md:p-5 text-center">
                            <svg className="mx-auto mb-4 text-gray-400 w-12 h-12 dark:text-gray-200" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M10 11V6m0 8h.01M19 10a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"/>
                            </svg>
                            <h3 className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">Are you sure you want to cancel the running job <span className='f18 mx-1 text-red'>{state.cancelJobDetails && state.cancelJobDetails.job_name ? state.cancelJobDetails.job_name : ''}<span className='ml-1 text-lightGray'>({state.cancelJobDetails && state.cancelJobDetails.job_id ? state.cancelJobDetails.job_id : ''})</span></span>?</h3>
                            <button data-modal-hide="popup-modal" type="button" className="py-2 px-2 rounded-md text-white bg-green" onClick={() =>
                                {state.showCancelLoding ?
                                    <svg className="animate-spin h-5 w-5 mr-3 text-white" fill="currentColor" viewBox="0 0 24 24">
                                        <circle cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="2" fill="none" />
                                        <path d="M4 12a8 8 0 0112-6.9" />
                                    </svg>
                                : 
                                    cancelJobFunction()
                                }}
                            >
                                Yes, I'm sure
                            </button>
                            <button data-modal-hide="popup-modal" type="button" className="py-2 px-2 rounded-md text-white bg-red" onClick={() => {
                                if(!state.showCancelLoding) {
                                    setState(prevState => ({ ...prevState, cancelModal: !state.cancelModal, showCancelLoding: false, name: '' }))
                                }
                            }}>
                            No, cancel
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div id="error-modal" className={`flex ${state.errorModal ? '' : 'hidden'} overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 h-[calc(100%-1rem)] max-h-full`}>
                <div className="relative p-4 w-full max-w-md max-h-full">
                    <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
                        <button type="button" className="absolute top-3 end-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white" data-modal-hide="popup-modal" onClick={() => setState(prevState => ({ ...prevState, errorModal: false, showCancelLoding: false }))}>
                            <svg className="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
                            </svg>
                            <span className="sr-only">Close modal</span>
                        </button>
                        <div className="p-4 md:p-5 text-center">
                            <svg className="mx-auto mb-4 text-gray-400 w-12 h-12 dark:text-gray-200" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M10 11V6m0 8h.01M19 10a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"/>
                            </svg>
                            
                            <div className='text-md mr-1'>
                                <span className='f14 mx-1'>Error Code :</span>
                                <span className='ml-2'>{state.errorJobDetails && state.errorJobDetails.error_code ? state.errorJobDetails.error_code : ''}</span>
                            </div>
                            <div className='text-md mr-1'>
                                <span className='f14 mx-1'>Error Message :</span>
                                <span className='ml-2'>{state.errorJobDetails && state.errorJobDetails.error_reason ? state.errorJobDetails.error_reason : ''}</span>
                            </div>

                            <button data-modal-hide="popup-modal" type="button" className="py-2 px-2 rounded-md text-white bg-red" onClick={() => setState(prevState => ({ ...prevState, errorModal: !state.errorModal }))}>
                            cancel
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Dashboard