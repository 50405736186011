import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { listAllProviders, listClusterFilters, setCommonPropsDetails } from '../../../../actions/commonActionNew';
import MultiSelectSection from "../../../common/MultiSelectSection";
import {Icon} from "@iconify/react";

export default function FileFilter(props) {
    const dispatch = useDispatch();
    const [ state, setState ] = useState({})
    const closeFilters = useSelector(state => state?.filters?.commonPropsDetails?.closeFilters || false);
    const providers = useSelector(state => state?.filters?.providers || false)
    const propProjAccounts = useSelector(state => state?.filters?.commonPropsDetails?.propProjAccounts || false);
    const propProjRegions = useSelector(state => state?.filters?.commonPropsDetails?.propProjRegions || false);

    const clickedonParent = useSelector(state => state?.filters?.commonPropsDetails?.clickedonParent || false);
  
    // list all providers
    useEffect(() => {
        dispatch(setCommonPropsDetails('fileManageFilterChange', {}))
    }, [dispatch])

    useEffect(() => { 
        if(providers) {
        setState(prevState => ({ ...prevState, providers: providers, selectedProvider: providers && providers.length ? (providers.filter(e => e.provider_name === 'AWS').length ? 'AWS' : providers[0].provider_name) : "" }));
        } else {
        let params = {}
        dispatch(listAllProviders(params))
            .then((response) => {
            if(response) {
                setState(prevState => ({ ...prevState, providers: response }));
            }
            })
        }
    }, [dispatch, providers]);

    useEffect(() => {
        if(state.selectedProvider) {
        let params = {};
        params.provider = state.selectedProvider.toLowerCase();
        params.aggregate_by = 'account_id';
        let label = state.selectedProvider
    
        if(propProjAccounts && propProjAccounts.hasOwnProperty(label)) {
            setState(prevState => ({ ...prevState, accounts: propProjAccounts[label] }));
        } else {
            dispatch(listClusterFilters(params))
                .then((response) => {
                    if(response) {
                        if(response.length) {
                            let obj = propProjAccounts ? propProjAccounts : {}
                            obj[label] = response
                            dispatch(setCommonPropsDetails('propProjAccounts', obj))
                        }
                        setState(prevState => ({ ...prevState, accounts: response }));
                    }
                })
        }
        }
    }, [state.selectedProvider, propProjAccounts, dispatch])

    useEffect(() => {
        if(state.selectedProvider) {
        let params = {};
        params.provider = state.selectedProvider.toLowerCase();
        params.aggregate_by = 'region';
        let label = state.selectedProvider
        if(state.selectedAccount && state.selectedAccount.length) {
            label = ''
            params.account_id = state.selectedAccount
        }

        if(propProjRegions && propProjRegions.hasOwnProperty(label)) {
            setState(prevState => ({ ...prevState, regions: propProjRegions[label] }));
        } else {
            dispatch(listClusterFilters(params))
                .then((response) => {
                    if(response && response.length) {
                        if(label) {
                            let obj = propProjRegions ? propProjRegions : {}
                            obj[label] = response
                            dispatch(setCommonPropsDetails('propProjRegions', obj))
                        }
                        setState(prevState => ({ ...prevState, regions: response }))
                    }
                })
        }
        }
    }, [dispatch, propProjRegions, state.selectedProvider, state.selectedAccount])

    useEffect(() => {
        if(state.selectedProvider && (state.selectedAccount && state.selectedAccount.length) && (state.selectedRegion && state.selectedRegion.length)) {
            let params = {};
            params.provider = state.selectedProvider.toLowerCase();
            params.aggregate_by = 'cluster_name';
            params.account_id = state.selectedAccount
            params.region = state.selectedRegion
            dispatch(listClusterFilters(params))
                .then((response) => {
                    if(response && response.length) {
                        setState(prevState => ({ ...prevState, clusters: response }))
                    }
                })
        }
    }, [dispatch, state.selectedProvider, state.selectedAccount, state.selectedRegion])

    useEffect(() => {
        if(closeFilters) {
        setState(prevState => ({ ...prevState, isProviderOpen: false, isAccountOpen: false, isRegionOpen: false }))

        dispatch(setCommonPropsDetails('closeFilters', false))
        }
    }, [closeFilters, dispatch])

    useEffect(() => {
        if(state.selectedProvider && state.selectedAccount && state.selectedRegion && state.selectedClusters) {
            let obj = {
                selectedProvider: state.selectedProvider,
                selectedAccount: state.selectedAccount,
                selectedRegion: state.selectedRegion,
                selectedClusters: state.selectedClusters,
            }
            
            dispatch(setCommonPropsDetails('fileManageFilterChange', obj))
        }
    }, [state.selectedProvider, state.selectedAccount, state.selectedRegion, state.selectedClusters, dispatch])

    useEffect(() => {
        if(clickedonParent) {
            if(state.clickedChild && state.clickedChild.length) {
                state.clickedChild.forEach(row => {
                    setState(prevState => ({ ...prevState, [row]: false }))
                })
            }
            setState(prevState => ({ ...prevState, clickedChild: [] }))

            dispatch(setCommonPropsDetails('clickedonParent', false))
        }

    }, [clickedonParent, dispatch, state.clickedChild])

    const handleChildClick = (event, type, dropdownType, section) => {
        event.stopPropagation();
        
        let clickedChild = []
        if(type === 'child') {
            if(state.clickedChild && state.clickedChild.length) {
                state.clickedChild.forEach(row => {
                    setState(prevState => ({ ...prevState, [row]: false }))
                })
            }
            clickedChild.push(section)
            setState(prevState => ({ ...prevState, [section]: dropdownType === "singleDropDown" && state[section] ? false : true, clickedChild }))
        } else if(type === 'parent') {
            if(state.clickedChild && state.clickedChild.length) {
                state.clickedChild.forEach(row => {
                    setState(prevState => ({ ...prevState, [row]: false }))
                })
            }
            
            setState(prevState => ({ ...prevState, clickedChild }))
        }
    }

    return (
        <div className='flex flex-wrap mx-0 my-2 justify-end gap-2' onClick={(event) => handleChildClick(event, 'parent')}>
            <div className={`bg-black5 w-full h-fit p-2 text-white flex flex-col justify-between rounded-md cursor-pointer mr-3`} style={{minWidth: '120px', maxWidth: '120px'}}
                onClick={(event) => {
                    if(!state.isProviderOpen) {
                        event.preventDefault();
                        handleChildClick(event, 'child', 'singleDropDown', "isProviderOpen")
                    }
                }}
            >
                <p className="text-xs text-white font-semibold">Provider</p>
                <p className={`flex justify-between text-sm font-medium mt-3 ${state.selectedProvider ? "text-white" : "text-lightGray"}`}>
                    <span className='truncate'>{state.selectedProvider ? state.selectedProvider.toUpperCase() : 'Select'}</span>
                    <Icon icon="icon-park-solid:down-one" color={`${state.selectedProvider ? "#FFFFFF" : "#999999"}`} width="16" height="16" />                        
                </p>
                {state.isProviderOpen ?
                    <div className='relative'>
                        <MultiSelectSection 
                            fields={["provider_name", "provider_name"]}
                            options={state.providers}
                            widthClass={'minWidth220'}
                            selectedValues={state.selectedProvider ? state.selectedProvider : ''}
                            callbackMultiSelect={(value) => {
                                if(!value || typeof(value) === 'string') {
                                    setState(prevState => ({ ...prevState, selectedProvider: value, selectedAccount: [], selectedRegion: [], selectedClusters: [] }))
                                } else {
                                    value.preventDefault()
                                    handleChildClick(value, "search", 'singleDropDown', "")
                                }
                            }}
                            singleSelection={true}
                            removeTopOptions={true}
                        />
                    </div>
                : null}
            </div>
            <div className={`bg-black5 w-full h-fit p-2 text-white flex flex-col justify-between rounded-md cursor-pointer mr-3`} style={{minWidth: '150px', maxWidth: '150px'}} 
                onClick={(event) => {
                    event.preventDefault();
                    handleChildClick(event, 'child', 'muliselectDropDown', "isAccountOpen")
                }}
            >
                <p className="text-xs text-white font-semibold">Account</p>
                <p className={`flex justify-between text-sm font-medium mt-3 ${state.selectedAccount && state.selectedAccount.length? "text-white" : "text-lightGray"}`}>
                    {state.accountLoader ? 
                        <svg className="animate-spin h-5 w-5 mr-3 text-blue-500" fill="currentColor" viewBox="0 0 24 24">
                            <circle cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="2" fill="none" />
                            <path d="M4 12a8 8 0 0112-6.9" />
                        </svg>
                    :
                        <span className='truncate'>{state.selectedAccount && state.selectedAccount.length ? state.selectedAccount.length+' Selected' : 'Select'}</span>
                    }
                    <Icon icon="icon-park-solid:down-one" color={`${state.selectedAccount && state.selectedAccount.length ? "#FFFFFF" : "#999999"}`} width="16" height="16" />                        
                </p>
                {state.isAccountOpen && !state.accountLoader ?
                    <div className='relative'>
                        <MultiSelectSection 
                            fields={["account_id", "account_name"]}
                            options={state.accounts}
                            selectedValues={state.selectedAccount ? state.selectedAccount : []}
                            callbackMultiSelect={(value) => {
                                setState(prevState => ({ ...prevState, selectedAccount: value }))
                            }}
                            widthClass={'minWidth220'}
                            removeTopOptions={true}
                        />
                    </div>
                : null}
            </div>
            <div className={`bg-black5 w-full h-fit p-2 text-white flex flex-col justify-between rounded-md cursor-pointer mr-3`} style={{minWidth: '120px', maxWidth: '120px'}}
                onClick={(event) => {
                    event.preventDefault();
                    handleChildClick(event, 'child', 'muliselectDropDown', "isRegionOpen")
                }}
            >
                <p className="text-xs text-white font-semibold">Region</p>
                <p className={`flex justify-between text-sm font-medium mt-3 ${state.selectedRegion && state.selectedRegion.length? "text-white" : "text-lightGray"}`}>
                    {state.regionLoader ? 
                        <svg className="animate-spin h-5 w-5 mr-3 text-blue-500" fill="currentColor" viewBox="0 0 24 24">
                            <circle cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="2" fill="none" />
                            <path d="M4 12a8 8 0 0112-6.9" />
                        </svg>
                    :
                        <span className='truncate'>{state.selectedRegion && state.selectedRegion.length ? state.selectedRegion.length+' Selected' : 'Select'}</span>
                    }
                    <Icon icon="icon-park-solid:down-one" color={`${state.selectedRegion && state.selectedRegion.length ? "#FFFFFF" : "#999999"}`} width="16" height="16" />                        
                </p>
                {state.isRegionOpen && !state.regionLoader ?
                    <div className='relative'>
                        <MultiSelectSection 
                            fields={["region", "name"]}
                            options={state.regions}
                            selectedValues={state.selectedRegion ? state.selectedRegion : []}
                            callbackMultiSelect={(value) => setState(prevState => ({ ...prevState, selectedRegion: value }))}
                            widthClass={'minWidth220'}
                            removeTopOptions={true}
                        />
                    </div>
                : null}
            </div>
            <div className={`bg-black5 w-full h-fit p-2 text-white flex flex-col justify-between rounded-md cursor-pointer mr-3`} style={{minWidth: '150px', maxWidth: '150px'}} 
                onClick={(event) => {
                    if(!state.isClusterOpen) {
                        event.preventDefault();
                        handleChildClick(event, 'child', 'singleDropDown', "isClusterOpen")
                    }
                }}
            >
                <p className="text-xs text-white font-semibold">Cluster</p>
                <p className={`flex justify-between text-sm font-medium mt-3 ${state.selectedClusters ? "text-white" : "text-lightGray"}`}>
                    {state.clusterLoader ? 
                        <svg className="animate-spin h-5 w-5 mr-3 text-blue-500" fill="currentColor" viewBox="0 0 24 24">
                            <circle cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="2" fill="none" />
                            <path d="M4 12a8 8 0 0112-6.9" />
                        </svg>
                    :
                        <span className='truncate'>{state.selectedClusters ? state.selectedClusters : 'Select'}</span>
                    }
                    <Icon icon="icon-park-solid:down-one" color={`${state.selectedClusters ? "#FFFFFF" : "#999999"}`} width="16" height="16" />                        
                </p>
                {state.isClusterOpen ?
                    <div className='relative'>
                        <MultiSelectSection 
                            options={state.clusters}
                            selectedValues={state.selectedClusters ? state.selectedClusters : ''}
                            callbackMultiSelect={(value) => {
                                if(!value || typeof(value) === 'string') {
                                    setState(prevState => ({ ...prevState, selectedClusters: value }))
                                } else {
                                    value.preventDefault()
                                    handleChildClick(value, "search", 'singleDropDown', "")
                                }
                            }}
                            singleSelection={true}
                            widthClass={'minWidth220'}
                            removeTopOptions={true}
                            className={'right-0'}
                        />
                    </div>
                : null}
            </div>
        </div>
    )
}