import React, { useCallback, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux"; // Import useDispatch hook to dispatch actions
import { setHpcPropsDetails } from "../../../../actions/Collider/HpcAction";
import MultiSelectSection from "../../../common/MultiSelectSection";
import ExistingStorageSection from "./ExistingStorageSection";
import NewStorageSection from "./NewStorageSection";
import { Icon } from '@iconify/react'

const StorageSection = (props) => {
  const [state, setState] = useState({});
  const createUpdateCluster = useSelector(
    (state) => state?.hpc?.hpcPropsDetails?.createUpdateCluster || false
  );
  const propsClusterFilters = useSelector(
    (state) => state?.hpc?.hpcPropsDetails?.propsClusterFilters || false
  );

  const dispatch = useDispatch(); // Create a dispatch function

  const callPageVist = useCallback(() => {
    let storageConfiguration = createUpdateCluster?.storage_configuration || [];
  
    if (storageConfiguration.length) {
      if (!state.storageOption) {
        setState((prevState) => ({
          ...prevState,
          storageOption: Object.keys(storageConfiguration[0]).includes("create") ? "new" : "existing"
        }));
      }
  
      if (!state.storage_type) {
        setState((prevState) => ({
          ...prevState,
          storage_type: storageConfiguration[0].storage_type
        }));
      }
    }
  }, [createUpdateCluster, state.storageOption, state.storage_type]);

  useEffect(() => callPageVist(), [callPageVist]);

  const handleStorageOptionChange = (e) => {
  if (window.confirm("If you change the storage option, the values already entered in the storage fields will be lost. Are you sure you want to proceed?")) {
    setState((prevState) => ({
      ...prevState,
      storageOption: e.target.value
    }));

    let obj = createUpdateCluster ? createUpdateCluster : {};
    const storage_type = obj.storage_configuration && obj.storage_configuration.length ? obj.storage_configuration[0].storage_type : null;

    if (e.target.value === 'new') {
      obj["storage_configuration"] = storage_type ? [{
        storage_type: storage_type,
        create: true
      }] : [];
    } else {
      // Append storage_type to existing configurations
      obj["storage_configuration"] = storage_type ? 
        obj.storage_configuration.map(config => ({ ...config, storage_type: storage_type})) 
        : [];
    }

    dispatch(setHpcPropsDetails("createUpdateCluster", obj));
  }
};

const handleStorageInput = (label, value) => {
  let obj = createUpdateCluster ? createUpdateCluster : {};
  let storageConfiguration = Array.isArray(obj.storage_configuration) ? obj.storage_configuration : [];

  if (label === 'storage_type') {
    storageConfiguration = [ ];
  }
    
    // Update the specific label with the new value in all entries
  //   storageConfiguration.forEach((entry) => {
  //     entry[label] = value;
  //   });
  // }

  obj["storage_configuration"] = storageConfiguration;
  dispatch(setHpcPropsDetails("createUpdateCluster", obj));
  setState((prevState) => ({
    ...prevState,
    [label]: value,
  }));
};


// const handleStorageInput = (label, value) => {
//   let obj = createUpdateCluster ? createUpdateCluster : {};
//   obj["storage_configuration"] = [{
//     [label]: value,
//   }];
//   dispatch(setHpcPropsDetails("createUpdateCluster", obj));
//   setState((prevState) => ({ ...prevState, [label]: value }));
// };


  const handleChildClick = (event, type, dropdownType, section) => {
    event.stopPropagation();

    let clickedChild = [];
    if (type === "child") {
      if (state.clickedChild && state.clickedChild.length) {
        state.clickedChild.forEach((row) => {
          setState((prevState) => ({ ...prevState, [row]: false }));
        });
      }
      clickedChild.push(section);
      setState((prevState) => ({
        ...prevState,
        [section]:
          dropdownType === "singleDropDown" && state[section] ? false : true,
        clickedChild,
      }));
    } else if (type === "parent") {
      if (state.clickedChild && state.clickedChild.length) {
        state.clickedChild.forEach((row) => {
          setState((prevState) => ({ ...prevState, [row]: false }));
        });
      }

      setState((prevState) => ({ ...prevState, clickedChild }));
    }
  };

  return (
    <div onClick={(event) => handleChildClick(event, "parent")}>
      <div className="rounded-lg bg-gray-800 p-4 mb-4">
        <p className="text-white text-lg mt-3">Storage Options</p>
  
        <div className="flex">
          <div className="py-2 px-4 w-1/5 self-center">
            <div className="flex">
              <label className="mb-0 ml-4">
                <input
                  className="form-check-input mt-1"
                  type="radio"
                  name="storageOption"
                  id="storageOption"
                  value="existing"
                  checked={state.storageOption === "existing"}
                  onChange={handleStorageOptionChange}
                />
                <span className="form-check-label mb-0 mr-2 self-center text-lightGray ml-1">
                  Use Existing
                </span>
              </label>
            </div>
          </div>
          <div className="py-2 px-4 w-1/5 self-center">
            <div className="flex">
              <label className="mb-0 ml-4">
                <input
                  className="form-check-input mt-1"
                  type="radio"
                  name="storageOption"
                  id="storageOption"
                  value="new"
                  checked={state.storageOption === "new"}
                  onChange={handleStorageOptionChange}
                />
                <span className="form-check-label mb-0 mr-2 self-center text-lightGray ml-1">
                  Create New
                </span>
              </label>
            </div>
          </div>
        </div>
  
        {state.storageOption !== "" && (
          <div className="flex mt-3">
            <div className="py-2 w-1/3">
              <div className="flex justify-between w-full">
                <label className="self-center m-0 text-lightGray">Storage type</label>
                {state.hasError && !state["storage_type"] ? (
                  <small className="text-red-500">required</small>
                ) : null}
              </div>
              <div
                className="text-white border-lightGray bg-transparent rounded-5"
                onClick={(event) => {
                  if (!state["isStorageType"]) {
                    event.preventDefault();
                    handleChildClick(event, "child", "singleDropDown", "isStorageType");
                  }
                }}
              >
                <p
                  className={`flex border border-lightGray rounded-md bg-transparent px-2 py-1 justify-between truncate ${
                    state["storage_type"] && props.pageMode === "Create" ? "text-white" : "placeholder"
                  }`}
                >
                  {state["storage_type"] ? 
                    propsClusterFilters.storage_type && propsClusterFilters.storage_type.filter((e) => 
                        e.value === state["storage_type"]).length ? 
                          propsClusterFilters.storage_type.filter((e) => 
                            e.value === state["storage_type"])[0].key
                        : state["storage_type"]
                    : "Select"}
                  <Icon icon="icon-park-solid:down-one" className={`${state["storage_type"]  ? "text-white" : "text-lightGray"} self-center`} width="16" height="16" />
                </p>
                {state["isStorageType"] && props.pageMode === "Create" ? (
                  <MultiSelectSection
                    fields={["value", "key"]}
                    options={propsClusterFilters && propsClusterFilters.storage_type ? propsClusterFilters.storage_type : []}
                    selectedValues={state["storage_type"] ? state["storage_type"] : ""}
                    callbackMultiSelect={(value) => {
                      if (!value || typeof value === "string") {
                        handleStorageInput("storage_type", value);
                        setState((prevState) => ({...prevState, storage_type: value}));
                      } else {
                        value.preventDefault();
                        handleChildClick(value, "search", "singleDropDown", "");
                      }
                    }}
                    singleSelection={true}
                    widthClass={"min-w-[220px]"}
                    removeTopOptions={true}
                  />
                ) : null}
              </div>
            </div>
          </div>
        )}
        {state.storageOption && state["storage_type"] && (
          <div>
            {state.storageOption === "existing" ? (
              <ExistingStorageSection
                pageMode={props.pageMode}
                storageType={state.storage_type}
              />
            ) : (
              <NewStorageSection
                pageMode={props.pageMode}
                storageOption={state.storageOption}
                storageType={state.storage_type}
              />
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default StorageSection;
