/* eslint-disable no-mixed-spaces-and-tabs */
/*************************************************
 * Tvastar
 * @exports
 * @file GovernanceDashboard.js
 * @author Prakash // on 08/01/2024
 * @copyright © 2024 Tvastar. All rights reserved.
 **************************************************/
import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useDispatch, } from 'react-redux'; // Import useDispatch hook to dispatch actions
import _ from 'lodash'

import { capitalizeFirstLetter, getAccountNameFromId, momentDateGivenFormat, momentConvertionUtcToLocalTime, capitalizeTheFirstLetterOfEachWord } from '../../utils/utility'

import { newGovernanceGetTotalCount, newGovernanceGetDayWiseCount, newGovernanceGetTransitionTrend, getGovernanceViolations, listGovernanceType } from '../../actions/governance/GovernanceAction'

import { CSVLink } from 'react-csv'

import Chart from 'react-apexcharts'
import ApexDonutChart from '../common/charts/ApexDonutChart'
import ApexAreaChart from '../common/charts/ApexAreaChart'
import ApexRiskStackedBarChart from '../common/charts/ApexRiskStackedBarChart'
import ApexBarChart from '../common/charts/ApexBarChart'
import StackedCircleBarChart from '../common/charts/StackedCircleBarChart'
// import DashboardSidePanel from 'DashboardSidePanel'
import ResizeableDarkThemeTable from '../designComponents/Table/ResizeableDarkThemeTable'
import MultiSelectSection from '../common/MultiSelectSection';
import {Icon} from "@iconify/react";
import Search from '../common/SearchWithHiddenInput';

const riskOptions = [
	{ label: 'All', value: 'All' },
	{ label: 'Critical', value: 'Critical' },
	{ label: 'High', value: 'High' },
	{ label: 'Medium', value: 'Medium' },
	{ label: 'Low', value: 'Low' },
]

const Dashboard = () => {
	const groupByRef = useRef()
	const rightSideTableRef = useRef()
	const rightRadialStackedBarWidthRef = useRef()
	const leftSectionRef = useRef()
	const rightSectionRef = useRef()

	const [state, setState] = useState({
		selectedDuration: '+30d',
		showLoading: true,
		percentStatusCount: [],
	
		dayWiseCountGraphData: {},
		selectedGovernanceType: 'infra',
	
		totalSum: 0,
		passSum: 0,
		failSum: 0,
	
		dayWiseRiskCountGraphData: {},
		//for next and previous week record
		transitionTrend: [],
		groupBy: 'resource_type'
	})

	const dispatch = useDispatch(); // Create a dispatch function
	// const tagKeys = useSelector(state => state?.filters?.tagKeys);

	const callGovernanceType = useCallback(() => {
		dispatch((listGovernanceType()))
			.then((response)=> {
				if(response) {
					setState(prevState => ({ ...prevState, governanceTypes: response && response.length ? response : [] }))
				}
			})
	}, [dispatch])

	useEffect(() => {
		dispatch((listGovernanceType()))
			.then((response)=> {
				if(response) {
					setState(prevState => ({ ...prevState, governanceTypes: response && response.length ? response : [], getAccountDetails: true }))
				}
			})
	}, [dispatch])


	useEffect(() => {
		if(state.showLoading) {
			setTimeout(() => { setState(prevState => ({ ...prevState, showLoading: false })) }, 5000)
		}
	}, [state.showLoading])

	useEffect(() => {
		if(state.getAccountDetails) {
			setState(prevState => ({ ...prevState, getAccountDetails : false }))
			let params = {}
			if(state.selectedGovernanceType === 'hpc-slurm') {
				params.aggregate_by = ['cluster_name']
			} else {
				params.aggregate_by = ['account_id']	
			}
			params.governance_type = state.selectedGovernanceType
			params.duration = state.selectedDuration

			dispatch(newGovernanceGetTotalCount(params))
				.then((response) => {
					if (response && !response.error) {
						let selectedAccount = ''
						if(state.selectedGovernanceType === 'hpc-slurm') {
							selectedAccount = response.length ? response[0].cluster_name : ''
						} else {
							selectedAccount = response.length ? response[0].account_id : ''
						}
						setState(prevState => ({  ...prevState,  accountsNew: response, filteredAccount: response, selectedAccount }))
					}
				})			
		}
    }, [state.getAccountDetails, state.selectedGovernanceType, state.selectedDuration,dispatch]); // Empty dependency array ensures this runs only once on mount	

	useEffect(() => {
		if(state.callOnSearch) {
			setState({columns: [], selectedGovernanceType: state.selectedGovernanceType, groupBy: state.groupBy, showLoading: true, getAccountDetails: true, selectedDuration: state.selectedDuration,  callOnSearch: false })
			setTimeout(() => { callGovernanceType() }, 1000);
		}
	}, [state.callOnSearch, callGovernanceType, state.selectedGovernanceType, state.groupBy, state.selectedDuration])



	useEffect(() => {
		if(state.selectedAccount) {
			setState(prevState => ({ ...prevState, callNewGovernanceGetTotalCountFunction: true, callMultidaywiseGraph: true, callTransitionTrend: true, callGetGovernanceViolationsFunction: true, callTop5Violations: true, callRadialBarDetails: true, callGetInitializePaginationRight: true }))
		}
	}, [state.selectedAccount])

	const onClickAccFunction = accountId => {
		if (state.selectedAccount !== accountId) {
			setState(prevState => ({ ...prevState, selectedAccount: accountId, showLoading: true }))
		}
	}

	useEffect(() => {
		if(state.showLoading) {
			setTimeout(() => { setState(prevState => ({ ...prevState, showLoading: false })) }, 1000); 
		}
	}, [state.showLoading])

	const onReset = () => {
		setState(prevState => ({ ...prevState, selectedGovernanceType: 'infra', showLoading: true, getAccountDetails: true, selectedDuration: '+30d' }))
	}	

	useEffect(() => {
		if(state.callNewGovernanceGetTotalCountFunction) {
			setState(prevState => ({ ...prevState, callNewGovernanceGetTotalCountFunction: false }))
			let params = {}
	
			if (state.selectedGovernanceType !== 'All') {
				params.governance_type = state.selectedGovernanceType
			}
			if(state.selectedAccount !== "" && state.selectedGovernanceType !== "opa") {
				if(state.selectedGovernanceType === 'hpc-slurm') {
					params.cluster_name = state.selectedAccount
				} else {
					params.account_id = state.selectedAccount
				}
			}
			params.duration = state.selectedDuration

			let riskListParam = {}
			riskListParam.aggregate_by = ['risk']
			riskListParam = {...params, ...riskListParam}
	
			dispatch(newGovernanceGetTotalCount(riskListParam))

			let riskParam = {}
			riskParam.aggregate_by = 'risk'
			riskParam = {...params, ...riskParam}
		
			dispatch(newGovernanceGetDayWiseCount(riskParam))
				.then((response) => {
					if (response && !response.error) {
						setState(prevState => ({ ...prevState, dayWiseRiskCountGraphData: response }))
					} else {
						setState(prevState => ({ ...prevState, dayWiseRiskCountGraphData: {} }))
					}
				})
		}
	}, [state.callNewGovernanceGetTotalCountFunction, state.selectedGovernanceType, state.selectedDuration, state.selectedAccount, dispatch])
	
	useEffect(() => {
		if(state.callMultidaywiseGraph) {
			setState(prevState => ({ ...prevState, callMultidaywiseGraph: false }))
		
			let params = {}

			if (state.selectedGovernanceType !== 'All') {
				params.governance_type = state.selectedGovernanceType
			}
			if(state.selectedAccount !== "" && state.selectedGovernanceType !== "opa") {
				if(state.selectedGovernanceType === 'hpc-slurm') {
					params.cluster_name = state.selectedAccount
				} else {
					params.account_id = state.selectedAccount
				}
			}
			params.duration = state.selectedDuration
			params.aggregate_by = 'status'

			dispatch(newGovernanceGetDayWiseCount(params))
				.then((response) => {
					if (response && !response.error) {
						setState(prevState => ({ ...prevState, dayWiseCountGraphData: response, multi_pass_fail_series: [], multi_pass_fail_options: {}, callmultidayWiseCountGraphApex: true }))
					} else {
						setState(prevState => ({ ...prevState, dayWiseCountGraphData: {} }))
					}
				})
		}
	}, [state.callMultidaywiseGraph, state.selectedGovernanceType, state.selectedDuration, state.selectedAccount, dispatch])

	useEffect(() => {
		if(state.callmultidayWiseCountGraphApex) {
			setState(prevState => ({ ...prevState, callmultidayWiseCountGraphApex: false }))
			let graphDataFail =
				state.dayWiseCountGraphData && state.dayWiseCountGraphData.fail
					? state.dayWiseCountGraphData.fail
					: {}
			let graphDataPass =
			state.dayWiseCountGraphData && state.dayWiseCountGraphData.pass
				? state.dayWiseCountGraphData.pass
				: {} 
	
			if (graphDataFail) {
				let passGraphData = []
				let failGraphData = []
	
				let labels = graphDataFail.labels
				if(labels &&  labels.length) {
					labels.forEach((lab, index) => {
						let dataItems1 = {}
						dataItems1.x = momentDateGivenFormat(lab, 'DD MMM Y')
						dataItems1.y = graphDataPass.data && graphDataPass.data.pass && graphDataPass.data.pass[index] ? graphDataPass.data.pass[index] : 0
						passGraphData.push(dataItems1)
	
						let dataItems2 = {}
						dataItems2.x = momentDateGivenFormat(lab, 'DD MMM Y')
						dataItems2.y = graphDataFail.data && graphDataFail.data.fail && graphDataFail.data.fail[index] ? graphDataFail.data.fail[index] : 0 
						failGraphData.push(dataItems2)
					})
				}
	
				let passData = graphDataPass.data && graphDataPass.data.pass ? graphDataPass.data.pass : []
				let failData = graphDataFail.data && graphDataFail.data.fail ? graphDataFail.data.fail : []
	
				let passSum = passData.reduce(function(a, b) {
					return a + b
				}, 0)
	
				let failSum = failData.reduce(function(a, b) {
					return a + b
				}, 0)
	
				let totalSum = failSum + passSum
	
				let multi_series = [
					{
						name: 'Pass',
						data: passGraphData,
					},
					{
						name: 'Fail',
						data: failGraphData,
					},
				]
	
				let xAxisRow = {}
				if (graphDataFail.labels && graphDataFail.labels && graphDataFail.labels.length >= 7) {
					xAxisRow.type = 'datetime'
				}
				let aAxixRowTool = {}
				aAxixRowTool.enabled = false
				xAxisRow.tooltip = aAxixRowTool
				let aAxixRowTicks = {}
				aAxixRowTicks.show = false
				xAxisRow.labels = aAxixRowTicks
	
				let multi_options = {
					chart: {
						type: 'bar',
						height: 100,
						stacked: true,
						toolbar: {
							show: false,
						},
						zoom: {
							enabled: false,
						},
						sparkline: {
							enabled: true,
						},
					},
					colors: ['#FFD200', '#B96422'],
					// colors: ["#17522E", "#6E2527"],
					plotOptions: {
						bar: {
							horizontal: false,
							barHeight: '100%',
							borderRadius: 'flat',
							startingShape: 'flat',
							endingShape: 'flat',
							columnWidth: '60%',
							distributed: false,
							rangeBarOverlap: false,
							colors: {
								ranges: [
									{
										from: 1,
										to: 0.9,
										color: 'red',
									},
								],
								backgroundBarColors: ['#333947'],
								backgroundBarOpacity: 1,
								backgroundBarRadius: 0,
							},
						},
					},
					dataLabels: {
						enabled: false,
					},
					legend: {
						show: false,
					},
					grid: {
						show: false,
						xaxis: {
							lines: {
								show: false,
							},
						},
						yaxis: {
							lines: {
								show: false,
							},
						},
						padding: {
							top: 0,
							right: 0,
							bottom: 0,
							left: -5,
						},
					},
					xaxis: xAxisRow,
					yaxis: {
						show: false,
						labels: {
							show: false,
						},
					},
					tooltip: {
						enabled: true,
						custom: function({ series, seriesIndex, dataPointIndex, w }) {
							let val = series[seriesIndex][dataPointIndex]
							let labelName = w.globals.initialSeries[seriesIndex].name
							return(
								'<div class="arrow_box">' +
								'<span> ' +
								w.globals.initialSeries[0].data[dataPointIndex].x +
								' <span style="color:' +
								w.globals.colors[seriesIndex] +
								'">' +
								labelName +
								'</span> ' +
								val +
								' </span>' +
								'</div>'
							)
						},
						style: {
							fontSize: '9px',
						},
					},
				}
				setState(prevState => ({ 
					...prevState, 
					multi_pass_fail_series: multi_series,
					multi_pass_fail_options: multi_options,
					totalSum,
					passSum,
					failSum,
				}))
			}
		}
	}, [state.callmultidayWiseCountGraphApex, state.dayWiseCountGraphData])

	useEffect(() => {
		if(state.callTransitionTrend) {
			setState(prevState => ({ ...prevState, callTransitionTrend: false }))

			let params = {}

			if (state.selectedGovernanceType !== 'All') {
				params.governance_type = state.selectedGovernanceType
			}
			if(state.selectedAccount !== "" && state.selectedGovernanceType !== "opa") {
				if(state.selectedGovernanceType === 'hpc-slurm') {
					params.cluster_name = state.selectedAccount
				} else {
					params.account_id = state.selectedAccount
				}
			}
			params.duration = state.selectedDuration

			dispatch(newGovernanceGetTransitionTrend(params))
				.then((response) => {
					if(response && !response.error) {
						setState(prevState => ({ ...prevState, transitionTrend: response, showLoading: false, callTransitionTrendGraph: true }))
					} else {
						setState(prevState => ({ ...prevState,transitionTrend: [], showLoading: false }))
					}
				})
		}
	}, [state.callTransitionTrend, state.selectedGovernanceType, state.selectedDuration, state.selectedAccount, dispatch])

	useEffect(() => {
		if(state.callTransitionTrendGraph) {
			setState(prevState => ({ ...prevState, callTransitionTrendGraph: false }))
			let graphDetails = state.transitionTrend.details
			let graphLabels = state.transitionTrend.labels
			if (graphDetails) {
				for (const [key, value] of Object.entries(graphDetails)) {
					let data = []
					let graphData = value.daywise_trend
					for (let x = 0; x < graphLabels.length; x++) {
						let dataItems = {}
						dataItems.x = momentDateGivenFormat(graphLabels[x], 'DD MMM Y')
						dataItems.y = graphData[x]
						data.push(dataItems)
					}

					let trendGraphData = {}
					trendGraphData.labels = graphLabels
					trendGraphData.data = data
	
					let donut = []
					let donut_total_count = 0
					if(value.severity_wise) {
						Object.entries(value.severity_wise).forEach(([sKey, sValue]) => {
							let donutRow = {}
							donutRow.item_name = sKey
							donutRow.item_count = sValue
							donut.push(donutRow)
							donut_total_count += sValue
						})
					}

					let distributionDonutData = {
						'items': donut,
						'label': 'Total',
						'total_count': donut_total_count
					}

					setState(prevState => ({ ...prevState, [key + '_trend_data']: trendGraphData, [key + '_trend_donut']: distributionDonutData }))
				}
			}
		}
	}, [state.callTransitionTrendGraph, state.transitionTrend])	

	useEffect(() => {
		if(state.callTop5Violations) {
			setState(prevState => ({ ...prevState, callTop5Violations: false })) 
			let params = {}
	
			if (state.selectedGovernanceType !== 'All') {
				params.governance_type = state.selectedGovernanceType
			}
			if(state.selectedAccount !== "" && state.selectedGovernanceType !== "opa") {
				if(state.selectedGovernanceType === 'hpc-slurm') {
					params.cluster_name = state.selectedAccount
				} else {
					params.account_id = state.selectedAccount
				}
			}
			params.duration = state.selectedDuration
			params.aggregate_by = ['description']
	
			dispatch(newGovernanceGetTotalCount(params))
				.then((response) => {
					if(response && !response.error) {
						setState(prevState => ({ ...prevState, violationsList: response }))
					} else {
						setState(prevState => ({ ...prevState, violationsList: {} }))
					}
				})
		}
	}, [dispatch, state.callTop5Violations, state.selectedDuration, state.selectedGovernanceType, state.selectedAccount])

	useEffect(() => {
		let list = state.violationsList

		let orderedList = _.orderBy(list, ['fail_count'], ['desc'])

		let labels = []
		let data = []
		orderedList &&
			orderedList.length &&
			orderedList.slice(0, 5).forEach(item => {
				labels.push(item.description)
				data.push(item.fail_count)
			})

		for (let i = orderedList.length; i < 5; i++) {
			data.push(0)
			labels.push('empty')
		}
		let topStatusData = {}
		topStatusData.data = data
		topStatusData.labels = labels

		setState(prevState => ({ ...prevState, top5Violations: topStatusData }))

	}, [state.violationsList])

	const getAssetNameByAssetArn = useCallback((assetArn) => {
		const splitItems = assetArn.split(':');
		// Get the last two items and join them with ':'
		return splitItems.slice(-2).join(':')
	}, [])

	useEffect(() => {
		if(state.callGetGovernanceViolationsFunction) {
			setState(prevState => ({ ...prevState, callGetGovernanceViolationsFunction: false}))

			let params = {}
			if (state.selectedGovernanceType !== 'All') {
				params.governance_type = state.selectedGovernanceType
			}
			if(state.selectedAccount !== "" && state.selectedGovernanceType !== "opa") {
				if(state.selectedGovernanceType === 'hpc-slurm') {
					params.cluster_name = state.selectedAccount
				} else {
					params.account_id = state.selectedAccount
				}
			}
			params.duration = state.selectedDuration
			params.aggregate_by = (state.selectedGovernanceType === 'infra' || state.selectedGovernanceType === 'access' || state.selectedGovernanceType === 'global') ? 'resource_type' : 'category'

			let columns = [
				{
					Header: 'Risk',
					accessor: 'risk',
					Cell: cellInfo => (
						<span className={`px-2 py-1 rounded-full bg-${cellInfo.row.original.risk ? cellInfo.row.original.risk.toLowerCase() : ''}`}>
							{cellInfo.row.original.risk ? (cellInfo.row.original.risk.toLowerCase() === 'critical' ? 'C' : cellInfo.row.original.risk.toLowerCase() === 	'high' ? 'H' : cellInfo.row.original.risk.toLowerCase() === 	'medium' ? 'M' : cellInfo.row.original.risk.toLowerCase() === 'low' ? 'L' : '') : 'U'}
						</span>
					),
					width: 80,
				},
				{
					Header: 'Date',
					accessor: 'timestamp',
					Cell: cellInfo => (
						<span>
							{cellInfo.row.original.status && cellInfo.row.original.status !== 'pass' ? 
								cellInfo.row.original.resolution_status_info && cellInfo.row.original.resolution_status_info.timestamp && momentConvertionUtcToLocalTime(cellInfo.row.original.resolution_status_info.timestamp, 'DD MMM YYYY HH:mm')
							: momentConvertionUtcToLocalTime(cellInfo.row.original.timestamp, 'DD MMM YYYY HH:mm')}
						</span>
					),
					width: 180,
				},
				{
					Header: 'Policy Name',
					accessor: 'policy_name',
					width: 150,
				},
				{
					Header: 'Resources',
					accessor: d =>
						d.provider + ' : ' + d.account_id + ' : ' + d.region,
					Cell: cellInfo => (
						<React.Fragment>
							<span className=''>
								{cellInfo.row.original.provider + ' : ' + cellInfo.row.original.account_id + ' : ' + (cellInfo.row.original.region === 'NA' ? 'Global' : cellInfo.row.original.region)}
							</span>
						</React.Fragment>
					),
					width: 200,
				}
			]
			let dataRow = {}
			if(state.selectedGovernanceType !== 'hpc-slurm') {
				dataRow = {
					Header: 'Services',
					accessor: d => d.resource_type + ' : ' + d.service_name,
					width: 150,
				}
				columns.push(dataRow)
			}

			dataRow = {
				Header: 'Asset',
				accessor: 'resource_id',
				// Cell: cellInfo => (
				// 	cellInfo.row.original.asset_arn ?
				// 	<span>{getAssetNameByAssetArn(cellInfo.row.original.asset_arn)}</span>
				// 	: null
				// ),
				width: 150,
			}
			columns.push(dataRow)
			
			dataRow = {
				Header: 'Status',
				accessor: 'status',
				Cell: cellInfo => (
					<span
						className={`border border-${cellInfo.row.original.status === 'pass' ? 'warning' : 'danger'} text-white px-2 py-1 rounded-full`}
					>
						{capitalizeFirstLetter(cellInfo.row.original.status)}
					</span>
				),
				width: 100,
			}
			columns.push(dataRow)
			dataRow = {
				Header: 'Description',
				accessor: 'description',
				Cell: cellInfo => (
					<span className=''>
						{(cellInfo.row.original.description ? cellInfo.row.original.description : '') + '' + (cellInfo.row.original.status && cellInfo.row.original.status === 'fail' && cellInfo.row.original.detail ? ' - ' + cellInfo.row.original.detail : '')}
					</span>
				),
				width: 370,
				className: 'whiteSpaceUnset',
			}
			columns.push(dataRow)
			dataRow = {
				Header: ' ',
				accessor: ' ',
				width: 1,
			}
			columns.push(dataRow)


			dispatch(getGovernanceViolations(params))
				.then((response) => {
					if(response && !response.error) {
						setState(prevState => ({  ...prevState,  violationResponse: response, viloationDataNew: response, filteredViolationDataNew: response, violationDetails: [], callcombineAllVilationData: true, columns
						}))
					} else {
						setState(prevState => ({ ...prevState, violationResponse: [] }))
					}
				})
		}
	}, [dispatch, state.callGetGovernanceViolationsFunction, state.selectedDuration, state.selectedGovernanceType, getAssetNameByAssetArn, state.selectedAccount])

	useEffect(() => {
		if(state.callcombineAllVilationData) {
			setState(prevState => ({ ...prevState, callcombineAllVilationData: false}))
			let violationAllResults = []
			if (state.filteredViolationDataNew && state.filteredViolationDataNew.length) {
				state.filteredViolationDataNew.forEach(item => {
					item.governance_results.forEach(row => {
						if (row.results) {
							violationAllResults = [...row.results, ...violationAllResults]
						}
					})
				})
			}
			
			let headers = []
			let usedKeys = []
			if (violationAllResults.length) {
				Object.keys(violationAllResults[0]).forEach(item => {
					if (item !== 'resolution_status_info' && item !== 'category' && item !== 'tags' && item !== 'version') {
						let headerRow = {}
						headerRow.label = capitalizeTheFirstLetterOfEachWord(item)
						headerRow.key = item
						headers.push(headerRow)
						usedKeys.push(item)
					}
				})
	
				let downloadData = []
				violationAllResults.forEach((item, index) => {
					let dataRow = {}
					Object.entries(item).forEach(([key, value]) => {
						if (usedKeys.includes(key)) {
							dataRow[key] = value
						}
					})
					downloadData.push(dataRow)
				})
	
			}
			setState(prevState => ({ ...prevState, violationAllResults, violationHeaders: headers }))
		}
	}, [state.filteredViolationDataNew, state.callcombineAllVilationData])

	const onChangeGroupBy = type => {
		setState(prevState => ({ 
			...prevState, 
			showRightSideRadialChart: type === 'graph' ? true : false,
			hideDistributionSection: false,
			radialBarData: [],
			showRadialLoading: true,
			showGroupByOptions: false,
			filteredViolationDataNew: [],
			callGetGovernanceViolationsFunction: true,
			callRadialBarDetails: true
		}))
	}

	useEffect(() => {
		if(state.callRadialBarDetails) {
			setState(prevState => ({ ...prevState, callRadialBarDetails: false }))
			let params = {}
	
			if (state.selectedGovernanceType !== 'All') {
				params.governance_type = state.selectedGovernanceType
			}
			if(state.selectedAccount !== "" && state.selectedGovernanceType !== "opa") {
				if(state.selectedGovernanceType === 'hpc-slurm') {
					params.cluster_name = state.selectedAccount
				} else {
					params.account_id = state.selectedAccount
				}
			}
			params.duration = state.selectedDuration
			if (state.groupBy === 'resource_type') {
				params.aggregate_by = ['resource_type', 'title']
			} else {
				params.aggregate_by = ['title', 'resource_type']
			}
	
			dispatch(newGovernanceGetTotalCount(params))
				.then((response) => {
					if (response && !response.error) {
						setState(prevState => ({ ...prevState, radialBarData: response, callFormRadialBarChart: true }))
					} else {
						setState(prevState => ({ ...prevState, radialBarData: [] }))
					}
				})
		}
	}, [dispatch, state.callRadialBarDetails, state.groupBy, state.selectedDuration, state.selectedGovernanceType, state.selectedAccount])

	
	useEffect(() => {
		if(state.callFormRadialBarChart) {
			setState(prevState => ({ ...prevState, callFormRadialBarChart: false }))

			let graphData = state.radialBarData
			let rightSideGraphColors = ['#9029cc', '#a22ee6', '#bc47ff', '#c35cff', '#cb70ff', '#d285ff', '#da99ff', '#e15ad2', '#fb74eb', '#fb83ed', '#fc93f0', '#fca2f2', '#fdb2f4', '#fdc1f6']
			let keys = []
	
			let newData = _.chain(graphData)
				.groupBy(state.groupBy)
				.map((value, key) => ({ label: key, data: value }))
				.value()

			if(state.selectedGovernanceType === 'hpc-slurm') {
				newData = _.chain(graphData)
				.groupBy('title')
				.map((value, key) => ({ label: key, data: value }))
				.value()
			}
	
			newData.forEach(item => {
				item.data.forEach(row => {
					if (state.groupBy === 'resource_type') {
						if (!keys.filter(e => e === row.title).length) {
							keys.push(row.title)
						}
					} else {
						if(state.selectedGovernanceType === 'hpc-slurm') {
							if (!keys.filter(e => e === row.category).length) {
								keys.push(row.category)
							}
						} else {
							if (!keys.filter(e => e === row.resource_type).length) {
								keys.push(row.resource_type)
							}
						}
					}
				})
			})
	
			let rightSideArray = []
			let rightSideHighest = 0
			let rightSideTotal = 0
			let rightSideTotalTableData = {}
			newData.forEach((item, index) => {
				// keys.push(item[state.groupBy])
				let State = 'files_' + index
				let dataRow = {}
				let count = 0
				
				let orderData = _.orderBy(item.data, ['fail_count'], ['desc'])
				if (state.groupBy === 'resource_type') {
					keys.forEach(row => {
						count += orderData.filter(e => e.resource_type === item.label && e.title === row).length
							? orderData.filter(e => e.resource_type === item.label && e.title === row)[0].fail_count
							: 0
						dataRow[row] = orderData.filter(e => e.resource_type === item.label && e.title === row).length
							? orderData.filter(e => e.resource_type === item.label && e.title === row)[0].fail_count
							: 0
					})
				} else {
					if(state.selectedGovernanceType === 'hpc-slurm') {
						keys.forEach(row => {
							count += orderData.filter(e => e.title === item.label && e.category === row).length
								? orderData.filter(e => e.title === item.label && e.category === row)[0].fail_count
								: 0
							dataRow[row] = orderData.filter(e => e.title === item.label && e.category === row).length
								? orderData.filter(e => e.title === item.label && e.category === row)[0].fail_count
								: 0
						})
					} else {
						keys.forEach(row => {
							count += orderData.filter(e => e.title === item.label && e.resource_type === row).length
								? orderData.filter(e => e.title === item.label && e.resource_type === row)[0].fail_count
								: 0
							dataRow[row] = orderData.filter(e => e.title === item.label && e.resource_type === row).length
								? orderData.filter(e => e.title === item.label && e.resource_type === row)[0].fail_count
								: 0
						})
					}
				}
	
				// let orderKeys = dataRow
				// orderKeys = orderKeys.sort(function(a, b) { return obj[a] - obj[b] });
	
				dataRow.State = State
				dataRow.page = 'MultipleKeys'
				dataRow.name = item.label
				dataRow.total = count
				// dataRow.rightSideHighest = passCount + failCount
				// let passFailTotal = count
				rightSideTotal = graphData.length
				if (rightSideHighest < count) {
					rightSideHighest = count
				}
				if (count > 0) {
					rightSideArray.push(dataRow)
				}
	
				rightSideTotalTableData[State] = dataRow
			})
	
			let rightSideTotalData = {}
			let data = {}
			data.name = 'Violations'
			keys.forEach((key, j) => {
				data[key] = count(rightSideArray, key)
			})
			rightSideTotalData.data = data
			
			let rightSideHighestCount = rightSideHighest
			
			if (rightSideArray.length < 25) {
				let length = rightSideArray.length
				for (let i = length; i <= 25; i++) {
					let dataRow = {}
					dataRow.State = 'files_' + i
					dataRow.name = 'empty_data'
					keys.forEach((key, j) => {
						if (j === 0) {
							let count = 1
							if (rightSideHighestCount <= 5) {
								count = 1 / 5
							}
							dataRow[key] = count
						} else {
							dataRow[key] = 0
						}
					})
					rightSideArray.push(dataRow)
				}
			}
			
			// rightSideArray = _.orderBy(rightSideArray, ["fail_count"], ["desc"])
	
			setState(prevState => ({ 
				...prevState, 
				rightSideGraphColors,
				rightSideArray,
				rightSideKeys: keys,
				rightSideHighest,
				rightSideTotalData,
				rightSideTotal,
				rightSideGraphId: 'totalThreats',
				rightSideSvgGraphId: 'svgTotalThreats',
				showRightSideRadialChart: true,
				hideDistributionSection: true,
				showRadialLoading: false,
				rightSideTotalTableData,
				callRightSideDataFormat: true
			}), 
				// () => rightSideDataFormat()
			)
		}
	}, [state.callFormRadialBarChart, state.groupBy, state.radialBarData, state.selectedGovernanceType])

	const rightSideDataFormat = useCallback((data, State) => {
		let result = []
		let rightSidePageCount = 1
		let rightSidePageCountArray = []
		let ritPageStart = 0
		let ritPageEnd = 1

		// let splitName = data && data.name ? data.name.split(":") : []
		let controlName = data && data.name ? data.name : 'All'
		if (!State) {
			result = state.rightSideTotalData ? state.rightSideTotalData.data : {}
		} else if (state.rightSideTotalTableData.hasOwnProperty(State)) {
			result = state.rightSideTotalTableData[State]
		}

		let propsToRemove = ['name', 'State', 'total', 'page']
		result = removeProperties(result, ...propsToRemove)

		let rightSideDataLength = Object.entries(result).length
		if (rightSideDataLength > state.rightSidePerPage) {
			rightSidePageCount = Math.ceil(rightSideDataLength / state.rightSidePerPage)
			ritPageEnd = rightSidePageCount > 5 ? 5 : rightSidePageCount
			for (let i = 0; i < rightSidePageCount; i++) {
				rightSidePageCountArray.push(i + 1)
			}
		}

		setState(prevState => ({ 
			...prevState, 
			ritPageStart,
			ritPageEnd,
			rightSidePageCount,
			rightSidePageCountArray,
			rightSideFilteredTableData: result,
			controlName,
			showRightSideRadialChart: true
		}))
	}, [state.rightSidePerPage, state.rightSideTotalData, state.rightSideTotalTableData])

	useEffect(() => {
		if(state.callRightSideDataFormat) {
			setState(prevState => ({ ...prevState, callRightSideDataFormat: false }))
			rightSideDataFormat()
		}
	}, [state.callRightSideDataFormat, rightSideDataFormat])


	const count = (array, key) => {
		let data = array.reduce(function(r, a) {
			return r + a[key]
		}, 0)

		return data
	}	

	const removeProperties = (object, ...keys) => {
		return Object.entries(object).reduce(
			(prev, [key, value]) => ({ ...prev, ...(!keys.includes(key) && { [key]: value }) }),
			{}
		)
	}

	useEffect(() => {
		if(state.callGetInitializePaginationRight) {
			setState(prevState => ({ 
				...prevState, 
				rightSidePageCount: 1,
				currentPage: 1,
				rightSideStart: 0,
				rightSidePerPage: 5,
				disableLeftAction: true,
				disableRightAction: false,
				callGetInitializePaginationRight: false
			}))
		}
	}, [state.callGetInitializePaginationRight])

	// const navigatePage = (action, currentPage) => {
	// 	let ritPageStart = 0
	// 	let ritPageEnd = state.rightSidePageCount > 5 ? 5 : state.rightSidePageCount

	// 	let startRecord = state.rightSideStart
	// 	if (action === 'next') {
	// 		startRecord = startRecord + state.rightSidePerPage
	// 		currentPage = currentPage + 1
	// 	} else if (action === 'previous' && currentPage !== 1) {
	// 		currentPage = currentPage - 1
	// 		startRecord = startRecord - state.rightSidePerPage
	// 	} else if (action === 'start' && currentPage !== 1) {
	// 		currentPage = 1
	// 		startRecord = 0
	// 	} else if (action === 'end') {
	// 		currentPage = state.rightSidePageCount
	// 		ritPageStart = state.ritPageStart + (currentPage - 5)
	// 		ritPageEnd = state.rightSidePageCount
	// 		startRecord = (state.rightSidePageCount - 1) * state.rightSidePerPage
	// 	} else if (action === 'page') {
	// 		startRecord = (currentPage - 1) * state.rightSidePerPage
	// 	}

	// 	if (state.rightSidePageCount > 5) {
	// 		if (currentPage > 2 && (action === 'next' || action === 'page')) {
	// 			ritPageStart = state.ritPageStart + 1
	// 			ritPageEnd = state.ritPageEnd + 1
	// 		}

	// 		if (currentPage < 3 && (action === 'previous' || action === 'page') && state.ritPageStart > 1) {
	// 			ritPageStart = state.ritPageStart - 1
	// 			ritPageEnd = state.ritPageEnd - 1
	// 		}

	// 		if (ritPageEnd > state.rightSidePageCount) {
	// 			ritPageStart = state.ritPageStart
	// 			ritPageEnd = state.rightSidePageCount
	// 		}
	// 	}

	// 	let disableLeftAction = true
	// 	if (currentPage > 1) {
	// 		disableLeftAction = false
	// 	}

	// 	let disableRightAction = false
	// 	if (currentPage === state.rightSidePageCount) {
	// 		disableRightAction = true
	// 	}

	// 	setState(prevState => ({ ...prevState, currentPage, ritPageStart, ritPageEnd, rightSideStart: startRecord, disableLeftAction, disableRightAction }),
	// 		() => rightSideTableRef.current.scrollIntoView()
	// 	)
	// }

	const navigatePage = (action, currentPage) => {
        if(currentPage > 0) {
            let startRecord = state.startRecord
            if(action === 'next' && currentPage !== state.totalPages) {
                startRecord = startRecord + state.perPage
                currentPage = currentPage + 1
            } else if(action === 'previous' && currentPage !== 0) {
                currentPage = currentPage - 1
                startRecord = startRecord - state.perPage
            } else if(action === 'start' && currentPage !== 0) {
                currentPage = 1
                startRecord = 0
            } else if(action === 'end' && currentPage !== 0) {
                currentPage = state.totalPages
                startRecord = (state.totalPages - 1) * state.perPage
            }
            setState(prevState => ({ ...prevState, currentPage, startRecord }))
        } 
	}

	const formDownloadData = (data, index, datIndex) => {
		let dataResult =
			state['filteredArray_' + index + '_' + datIndex] &&
			state['filteredArray_' + index + '_' + datIndex].length
				? state['filteredArray_' + index + '_' + datIndex]
				: data

		if (
			state['selectedStatus_' + index + '_' + datIndex] &&
			state['selectedStatus_' + index + '_' + datIndex] !== ''
		) {
			dataResult = dataResult.filter(e => e.status === state['selectedStatus_' + index + '_' + datIndex])
		}

		if (
			state['selectedRisk_' + index + '_' + datIndex] &&
			state['selectedRisk_' + index + '_' + datIndex] !== ''
		) {
			dataResult = dataResult.filter(e => e.risk === state['selectedRisk_' + index + '_' + datIndex])
		}

		if (dataResult.length) {
			let headers = []
			let usedKeys = []
			Object.keys(dataResult[0]).forEach(item => {
				if (item !== 'resolution_status_info' && item !== 'category' && item !== 'tags' && item !== 'version') {
					let headerRow = {}
					headerRow.label = capitalizeTheFirstLetterOfEachWord(item)
					headerRow.key = item
					headers.push(headerRow)
					usedKeys.push(item)
				}
			})

			let downloadData = []
			dataResult.forEach((item, index) => {
				let dataRow = {}
				Object.entries(item).forEach(([key, value]) => {
					if (usedKeys.includes(key)) {
						dataRow[key] = value
					}
				})
				downloadData.push(dataRow)
			})

			setState(prevState => ({ 
				...prevState, 
				['headers_' + index + '_' + datIndex]: headers,
				['download_data_' + index + '_' + datIndex]: downloadData,
			}))
		}
	}

	const handleChildClick = (event, type, dropdownType, section) => {
		event.stopPropagation();
		let clickedChild = []
		if(type === 'child') {
            if(state.clickedChild && state.clickedChild.length) {
                state.clickedChild.forEach(row => {
                    setState(prevState => ({ ...prevState, [row]: false }))
                })
            }
            clickedChild.push(section)
            setState(prevState => ({ ...prevState, [section]: dropdownType === "singleDropDown" && state[section] ? false : true, clickedChild }))
        } else if(type === 'parent') {
            if(state.clickedChild && state.clickedChild.length) {
                state.clickedChild.forEach(row => {
                    setState(prevState => ({ ...prevState, [row]: false }))
                })
            }
            
            setState(prevState => ({ ...prevState, clickedChild }))
        }
	}

	/**
	 * Renders Compliance Dashboard
	 */
	return (
		<div onClick={(event) => handleChildClick(event, 'parent')}>
            <div className={`${state.showLoading ? '' : 'hidden'}`}>
                <div className='loading-wave'>
                    <div className='overlayEqualizerLoader'>
                        <div className="loading-bar"></div>
                        <div className="loading-bar"></div>
                        <div className="loading-bar"></div>
                        <div className="loading-bar"></div>
                    </div>
                </div>
            </div>            
			{/* {state.showDashboardPanel ? (
				<DashboardSidePanel
					closeSidePanel={() => this.setState({ showDashboardPanel: false })}
					selectedViolation={state.selectedViolation}
				/>
			) : null} */}
			<div className="bg-nero w-full h-fit relative stickyUiChange">
                <div className="grid grid-cols-12 items-center pb-4">
                    <div className="xl:col-span-5 col-span-12 self-center xl:mt-4">
                        <p className="text-white mx-4 text-xl md:mt-0 mt-4 font-semibold">Governance Dashboard</p>
						<p className="mx-4 text-white">Dashboard view of various types of policies performed on your Cloud</p>
					</div>
					<div className={`xl:col-span-7 col-span-12 items-center lg:mt-4 mt-6 lg:ml-4 ${state.showLoading ? 'disabled' : ''}`}>
						<div className={`flex flex-wrap justify-end`}>
							<div onClick={(event) => {
									if(!state.isGovernanceTypeOpen) {
										event.preventDefault();
										handleChildClick(event, 'child', 'singleDropDown', "isGovernanceTypeOpen")
									}
								}}
							>
								<label className='mb-1 mt-1 text-white flex'>Governance Type</label>
								<p className={`flex bg-transparent border border-lightGray rounded-md px-2 py-1 justify-between px-2 mb-0 truncate cursor-pointer ${state.selectedGovernanceType ? 'text-white' : 'text-lightGray'}`}>
									{state.selectedGovernanceType && state.governanceTypes && state.governanceTypes.filter(e => e.value === state.selectedGovernanceType).length ? 
										state.governanceTypes.filter(e => e.value === state.selectedGovernanceType)[0].label 
									: 'Select'}
									<Icon icon="icon-park-solid:down-one" className={`${state.selectedGovernanceType ? "text-white" : "text-lightGray"} self-center`} width="16" height="16" />
								</p>
								{state.isGovernanceTypeOpen ?
									<MultiSelectSection 
										fields={["value", "label"]}
										options={state.governanceTypes ? state.governanceTypes : []}
										widthClass={'minWidth220'}
										selectedValues={state.selectedGovernanceType ? state.selectedGovernanceType : ''}
										callbackMultiSelect={(value) => {
											if(!value || typeof(value) === 'string') {
												setState(prevState => ({ ...prevState, groupBy: value === 'hpc-slurm' ? 'title' : 'resource_type', selectedGovernanceType: value, selectedProject: '', selectedProjectTags: [], selectedAccount: '', selectedRegion: [], selectedClusters: [], selectedQueue: [], callAccountList: true, callRegionList: true, callClusterList: true, selectedStatus: "" }))
											} else {
												value.preventDefault()
												handleChildClick(value, "search", 'singleDropDown', "")
											}
										}}
										hideSearch={false}
										singleSelection={true}
									/>
								: null}
							</div>
							<div className={`flex ml-3`}>
								<span className={`mr-2 f12 self-center text-lightGray`}>Last</span>
								<span className={`mr-2 font-semibold self-center ${state.selectedDuration === '+90d' ? 'border border-info px-2 py-1 rounded-full text-white' : 'cursor-pointer text-info' }`} onClick={() => setState(prevState => ({ ...prevState, selectedDuration: '+90d' }))}>3 Months</span>
								<span className={`mr-2 font-semibold self-center ${state.selectedDuration === '+60d' ? 'border border-info px-2 py-1 rounded-full text-white' : 'cursor-pointer text-info' }`} onClick={() => setState(prevState => ({ ...prevState, selectedDuration: '+60d' }))}>2 Month</span>
								<span className={`mr-2 font-semibold self-center ${state.selectedDuration === '+30d' ? 'border border-info px-2 py-1 rounded-full text-white' : 'cursor-pointer text-info' }`} onClick={() => setState(prevState => ({ ...prevState, selectedDuration: '+30d' }))}>1 Month</span>
								<span className={`mr-2 font-semibold self-center ${state.selectedDuration === '+14d' ? 'border border-info px-2 py-1 rounded-full text-white' : 'cursor-pointer text-info' }`} onClick={() => setState(prevState => ({ ...prevState, selectedDuration: '+14d' }))}>2 Week</span>
								<span className={`mr-2 font-semibold self-center ${state.selectedDuration === '+7d' ? 'border border-info px-2 py-1 rounded-full text-white' : 'cursor-pointer text-info' }`} onClick={() => setState(prevState => ({ ...prevState, selectedDuration: '+7d' }))}>1 Week</span>
							</div>
							<div className={`mx-2 self-center`}>
								<div className="flex space-x-0" role="group">
									<button type="button" className="bg-info hover:bg-blue-700 text-white border-r-2 font-bold px-2 py-1 rounded-l inline-flex items-center"
										onClick={() => setState(prevState => ({ ...prevState, showLoading: true, callOnSearch: true }))}
									>
										<span className='text-white'>Search</span>
									</button>
									<button type="button" className="bg-info hover:bg-red-700 text-white font-bold px-2 py-1 rounded-r inline-flex items-center"
										onClick={() => onReset()}
									>
										<Icon icon="mdi:reload" className="text-white" width="18" height="18" />
										{/* <Icon icon="bytesize:reload" className={'text-white'} color="#ffffff" style={{stroke: "#FFFFFF", strokeWidth: 2}} width="18" height="18" /> */}
									</button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className={`p-2 bg-muted rounded-md`}>
				<div className='flex flex-wrap item-stretch'>
					<div className={`${state.minimizeLeftDiv ? 'lg:w-1/12 md:w-2/5 w-full' : 'lg:w-1/5 md:2/5 w-full'} border-right flex flex-col flex-grow`} ref={leftSectionRef}>
						<div className='bg-dark rounded-md overflow-y-auto'>
							{state.selectedGovernanceType !== 'opa' ? (
								<div className='pt-3'>
									<div className={`flex justify-between px-3 ${state.minimizeLeftDiv ? 'mb-1' : 'mb-3'}`}>
										{!state.minimizeLeftDiv ? (
											<p className='mb-0 mt-1 small text-white'> Showing {state.filteredAccount && state.filteredAccount.length} of Total
												{state.accountsNew && state.accountsNew.length} {state.selectedGovernanceType === 'hpc-slurm' ? ' Cluster(s)' : ' Account(s)'} </p>
										) : (
											<p className='mb-0 mt-1 small text-white'> Showing {state.filteredAccount && state.filteredAccount.length} </p>
										)}
										<div className='flex'>
											{!state.minimizeLeftDiv && state.accountsNew && state.accountsNew.length ? 
												<Search
													data={state.accountsNew}
													topClassName={'bg-transparent text-white border border-lightGray rounded-lg cursor-pointer'}
													searchClassName={'px-1'}
													searchIconColor={'text-lightGray'}
													searchPlaceHolder={'Search....'}
													className={'bg-transparent text-white'}
													widthHeight={25}
													hideInputSection={true}
													filteredData={filteredAccount => setState(prevState => ({ ...prevState,  filteredAccount }))}
												/>
											: null}
											{state.minimizeLeftDiv ?
												<button className='bg-transparent border-0 self-center text-white p-0 z999 hidden lg:block'>
													<Icon icon="basil:expand-outline" width="20" height="20" className={`text-cyon mt-3 cursor-pointer`} onClick={() => setState(prevState => ({ ...prevState,  minimizeLeftDiv: false }))} />
												</button>
											:
												<button className='bg-transparent border-0 self-center text-white p-0 z999 hidden lg:block'>
													<Icon icon="la:compress" width="20" height="20" className={`text-cyon mt-3 cursor-pointer`} onClick={() => setState(prevState => ({ ...prevState,  minimizeLeftDiv: true }))} />
												</button>
											}
										</div>
									</div>
									{state.minimizeLeftDiv && state.accountsNew && state.accountsNew.length ? (
										<Search
											data={state.accountsNew}
											topClassName={'bg-transparent text-white border border-lightGray rounded-lg cursor-pointer'}
											searchClassName={'px-1'}
											searchIconColor={'text-lightGray'}
											searchPlaceHolder={'Search....'}
											className={'bg-transparent text-white'}
											widthHeight={25}
											hideInputSection={true}
											filteredData={filteredAccount => setState(prevState => ({ ...prevState, filteredAccount }))}
										/>
									) : null}
									{state.filteredAccount && state.filteredAccount.length ? (
										state.filteredAccount.map((item, i) => {
											return(
												<div key={'filter_'+i} className={`cursor-pointer ${state.selectedAccount === (item.account_id || item.cluster_name) ? 'bg-dark3' : ''} px-3`} onClick={() => onClickAccFunction(item.account_id ? item.account_id : item.cluster_name)}>
													<div className={`flex justify-between py-2 ${i !== 0 ? 'border-top' : ''}`}>
														<div className='w-55 self-center'>
															{item.account_id ?
																<React.Fragment>
																<span className='f16 font-weight-bold text-white'>
																	{capitalizeFirstLetter(getAccountNameFromId(item.account_id, state.accountsNew))}</span>
																<p className='f12 m-0'>{item.account_id}</p>
																</React.Fragment>
															:
																<p className='f12 m-0'>{item.cluster_name}</p>
															}
														</div>
														{!state.minimizeLeftDiv ?
															<div className='box bg-muted flex p-2 shadow-none heihgtFitContent mr-2 self-center'>
																<div className='mr-2 width75'>
																	<p className='font-weight-bold m-0 f12'>
																		{state.minimizeLeftDiv ? 'P' : 'Pass'}&nbsp;:&nbsp;
																		<span className='f16 font-weight-bold mb-0 text-warning'>{item.pass_count && item.pass_count}</span>
																	</p>
																</div>
																<div className='mr-2 width75'>
																	<p className='font-weight-bold m-0 f12'>
																		{state.minimizeLeftDiv ? 'F' : 'Fail'}&nbsp;:&nbsp;
																		<span className='f16 font-weight-bold mb-0 text-dark-theme-orange'>{item.fail_count && item.fail_count}</span>
																	</p>
																</div>
															</div>
														: null}
													</div>
												</div>
											)
										})
									) : (
										<div className='flex justify-center m-4'>
											<p>There are no data on this criteria. Please try adjusting your search.</p>
										</div>
									)}
								</div>
							) : null}
						</div>
					</div>
					<div className={`${state.minimizeLeftDiv ? 'lg:w-11/12 md:w-10/12 w-9/12' : 'lg:w-4/5 md:3/5 w-full'} overflow-y-Auto flex flex-col flex-grow lg:pl-2 md:pl-0 pl-0 lg:mt-0 md:mt-2 mt-2`} ref={rightSectionRef}>
						<div className='flex flex-wrap border-b border-lightGray pb-3'>
							<div className='lg:w-3/4 w-full lg:pr-3 pr-0'>
								<div className='bg-dark rounded-md p-3 flex flex-wrap'>
									{state.transitionTrend && state.transitionTrend.details && Object.entries(state.transitionTrend.details).length ? 
										Object.entries(state.transitionTrend.details).map(([key, value]) => {
											return key === 'new' || key === 'pending' ?
												<div className="lg:w-1/2 md:w-1/2 w-full flex" key={'filter_'+key}>
													{state[key+'_trend_donut'] ? 
														<div className='mx-2'>
															<ApexDonutChart
																graphData={state[key+'_trend_donut']}
																legend={false}
																showTotal={true}
																showTotalLable={true}
																totalLableType={'integer'}
																showGraphHeading={false}
																height={144}
																width={144}
																legendWidth={200}
																size={'70%'}
																gradient={false}
																gradientColor={['#A88CCC', '#D88ACF']}
																colors={['#5F5BA2', '#9A77D1', '#3F73AD', '#24A597']}
																className={'transparentTooltip'}
															/>
														</div>
													: null}

													<div className='pr-2 ml-5 self-center'>
														<p className='flex text-white text-lg'>
															<span className='mr-2'>{capitalizeFirstLetter(key)}</span>
															<b>{value.count ? value.count : 0}</b>
															<Icon icon={`uil:arrow-${value.trend === null || value.trend === 'increase' ? 'up' : 'down'}`} className={`${value.trend === null || value.trend === 'increase' ? 'text-success' : 'text-danger'}`} />
															<span>{value.percent ? value.percent : 0}%</span>
														</p>
														<div className='flex'>
															<div className={`mr-3 risk-critical`}>
																<p className='mb-0 font-weight-bold'>{value.severity_wise && value.severity_wise.Critical ? value.severity_wise.Critical : 0}</p>
																<p className='small font-weight-bold self-end'>Critical</p>
															</div>
															<div className={`mr-3 risk-high`}>
																<p className='mb-0 font-weight-bold'>{value.severity_wise && value.severity_wise.High ? value.severity_wise.High : 0}</p>
																<p className='small font-weight-bold self-end'>High</p>
															</div>
															<div className='mr-3 risk-medium'>
																<p className='mb-0 font-weight-bold'>{value.severity_wise && value.severity_wise.Medium ? value.severity_wise.Medium : 0}</p>
																<p className='small font-weight-bold self-end'>Medium</p>
															</div>
															<div className='mr-3 risk-low'>
																<p className='mb-0 font-weight-bold'>{value.severity_wise && value.severity_wise.Low ? value.severity_wise.Low : 0}</p>
																<p className='small font-weight-bold self-end'>Low</p>
															</div>
														</div>
														{state[key + '_trend_data'] && Object.keys(state[key + '_trend_data']).length ? 
															<div className='w-full -ml-2 -mt-1 -mb-4'>
																<ApexAreaChart
																	graphData={state[key + '_trend_data']}
																	name={'New'}
																	xaxis={false}
																	yaxis={true}
																	yaxisLabe={false}
																	markerSize={2}
																	hideMarkerLength={30}
																	gradient={true}
																	gradientColor={['#775BA2', '#775BA2']}
																	colors={['#775BA2']}
																	sparkline={false}
																	zoom={false}
																	height={85}
																	width={'100%'}
																	grid={true}
																	className={'transparentTooltip'}
																/>
															</div>
														: null}
													</div>
												</div>
											: null
										})
									: null}
								</div>
							</div>
							<div className='lg:w-1/4 w-full lg:mt-0 mt-2'>
								<div className='bg-dark rounded-md p-3'>
									<div className={`w-full`}>
										<p className='mb-1 text-lg text-white'>Policies</p>
										<div className='flex'>
											<div className='pr-2 text-warning'>
												<p className='f12 flex mb-0 font-weight-bold mr-1'>
													{state.passSum && state.passSum}
													<small className='flex pl-1 text-lightGray self-center'>Pass</small>
												</p>
											</div>
											<div className='text-dark-theme-orange'>
												<p className='f12 flex mb-0 font-weight-bold mr-1'>
													{state.failSum && state.failSum}
													<small className='flex pl-1 text-lightGray self-center'>Fail</small>
												</p>
											</div>
										</div>
									</div>
									{state.multi_pass_fail_series && state.multi_pass_fail_series.length ?
										<div className='w-full transparentTooltip mt-3 mb-2'>
											<Chart
												type='bar'
												series={state.multi_pass_fail_series}
												options={state.multi_pass_fail_options}
												height={75}
											/>
										</div>
									: null}
								</div>
							</div>
						</div>
						<div className='flex flex-wrap border-b border-lightGray py-3 ml-0 '>
							<div className='lg:w-3/5 w-full'>
								<div className='p-2 bg-dark rounded-md'>
									{state.dayWiseRiskCountGraphData && Object.keys(state.dayWiseRiskCountGraphData).length ?
										<ApexRiskStackedBarChart
											graphData={state.dayWiseRiskCountGraphData}
											graphRiskCountSection={true}
											sparkline={false}
											yaxis={true}
											yaxisLabel={true}
											xaxis={true}
											xaxisFormat={'string'}
											xaxisLabel={true}
											axisLabelColor={'#50586A'}
											legend={false}
											stacked={true}
											height={265}
											horizontal={false}
											barHeight={'40%'}
											barEndShape={'flat'}
											columnWidth={'25%'}
											backgroundBarShape={'rounded'}
											backgroundBarColors={['#333947']}
											showBackgroundBarColors={true}
											className={'transparentTooltip'}
											colors={['#5F5BA2', '#9A77D1', '#3F73AD', '#039BE5']}
										/>
									: null}
								</div>
							</div>
							<div className='lg:w-2/5 w-full lg:pl-3 pl-0 lg:mt-0 mt-2'>
								<div className='bg-dark p-3 rounded-md'>
									<label className='text-white'>Top 5 Violations</label>
									{state.top5Violations && Object.entries(state.top5Violations).length ?
										<div className='-mt-2 -mb-2'>
											<ApexBarChart
												graphData={state.top5Violations}
												sparkline={false}
												yaxis={false}
												yaxisLabel={false}
												xaxis={true}
												xaxisFormat={'string'}
												xaxisLabel={true}
												axisLabelColor={'#B8BBBE'}
												paddingLeft={0}
												legend={false}
												stacked={false}
												height={319}
												horizontal={true}
												barHeight={'40%'}
												barEndShape={'rounded'}
												columnWidth={'25%'}
												gradient={true}
												gradientColor={['#039BE5', '#5F5BA2']}
												hideTooltipValue={true}
												backgroundBarShape={'rounded'}
												backgroundBarColors={['#333947']}
												showBackgroundBarColors={false}
												className={'transparentTooltip'}
											/>
										</div>
									: null}
								</div>
							</div>
						</div>
						<div className='my-3 bg-dark p-2 rounded'>
							<div className='p-2 rounded'>
								<div className='flex justify-between cursor-pointer' onClick={() => setState(prevState => ({ ...prevState, hideDistributionSection: !state.hideDistributionSection }))}>
									<p className='mb-0 text-purple3 text-lg flex'>{' '} Distribution</p>
									<Icon icon={`fa:angle-${state.hideDistributionSection ? 'up' : 'down'}`} width="20" height="20" className='text-lightGray ml-4 cursor-pointer'/>
								</div>
								{!state.hideDistributionSection ? (
									<div className={`w-full mt-1`} ref={rightSideTableRef}>
										<div className='flex flex-wrap justify-between'>
											<div className=''>
												<p className='mb-0 text-white hidden'>Distribution</p>
												<p className='text-xs'>Showing distribution of Failed Checks</p>
											</div>
											<div className='flex flex-wrap'>
												<p className='mb-0 mr-2 mt-2 flex'>show by category {state.groupBy}</p>
												<div className="border-b border-lightGray mx-2 flex hidden">
                                                    <select className="form-control w-fit bg-transparent text-white truncate" 
                                                        onChange={(e) => {
                                                            const selectedValue = e.target.value;
                                                            setState((prevState) => ({ ...prevState, groupBy: selectedValue}));
                                                            onChangeGroupBy('graph')

                                                        }}
                                                        value={state.groupBy === 'resource_type' ? 'Service' : (state.groupBy === 'title' ? 'Category' : 'Select')}
                                                    >
														<option key={'resource_type'} className='text-black' value={'resource_type'}>Service</option>
														<option key={'title'} className='text-black' value={'title'}>Category</option>
                                                    </select>
                                                </div>
											</div>
										</div>
										<div className='flex flex-wrap'>
											<div className='lg:w-3/5 w-full p-0' ref={rightRadialStackedBarWidthRef}>
												{state.showRightSideRadialChart ? (
													<StackedCircleBarChart
														ID={state.rightSideGraphId}
														svgID={state.rightSideSvgGraphId}
														width={
															rightRadialStackedBarWidthRef.current &&
															rightRadialStackedBarWidthRef.current.offsetWidth
														}
														data={state.rightSideArray}
														keys={state.rightSideKeys}
														userHighestValue={true}
														highestValue={state.rightSideHighest}
														hoverData={state.rightSideTotalData}
														totalValue={state.rightSideTotal}
														selectedState={
															state.selectedChartControl ? state.selectedChartControl : ''
														}
														tableData={(data, State) => {
															if (State && state.selectedChartControl !== State) {
																setState(prevState => ({ ...prevState, selectedChartControl: State, showRightSideRadialChart: false }))
																rightSideDataFormat(data, State)
															}
														}}
														legendText={'Violations'}
														colors={state.rightSideGraphColors}
													/>
												) : (
													<div className='w-full flex justify-center mt-4'>
														<svg className="animate-spin h-5 w-5 mr-2 text-white" fill="currentColor" viewBox="0 0 24 24">
															<circle cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="2" fill="none" />
															<path d="M4 12a8 8 0 0112-6.9" />
														</svg>
													</div>
												)}
											</div>
											{state.showRightSideRadialChart ? (
												<div className='lg:w-2/5 w-full lg:pl-3 pl-0 lg:mt-0 mt-2'>
													<p className='small mb-0 mt-2'>
														Status by Violations
														<p className='small'>
															{state.controlName && state.controlName !== '' ? state.controlName : <span>&nbsp;</span>}
														</p>
													</p>
													<div className='mt-3 radialTableSection' style={{ minHeight: '300px' }}>
														{state.rightSideFilteredTableData &&
														Object.entries(state.rightSideFilteredTableData).length ?
															<div className='' style={{ minHeight: '260px' }}>
																{Object.entries(state.rightSideFilteredTableData).slice(state.rightSideStart, state.rightSideStart + state.rightSidePerPage).map(([key, value], i) => {
																	return(
																		<div key={'right_'+i} className='mt-2 p-0 bg-dark'>
																			<div className='border border-info flex p-2'>
																				<p className='w-1/6 text-lg text-white p-0 m-0'>{value}</p>
																				<div className='w-5/6 pl-3 text-lg' id={'description_' + i}>
																					<p className='text-nowrap mb-0 self-end ml-2 cursor-pointer'>
																						{key}
																					</p>
																				</div>
																			</div>
																		</div>
																	)
																})}
															</div>
														: null}
														{state.rightSidePageCount > 1 ? 
															<div className='flex justify-end text-white w-full'>
																<span className='mx-3'>Page <strong>{state.currentPage} of {state.totalPages}</strong> </span>
																<div className="pagination text-sm">
																	<button><Icon icon="system-uicons:push-left" width="20" height="20" className={`ml-2 cursor-pointer ${state.currentPage === 1 ? 'pointer-events-none text-lightGray' : 'text-info'}`} onClick={() => navigatePage('start', state.currentPage)} /></button> 
																	<button><Icon icon="prime:angle-left" width="20" height="20" className={`ml-2 cursor-pointer ${state.currentPage === 1 ? 'pointer-events-none text-lightGray' : 'text-info'}`} onClick={() => navigatePage('previous', state.currentPage)} /></button> 
																	{/* <span>
																		Go to
																		<input
																			type='number'
																			value={state.pageNumber || state.pageNumber === "" ? state.pageNumber : state.currentUpcoming}
																			onChange={e => {
																				const page = e.target.value ? Number(e.target.value) : ""
																				gotoPage(page)
																			}}
																			className="ml-1 inputClass"
																		/>
																	</span> */}
																	<button><Icon icon="prime:angle-right" width="20" height="20" className={`ml-3 cursor-pointer ${state.currentPage === state.totalPages ? 'pointer-events-none text-lightGray' : 'text-info'}`} onClick={() => navigatePage('next', state.currentPage)}/></button> 
																	<button><Icon icon="system-uicons:push-right" width="20" height="20" className={`ml-2 cursor-pointer ${state.currentPage === state.totalPages ? 'pointer-events-none text-lightGray' : 'text-info'}`} onClick={() => navigatePage('end', state.currentPage)} /></button>
																</div>
															</div>
														: null}
													</div>
												</div>
											) : null}
										</div>
									</div>
								) : null}
							</div>
						</div>
						<div className='my-3 bg-dark px-2 py-3 rounded-md'>
							<div className='w-full p-0'>
								<div className='flex flex-wrap justify-between mb-1'>
									<div className='flex mb-1'>
										<p className='mb-0 mr-2 mt-2'>show by category</p>
										<div className='w-10 select-sm-bg curvedDropdown minWidth150 hidden' ref={groupByRef}>
											<div className='dropdown'>
												<span className='dropdown-toggle flex justify-between px-2 text-white'
													onClick={() =>
														setState(prevState => ({ ...prevState, showGroupByOptions: !state.showGroupByOptions }))
													}
												>
													{state.groupBy === 'resource_type' ?
														'Service'
													: state.groupBy === 'title' ?
														'Category'
													:
														<span className='placeholder'>Select</span>
													}
													<span className='caret'></span>
													{/* <i className="fas fa-angle-down"></i> */}
												</span>
												<ul className={`dropdown-menu p-0 ${state.showGroupByOptions ? 'show' : ''}`}>
													{state.selectedGovernanceType !== 'opa_kubernetes' && state.selectedGovernanceType !== 'opa' ?
														<li onClick={() =>
																setState(prevState => ({ ...prevState, groupBy: 'resource_type', showGroupByOptions: false }), () =>
																	onChangeGroupBy()
																)
															}
															value={'resource_type'}
															className={`${state.groupBy === 'resource_type' ? 'selected' : ''}`}
														>
															Service
														</li>
													:null}
													<li onClick={() => {
															setState(prevState => ({ ...prevState, groupBy: 'title', showGroupByOptions: false }))
															onChangeGroupBy()
														}}
														value={'title'}
														className={`${state.groupBy === 'title' ? 'selected' : ''}`}
													>
														Category
													</li>
												</ul>
											</div>
										</div>
									</div>
									<div className='flex justify-end mb-1'>
										{state.viloationDataNew && state.viloationDataNew.length ? (
											<div className='flex'>
												<CSVLink
													data={state.violationAllResults ? state.violationAllResults : []}
													headers={state.violationHeaders ? state.violationHeaders : []}
													filename={'governance_total_violations.csv'}
													className={''}
													target='_blank'
												>
													<Icon icon="material-symbols:download" className='text-gray5 self-center mr-2 self-center' width="22" height="22"/>
												</CSVLink>
												<div className='mr-2'>
												<Search
													data={state.viloationDataNew}
													topClassName={'bg-transparent text-white border border-lightGray rounded-lg cursor-pointer'}
													searchClassName={'px-1'}
													searchIconColor={'text-lightGray'}
													searchPlaceHolder={'Search....'}
													className={'bg-transparent text-white'}
													widthHeight={25}
													hideInputSection={true}
													filteredData={(filteredViolationDataNew, searchText) => {
														setState(prevState => ({ ...prevState, filteredViolationDataNew, callcombineAllVilationData: true }))
													}}
												/>
												</div>
											</div>
										) : null}
										<div className='self-center'>
											<ul className="flex mb-0 justify-end">
												<li className="flex items-center mr-2">
													<span className="block w-2.5 h-2.5 bg-critical rounded-full"></span> 
													<span className="text-lightGray ml-1">Critical</span>
												</li>
												<li className="flex items-center mr-2">
													<span className="block w-2.5 h-2.5 bg-high rounded-full"></span> 
													<span className="text-lightGray ml-1">High</span>
												</li>
												<li className="flex items-center mr-2">
													<span className="block w-2.5 h-2.5 bg-medium rounded-full"></span> 
													<span className="text-lightGray ml-1">Medium</span>
												</li>
												<li className="flex items-center">
													<span className="block w-2.5 h-2.5 bg-low rounded-full"></span> 
													<span className="text-lightGray ml-1">Low</span>
												</li>
											</ul>
										</div>
									</div>
								</div>
								{state.filteredViolationDataNew && state.filteredViolationDataNew.length ? (
									state.filteredViolationDataNew.map((item, index) => {
										return(
											<div key={'filterViolation_'+index} className={`card bg-dark3 border-0 mb-2`}>
												<div id={'heading_event'}
													onClick={() =>
														setState(prevState => ({ ...prevState, ['expand_parent_' + index]: !state['expand_parent_' + index] }))
													}
												>
													<div className='p-3 mb-0 flex justify-between'>
														<div className='flex text-white'>
															{state['expand_parent_' + index] ?
																<Icon icon="zondicons:minus-outline" className='mr-1 self-center cursor-pointer' width={16} height={16} />
															:
																<Icon icon="tabler:circle-plus" className='mr-1 self-center cursor-pointer' width={20} height={20} />
															}
															<span className='self-center font-semibold'>{state.groupBy !== 'resource_type' ? item.category : item.resource_type}</span>
														</div>
														<div className='flex'>
															{item.critical ? (
																<span className={`mr-2 self-start bg-critical rounded-full px-2 text-white`}> C: {item.critical} </span>
															) : null}
															{item.high ? (
																<span className={`mr-2 self-start bg-high rounded-full px-2 text-white`}> H: {item.high} </span>
															) : null}
															<div className='text-info self-center'>
																Showing{' '}
																{state.groupBy === 'resource_type' ? item.category_count + ' service(s)' : item.service_count + (item.category_count > 1 ? ' category' : ' categories')}{' '} with {item.violation_count} violation(s) in {item.policy_count}{' '} {item.policy_count > 1 ? 'policies' : 'policy'}
															</div>
														</div>
													</div>
												</div>
												{state['expand_parent_' + index] ?
													<div className=''>
													{item.governance_results.map((dat, datIndex) => {
														return(
															<div key={'dat_'+datIndex} className='pb-2'>
																<div className={`bg-dark mx-2 p-3 rounded-md`}>
																	<div className='p-3 mb-0 flex justify-between'>
																		<div className='flex text-white'
																			onClick={() =>
																				setState(prevState => ({ ...prevState,['expand_child_' + index + '_' + datIndex]: !state['expand_child_' + index + '_' + datIndex] }))
																			}
																		>
																			{state['expand_child_' + index + '_' + datIndex] ?
																				<Icon icon="zondicons:minus-outline" className='mr-1 self-center cursor-pointer' width={16} height={16} />
																			:
																				<Icon icon="tabler:circle-plus" className='mr-1 self-center cursor-pointer' width={20} height={20} />
																			}
																			<span className='self-center font-semibold'>{dat.governance_item}</span>
																		</div>
																		<div className='flex'>
																			{state['expand_child_' + index + '_' + datIndex] &&
																			dat.results && dat.results.length ? (
																				<React.Fragment>
																					<div onClick={(event) => {
																							if(!state['isExpandChild_'+index+'_'+datIndex]) {
																								event.preventDefault();
																								handleChildClick(event, 'child', 'singleDropDown', ['isExpandChild_'+index+'_'+datIndex])
																							}
																						}}
																					>
																						<p className={`flex bg-transparent border-b border-lightGray px-2 py-1 justify-between px-2 mb-0 w-fit truncate cursor-pointer ${state['selectedRisk_' +index +'_' +datIndex] ? 'text-white' : 'text-lightGray'}`}>
																							{state['selectedRisk_' +index +'_' +datIndex] ? state['selectedRisk_' +index +'_' +datIndex] : 'Select'}
																							<Icon icon="icon-park-solid:down-one" className={`${state['selectedRisk_' + index + '_' + datIndex] ? "text-white" : "text-lightGray"} self-center`} width="16" height="16" />
																						</p>
																						{state['isExpandChild_'+index+'_'+datIndex] ?
																							<MultiSelectSection 
																								fields={["value", "label"]}
																								options={riskOptions ? riskOptions : []}
																								widthClass={'minWidth220'}
																								selectedValues={state['selectedRisk_' + index + '_' + datIndex] ? state['selectedRisk_' + index + '_' + datIndex] : ''}
																								callbackMultiSelect={(value) => {
																									if(!value || typeof(value) === 'string') {
																										setState(prevState => ({ ...prevState, ['selectedRisk_' +index +'_' +datIndex]: value, issueStatusFilter: false, }))
																									} else {
																										value.preventDefault()
																										handleChildClick(value, "search", 'singleDropDown', "")
																									}
																								}}
																								hideSearch={false}
																								singleSelection={true}
																								removeTopOptions={true}
																							/>
																						: null}
																					</div>
																					<div className='ml-3 flex'>
																						<div className='self-center'>
																							<span 
																								onClick={() => {
																									setState(prevState => ({ ...prevState, ['selectedStatus_' + index + '_' + datIndex]: state['selectedStatus_' + index + '_' + datIndex] === 'pass' ? '' : 'pass' }))
																									formDownloadData(index, datIndex)
																								}}
																								className={`cursor-pointer px-2 py-1 rounded-full mr-2 text-white text-xs ${state['selectedStatus_' + index + '_' + datIndex] === 'pass' ? 'bg-warning' : 'border border-warning' }`}
																							>
																								Pass
																							</span>
																						</div>
																						<div className='self-center'>
																							<span 
																								onClick={() => {
																									setState(prevState => ({ ...prevState, ['selectedStatus_' + index + '_' + datIndex]: state['selectedStatus_' + index + '_' + datIndex] === 'fail' ? '': 'fail' }))
																									formDownloadData(index, datIndex)	
																								}}
																								className={`cursor-pointer px-2 py-1 rounded-full mr-2 text-white text-xs ${state['selectedStatus_' + index + '_' + datIndex] === 'fail' ? 'bg-danger' : 'border border-danger' }`}
																							>
																								Fail
																							</span>
																						</div>
																					</div>
																				</React.Fragment>
																			) : null}
																			{state['expand_child_' + index + '_' + datIndex] &&
																			dat.results && dat.results.length ? (
																				<div className='flex'>
																					<div className='form-group mb-0'>
																						<Search
																							ID={'anomaly_search'}
																							data={dat.results ? dat.results : dat.results}
																							topClassName={'bg-transparent text-white border border-lightGray rounded-lg cursor-pointer'}
																							searchClassName={'px-1'}
																							searchIconColor={'text-lightGray'}
																							searchPlaceHolder={'Search....'}
																							className={'bg-transparent text-white'}
																							widthHeight={25}
																							hideInputSection={true}
																							filteredData={(filteredArray, searchText) => {
																								setState(prevState => ({ 
																									...prevState, 
																									['filteredArray_' +index +'_' +datIndex]: filteredArray,
																									['search_' + index + '_' + datIndex]: searchText,
																								}))
																								formDownloadData(index, datIndex)
																							}}
																						/>
																					</div>
																				</div>
																			) : null}
																			{state['expand_child_' + index + '_' + datIndex] &&
																			dat.results &&
																			dat.results.length ? (
																				<React.Fragment>
																					<CSVLink
																						data={
																							state['filteredArray_' + index + '_' + datIndex] && state['filteredArray_' + index + '_' + datIndex].length ? 
																								state['filteredArray_' + index + '_' + datIndex]
																							: dat.results
																						}
																						headers={state['headers_' + index + '_' + datIndex]}
																						filename={'governance-violations.csv'}
																						className={'self-center mr-2'}
																						target='_blank'
																					>
																						<Icon icon="material-symbols:download" className='text-gray5 self-center' width="22" height="22"/>
																					</CSVLink>
																				</React.Fragment>
																			) : null}
																			{dat.critical ?
																				<span className={`mr-2 self-center bg-critical rounded-full px-2 text-white`}
																				> C: {dat.critical}</span>
																			: null}
																			{dat.high ?
																				<span className={`mr-2 self-center bg-high rounded-full px-2 text-white`}
																				> H: {dat.high}</span>
																			: null}
																			<div className='text-info self-center'
																				onClick={() =>
																					setState(prevState => ({ 
																						...prevState, 
																						['expand_child_' + index + '_' + datIndex]: !state['expand_child_' + index + '_' + datIndex],
																					}))
																				}
																			>
																				Showing {dat.results && dat.results.length} violation(s)
																			</div>
																		</div>
																	</div>
																	{state['expand_child_' + index + '_' + datIndex] ?
																		<div className=''>
																			<div className='bg-black pb-2'>
																				{state.selectedGovernanceType !== 'opa_kubernetes' && state.columns ? (
																					<ResizeableDarkThemeTable
																						columns={state.columns}
																						data={
																							state['search_' + index + '_' + datIndex] ? 
																								state['selectedRisk_' + index + '_' + datIndex] && state['selectedRisk_' + index + '_' + datIndex] !== 'All' && state['selectedStatus_' + index + '_' + datIndex] ? 
																									state['filteredArray_' + index + '_' + datIndex].filter(e => e.status === state['selectedStatus_' + index + '_' + datIndex] && e.risk === state['selectedRisk_' + index + '_' + datIndex])
																								: state['selectedRisk_' + index + '_' + datIndex] && state['selectedRisk_' + index + '_' + datIndex] !== 'All' ? 
																									state['filteredArray_' + index + '_' +datIndex].filter(e => e.risk === state['selectedRisk_' + index + '_' + datIndex])
																								: state['selectedStatus_' + index + '_' + datIndex] ? 
																									state['filteredArray_' + index + '_' + datIndex].filter(e => e.status === state['selectedStatus_' + index + '_' + datIndex])
																								: state['filteredArray_' + index + '_' + datIndex]
																							: state['selectedRisk_' + index + '_' + datIndex] && state['selectedRisk_' + index + '_' + datIndex] !== 'All' && state['selectedStatus_' + index + '_' + datIndex] ? 
																								dat.results.filter(e => e.status === state['selectedStatus_' + index + '_' + datIndex] && e.risk === state['selectedRisk_' + index + '_' + datIndex])
																							: state['selectedRisk_' + index + '_' + datIndex] && state['selectedRisk_' + index + '_' + datIndex] !== 'All' ? 
																								dat.results.filter(e => e.risk === state['selectedRisk_' + index + '_' + datIndex])
																							: state['selectedStatus_' + index + '_' + datIndex] ? 
																								dat.results.filter(e => e.status === state['selectedStatus_' + index + '_' + datIndex])
																							: dat.results
																						}
																						onClickRow={tableRow => {
																							setState(prevState => ({ ...prevState, selectedViolation: tableRow, showDashboardPanel: true }))										
																						}}
																						selectedRecord={state.selectedTableRow}
																						perPage={20}
																						dashboard={true}
																						sortByColumn={'timestamp'}
																						selectedColor={'bg-transparent'}
																						tooltipFor={[]}
																						paginationColor={'text-white'}
																						// riskTooltip={[0]}
																						tableBody={'bg-dark3'}
																						rounded={true}
																						// selectedColumn={'bg-light'}
																						// columnFontSize={'f12'}
																					/>
																				) : state.selectedGovernanceType === 'opa_kubernetes' ? (
																					<ResizeableDarkThemeTable
																						columns={[
																							{
																								Header: 'Risk',
																								accessor: 'risk',
																								Cell: cellInfo => (
																									<span
																										className={`badge risk-badge-${
																											cellInfo.row.original.risk
																												? cellInfo.row.original.risk.toLowerCase()
																												: ''
																										} aiopsTableBadgeWidth`}
																									>
																										{cellInfo.row.original.risk
																											? cellInfo.row.original.risk.toLowerCase() ===
																												'critical'
																												? 'C'
																												: cellInfo.row.original.risk.toLowerCase() ===
																													'high'
																												? 'H'
																												: cellInfo.row.original.risk.toLowerCase() ===
																													'medium'
																												? 'M'
																												: cellInfo.row.original.risk.toLowerCase() === 'low'
																												? 'L'
																												: ''
																											: 'U'}
																									</span>
																								),
																								width: 80,
																							},
																							{
																								Header: 'Date',
																								accessor: 'timestamp',
																								Cell: cellInfo => (
																									<span>
																										{cellInfo.row.original.status &&
																										cellInfo.row.original.status !== 'pass'
																											? cellInfo.row.original.resolution_status_info &&
																												cellInfo.row.original.resolution_status_info
																													.timestamp &&
																												momentConvertionUtcToLocalTime(
																													cellInfo.row.original.resolution_status_info
																														.timestamp,
																													'DD MMM YYYY HH:mm'
																												)
																											: momentConvertionUtcToLocalTime(
																													cellInfo.row.original.timestamp,
																													'DD MMM YYYY HH:mm'
																												)}
																									</span>
																								),
																								width: 180,
																							},
																							{
																								Header: 'Policy Name',
																								accessor: 'policy_name',
																								width: 150,
																							},
																							{
																								Header: 'Cluster',
																								accessor: d => d.cluster_details,
																								Cell: cellInfo => (
																									<React.Fragment>
																										{cellInfo.row.original.cluster_details &&
																										cellInfo.row.original.cluster_details.clustername ? (
																											<span>
																												{cellInfo.row.original.cluster_details.clustername}
																											</span>
																										) : (
																											''
																										)}
																										:
																										{cellInfo.row.original.cluster_details &&
																										cellInfo.row.original.cluster_details.clusterid ? (
																											<span>
																												{cellInfo.row.original.cluster_details.clusterid}
																											</span>
																										) : (
																											''
																										)}
																									</React.Fragment>
																								),
																								width: 150,
																							},
																							{
																								Header: 'Resources',
																								accessor: 'resource_type',
																								width: 130,
																							},
																							{
																								Header: 'Services',
																								accessor: 'service_name',
																								width: 200,
																							},
																							{
																								Header: 'Name Space',
																								accessor: 'namespace',
																								width: 170,
																							},
																							{
																								Header: 'Resource Id',
																								accessor: 'resource_id',
																								width: 200,
																							},
																							{
																								Header: 'Status',
																								accessor: 'status',
																								Cell: cellInfo => (
																									<span
																										className={`badge status-badge badge-outline-${
																											cellInfo.row.original.status === 'pass'
																												? 'pass'
																												: 'fail'
																										} badge-pill`}
																									>
																										{capitalizeFirstLetter(cellInfo.row.original.status)}
																									</span>
																								),
																								width: 100,
																							},
																							{
																								Header: 'Description',
																								accessor: 'description',
																								Cell: cellInfo => (
																									<React.Fragment>
																										<span className=''>
																											{(cellInfo.row.original.description
																												? cellInfo.row.original.description
																												: '') +
																												'' +
																												(cellInfo.row.original.status &&
																												cellInfo.row.original.status === 'fail' &&
																												cellInfo.row.original.detail
																													? ' - ' + cellInfo.row.original.detail
																													: '')}
																										</span>
																									</React.Fragment>
																								),
																								width: 370,
																								// className: 'whiteSpaceUnset',
																							},
																							{
																								Header: ' ',
																								accessor: ' ',
																								width: 1,
																							},
																						]}
																						data={
																							state['search_' + index + '_' + datIndex] &&
																							state['search_' + index + '_' + datIndex] !== ''
																								? state['selectedRisk_' + index + '_' + datIndex] &&
																									state['selectedRisk_' + index + '_' + datIndex] !==
																										'All' &&
																									state['selectedStatus_' + index + '_' + datIndex]
																									? state[
																											'filteredArray_' + index + '_' + datIndex
																										].filter(
																											e =>
																												e.status ===
																													state[
																														'selectedStatus_' + index + '_' + datIndex
																													] &&
																												e.risk ===
																													state[
																														'selectedRisk_' + index + '_' + datIndex
																													]
																										)
																									: state['selectedRisk_' + index + '_' + datIndex] &&
																										state['selectedRisk_' + index + '_' + datIndex] !==
																											'All'
																									? state[
																											'filteredArray_' + index + '_' + datIndex
																										].filter(
																											e =>
																												e.risk ===
																												state['selectedRisk_' + index + '_' + datIndex]
																										)
																									: state['selectedStatus_' + index + '_' + datIndex]
																									? state[
																											'filteredArray_' + index + '_' + datIndex
																										].filter(
																											e =>
																												e.status ===
																												state[
																													'selectedStatus_' + index + '_' + datIndex
																												]
																										)
																									: state['filteredArray_' + index + '_' + datIndex]
																								: state['selectedRisk_' + index + '_' + datIndex] &&
																									state['selectedRisk_' + index + '_' + datIndex] !==
																										'All' &&
																									state['selectedStatus_' + index + '_' + datIndex]
																								? dat.results.filter(
																										e =>
																											e.status ===
																												state[
																													'selectedStatus_' + index + '_' + datIndex
																												] &&
																											e.risk ===
																												state['selectedRisk_' + index + '_' + datIndex]
																									)
																								: state['selectedRisk_' + index + '_' + datIndex] &&
																									state['selectedRisk_' + index + '_' + datIndex] !==
																										'All'
																								? dat.results.filter(
																										e =>
																											e.risk ===
																											state['selectedRisk_' + index + '_' + datIndex]
																									)
																								: state['selectedStatus_' + index + '_' + datIndex]
																								? dat.results.filter(
																										e =>
																											e.status ===
																											state['selectedStatus_' + index + '_' + datIndex]
																									)
																								: dat.results
																						}
																						onClickRow={tableRow => {
																							setState(prevState => ({ ...prevState, selectedViolation: tableRow }), () =>
																								setState(prevState => ({ ...prevState, showDashboardPanel: true }))
																							)
																						}}
																						dashboard={true}
																						selectedRecord={state.selectedTableRow}
																						perPage={20}
																						sortByColumn={'timestamp'}
																						selectedColor={'bg-transparent'}
																						tooltipFor={[]}
																						paginationColor={'text-white'}
																						tableBody={'bg-dark3'}
																						rounded={true}
																					/>
																				) : null}
																				{(state['search_' + index + '_' + datIndex] &&
																				state['search_' + index + '_' + datIndex] !== ''
																					? state['filteredArray_' + index + '_' + datIndex]
																					: dat.results
																				).map((row, rowIndex) => {
																					return(
																						<div key={'row_'+rowIndex}
																							className={`px-2 ${
																								!rowIndex ? '' : 'border-top-black5 pt-2'
																							} hidden`}
																							onClick={() => {
																								this.setState({ selectedViolation: row }, () =>
																									this.setState({ showDashboardPanel: true })
																								)
																							}}
																						>
																							<div className={`flex cursor-pointer mb-2`}>
																								<span
																									className={`f10 mr-2 self-start mt-1 badge-sm risk-badge-${
																										row.risk ? row.risk.toLowerCase() : 'critical'
																									}`}
																								>
																									{row.risk
																										? row.risk.toLowerCase() === 'critical'
																											? 'C'
																											: row.risk.toLowerCase() === 'high'
																											? 'H'
																											: row.risk.toLowerCase() === 'medium'
																											? 'M'
																											: 'L'
																										: 'U'}
																								</span>
																								<p className='mb-0'>
																									<span className='mb-0 mr-2 text-white f12 font-weight-bold text-lightGray'>
																										{row.timestamp
																											? momentConvertionUtcToLocalTime(
																													row.timestamp,
																													'DD MMM YYYY HH:mm'
																												)
																											: ''}{' '}
																									</span>
																									{/* <span className={`mb-0 mr-2 f12 text-purple-2`}>
																									{row.event_source ? (Array.isArray(row.event_source) ? row.event_source.join(", ") : row.event_source) : ""}
																									{row.event_name ? ' : ' + row.event_name : ""}
																									{row.event_type ? ' : '+ row.event_type : ""}
																								</span> */}
																									{row.policy_name ? (
																										<span className='mb-0 mr-2 f12 text-white font-weight-bold'>
																											{row.policy_name}
																										</span>
																									) : null}
																									{row.service_id ? (
																										<span className='mb-0 mr-2 f12 text-white font-weight-bold'>
																											{row.service_id}
																										</span>
																									) : null}
																									{row.asset_name ? (
																										<span className='mb-0 mr-2 f12 text-white font-weight-bold'>
																											{row.asset_name}
																										</span>
																									) : null}
																									<span className='mb-0 small mr-1 text-lightGray'>
																										Resource
																									</span>
																									{row.account_id ? (
																										<span className='mb-0 f12 text-lightGray font-weight-bold'>
																											{' ' + row.account_id}
																										</span>
																									) : null}
																									{row.region ? (
																										<span className='mb-0 f12 text-lightGray font-weight-bold'>
																											{' : ' + row.region}
																										</span>
																									) : null}
																									{row.resource_type ? (
																										<span className='mb-0 f12 text-lightGray font-weight-bold'>
																											{' : ' +
																												(Array.isArray(row.resource_type)
																													? row.resource_type.join(', ')
																													: row.resource_type)}
																										</span>
																									) : null}
																									{row.service_name ? (
																										<span className='mb-0 f12 text-lightGray font-weight-bold mr-2'>
																											{' : ' + row.service_name}
																										</span>
																									) : null}
																									{row.detail ? (
																										<span className='mb-0 ml-2 f12 text-white font-weight-bold'>
																											{row.detail}
																										</span>
																									) : null}
																								</p>
																							</div>
																						</div>
																					)
																				})}
																			</div>
																		</div>
																	: null}
																</div>
															</div>
														)
													})}
													</div>
												: null}
											</div>
										)
									})
								) : (
									<div className='col-lg-12 flex justify-center my-4'>
										<p>There are no data on this criteria. Please try adjusting your search.</p>
									</div>
								)}
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}
export default Dashboard
