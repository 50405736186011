// components/common/AppWrapper.js
import React from 'react';
import Header from './Header'
import Sidebar from './Sidebar'


function AppWrapper({ children }) {
  return (
    <React.Fragment>
      <Sidebar />
      <div className="pl-16">
        <Header />
        {children}
      </div>
    </React.Fragment>
  );
}

export default AppWrapper;
