import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux'; // Import useDispatch hook to dispatch actions
import { useLocation } from 'react-router-dom';
import _ from 'lodash'
import { listWorkstationResults, getCatalogFilters } from '../../../actions/CatalogAction'
import { numberFormater, capitalizeFirstLetter } from '../../../utils/utility'
import WorkstationSidePanel from './WorkstationSidePanel'
import {Icon} from "@iconify/react";

let searchWorstation = null

const Summary = (props) => {

    // State variables for filters
    const [state, setState] = useState({
        showLoading: true,
        selectedProvider: props.selectedProvider,
        catalogType: props.selecedProvider && props.selecedProvider.toLowerCase(),
        selectedAccount: props.selectedAccount,
        selectedRegion: props.selectedRegion,
        emptyOptions: [],
        startRecord: 0, 
        currentPage: 1,
        totalPages: 1,
        perPage: 20,
    })

    const dispatch = useDispatch(); // Create a dispatch function
    const location = useLocation();
    const receivedData = location.state;
    const userMenu = useSelector(state => state?.filters?.userMenu || false);

    // const locationData = location.state || false
    const currentPath = location.pathname;

    useEffect(() => {
        if(userMenu && Object.entries(userMenu).length) {
			let actionsAllowed = []
			userMenu.menu && userMenu.menu.forEach(row => {
                if(row.submenulist && row.submenulist.length) {
                    if(row.submenulist.filter(e => e.link === currentPath && e.workstation_action).length) {
                        actionsAllowed = row.submenulist.filter(e => e.link === currentPath)[0].workstation_action
                    }
				} else if(row.workstation_action) {
                    actionsAllowed = row.workstation_action
                }
			})
            setState(prevState => ({ ...prevState, actionsAllowed }))
		}
    }, [userMenu, currentPath])

    const handleCatalogTypeChange = (event) => {
        setState(prevState => ({ ...prevState, showLoading: true, listCatalogs: [], filteredCatalogs: [], nextCatalogToken: [], catalogType: event.target.value, callListWorkstationResults: true }))
    }

    useEffect(() => {
        let params = {}
        if(state.selectedProvider && state.selectedProvider !== '') {
            params.provider = state.selectedProvider.toLowerCase()
        }        
        dispatch(getCatalogFilters(params))
            .then((response) => {
                if(response) {
                    let results = response.results ? response.results : []
                    let mappings = response.mappings ? response.mappings : []                    
                    setState(prevState => ({ ...prevState, catalogTypes: mappings, catalogResponse: results, catalogType: state.selectedProvider.toLowerCase(),  callListWorkstationResults: true }))
                }
            })
    }, [state.selectedProvider, dispatch])

    useEffect(() => {
        if(state.callListWorkstationResults) {
            setState(prevState => ({ ...prevState, callListWorkstationResults: false }))
            let params = {}
            params.summary =true
            if(state.selectedProvider && state.selectedProvider !== '') {
                params.provider = state.selectedProvider.toLowerCase()
            }
            if(state.selectedAccount && state.selectedAccount.length) {
                params.account_id = state.selectedAccount.filter(e => e !== "All")
            }
            
            if(state.selectedRegion && state.selectedRegion.length) {
                params.region = state.selectedRegion.filter(e => e !== "All")
            }
            
            if(props.subscribedRow && props.subscribedRow.workstation_id) {
                params.workstation_id = props.subscribedRow && props.subscribedRow.workstation_id
            }

            if(state.catalogType) {
                params.catalog_type = state.catalogType.toLowerCase()
            }

            if(state.searchText) {
                params.search_input = state.searchText
            }
            params.size = state.perPage

            if(state.nextCatalogToken && state.nextCatalogToken.length) {
                params.next_token = state.nextCatalogToken
            }

            if(state.selectedStatus) {
                params.status = state.selectedStatus
            }

            if(state.sort_order) {
                params.sort_order = state.sort_order
                params.sort_by = state.sort_by
            }

            params.metrics = false

    
            dispatch(listWorkstationResults(params))
                .then((response) => {
					if(response) {
                        
                        let totalResults = [] 
                        let startRecord = state.startRecord
                        let currentPage = state.currentPage 
                        if(state.nextCatalogToken && state.nextCatalogToken.length) {
                            totalResults = state.workstationList &&  state.workstationList.length ? state.workstationList : []
                            startRecord = startRecord + state.perPage;
                            currentPage = currentPage+ 1
                        }

                        if(response.results && response.results.length) {
                            totalResults = totalResults.concat(response.results)
                            // if(state.nextCatalogToken && state.nextCatalogToken.length) {
                            //     setState(prevState => ({ ...prevState, startRecord: state.startRecord + state.perPage }))
                            // }
                        }

                        let totalPages = 1                
                        if(totalResults && totalResults.length > state.perPage) {
                            totalPages = Math.ceil(totalResults.length / state.perPage)
                        }

                        let json = response.workstation_details ? response.workstation_details : []

                        let detailsFormat = _.orderBy(json, ['order'], ['asc'])

                        setState(prevState => ({ ...prevState, statusSection: response.total_count ? response.total_count : {}, workstationList: totalResults, filterdWorkStationList: totalResults, totalRecords: response.total ? response.total : 0, selectedFilters: params, detailsFormat, showLoading: false, callFormGraph: true, totalPages, startRecord, currentPage, nextCatalogToken: response.next_token }));
                    }
                })
        }
    }, [state.callListWorkstationResults, dispatch, state.selectedAccount, state.selectedProvider, state.selectedRegion, receivedData, state.workstationList, state.nextCatalogToken, props, state.startRecord, state.currentPage, state.perPage, state.catalogType, state.searchText, state.selectedStatus, state.sort_by, state.sort_order])

    // Function to navigate between pages
    const navigatePage = (action, currentPage) => {
        // Update startRecord and currentPage based on action
        let startRecord = state.startRecord
        if(action === 'next' && currentPage === state.totalPages && state.nextCatalogToken && state.nextCatalogToken.length) {
            setState(prevState => ({ ...prevState, showLoading: true, callListWorkstationResults: true }))
        } else if(action === 'next' && currentPage !== state.totalPages) {
            startRecord = startRecord + state.perPage;
            setState(prevState => ({ ...prevState, currentPage: currentPage+ 1 }));
        } else if(action === 'previous' && currentPage !== 0) {
            setState(prevState => ({ ...prevState, currentPage: currentPage - 1 }));
            startRecord = startRecord - state.perPage;
        } else if(action === 'start' && currentPage !== 0) {
            setState(prevState => ({ ...prevState, currentPage: 1 }));
            startRecord = 0;
        } else if(action === 'end' && currentPage !== 0) {
            setState(prevState => ({ ...prevState, currentPage: state.totalPages }));
            startRecord = (state.totalPages - 1) * state.perPage;
        }
        setState(prevState => ({ ...prevState, startRecord }))
    }


    return (
        state.showLoading ?
            <div className='flex justify-center m-4'>
                <svg className="animate-spin h-5 w-5 ml-3 text-white" fill="currentColor" viewBox="0 0 24 24">
                    <circle cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="2" fill="none" />
                    <path d="M4 12a8 8 0 0112-6.9" />
                </svg>
            </div>
        :
            <React.Fragment>
            {state.selectedDetails && Object.entries(state.selectedDetails).length ? 
                <WorkstationSidePanel 
                    closeSidePanel={(string) => setState(prevState => ({...prevState, selectedDetails: {}, selectedAction: "", callListWorkstationResults: string === 'refresh' ? true : false, nextCatalogToken: string === 'refresh' ? [] : state.nextCatalogToken, showLoading: false }))}
                    selectedDetails={state.selectedDetails} 
                    selectedAction={state.selectedAction}
                    detailsFormat={state.detailsFormat}
                />
            : null}
            <div className='p-2 overflow-auto'>
                
                <div className='flex flex-wrap justify-center'>
                    {Object.entries(state.statusSection).map(([key, value], index) => {
                        return(
                            <div key={'key__'+index} className='mr-2 pt-1'>

                                <div className={`border border-${(key.toLowerCase() === "failed" || key.toLowerCase() === "rejected") ? "danger" : (key.toLowerCase() === "stopped" || key.toLowerCase() === "hibernated") ? "warning" : key.toLowerCase() === "requested" ? 'warning' : key.toLowerCase() === "approved" ? 'success' :  "info"} bg-${state.selectedStatus === key ? ((key.toLowerCase() === "failed" || key.toLowerCase() === "rejected") ? "danger" : (key.toLowerCase() === "stopped" || key.toLowerCase() === "hibernated") ? "warning" : key.toLowerCase() === "requested" ? 'warning' : key.toLowerCase() === "approved" ? 'success' :  "info") : 'transparent'} text-${state.selectedStatus !== key ? ((key.toLowerCase() === "failed" || key.toLowerCase() === "rejected") ? "danger" : (key.toLowerCase() === "stopped" || key.toLowerCase() === "hibernated") ? "warning" : key.toLowerCase() === "requested" ? 'warning' : key.toLowerCase() === "approved" ? 'success' :  "info") : 'white'}  cursor-pointer min-w-24 h-20 rounded-md flex justify-center items-center p-3`} onClick={() => setState(prevState => ({ ...prevState, selectedStatus: state.selectedStatus !== key ? key : '', nextCatalogToken: [], startRecord: 0, currentPage: 1, showLoading: true, callListWorkstationResults: true }))}>
                                        <div className="flex flex-col justify-between items-center">
                                            <p className='mb-0 text-sm text-white'>{numberFormater(value)}</p>
                                            <small className='mb-0 text-sm'>{capitalizeFirstLetter(key.toLowerCase())}</small>
                                        </div>
                                    </div>
                                
                                {/* {convertToLowerCase(key) === "completed" || convertToLowerCase(key) === "finished" ?
                                    <div className={`border border-cyon bg-transparent min-w-24 h-20 rounded-md flex justify-center items-center p-3 ${state.selectedStatus === key ? 'bg-cyon text-white' : 'text-cyon'} cursor-pointer`} onClick={() => setState(prevState => ({ ...prevState, selectedStatus: state.selectedStatus !== key ? key : '', callListJobDetailsFunction: true }))}>
                                        <div className="flex flex-col justify-between items-center">
                                            <p className='mb-0 text-sm'>{numberFormater(value)}</p>
                                            <small className='mb-0 text-sm'>{capitalizeFirstLetter(key.toLowerCase())}</small>
                                        </div>
                                    </div>

                                :
                                    <div className={`border border-mulbery bg-transparent min-w-24 h-20 rounded-md flex justify-center items-center p-3 ${state.selectedStatus === key ? 'bg-mulbery text-white' : 'text-mulbery'} cursor-pointer`} onClick={() => setState(prevState => ({ ...prevState, selectedStatus: state.selectedStatus !== key ? key : '', callListJobDetailsFunction: true }))}>
                                        <div className="flex flex-col justify-between items-center">
                                            <p className='mb-0 text-sm'>{numberFormater(value)}</p>
                                            <small className='mb-0 text-sm'>{capitalizeFirstLetter(key.toLowerCase())}</small>
                                        </div>
                                    </div>
                                } */}
                            </div>
                        )
                    })}
                </div>
                <div className='flex flex-wrap justify-between my-3'>
                    <p className='mb-0 self-center'>
                        <small>Showing {state.filterdWorkStationList && state.filterdWorkStationList.length} out of total {state.totalRecords} workstation(s)</small>
                        {state.selectedFilters && state.selectedFilters.provider ?
                            <React.Fragment>
                            <small className='ml-1'>obtained from the</small>
                            <small className='ml-1 text-info'>{state.selectedFilters.provider.toUpperCase()}</small>
                            <small className='ml-1'>provider</small>
                            </React.Fragment>
                        : null}
                        {state.selectedFilters && state.selectedFilters.account_id && state.selectedFilters.account_id.length ?
                            <React.Fragment>
                            <small className='ml-1'>with the account</small>
                            <small className='badge badge-outline-info mr-2'>{state.selectedFilters.account_id[0]}</small>
                            {state.selectedFilters.account_id.length > 1 ?
                                <small className='ml-2 text-info'>more</small>
                            : null}
                            </React.Fragment>
                        : null}
                        {state.selectedFilters && state.selectedFilters.region && state.selectedFilters.region.length ?
                            <React.Fragment>
                            <small className='mx-1'>in the</small>
                            <small className='badge badge-outline-info mr-2'>{state.selectedFilters.region[0]}</small>
                            {state.selectedFilters.region.length > 1 ?
                                <small className='ml-2 text-info'>more</small>
                            : null}
                            <small className='ml-1'>region</small>
                            </React.Fragment>
                        : null}
                    </p>
                    <div className='flex mr-2 justify-end lg:w-1/2 w-full'>
                        <select className="self-center bg-transparent border-b border-mediumDarkGray text-white text-xs lg:w-1/6 w-2/5 p-2 focus-visible:border-b border-mediumDarkGray mr-2" onChange={handleCatalogTypeChange} value={state.catalogType}>
                            {state.catalogTypes && state.catalogTypes.map((cat) => {
                                return(
                                    <option key={cat.value} value={cat.value}>{cat.label}</option>
                                )
                            })}
                        </select>
                        <div className='lg:w-1/2 md:w-1/2 w-full justiyf-end'>
                            <div className='flex border border-lightGray rounded-md'>
                                <Icon icon={`bitcoin-icons:search-outline`} className={`text-white w-5 self-center`} width={25} height={25} />
                                <div className={`w-11/12`}>
                                    <input
                                        value={state.searchText}
                                        onChange={e => {
                                            setState(prevState => ({ ...prevState, searchText: e.target.value }))
                                            clearTimeout(searchWorstation)
                                            if(e.target.value.length > 2) {
                                                searchWorstation = setTimeout(() => { setState(prevState => ({ ...prevState, searchText: e.target.value, nextCatalogToken: [], startRecord: 0, currentPage: 1, totalPages: 1, workstationList: [], showLoading: true, callListWorkstationResults: true }))}, 2000); 
                                            } else if(!e.target.value.length) {
                                                setState(prevState => ({ ...prevState, startRecord: 0, currentPage: 1, totalPages: 1, workstationList: [], showLoading: true, nextCatalogToken: [], callListWorkstationResults: true }))
                                            }
                                        }}
                                        type='text'
                                        className={`bg-transparent text-white text-md pl-1 py-1`}
                                        placeholder='Search'
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {state.workstationList && state.workstationList.length ?
                    state.totalRecords > state.perPage ?
                        <div className='flex justify-end text-white w-full mt-2'>
                            <span className='mx-3'>Page <strong>{state.currentPage} of {Math.ceil(state.totalRecords / state.perPage)}</strong> </span>
                            <div className="pagination text-sm">
                                <button><Icon icon="system-uicons:push-left" width="20" height="20" className={`ml-2 cursor-pointer ${state.currentPage === 1 ? 'pointer-events-none text-lightGray' : 'text-info'}`} onClick={() => navigatePage('start', state.currentPage)} /></button> 
                                <button><Icon icon="prime:angle-left" width="20" height="20" className={`ml-2 cursor-pointer ${state.currentPage === 1 ? 'pointer-events-none text-lightGray' : 'text-info'}`} onClick={() => navigatePage('previous', state.currentPage)} /></button> 
                                {/* <span>
                                    Go to
                                    <input
                                        type='number'
                                        value={state.pageNumber || state.pageNumber === "" ? state.pageNumber : state.currentUpcoming}
                                        onChange={e => {
                                            const page = e.target.value ? Number(e.target.value) : ""
                                            gotoPage(page)
                                        }}
                                        className="ml-1 inputClass"
                                    />
                                </span> */}
                                <button><Icon icon="prime:angle-right" width="20" height="20" className={`ml-3 cursor-pointer ${state.currentPage === Math.ceil(state.totalRecords / state.perPage) ? 'pointer-events-none text-lightGray' : 'text-info'}`} onClick={() => navigatePage('next', state.currentPage)}/></button> 
                                <button><Icon icon="system-uicons:push-right" width="20" height="20" className={`ml-2 cursor-pointer ${state.currentPage === Math.ceil(state.totalRecords / state.perPage) ? 'pointer-events-none text-lightGray' : 'text-info'}`} onClick={() => navigatePage('end', state.currentPage)} /></button>
                            </div>
                        </div>
                    : null
                : null}
                <div className='w-full px-3 py-1 mb-4 overflow-auto'>
                    <table className="min-w-full table-auto">
                        <thead className='text-md text-white bg-dark2'>
                            <tr>
                                <th className='text-sm py-2 text-start pl-2 curosr-pointer' onClick={() => setState(prevState => ({ ...prevState, sort_order: state.sort_by === 'workstation_name' ? (state.sort_order === 'asc' ? 'desc' : (state.sort_order === 'desc' ? '' : 'asc')) : 'asc', sort_by: 'workstation_name', nextCatalogToken: [], callListWorkstationResults: true }))}> 
                                    {state.sort_by === 'workstation_name' && (state.sort_order === 'asc' ? 
                                        <Icon icon={`icon-park-solid:down-one`} className={`text-white self-center mr-2`} width="16" height="16" />
                                    : state.sort_order === 'desc' ?
                                        <Icon icon={`icon-park-solid:up-one`} className={`text-white self-center mr-2`} width="16" height="16" />
                                    : null)}
                                    Name
                                </th>
                                <th className='text-sm py-2 text-start pl-2 curosr-pointer' onClick={() => setState(prevState => ({ ...prevState, sort_order: state.sort_by === 'status' ? (state.sort_order === 'asc' ? 'desc' : (state.sort_order === 'desc' ? '' : 'asc')) : 'asc', sort_by: 'status', nextCatalogToken: [], callListWorkstationResults: true }))}>
                                    {state.sort_by === 'status' && (state.sort_order === 'asc' ? <i className='fa fa-caret-down mt-1 mr-1'/> : state.sort_order === 'desc' ? <i className='fa fa-caret-up mt-1 mr-1'/> : null)}
                                    Status
                                </th>
                                <th className='text-sm py-2 text-start pl-2 curosr-pointer' onClick={() => setState(prevState => ({ ...prevState, sort_order: state.sort_by === 'instance_type' ? (state.sort_order === 'asc' ? 'desc' : (state.sort_order === 'desc' ? '' : 'asc')) : 'asc', sort_by: 'instance_type', nextCatalogToken: [], callListWorkstationResults: true }))}>
                                    {state.sort_by === 'instance_type' && (state.sort_order === 'asc' ? <i className='fa fa-caret-down mt-1 mr-1'/> : state.sort_order === 'desc' ? <i className='fa fa-caret-up mt-1 mr-1'/> : null)}
                                    Capacity
                                </th>
                                <th className='text-sm py-2 text-start pl-2 curosr-pointer' onClick={() => setState(prevState => ({ ...prevState, sort_order: state.sort_by === 'asset_name' ? (state.sort_order === 'asc' ? 'desc' : (state.sort_order === 'desc' ? '' : 'asc')) : 'asc', sort_by: 'asset_name', nextCatalogToken: [], callListWorkstationResults: true }))}>
                                    {state.sort_by === 'asset_name' && (state.sort_order === 'asc' ? <i className='fa fa-caret-down mt-1 mr-1'/> : state.sort_order === 'desc' ? <i className='fa fa-caret-up mt-1 mr-1'/> : null)}
                                    Asset
                                </th>
                                <th className='text-sm py-2 text-start pl-2 curosr-pointer' onClick={() => setState(prevState => ({ ...prevState, sort_order: state.sort_by === 'public_ip_address' ? (state.sort_order === 'asc' ? 'desc' : (state.sort_order === 'desc' ? '' : 'asc')) : 'asc', sort_by: 'public_ip_address', nextCatalogToken: [], callListWorkstationResults: true }))}>
                                    {state.sort_by === 'public_ip_address' && (state.sort_order === 'asc' ? <i className='fa fa-caret-down mt-1 mr-1'/> : state.sort_order === 'desc' ? <i className='fa fa-caret-up mt-1 mr-1'/> : null)}
                                    Public IP / Private Ip
                                </th>
                                {/* <th className='f16'>Storage</th> */}
                                <th className='text-sm py-2 text-start pl-2 curosr-pointer' onClick={() => setState(prevState => ({ ...prevState, sort_order: state.sort_by === 'user_name' ? (state.sort_order === 'asc' ? 'desc' : (state.sort_order === 'desc' ? '' : 'asc')) : 'asc', sort_by: 'user_name', nextCatalogToken: [], callListWorkstationResults: true }))}>
                                    {state.sort_by === 'user_name' && (state.sort_order === 'asc' ? <i className='fa fa-caret-down mt-1 mr-1'/> : state.sort_order === 'desc' ? <i className='fa fa-caret-up mt-1 mr-1'/> : null)}
                                    User
                                </th>
                                <th className='text-sm py-2 text-start pl-2'>Action</th>
                            </tr>
                        </thead>
                        <tbody className='bg-dark3 text-white'>
                            {state.filterdWorkStationList && state.filterdWorkStationList.length ?
                                state.filterdWorkStationList.slice(state.startRecord, state.startRecord + state.perPage).map((row, index)=> {
                                    return(
                                        <tr key={"head1_"+index} className={`${!index ? '' : 'border-t border-mediumDarkGray'} ${index % 2 === 0 ? '' : ''}`}>
                                            <td className="p-2 text-white">{row.workstation_name}</td>
                                            <td className="p-2 ">
                                                <span className={`rounded-full px-2 py-1 border border-${(row.status.toLowerCase() === "failed" || row.status.toLowerCase() === "rejected") ? "danger" : (row.status.toLowerCase() === "stopped" || row.status.toLowerCase() === "hibernated") ? "warning" : row.status.toLowerCase() === "requested" ? 'warning' : row.status.toLowerCase() === "approved" ? 'success' :  "info"} text-${(row.status.toLowerCase() === "failed" || row.status.toLowerCase() === "rejected") ? "danger" : (row.status.toLowerCase() === "stopped" || row.status.toLowerCase() === "hibernated") ? "warning" : row.status.toLowerCase() === "requested" ? 'warning' : row.status.toLowerCase() === "approved" ? 'success' :  "info"}`}>{row.status}</span>
                                            </td>
                                            <td className="p-2 text-white">
                                                {row.instance_type}
                                                <div className='flex'>
                                                    ({row.gpu ? <span>{row.gpu+" GPU, "}</span> : null}
                                                    <span className='ml-1'>{row.vcpus} vCPU,</span>
                                                    <span className='ml-1'>{row.memory} {row.memory_unit ? row.memory_unit : 'GB'} </span>)
                                                </div>                                                
                                                {row.volume_details && row.volume_details.length && row.volume_details[0].volume_size ?
                                                    <span className='text-white'>Storage {row.volume_details[0].volume_size} GB</span>
                                                : null}
                                            </td>
                                            <td className="p-2 ">
                                                <span className='text-white'>{(row.id_name_map && !row.id_name_map.key && row.id_name_map.value ? row.id_name_map.value : "")}
                                                    {row.id_name_map && row.id_name_map.key ?
                                                        (<span className={`ml-1 text-info mb-0`}>
                                                        {row.id_name_map.key }</span>)
                                                    : null}
                                                </span>
                                            </td>
                                            <td className="p-2 text-white">
                                                {row.public_ip_address ? row.public_ip_address : ''} 
                                                {row.public_ip_address && row.private_ip_address ? ' / ' : ''}
                                                {row.private_ip_address ? row.private_ip_address : ''}
                                            </td>
                                            {/* <td className="p-2 text-white displayNone">
                                                <div className='flex'>
                                                    {row.gpu ? <span>{row.gpu+" GPU, "}</span> : null}
                                                    <span className='ml-1'>{row.vcpus} vCPU,</span>
                                                    <span className='ml-1'>{row.memory} {row.memory_unit ? row.memory_unit : 'GB'} </span>
                                                    {row.volume_details && row.volume_details.length && row.volume_details[0].volume_size ?
                                                        <span className='text-white ml-2'>({row.volume_details[0].volume_size} GB)</span>
                                                    : null}
                                                </div>
                                            </td> */}
                                            <td className="p-2 w-48">
                                                <div className='flex flex-wrap gap-2'>
                                                    {Array.isArray(row.user_name) ?
                                                        row.user_name.map((usr, usrIndex) => {
                                                            return(
                                                                <p className='text-white mb-0'>{usr} {usrIndex+1 !== row.user_name.length ? ', ' : ''}</p>
                                                            )
                                                        })
                                                    :
                                                        <span className='text-white'>{row.user_name}</span>
                                                    }
                                                        
                                                    {/* {Array.isArray(row.user_name) && row.user_name.length > 0 ?
                                                        <span className='text-info ml-2'>more</span>
                                                    : null} */}
                                                </div>
                                            </td>
                                            <td className="p-2 ">
                                                <div className="flex justify-between self-center">
                                                    <span className='text-info cursor-pointer' onClick={() => setState(prevState => ({ ...prevState, selectedDetails: row }))}>Details</span>
                                                </div>
                                            </td>
                                        </tr>
                                    )

                                })
                            : 
                                <tr>
                                    <td colspan={8} className="text-center">
                                        <p className='mb-0'>There are no data on this criteria. Please try adjusting your filter.</p>
                                    </td>
                                </tr>
                            }
                        </tbody>
                    </table>
                </div>
            </div>
            </React.Fragment>
    );
};
export default Summary