/* eslint-disable no-mixed-spaces-and-tabs */
/*************************************************
 * Tvastar
 * @exports
 * @file ManagePolicies.js
 * @author Prakash // on 09/12/2022
 * @copyright © 2022 Tvastar. All rights reserved.
 *************************************************/
import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux'; // Import useDispatch hook to dispatch actions
import { useNavigate, useLocation } from 'react-router-dom';
import { listAllProviders, listAllAccounts, listAllRegions, getAllTagsKeys, getAllTagsValues, setCommonPropsDetails } from '../../actions/commonActionNew'
import { getGovMetaData, listGovernancePolicies, getPolicyDetails, listAssets, cleareNewEditRuleDetails, listGovernanceType, deleteGovernancePolicy } from '../../actions/governance/GovernanceAction'
import _ from 'lodash'
import { CSVLink } from "react-csv"
import ResizeableDarkThemeTable from '../designComponents/Table/ResizeableDarkThemeTable'
import {Icon} from "@iconify/react";
import Search from '../common/SearchWithHiddenInput';
import { URL_PATH } from '../../config/urlPath'
import MultiSelectSection from '../common/MultiSelectSection';

import { capitalizeAllLetter, getAccountNameFromId, getRegionName, capitalizeTheFirstLetterOfEachWord } from '../../utils/utility'
import FilterSidePanel from './FilterSidePanel';
import { Store as CommonNotification } from 'react-notifications-component';
// import ViewSidePanel from './ViewSidePanel'

const ManagePolicies = () => {
	const advanceSearchRef = useRef();

	const [state, setState] = useState({
		queryLoading: true,
		showLoading: true,
		selectedGovernanceType: "All",
		filteredArray: [],
		viewOptions: [
			{label: "Policy", value: "policy_wise"},
			{label: "Policy Rule", value: "rule_wise"}
		],
		policyTypes: [
			{label: "Configured", value: "Configured"},
			{label: "Not Configured", value: "Not Configured"},
			{label: "All Rules", value: "all_rules"}
		],
		selectedViewOption: "policy_wise",
		selectedView: "policy_wise",
		selectedPolicyType: "Configured",
	})

	const navigate = useNavigate();
	const dispatch = useDispatch(); // Create a dispatch function
    const userMenu = useSelector(state => state?.filters?.userMenu || false);
	const location = useLocation()

    const currentPath = location.pathname;
	const tagKeys = useSelector(state => state?.filters?.tagKeys);	
    const providers = useSelector(state => state?.filters?.providers || false)
    const propAccounts = useSelector(state => state?.filters?.commonPropsDetails?.propAccounts || false);
    const propRegions = useSelector(state => state?.filters?.commonPropsDetails?.propRegions || false);

    useEffect(() => {
        if(userMenu && Object.entries(userMenu).length) {
			let actionsAllowed = []
			userMenu.menu && userMenu.menu.forEach(row => {
                if(row.submenulist && row.submenulist.length) {
                    if(row.submenulist.filter(e => e.link === currentPath && e.action).length) {
                        actionsAllowed = row.submenulist.filter(e => e.link === currentPath)[0].action
                    }
				}
				// else {
				// 	if(row.action) {
				// 		actionsAllowed = row.action
				// 	}
				// }
			})
            setState(prevState => ({ ...prevState, actionsAllowed }))
		}
    }, [userMenu, currentPath])

	useEffect(() => {
        if(providers) {
            setState(prevState => ({ ...prevState, providers: providers, selectedProvider: providers && providers.length ? (providers.filter(e => e.provider_name === 'AWS').length ? 'AWS' : providers[0].provider_name) : "",  callSearch: true }));
        } else {
            let params = {}
            dispatch(listAllProviders(params))
                .then((response) => {
                    if(response) {
                        setState(prevState => ({ ...prevState, providers: response, selectedProvider: response && response.length ? response.filter(e => e.provider_name === 'AWS').length ? 'AWS' : response[0].provider_name : "", callSearch: true }));
                    }
                })
        }
    }, [dispatch, providers]);// Call getFilterData whenever the providers state is updated

    useEffect(() => {
        if (state.selectedProvider) {
            let params = {};
            params.provider = state.selectedProvider.toLowerCase();
            let label = state.selectedProvider

            if(propAccounts && propAccounts.hasOwnProperty(label)) {
                setState(prevState => ({ ...prevState, accounts: propAccounts[label] }));
            } else {
                dispatch(listAllAccounts(params))
                    .then((response) => {
                        if(response) {
                            if(response.length) {
                                let obj = propAccounts ? propAccounts : {}
                                obj[label] = response
                                dispatch(setCommonPropsDetails('propAccounts', obj))
                                setState(prevState => ({ ...prevState, accounts: response }));
                            }
                        }
                    })
            }
        }
    }, [state.selectedProvider, propAccounts, dispatch]);

    useEffect(() => {
        if (state.selectedProvider) {
            let params = {};
            params.provider = state.selectedProvider.toLowerCase();
            let label = state.selectedProvider
            if(propRegions && propRegions.hasOwnProperty(label)) {
                setState(prevState => ({ ...prevState, regions: propRegions[label] }));
            } else {
                dispatch(listAllRegions(params))
                    .then((response) => {
                        if(response && response.length) {
							let results = response[0]?.provider_regions || []
							let obj = propRegions ? propRegions : {}
							obj[label] = results
							dispatch(setCommonPropsDetails('propRegions', obj))
							setState(prevState => ({ ...prevState, regions: results }));
						}
                    })
            }
        }
    }, [state.selectedProvider, propRegions, dispatch]);

	useEffect(() => {
		dispatch((listGovernanceType()))
			.then((response)=> {
				if(response) {
					setState(prevState => ({ ...prevState, governanceTypes: response && response.length ? response : [] }))
				}
			})
	}, [dispatch])

	useEffect(() => {
		if(state.queryLoading) {
			setTimeout(() => { setState(prevState => ({ ...prevState, queryLoading: false })) }, 10000);
		}
	}, [state.queryLoading])

	useEffect(() => {
		let params = {}
		dispatch(getGovMetaData(params))
			.then((response) => {
				if(response) {
					if(!response.error) {
						setState(prevState => ({ ...prevState, metaData: response }))
					} else {
						setState(prevState => ({ ...prevState, metaData: [] }))
					}
				}
			})
    }, [dispatch]); // Empty dependency array ensures this runs only once on mount	

	useEffect(() => {
		if(state.callSearch) {
			setState(prevState => ({ ...prevState, callSearch: false }))
			let params = {
				"enabled": true,
			}	
	
			if(state.selectedGovernanceType && state.selectedGovernanceType !== "All") {
				params.governance_type = state.selectedGovernanceType
			}
	
			if(state.selectedViewOption) {
				params.view = state.selectedViewOption
				if(state.selectedPolicyType === "all_rules") {
					params.all_rules = true
				}
			}
	
			
			let appliesTo = {}
			if(state.selectedAccount && state.selectedAccount !== "") {
				appliesTo.account_id = state.selectedAccount
			}
			if(state.selectedRegion && state.selectedRegion.length) {
				appliesTo.region = state.selectedRegion
			}
			if(state.selectedTags && state.selectedTags.length) {
				appliesTo.tags = state.selectedTags
			}
			if(state.selectedUsers && state.selectedUsers.length) {
				appliesTo.users = state.selectedUsers
			}
			if(state.selectedRoles && state.selectedRoles.length) {
				appliesTo.roles = state.selectedRoles
			}
	
			if(state.selectedGovernanceType !== "opa" && appliesTo && Object.entries(appliesTo).length) {
				params.applies_to = appliesTo
			}
	
			if(state.selectedViewOption === "rule_wise") {
				if(state.selectedFilters && state.selectedFilters.length) {
					let groupedData = _.chain(state.selectedFilters)
									.groupBy('label')
									.map((value, key) => ({ groupLabel: key, groupValue: value })).value()
	
					groupedData.forEach(row => {
						let data = []
						row.groupValue.forEach(item => {
							data.push(item.value)
						})
						params[row.groupLabel] = data
					})
		
				}
			}
	
			params.not_configured = false
			if(state.selectedPolicyType === "Not Configured") {
				params.not_configured = true
			}
			
			
			let showFilteredList = true
			if(Object.entries(params).length === 2) {
				showFilteredList = false
			}
	
			let dynamicRulePoliceColumns = []
			if(state.selectedViewOption === "rule_wise") {
				let dataRow = {}
		
				dataRow.Header = 'Type'
				dataRow.accessor = 'governance_type'
				dataRow.Cell = cellInfo => (
					<span>{cellInfo.row.original.governance_type ? (state.governanceTypes && state.governanceTypes.filter(e => e.value === cellInfo.row.original.governance_type).length ? state.governanceTypes.filter(e => e.value === cellInfo.row.original.governance_type)[0].label : cellInfo.row.original.governance_type) : "" }</span>
				)
				dataRow.width = 200
				dynamicRulePoliceColumns.push(dataRow)
	
				dataRow = {}
				dataRow.Header = 'Service'
				dataRow.accessor = 'resource_type'	
				dataRow.width = state.selectedPolicyType !== "all_rules" ? 100	: 250
				dynamicRulePoliceColumns.push(dataRow)
	
				dataRow = {}
				dataRow.Header = 'Category'
				dataRow.accessor = 'severity'
				dataRow.Cell = cellInfo => (
					Array.isArray(cellInfo.row.original.rule_category) ?
						cellInfo.row.original.rule_category.length ?
							<div className='flex'>
								{cellInfo.row.original.rule_category.map((row, rIndex) => {
									return(
										<span className={`${!rIndex ? '' : 'mx-1'}`}>{row}{(rIndex+1) === cellInfo.row.original.rule_category.length ? '  Policy Rules' : ','}</span>
									)
								})}
							</div>
						: null
					: cellInfo.row.original.rule_category ?
						<span>{cellInfo.row.original.rule_category} Policy Rules</span>
					: null
				)
				dataRow.width = state.selectedPolicyType !== "all_rules" ? 200 : 350
				dynamicRulePoliceColumns.push(dataRow)
	
				if(state.selectedPolicyType !== "all_rules") {	
					dataRow = {}
					dataRow.Header = 'Resources'
					// dataRow.accessor = 'severity'
					dataRow.Cell = cellInfo => (
						<div className="flex">
							<span className='mr-2'>{cellInfo.row.original.appResources}</span>
							{cellInfo.row.original.showMore ?
								<span className={`badge status-badge badge-outline-info`} onClick={() => setState(prevState => ({ ...prevState, masterData: cellInfo.row.original && cellInfo.row.original.ruleData ? cellInfo.row.original.ruleData : cellInfo.row.original, showFilterDetailsPanel: true }))}>more</span>
							: null}
						</div>
					)
					dataRow.width = 300
					dynamicRulePoliceColumns.push(dataRow)
				}
	
				dataRow = {}	
				dataRow.Header = 'Tags'
				dataRow.Cell = cellInfo => (
					<div className="flex">
						{cellInfo.row.original.ruleTags && cellInfo.row.original.ruleTags.length ? 
							<span className={`badge status-badge risk-badge-outline-medium risk-medium`}>{cellInfo.row.original.ruleTags[0]}</span>
						: null}
					</div>
				)
				dataRow.width = state.selectedPolicyType !== "all_rules" ? 150 : 250
				dynamicRulePoliceColumns.push(dataRow)
	
				dataRow = {}	
				dataRow.Header = 'Status'
				dataRow.Cell = cellInfo => (
					<span className={`badge status-badge badge-outline-${cellInfo.row.original.ruleEnabled ? "success" : "danger"}`}>{cellInfo.row.original.ruleEnabled ? "Enabled" : "Disabled"}</span>
				)
				dataRow.width = state.selectedPolicyType !== "all_rules" ? 100 : 150
				dynamicRulePoliceColumns.push(dataRow)
	
				if(state.selectedPolicyType !== "all_rules") {	
					dataRow = {}
					dataRow.Header = 'Actions'
					// dataRow.accessor = 'severity'
					dataRow.Cell = cellInfo => (
						<div className="flex justify-between self-center">
							<p className="m-0 p-0 mx-1 cursor-pointer" 
								onClick={() => {
									// dispatch(setGovernancePropsDetails('editNewRuleDetails', false))
									setState(prevState => ({ ...prevState, masterData: cellInfo.row.original && cellInfo.row.original.ruleData ? cellInfo.row.original.ruleData : cellInfo.row.original, showFilterDetailsPanel: true }))
								}}
							>
								<Icon icon="streamline:open-book" width='16' height='16' className="mr-1 self-center cursor-pointer"  />
							</p>
						</div>
					)
					dataRow.width = 100
					dynamicRulePoliceColumns.push(dataRow)
				}
	
				dataRow = {}	
				dataRow.Header = 'Description'
				dataRow.accessor = "ruleDescription"
				dataRow.width = 400
				dynamicRulePoliceColumns.push(dataRow)
			}
			
			dispatch(listGovernancePolicies(params))
				.then((response) => {
					if(response) {
						setState(prevState => ({ ...prevState, governanceRules: response, filteredArray: [], showLoading: false, showFilteredList, queryLoading: false, selectedView: state.selectedViewOption, dynamicRulePoliceColumns, callStructurePolicyDetails: true }))
					} else {
						setState(prevState => ({ ...prevState, showLoading: false, governanceRules: [], filteredArray: [], showFilteredList, queryLoading: false, selectedView: state.selectedViewOption, dynamicRulePoliceColumns }))
					}
				})
		}
	}, [state.callSearch, dispatch, state.governanceTypes, state.selectedAccount, state.selectedFilters, state.selectedGovernanceType, state.selectedPolicyType, state.selectedRegion, state.selectedRoles, state.selectedTags, state.selectedUsers, state.selectedViewOption])

	useEffect(() => {
		if(state.callStructurePolicyDetails) {
			setState(prevState => ({ ...prevState, callStructurePolicyDetails: false }))
			let data = state.governanceRules
			let policies = []
			data.length && data.forEach(row => {
				let policy_metadata = row.policy_metadata ? row.policy_metadata : {}
				let policyData = row.policy ? row.policy : {}
				let dataRow = {...policyData, ...policy_metadata}
	
				let appliesTo = row.policy_metadata && row.policy_metadata.applies_to ? row.policy_metadata.applies_to : []
				if(appliesTo.length) {
					let appAccounts = []	
					let appRegions = []
					let appTags = []
					let appUsers = []
					let appRoles = []
					let appResources = row.policy_metadata && row.policy_metadata.provider ? capitalizeAllLetter(row.policy_metadata.provider) : ""
					appliesTo.forEach((item, index) => {
						if(item.account_id){
							appAccounts.push(item.account_id)
						}
	
						if(item.region && Array.isArray(item.region)) {
							item.region.forEach(reg => {
								appRegions.push(reg)
							})
						} else if(item.region) {
							appRegions.push(item.region)
						}
						item.tags && item.tags.length && item.tags.forEach(tag => {
							appTags.push(tag)
						})
						item.users && item.users.length && item.users.forEach(usr => {
							appUsers.push(usr)
						})
						item.roles && item.roles.length && item.roles.forEach(rol => {
							appRoles.push(rol)
						})
					})
					
					if(appAccounts.length) {
						appResources += (row.policy_metadata && row.policy_metadata.provider ? " : " : "")+getAccountNameFromId(appAccounts[0], state.accounts)
					} 
	
					if(appRegions.length) {
						appResources += " : "+getRegionName(appRegions[0], state.regions)
					}
	
					// if(appRegions.length) {
					// 	appResources += " : "+appRegions[0]
					// }
	
					// row.appAccounts = appAccounts
					// row.appRegions = appRegions
					// row.appTags = appTags
					// row.appUsers = appUsers
					// row.appRoles = appRoles
	
					dataRow.appResources = appResources
					dataRow.showMore = (appAccounts.length > 1 || appRegions.length > 1) ? true : false
				}
				dataRow.ruleDescription = policyData.description
				dataRow.ruleTags = policyData.tags
				dataRow.ruleEnabled = policyData.enabled
	
				dataRow.ruleData = row
				policies.push(dataRow)
			})
	
			setState(prevState => ({ ...prevState, governancePolicies: policies, filteredArray: policies, callFormHeaders: true }))
		}
	}, [state.callStructurePolicyDetails, state.accounts, state.governanceRules, state.regions])

	useEffect(() => {
		if(state.callFormHeaders) {
			setState(prevState => ({ ...prevState, callFormHeaders: false }))
			let dataResult = state.filteredArray && state.filteredArray.length ? state.filteredArray : []
			if(dataResult.length) {
				let headers = []
				Object.entries(dataResult[0]).forEach(([key, value]) => {
					if(typeof value === "string") {
						let headerRow = {}
						headerRow.label = capitalizeTheFirstLetterOfEachWord(key)
						headerRow.key = key
						headers.push(headerRow)
					}
				})
				setState(prevState => ({ ...prevState, headers }))
			}
		}
	}, [state.callFormHeaders, state.filteredArray])

	useEffect(() => {
		if(state.calltoGetPolicyInformation) {			
			let details = state.metaData[state.selectedGovernanceType] ? state.metaData[state.selectedGovernanceType] : {}

			let data = []

			if(Object.entries(details).length) {
				data = Object.keys(details)
			}

			setState(prevState => ({ ...prevState, calltoGetPolicyInformation: false, policyTypeOptions: data, mainDropdown: data }))
		}
	}, [state.calltoGetPolicyInformation, state.metaData, state.selectedGovernanceType])

	useEffect(() => {
		if(state.tag_key) {
			let params = {}
			params.tags = [{ "key": state.tag_key }]
			dispatch(getAllTagsValues(params))
				.then((response) => {
					if(response) {
						setState(prevState => ({ ...prevState, tagValues: response }))
					}
				})
		}
	}, [state.tag_key, dispatch])

	const addNewTag = () => {
		let selectedTags = state["selectedTags"] ? state["selectedTags"] : []
		if(state["tag_key"] && state["tag_key"] !== "" && state["tag_value"] && state["tag_value"] !== "" ) {
			let dataRow = {
				key: state["tag_key"],
				value: state["tag_value"],
			}
			selectedTags.push(dataRow)
		}


		setState(prevState => ({ ...prevState, selectedTags: selectedTags, tag_key: "",  tag_value: "", tagValues: [] }))
	}

	const removeTagSection = (tag) => {
		// filteredResult = state.selectedTags.filter(e => e.key !== tag.key && e.value !== tag.value)
		let filteredResult = state.selectedTags.filter(e => e !== tag)
		setState(prevState => ({ ...prevState, selectedTags: filteredResult }))
	}
	const onReset = () => {
		if(state.selectedFilters && state.selectedFilters.length) {
			let groupedData = _.chain(state.selectedFilters)
							.groupBy('label')
							.map((value, key) => ({ groupLabel: key, groupValue: value })).value()
			
			groupedData.forEach(row => {
				row.groupValue.forEach(item => {
					setState(prevState => ({ ...prevState, ["options_"+state.selectedOption+"".value]: false }))
				})
			})
		}

		setState(prevState => ({ 
			...prevState,
			selectedGovernanceType: "All",
			selectedAccount: "",
			selectedRegion: [],
			selectedTags: [],
			selectedUsers: [],
			selectedRoles: [],
			selectedFilters: [],
			selectedPolicyType: "Configured",
			callSearch: true
		}))
	}

    const comparisonFilter = () => {
		if(!state.searchText || state.searchText === '') {
            setState(prevState => ({ ...prevState, showMainDropdownOption: state.showMainDropdownOption ? false : true, selectedDefaultOption: '' }))
		}
	}
    
    useEffect(() => {
		setState(prevState => ({ ...prevState, showMainDropdownOption: false }))

		let filterData = _.orderBy(state.policyTypeOptions, ['Asc'])
		if(state.searchText !== '') {
			filterData = filterData.filter(
				filter => 
				filter.toLocaleLowerCase().includes(state.searchText) ||
				filter.includes(state.searchText)
			)
		}
		setState(prevState => ({ 
			...prevState, 
			selectedDefaultOption: '',
			mainDropdown: filterData, 
			showMainDropdownOption: state.searchText === '' ? false : true 
		}))
	}, [state.searchText, state.policyTypeOptions])

	useEffect(() => {
		if(state.calltoGetSelectedOptionValues) {
			let details = state.metaData[state.selectedGovernanceType]
			let selectedOptionValues = details && details[state.selectedOption] ? details[state.selectedOption] : []
	
			setState(prevState => ({ ...prevState, childDropdownOptions: selectedOptionValues, filteredChildDropdownOptions: selectedOptionValues, calltoGetSelectedOptionValues: false }))
		}

	}, [state.calltoGetSelectedOptionValues, state.metaData, state.selectedOption, state.selectedGovernanceType])


	const updateMultiSelectedOptions = (row, options) => {
		let data = state.selectedFilters ? state.selectedFilters : []
		let selectedMultiOptions = state.selectedMultiOptions ? state.selectedMultiOptions : []

		if(state["options_"+state.selectedOption+"_"+row]) {				
			let dataRow = {}
			dataRow.label = state.selectedOption
			dataRow.value = row

			data.push(dataRow)
		} else {
			data = data.filter(e => e.value !== row)
		}

		setState(prevState => ({ ...prevState, selectedFilters: data, selectedMultiOptions }))
	}

	useEffect(() => {
		if(state.showAdvancedSearch) {

			if(state.selectedGovernanceType === "infra") {
				if(!tagKeys || !tagKeys.length) {
					let params = {}
					// params.account_id = "265469181489"
					// params.region = "us-east-2"
					dispatch(getAllTagsKeys(params))
				}
			} else {
	
				let params = {}
				if(state.selectedAccount && state.selectedAccount !== "") {
					params.account_id = state.selectedAccount
				}
				// params.service_name = "iam-users"
				dispatch(listAssets(params))
					.then((response) => {
						if (response) {
							setState(prevState => ({ ...prevState, applies_to_users: response.results ? response.results : [] }))
						} else {
							setState(prevState => ({ ...prevState, applies_to_users: [] }))
						}
					})
	
				let roleParams = {}
				if(state.selectedAccount && state.selectedAccount !== "") {
					roleParams.account_id = state.selectedAccount
				}
				roleParams.service_name = "iam-roles"
	
				dispatch(listAssets(roleParams))
					.then((response) => {
						if (response) {
							setState(prevState => ({ ...prevState, applies_to_roles: response.results ? response.results : [] }))
						} else {
							setState(prevState => ({ ...prevState, applies_to_roles: [] }))
						}
					})
			}
		}
	}, [state.showAdvancedSearch, dispatch, state.selectedAccount, state.selectedGovernanceType, tagKeys])
	
	const onClickCreateRule = ruleObj => {
		dispatch(cleareNewEditRuleDetails());
		navigate(URL_PATH.GOVERNANCE_CREATE_NEW_RULE, {state: { ruleDetails: {} } })
	}

	// const onSideViewRule = (ruleId) => {
	// 	setState(prevState => ({ ...prevState, queryLoading: true }))
	// 	let params = {}
	// 	params.policy_name = ruleId
	// 	dispatch(getPolicyDetails(params))
	// 		.then((response) => {
	// 			if(response) {
	// 				let ruleDetails = response
	// 				let policyBasicDetails = ruleDetails.policy_metadata ? ruleDetails.policy_metadata : {}	
	// 				setState(prevState => ({ ...prevState, queryLoading: false, ruleDetails, policyBasicDetails, showListDetailsPanel: true }))
	// 			} else {
	// 				setState(prevState => ({ ...prevState, editGovernanceRule: false, queryLoading: false }))
	// 			}
	// 		})
	// }

	const onClickEditRule = (ruleId) => {
		// dispatch(setGovernancePropsDetails('appliesToError', false))
		let params = {}
		params.policy_name = ruleId
		dispatch(getPolicyDetails(params))
			.then((response) => {
				if(response) {
					navigate(URL_PATH.GOVERNANCE_EDIT_NEW_RULE, { state: { ruleDetails: response } });
				} else {
					setState(prevState => ({ ...prevState, editGovernanceRule: false }))
				}
			})
	}

	const onClickDeleteRule = (ruleId) => {
		let params = {}
		params.policy_name = ruleId
		dispatch(deleteGovernancePolicy(params))
			.then((response) => {
				if(response) {
					let messageType = 'danger'
					let message = response.message ? response.message : 'Error in deleting policy'
					if(response && response.status) {
						messageType = 'success'
						message = response.message ? response.message : 'Policy deleted successfully'

						setTimeout(() => { setState(prevState => ({ ...prevState, callSearch: true, deletePolicyDetails: '', deleteModal: false })) }, 2000)
					}

					setTimeout(() => { setState(prevState => ({ ...prevState, showDeleteLoading: false })) }, 2000)
	
					// setState(prevState => ({ ...prevState, saveLoading: false, message, messageType, createErrorMessage }))
	
					CommonNotification.addNotification({
						//title: 'Wonderful!',
						message: message,
						type: messageType,
						insert: 'top',
						container: 'top-center',
						// animationIn: ['animate__animated', 'animate__fadeIn'],
						// animationOut: ['animate__animated', 'animate__fadeOut'],
						dismiss: {
						duration: 5000,
						onScreen: false,
						pauseOnHover: true,
						showIcon: true,
						}
					});
				}
			})
	}

	const removeFilterData = (row) => {
		let selectedFilters = state.selectedFilters.filter(e => e.label === row.label && e.value === row.value)

		setState(prevState => ({ ...prevState, selectedFilters }))
	}

	const handleChildClick = (event, type, dropdownType, section) => {
		if (advanceSearchRef && advanceSearchRef.current && !advanceSearchRef.current.contains(event.target)) {
			setState(prevState => ({ ...prevState, showMainDropdownOption: false, selectedOption: "" }))
		}

		event.stopPropagation();
		let clickedChild = []
		if(type === 'child') {
            if(state.clickedChild && state.clickedChild.length) {
                state.clickedChild.forEach(row => {
                    setState(prevState => ({ ...prevState, [row]: false }))
                })
            }
            clickedChild.push(section)
            setState(prevState => ({ ...prevState, [section]: dropdownType === "singleDropDown" && state[section] ? false : true, clickedChild }))
        } else if(type === 'parent') {
            if(state.clickedChild && state.clickedChild.length) {
                state.clickedChild.forEach(row => {
                    setState(prevState => ({ ...prevState, [row]: false }))
                })
            }
            
            setState(prevState => ({ ...prevState, clickedChild }))
        }
	}
	
	return (
		<div onClick={(event) => handleChildClick(event, 'parent')}>
			<div className={`${state.showLoading ? '' : 'hidden'}`}>
				<div className='loading-wave'>
					<div className='overlayEqualizerLoader'>
						<div className="loading-bar"></div>
						<div className="loading-bar"></div>
						<div className="loading-bar"></div>
						<div className="loading-bar"></div>
					</div>
				</div>
			</div>
			{/* {state.showListDetailsPanel ? (
				<ViewSidePanel
					visitFrom={"List"}
					selectedProvider={state.selectedProvider}
					// resource_type={state.resource_type}
					ruleDetails={state.ruleDetails}
					governanceType={state.policyBasicDetails && state.policyBasicDetails.governance_type}
					closeSidePanel={() => setState(prevState => ({ ...prevState, showListDetailsPanel: false }))}
				/>
			) : null} */}
			{state.showFilterDetailsPanel ? (            
				<FilterSidePanel
					masterData={state.masterData}
					closeSidePanel={(type) => setState(prevState => ({ ...prevState, queryLoading: type === 'refresh' ? true : false, callSearch: type === 'refresh' ? true : false,  showFilterDetailsPanel: false }))}
				/>
			) : null}
			<div className="bg-nero w-full h-fit relative stickyUiChange">
                <div className="grid grid-cols-12 items-center pb-4">
                    <div className="xl:col-span-8 col-span-12 self-center xl:mt-4">
                        <p className="text-white mx-4 text-xl md:mt-0 mt-4 font-semibold">Governance Rules</p>
                        <p className="mx-4 text-white">Filter user governance rules on your Cloud</p>
                    </div>
					<div className={`xl:col-span-4 col-span-12 items-center lg:mt-4 mt-6 flex justify-end mr-2`}>
						<button className={`btn bg-green rounded-md px-2 py-1 text-white ml-2 text-nowrap`} onClick={() => onClickCreateRule(state.selectedTableRow)}>Create Rule</button>
					</div>
				</div>
			</div>
			<div className='m-2'>
				<div className='w-full'>
					<div className='bg-dark rounded p-3'>
						<div className="flex flex-wrap gap-2">
							<div className="w-fit">
								<div className="flex justify-between">
									<label className="self-center m-0 text-white">Governance Type </label>
								</div>
								<div onClick={(event) => {
										if(!state.isGovernanceTypeOpen) {
											event.preventDefault();
											handleChildClick(event, 'child', 'singleDropDown', "isGovernanceTypeOpen")
										}
									}}
								>
									<p className={`flex bg-transparent border-b border-lightGray px-2 py-1 justify-between px-2 mb-0 truncate cursor-pointer ${state.selectedGovernanceType ? 'text-white' : 'text-lightGray'}`}>
										{state.selectedGovernanceType ? (state.governanceTypes && state.governanceTypes.filter(e => e.value === state.selectedGovernanceType).length ? state.governanceTypes.filter(e => e.value === state.selectedGovernanceType)[0].label : state.selectedGovernanceType) : 'Select'}
										<Icon icon="icon-park-solid:down-one" className={`${state.selectedGovernanceType ? "text-white" : "text-lightGray"} self-center`} width="16" height="16" />
									</p>
									{state.isGovernanceTypeOpen ?
										<MultiSelectSection
											fields={["value", "label"]}
											options={state.governanceTypes}
											selectedValues={state.selectedGovernanceType ? state.selectedGovernanceType : ''}
											callbackMultiSelect={(value) => {
												if(!value || typeof(value) === 'string') {
													setState(prevState => ({ ...prevState, selectedGovernanceType: value, calltoGetPolicyInformation: true }))
												} else {
													value.preventDefault()
													handleChildClick(value, "search", 'singleDropDown', "")
												}
											}}
											singleSelection={true}
											addAllField={true}
											hideSearch={true}
											topClass={'auto'}
											widthClass={'minWidth220'}
											removeTopOptions={true}
										/>
									: null}
								</div>
							</div>
							{state.selectedGovernanceType !== "opa" && state.selectedGovernanceType !== "opa_kubernetes"  ?
								<React.Fragment>
								<div className="mr-4 min-w-28">
									<div className="flex justify-between">
										<label className="self-center m-0 text-white">Provider</label>
									</div>
									<div onClick={(event) => {
											if(!state.isProviderOpen) {
												event.preventDefault();
												handleChildClick(event, 'child', 'singleDropDown', "isProviderOpen")
											}
										}}
									>
										<p className={`flex bg-transparent border-b border-lightGray px-2 py-1 justify-between px-2 mb-0 truncate cursor-pointer ${state.selectedProvider ? 'text-white' : 'text-lightGray'}`}>
											{state.selectedProvider ? state.selectedProvider : 'Select'}
											<Icon icon="icon-park-solid:down-one" className={`${state.selectedProvider ? "text-white" : "text-lightGray"} self-center`} width="16" height="16" />
										</p>
										{state.isProviderOpen ?
											<MultiSelectSection
												fields={["provider_name", "provider_name"]}
												options={state.providers}
												selectedValues={state.selectedProvider ? state.selectedProvider : ''}
												callbackMultiSelect={(value) => {
													if(!value || typeof(value) === 'string') {
														setState(prevState => ({ ...prevState, selectedProvider: value, selectedAccount: '', selectedRegion: '' }))
													} else {
														value.preventDefault()
														handleChildClick(value, "search", 'singleDropDown', "")
													}
												}}
												singleSelection={true}
												hideSearch={true}
												topClass={'auto'}
												widthClass={'minWidth220'}
												removeTopOptions={true}
											/>
										: null}
									</div>
								</div>
								<div className="mr-4 w-40">
									<div className="flex justify-between">
										<label className="self-center m-0 text-white">Account</label>
									</div>
									<div onClick={(event) => {
											if(!state.isAccountOpen) {
												event.preventDefault();
												handleChildClick(event, 'child', 'singleDropDown', "isAccountOpen")
											}
										}}
									>
										<p className={`flex bg-transparent border-b border-lightGray px-2 py-1 justify-between px-2 mb-0 truncate cursor-pointer ${state.selectedAccount ? 'text-white' : 'text-lightGray'}`}>
											{state.selectedAccount ? getAccountNameFromId(state.selectedAccount, state.accounts) : 'Select'}
											<Icon icon="icon-park-solid:down-one" className={`${state.selectedAccount ? "text-white" : "text-lightGray"} self-center`} width="16" height="16" />
										</p>
										{state.isAccountOpen ?
											<MultiSelectSection
												fields={["account_id", "account_name"]}
												options={state.accounts}
												selectedValues={state.selectedAccount ? state.selectedAccount : ''}
												callbackMultiSelect={(value) => {
													if(!value || typeof(value) === 'string') {
														setState(prevState => ({ ...prevState, selectedAccount: value, selectedRegion: '' }))
													} else {
														value.preventDefault()
														handleChildClick(value, "search", 'singleDropDown', "")
													}
												}}
												singleSelection={true}
												hideSearch={true}
												topClass={'auto'}
												widthClass={'minWidth220'}
												removeTopOptions={true}
											/>
										: null}
									</div>
								</div>
								</React.Fragment>
							:null}
							<div className="mr-4 w-40">
								<div className="flex justify-between">
									<label className="self-center m-0 text-white">Region</label>
								</div>
								<div onClick={(event) => {
										if(!state.isRegionOpen) {
											event.preventDefault();
											handleChildClick(event, 'child', 'singleDropDown', "isRegionOpen")
										}
									}}
								>
									<p className={`flex bg-transparent border-b border-lightGray px-2 py-1 justify-between px-2 mb-0 truncate cursor-pointer ${state.selectedRegion ? 'text-white' : 'text-lightGray'}`}>
										{state.selectedRegion ? getRegionName(state.selectedRegion, state.regions) : 'Select'}
										<Icon icon="icon-park-solid:down-one" className={`${state.selectedRegion ? "text-white" : "text-lightGray"} self-center`} width="16" height="16" />
									</p>
									{state.isRegionOpen ?
										<MultiSelectSection
											fields={["region", "name"]}
											options={state.regions}
											selectedValues={state.selectedRegion ? state.selectedRegion : ''}
											callbackMultiSelect={(value) => {
												if(!value || typeof(value) === 'string') {
													setState(prevState => ({ ...prevState, selectedRegion: value }))
												} else {
													value.preventDefault()
													handleChildClick(value, "search", 'singleDropDown', "")
												}
											}}
											singleSelection={true}
											topClass={'auto'}
											widthClass={'minWidth220'}
											removeTopOptions={true}
										/>
									: null}
								</div>
							</div>
							<div className="mr-4 w-40">
								<div className="flex justify-between">
									<label className="self-center m-0 text-white">View</label>
								</div>
								<div onClick={(event) => {
										if(!state.isViewOpen) {
											event.preventDefault();
											handleChildClick(event, 'child', 'singleDropDown', "isViewOpen")
										}
									}}
								>
									<p className={`flex bg-transparent border-b border-lightGray px-2 py-1 justify-between px-2 mb-0 truncate cursor-pointer ${state.selectedViewOption ? 'text-white' : 'text-lightGray'}`}>
										{state.selectedViewOption ? state.viewOptions.filter(e => e.value === state.selectedViewOption)[0].label : 'Select'}
										<Icon icon="icon-park-solid:down-one" className={`${state.selectedViewOption ? "text-white" : "text-lightGray"} self-center`} width="16" height="16" />
									</p>
									{state.isViewOpen ?
										<MultiSelectSection
											fields={["value", "label"]}
											options={state.viewOptions}
											selectedValues={state.selectedViewOption ? state.selectedViewOption : ''}
											callbackMultiSelect={(value) => {
												if(!value || typeof(value) === 'string') {
													setState(prevState => ({ ...prevState, selectedViewOption: value, selectedPolicyType: value === "policy_wise" && state.selectedPolicyType === "all_rules" ? "Configured" : state.selectedPolicyType }))
												} else {
													value.preventDefault()
													handleChildClick(value, "search", 'singleDropDown', "")
												}
											}}
											singleSelection={true}
											hideSearch={true}
											topClass={'auto'}
											widthClass={'minWidth220'}
											removeTopOptions={true}
										/>
									: null}
								</div>
							</div>
							<div className="mr-4 w-40">
								<div className="flex justify-between">
									<label className="self-center m-0 text-white">Policy Type</label>
								</div>
								<div onClick={(event) => {
										if(!state.isPolicyType) {
											event.preventDefault();
											handleChildClick(event, 'child', 'singleDropDown', "isPolicyType")
										}
									}}
								>
									<p className={`flex bg-transparent border-b border-lightGray px-2 py-1 justify-between px-2 mb-0 truncate cursor-pointer ${state.selectedPolicyType ? 'text-white' : 'text-lightGray'}`}>
										{state.selectedPolicyType ? state.selectedPolicyType : 'Select'}
										<Icon icon="icon-park-solid:down-one" className={`${state.selectedPolicyType ? "text-white" : "text-lightGray"} self-center`} width="16" height="16" />
									</p>
									{state.isPolicyType ?
										<MultiSelectSection
											fields={["label", "value"]}
											options={state.policyTypes && (state.selectedPolicyType !== "rule_wise" ? state.policyTypes.filter(e => e.value !== "all_rules") : state.policyTypes)}
											selectedValues={state.selectedPolicyType ? state.selectedPolicyType : ''}
											callbackMultiSelect={(value) => {
												if(!value || typeof(value) === 'string') {
													setState(prevState => ({ ...prevState, selectedPolicyType: value }))
												} else {
													value.preventDefault()
													handleChildClick(value, "search", 'singleDropDown', "")
												}
											}}
											singleSelection={true}
											hideSearch={true}
											topClass={'auto'}
											widthClass={'minWidth220'}
											removeTopOptions={true}
										/>
									: null}
								</div>
							</div>
							{!state.showAdvancedSearch ?							
								<div className="self-center">
									<div className={`mx-2 self-center`}>
										<div className="flex space-x-0" role="group">
											<button type="button" className="bg-info hover:bg-blue-700 text-white border-r-2 font-bold px-2 py-1 rounded-l inline-flex items-center"
												onClick={()=> setState(prevState => ({ ...prevState, queryLoading: true, callSearch: true }))}
											>
												<span className='text-white'>Search</span>
											</button>
											<button type="button" className="bg-info hover:bg-red-700 text-white font-bold px-2 py-1 rounded-r inline-flex items-center"
												onClick={() => onReset()}
											>
												<Icon icon="mdi:reload" className="text-white" width="18" height="18" />
											</button>
										</div>
									</div>
									
									{/* {state.selectedViewOption === "rule_wise" ?
										<p className="mb-0 mt-2 text-info cursor-pointer" onClick={() => setState(prevState => ({ ...prevState, showAdvancedSearch: !state.showAdvancedSearch }))}>Advanced Search</p>
									: null} */}
								</div>
							: null}
						</div>
						{state.selectedViewOption === "rule_wise" && state.showAdvancedSearch ? 
							<React.Fragment>
							<div className="mt-3">
								{state.selectedFilters && state.selectedFilters.length ? 
									<div className="flex flex-wrap mt-3 mb-2">
										{state.selectedFilters.map(row => {
											return (
												<small key={'filter_'+row.label} className={`badge badge-secondary mr-1`}>{row.label+" : "+row.value}
												<i className='ml-2 f14 fas fa-times-circle cursor-pointer text-lightGray' onClick={() => removeFilterData(row)} ></i>
												</small>
												
											)
										})}
									</div>
								: null}
								<div className="position-relative d-inline-block w-25 mt-2" ref={advanceSearchRef}>
									<label className="mb-1">Search</label>
									<div className="comparisonTextboxDiv p-0 m-0">
										<input type="text" className={`comparisonTextbox bg-transparent text-white border-lightGray py-2 pl-3 rounded`}
											onChange={e => setState(prevState => ({ ...prevState, searchText: e.target.value }))}
											value={state.searchText}
											onClick={ () => comparisonFilter() }
											placeholder={state.placeHolder}
										/>
									</div>
									{state.showMainDropdownOption ?
										<div className={`dropdownOptions topAuto mt-2 bg-muted border-muted`}>
											{state.mainDropdown && state.mainDropdown.map(row => {
												return(
													<button key={'drop_'+row} className="flex justify-between mb-3 bg-muted text-white cursor-pointer" onClick={() => setState(prevState => ({ ...prevState, selectedOption: row, showDropdown: false, searchKey: "", calltoGetSelectedOptionValues: true }))}>
														<p className="mb-0 text-left">{row}</p>
													</button>
												)
											})}
										</div>
									: null}
									{state.selectedOption && state.selectedOption !== "" ?
										<div className={`dropdownOptions topAuto mt-2 bg-muted border-dark`}>
											{state.filteredChildDropdownOptions && state.filteredChildDropdownOptions.map(row => {
												return(		
													<div key={'cbildDrop_'+row} className="flex mb-3 bg-muted text-white cursor-pointer" onClick={() => {
														setState(prevState => ({ ...prevState, ["options_"+state.selectedOption+"_"+row]: !state["options_"+state.selectedOption+"_"+row] }))
														updateMultiSelectedOptions(row, state.filteredChildDropdownOptions)
													}}>
														<div className="ml-1 form-check">
															<input type="checkbox"
																className="form-check-input"
																checked={state["options_"+state.selectedOption+"_"+row]}
															/>
														</div>
														<p className="ml-1 mb-0 text-left">{row}</p>
													</div>
												)
											})}
										</div>
									: null}
								</div>
							</div>
							
							{state.selectedGovernanceType === "infra" ?
								<div className="mt-3">
									<div className="flex justify-between">
										<label className="self-center m-0 text-white">Tags</label>
									</div>
									<div className="flex">
										<div onClick={(event) => {
												if(!state.isTagOpen) {
													event.preventDefault();
													handleChildClick(event, 'child', 'singleDropDown', "isTagOpen")
												}
											}}
										>
											<p className={`flex justify-between hiddenEllipses  mt-2 mb-2 ${state.tag_key ? 'text-white' : 'text-lightGray'}`}>
												{state.tag_key ? state.tag_key : 'Select'}
												<Icon icon="icon-park-solid:down-one" className={`${state.tag_key ? "text-white" : "text-lightGray"} self-center`} width="16" height="16" />
											</p>
											{state.isTagOpen ?
												<MultiSelectSection
													// fields={["provider_name", "provider_name"]}
													options={state.tagKeys}
													selectedValues={state.tag_key ? state.tag_key : ''}
													callbackMultiSelect={(value) => {
														if(!value || typeof(value) === 'string') {
															setState(prevState => ({ ...prevState, tag_key: value }))
														} else {
															value.preventDefault()
															handleChildClick(value, "search", 'singleDropDown', "")
														}
													}}
													singleSelection={true}
													hideSearch={true}
													topClass={'auto'}
													widthClass={'minWidth220'}
													removeTopOptions={true}
												/>
											: null}
										</div>
										<div onClick={(event) => {
												if(!state.isTagValueOpen) {
													event.preventDefault();
													handleChildClick(event, 'child', 'singleDropDown', "isTagValueOpen")
												}
											}}
										>
											<p className={`flex bg-transparent border-b border-lightGray px-2 py-1 justify-between px-2 mb-0 truncate cursor-pointer ${state.tag_value ? 'text-white' : 'text-lightGray'}`}>
												<Icon icon="icon-park-solid:down-one" className={`${state.tag_value ? "text-white" : "text-lightGray"} self-center`} width="16" height="16" />
												{state.tag_value ? state.tag_value : 'Select'}
											</p>
											{state.isTagValueOpen ?
												<MultiSelectSection
													// fields={["provider_name", "provider_name"]}
													options={state.tagValues}
													selectedValues={state.tag_value ? state.tag_value : ''}
													callbackMultiSelect={(value) => {
														if(!value || typeof(value) === 'string') {
															setState(prevState => ({ ...prevState, tag_value: value }))
														} else {
															value.preventDefault()
															handleChildClick(value, "search", 'singleDropDown', "")
														}
													}}
													singleSelection={true}
													hideSearch={true}
													topClass={'auto'}
													widthClass={'minWidth220'}
													removeTopOptions={true}
												/>
											: null}
										</div>
										<span className={`far fa-plus cursor-pointer f18 mt-2 mb-1 self-end mr-4`} onClick={() => addNewTag()}></span>

										{state.selectedTags && state.selectedTags.length ? 
											state.selectedTags.map((tag, index) => {
												return(
													<span key={'tag_'+index} className="badge badge-secondary mr-2 mt-2 self-center f14 px-2">{tag.key +" : "+tag.value}
														<i className='ml-2 f14 fas fa-times-circle cursor-pointer text-lightGray' onClick={ () => removeTagSection(tag) } ></i>
													</span>
												)
											})
										: null}
									</div>
								</div>
							: null}

							<div className='mt-4 flex'>
								<div className="btn-group" role="group" aria-label="governance-dash">
									<button type="button" className={`cursor-pointer btn btn-secondary border-right`} onClick={()=> setState(prevState => ({ ...prevState, queryLoading: true, callSearch: true }))}>Search</button>
									<button type="button" className={`cursor-pointer btn btn-secondary border-left`} onClick={()=> onReset()}><i className='far fa-redo'></i></button>
								</div>
								<button className="ml-4 btn btn-secondary" onClick={() => setState(prevState => ({ ...prevState, showAdvancedSearch: false }))} >Cancel</button>
							</div>
							</React.Fragment>
						: null}
					</div>
				</div>

				<div className='w-full py-2'>
					<div className='rounded-md bg-dark p-3'>
						<div className='flex justify-between mb-1'>
							<p className='mb-1 self-center'>Showing {state.filteredArray && state.filteredArray.length} of total {state.governancePolicies && state.governancePolicies.length} {state.selectedView === "policy_wise" ? "Policies" : "Rule(s)"}</p>
							{state.governancePolicies && state.governancePolicies.length ?
								<div className="flex w-1/2 justify-end">
									<CSVLink 
										data={state.filteredArray ? state.filteredArray : []} 
										headers={state.headers ? state.headers : []}
										filename={"resource-alerts.csv"}
										className={"self-center"}
										target="_blank"
									>
										<Icon icon="material-symbols:download" className='text-gray5 self-center self-center' width="22" height="22"/>
									</CSVLink>
									<Search
										data={state.governancePolicies ? state.governancePolicies : []}
										filteredData={filteredArray => {
											setState(prevState => ({ ...prevState, filteredArray }))
										}}
										topClassName={'bg-transparent text-white border border-lightGray rounded-lg cursor-pointer'}
										searchClassName={'px-1'}
										searchIconColor={'text-lightGray'}
										searchPlaceHolder={'Search....'}
										className={'bg-transparent text-white'}
										widthHeight={25}
									/>
								</div>
							: null}
						</div>
					
						{state.governancePolicies && state.governancePolicies.length && !state.queryLoading ?
							state.selectedView === "policy_wise" ?
								<ResizeableDarkThemeTable
									columns={[
										{
											Header: "Type",
											Cell: cellInfo => (
												<span>{cellInfo.row.original.governance_type ? (state.governanceTypes && state.governanceTypes.filter(e => e.value === cellInfo.row.original.governance_type).length ? state.governanceTypes.filter(e => e.value === cellInfo.row.original.governance_type)[0].label : cellInfo.row.original.governance_type) : "" }</span>
											),
											accessor: "governance_type",
											width: 200
										},
										{
											Header: "Policy Name",
											accessor: "policy_name",
											width: 200
										},
										{
											Header: "Category",
											Cell: cellInfo => (
												cellInfo.row.original.rule_category && cellInfo.row.original.rule_category.length ?
													<div className='flex'>
														{cellInfo.row.original.rule_category.map((row, rIndex) => {
															return(
																<span key={'tt_'+rIndex} className={`${!rIndex ? '' : 'mx-1'}`}>{row}{(rIndex+1) === cellInfo.row.original.rule_category.length ? '  Policy Rules' : ','}</span>
															)
														})}
													</div>
												: null
											),
											accessor: "rule_category",
											width: 300
										},
										{
											Header: "Resources",
											Cell: cellInfo => (
												<div className="flex">
													<span className='mr-2'>{cellInfo.row.original.appResources}</span>
													{cellInfo.row.original.showMore ?
														<span className={`badge status-badge badge-outline-info`}>more</span>
													: null}
												</div>
											),
											width: 250
										},
										{
											Header: "Actions",
											Cell: cellInfo => (
												<div className="flex self-center">
													{state.selectedView === "rule_wise" ?
														<Icon icon="streamline:open-book" width='16' height='16' className="mr-1 self-center cursor-pointer" onClick={() => setState(prevState => ({ ...prevState, masterData: cellInfo.row.original && cellInfo.row.original.ruleData ? cellInfo.row.original.ruleData : cellInfo.row.original, showFilterDetailsPanel: true }))} />
													:
														<React.Fragment>
														{state.actionsAllowed && state.actionsAllowed.includes('update') ?
															<Icon icon="octicon:pencil-24" width='16' height='16' className="mr-1 self-center cursor-pointer text-white" onClick={() => onClickEditRule(cellInfo.row.original.policy_name)} />
														: null}
														{state.actionsAllowed && state.actionsAllowed.includes('delete') ?
															<Icon icon="mdi:trash" width='16' height='16' className="mr-1 self-center cursor-pointer" onClick={() => setState(prevState =>({ ...prevState, deletePolicyDetails: cellInfo.row.original.policy_name, deleteModal: true }))} />
														: null}
														{/* <Icon icon="streamline:open-book" width='16' height='16' className="mr-1 self-center cursor-pointer" onClick={() => onSideViewRule(cellInfo.row.original.policy_name)} /> */}
														</React.Fragment>
													}
												</div>
											),
											width: 100
										},											
										{
											Header: "Description",
											accessor: "description",
											width: 350
										},
									]}
									
									data={state.filteredArray ? state.filteredArray : state.governancePolicies}
									perPage={20}
									tableHead={'bg-black text-white'}
									tableBody={'bg-dark3 text-white'}
									perPageClass={`bg-transparent text-lightGray`}
									textColor={'text-white'}
									selectedColor={'bg-transparent'}
									dashboard={state.filteredArray && state.filteredArray.length ? true : false}
									onClickRow={tableRow => {}}
									showPaginationTop={true}
									sortByColumn={"Date"}
									riskTooltip={[]}
								/>
							:
								<ResizeableDarkThemeTable
									columns={state.dynamicRulePoliceColumns}
									data={state.filteredArray ? state.filteredArray : state.governancePolicies}
									perPage={20}
									dashboard={(state.filteredArray ? state.filteredArray.length : (state.governancePolicies && state.governancePolicies.length)) ? true : false}
									sortByColumn={"Date"}
									riskTooltip={[0]}
									tableType={'table-dark-hover'}
									onClickRow={tableRow => {}}
									selectedColor={'bg-transparent'}
									// tableSize={"table-sm"}
									// tableHead={"bg-dark"}
									tooltipFor={[]}
								/>
						: (!state.queryLoading && !state.governancePolicies) ||
							(state.governancePolicies && !state.governancePolicies.length && !state.showLoading) ? 
							<div className="flex justify-center m-4 text-white">
								<p>There are no data on this criteria. Please try adjusting your filter.</p>
							</div>
						: null}
						{state.queryLoading ? (
							<div className="flex justify-center m-4">
								<svg className="animate-spin h-5 w-5 mr-2 text-white" fill="currentColor" viewBox="0 0 24 24">
									<circle cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="2" fill="none" />
									<path d="M4 12a8 8 0 0112-6.9" />
								</svg>
							</div>
						) : null}
					</div>
				</div>
			</div>

			<div id="popup-modal" tabIndex="-1" className={`flex ${state.deleteModal ? '' : 'hidden'} overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 h-[calc(100%-1rem)] max-h-full`}>
				<div className="relative p-4 w-full max-w-md max-h-full">
					<div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
						<button type="button" className="absolute top-3 end-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white" data-modal-hide="popup-modal" onClick={() => setState(prevState => ({ ...prevState, deleteModal: !state.deleteModal, deletePolicyDetails: '' }))}>
							<svg className="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
								<path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
							</svg>
							<span className="sr-only">Close modal</span>
						</button>
						<div className="p-4 md:p-5 text-center">
							<svg className="mx-auto mb-4 text-gray-400 w-12 h-12 dark:text-gray-200" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
								<path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M10 11V6m0 8h.01M19 10a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"/>
							</svg>
							<h3 className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">Are you sure you want to delete the police "{state.deletePolicyDetails ? state.deletePolicyDetails : ''}"?</h3>

							<div className='flex mt-4'>
							<button data-modal-hide="popup-modal" type="button" className="py-2 px-2 rounded-md text-white bg-green mr-2" 
								onClick={() => {
									setState(prevState => ({ ...prevState, showDeleteLoading: true }))
									if(!state.showDeleteLoading) {
										onClickDeleteRule(state.deletePolicyDetails ? state.deletePolicyDetails : '')
									}
								}}
							>
								Yes, I'm sure
							</button>
							<button data-modal-hide="popup-modal" type="button" className="py-2 px-2 rounded-md bg-red text-white" onClick={() => {
								if(!state.showDeleteLoading) {
									setState(prevState => ({ ...prevState, deleteModal: !state.deleteModal, showDeleteLoading: false }))
								}
							}}>
							No, cancel
							</button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default ManagePolicies;
