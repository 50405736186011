/*************************************************
 * Collider
 * @exports
 * @file CreateUpdateUserPanel.js
 * @author Prakash // on 18/01/2024
 * @copyright © 2024 Collider. All rights reserved.
 *************************************************/
import React, { useEffect, useState, useCallback, useRef} from 'react';
import { useDispatch, useSelector } from 'react-redux'; // Import useDispatch hook to dispatch actions
import { listAllProviders, listClusterFilters, listAllProjects, setCommonPropsDetails } from '../../../actions/commonActionNew'
import { createUser, updateUser, listUserFilters } from '../../../actions/UserAction'
import _ from 'lodash'
import { qosListAccounts } from '../../../actions/Collider/AccountAction';
import MultiSelectSection from '../../common/MultiSelectSection';
 import { Store as CommonNotification } from 'react-notifications-component';
import { validateEmail, getRegionName, getAccountNameFromId, removeUnderScore } from '../../../utils/utility';
import {Icon} from "@iconify/react";

const CreateUpdateUserPanel = ({ closeSidePanel, refreshApi, pageMode, selectedUserDetails }) => {
    const clickOut = useRef()

    const [state, setState] = useState({
        appliesMenu: [0],
        groupArray: [0],
        updateStrategies: ['drain', 'compute fleet stop', 'terminate', 'force update'],
        clusters: [],
        callUserFilters: true,
        onLoad: true,
    })

    const dispatch = useDispatch(); // Create a dispatch function
    const providers = useSelector(state => state?.filters?.providers || false)
    const propProjects = useSelector(state => state?.filters?.commonPropsDetails?.propProjects || false);
    const propProjAccounts = useSelector(state => state?.filters?.commonPropsDetails?.propProjAccounts || false);
    const propProjRegions = useSelector(state => state?.filters?.commonPropsDetails?.propProjRegions || false);

    const handleKeyDown = useCallback((event) => {
        if (event.key === 'Escape' || event.keyCode === 27) {
            closeSidePanel();
        }
    }, [closeSidePanel])
    
    useEffect(() => {
        // Add event listener for keydown
        window.addEventListener('keydown', handleKeyDown);
    
        // Cleanup event listener on component unmount
        return() => {
          window.removeEventListener('keydown', handleKeyDown);
        };
    }, [handleKeyDown]);

    useEffect(() => {
        if(providers) {
            setState(prevState => ({ ...prevState, providers: providers, selectedProvider: providers && providers.length ? (providers.filter(e => e.provider_name === 'AWS').length ? 'AWS' : providers[0].provider_name) : "" }));
        } else {
            let params = {}
            dispatch(listAllProviders(params))
                .then((response) => {
                    if(response) {
                        setState(prevState => ({ ...prevState, providers: response }));
                    }
                })
        }
    }, [dispatch, providers]);

    const getMenuList = useCallback((applicationId, roleId) => {
        let data = state.applications
        const application = data.find(app => app.application_id === applicationId);
        if (!application) return null;
      
        const role = application.roles.find(role => role.role_id === roleId);
        if (!role) return null;
      
        return role.menulist;
    }, [state.applications])

    useEffect(() => {
        if(state.callUserFilters) {
            setState(prevState => ({ ...prevState, callUserFilters: false }))
            let params = {};
            dispatch(listUserFilters(params))
                .then((response) => {
                    if(response) {
                        
                        let groups = response.groups ? response.groups : []
                        let applications = response.applications ? response.applications : []

                        setState(prevState => ({ ...prevState, groups, applications }));
                    }
                })
        }
    }, [state.callUserFilters, dispatch, pageMode])

    useEffect(() => {
        if(state.applications && selectedUserDetails && selectedUserDetails.applications && selectedUserDetails.applications.length){
            let applicationId = []
            selectedUserDetails.applications.forEach((app, appIndex) => {
                applicationId.push(app.application_id)
                
                setState(prevState => ({ ...prevState, ['role_id_'+appIndex]: app.role_id, ['menulist_'+appIndex]: getMenuList(app.application_id, app.role_id) }))
            })

            setState(prevState => ({ ...prevState, applicationId }))
        }
    }, [state.applications, selectedUserDetails, getMenuList])

    const getAccountRegion = useCallback((provider, project, account, region, arrIndex, type) => { 
        if(!region || (region && !region.length)) {
            let params = {};
            params.provider = provider.toLowerCase();
            
            if(propProjects && propProjects.hasOwnProperty(provider)) {
                setState(prevState => ({ ...prevState, ['projects_'+arrIndex]: propProjects[provider] }));
            } else {
                dispatch(listAllProjects(params))
                    .then((response) => {
                        if(response) {
                            let obj = propProjects ? propProjects : {}
                            obj[provider] = response
                            dispatch(setCommonPropsDetails('propProjects', obj))
                            setState(prevState => ({ ...prevState, ['projects_'+arrIndex]: response }))
                        }
                    });
            }
    
            let accParams = {};
            accParams.provider = provider.toLowerCase();
            accParams.aggregate_by = 'account_id';
            let label = provider
            if(project && project.length) {
                label = ''
                accParams.project_tags = project
            }
    
            if(propProjAccounts && propProjAccounts.hasOwnProperty(label)) {
                setState(prevState => ({ ...prevState, ['accounts_'+arrIndex]: propProjAccounts[label] }));
            } else {
                dispatch(listClusterFilters(accParams))
                    .then((response) => {
                        if(response) {
                            if(response.length) {
                                if(label) {
                                    let obj = propProjAccounts ? propProjAccounts : {}
                                    obj[label] = response
                                    dispatch(setCommonPropsDetails('propProjAccounts', obj))
                                }
                            }
                            setState(prevState => ({ ...prevState, ['accounts_'+arrIndex]: response }));
                        }
                    })
            }
    
            let regParams = {};
            regParams.provider = provider.toLowerCase();
            regParams.aggregate_by = 'region';
            let regLabel = provider
            if(project && project.length) {
                regLabel = ''
                regParams.project_tags = project
            }
            if(account && account.length) {
                regLabel = ''
                regParams.account_id = account
            }
            
            if(propProjRegions && propProjRegions.hasOwnProperty(regLabel)) {
                setState(prevState => ({ ...prevState, ['regions_'+arrIndex]: propProjRegions[regLabel] }));
            } else {
                dispatch(listClusterFilters(regParams))
                    .then((response) => {
                        if(response && response.length) {
                            let obj = propProjRegions ? propProjRegions : {}
                            obj[regLabel] = response
                            dispatch(setCommonPropsDetails('propProjRegions', obj))
                        }
                        setState(prevState => ({ ...prevState, ['regions_'+arrIndex]: response }))
                    })
            }
        }

        let clusParams = {};
        
        if(provider) {
            clusParams.provider = provider.toLowerCase();
        }
        if(project && project.length) {
            clusParams.project_tags = project
        }
		if(account && account.length) {
			clusParams.account_id = account
		}
		if(region && region.length) {
			clusParams.region = region
		}
        clusParams.aggregate_by = 'cluster_name';
        if(provider) {
            dispatch(listClusterFilters(clusParams))
                .then((response) => {
                    if(response) {
                        setState(prevState => ({ ...prevState, ['clusters_'+arrIndex]: response }));
                        if(type !== 'edit') {
                            setState(prevState => ({ ...prevState, ["selectedClusters_"+arrIndex]: [] }))
                        }
                    }
            })
        }

    }, [dispatch, propProjAccounts, propProjRegions, propProjects])

    useEffect(() => {
        let accParams = {};
        accParams.provider = 'aws'
        accParams.aggregate_by = 'account_id'
        let label = 'AWS'
        if(propProjAccounts && propProjAccounts.hasOwnProperty(label)) {
            setState(prevState => ({ ...prevState, providerAccounts: propProjAccounts[label] }));
        } else {
            dispatch(listClusterFilters(accParams))
                .then((response) => {
                    if(response) {
                        if(response.length) {
                            let obj = propProjAccounts ? propProjAccounts : {}
                            obj[label] = response
                            dispatch(setCommonPropsDetails('propProjAccounts', obj))
                        }
                        setState(prevState => ({ ...prevState, providerAccounts: response }));
                    }
                })
        }

        let regParams = {};
        regParams.provider = 'aws'
        regParams.aggregate_by = 'region'        
        if(propProjRegions && propProjRegions.hasOwnProperty(label)) {
            setState(prevState => ({ ...prevState, providerRegions: propProjRegions[label] }));
        } else {
            dispatch(listClusterFilters(regParams))
                .then((response) => {
                    if(response && response.length) {
                        let obj = propProjRegions ? propProjRegions : {}
                        obj[label] = response
                        dispatch(setCommonPropsDetails('propProjRegions', obj))
                    }
                    setState(prevState => ({ ...prevState, providerRegions: response }))
                })
        }
    }, [dispatch, propProjAccounts, propProjRegions])

    const getSlurmClusterDetailsFunction = useCallback((provider, account, region, slmIndex, selectedCluster) => {
        let params = {}
        params.provider = provider
        params.account_id = account
        params.region = region
        params.aggregate_by = 'cluster_name'

        dispatch(listClusterFilters(params))
            .then((response) => {
                if(response) {
                    setState(prevState => ({ ...prevState, ['groupClustersOptions_'+slmIndex]: response, ["grpCluster_"+slmIndex]: selectedCluster ? selectedCluster : '', fetchingCluster: false }));
                } else {
                    setState(prevState => ({ ...prevState, ['groupClustersOptions_'+slmIndex]: [], ["grpCluster_"+slmIndex]: '', fetchingCluster: false }));
                }
        })
    }, [dispatch])

    const getSlurmAccountDetails = useCallback((provider, account, region, cluster, slmIndex) => {
        let params = {}
        params.provider = provider
        params.account_id = account
        params.region = region
        params.cluster_name = cluster

        dispatch(qosListAccounts(params))
            .then((response) => {
                let results = []
                if(response) {
                    results = response.results ? response.results : []
                }
                setState(prevState => ({ ...prevState, ['groupList_'+slmIndex]: results, fetchingSlurmAccount: false }))
        })
    }, [dispatch])

    //for slurm account end

    useEffect(() => {
        if(state.onLoad && pageMode !== 'Create') {
            setState(prevState => ({ ...prevState, shortName: selectedUserDetails.shortName, emailId: selectedUserDetails.emailId, disabled: selectedUserDetails.disabled ? true : false, userName: selectedUserDetails.userName, roleId: selectedUserDetails.roleId, userType: selectedUserDetails.userType, allow_all: selectedUserDetails.allow_all ? true : false, onLoad: false }))

            let groupArray = []
            if(selectedUserDetails.account_list && selectedUserDetails.account_list.length) {
                selectedUserDetails.account_list.forEach((row, index) => {
                    groupArray.push(index)
                    setState(prevState => ({ ...prevState, ["grpAccount_"+index]: row.account_id ? row.account_id : '', ["grpRegion_"+index]: row.region ? row.region : '', ["grpCluster_"+index]: row.cluster_name ? row.cluster_name : '', ["selectedGroup_"+index]: row.account ? row.account : [] }))

                    if(row.provider && row.account_id && row.region) {
                        getSlurmClusterDetailsFunction('aws', row.account_id, row.region, index, row.cluster_name)
                    }
                    if(row.provider && row.account_id && row.region && row.cluster_name) {
                        getSlurmAccountDetails('aws', row.account_id, row.region, row.cluster_name, index)
                    }              
                })
            } else {
                groupArray = [0]
            }
            
            let groupId = []
            if(selectedUserDetails.groups && selectedUserDetails.groups.length) {
                selectedUserDetails.groups.forEach(app => {
                    groupId.push(app.group_id)
                })
            }
            setState(prevState => ({ ...prevState, groupId }))
        }
    }, [state.onLoad, pageMode, selectedUserDetails, getAccountRegion, getSlurmClusterDetailsFunction, getSlurmAccountDetails])

    useEffect(() => {
        if(state.callSaveFunction) {
            setState(prevState => ({ ...prevState, callSaveFunction: false }))
            let hasError = false
            let params = {}
            if(!state.shortName || !state.userName) {
                hasError = true
            }
            params.shortName = state.shortName
            if(pageMode === 'Create') {
                if(!state.emailId || !validateEmail(state.emailId)) {
                    hasError = true
                }
                params.emailId = state.emailId
            }
            params.userName = state.userName
            params.userType = state.userType
            params.disabled = state.disabled ? true : false

            if(pageMode === 'Edit') {
                params.update_user_id = selectedUserDetails.userId
            }

            if(state.applicationId && state.applicationId.length) {
                let applications = []
                state.applicationId.forEach((app, appIndex) => {
                    let application = state.applications.find(e => e.application_id === app)
                    if (application && state["role_id_"+appIndex]) {
                        let dataRow = {}
                        dataRow.application_id = application.application_id
                        dataRow.application_name = application.application_name
                        let role = application.roles.find(role => role.role_id === state["role_id_"+appIndex]);
                        dataRow.role_id = role.role_id
                        dataRow.role_name = role.role_name
                        applications.push(dataRow)
                    } else if(!state["role_id_"+appIndex]) {
                        hasError = true
                    }

                })
                params.applications = applications
            }

            params.allow_all = state.allow_all ? true : false

            if(!state.allow_all) {
                let groups = []
                if(state.groupId && state.groupId.length) {
                    state.groups.forEach(grp => {
                        if(state.groupId.includes(grp.group_id)) {
                            groups.push(grp)
                        }
                    })
                }
                params.groups = groups
            }
            

            let grpAccount = []
            if(state.groupArray && state.groupArray.length) {
                state.groupArray.forEach(slmIndex => {
                    if(state["grpAccount_"+slmIndex] && state["grpRegion_"+slmIndex] && state["grpCluster_"+slmIndex] && state["selectedGroup_"+slmIndex] && state["selectedGroup_"+slmIndex].length) {
                        let slurmRow = {}
                        slurmRow.provider = 'aws'
                        slurmRow.account_id = state["grpAccount_"+slmIndex]
                        slurmRow.region = state["grpRegion_"+slmIndex]
                        slurmRow.cluster_name = state["grpCluster_"+slmIndex]
                        slurmRow.account = state["selectedGroup_"+slmIndex]
                        grpAccount.push(slurmRow)
                    }
                })
            }
            if(grpAccount && grpAccount.length) {
                if(!_.isEqual(selectedUserDetails.account_list, grpAccount)) {
                    params.account_list = grpAccount
                }
            } else {
                params.account_list = []
            }

            setState(prevState => ({ ...prevState, hasError, saveLoading: hasError ? false : true }))

           if(!hasError) {
                if(pageMode === 'Create') {
                    dispatch(createUser(params))
                        .then((response) => {
                            let messageType = 'danger'
                            let message = response.message ? response.message : 'Error in saving User'
                            
                            if(response && response.status) {
                                refreshApi()
                                messageType = 'success'
                                message = response.message ? response.message : 'User Created Successfully'
                                
                                setTimeout(() => closeSidePanel(), 2000)
                            } else {
                                setState(prevState => ({ ...prevState, saveLoading: false }))
                            }
            
                            CommonNotification.addNotification({
                                //title: 'Wonderful!',
                                message: message,
                                type: messageType,
                                insert: 'top',
                                container: 'top-center',
                                // animationIn: ['animate__animated', 'animate__fadeIn'],
                                // animationOut: ['animate__animated', 'animate__fadeOut'],
                                dismiss: {
                                duration: 5000,
                                onScreen: false,
                                pauseOnHover: true,
                                showIcon: true,
                                }
                            });
            
                            // setState(prevState => ({ ...prevState, createRespons: response }))
                        })
                } else {
                    dispatch(updateUser(params))
                        .then((response) => {
                            let messageType = 'danger'
                            let message = response.message ? response.message : 'Error in updating User'
                            if(response && response.status) {
                                messageType = 'success'
                                message = response.message ? response.message : 'User Updated Successfully'

                                refreshApi()
                            }

                            setTimeout(() => setState(prevState => ({ ...prevState, saveLoading: false })), 2000)
            
                            // setState(prevState => ({ ...prevState, saveLoading: false, message, messageType, createErrorMessage }))
            
                            CommonNotification.addNotification({
                                //title: 'Wonderful!',
                                message: message,
                                type: messageType,
                                insert: 'top',
                                container: 'top-center',
                                // animationIn: ['animate__animated', 'animate__fadeIn'],
                                // animationOut: ['animate__animated', 'animate__fadeOut'],
                                dismiss: {
                                duration: 5000,
                                onScreen: false,
                                pauseOnHover: true,
                                showIcon: true,
                                }
                            });
                        })
                }
            }
        }
    }, [state.callSaveFunction, dispatch, pageMode, closeSidePanel, refreshApi, state, selectedUserDetails])

    const removeSlurmAccount = (value, arrIndex) => {
		let filteredResult = state["selectedGroup_"+arrIndex].filter(e => e !== value)
		setState(prevState => ({ ...prevState, ["selectedGroup_"+arrIndex]: filteredResult }))
	}

    const addFilterArray = (field) => {
		let rowList = state[field] ? state[field] : [0]
		if (state[field]) {
			let value = state[field][state[field].length - 1]
			value = value + 1
			rowList.push(value)
		}

		setState(prevState => ({ ...prevState, [field]: rowList }))
	}

	const removeFilterArray = (field, index, selItem) => {
		let rowList = state[field] ? state[field] : [0]
		rowList.splice(index, 1);
        if(field === 'groupArray') {
            if(!rowList.length) {
                rowList = [0]
            }
            setState(prevState => ({ ...prevState, ["grpAccount_"+selItem]: '', ["grpRegion_"+selItem]: '', ["grpCluster_"+selItem]: '', ["selectedGroup_"+selItem]: [] }))
        } else {
            setState(prevState => ({ ...prevState, ["moduleName_"+selItem]: '', ["selectedProvider_"+selItem]: '', ["selectedAccount_"+selItem]: [], ["selectedRegion_"+selItem]: [], ["selectedClusters_"+selItem]:  [] }))
        }
		setState(prevState => ({ ...prevState, [field]: rowList }))
    }

    const handleChildClick = (event, type, dropdownType, section) => {	
        
        if (clickOut.current && !clickOut.current.contains(event.target)) {
            closeSidePanel()
        }
		event.stopPropagation();
		let clickedChild = []
		if(type === 'child') {
            if(state.clickedChild && state.clickedChild.length) {
                state.clickedChild.forEach(row => {
                    setState(prevState => ({ ...prevState, [row]: false }))
                })
            }
            clickedChild.push(section)
            setState(prevState => ({ ...prevState, [section]: dropdownType === "singleDropDown" && state[section] ? false : true, clickedChild }))
        } else if(type === 'parent') {
            if(state.clickedChild && state.clickedChild.length) {
                state.clickedChild.forEach(row => {
                    setState(prevState => ({ ...prevState, [row]: false }))
                })
            }
            
            setState(prevState => ({ ...prevState, clickedChild }))
        }
	}

    return (
        <div className='advanced-search' style={{ zIndex: 999 }} onClick={(event) => handleChildClick(event, 'parent')}>
            <div className={`${state.showLoading ? '' : 'hidden'}`}>
                <div className='loading-wave'>
                    <div className='overlayEqualizerLoader'>
                        <div className="loading-bar"></div>
                        <div className="loading-bar"></div>
                        <div className="loading-bar"></div>
                        <div className="loading-bar"></div>
                    </div>
                </div>
            </div>
            <div className='search w-3/5 bg-nero overflow-auto' ref={clickOut}>
                <div className='header-search bg-black flex justify-between p-2'>
                    <p className='text-white text-xl'>{pageMode === "View" ? '' : (pageMode === "Edit" ? "Edit" : "Create")} {pageMode === "View" ? 'Details' : 'User'}</p>

                    <div className='self-center'>
                        <Icon icon="jam:close" className='text-white cursor-pointer' width="25" height="25" onClick={() => closeSidePanel()} />
                    </div>
                </div>
                <div className='m-2  overflow-auto'>
                    <div className='rounded-md bg-dark3 p-3'>
                        <div className='flex flex-wrap'>
                            <div className='py-1 lg:w-1/2 md:w-1/2 w-full'>
                                <p className='b-block mb-0 flex'> 
                                    AD Short Name 
                                    {pageMode !== 'View' ?
                                        <span className="fa fa-star text-purplishRed ml-1 f8 self-center"></span>
                                    : null}
                                    {state.hasError && !state.shortName ?
                                        <span className='mb-0 text-red ml-2'>required</span>
                                    : null}
                                </p>
                                {pageMode !== 'View' ?
                                    <div className='flex'>
                                        <input
                                            type='text'
                                            placeholder='short name'
                                            className={`border border-lightGray rounded-md bg-transparent px-2 py-1 text-white w-full`}
                                            value={state.shortName ? state.shortName : ''}
                                            onChange={e => setState(prevState => ({ ...prevState, shortName: e.target.value }))}
                                        />
                                    </div>
                                : 
                                    <p className='text-white b-block mb-0'>{state.shortName}</p>
                                }
                            </div>
                            <div className='py-1 lg:w-1/2 md:w-1/2 w-full lg:pl-3 md:pl-3 pl-0'>
                                <p className='b-block mb-0 flex'>
                                Email Id 
                                    {pageMode !== 'View' ?
                                        <span className="fa fa-star text-purplishRed ml-1 f8 self-center"></span>
                                    : null}
                                    {pageMode !== 'Edit' && state.hasError && !state.emailId ?
                                        <span className='mb-0 text-red ml-2'>required</span>
                                    : pageMode !== 'Edit' && state.hasError && state.emailId && !validateEmail(state.emailId) ?
                                        <span className='mb-0 text-red ml-2'>Invalid email id</span>
                                    : null}
                                </p>                                
                                {pageMode === 'Create' ?
                                    <div className='flex'>
                                        <input
                                            type='text'
                                            placeholder='email'
                                            className={`border border-lightGray rounded-md bg-transparent px-2 py-1 text-white w-full`}
                                            value={state.emailId ? state.emailId : ''}
                                            onChange={e => setState(prevState => ({ ...prevState, emailId: e.target.value }))}
                                        />
                                    </div>
                                :
                                    <p className='text-white b-block mb-0'>{state.emailId}</p>
                                }
                            </div>
                        </div>

                        <div className='flex flex-wrap mt-2 pt-2 border border-darkGray'>
                            <div className='py-1 lg:w-1/2 md:w-1/2 w-full'>
                                <p className='b-block mb-0 flex'>
                                Display Name 
                                    {pageMode !== 'View' ?
                                        <span className="fa fa-star text-purplishRed ml-1 f8 self-center"></span>
                                    : null}
                                    {state.hasError && !state.userName ?
                                        <span className='mb-0 text-red ml-2'>required</span>
                                    : null}
                                </p>                                
                                {pageMode !== 'View' ?
                                    <div className='flex'>
                                        <input
                                            type='text'
                                            placeholder='display name'
                                            className={`border border-lightGray rounded-md bg-transparent px-2 py-1 text-white w-full`}
                                            value={state.userName ? state.userName : ''}
                                            onChange={e => setState(prevState => ({ ...prevState, userName: e.target.value }))}
                                        />
                                    </div>
                                :
                                    <p className='text-white b-block mb-0'>{state.userName ? state.userName : ''}</p>
                                }
                            </div>
                        </div>
                        <div className='flex flex-wrap mt-2 pt-2 border border-darkGray'>
                            <div className='py-1 md:w-1/2 w-full'>
                                <div className='flex justify-between'>
                                    <p className="b-block mb-0 text-lightGray">Application Name</p>
                                    {state.hasError && (!state.applicationId || !state.applicationId.length) ?
                                        <span className='text-danger self-center'>required</span>
                                    : null}
                                </div>
                                <div className={`w-50 ${state.pageType === 'View' ? 'disabled' : ''}`} onClick={(event) => {
                                    event.preventDefault();
                                    handleChildClick(event, 'child', 'muliselectDropDown', "showApplications")
                                }}>
                                    <p className={`flex bg-transparent border border-lightGray rounded-md px-2 py-1 justify-between mb-0 truncate ${state.applicationId ? 'text-white' : 'text-lightGray'}`}>
                                        {state.applicationId && state.applicationId.length ? state.applicationId.length + ' Selected' : 'Select'}
                                        <Icon icon="icon-park-solid:down-one" className={`${state.applicationId && state.applicationId.length ? "text-white" : "text-lightGray"} self-center`} width="16" height="16" />
                                    </p>
                                    {state.showApplications && pageMode !== 'View' ?
                                        <div className='relative'>
                                            <MultiSelectSection 
                                                fields={["application_id", "application_name"]}
                                                options={state.applications}
                                                selectedValues={state.applicationId ? state.applicationId : ''}
                                                callbackMultiSelect={(value) => {
                                                    setState(prevState => ({ ...prevState, applicationId: value }))
                                                }}
                                                singleSelection={false}
                                                widthClass={'minWidth220'}
                                                removeTopOptions={true}
                                            />
                                        </div>
                                    : null}
                                </div>
                            </div>
                        </div>
                        
                        {state.applicationId && state.applicationId.length ? 
                            <div className='flex flex-wrap'>
                                {state.applicationId.map((app, accIndex) => {
                                    return(
                                        <span key={'acc1_'+accIndex} className="flex border border-gray3 rounded-full mr-2 mt-2 self-center text-info px-2">{state.applications && state.applications.filter(e => e.application_id === app).length && state.applications.filter(e => e.application_id === app)[0].application_name ? state.applications.filter(e => e.application_id === app)[0].application_name : app}
                                        <Icon icon="ion:close-circle-sharp" width="16" height="16"  className='text-white ml-2 self-center' onClick={ () => setState(prevState => ({ ...prevState, applicationId: state.applicationId.filter(e => e !== app)})) } />
                                        </span>
                                    )
                                })}
                            </div>
                        : null}

                        {state.applications && state.applicationId && state.applicationId.length ? 
                            state.applicationId.map((app, appIndex) => {
                                return(
                                    <div key={'app_'+appIndex} className='bg-black rounded-md p-3 mt-3'>
                                        <h5 className='text-lg mb-0 text-plum cursor-pointer flex'>{state.applications && state.applications.filter(e => e.application_id === app).length && state.applications.filter(e => e.application_id === app)[0].application_name ? state.applications.filter(e => e.application_id === app)[0].application_name : app}</h5>

                                        <div className='flex flex-wrap mt-2'>
                                            <div className='py-1 lg:w-1/2 md:w-1/2 w-full'>
                                                <div className='flex justify-between'>
                                                    <p className="b-block mb-0 text-lightGray">Role Name</p>
                                                    {state.hasError && !state['role_id_'+appIndex] ?
                                                        <span className='text-danger self-center'>required</span>
                                                    : null}
                                                </div>
                                                <div className={`w-50 ${state.pageType === 'View' ? 'disabled' : ''}`} onClick={(event) => {
                                                    if(!state["showRole_"+appIndex]) {
                                                        event.preventDefault();
                                                        handleChildClick(event, 'child', 'singleDropDown', "showRole_"+appIndex)
                                                    }
                                                }}>
                                                    <p className={`flex bg-transparent border border-lightGray rounded-md px-2 py-1 justify-between mb-0 truncate ${state['role_id_'+appIndex] ? 'text-white' : 'text-lightGray'}`}>
                                                        {state['role_id_'+appIndex] && state.applications.filter(e => e.application_id === app)[0].roles && state.applications.filter(e => e.application_id === app)[0].roles.filter(e => e.role_id === state['role_id_'+appIndex]).length ? state.applications.filter(e => e.application_id === app)[0].roles.filter(e => e.role_id === state['role_id_'+appIndex])[0].role_name : 'Select'}
                                                        <Icon icon="icon-park-solid:down-one" className={`${state['role_id_'+appIndex] ? "text-white" : "text-lightGray"} self-center`} width="16" height="16" />
                                                    </p>
                                                    {state["showRole_"+appIndex] && pageMode !== 'View' && state.applications && state.applications.filter(e => e.application_id === app).length && state.applications.filter(e => e.application_id === app)[0].roles ?
                                                        <div className='relative'>
                                                            <MultiSelectSection 
                                                                fields={["role_id", "role_name"]}
                                                                options={state.applications.filter(e => e.application_id === app)[0].roles}
                                                                selectedValues={state['role_id_'+appIndex] ? state['role_id_'+appIndex] : ''}
                                                                callbackMultiSelect={(value) => {
                                                                    if(!value || typeof(value) === 'string' || typeof(value) === 'number') {
                                                                        setState(prevState => ({ ...prevState, 
                                                                            ['role_id_'+appIndex]: value,
                                                                            ['menulist_'+appIndex]: getMenuList(app, value)
                                                                        }))
                                                                    } else {
                                                                        value.preventDefault()
                                                                        handleChildClick(value, "search", 'singleDropDown', "")
                                                                    }
                                                                    
                                                                }}
                                                                singleSelection={true}
                                                                widthClass={'minWidth220'}
                                                                removeTopOptions={true}
                                                            />
                                                        </div>
                                                    : null}
                                                </div>
                                            </div>
                                        </div>
                                        {state['role_id_'+appIndex] && state['menulist_'+appIndex] ?
                                            <div className='mt-2'>
                                                <h5 className='mb-0 text-plum cursor-pointer flex w-full'>Menu Access</h5>
                                                
                                                {state['menulist_'+appIndex].map(row => {
                                                    return(
                                                        <div key={'prd_'+appIndex} className={`flex flex-wrap ${appIndex ? "mt-1 pt-1 border-mediumDarkGray-top":  ""}`}>
                                                            <div className="py-1 w-full">
                                                                <p className={`flex bg-transparent justify-between mb-0 truncate text-white`}>
                                                                    {row.menu}
                                                                </p>
                                                            </div>
                                                            {row.submenulist && row.submenulist.length ?
                                                                <React.Fragment>
                                                                {/* <p className='ml-3'>Sub Menus</p> */}
                                                                <div className='ml-3 flex flex-wrap gap-2'>
                                                                    {row.submenulist.map((subMenu, smIndex) => {
                                                                        return(
                                                                            <div className="py-1" key={subMenu.submenu}>
                                                                                <p className={`flex bg-transparent mb-0 truncate text-white`}>
                                                                                    {subMenu.submenu} 
                                                                                    <span className='text-lightGray ml-1'>({subMenu.link})</span>
                                                                                    <span className='mr-1 text-white'>{row.submenulist.length === smIndex+1 ? '' : ', '}</span>
                                                                                </p>
                                                                            </div>
                                                                        )
                                                                    })}
                                                                </div>
                                                                </React.Fragment>
                                                            : null}
                                                        </div>
                                                    )
                                                })}

                                            </div>
                                        : null}
                                    </div>
                                )
                            })
                        : null}
                        
                        <div className='flex mt-2 pt-2 border-darkGray-top'>
                            <div className='py-1 lg:w-1/2 md:w-1/2 w-full self-center'>
                                <p className='b-block mb-1 flex'>Status</p>
                                <div className='flex self-center mt-3'>
                                    <label className="mb-0">
                                        <input type="checkbox" 
                                            className="mt-1" 
                                            checked={state.disabled ? true : false}
                                            onChange={(event) => {
                                                if(pageMode !== 'View') {
                                                    setState(prevState => ({ ...prevState, disabled: !state.disabled }))
                                                }
                                            }}
                                        />
                                        <span className="slider round small pl-1"></span>
                                    </label>
                                    <span className="mb-0 text-lightGray" onClick={() => setState(prevState => ({ ...prevState, disabled: !state.disabled }))}>Disable</span>
                                </div>
                            </div>
                        </div>
                    </div>

                    
                    <div className='bg-black rounded-md p-3 mt-3'>
                        <div className='flex justify-between'>
                            <div className='flex'>
                                <p className='mb-0 text-plum text-md text-lg cursor-pointer flex'>Group Associations</p>
                                <div className='flex self-center ml-3 mt-1'>
                                    <label className="mb-0">
                                        <input type="checkbox" 
                                            className="mt-1" 
                                            checked={state.allow_all ? true : false}
                                            onChange={(event) => {
                                                if(pageMode !== 'View') {
                                                    setState(prevState => ({ ...prevState, allow_all: !state.allow_all }))
                                                }
                                            }}
                                        />
                                        <span className="slider round small pl-1"></span>
                                    </label>
                                    <span className="mb-0 text-lightGray" onClick={() => setState(prevState => ({ ...prevState, allow_all: !state.allow_all }))}>Allow All</span>
                                </div>
                            </div>

                            {/* <p className='mb-0'>
                                {state.hasError && !state.allow_all && (!state.filters || !state.filters.length) ?
                                    <span className='mb-0 text-red ml-2'>required</span>
                                : null}
                            </p> */}
                        </div>
                        
                        {!state.allow_all ?
                            <React.Fragment>
                            <div className='flex mt-2 pt-2 border-darkGray-top'>
                                <div className='py-1 lg:2-1/2 md:w-1/2 w-full'>
                                    <div className={``} onClick={(event) => {
                                        event.preventDefault();
                                        handleChildClick(event, 'child', 'muliselectDropDown', "showGroup")
                                    }}>
                                        <p className={`flex bg-transparent border border-lightGray rounded-md px-2 py-1 justify-between mb-0 truncate ${state.groupId && state.groupId.length ? 'text-white' : 'text-lightGray'}`}>
                                            {state.groupId && state.groupId.length ? state.groupId.length + ' Selected' : 'Select'}
                                            <Icon icon="icon-park-solid:down-one" className={`${state.groupId && state.groupId.length ? "text-white" : "text-lightGray"} self-center`} width="16" height="16" />
                                        </p>
                                        {state.showGroup ?
                                            <div className='relative'>
                                                <MultiSelectSection 
                                                    fields={["group_id", "group_name"]}
                                                    options={state.groups}
                                                    selectedValues={state.groupId ? state.groupId : ''}
                                                    callbackMultiSelect={(value) => {
                                                        setState(prevState => ({ ...prevState, groupId: value }))
                                                    }}
                                                    singleSelection={false}
                                                    widthClass={'minWidth220'}
                                                    removeTopOptions={true}
                                                />
                                            </div>
                                        : null}
                                    </div>
                                </div>
                            </div>
                            {state.groupId && state.groupId.length && state.groups ?
                                state.groups.map((grp, grpIndex) => {
                                    return(
                                        state.groupId.includes(grp.group_id) ?
                                            <div key={'grp_'+grpIndex} className='p-3 mb-3 bg-dark3 rounded-md'>
                                                <div className='flex mt-2'>
                                                    <div className="py-1 lg:w-1/2 md:w-1/2 w-full">
                                                        <p className="b-block mb-0">Name</p>
                                                        <p className="mb-0 text-white">
                                                            {grp.group_name ? grp.group_name : <span>&nbsp;</span>}
                                                        </p>
                                                    </div>
                                                </div>
                                                {grp.associations && grp.associations.map((gAss, gAssIndex) => {
                                                    return(
                                                        <div key={'gAss_'+gAssIndex} className='bg-dark2 p-3 mt-3 rounded-md'>
                                                            {Object.entries(gAss).map(([key, value], objIndex) => {
                                                                return(
                                                                    key !== 'project_tags' ?
                                                                        <div key={'pbj_'+objIndex} className={`${objIndex ? 'mt-2' : ''}`}>
                                                                            {key === 'tags' ?
                                                                                value && value.length ?
                                                                                    <div className='flex mt-2 pt-2'>
                                                                                        <div className="py-1 w-full">
                                                                                            <p className="b-block mb-0 text-lg">{removeUnderScore(key)}</p>
                                                                                            <p className="mb-0 text-white">
                                                                                                {value.map((tag, tagIndex) => {
                                                                                                    return(
                                                                                                        <span key={'tag_'+tagIndex} className="border border-gray3 w-fit rounded-full mr-2 mt-2 self-center f14 text-info px-2">{tag.key+' : '+tag.value}</span>
                                                                                                    )
                                                                                                })}
                                                                                            </p>
                                                                                        </div>
                                                                                    </div>
                                                                                : null
                                                                            : Array.isArray(value) ?
                                                                                value.every(item => typeof item === 'string') ?
                                                                                    <React.Fragment>
                                                                                    <p className="b-block mb-1 text-lg">{removeUnderScore(key)}</p>
                                                                                    {value.map((arr, arrIndex) => {
                                                                                        return(
                                                                                            <span key={'arr_'+arrIndex} className="border border-gray3 w-fit rounded-full mr-2 mt-2 self-center f14 text-white px-2">{arr}</span>
                                                                                        )
                                                                                    })}
                                                                                    </React.Fragment>
                                                                                : value && value.length ?
                                                                                    <div className='flex mt-2 pt-2'>
                                                                                        <div className="py-1 w-100">
                                                                                            <p className="b-block mb-0 text-lg">{removeUnderScore(key)}</p>
                                                                                            <p className="mb-0 text-white">
                                                                                                {value.map((tag, tagIndex) => {
                                                                                                    return(
                                                                                                        <span key={'tag_'+tagIndex} className="border border-gray3 w-fit rounded-full mr-2 mt-2 self-center f14 text-info px-2">{tag.key+' : '+tag.value}</span>
                                                                                                    )
                                                                                                })}
                                                                                            </p>
                                                                                        </div>
                                                                                    </div>
                                                                                : null
                                                                            : typeof value === 'string' ?
                                                                                <p className="border border-gray3 w-fit rounded-full mr-2 mt-2 mb-0 self-center f14 text-white px-2"><span className='text-lightGray mr-1'>{removeUnderScore(key)} :</span>{value}</p>
                                                                            : typeof value === 'boolean' ?
                                                                                <p className="border border-gray3 w-fit rounded-full mr-2 mt-2 mb-0 self-center f14 text-white px-2"><span className='text-lightGray mr-1'>{removeUnderScore(key)} :</span>{value ? 'True' : 'False'}</p>
                                                                            : null}
                                                                        </div>
                                                                    : null

                                                                )
                                                            })}
                                                        </div>
                                                    )
                                                })}
                                            </div>
                                        : null
                                    )
                                })
                            : null}
                            
                            </React.Fragment>
                        : null}
                    </div>

                    <div className='bg-black rounded-md p-3 pb-5 mt-3'>
                        <div className='flex justify-between'>
                            <div className='flex'>
                                <h5 className='mb-0 text-plum cursor-pointer text-lg'>HPC Cluster Group Associations</h5>
                            </div>
                        </div>
                        
                        {state.groupArray.map((slmIndex, sIndex) => {
                            return(
                                <React.Fragment key={'prd_'+slmIndex}>
                                <div className={`flex flex-wrap ${slmIndex ? "mt-1 pt-1 border-mediumDarkGray-top":  ""}`}>
                                    <div className="py-1 lg:w-1/4 md:w-1/2 w-full">
                                        <div className='flex justify-between mr-2'>
                                            <p className="b-block mb-0">Account</p>
                                            {state.hasError && !state["grpAccount_"+slmIndex] ?
                                                <span className='text-danger self-center'>required</span>
                                            : null}
                                        </div>
                                        <div onClick={(event) => {
                                            event.preventDefault();
                                            handleChildClick(event, 'child', 'singleDropDown', "groupShowAccount_"+slmIndex)
                                        }}>
                                        <p className={`flex bg-transparent border border-lightGray rounded-md px-2 py-1 justify-between mb-0 truncate ${state["grpAccount_"+slmIndex] ? 'text-white' : 'text-lightGray'}`}>
                                            {state["grpAccount_"+slmIndex] ? getAccountNameFromId(state["grpAccount_"+slmIndex], state.providerAccounts) : 'Select'}
                                            <Icon icon="icon-park-solid:down-one" className={`${state["grpAccount_"+slmIndex] ? "text-white" : "text-lightGray"} self-center`} width="16" height="16" />
                                        </p>
                                        {state["groupShowAccount_"+slmIndex] && pageMode !== 'View' ?
                                            <div className='relative'>
                                                <MultiSelectSection 
                                                    fields={["account_id", "account_name"]}
                                                    options={state.providerAccounts}
                                                    selectedValues={state["grpAccount_"+slmIndex] ? state["grpAccount_"+slmIndex] : []}
                                                    callbackMultiSelect={(value) => {
                                                        setState(prevState => ({ ...prevState, ["grpAccount_"+slmIndex]: value }))
                                                    }}
                                                    singleSelection={true}
                                                    widthClass={'minWidth220'}
                                                    removeTopOptions={true}
                                                />
                                            </div>
                                        : null}
                                        </div>
                                    </div>
                                    <div className="py-1 lg:w-1/4 md:w-1/2 w-full md:pl-3">
                                        <div className='flex justify-between mr-2'>
                                            <p className="b-block mb-0">Region</p>
                                            {state.hasError && !state["grpRegion_"+slmIndex] ?
                                                <span className='text-danger self-center'>required</span>
                                            : null}
                                        </div>
                                        <div onClick={(event) => {
                                            event.preventDefault();
                                            handleChildClick(event, 'child', 'singleDropDown', "groupShowRegion_"+slmIndex)
                                        }}>
                                        <p className={`flex bg-transparent border border-lightGray rounded-md px-2 py-1 justify-between mb-0 truncate ${state["grpRegion_"+slmIndex] ? 'text-white' : 'text-lightGray'}`}>
                                            {state["grpRegion_"+slmIndex] ? getRegionName(state["grpRegion_"+slmIndex], state.providerRegions) : 'Select'}
                                            <Icon icon="icon-park-solid:down-one" className={`${state["grpRegion_"+slmIndex] ? "text-white" : "text-lightGray"} self-center`} width="16" height="16" />
                                        </p>
                                        {state["groupShowRegion_"+slmIndex] && pageMode !== 'View' ?
                                            <div className='relative'>
                                                <MultiSelectSection 
                                                    fields={["region", "name"]}
                                                    options={state.providerRegions}
                                                    selectedValues={state["grpRegion_"+slmIndex] ? state["grpRegion_"+slmIndex] : []}
                                                    callbackMultiSelect={(value) => {
                                                        setState(prevState => ({ ...prevState, 
                                                            ["grpRegion_"+slmIndex]: value,
                                                            fetchingCluster: true
                                                        }))
                                                        getSlurmClusterDetailsFunction('aws', state["grpAccount_"+slmIndex], value, slmIndex)
                                                    }}
                                                    singleSelection={true}
                                                    widthClass={'minWidth220'}
                                                    removeTopOptions={true}
                                                    className={'lg:right-auto md:right-0'}
                                                />
                                            </div>
                                        : null}
                                        </div>
                                    </div>
                                    
                                    <div className="py-1 lg:w-1/4 md:w-1/2 w-full lg:pl-3">
                                        <div className='flex justify-between mr-2'>
                                            <p className="b-block mb-0">Cluster</p>
                                            {state.hasError && !state["grpCluster_"+slmIndex] ?
                                                <span className='text-danger self-center'>required</span>
                                            : null}
                                        </div>
                                        <div onClick={(event) => {
                                            event.preventDefault();
                                            handleChildClick(event, 'child', 'singleDropDown', "grpShowCluster_"+slmIndex)
                                        }}>
                                        <p className={`flex bg-transparent border border-lightGray rounded-md px-2 py-1 justify-between mb-0 truncate ${state["grpCluster_"+slmIndex] ? 'text-white' : 'text-lightGray'}`}>
                                            {state["grpCluster_"+slmIndex] ? state["grpCluster_"+slmIndex] : 'Select'}
                                            <Icon icon="icon-park-solid:down-one" className={`${state["grpCluster_"+slmIndex] ? "text-white" : "text-lightGray"} self-center`} width="16" height="16" />
                                        </p>
                                        {state["grpShowCluster_"+slmIndex] && pageMode !== 'View' && !state.fetchingCluster ?
                                            <div className='relative'>
                                                <MultiSelectSection 
                                                    options={state['groupClustersOptions_'+slmIndex]}
                                                    selectedValues={state["grpCluster_"+slmIndex] ? state["grpCluster_"+slmIndex] : []}
                                                    callbackMultiSelect={(value) => {
                                                        setState(prevState => ({ ...prevState, 
                                                            ["grpCluster_"+slmIndex]: value,
                                                            fetchingSlurmAccount: true
                                                        }))
                                                        getSlurmAccountDetails('aws', state["grpAccount_"+slmIndex], state["grpRegion_"+slmIndex], value, slmIndex)
                                                    }}
                                                    singleSelection={true}
                                                    widthClass={'minWidth220'}
                                                    removeTopOptions={true}
                                                />
                                            </div>
                                        : null}
                                        </div>
                                    </div>
                                    <div className="py-1 lg:w-1/4 md:w-1/2 w-full md:pl-3 mb-3">
                                        <p className="b-block mb-0">Group</p>
                                        <div onClick={(event) => {
                                            event.preventDefault();
                                            handleChildClick(event, 'child', 'muliselectDropDown', "showGroup_"+slmIndex)
                                        }}>
                                            <p className={`flex bg-transparent border border-lightGray rounded-md px-2 py-1 justify-between mb-0 truncate ${state["selectedGroup_"+slmIndex] && state["selectedGroup_"+slmIndex].length ? 'text-white' : 'text-lightGray'}`}>
                                                {state["selectedGroup_"+slmIndex] && state["selectedGroup_"+slmIndex].length ? state["selectedGroup_"+slmIndex].length+' Selected' : 'Select'}
                                                <Icon icon="icon-park-solid:down-one" className={`${state["selectedGroup_"+slmIndex] ? "text-white" : "text-lightGray"} self-center`} width="16" height="16" />
                                            </p>
                                            
                                            {state["showGroup_"+slmIndex] && pageMode !== 'View' && !state.fetchingSlurmAccount ?
                                                <div className='relative'>
                                                    <MultiSelectSection 
                                                        fields={["account", "account"]}
                                                        options={state["groupList_"+slmIndex]}
                                                        selectedValues={state["selectedGroup_"+slmIndex] ? state["selectedGroup_"+slmIndex] : []}
                                                        callbackMultiSelect={(value) => {
                                                            setState(prevState => ({ ...prevState, ["selectedGroup_"+slmIndex]: value }))
                                                        }}
                                                        singleSelection={false}
                                                        widthClass={'minWidth220'}
                                                        removeTopOptions={true}
                                                        className={'lg:right-auto md:right-0'}
                                                    />
                                                </div>
                                            : null}
                                        </div>
                                    </div>
                                </div>
                                {state["selectedGroup_"+slmIndex] && state["selectedGroup_"+slmIndex].length ?
                                    <div className='flex flex-wrap mb-4'>
                                        {state["selectedGroup_"+slmIndex].filter(e => e.value !== "All").map((reg, regIndex) => {
                                            return(
                                                <span key={'slurmReg_'+regIndex} className="flex border border-lightGray px-2 py-1 rounded-full mr-2 mt-2 self-center text-info">{reg}
                                                    {!state.editMode ?
                                                        <Icon icon="ion:close-circle-sharp" width="16" height="16"  className='text-white ml-2 self-center' onClick={ () => removeSlurmAccount(reg, slmIndex) } />
                                                    : null}
                                                </span>
                                            )
                                        })}
                                    </div>
                                : null}
                                <div className='flex mt-1 mb-3'>
                                    {pageMode !== 'View' ?
                                        <React.Fragment>
                                        {state.groupArray.length === sIndex+1 ?
                                            <span className={`self-center mr-3 cursor-pointer text-info`} onClick={() => addFilterArray('groupArray')} >
                                                {/* <i className={`far fa-plus f16 mt-2 mb-1 self-end mr-2`}></i> */} 
                                                Add
                                            </span>
                                        : null}
                                        {/* {state.groupArray.length > 1 ? */}
                                            <span className={`self-center mr-2 cursor-pointer text-danger`} onClick={() => removeFilterArray('groupArray', sIndex, slmIndex)} >Remove
                                            </span>
                                        {/* : null} */}
                                        </React.Fragment>
                                    : null}
                                </div>
                                </React.Fragment>
                            )
                        })}
                    </div>

                    <div className='flex bottom-4 right-4 justify-end bg-transparent my-2'>
                        <div className={`flex justify-end w-full`}>
                            {state.saveLoading ?
                                <button className={`btn bg-info text-md px-2 py-1 rounded-md mr-2`}>
                                    <svg className="animate-spin h-5 w-5 mr-2 text-white" fill="currentColor" viewBox="0 0 24 24">
                                        <circle cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="2" fill="none" />
                                        <path d="M4 12a8 8 0 0112-6.9" />
                                    </svg>
                                </button>
                            :  pageMode !== 'View' ? 
                                <button className={`btn bg-info text-md px-2 py-1 rounded-md mr-2 text-white`} onClick={ () => setState(prevState => ({ ...prevState, saveLoading: true, callSaveFunction: true }))}>
                                    {pageMode === 'Edit' ? 'Update' : 'Save'}
                                </button>
                            : null}
                            <button className={`btn bg-lightGray px-2 py-1 text-md rounded-md text-white`} onClick={ () => closeSidePanel()}>Cancel</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CreateUpdateUserPanel