import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux'; // Import useDispatch hook to dispatch actions
import { setHpcPropsDetails } from '../../../../actions/Collider/HpcAction';
import MultiSelectSection from '../../../common/MultiSelectSection';
import {Icon} from "@iconify/react";

const AuthenticationSection = (props) => {
    const [state, setState] = useState({});
    const createUpdateCluster = useSelector(
      (state) => state?.hpc?.hpcPropsDetails?.createUpdateCluster || false
    );
    const propsClusterFilters = useSelector(
      (state) => state?.hpc?.hpcPropsDetails?.propsClusterFilters || false
    );
  
    const dispatch = useDispatch(); // Create a dispatch function

    const addNewkeyValue = () => {
      let selectedKeys = state.selected_keys ? state.selected_keys : {};
  
      if (state.key && state.key !== "" && state.value && state.value.length) {
        selectedKeys[state.key] = state.value;
      }
  
      setState((prevState) => ({
        ...prevState,
        selected_keys: selectedKeys,
        key: "",
        value: "",
      }));
  
      setTimeout(() => {
        handleDirectoryInput("", selectedKeys, "additional_sssd_configs");
      }, 500);
    };
  
    const removeKeyValue = (key) => {
      let filteredResult =
        state.selected_keys && state.selected_keys.filter((e) => e !== key);
      setState((prevState) => ({ ...prevState, selected_keys: filteredResult }));
    };
  
    const handleDirectoryInput = (label, value, field) => {
      let obj = createUpdateCluster ? createUpdateCluster : {};
      let directoryConfiguration = obj.directory_configuration
        ? obj.directory_configuration
        : {};
      if (field === "additional_sssd_configs") {
        // let additionalSSdConfigs = directoryConfiguration.additional_sssd_configs ? directoryConfiguration.additional_sssd_configs : []
        // additionalSSdConfigs[label] = value
        directoryConfiguration["additional_sssd_configs"] = value;
      } else {
        // if(label !== 'password_secret_arn') {
        directoryConfiguration[label] = value;
        // }
      }
  
      obj["directory_configuration"] = directoryConfiguration;
      dispatch(setHpcPropsDetails("createUpdateCluster", obj));
  
      setState((prevState) => ({ ...prevState, [label]: value }));
    };
  
    const handleChildClick = (event, type, dropdownType, section) => {
      event.stopPropagation();
  
      let clickedChild = [];
      if (type === "child") {
        if (state.clickedChild && state.clickedChild.length) {
          state.clickedChild.forEach((row) => {
            setState((prevState) => ({ ...prevState, [row]: false }));
          });
        }
        clickedChild.push(section);
        setState((prevState) => ({
          ...prevState,
          [section]:
            dropdownType === "singleDropDown" && state[section] ? false : true,
          clickedChild,
        }));
      } else if (type === "parent") {
        if (state.clickedChild && state.clickedChild.length) {
          state.clickedChild.forEach((row) => {
            setState((prevState) => ({ ...prevState, [row]: false }));
          });
        }
  
        setState((prevState) => ({ ...prevState, clickedChild }));
      }
    };

    return (
        <div className='rounded bg-dark p-3' onClick={(event) => handleChildClick(event, 'parent')}>
            <div className={'flex'}>
                <div className='py-1 w-full'>
                    <div className='lg:w-1/3 md:w-1/2 w-full'>
                        <p className='b-block mb-0'>Domain name</p>
                        <input
                            type='text'
                            placeholder='Enter name'
                            className={`border border-lightGray rounded-md bg-transparent px-2 py-1 text-white w-full`}
                            // maxlength='128'
                            value={createUpdateCluster && createUpdateCluster.directory_configuration && createUpdateCluster.directory_configuration.domain_name ? createUpdateCluster.directory_configuration.domain_name : ''}
                            onChange={e => 
                                handleDirectoryInput('domain_name', e.target.value)
                            }
                        />
                    </div>
                    {propsClusterFilters && propsClusterFilters.description && propsClusterFilters.description.directory_configuration && propsClusterFilters.description.directory_configuration.domain_name ?
                        <p className='mt-1 mb-0 text-info'>{propsClusterFilters.description.directory_configuration.domain_name}</p>
                    : null}
                </div>
            </div>
            <div className={'flex mt-3'}>
                <div className='py-1 w-full'>
                    <div className='lg:w-1/3 md:w-1/2 w-full'>
                        <p className='b-block mb-0'>Domain address</p>
                        <input
                            type='text'
                            placeholder='Enter address'
                            className={`border border-lightGray rounded-md bg-transparent px-2 py-1 text-white w-full`}
                            // maxlength='128'
                            value={createUpdateCluster && createUpdateCluster.directory_configuration && createUpdateCluster.directory_configuration.domain_address ? createUpdateCluster.directory_configuration.domain_address : ''}
                            onChange={e => 
                                handleDirectoryInput('domain_address', e.target.value)
                            }
                        />
                    </div>
                    {propsClusterFilters && propsClusterFilters.description && propsClusterFilters.description.directory_configuration && propsClusterFilters.description.directory_configuration.domain_address ?
                        <p className='mt-1 mb-0 text-info'>{propsClusterFilters.description.directory_configuration.domain_address}</p>
                    : null}
                </div>
            </div>
            <div className={'flex mt-3'}>
                <div className='py-1 w-full'>
                    <div className='lg:w-1/3 md:w-1/2 w-full'>
                        <p className='b-block mb-0'>Password secret amazon resource name (ARN)</p>
                        <input
                            type='text'
                            placeholder='Enter secret arn'
                            className={`border border-lightGray rounded-md bg-transparent px-2 py-1 text-white w-full`}
                            // maxlength='128'
                            value={createUpdateCluster && createUpdateCluster.directory_configuration && createUpdateCluster.directory_configuration.password_secret_arn ? createUpdateCluster.directory_configuration.password_secret_arn : ''}
                            onChange={e => 
                                handleDirectoryInput('password_secret_arn', e.target.value)
                            }
                        />
                    </div>
                    {propsClusterFilters && propsClusterFilters.description && propsClusterFilters.description.directory_configuration && propsClusterFilters.description.directory_configuration.password_secret_arn ?
                        <p className='mt-1 mb-0 text-info'>{propsClusterFilters.description.directory_configuration.password_secret_arn}</p>
                    : null}
                </div>
            </div>
            <div className={'flex mt-3'}>
                <div className='py-1 w-full'>
                    <div className='lg:w-1/3 md:w-1/2 w-full'>
                        <p className='b-block mb-0'>Domain read only user</p>
                        <input
                            type='text'
                            placeholder='Enter user'
                            className={`border border-lightGray rounded-md bg-transparent px-2 py-1 text-white w-full`}
                            // maxlength='128'
                            value={createUpdateCluster && createUpdateCluster.directory_configuration && createUpdateCluster.directory_configuration.domain_read_only_user ? createUpdateCluster.directory_configuration.domain_read_only_user : ''}
                            onChange={e => 
                                handleDirectoryInput('domain_read_only_user', e.target.value)
                            }
                        />
                    </div>
                    {propsClusterFilters && propsClusterFilters.description && propsClusterFilters.description.directory_configuration && propsClusterFilters.description.directory_configuration.domain_read_only_user ?
                        <p className='mt-1 mb-0 text-info'>{propsClusterFilters.description.directory_configuration.domain_read_only_user}</p>
                    : null}
                </div>
            </div>
            <div className={'flex mt-3'}>
                <div className='py-3 w-full'>
                    <p className='b-block mb-0'>Additional SSSD options</p>
                    {propsClusterFilters && propsClusterFilters.description && propsClusterFilters.description.directory_configuration && propsClusterFilters.description.directory_configuration.additional_sssd_configs ?
                        <p className='mb-1 text-info'>{propsClusterFilters.description.directory_configuration.additional_sssd_configs}</p>
                    : null}
                    <div className='flex flex-wrap mb-1'>
                        <input
                            type='text'
                            placeholder='Key'
                            className={`border border-lightGray rounded-md bg-transparent px-2 py-1 text-white lg:w-1/3 md:w-1/2 w-full`}
                            // maxlength='128'
                            value={state.key ? state.key : ''}
                            onChange={e => 
                                setState(prevState => ({ ...prevState, key: e.target.value 
                            }))}
                        />
                        <input
                            type='text'
                            placeholder='value'
                            className={`border border-lightGray rounded-md bg-transparent px-2 py-1 text-white lg:w-1/3 md:w-1/2 w-full lg:ml-3 md:ml-3 ml-0 lg:mt-0 md:mt-0 mt-2 `}
                            // maxlength='128'
                            value={state.value ? state.value : ''}
                            onChange={e => 
                                setState(prevState => ({ ...prevState, value: e.target.value
                            }))}
                            // onChange={e => handleDirectoryInput('value', e.target.value, 'additional_sssd_configs')}
                        />
                        <Icon icon="iconoir:plus" width="25" height="25"  className='text-white cursor-pointer ml-3 lg:mt-0 md:mt-0 mt-2' onClick={() => addNewkeyValue()}/>
                    </div>

                    {createUpdateCluster && createUpdateCluster.directory_configuration && createUpdateCluster.directory_configuration.additional_sssd_configs && Object.entries(createUpdateCluster.directory_configuration.additional_sssd_configs).length ? 
                        <div className='flex flex-wrap'>
                            {Object.entries(createUpdateCluster.directory_configuration.additional_sssd_configs).map(([key, value], tIndex) => {
                                return(
                                    <span className='flex border border-lightGray rounded-full mr-2 mt-2 self-center text-info pl-2 pr-1 breakWhiteSpace' id={'row_'+tIndex}>
                                        <div className='truncate'  style={{maxWidth: '250px'}}>
                                            {key +' : '+value}
                                        </div>
                                        {props.pageMode === 'Create' ?
                                            <Icon icon="ion:close-circle-sharp" width="16" height="16"  className='text-white ml-2 self-center' onClick={() => removeKeyValue(key, value) }/>
                                        : null}
                                    </span>
                                )
                            })}
                        </div>
                    : null}
                </div>
            </div>
            <div className={'flex mt-3'}>
                <div className='py-1 w-full'>
                    <div className='lg:w-1/3 md:w-1/2 w-full'>
                        <div className='flex justify-between'>
                            <label className='self-center m-0 text-lightGray text-nowrap'>Request certificate</label>
                            {state.hasError && state.ldap_tls_request_certificate ? 
                                <small className='text-issues'>required</small>
                            : null}
                        </div>
                        <div className='border-lightGray bg-transparent rounded-5'
                            onClick={(event) => {
                                if(!state.showRequestCertificate) {
                                    event.preventDefault();
                                    handleChildClick(event, 'child', 'singleDropDown', "showRequestCertificate")
                                }
                            }}
                        >
                            <p className={`flex border border-lightGray rounded-md bg-transparent px-2 py-1 justify-between mb-2 ${createUpdateCluster && createUpdateCluster.directory_configuration && createUpdateCluster.directory_configuration.ldap_tls_request_certificate ? 'text-white truncate' : 'placeholder'}`}>
                                {createUpdateCluster && createUpdateCluster.directory_configuration && createUpdateCluster.directory_configuration.ldap_tls_request_certificate ? createUpdateCluster.directory_configuration.ldap_tls_request_certificate : 'Select'}
                                <Icon icon="icon-park-solid:down-one" className={`${createUpdateCluster && createUpdateCluster.directory_configuration && createUpdateCluster.directory_configuration.ldap_tls_request_certificate ? "text-white" : "text-lightGray"} self-center`} width="16" height="16" />
                            </p>
                            {state.showRequestCertificate ?
                                <MultiSelectSection
                                    // fields={["value", "key"]}
                                    options={propsClusterFilters && propsClusterFilters.ldap_tls_request_certificate ? propsClusterFilters.ldap_tls_request_certificate : []}
                                    selectedValues={createUpdateCluster && createUpdateCluster.directory_configuration && createUpdateCluster.directory_configuration.ldap_tls_request_certificate ? createUpdateCluster.directory_configuration.ldap_tls_request_certificate : ''}
                                    callbackMultiSelect={(value) => {
                                        if(!value || typeof(value) === 'string') {
                                            setState(prevState => ({ ...prevState, ldap_tls_request_certificate: value }))
                                            handleDirectoryInput('ldap_tls_request_certificate', value)
                                        } else {
                                            value.preventDefault()
                                            handleChildClick(value, "search", 'singleDropDown', "")
                                        }
                                    }}
                                    singleSelection={true}
                                    removeTopOptions={true}
                                    hideSearch={false}
                                    topClass={'auto'}
                                    widthClass={'minWidth220'}
                                />
                            : null}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}


export default AuthenticationSection