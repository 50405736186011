/*************************************************
 * Collider
 * @exports
 * @file QueuesFieldDetail.js
 * @author Prakash // on 29/11/2023
 * @copyright © 2023 Collider. All rights reserved.
 *************************************************/
import React from 'react';
import { momentConvertionUtcToLocalTime, capitalizeTheFirstLetterOfEachWord, removeUnderScore, isValidDateTime, convertToLowerCase } from '../../../utils/utility';

export const QueuesFieldDetails = ({ detailsFormat, selectedDetails }) => {
    return (
        <div className='m-2 grow overflow-auto'>
            <div className="rounded bg-dark3 p-3">
                {detailsFormat && detailsFormat.length && detailsFormat.map((row, index) => {
                    return(
                        <div key={'data_'+index} className={`${!index ? '' : 'mt-2'}`}>
                            <p className={`mb-1 text-cyon text-xl font-bold pb-1 border-b border-lightGray`}>{capitalizeTheFirstLetterOfEachWord(row.label)}</p>
                            <small className='text-lightGray'>{row.description}</small>
                            {row.fields && row.fields.map((fld, fldIndex) => {
                                return(
                                    <React.Fragment key={'tIndex_'+fldIndex}>
                                    {fld === "queue_configuration" ?
                                        <div className={`rounded bg-dark2 p-2 mb-2 mt-2`}>
                                            <div className="flex mt-3">
                                                <span className="minWidth220 mr-3 text-lightGray self-center">Subnet IDs</span>
                                                <div className="flex flex-wrap">
                                                    {selectedDetails[fld] && selectedDetails[fld].subnet_ids ?
                                                        selectedDetails[fld].subnet_ids.map((ids, idsIndex) => {
                                                            return(
                                                                <span key={'idsIndex_'+idsIndex} className='border border-info rounded-full mb-2 px-2 bg-info text-white mr-2 py-1 break-all'>{ids}</span>
                                                            )
                                                        })
                                                    : null}
                                                </div>
                                            </div>
                                            <div className="flex mt-3">
                                                <span className="minWidth220 mr-3 text-lightGray self-center">Security Group IDs</span>
                                                <div className="flex flex-wrap">
                                                    {selectedDetails[fld] && selectedDetails[fld].security_group_ids ?
                                                        selectedDetails[fld].security_group_ids.map((ids, gIndex) => {
                                                            return(
                                                                <span key={'gIndex_'+gIndex} className='border border-info rounded-full mb-2 px-2 bg-info text-white mr-2 py-1 break-all'>{ids}</span>
                                                            )
                                                        })
                                                    : null}
                                                </div>
                                            </div>
                                            <div className="flex mt-3">
                                                <span className="minWidth220 mr-3 text-lightGray self-center">Policies</span>
                                                <div className="flex flex-wrap">
                                                    {selectedDetails[fld] && selectedDetails[fld].policies ?
                                                        selectedDetails[fld].policies.map((pol, polIndex) => {
                                                            return(
                                                                <span key={'pol_'+polIndex} className='border border-info rounded-full mb-2 px-2 bg-info text-white mr-2 py-1 break-all'>{pol}</span>
                                                            )
                                                        })
                                                    : null}
                                                </div>
                                            </div>
                                            <div className="flex mt-3">
                                                <span className="minWidth220 mr-3 text-lightGray self-center">Script Location</span>
                                                <span className={`self-center text-white break-all`}>{selectedDetails[fld] && selectedDetails[fld].script_location ? selectedDetails[fld].script_location : ""}</span>
                                            </div>

                                            <p className={`mb-1 mt-2 text-purple f18 pb-1 border-bottom`}>Volume Details</p>
                                            <div className="flex mt-3">
                                                <span className="minWidth220 mr-3 text-lightGray self-center">Type</span>
                                                <span className={`self-center text-white`}>{selectedDetails[fld] && selectedDetails[fld].volume_type ? selectedDetails[fld].volume_type : ""}</span>
                                            </div>
                                            <div className="flex mt-3">
                                                <span className="minWidth220 mr-3 text-lightGray self-center">Size</span>
                                                <span className={`self-center text-white`}>{selectedDetails[fld] && selectedDetails[fld].volume_size && selectedDetails[fld].volume_size}</span>
                                            </div>
                                            <div className="flex mt-3">
                                                <span className="minWidth220 mr-3 text-lightGray self-center">Encryption</span>
                                                <span className={`self-center text-white`}>{selectedDetails[fld] && selectedDetails[fld].volume_encryption ? "True" : "False"}</span>
                                            </div>

                                            <p className={`mb-1 mt-2 text-purple f18 pb-1 border-bottom`}>S3 Access</p>
                                            <div className="flex mt-3">
                                                <span className="minWidth220 mr-3 text-lightGray self-center">Bucket</span>
                                                <span className={`self-center text-white`}>{selectedDetails[fld] && selectedDetails[fld].bucket_name ? selectedDetails[fld].bucket_name : ""}</span>
                                            </div>
                                            <div className="flex mt-3">
                                                <span className="minWidth220 mr-3 text-lightGray self-center">Write Access</span>
                                                <span className={`self-center text-white`}>{selectedDetails[fld] && selectedDetails[fld].enable_s3_write_access ? "True" : "False"}</span>
                                            </div>

                                            <p className={`mb-1 mt-2 text-purple f18 pb-1 border-bottom`}>Compute Resources</p>
                                            {selectedDetails[fld] && selectedDetails[fld].compute_resources ?
                                                selectedDetails[fld].compute_resources.map((comp, cIndex) => {
                                                    return (
                                                        <div key={'comp_'+cIndex} className={`bg-dark p-2 rounded mb-2 ${!cIndex ? "mt-2" : ""}`}>
                                                            <div className="flex mt-3">
                                                                <span className="minWidth220 mr-3 text-lightGray self-center">Name</span>
                                                                <span className={`self-center text-white`}>{comp && comp.compute_resource_name ? comp.compute_resource_name : ""}</span>
                                                            </div>
                                                            <div className="flex mt-3">
                                                                <span className="minWidth220 mr-3 text-lightGray self-center">Instance Type</span>
                                                                <span className={`self-center text-white`}>{comp && comp.instance_type && comp.instance_type.length ? comp.instance_type[0] : ""}</span>
                                                            </div>
                                                            <div className="flex mt-3">
                                                                <span className="minWidth220 mr-3 text-lightGray self-center">Min Instance Count</span>
                                                                <span className={`self-center text-white`}>{comp && comp.min_instance_count && comp.min_instance_count}</span>
                                                            </div>
                                                            <div className="flex mt-3">
                                                                <span className="minWidth220 mr-3 text-lightGray self-center">Max Instance Count</span>
                                                                <span className={`self-center text-white`}>{comp && comp.max_instance_count && comp.max_instance_count}</span>
                                                            </div>
                                                            <div className="flex mt-3">
                                                                <span className="minWidth220 mr-3 text-lightGray self-center">Efa Enabled</span>
                                                                <span className={`self-center text-white`}>{comp && comp.ena_enabled ? "True" : "False"}</span>
                                                            </div>
                                                            <div className="flex mt-3">
                                                                <span className="minWidth220 mr-3 text-lightGray self-center">Placement Group Enabled</span>
                                                                <span className={`self-center text-white`}>{comp && comp.placement_group_enabled ? "True" : "False"}</span>
                                                            </div>
                                                            <div className="flex mt-3">
                                                                <span className="minWidth220 mr-3 text-lightGray self-center">Disable Simultaneous Multithreading</span>
                                                                <span className={`self-center text-white`}>{comp && comp.disable_simultaneous_multithreading ? "True" : "False"}</span>
                                                            </div>
                                                        </div>
                                                    )
                                                })
                                            : null}
                                        </div>
                                    :
                                        <div className="flex mt-3">
                                            <span className="minWidth220 mr-3 text-lightGray self-center">{removeUnderScore(fld)}</span>
                                            {selectedDetails && selectedDetails[fld] && fld !== "queue_configuration" ?
                                                <React.Fragment>
                                                {typeof selectedDetails[fld] === 'boolean' ?
                                                    <span className={`text-white`}>{selectedDetails[fld] ? 'True' : 'False'}</span>
                                                : isValidDateTime(selectedDetails[fld]) ?
                                                    <span className="text-white">{momentConvertionUtcToLocalTime(selectedDetails[fld], "DD MMM YYYY HH:mm")}<span className='ml-2 text-info'>(local time)</span></span>
                                                : convertToLowerCase(fld) === "status" ?
                                                    <span className={`self-center border border-mulbery rounded-full px-2 bg-mulbery text-white`}>{selectedDetails[fld]}</span>
                                                : convertToLowerCase(fld) === "tags" ?
                                                    <div className="flex flex-wrap">
                                                        {selectedDetails[fld].map((item, itIndex) => {
                                                            return(
                                                                <span key={'itIndex_'+itIndex} className='border border-info rounded-full mb-2 px-2 bg-info text-white mr-2 py-1 break-all'>{item.key +' : '+item.value}</span>
                                                            )
                                                        })}
                                                    </div>
                                                : 
                                                    <span className="text-white">{fld === 'provider' ? selectedDetails[fld].toUpperCase() : selectedDetails[fld]}</span>
                                                }
                                                </React.Fragment>
                                            : null}
                                        </div>
                                    }
                                    </React.Fragment>
                                )
                            })}
                        </div>
                    )
                })}
            </div>
        </div>
    )
}