import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ReactTable from "../../../designComponents/Table/FileManagerTable";
import { getStats, getUserFiles } from '../../../../actions/files/FileAction';
import FileActions from '../file-actions';
import Sidebar from '../side-bar';
import ErrorToast from '../../../common/Toast/ErrorToast';
import { setCommonPropsDetails } from '../../../../actions/commonActionNew';
import _ from 'lodash'
import { removeUnderScore, splitString } from '../../../../utils/utility';
import Search from '../../../common/SearchWithHiddenInput';
import {Icon} from "@iconify/react";

const createParams = (state, mount_path='') => {
  let params = {}
  if(mount_path) {
    params.path = mount_path
  }

  if (state) {
    params.provider = state.selectedProvider ? state.selectedProvider.toLowerCase() :''
    params.account_id = state.selectedAccount
    params.cluster_name = state.selectedClusters
    params.region = state.selectedRegion
  }
  return params
}

export default function FileList(props) {
  const filterRef = useRef();
  const dispatch = useDispatch();
  
  const [state, setState] = useState({
    showLoading: true,
    selectedProvider: '',
    selectedAccount: [],
    isAccountOpen: false,
    selectedRegion: [],
    isRegionOpen: false,
  });
  const [clickCount, setClickCount] = useState(0)


  const fileManageFilterChange = useSelector(state => state?.filters?.commonPropsDetails?.fileManageFilterChange || false);
  const currentLocation = useSelector(state => state?.filters?.commonPropsDetails?.currentLocation || false);
  const columns = [
    {
      Header: 'Name',
      accessor: 'name',
      Cell: ({ row }) => (
        <NameCellRenderer row={row} />
      ),
    },
    {
      Header: 'Last Modified',
      accessor: 'modified_at',
    },
    // {
    //   Header: 'Created On',
    //   accessor: 'date_created',
    // },
    {
      Header: 'Owner',
      accessor: 'owner',
    },
    // {
    //   Header: 'Action',
    //   Cell: cellInfo => (
    //     <div className="flex justify-between cursor-pointer self-center">
    //       {cellInfo.row.original.type === 'file' ?
    //         <div className='text-info mr-2'
    //           onClick={() => setState(prevState => ({ ...prevState, selectedUserDetails: cellInfo.row.original, showCreateUserPanel: true }))}
    //         >Edit </div>
    //       : null}
    //     </div>
    //   ),
    // },
    {
      Header: 'File Size',
      accessor: 'size',
    },
  ]

  useEffect(() => {
    if(!_.isEqual(state.fileManageFilterChange, fileManageFilterChange)) {
      setState(prevState => ({ 
        ...prevState, 
        selectedProvider: fileManageFilterChange.selectedProvider,
        selectedAccount: fileManageFilterChange.selectedAccount ? fileManageFilterChange.selectedAccount : [],
        selectedRegion: fileManageFilterChange.selectedRegion ? fileManageFilterChange.selectedRegion : [],
        selectedClusters: fileManageFilterChange.selectedClusters ? fileManageFilterChange.selectedClusters : '',
        fileManageFilterChange,
        filteredFiles: []
      }))

      if(fileManageFilterChange.selectedClusters) {
        setState(prevState => ({ ...prevState, callUserFiles: true, filesDataLoading: true, statsDetail: {} }))        
      }
    }
  }, [fileManageFilterChange, state.fileManageFilterChange])

  const NameCellRenderer = ({ row }) => {
    const { type, name } = row.original;
    return(
      <div className="flex">
        <Icon icon={`${type === 'directory' ? 'flat-color-icons:folder' : 'mingcute:file-fill'} className="mr-2 self-center`} />
        <span>{name}</span>
      </div>
    )    
  };

  const getFileFolderStats = useCallback((data) => {
    let params = {}
    params.path = data.path
    params.provider = state.selectedProvider ? state.selectedProvider.toLowerCase() : ''
    params.cluster_name = state.selectedClusters
    if(data.owner) {
      params.owner = data.owner
    }

    dispatch(getStats(params))
      .then((response) => {
        setState(prevState => ({ ...prevState, statsDetail: response }))
      })


  }, [dispatch, state])

  const handleRowSelect = (selectedRows, path) => {
    // dispatch(setCommonPropsDetails('currentLocation', selectedRows))
    if(selectedRows && selectedRows.type === 'directory') {
      setState(prevState => ({ ...prevState, selectedPath: selectedRows.path, fileRowSelected: true, selectedRows, filesDataLoading: true }))
    } else {
      getFileFolderStats(selectedRows)
        // Increment the click count
        setClickCount((prevCount) => prevCount + 1);
        // Check if it's a double-click
        if (clickCount === 1) {
          // Reset the click count and perform the action for double-click (e.g., open the folder)
          setClickCount(0);
          // Add your logic to open the folder here
          dispatch(setCommonPropsDetails('doubleClickOpenFile', true))
        }

    
        // Set a timeout to reset the click count after a short delay (e.g., 300 milliseconds)
        setTimeout(() => {
          setClickCount(0);

        }, 300);
        
    }
    
  };

  // const handleBreadcrumbClick = (item) => {
  //   setState(prevState => ({ ...prevState, filesDataLoading: true }))
  //   let params = createParams(state, item.path)
  //   dispatch(getUserFiles(params))
  //   .then((response) => {
  //     if(response.data) {
  //       // let splitPath = response.data[0].current_directory ? splitString(response.data[0].current_directory, "/") : 
  //       setState(prevState => ({ ...prevState, filesDataLoading: false, files: response.data[0].children, filteredFiles: response.data[0].children,  breadcrumbLinks: response.data[0].current_directory }))
  //       dispatch(setCommonPropsDetails('currentLocation', response.data[0]))
  //     }
  //   })
  // }

  const handleNodeClick = (node) => {
		if (node.path && node.type === 'directory') {
      setState(prevState => ({ ...prevState, filesDataLoading: true }))
      let params = createParams(state, node.path)
      if(params.provider && params.cluster_name) {
        dispatch(getUserFiles(params))
        .then((response) => {
          if(response.data) {
            let responseData = response.data && response.data.length ? response.data[0] : {}
            let files = response.data && response.data.length && response.data[0].children ? response.data[0].children : []
            let breadcrumbLinks = response.data && response.data.length && response.data[0].current_directory ? response.data[0].current_directory : ''
            setState(prevState => ({ ...prevState, filesDataLoading: false, files, filteredFiles: files, breadcrumbLinks, redirectPath: '' }))
            getFileFolderStats(files)
            dispatch(setCommonPropsDetails('currentLocation', responseData))
          } else {
            setState(prevState => ({ ...prevState, filesDataLoading: false, permissionError: 'Permission Denied!' }))
          }
        })
      }
    }
  }

  // const getLastObjectFromBreadcrumb = () => {
    // if (state.breadcrumbLinks.length === 0) {
    //   return null;
    // }

    // return state.breadcrumbLinks[state.breadcrumbLinks.length - 1];
  // };

  const handleBreadCrumb = (currentPath, selectedPath, index) => {
    let splitPath = splitString(currentPath, '/')
    let redirectPath = ''
    splitPath.forEach((row, rIndex) => {
      if(row && rIndex <= index) {
        redirectPath += '/'+row
      }
    })
    setState(prevState => ({ ...prevState, redirectPath, activePath: selectedPath, callUserFiles: true }))
  }

  const handleFileBrowserRefresh = () => {
    setState(prevState => ({ ...prevState, filesDataLoading: true }))
    // let getLastBreadcrumb = getLastObjectFromBreadcrumb()
    let currentPath = currentLocation.path ? currentLocation.path : ''
    let params = createParams(state, currentPath)
    if(params.provider && params.cluster_name) {
      dispatch(getUserFiles(params))
      .then((response) => {
        if(response.data) {
          let responseData = response.data && response.data.length ? response.data[0] : {}
          let files = response.data && response.data.length && response.data[0].children ? response.data[0].children : []
          let breadcrumbLinks = response.data && response.data.length && response.data[0].current_directory ? response.data[0].current_directory : ''
          setState(prevState => ({ ...prevState, filesDataLoading: false, files, filteredFiles: files, breadcrumbLinks, redirectPath: '' }))
          getFileFolderStats(responseData)
          dispatch(setCommonPropsDetails('currentLocation', response.data[0]))
        }
      })
    }
  }

  const closeErrorToast = () => {
    setState(prevState => ({ ...prevState, permissionError: null }))
  };

  /* get folder specific file structure */
  useEffect(() => {
    if (state.fileRowSelected && state.selectedPath) {
      let params = createParams(state, state.selectedPath)
      if(params.provider && params.cluster_name) {
        dispatch(getUserFiles(params))
        .then((response) => {
          if(response.data) {
            let responseData = response.data && response.data.length ? response.data[0] : {}
            let files = response.data && response.data.length && response.data[0].children ? response.data[0].children : []
            let breadcrumbLinks = response.data && response.data.length && response.data[0].current_directory ? response.data[0].current_directory : ''
            setState(prevState => ({ ...prevState, filesDataLoading: false, files, filteredFiles: files, breadcrumbLinks, redirectPath: '' }))
            dispatch(setCommonPropsDetails('currentLocation', responseData))
            getFileFolderStats(response.data[0])
            setState(prevState => ({ ...prevState, selectedPath: null, selectedRows: {}, fileRowSelected: false }))
          } else {
            setState(prevState => ({ ...prevState, filesDataLoading: false }))
            setState(prevState => ({ ...prevState, selectedPath: null, selectedRows: {}, fileRowSelected: false, permissionError: 'Permission Denied!' }))
          }
        })
      }
    }
  }, [dispatch, state, getFileFolderStats])
  
  /* get default file structure */
	useEffect(() => {
    if (state.callUserFiles) {
      setState(prevState => ({ ...prevState, callUserFiles: false }))
      let params = createParams(state, state.redirectPath ? state.redirectPath : '')
      if(params.provider && params.cluster_name) {
        dispatch(getUserFiles(params))
        .then((response) => {
          if(response.data) {
            let responseData = response.data && response.data.length ? response.data[0] : {}
            let files = response.data && response.data.length && response.data[0].children ? response.data[0].children : []
            let breadcrumbLinks = response.data && response.data.length && response.data[0].current_directory ? response.data[0].current_directory : ''

            setState(prevState => ({ ...prevState, filesDataLoading: false, fileManageError: false, files, filteredFiles: files, breadcrumbLinks, redirectPath: '' }))
            dispatch(setCommonPropsDetails('currentLocation', responseData))
          } else {
            setState(prevState => ({ ...prevState, filesDataLoading: false, fileManageError: false, files: [], filteredFiles: [], breadcrumbLinks: '' }))
          }
        })
      }
    }
  }, [dispatch, state])

  useEffect(() => {
    // if (state.selectedProvider && state.selectedAccount.length && state.selectedRegion.length && !selectedCluster) {
    //   setSelectedCluster('awuse1nprpc01')
    //   setState(prevState => ({
    //     ...prevState,
    //     selectedClusters: 'awuse1nprpc01'
    //   }))
    // }
  }, [state])

  const handleClickOutside = (event) => {
    if (filterRef.current && filterRef.current.contains(event.target)) {
      dispatch(setCommonPropsDetails('closeFilters', true))
    }
  }
  
  return (
    <div className='w-full mt-2' ref={filterRef} onClick={(event) => handleClickOutside(event)}>
       <div className="flex flex-wrap">
        <div className="lg:w-2/12 w-2/12 pl-2">
          <div className="bg-dark3 rounded-md overflow-auto lg:h-screen h-auto">
            <Sidebar
              state={state}
              onNodeClick={handleNodeClick}
              // onSelectedRow={}?
              // onRightClick={}
            />
          </div>
        </div>
        <div className="lg:w-7/12 w-10/12 pl-2">
          <div className='bg-dark rounded-md overflow-auto lg:h-screen h-auto'>
            <div className="p-2">
              {state.selectedClusters &&
                <>
                  <FileActions
                    handleFileBrowserRefresh={handleFileBrowserRefresh}
                    state={state}
                    getLastObjectFromBreadcrumb={handleFileBrowserRefresh}
                    fromScreen={props.fromScreen}
                    selectedFile={(fileContent) => {
                      if(props.fromScreen) {
                        props.selectedFile(fileContent)
                      }
                    }}
                  />
                  {/* <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                      {state.breadcrumbLinks && state.breadcrumbLinks.map((item, index) => {
                        return(
                          <li className="breadcrumb-item" key={index}>
                            <span onClick={() => handleBreadcrumbClick(item)}>{item.name}</span>
                          </li>
                        )    
                      })}
                    </ol>
                  </nav> */}
                  <div className='flex justify-between mb-2'>
                    <nav aria-label="flex">
                      <ol className="flex bg-transparent mb-0 p-1">
                        {console.log(state.breadcrumbLinks)}
                          {state.breadcrumbLinks && splitString(state.breadcrumbLinks, '/') && splitString(state.breadcrumbLinks, '/').length ?
                              splitString(state.breadcrumbLinks, '/').map((spl, splIndex) => {
                                  return(
                                    spl ?
                                      <li key={'split_'+splIndex} className={`breadcrumb-item ${splitString(state.breadcrumbLinks, '/').length-1 !== splIndex ? 'cursor-pointer text-white' : 'active text-info'}`}
                                        onClick={() => handleBreadCrumb(state.breadcrumbLinks, spl, splIndex)}
                                      >
                                        {spl}
                                        <span className='mx-1'>{splitString(state.breadcrumbLinks, '/').length !== splIndex+1 ? '/' : ''}</span>
                                      </li>
                                    : null
                                  )
                              })
                          : null}
                      </ol>
                    </nav>
                    {state.files ?
                      <div className='self-center w-1/5 mb-0 mt-2'>
                        <Search
                            data={state.files ? state.files : []}
                            applyTags={false}
                            applyLiteDarkTags={true}
                            topClassName={'bg-darkGray text-white border border-mediumDarkGray'}
                            searchClassName={'px-2'}
                            searchIconColor={'text-lightGray'}
                            searchPlaceHolder={'Search....'}
                            className={'bg-transparent text-white w-full'}
                            widthHeight={22}
                            filteredData={(filteredFiles) => {
                              setState(prevState => ({ ...prevState, filteredFiles }))
                            }}
                        />
                      </div>
                    : null}
                  </div>
                  {!state.fileManageError &&
                    <ReactTable 
                      columns={columns}
                      data={state.filteredFiles ? state.filteredFiles : []}
                      loading={state.filesDataLoading}
                      onRowSelect={handleRowSelect}
                      selectedColor={'bg-mediumDarkGray'}
                    />
                  }
                </>
              }
              {state.fileManageError &&
                <div className="text-center">
                  <h5>File manager failed, please check admin</h5>
                </div>
              }
            </div>
          </div>
        </div>
        
        <div className="lg:w-3/12 w-full px-2">
          <div className='bg-dark2 rounded-md overflow-auto p-2 lg:h-screen h-auto'>
            {state.statsDetail && state.statsDetail.data ?
              <React.Fragment>
                {state.statsDetail && state.statsDetail.details ?
                  state.statsDetail.details.map(det => {
                    return(
                    <div className="flex mb-1">
                      <div className="py-1 w-full">
                        <p className="b-block mb-0">{removeUnderScore(det)}</p>
                        <p className="mb-0 text-white wordBreakAll">{state.statsDetail.data[det] ? state.statsDetail.data[det] : ''}</p>
                      </div>
                    </div>
                    )
                  })
                : null}
              </React.Fragment>
            : null}
          </div>
        </div>
        {state.permissionError && <ErrorToast message={state.permissionError} onClose={closeErrorToast} />}
      </div>
    </div>
  );
}