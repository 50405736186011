/*************************************************
 * Collider
 * @exports
 * @file LicenseAction.js
 * @author parakash // on 11-03-2024
 * @copyright © 2019-24 Collider. All rights reserved.
 *************************************************/

import ApiCalls from '../../components/ApiCalls'

import {
    GET_LICENSE_TOP_RESULTS,
    LIST_LICENSES,
	LIST_LICENSE_APPLICATIONS
} from '../../config'

/**
 * Action to get-license-top-results
 * @param {Object} body
 * @param {Function} callback
*/
export const getLicenseTopResults = (params) => {
	return async () => {
		try {

			const url = GET_LICENSE_TOP_RESULTS;
			// Assuming your API endpoint for the POST request is 'YOUR_API_ENDPOINT'
			const response = await ApiCalls.post(url, params)

			// You can dispatch an action with the fetched data here
			//   dispatch({ type: 'FETCH_TROUBLESHOOT_RESULTS_SUCCESS', payload: response.data });

			// Return the response data so you can use it in the component
			return response.data;
		} catch (error) {
			return error.response && error.response.data ? error.response.data : error.response && error.response.data ? error.response.data : error.response
		}
	};
}

/**
 * Action to list-licenses
 * @param {Object} body
 * @param {Function} callback
*/
export const listLicenses = (params) => {
	return async () => {
		try {

			const url = LIST_LICENSES;
			// Assuming your API endpoint for the POST request is 'YOUR_API_ENDPOINT'
			const response = await ApiCalls.post(url, params)

			// You can dispatch an action with the fetched data here
			//   dispatch({ type: 'FETCH_TROUBLESHOOT_RESULTS_SUCCESS', payload: response.data });

			// Return the response data so you can use it in the component
			return response.data;
		} catch (error) {
			return error.response && error.response.data ? error.response.data : error.response && error.response.data ? error.response.data : error.response
		}
	};
}

/**
 * Action to list-license-applications
 * @param {Object} body
 * @param {Function} callback
*/
export const listLicensesApplications = (params) => {
	return async () => {
		try {

			const url = LIST_LICENSE_APPLICATIONS;
			// Assuming your API endpoint for the POST request is 'YOUR_API_ENDPOINT'
			const response = await ApiCalls.post(url, params)

			// You can dispatch an action with the fetched data here
			//   dispatch({ type: 'FETCH_TROUBLESHOOT_RESULTS_SUCCESS', payload: response.data });

			// Return the response data so you can use it in the component
			return response.data;
		} catch (error) {
			return error.response && error.response.data ? error.response.data : error.response && error.response.data ? error.response.data : error.response
		}
	};
}