/*************************************************
 * Tvastar
 * @exports
 * @file SnsServiceLogs.js
 * @author Prakash // on 07/04/2024
 * @copyright © 2024 Tvastar. All rights reserved.
 *************************************************/
import React, { useCallback, useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import {Icon} from "@iconify/react";
import MultiSelectSection from '../../common/MultiSelectSection';
import { setGovernancePropsDetails, setNewEditRuleDetails } from '../../../actions/governance/GovernanceAction'
import { DynamicApiToDevCall } from '../../../actions/commonActionNew'
import { onlyNumeric } from '../../../utils/utility'

const SnsServiceLogs = (props) => {

	const [state, setState] = useState({
		onLoad: true,
		policyArray: [0],
		engionVersionType: ["Latest", "Specific"],

		hasErrorInRuleForm: false,
		hasError: props.hasError,
		timeTypeOptions: [
			{value: "h", label: "Hour(s)"},
			{value: "m", label: "Minute(s)"}
		],
		time_type_0: "h"
	})

	const dispatch = useDispatch(); // Create a dispatch function
	const editNewRuleDetails = useSelector(state => state?.governance?.editNewRuleDetails)
	const onClickFinish = useSelector(state => state?.governance?.governancePropsDetails?.onClickFinish||false)

	const apiToCall = useCallback((apiDetails, variable) => {
		apiDetails.forEach(item => {
			let params = {}
	
			let valueRequest =  item.body && item.body.values
			valueRequest && valueRequest.forEach(req => {
				if(req.value) {
					params[req.key] = req.value
				} else if(req.value_object) {
					let valuObj = {}
					req.value_object.forEach(subReq => {
						valuObj[subReq.key] = subReq.value
					})
					params[req.key] = valuObj
				}
			})
	
			let url = item.name
			let API_END_POINT = item.api
			let apiMethod = item.method
			
			dispatch(DynamicApiToDevCall(API_END_POINT, apiMethod, url, params))
				.then((response) => {
					if(response) {
						let results = response.length && response[0].results ? response[0].results : []
						setState(prevState => ({ ...prevState, Enable_options: results, loadingConditionPage: false }))
						if(props.viewMode) {
							setState(prevState => ({ ...prevState, savedData: [props.masterData], callSavedData: true }))
						} else {
							if(editNewRuleDetails && editNewRuleDetails.policies) {
								if(editNewRuleDetails.policies.filter(e => e.rule_category === props.masterData.rule_category).length) {						
									let savedData = editNewRuleDetails.policies.filter(e => e.rule_id === props.masterData.rule_id)
									if(savedData.length) {
										setState(prevState => ({ ...prevState, savedData, callSavedData: true }))
									}
								}
							}
						}
					} else {
						// setState(prevState => ({ ...prevState, Enable_options: [], loadingConditionPage: false }))
					}
				})
		})
	}, [dispatch, editNewRuleDetails, props.masterData, props.viewMode])

	useEffect(() => {
		if(state.onLoad) {
			setState(prevState => ({ ...prevState, onLoad: false }))
			let masterData = props.masterData
			let actionDetails = []
			if(masterData.actions && masterData.actions.length) {
				actionDetails = masterData.actions
			}
			let variables = masterData.variables
			variables.forEach(item => {
				if(item.component === "select" || item.component === "multiselect") {
					let options = []
					if(item.options) {
						options = item.options
					}
					setState(prevState => ({ ...prevState, [item.variable+"_options"]: options, [item.variable+"_0"]: (item.default ? item.default : "") }))
				} else {
					setState(prevState => ({ ...prevState, apiCallVariable: item.variable, [item.variable+"_0"]: (item.default ? item.default : "") }))
				}			
				
				setState(prevState => ({ ...prevState, ["mode_"+item.variable]: item.mode ? item.mode : "" }))
			})
			setState(prevState => ({ ...prevState, tags: masterData.tags, description: masterData.description ? masterData.description : "", actionDetails }))

			if(masterData.api_to_call) {
				apiToCall(masterData.api_to_call)
			} else {
				if(props.viewMode) {
					setState(prevState => ({ ...prevState, savedData: [props.masterData], callSavedData: true }))
				} else {
					if(editNewRuleDetails && editNewRuleDetails.policies) {
						if(editNewRuleDetails.policies.filter(e => e.rule_category === props.masterData.rule_category).length) {
							let savedData = editNewRuleDetails.policies.filter(f => f.rule_id === props.masterData.rule_id)
							if(savedData.length) {
								setState(prevState => ({ ...prevState, savedData, callSavedData: true }))
							}
						}
					}
				}
			}
		}
	}, [state.onLoad, apiToCall, props.masterData, editNewRuleDetails, props.viewMode])

	useEffect(() => {
		if(state.callSavedData) {
			setState(prevState => ({ ...prevState, callSavedData: false }))
			let savedData = state.savedData
			let policyArray = []
			savedData.forEach((row, index) => {
				policyArray.push(index)
				setState(prevState => ({ ...prevState, ["policy_id_"+index]: row.policy_id }))
				if(row.action && Object.entries(row.action)) {
					Object.entries(row.action).forEach(([key, value]) => {
						if(key === "schedule") {
							let timeSplit = value.substring(0, value.length - 1)
							let timeTypeSplit = value.substring(value.length - 1, value.length)
							if(timeTypeSplit === "h" || timeTypeSplit === "m") {
								setState(prevState => ({ ...prevState, ["time_type_"+index]: timeTypeSplit, [key+"_"+index]: timeSplit }))
							} else {
								setState(prevState => ({ ...prevState, [key+"_"+index]: value, ["time_type_"+index]: "" }))
							}
						} else {
							if(key === "name") {
								key = "selected_action"
							}
							setState(prevState => ({ ...prevState, [key+"_"+index]: value }))
						}
					})
				}
				row.variables.forEach(item => {
					setState(prevState => ({ ...prevState, [item.variable+"_"+index]: item.value_list ? item.value_list : item.value, inputDetected: true, show_child_policy_0: true }))
				})	

				if(row.enabled) {
					setState(prevState => ({ ...prevState, ["enabled_"+index]: true }))
				}		
			})
			setState(prevState => ({ ...prevState, policyArray }))
		}
	}, [state.callSavedData, state.savedData])

	const showPolicy = (array, showField, index) => {	
		state[array].forEach(item => {
			if(index === item) {
				setState(prevState => ({ ...prevState, [showField+"_"+item]: !state[showField+"_"+item] }))
			} else {
				setState(prevState => ({ ...prevState, [showField+"_"+item]: false }))
			}
		})
	}

	const addPolicies = (array, showField, index) => {
		let rowList = state[array];
		if(state[array]) {
			let value = state[array][state[array].length - 1]
			value = value+1 
			rowList.push(value);
		}
		rowList.forEach(item => {
			if(index === item) {
				setState(prevState => ({ ...prevState, [showField+"_"+item]: true }))
			} else {
				setState(prevState => ({ ...prevState, [showField+"_"+item]: false }))
			}
		})

		setState(prevState => ({ ...prevState, [array]: rowList, onChangeInput: true }))
	}

	const removeSelectedDetails = (field, removeItem) => {
		let filteredResult = state[field].filter(e => e !== removeItem)
		setState(prevState => ({ ...prevState, [field]: filteredResult }))
	}

	useEffect(() => {
		if(state.onChangeInput) {
			setState(prevState => ({ ...prevState, onChangeInput: false }))
			let allPolicies = editNewRuleDetails.policies ? editNewRuleDetails.policies : []
			let currentPolicies = []
			let policies = []
			if(allPolicies.length) {
				allPolicies.forEach(row => {
					if(row.rule_id === props.masterData.rule_id) {
						currentPolicies.push(row)
					} else {
						policies.push(row)
					}
				})
			}
			let data = []
			state.policyArray.forEach(item => {
				let obj = {}
				if(state.edit) {
					obj.policy_id = "id"
				}
					
				obj.rule_category = props.masterData.rule_category
				obj.resource_type = props.masterData.resource_type
				obj.tags = props.masterData.tags
				obj.mode =  props.masterData.mode
				obj.enabled = state["enabled_"+item] ? true : false
				obj.disabled = state["disable_"+item] ? true : false
				obj.rule_id = props.masterData.rule_id
				obj.policy_name = props.masterData.policy_name
				obj.resource_type = props.masterData.resource_type
				obj.rule_category = props.masterData.rule_category
				obj.template = props.masterData.template
				obj.governance_type = props.masterData.governance_type

				if(state["policy_id_"+item]) {
					obj.policy_id = state["policy_id_"+item]
				}

				let variables = []
				let masterVaiables = props.masterData.variables ? props.masterData.variables : []
				let inputMissing = false
				masterVaiables.forEach(itmVar => {
					let dataRow = {}
					let inputValue = state[itmVar.variable+"_"+item]
					Object.entries(itmVar).forEach(([key, value]) => {		
						if(key === "value_list" || key === "value") {
							dataRow[key] = inputValue
						} else {
							dataRow[key] = value
						}
						if(typeof inputValue !== 'boolean') {
							if(!inputValue || (inputValue && Array.isArray(inputValue) && !inputValue.length)) {
								inputMissing = true
							}
						}
					})
					variables.push(dataRow)
				})
				obj.variables = variables
				obj.description = state.description

				if(state.actionDetails && state.actionDetails.length) {
					let actionObj = {}
					actionObj.variables = []
					if(state["selected_action_"+item]) {
						actionObj.processor_rule_id = state["action_processor_rule_id_"+item]
						actionObj.name = state["selected_action_"+item]				
						if(state["apply_method_"+item]) {
							actionObj.apply_method = state["apply_method_"+item]					
							if(state["schedule_"+item] && state["schedule_"+item] !== "") {
								if(state["apply_method_"+item] === "run_after") {
									actionObj.schedule = state["schedule_"+item]+""+state["time_type_"+item]
								} else if(state["apply_method_"+item] === "run_at") {
									actionObj.schedule = state["schedule_"+item]
								}
							}
						}
						obj.action = actionObj
					}
				}

				if(!inputMissing) {
					data.push(obj)
				}
			})

			if(data && data.length) {
				policies = [...data, ...policies]
			}

			setTimeout(() => { dispatch(setNewEditRuleDetails('policies', policies)) }, 500);

			// dispatch(setNewEditRuleDetails('policies', policies))

			setState(prevState => ({ ...prevState, inputDetected: true }))
		}
	}, [state.onChangeInput, dispatch, editNewRuleDetails.policies, props, state])

	useEffect(() => {
		if(onClickFinish) {
			dispatch(setGovernancePropsDetails('onClickFinish', false))
			let hasError = false
			state.policyArray.forEach(item => {
				if((!state["Enable_"+item] || state["Enable_"+item] === "") || (!state["protocol_"+item] || state["protocol_"+item] === "") ||(!state["sample_rate_"+item] || state["sample_rate_"+item] === "")) {
					hasError = true
				}
			})
			
			setState(prevState => ({ ...prevState, hasError }))
			
			props.validateInput(hasError)
		}
	}, [onClickFinish, props, state, dispatch])
	
	// useEffect(() => {
	// 	if(state.onChangeInput) {
	// 		setState(prevState => ({ ...prevState, onChangeInput: false }))
	// 		setTimeout(() => { onChangeInput() }, 1000);
	// 	}

	// }, [state.onChangeInput, onChangeInput])

	// const validateInput = () => {
	// 	let hasError = false
	// 	state.policyArray.forEach(item => {
	// 		if((!state["Enable_"+item] || state["Enable_"+item] === "") || (!state["protocol_"+item] || state["protocol_"+item] === "") ||(!state["sample_rate_"+item] || state["sample_rate_"+item] === "")) {
	// 			hasError = true
	// 		}
	// 	})


	// 	setState(prevState => ({ ...prevState, hasError }))
		
	// 	props.validateInput(hasError)
	// }

	const getLogsOfLogType = (item, logType) => {

		let logOptions = []
		if(state.Enable_options && Array.isArray(state.Enable_options)) {
			state.Enable_options.forEach(row => {
				logOptions = row.logs ? row.logs : []
			})
		}

		setState(prevState => ({ ...prevState, ["log_options_"+item]: logOptions }))
	}

	const resetPolicy = (item) => {
		setState(prevState => ({ ...prevState,  ["Enable_"+item]: "",  ["protocol_"+item]: [], ["sample_rate_"+item]: "", onChangeInput: true }))
	}
	
	// const deletePolicy = (item) => {
	// 	let array = state.policyArray.filter(e => e !== item)

	// 	if(!array.length) {
	// 		array = [0]
	// 	}
	// 	let deletedPolicies = editNewRuleDetails && editNewRuleDetails.deleted_policies ? editNewRuleDetails.deleted_policies : []
	// 	deletedPolicies.push(state["policy_id_"+item])
	// 	props.setNewEditRuleDetails("deleted_policies", deletedPolicies)
	// 	setState(prevState => ({ ...prevState, policyArray: array, ["show_child_policy_"+item]: false }))
	// 	resetPolicy(item)
	// }

	const onKeyDownTime = (e, time, state) => {
		let str = time.replace(/[^0-9]/gi,'')
		if(e.keyCode === 8) { 
			str = str.slice(0, -1)
			validateTime(str, state)
		} else {
			validateTime(time, state)
		}
	}

	const validateTime = (time, state) => {
		let str = time.replace(/[^0-9]/gi,'')
		
		let strLength = str.length
		let firstHour = str.substring(0, 2)
		let data = "" 
		if(strLength > 1 && parseInt(firstHour) > 23) {
			data += "00:"
		} else if(strLength > 1 && parseInt(firstHour) < 10) {
			if(firstHour.length === 2) {
				data = firstHour+":"
			} else {
				data += "0"+firstHour+":"
			}
		} else if(strLength > 1 && parseInt(firstHour) <= 23) {
			data += firstHour+":"
		}

		if(data === "") {
			data = time	
		}
		
		let firstMinute = str.substring(2, 4)
		
		if(firstMinute.length) {
			if(strLength > 3 && parseInt(firstMinute) > 59) {
				data += "00:"
			} else {
				data += firstMinute+":"
			}
		}
		
		let firstSecond = str.substring(4, 6)
		
		if(firstSecond.length) {
			if(strLength > 5 && parseInt(firstSecond) > 59) {
				data += "00"
			} else {
				data += firstSecond
			}
		}

		setState(prevState => ({ ...prevState, [state]: data, onChangeInput: true }))
	}

	const handleChildClick = (event, type, dropdownType, section) => {	
		event.stopPropagation();

		let clickedChild = []
		if(type === 'child') {
            if(state.clickedChild && state.clickedChild.length) {
                state.clickedChild.forEach(row => {
                    setState(prevState => ({ ...prevState, [row]: false }))
                })
            }
            clickedChild.push(section)
            setState(prevState => ({ ...prevState, [section]: dropdownType === "singleDropDown" && state[section] ? false : true, clickedChild }))
        } else if(type === 'parent') {
            if(state.clickedChild && state.clickedChild.length) {
                state.clickedChild.forEach(row => {
                    setState(prevState => ({ ...prevState, [row]: false }))
                })
            }
            
            setState(prevState => ({ ...prevState, clickedChild }))
        }
	}

	return (
		<div onClick={(event) => handleChildClick(event, 'parent')}>
			{state.hasError ?
			    <p className='mb-0 text-danger'>Please check the input</p>
		   : null}
			{state.policyArray && state.policyArray.map((item, index) => {
				return(
					<React.Fragment key={"policy_"+index}>
					<div className={`${!props.viewMode ? "py-3" : "py-1"} mb-2 border-b border-mediumDarkGray`}>
						{!props.viewMode ?
							<React.Fragment>
							<div className={`flex flex-wrap justify-between pl-2`}>
								<div className='flex flex-wrap w-3/5'>
									{(!state["Enable_"+item] || state["Enable_"+item] === "") && (!state["protocol_"+item] || !state["protocol_"+item].length) && (!state["sample_rate_"+item] || state["sample_rate_"+item] === "") ?
										<p className={`mb-0 mx-2 self-center mb-1 text-lightGray cursor-pointer`} onClick={() => showPolicy("policyArray", "show_child_policy", item)}>{props.masterData && props.masterData.description ? props.masterData.description : "Choose policy details"}</p>
									:
										<React.Fragment>
											<p className={`mx-2 self-center mb-0 text-white mt-2`}>
												{state["Enable_"+item] === false || state["Enable_"+item] === true ?
													<React.Fragment>
														<span className="mr-2 text-info">{state["Enable_"+item] === false ? 'Disable' : 'Enable'}</span>
														<span className="mr-2">Log delivery status</span>
													</React.Fragment>
												: null}
											</p>
												
											{state["protocol_"+item] && state["protocol_"+item].length ?
												<React.Fragment>												
													{state["protocol_"+item] && state["protocol_"+item].length ? 
														state["protocol_"+item].map((row, index) => {
															return(
																<span key={"row_"+index} className="flex border border-lightGray rounded-full px-2 py-1 mr-2 self-center text-info mt-2">{row}
																	<Icon icon="ion:close-circle-sharp" width="16" height="16"  className='text-white ml-2 self-center' onClick={ () => removeSelectedDetails("protocol_"+item, row) } />
																</span>
															)
														})
													: null}
													<p className="self-center mb-0 mr-2 mt-2 text-white">protocols are selected.</p>
												</React.Fragment>
											: null}

											{state["sample_rate_"+item] && state["sample_rate_"+item] !== "" ?
												<p className={`self-center mb-0 text-white mt-2`}>
												<span className="mr-2">At</span>
												<span className="mr-2 text-info">{state["sample_rate_"+item]}</span>
												{/* <span className="mr-2">days</span> */}
												</p>
											: null}
										</React.Fragment>
									}
								</div>
								<div className="flex flex-wrap justify-end self-center w-2/5">
									{state["tags"] && state["tags"].length ?
										state["tags"].map((tag, ttIndex) => {
											return(
												tag !== "" ?
													<span key={'tag_'+ttIndex} className={`border border-lightGray px-2 py-1 mr-2 self-center text-info ml-2`} > {tag} </span>
												: null
											)
										})
									: null}
									{!state["show_child_policy_"+index] ?
										<div className="ml-3 flex">
											<Icon icon="octicon:pencil-24" width='16' height='16' className="mr-1 self-center cursor-pointer text-white" onClick={() => showPolicy("policyArray", "show_child_policy", item)} />
											<Icon icon={`fa:angle-${state["show_child_policy_"+item] ? 'up' : 'down'}`} width="20" height="20" className='text-lightGray ml-4 cursor-pointer self-center' onClick={() => showPolicy("policyArray", "show_child_policy", item)} />
										</div>
									: 
									
										<div className="flex">
											<Icon icon="mdi:reload" className="text-white self-center cursor-pointer" width="18" height="18" onClick={() => resetPolicy(item)} />
											{/* <span className={`fal fa-trash cursor-pointer mr-3 text-lightGray f15`} onClick={() => deletePolicy(item)}></span> */}
											<Icon icon={`fa:angle-${state["show_child_policy_"+item] ? 'up' : 'down'}`} width="20" height="20" className='text-lightGray ml-4 cursor-pointer self-center' onClick={() => showPolicy("policyArray", "show_child_policy", item)} />
										</div>
									}
								</div>
							</div>
							{state["show_child_policy_"+item] ?
								<div className={`pl-5`}>
									<div className={`flex flex-wrap mb-2`}>
										<div className={`form-check form-check-inline self-center mt-2 mr-2 ${state["enabled_"+item] ? "" : "checkboxGrayColor"}`}>
											<input
												className='form-check-input m-0'
												type='checkbox'
												checked={state["enabled_"+item]}
												onClick={e => setState(prevState => ({ ...prevState, ["enabled_"+item]: !state["enabled_"+item] , onChangeInput: true }))}	
												title={state["enabled_"+item] ? "not enable" : "enable"}								
											/>
											{!state["enabled_"+item] ?
												<span className="checkmark" onClick={e => setState(prevState => ({ ...prevState, ["enabled_"+item]: !state["enabled_"+item] , onChangeInput: true }))}></span>
											: null}
										</div>
										{/* <span className={`fas fa-ban self-center mt-2 mr-2 ${state["disable_"+item] ? "text-issues" : "text-lightGray"}`} title={state["disable_"+item] ? "disable" : "not disbale"}  onClick={() => this.setState({ ["disable_"+item]: !state["disable_"+item] }, () => this.onChangeInput() )}></span> */}
										
										{/* <p className={`mb-0 ml-2 mr-1 mt-2 self-center ${state["disable_"+item] ? "text-issues" : !state["enabled_"+item] ? "text-lightGray" : "text-white"}`}>Use</p> */}
										<div className='min-w-32 mr-2' 
											onClick={(event) => {
												if(!state["emgineType_"+item]) {
													event.preventDefault();
													handleChildClick(event, 'child', 'singleDropDown', "emgineType_"+item)
												}
											}}
										>
											<p className={`flex bg-transparent border-b border-lightGray px-2 py-1 justify-between px-2 mb-0 mx-2 truncate cursor-pointer ${state["Enable_"+item] !== 'undefined' ? 'text-white' : 'text-lightGray'}`}>
												{state["Enable_"+item] !== 'undefined' && state.Enable_options && state.Enable_options.filter(e => e.value === state["Enable_"+item]).length ? state.Enable_options.filter(e => e.value === state["Enable_"+item])[0].label : 'Select'}
												<Icon icon="icon-park-solid:down-one" className={`${state["Enable_"+item] !== 'undefined' ? "text-white" : "text-lightGray"} self-center`} width="16" height="16" />
											</p>	
											{state["emgineType_"+item] ?
												<MultiSelectSection
													fields={["value", "label"]}
													options={state.Enable_options ? state.Enable_options : []}
													selectedValues={state["Enable_"+item] ? state["Enable_"+item] : ''}
													callbackMultiSelect={(value) => {
														if(!value || typeof(value) === 'string' || typeof(value) === 'boolean') {
															setState(prevState => ({ ...prevState, ["Enable_"+item]: value, onChangeInput: true }))
															getLogsOfLogType(item, value)
														} else {
															value.preventDefault()
															handleChildClick(value, "search", 'singleDropDown', "")
														}
													}}
													singleSelection={true}
													hideSearch={false}
													topClass={'auto'}
													widthClass={'minWidth220'}
													removeTopOptions={true}
												/>
											: null}
										</div>
										<p className={`mb-0 mr-1 mt-2 self-center ${state["disable_"+item] ? "text-issues" : !state["enabled_"+item] ? "text-lightGray" : "text-white"}`}>Log delivery status</p>
									</div>
									{state["Enable_"+item] ?
										<React.Fragment>
										<div className={'flex flex-wrap mb-2'} style={{marginLeft:"45px"}}>
											<div className='min-w-32 mx-2'
												onClick={(event) => {
													event.preventDefault();
													handleChildClick(event, 'child', 'muliselectDropDown', "doprdown_"+item)
												}}
											>
												<p className={`flex bg-transparent border-b border-lightGray px-2 py-1 justify-between px-2 mb-0 mx-2 truncate cursor-pointer ${state["protocol_"+item] && state["protocol_"+item].length ? 'text-white' : 'text-lightGray'}`}>
													{state["protocol_"+item] && state["protocol_"+item].length ? state["protocol_"+item].length+' Selected' : 'Select'}
													<Icon icon="icon-park-solid:down-one" className={`${state["protocol_"+item] && state["protocol_"+item].length ? "text-white" : "text-lightGray"} self-center`} width="16" height="16" />
												</p>
												{state['doprdown_'+item] ?
													<MultiSelectSection
														fields={["value", "label"]}
														options={state["protocol_options"]}
														selectedValues={state["protocol_"+item] ? state["protocol_"+item] : []}
														callbackMultiSelect={(value) => setState(prevState => ({ ...prevState, ["protocol_"+item]: value, onChangeInput: true }))}
														singleSelection={false}
														hideSearch={false}
														topClass={'auto'}
														widthClass={'minWidth220'}
														removeTopOptions={true}
													/>
												: null}
											</div>
											{state["protocol_"+item] && state["protocol_"+item].length ? 
												state["protocol_"+item].map((row, index) => {
													return(
														<span key={"row1_"+index} className="flex border border-lightGray rounded-full px-2 py-1 mr-2 mt-2 self-center text-info">{row}
															<Icon icon="ion:close-circle-sharp" width="16" height="16"  className='text-white ml-2 self-center' onClick={ () => removeSelectedDetails("protocol_"+item, row) } />
														</span>
													)
												})
											: null}
											<p className={`mb-0 mr-1 mt-2 self-center ${state["disable_"+item] ? "text-issues" : !state["enabled_"+item] ? "text-lightGray" : "text-white"}`}>protocols are selected</p>
										</div>
										
										<div className={'flex flex-wrap mb-2'} style={{marginLeft:"45px"}}>
											<p className={`mb-0 mr-1 mt-2 self-center ${state["disable_"+item] ? "text-issues" : !state["enabled_"+item] ? "text-lightGray" : "text-white"}`}>At</p>
											<input 
												type="text" 
												className={`bg-transparent border-b border-lightGray px-2 py-1 text-white min-w-32`}
												style={{minHeight: "38px"}}
												placeholder={"Enter retention period"}
												value={state["sample_rate_"+item]}
												onChange={e => setState(prevState => ({ ...prevState, ["sample_rate_"+item]: onlyNumeric(e.target.value), onChangeInput: true }))}
											/>
											{/* <p className={`mb-0 mr-1 mt-2 self-center ${state["disable_"+item] ? "text-issues" : !state["enabled_"+item] ? "text-lightGray" : "text-white"}`}>days</p> */}
										</div>
										<div className={'flex flex-wrap mb-2'} style={{marginLeft:"45px"}}>						
											{state["tags_"+item] && state["tags_"+item].length ?
												state["tags_"+item].map((tag, tIndex) => {
													return(
														<span key={"tag1_"+tIndex} className={`border border-lightGray px-2 py-1 mr-2 self-center text-info mr-2`} > {tag} </span>
													)
												})
											: null}
										</div>
										</React.Fragment>
									: null}
							
									{state.actionDetails && state.actionDetails.length ?
										<div className="rounded bg-muted p-3 mt-5">
											<p className="mb-0 text-lg text-purple3 cursor-pointer">Rule Action</p>
											<div className={`flex flex-wrap mb-2`}>
												<p className={`mb-0 mt-2 self-center`}>Select Action</p>
												<div className='min-w-32 mr-2' 
													onClick={(event) => {
														if(!state["showAction_"+item]) {
															event.preventDefault();
															handleChildClick(event, 'child', 'singleDropDown', "showAction_"+item)
														}
													}}
												>
													<p className={`flex bg-transparent border-b border-lightGray px-2 py-1 justify-between px-2 mb-0 mx-2 truncate cursor-pointer ${state["selected_action_"+item] ? 'text-white' : 'text-lightGray'}`}>
														{state["selected_action_"+item] ? state["selected_action_"+item] : 'Select'}
														<Icon icon="icon-park-solid:down-one" className={`${state["selected_action_"+item] ? "text-white" : "text-lightGray"} self-center`} width="16" height="16" />
													</p>
													{state["showAction_"+item] ?
														<MultiSelectSection
															fields={["action", "action"]}
															options={state.actionDetails ? state.actionDetails : []}
															selectedValues={state["selected_action_"+item] ? state["selected_action_"+item] : ''}
															callbackMultiSelect={(value) => {
																if(!value || typeof(value) === 'string' || typeof(value) === 'boolean') {
																	setState(prevState => ({ ...prevState, 
																		["selected_action_"+item]: value,
																		["action_processor_rule_id_"+item]: state.actionDetails.filter(e => e.action === value) && state.actionDetails.filter(e => e.action === value).length && state.actionDetails.filter(e => e.action === value)[0].processor_rule_id ? state.actionDetails.filter(e => e.action === value)[0].processor_rule_id : "",
																		onChangeInput: true
																	}))
																} else {
																	value.preventDefault()
																	handleChildClick(value, "search", 'singleDropDown', "")
																}
															}}
															singleSelection={true}
															hideSearch={false}
															topClass={'auto'}
															widthClass={'minWidth220'}
															removeTopOptions={true}
														/>
													: null}
												</div>
											</div>
											
											{state["selected_action_"+item] ?
												<div className={`flex flex-wrap mb-2 ml-5`}>
													<p className={`mb-0 mt-2 self-center`}>Apply Method</p>
													<div className='min-w-32 mr-2' 
														onClick={(event) => {
															if(!state["showMethod_"+item]) {
																event.preventDefault();
																handleChildClick(event, 'child', 'singleDropDown', "showMethod_"+item)
															}
														}}
													>
														<p className={`flex bg-transparent border-b border-lightGray px-2 py-1 justify-between px-2 mb-0 mx-2 truncate cursor-pointer ${state["apply_method_"+item] ? 'text-white' : 'text-lightGray'}`}>
															{state["apply_method_"+item] && props.actionMethods ? props.actionMethods.filter(e => e.value === state["apply_method_"+item])[0].label : 'Select'}
															<Icon icon="icon-park-solid:down-one" className={`${state["apply_method_"+item] ? "text-white" : "text-lightGray"} self-center`} width="16" height="16" />
														</p>
														{state["showMethod_"+item] ?
															<MultiSelectSection
																fields={["value", "label"]}
																options={props.actionMethods ? props.actionMethods : []}
																selectedValues={state["apply_method_"+item] ? state["apply_method_"+item] : ''}
																callbackMultiSelect={(value) => {
																	if(!value || typeof(value) === 'string' || typeof(value) === 'boolean') {
																		setState(prevState => ({ ...prevState, ["apply_method_"+item]: value, ["schedule_"+item]: "", onChangeInput: true }))
																	} else {
																		value.preventDefault()
																		handleChildClick(value, "search", 'singleDropDown', "")
																	}
																}}
																singleSelection={true}
																hideSearch={false}
																topClass={'auto'}
																widthClass={'minWidth220'}
																removeTopOptions={true}
															/>
														: null}
													</div>
													{state["apply_method_"+item] && state["apply_method_"+item] !== "immediately" ?
														<React.Fragment>
														<p className={`mb-0 ml-1 mr-2 self-center`}>Schedule</p>
														{state["apply_method_"+item] === "run_at" ?
															<input 
																type="text" 
																className={`bg-transparent border-b border-lightGray px-2 py-1 text-white min-w-32`}
																style={{minHeight: "38px"}}
																placeholder={"HH:MM:SS"}
																value={state["schedule_"+item]}
																maxLength="8"
																onKeyDown={e => onKeyDownTime(e, e.target.value, "schedule_"+item)}
																onChange={e => validateTime(e.target.value, "schedule_"+item)}
															/>
														: state["apply_method_"+item] === "run_after" ?
															<div className="flex">
																<input 
																	type="text" 
																	className={`bg-transparent border-b border-lightGray px-2 py-1 text-white min-w-32`}
																	style={{minHeight: "38px"}}
																	placeholder={""}
																	value={state["schedule_"+item]}
																	maxLength="2"
																	onChange={e => setState(prevState => ({ ...prevState, ["schedule_"+item]: onlyNumeric(e.target.value), onChangeInput: true }))}
																/>
																<div className='min-w-32 mr-2' 
																	onClick={(event) => {
																		if(!state["showTimeType_"+item]) {
																			event.preventDefault();
																			handleChildClick(event, 'child', 'singleDropDown', "showTimeType_"+item)
																		}
																	}}
																>
																	<p className={`flex bg-transparent border-b border-lightGray px-2 py-1 justify-between px-2 mb-0 mx-2 truncate cursor-pointer ${state["time_type_"+item] ? 'text-white' : 'text-lightGray'}`}>
																		{state["time_type_"+item] && state.timeTypeOptions ? state.timeTypeOptions.filter(e => e.value === state["time_type_"+item])[0].label : 'Select'}
																		<Icon icon="icon-park-solid:down-one" className={`${state["time_type_"+item] ? "text-white" : "text-lightGray"} self-center`} width="16" height="16" />
																	</p>
																	{state["showTimeType_"+item] ?
																		<MultiSelectSection
																			fields={["value", "label"]}
																			options={state.timeTypeOptions ? state.timeTypeOptions : []}
																			selectedValues={state["time_type_"+item] ? state["time_type_"+item] : ''}
																			callbackMultiSelect={(value) => {
																				if(!value || typeof(value) === 'string' || typeof(value) === 'boolean') {
																					setState(prevState => ({ ...prevState, ["time_type_"+item]: value, onChangeInput: true }))
																				} else {
																					value.preventDefault()
																					handleChildClick(value, "search", 'singleDropDown', "")
																				}
																			}}
																			singleSelection={true}
																			hideSearch={false}
																			topClass={'auto'}
																			widthClass={'minWidth220'}
																			removeTopOptions={true}
																		/>
																	: null}
																</div>
															</div>

														: null}
														</React.Fragment>
													: null}
												</div>
											: null}
										</div>
									: null}
								</div>
							: null}
							</React.Fragment>
						:
							<React.Fragment>
							<div className="flex justify-between ml-3">
								<div className={`w-80"`}>
									<div className={`flex flex-wrap mb-2`}>
										<p className={`mb-0 mt-2 self-center ${props.selectedModeType === state.mode_engine_type ? (props.selectedModeType === "reactive" ? "text-lightGray" : props.selectedModeType === "preventive" ? "text-danger" : "text-white") : "text-white"}`}>Use</p>

										<p className={`mb-0 ml-2 mt-2 self-center text-info`}>{state["Enable_"+item] && state["Enable_"+item] !== "" ? state["Enable_"+item] : ""}</p>

										<p className={`mb-0 ml-2 mt-2 self-center ${props.selectedModeType === state.mode_engine_type ? (props.selectedModeType === "reactive" ? "text-lightGray" : props.selectedModeType === "preventive" ? "text-danger" : "text-white") : "text-white"}`}>engines</p>
									</div>
									
									<div className={`flex flex-wrap mb-2 ml-3`}>
										<p className={`mb-0 ml-2 mr-2 mt-2 self-center ${props.selectedModeType === state.mode_logs_to_export ? (props.selectedModeType === "reactive" ? "text-lightGray" : props.selectedModeType === "preventive" ? "text-danger" : "text-white") : "text-white"}`}>Logs to be exported</p>

										{state["protocol_"+item] && state["protocol_"+item].length ? 
											state["protocol_"+item].map((row, index) => {
												return(
													<span key={"row2_"+index} className="border border-lightGray px-2 py-1 mr-2 self-center text-info">{row}</span>
												)
											})
										: null}
									</div>

									<div className={`flex flex-wrap mb-2 ml-3`}>
										<p className={`mb-0 ml-2 mt-2 self-center ${props.selectedModeType === state.mode_log_retention_period ? (props.selectedModeType === "reactive" ? "text-lightGray" : props.selectedModeType === "preventive" ? "text-danger" : "text-white") : "text-white"}`}>Log retention period</p>

										<p className={`mb-0 ml-2 mt-2 self-center text-info`}>{state["sample_rate_"+item]}</p>
									</div>
								</div>
								{!index ?
									<div className={`flex flex-wrap w-20 self-start justify-end mt-2`}>
										{state["tags"] && state["tags"].length ?
											state["tags"].map((tag, tIndex) => {
												return(
													tag !== "" ?
														<span key={"tag2_"+index} className={`border border-lightGray px-2 py-1 mr-2 self-center text-info ml-2 mb-2`} > {tag} </span>
													: null
												)
											})
										: null}
									</div>
								: null}
							</div>
										
							{state["selected_action_"+item] && state["selected_action_"+item] !== "" ?
								<div className="rounded bg-muted p-3 ml-4 mt-2">
									<div className={`flex flex-wrap mb-2`}>
										<span className={`mb-0 ml-2 mt-2 self-center text-purple mt-2`}>Selected Action <span className="text-white mx-1">{state["selected_action_"+item]}</span></span>
										{state["apply_method_"+item] && state["apply_method_"+item] ?
											<span className={`mb-0 ml-2 mt-2 self-center text-lightGray mt-2`}><span className="text-purple">{props.actionMethods.filter(e => e.value === state["apply_method_"+item]) && props.actionMethods.filter(e => e.value === state["apply_method_"+item]).length ? props.actionMethods.filter(e => e.value === state["apply_method_"+item])[0].label : ""}</span></span>
										: null}
										{state["schedule_"+item] && state["schedule_"+item] ?
											<span className={`mb-0 ml-2 mt-2 self-center text-lightGray mt-2`}><span className="text-white mx-1">{state["schedule_"+item]} {state["time_type_"+item] !== "" ? <span className="ml-1">{state["time_type_"+item]}</span>: null}</span></span>
										: null}
									</div>
								</div>
							: null}
							</React.Fragment>

						}
					</div>
					
					{state.policyArray.length === (index+1) && !props.viewMode ?
						<span className="text-info mt-2 ml-3 mb-5 cursor-pointer" onClick={() => addPolicies("policyArray", "show_child_policy", item+1)}>+ Add new Log policy</span>
					: null}
					</React.Fragment>
				)
			})}
		</div>
	)
}
export default SnsServiceLogs
