/*************************************************
 * Tvastar
 * @exports
 * @file CreateUpdateGroup.js
 * @author Prakash // on 08/03/2024
 * @copyright © 2024 Tvastar. All rights reserved.
 *************************************************/
import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux'; // Import useDispatch hook to dispatch actions
import { useNavigate, useLocation } from 'react-router-dom';

import { Store as CommonNotification } from 'react-notifications-component';
import { URL_PATH } from '../../../config/urlPath'

import { listAllProviders, listClusterFilters, setCommonPropsDetails } from '../../../actions/commonActionNew'
import { qosCreateAccout, qosUpdateAccount, getAccountPolicy } from '../../../actions/Collider/AccountAction'
import MultiSelectSection from '../../common/MultiSelectSection';
import { capitalizeAllLetter, getAccountNameFromId, getRegionName, noSpace } from '../../../utils/utility';
import { setHpcPropsDetails } from '../../../actions/Collider/HpcAction';
import GroupAssocationDetails from './GroupAssocationDetails';
import {Icon} from "@iconify/react";

const CreateUpdateGroup = () => {
	const [state, setState] = useState({
		listLoading: false,
	})

	const dispatch = useDispatch(); // Create a dispatch function
	const providers = useSelector(state => state?.filters?.providers || false);
    const propProjAccounts = useSelector(state => state?.filters?.commonPropsDetails?.propProjAccounts || false);
    const propProjRegions = useSelector(state => state?.filters?.commonPropsDetails?.propProjRegions || false);
	const qosCreateEditInputs = useSelector(state => state?.hpc?.hpcPropsDetails?.qosCreateEditInputs || false)

	const navigate = useNavigate();
	const location = useLocation();
    const receivedData = location.state || false

	const handleInputChange = useCallback((label, value) => {
		let obj = qosCreateEditInputs ? qosCreateEditInputs : {}
		obj[label] = value
		dispatch(setHpcPropsDetails('qosCreateEditInputs', obj))
	}, [dispatch, qosCreateEditInputs])	

    // list all providers
    useEffect(() => {
        if (providers) {
            setState(prevState => ({
                ...prevState,
                providers: providers,
                selectedProvider: providers && providers.length ? (providers.filter(e => e.provider_name === 'AWS').length ? 'AWS' : providers[0].provider_name) : ''
            }));			
        } else { 
            let params = {}
            dispatch(listAllProviders(params))
                .then((response) => {
					if(response) {
						
						setState(prevState => ({
							...prevState,
							providers: response,
							selectedProvider: response && response.length ? (response.filter(e => e.provider_name === 'AWS').length ? 'AWS' : response[0].provider_name) : '',
							callClusterList: true,
							callQueueList: true,
							callUserList: true
						}));						
					}
                })

        }
    }, [dispatch, providers, handleInputChange]);

    // Call provider based accounts whenever the selectedProviders state is updated
    useEffect(() => {
        if(state.selectedProvider) {	
			handleInputChange('provider', state.selectedProvider.toLowerCase())
            let params = {};
            params.provider = state.selectedProvider.toLowerCase();
            params.aggregate_by = 'account_id';
            let label = state.selectedProvider
			if(propProjAccounts && propProjAccounts.hasOwnProperty(label)) {
                setState(prevState => ({ ...prevState, accounts: propProjAccounts[label] }));
            } else {
                dispatch(listClusterFilters(params))
                    .then((response) => {
                        if(response) {
                            if(response.length) {
                                let obj = propProjAccounts ? propProjAccounts : {}
                                obj[label] = response
                                dispatch(setCommonPropsDetails('propProjAccounts', obj))
                                setState(prevState => ({ ...prevState, accounts: response }));
                            }
                        }
                    })
            }
        }
    }, [state.selectedProvider, dispatch, propProjAccounts, handleInputChange]);

    useEffect(() => {
        if(state.selectedProvider) {
			let params = {};
            params.provider = state.selectedProvider.toLowerCase();
            params.aggregate_by = 'region';
            let label = state.selectedProvider
            if(state.selectedAccount) {
                label = state.selectedProvider+'_'+state.selectedAccount
                params.account_id = state.selectedAccount
            }

            if(propProjRegions && propProjRegions.hasOwnProperty(label)) {
                setState(prevState => ({ ...prevState, regions: propProjRegions[label] }));
            } else {
                dispatch(listClusterFilters(params))
                    .then((response) => {
                        if(response && response.length) {
							let obj = propProjRegions ? propProjRegions : {}
							obj[label] = response
							dispatch(setCommonPropsDetails('propProjRegions', obj))
							setState(prevState => ({ ...prevState, regions: response }));
						}
                    })
            }
        }
    }, [state.selectedProvider, state.selectedAccount, dispatch, propProjRegions]);

	useEffect(() => {
		if(receivedData && Object.entries(receivedData).length) {
			setState(prevState => ({ 
				...prevState,
				selectedData: receivedData.selectedData ? receivedData.selectedData : '',
				pageType: receivedData.pageType ? receivedData.pageType : '',
				selectedprovider: receivedData.selectedData && receivedData.selectedData.provider ? receivedData.selectedData.provider : '',
				selectedAccount: receivedData.selectedData && receivedData.selectedData.account_id ? receivedData.selectedData.account_id : '',
				selectedRegion: receivedData.selectedData && receivedData.selectedData.region ? receivedData.selectedData.region : '',
				cluster_name: receivedData.selectedData && receivedData.selectedData.cluster_name ? receivedData.selectedData.cluster_name : '',
				description: receivedData.selectedData && receivedData.selectedData.description ? receivedData.selectedData.description : ''
			}))
		} else {
			setState(prevState => ({ ...prevState, pageType: 'Create' }))
		}
	}, [receivedData, dispatch])

	useEffect(() => {
        if (state.selectedProvider) {
            let params = {};
            params.provider = state.selectedProvider.toLowerCase();
            if(state.selectedAccount) {
                params.account_id = state.selectedAccount
            }
            if(state.selectedRegion) {
                params.region = state.selectedRegion
            }
			params.aggregate_by = 'cluster_name'
            dispatch(listClusterFilters(params))
                .then((response) => {
                    if(response) {
                        setState(prevState => ({ ...prevState, clusters: response }));
                    }
            })
        }
    }, [dispatch, state.selectedProvider, state.selectedAccount, state.selectedRegion])
	
	const onFinish = () => {
		let hasError = false
		let data = qosCreateEditInputs

		if (!data.provider || !data.account_id || !data.region || !data.cluster_name) {
			hasError = true
		}

		setState(prevState => ({ ...prevState, hasError, saveLoading: hasError ? false : true }))

		if(!hasError) {
			if(!state.pageType || state.pageType !== 'edit') {
				setState(prevState => ({ ...prevState, callCreate: true, saveLoading: true }))
			} else {
				setState(prevState => ({ ...prevState, callUpdate: true, saveLoading: true }))
			}
		}
	}

	useEffect(() => {
		if(state.callCreate) {
			setState(prevState => ({ ...prevState, callCreate: false }))
			let params = qosCreateEditInputs
			
			dispatch(qosCreateAccout(params))
				.then((response) => {
					if(response) {
						let messageType = 'danger'
                        let message = response.message ? response.message : 'Error in saving Qos'
                        if(response.status) {
                            messageType = 'success'
                            message = response.message ? response.message : 'Qos saved successfully'
							dispatch(setHpcPropsDetails('qosCreateEditInputs', {}))
							navigate(URL_PATH.MANAGE_QOS, { state: { selectedScreen: 'Group' } });
                        } else {
							setState(prevState => ({ ...prevState, saveLoading: false }))
						}
						CommonNotification.addNotification({
							//title: 'Wonderful!',
							message: message,
							type: messageType,
							insert: 'top',
							container: 'top-center',
							// animationIn: ['animate__animated', 'animate__fadeIn'],
							// animationOut: ['animate__animated', 'animate__fadeOut'],
							dismiss: {
								duration: 5000,
								onScreen: false,
								pauseOnHover: true,
								showIcon: true,
							}
						});

						if(response.status) {
							setState(prevState => ({ ...prevState, disableFinish: false, finishBtn: 'Save', queryLoading: false }))	
						} else {
							setState(prevState => ({ ...prevState, showLoading: false, finishBtn: 'Saved' }))
						}
					} else {
						setState(prevState => ({ ...prevState, disableFinish: false, finishBtn: 'Save', queryLoading: false }))
					}
				})
		}

	}, [state.callCreate, qosCreateEditInputs, dispatch, navigate])

	// To update the state
	const updateLocationState = useCallback(() => {
		if(state.selectedData && Object.entries(state.selectedData).length) {
			let params = {}
			params.provider = state.selectedData.provider
			params.account_id = state.selectedData.account_id
			params.region = state.selectedData.region
			params.cluster_name = state.selectedData.cluster_name
			params.account = state.selectedData.account
			dispatch(getAccountPolicy(params))
				.then((response) => {
					if(response && !response.error) {
						navigate(URL_PATH.CREATE_GROUP, { 
							state: {
								selectedData: response.account_policy ? response.account_policy : {},
								pageType: 'edit',
							}
						})
					}
				})
		}
	}, [state.selectedData, dispatch, navigate])

	useEffect(() => {
		if(state.callUpdate) {
			setState(prevState => ({ ...prevState, callUpdate: false }))
			let params = qosCreateEditInputs
			delete params.user_name;

			dispatch(qosUpdateAccount(params))
				.then((response) => {
					if (response) {
						let messageType = 'danger'
						let message = response.message ? response.message : 'Error in updating Qos'
						if(response.status) {
							messageType = 'success'
							message = response.message ? response.message : 'Qos updated successfully'
							setTimeout(() => { updateLocationState() }, 500); 
						}
						setState(prevState => ({ ...prevState, saveLoading: false }))
						CommonNotification.addNotification({
							//title: "Wonderful!",
							message: message,
							type: messageType,
							insert: 'top',
							container: 'top-center',
							// animationIn: ["animate__animated", "animate__fadeIn"],
							// animationOut: ["animate__animated", "animate__fadeOut"],
							dismiss: {
								duration: 5000,
								onScreen: false,
								pauseOnHover: true,
								showIcon: true,
							},
						})
						
						if(response.status) {
							setState(prevState => ({ ...prevState, disableFinish: false, finishBtn: 'Save', queryLoading: false }))	
						} else {
							setState(prevState => ({ ...prevState, showLoading: false, finishBtn: 'Saved' }))
						}
					} else {
						setState(prevState => ({ ...prevState, disableFinish: false, finishBtn: 'Save', queryLoading: false }))
					}
				})
		}

	}, [state.callUpdate, qosCreateEditInputs, dispatch, updateLocationState])

	const handleChildClick = (event, type, dropdownType, section) => {	
		event.stopPropagation();

		let clickedChild = []
		if(type === 'child') {
            if(state.clickedChild && state.clickedChild.length) {
                state.clickedChild.forEach(row => {
                    setState(prevState => ({ ...prevState, [row]: false }))
                })
            }
            clickedChild.push(section)
            setState(prevState => ({ ...prevState, [section]: dropdownType === "singleDropDown" && state[section] ? false : true, clickedChild }))
        } else if(type === 'parent') {
            if(state.clickedChild && state.clickedChild.length) {
                state.clickedChild.forEach(row => {
                    setState(prevState => ({ ...prevState, [row]: false }))
                })
            }
            
            setState(prevState => ({ ...prevState, clickedChild }))
        }
	}

	return (
		<div onClick={(event) => handleChildClick(event, 'parent')}>
			<div className={`${state.showLoading ? '' : 'hidden'}`}>
                <div className='loading-wave'>
                    <div className='overlayEqualizerLoader'>
                        <div className="loading-bar"></div>
                        <div className="loading-bar"></div>
                        <div className="loading-bar"></div>
                        <div className="loading-bar"></div>
                    </div>
                </div>
            </div>
			<div className="bg-nero w-full h-fit relative stickyUiChange">
                <div className="grid grid-cols-12 items-center pb-4">
                    <div className="xl:col-span-3 col-span-12 self-center ">
                        <p className="text-white mx-4 text-xl md:mt-0 mt-4 font-semibold mb-0">{state.pageType === 'edit' ? 'Modify' : 'Create'} Group</p>
					</div>
				</div>
			</div>
			
			<div className='p-2 min-h-screen overflow-auto'>
                <div className='rounded-md bg-dark p-3 mb-2'>
                    <div className={`flex flex-wrap`}> 
						<div className='lg:w-1/5 md:w-1/2 w-full'>
							<div className='flex justify-between'>
								<p className='b-block mb-0'>Group</p>
								{state.hasError && (qosCreateEditInputs && !qosCreateEditInputs.account) ? (
									<small className='text-issues'>required</small>
								) : null}
							</div>
							<input
								type='text'
								placeholder='Enter Group'
								className={`border border-lightGray rounded-md bg-transparent px-2 py-1 w-full ${state.pageType !== 'edit' ? 'disabled text-lightGray' : 'text-white'}`}
								value={
									qosCreateEditInputs && qosCreateEditInputs.account
										? qosCreateEditInputs.account
										: ''
								}
								onChange={e => {
									if(state.pageType !== 'edit') {
										setState(prevState => ({ ...prevState, account: noSpace(e.target.value)}))
										handleInputChange('account', noSpace(e.target.value))
									}
								}}
							/>
						</div>
						<div className='lg:w-1/5 md:w-1/2 w-full lg:pl-3 md:pl-3'>
							<p className='b-block mb-0'>Description</p>
							<textarea 
								id="comment" 
								name="comment" 
								rows="4" 
								className={`border border-lightGray rounded-md bg-transparent px-2 py-1 w-full text-white`}
								onChange={e => {
									handleInputChange('description', e.target.value)
									setState(prevState => ({ ...prevState, description: e.target.value }))
								}}
								value={state.description}
								placeholder='Enter description'
							/>
						</div>
					</div>
					<div className={`flex flex-wrap mt-3`}>
						<div className='lg:w-1/5 md:w-1/2 w-full'>
							<div className='flex justify-between'>
								<p className='self-center m-0'>Provider </p>
								{state.hasError && (qosCreateEditInputs && !qosCreateEditInputs.provider) ? 
									<small className='text-issues'>required</small>
								: null}
							</div>
							<div onClick={(event) => {
									if(!state.isProviderOpen) {
										event.preventDefault();
										handleChildClick(event, 'child', 'singleDropDown', "isProviderOpen")
									}
								}}
							>
								<p className={`flex bg-transparent border border-lightGray rounded-md px-2 py-1 justify-between px-2 mb-2 truncate ${qosCreateEditInputs && qosCreateEditInputs.provider ? 'text-white' : 'text-lightGray'}`}>
									{qosCreateEditInputs && qosCreateEditInputs.provider ? capitalizeAllLetter(qosCreateEditInputs.provider) : 'Select'}
									{state.pageType === 'Create' ? 
										<Icon icon="icon-park-solid:down-one" className={`${qosCreateEditInputs.provider ? "text-white" : "text-lightGray"} self-center`} width="16" height="16" />
									: null}
								</p>
								{state.isProviderOpen && state.pageType !== 'edit' ?
									<MultiSelectSection
										fields={["provider_name", "provider_name"]}
										options={state.providers}
										selectedValues={state.selectedProvider ? state.selectedProvider : ''}
										callbackMultiSelect={(value) => {
											if(!value || typeof(value) === 'string') {
												setState(prevState => ({  ...prevState,  selectedProvider: value, selectedAccount: '', selectedRegion: '', cluster_name: '' }))
												handleInputChange('provider', value.toLowerCase())
												handleInputChange('account_id', '')
												handleInputChange('region', '')
												handleInputChange('cluste_name', '')
											} else {
												value.preventDefault()
												handleChildClick(value, "search", 'singleDropDown', "")
											}
										}}
										singleSelection={true}
										hideSearch={false}
										topClass={'auto'}
										widthClass={'minWidth220'}
										removeTopOptions={true}
									/>
								: null}
							</div>
						</div>
						<div className='lg:w-1/5 md:w-1/2 w-full lg:pl-3 md:pl-3 pl-0'>
							<div className='flex justify-between'>
								<p className='self-center m-0'>Account </p>
								{state.hasError && qosCreateEditInputs && !qosCreateEditInputs.account_id ?  
									<small className='text-issues'>required</small>
								: null}
							</div>
							<div onClick={(event) => {
									if(!state.isAccountOpen) {
										event.preventDefault();
										handleChildClick(event, 'child', 'singleDropDown', "isAccountOpen")
									}
								}}
							>
								<p className={`flex bg-transparent border border-lightGray rounded-md px-2 py-1 justify-between px-2 mb-2 truncate ${qosCreateEditInputs && qosCreateEditInputs.account_id && qosCreateEditInputs.account_id.length ? 'text-white' : 'text-lightGray'}`}>
									{qosCreateEditInputs && qosCreateEditInputs.account_id ? getAccountNameFromId(qosCreateEditInputs.account_id, state.accounts) : 'Select'}
									{state.pageType === 'Create' ? 
										<Icon icon="icon-park-solid:down-one" className={`${qosCreateEditInputs.account_id ? "text-white" : "text-lightGray"} self-center`} width="16" height="16" />
									: null}
								</p>
								{state.isAccountOpen && state.pageType !== 'edit' ?
									<MultiSelectSection
										fields={["account_id", "account_name"]}
										options={state.accounts}
										selectedValues={state.selectedAccount ? state.selectedAccount : []}
										callbackMultiSelect={(value) => {
											if(!value || typeof(value) === 'string') {
												setState(prevState => ({  ...prevState, selectedAccount: value, cluster_name: '' }))
												handleInputChange('account_id', value)
												handleInputChange('cluster_name', '')
											} else {
												value.preventDefault()
												handleChildClick(value, "search", 'singleDropDown', "")
											}
										}}
										singleSelection={true}
										hideSearch={false}
										topClass={'auto'}
										widthClass={'minWidth220'}
										removeTopOptions={true}
									/>
								: null}
							</div>
						</div>
						<div className='lg:w-1/5 md:w-1/2 w-full lg:pl-3 md:pl-0 pl-0'>
							<div className='flex justify-between'>
								<p className='self-center m-0'>Region </p>
								{state.hasError && qosCreateEditInputs && !qosCreateEditInputs.region ? 
									<small className='text-issues'>required</small>
								: null}
							</div>
							<div onClick={(event) => {
									if(!state.isRegionOpen) {
										event.preventDefault();
										handleChildClick(event, 'child', 'singleDropDown', "isRegionOpen")
									}
								}}
							>
								<p className={`flex bg-transparent border border-lightGray rounded-md px-2 py-1 justify-between px-2 mb-2 truncate ${qosCreateEditInputs && qosCreateEditInputs.region ? 'text-white' : 'text-lightGray'}`}>
									{qosCreateEditInputs && qosCreateEditInputs.region ? getRegionName(qosCreateEditInputs.region, state.regions) : 'Select'}
									{state.pageType === 'Create' ? 
										<Icon icon="icon-park-solid:down-one" className={`${qosCreateEditInputs.region ? "text-white" : "text-lightGray"} self-center`} width="16" height="16" />
									: null}
								</p>
								{state.isRegionOpen && state.pageType !== 'edit' ?
									<MultiSelectSection
										fields={["region", "name"]}
										options={state.regions}
										selectedValues={state.selectedRegion ? state.selectedRegion : []}
										callbackMultiSelect={(value) => {
											if(!value || typeof(value) === 'string') {
												setState(prevState => ({ ...prevState, selectedRegion: value, cluster_name: '' }))
												handleInputChange('region', value)
												handleInputChange('cluster_name', '')
											} else {
												value.preventDefault()
												handleChildClick(value, "search", 'singleDropDown', "")
											}
										}}
										singleSelection={true}
										hideSearch={false}
										topClass={'auto'}
										widthClass={'minWidth220'}
										removeTopOptions={true}
									/>
								: null}
							</div>
						</div>
						<div className='lg:w-1/5 md:w-1/2 w-full lg:pl-3 md:pl-3 pl-0'>
							<div className='flex justify-between'>
								<p className='self-center m-0'>Cluster Name </p>
								{state.hasError && qosCreateEditInputs && !qosCreateEditInputs.cluster_name ? 
									<small className='text-issues'>required</small>
								: null}
							</div>
							<div onClick={(event) => {
									if(!state.isClusterOpen) {
										event.preventDefault();
										handleChildClick(event, 'child', 'singleDropDown', "isClusterOpen")
									}
								}}
							>
								<p className={`flex bg-transparent border border-lightGray rounded-md px-2 py-1 justify-between px-2 mb-2 truncate ${qosCreateEditInputs && qosCreateEditInputs.cluster_name ? 'text-white' : 'text-lightGray'}`}>
									{qosCreateEditInputs && qosCreateEditInputs.cluster_name ? qosCreateEditInputs.cluster_name : 'Select'}
									{state.pageType === 'Create' ? 
										<Icon icon="icon-park-solid:down-one" className={`${qosCreateEditInputs.cluster_name ? "text-white" : "text-lightGray"} self-center`} width="16" height="16" />
									: null}
								</p>
								{state.isClusterOpen && state.pageType !== 'edit' ?
									<MultiSelectSection
										// fields={["region", "name"]}
										options={state.clusters}
										selectedValues={state.cluster_name ? state.cluster_name : []}
										callbackMultiSelect={(value) => {
											if(!value || typeof(value) === 'string') {
												setState(prevState => ({ ...prevState, cluster_name: '' }))
												handleInputChange('cluster_name', value)
											} else {
												value.preventDefault()
												handleChildClick(value, "search", 'singleDropDown', "")
											}
										}}
										singleSelection={true}
										hideSearch={false}
										topClass={'auto'}
										widthClass={'minWidth220'}
										removeTopOptions={true}
									/>
								: null}
							</div>
						</div>
					</div>
				</div>
							
				<div className='rounded-md bg-dark p-3 mt-3'>
					<GroupAssocationDetails 
						onChildPageClick={(event) => handleChildClick(event, 'parent')}
					/>
				</div>
			</div>
							
			<div className='flex justify-end mr-2 my-2 text-white'>
				{state.hasError ?
					<p  className='mb-0 text-danger self-center mr-3'>Please fill all required fields.</p>
				: null}
				{state.pageType !== 'View' ?
					state.saveLoading ?
						<button className={`flex bg-info rounded-md mr-2 px-2 py-1 text-lg`}>
							<svg className="animate-spin h-5 w-5 mr-3 text-white self-center" fill="currentColor" viewBox="0 0 24 24">
								<circle cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="2" fill="none" />
								<path d="M4 12a8 8 0 0112-6.9" />
							</svg>
							{state.pageType === 'edit' ? 'Updating' : 'Saving'}
						</button>
					:
						<button className={`flex justify-end cursor-pointer btn bg-info rounded-md px-2 py-1 text-lg`} onClick={ () => onFinish()}>{state.pageType === 'edit' ? 'Update' : 'Save'}</button>
				: null}
				<button className='mb-0 bg-lightGray px-2 py-1 rounded-md ml-1 text-lg'
					onClick={() => {
						navigate(URL_PATH.MANAGE_QOS, { state: { selectedManage: 'Group' } });
					}}
				>Cancel</button>
			</div>
		</div>
	)
}

export default CreateUpdateGroup
