/*************************************************
 * Collider
 * @exports
 * @file ViewAssetDeailSidePanel.js
 * @author Prakash // on 18/01/2024
 * @copyright © 2024 Collider. All rights reserved.
 *************************************************/
import React, { useEffect, useState, useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { removeUnderScore } from '../../../utils/utility'
import { getAssetDetailExt } from '../../../actions/assets/assetsActionNew'
import {Icon} from "@iconify/react";

const KeyValueDisplay = ({ data }) => {
	const renderKeyValue = (obj) => {
	  return Object.entries(obj).map(([key, value]) => (
			key !== 'tags' ?
			<div className='mb-1 my-2 pl-1' key={key}>
				{typeof value === 'string' ?
					<div className='flex'>
						<span className='text-lightGray mr-2'>{key}</span>
						<span className='mr-2'>:</span>
						<span className='text-white '>{value}</span>
					</div>
				: typeof value === 'object' ? 
					Array.isArray(value) ?
						<div className='mb-2 ml-2'>
							<p className='text-info mb-2 text-md'>{removeUnderScore(key)}</p>
							{/* {console.log(key, typeof value,  value)} */}
							{value.map(row => (
								<React.Fragment>
								{typeof row === 'string' ?
									<div className='flex'>
										<span className='ml-2 text-white text-lg '>{row}</span>
									</div>
								: Array.isArray(row) && row.every(element => typeof element === 'string') ?
									row.map(e => (
										<div className='flex'>
											<span className='mr-2 text-white'>{e}</span>
										</div>
									))
								: Array.isArray(row) && row.every(element => typeof element === 'object' && element !== null) ?
									<React.Fragment>
									{row.map(e => (
										<React.Fragment>
										{/* {console.log('e', e)} */}
										<KeyValueDisplay 
											data={e}
										/>
										</React.Fragment>
									))}
									</React.Fragment>
								: typeof row === 'object' ?
									<KeyValueDisplay data={row} />
								: null}
								</React.Fragment>
							))}
						</div>
					: 	
						<div className='mb-2'>
							<p className='text-info mb-2 f16'>{removeUnderScore(key)}</p>
							<KeyValueDisplay data={value} />
						</div>
				: 
					<div className='flex'>
						<span className='mr-2 text-white'>{key}</span>
						<span className='mr-2'>:</span>
						<span className=' text-white'>{value}</span>
					</div>
				}
			</div>
		: null
	  ));
	};
  
	return (
		data ?
			<div>
				{renderKeyValue(data)}
			</div>
		: null
	);
};

const ViewAssetDeailSidePanel = (props) => {

    // const accounts = useSelector(state => state.filters.accounts);
    // const regions = useSelector(state => state.filters.regions);

    const [state, setState] = useState({
        showLoading: true,
        summaryLoader: true,
        selectedTab: 'Details',
		callselectedAsset: true
    })

	const dispatch = useDispatch();

    const handleKeyDown = useCallback((event) => {
        if (event.keyCode === 27) {
            props.closeSidePanel()
        }
    }, [props]);

    useEffect(() => {
        document.addEventListener('keydown', handleKeyDown);
    }, [handleKeyDown]);

	

    useEffect(() => {
        if (state.callselectedAsset) {
			setState(prevState => ({ ...prevState, callSelectedAsset: false }))
            let params = {}
            params.provider = props.selectedAsset.provider
            params.service_name = props.selectedAsset.service_name
            params.asset_id = props.selectedAsset.asset_id

            // Fetch job metrics data here using params and update the state
            dispatch(getAssetDetailExt(params))
                .then((response) => {
                    if(response && !response.error) {
                        let results = response.length && response[0] ? response[0] : {}
                        setState(prevState => ({
                            ...prevState,
                            assetDetails: results,
							showLoading: false
                        }));
                    } else {
						setState(prevState => ({
                            ...prevState,
                            assetDetails: {},
							showLoading: false
                        }));
					}
                })
        }
    }, [state.callselectedAsset, props, dispatch])

	// useEffect(() => {
	// 	if(Object.entries(state.assetDetails).length) {
	// 		Object.entries(state.assetDetails).forEach(([key, value], index) => {
	// 			if()
	// 		})
	// 	}
	// }, [state.assetDetails])

    return (
        <div className="advanced-search" style={{ zIndex: 9999999 }}>
            {/* <div className={`loaderOverlay ${state.showLoading ? "" : 'displayNone'}`}>
                <div className="overlayEqualizerLoader">
                    <div className="spinner-item"></div>
                    <div className="spinner-item"></div>
                    <div className="spinner-item"></div>
                    <div className="spinner-item"></div>
                    <div className="spinner-item"></div>
                </div>
            </div> */}
            <div className="search-content bg-muted w-3/4">
                <div className="header-search bd-highlight bg-black flex justify-between p-2">
                    <div className="flex-fill bd-highlight text-white">
                        <h5>Asset Details</h5>
                        <div className="mr-2 flex">
                        </div>
                    </div>

                    <div className="text-right flex-fill bd-highlight self-center">
						<Icon icon="jam:close" className='text-white' width="25" height="25" onClick={() => props.closeSidePanel()} />
                    </div>
                </div>
				{state.assetDetails ?
					<div className='m-2 bg-dark3 p-3 overflow-auto'>
						<KeyValueDisplay
							data={state.assetDetails}
						/>
					</div>
				: state.showLoading ?
					<div className='flex justify-center m-4'>
						<svg className="animate-spin h-5 w-5 mr-2 text-white" fill="currentColor" viewBox="0 0 24 24">
							<circle cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="2" fill="none" />
							<path d="M4 12a8 8 0 0112-6.9" />
						</svg>
					</div>
				: null}
            </div>
        </div>
    )
}

export default ViewAssetDeailSidePanel