/* eslint-disable no-mixed-spaces-and-tabs */
/*************************************************
 * Collider
 * @exports
 * @file ResourcesAdvancedSearch.js
 * @author Prakash // on 30/01/2023
 * @copyright © 2023 Collider. All rights reserved.
 *************************************************/
import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useDispatch } from 'react-redux'; // Import useDispatch hook to dispatch actions
import { listAllAccounts, listAllRegions, listAllServices, saveSearchMetaResults, getSearchMetaResults } from '../../../../actions/commonActionNew'

import { CUSTOM_CURRENT_DURATION_FILTER_OPTIONS } from '../../../../utils/constants'
import { Store as CommonNotification } from 'react-notifications-component';
// import { getDayFromSelectedDuration } from '../../../../utils/utility'

import _ from 'lodash'
import Search from '../../../common/SearchWithHiddenInput'
import SingleInputSection from './SingleInputSection'
import DurationFilterSection from './DurationFilterSection'
import IpAddressess from './IpAddressess'
import ResourcesInputSection from './ResourcesInputSection'
import ServicesInputSection from './ServicesInputSection'
// import AccessedBySection from './AccessedBySection'
import TagsSection from './TagsSection'
import InstanceTypeInputSection from './InstanceTypeInputSection'
import { getAccountNameFromId, getRegionName, momentConvertionUtcToLocalTime } from '../../../../utils/utility'

// import CustomTable from '../../../designComponents/Table/customTable'

import DropdownTreeSelect from 'react-dropdown-tree-select'
import 'react-dropdown-tree-select/dist/styles.css'
import {Icon} from "@iconify/react";
import MultiSelectSection from '../../../common/MultiSelectSection';
import ResizeableDarkThemeTable from '../../../designComponents/Table/ResizeableDarkThemeTable';

const defaultDropdown = [ 'Accessed By', 'Account', 'Category', 'Duration', 'Events', "Instance Type", 'IP Address', 'Networking', 'Region', "Tags", "Services"]; //, 'Resources' "Status", 

const ResourcesAdvancedSearch = (props) => {

	// const advanceSearchRef = useRef()
	const advanceMultiSearchRef = useRef()
	const servicesRef = useRef()

	// const providers = useSelector(state => state?.filters?.providers || false);
    // const propProjAccounts = useSelector(state => state?.filters?.commonPropsDetails?.propProjAccounts || false);
    // const propProjRegions = useSelector(state => state?.filters?.commonPropsDetails?.propProjRegions || false);

	const dispatch = useDispatch(); // Create a dispatch function

	const [state, setState] = useState({
		mainDropdown: defaultDropdown,
		searchText: '',
		isLoading: false,
		filteredServiceResourceArray: [],
	})
	
	const filterOnchange = (searchText) => {
		let filterData = _.orderBy(defaultDropdown, ['Asc'])
		if(searchText !== '') {
			filterData = filterData.filter(
				filter => 
				filter.toLocaleLowerCase().includes(state.searchText) ||
				filter.includes(state.searchText)
			)
		}
		setState(prevState => ({ ...prevState, mainDropdown: filterData }))
	}

	const handleKeyDown = useCallback((event) => {
        if (event.key === 'Escape' || event.keyCode === 27) {
            props.closeSidePanel();
        }
    }, [props])
    
    useEffect(() => {
        // Add event listener for keydown
        window.addEventListener('keydown', handleKeyDown);
    
        // Cleanup event listener on component unmount
        return() => {
          window.removeEventListener('keydown', handleKeyDown);
        };
    }, [handleKeyDown]);

	const onSelectOptions = (value) => {
		setState(prevState => ({ ...prevState, selectedOption: value, showMainDropdownOption: false, searchText: "", mainDropdown: defaultDropdown }))

		let selectedPage = ""
		let inputComponent = []
		if(value === "Account") {
			let options = []
			state.accounts.forEach(row => {
				let dataRow = {
					label: row.account_name,
					value: row.account_id
				}
				options.push(dataRow)
			})
			let inputRow = {
				label: value,
				component: "multiselect",
				value_list: [],
				options,
				field: "account"
			}
			inputComponent.push(inputRow)

			if(state.selectedFilters && state.selectedFilters.length) {
				let data = state.selectedFilters.filter(e => e.label === 'Account').map(e => e.value)
				setState(prevState => ({ ...prevState, selectedMultiInput: data }))
			}

		} else if(value === "Status") {
			let options = [
				{label: "Start", value: "start"},
				{label: "End", value: "end"},
				{label: "Running", value: "running"}
			]
			let inputRow = {
				label: value,
				component: "multiselect",
				value_list: [],
				options,
				field: "status"
			}
			inputComponent.push(inputRow)

			if(state.selectedFilters && state.selectedFilters.length) {
				let data = state.selectedFilters.filter(e => e.label === 'Status').map(e => e.value)
				setState(prevState => ({ ...prevState, selectedMultiInput: data }))
			}
		} else if(value === "Region") {
			let options = []
			state.regions.forEach(row => {
				let dataRow = {
					label: row.name,
					value: row.region
				}
				options.push(dataRow)
			})
			let inputRow = {
				label: value,
				component: "multiselect",
				value_list: [],
				options,
				field: "region"
			}
			inputComponent.push(inputRow)

			if(state.selectedFilters && state.selectedFilters.length) {
				let data = state.selectedFilters.filter(e => e.label === 'Region').map(e => e.value)
				setState(prevState => ({ ...prevState, selectedMultiInput: data }))
			}
		} else if(value === "Category") {
			let options = []

			// let dataRow = {
			// 	label: "All",
			// 	value: "All"
			// }
			// options.push(dataRow)

			state.categoryCounts.forEach(row => {
				let dataRow = {}
				dataRow.label = row.category
				dataRow.value = row.category
				options.push(dataRow)
			})
			let inputRow = {
				label: value,
				component: "multiselect",
				value_list: [],
				options,
				field: "category"
			}
			inputComponent.push(inputRow)

			if(state.selectedFilters && state.selectedFilters.length) {
				let data = state.selectedFilters.filter(e => e.label === 'Category').map(e => e.value)
				setState(prevState => ({ ...prevState, selectedMultiInput: data }))
			}
		} else if(value === "Duration") {
			let options = []
			CUSTOM_CURRENT_DURATION_FILTER_OPTIONS.forEach(row => {
				let dataRow = {}
				dataRow.label = row.option
				dataRow.value = row.value
				options.push(dataRow)
			})
			let inputRow = {
				label: value,
				component: "Custom_Duration",
				value: "",
				options,
				field: "duration"
			}
			inputComponent.push(inputRow)
		} else if(value === "IP Address") {
			let options = [
				{label: "Include", value: "include"},
				{label: "Exclude", value: "exclude"}
			]

			let inputRow = {
				label: value,
				component: "select",
				options,
				field: "type",
				type: "value",
				step: 1,
				default: "include"
			}

			inputComponent.push(inputRow)

			inputRow = {
				label: "ipv4, ipv6 etc...",
				component: "multipleAddTextBox",
				field: "ids",
				type: "value_list",
				step: 1
			}

			inputComponent.push(inputRow)

			selectedPage = "selectTextList"
		} else if(value === "Events") { //EventSection
			let options = [
				{label: "Include", value: "include"},
				{label: "Exclude", value: "exclude"}
			]

			let inputRow = {
				label: value,
				component: "select",
				options,
				field: "type",
				type: "value",
				step: 1,
				default: "include"
			}

			inputComponent.push(inputRow)

			inputRow = {
				label: "enter event name",
				component: "multipleAddTextBox",
				field: "ids",
				type: "value_list",
				step: 1
			}

			inputComponent.push(inputRow)

			selectedPage = "selectTextList"
		} else if(value === "Networking") {
			let options = [
				{label: "Include", value: "include"},
				{label: "Exclude", value: "exclude"}
			]

			let inputRow = {
				label: value,
				component: "select",
				options,
				field: "type",
				type: "value",
				step: 1,
				default: "include"
			}

			inputComponent.push(inputRow)

			inputRow = {
				label: "enter vpc id",
				component: "multipleAddTextBox",
				field: "ids",
				type: "value_list",
				step: 1
			}

			inputComponent.push(inputRow)

			selectedPage = "selectTextList"
		} else if(value === "Resources") {
			let options = [
				{label: "Include", value: "include"},
				{label: "Exclude", value: "exclude"}
			]

			let inputRow = {
				label: "",
				component: "select",
				options,
				field: "type",
				type: "value",
				default: "include"
			}

			inputComponent.push(inputRow)

			inputRow = {
				label: value,
				component: "select",
				options: state.serviceResourceType,
				field: "resources",
				type: "value",
			}

			inputComponent.push(inputRow)

			inputRow = {
				label: "enter asset name",
				component: "multipleAddTextBox",
				field: "ids",
				type: "value_list",
				step: 1
			}

			inputComponent.push(inputRow)

			selectedPage = "Resources"
		} else if(value === "Services") {
			selectedPage = "Services"
		} else if(value === "Accessed By") {
			let inputRow = {
				label: "Enter user name",
				component: "multipleAddTextBox",
				field: "ids",
				type: "value_list",
				step: 1
			}

			inputComponent.push(inputRow)

			selectedPage = "selectTextList"
		} else if(value === "Tags") {
			selectedPage = "Tags"
		} else if(value === "Instance Type") {
			let options = [
				{label: "Include", value: "include"},
				{label: "Exclude", value: "exclude"}
			]

			let inputRow = {
				label: value,
				component: "select",
				options,
				field: "type",
				type: "value",
				step: 1,
				default: "include"
			}

			inputComponent.push(inputRow)

			inputRow = {
				label: "mx-large, t3-small etc...",
				component: "multipleAddTextBox",
				field: "ids",
				type: "value_list",
				step: 1
			}

			inputComponent.push(inputRow)

			selectedPage = "selectTextList"
		} else if(value === "Instance Type1") {
			selectedPage = "InstanceType"
		}
		
		setState(prevState => ({ ...prevState, inputComponent, selectedPage }))
	}

	const updateMultiSelectedOptions = (value, inputs, mindex) => {
		let data = state.selectedFilters ? state.selectedFilters : []
		let selectedMultiOptions = state.selectedMultiOptions ? state.selectedMultiOptions : []

		let valueLabel = value
		if(inputs.label === "account_id") {
			valueLabel = getAccountNameFromId(value, state.accounts)
		} else if(inputs.label === "region") {
			valueLabel = getRegionName(value, state.regions)
		}
		if(!data.filter(e => e.label === inputs.label && e.value === value).length) {
			let dataRow = {
				label: inputs.label,
				value: value,
				userView: inputs.label+" "+ valueLabel
			}
			data.push(dataRow)
		} else {
			let otherFilters = data.filter(e => e.label !== inputs.label)
			data = data.filter(e => e.label === inputs.label && e.value !== value)

			data = [...otherFilters, ...data]
		}

		setState(prevState => ({ ...prevState, selectedFilters: data, selectedMultiOptions, selectedOption: "" }))
	}

	const updateMultiSelectedOptionsNew = (value, inputs) => {
		let data = state.selectedFilters ? state.selectedFilters : []

		let newData = []
		let otherFilters = data.filter(e => e.label !== inputs.label)
		value.forEach(row => {
			let valueLabel = row
			if(inputs.label === "Account") {
				valueLabel = getAccountNameFromId(row, state.accounts)
			} else if(inputs.label === "Region") {
				valueLabel = getRegionName(row, state.regions)
			}
			
			let dataRow = {
				label: inputs.label,
				value: row,
				userView: inputs.label+" "+ valueLabel
			}
			newData.push(dataRow)
		})

		newData = [...otherFilters, ...newData]

		if(state.selectedMultiInput && state.selectedMultiInput.length) {
			setState(prevState => ({ ...prevState, selectedMultiInput: state.selectedMultiInput.filter(e => value.includes(e.value))}))
		}

		setState(prevState => ({ ...prevState, selectedFilters: newData }))
	}

	const updateSelectedFilter = (row, inputs, options, mindex) => {
		let data = state.selectedFilters ? state.selectedFilters : []

		let dataRow = {
			label: inputs.label,
			value: row,
			userView: inputs.label+" "+row
		}
		data.push(dataRow)

		setState(prevState => ({ ...prevState, selectedFilters: data, selectedOption: ""  }))
	}
	
	const selectedDurationFunction = (row) => {
		let data = state.selectedFilters ? state.selectedFilters : []
		if(row.selectedDuration === "custom") {
			let dataRow = {}
			dataRow.label = 'Duration'
			dataRow.start_time = row.start_time
			dataRow.end_time = row.end_time
			dataRow.userView = 'Duration from '+ row.from+' to '+row.to
			data.push(dataRow)
		} else {
			let dataRow = {}
			dataRow.label = 'Duration'
			dataRow.value = row.selectedDuration
			dataRow.userView = 'Duration '+row.selectedDuration
			data.push(dataRow)
		}

		setState(prevState => ({ ...prevState, selectedFilters: data, inputComponent: [], selectedOption: ""  }))
	}

	const selectedTextListDetails = (selectedOptions, result) => {
		let data = state.selectedFilters ? state.selectedFilters : []
		
		let filteredData = data.filter(e => e.label !== selectedOptions)
		
		if(result && result.length) {
			data = [...filteredData, ...result]
		}

		setState(prevState => ({ ...prevState, selectedFilters: data, selectedOption: "", selectedPage: "" }))
	}

	useEffect(() => {
		let data = []
		if(Array.isArray(state.selectedService) && state.selectedService.length) {
			let result = []
			state.selectedService.forEach(row => {
				let dataRow = {
					label: "Services",
					value: row,
					userView: "Services : "+row
				}
				result.push(dataRow)
			})
	
			data = state.selectedFilters ? state.selectedFilters : []
	
			
			let filteredData = data 
			filteredData = data.filter(e => e.label !== 'Services')
			
			if(result && result.length) {
				data = [...filteredData, ...result]
			}
		}

		setState(prevState => ({ ...prevState, selectedFilters: data }))
	}, [state.selectedService, state.selectedFilters])

	const removeFilterData = (row) => {
		// console.log(state.selectedFilters.filter(e => e.label === row.label))
		// console.log(state.selectedFilters.filter(e => e.value === row.value))
		if(row.label === 'Region') {
			setState(prevState => ({ ...prevState, selectedMultiInput: state.selectedMultiInput.filter(e => e !== row.value)}))
		}
		let selectedFilters = state.selectedFilters.filter(e => !(e.label === row.label && e.value === row.value));
		setState(prevState => ({ ...prevState, selectedFilters }))
	}

	const onSearch = () => {
		if(state.selectedFilters && state.selectedFilters.length) {
			let params = {}
			params.provider = state.selectedProvider.toLowerCase()
			if(state.selectedFilters.filter(e => e.label === "Account").length) {
				let data = []
				state.selectedFilters.filter(e => e.label === "Account").forEach(row => {
					data.push(row.value)
				})
				params.account_id = data
			}
			if(state.selectedFilters.filter(e => e.label === "Region").length) {
				let data = []
				state.selectedFilters.filter(e => e.label === "Region").forEach(row => {
					data.push(row.value)
				})
				params.region = data
			}
			if(state.selectedFilters.filter(e => e.label === "Category").length) {
				let data = []
				state.selectedFilters.filter(e => e.label === "Category").forEach(row => {
					data.push(row.value)
				})
				params.category = data
			}
			if(state.selectedFilters.filter(e => e.label === "Duration").length) {
				state.selectedFilters.filter(e => e.label === "Duration").forEach(row => {
					if(row.value) {
						params.duration = row.value
					} else {
						params.start_time = row.start_time
						params.end_time = row.end_time
					}
				})
			}
			if(state.selectedFilters.filter(e => e.label === "Events").length) {
				let groupData = _.groupBy(state.selectedFilters.filter(e => e.label === "Events"), 'type')
				let dataRow = {}
				Object.entries(groupData).forEach(([key, gValue]) => {
					let gData = []
					gValue.forEach(item => {
						gData.push(item.value)
					})
					dataRow[key] = gData
				})
				
				params.event_name = dataRow
			}
			if(state.selectedFilters.filter(e => e.label === "IP Address").length) {
				let groupData = _.groupBy(state.selectedFilters.filter(e => e.label === "IP Address"), 'type')

				let dataRow = {}
				Object.entries(groupData).forEach(([key, gValue]) => {
					let gData = []
					gValue.forEach(item => {
						gData.push(item.value)
					})
					dataRow[key] = gData
				})
				
				params.ip = dataRow
			}
			if(state.selectedFilters.filter(e => e.label === "Networking").length) {
				let groupData = _.groupBy(state.selectedFilters.filter(e => e.label === "Networking"), 'type')

				let dataRow = {}
				Object.entries(groupData).forEach(([key, gValue]) => {
					let gData = []
					gValue.forEach(item => {
						gData.push(item.value)
					})
					dataRow[key] = gData
				})
				
				params.vpc_id = dataRow
			}
			if(state.selectedFilters.filter(e => e.label === "Resources").length) {
				let groupType = _.groupBy(state.selectedFilters.filter(e => e.label === "Resources"), 'type')
				let dataRow = {}
				Object.entries(groupType).forEach(([key, gValue]) => {
					
					let gData = []
					let groupResources = _.groupBy(gValue, 'resources')
					Object.entries(groupResources).forEach(([rKey, rValue]) => {
						let rData = []
						rValue.forEach(item => {
							rData.push(item.value)
						})
						let groupRow = {}
						groupRow[rKey] = rData

						gData.push(groupRow)
					})
					
					dataRow[key] = gData
				})
				params.resources = dataRow
			}
			if(state.selectedFilters.filter(e => e.label === "Services").length) {
				let groupData = state.selectedFilters.filter(e => e.label === "Services")
				let gData = []
				groupData.forEach(row => {
					gData.push(row.value)
				})
				
				params.service_name = gData
			}
			if(state.selectedFilters.filter(e => e.label === "Tags").length) {
				let data = []
				state.selectedFilters.filter(e => e.label === "Tags").forEach(row => {
					let dataRow = {}
					dataRow.label = row.key
					dataRow.value = row.value
					data.push(dataRow)
				})				
				params.tags = data
			}
			if(state.selectedFilters.filter(e => e.label === "Instance Type").length) {

				let groupData = _.groupBy(state.selectedFilters.filter(e => e.label === "Instance Type"), 'service')
				let rData = []
				Object.entries(groupData).forEach(([key, gValue]) => {
					let value = []
					gValue.forEach(row => {
						value.push(row.value)
					})
					let rRow = {}
					rRow.resource_type = key
					rRow.instance_type = value

					rData.push(rRow)
				})
				
				params.instance_type = rData
			}
			if(state.selectedFilters.filter(e => e.label === "Accessed By").length) {
				let groupData = state.selectedFilters.filter(e => e.label === "Accessed By")
				let gData = []
				groupData.forEach(row => {
					gData.push(row.value)
				})
				params.users = gData
			}

			props.getFilteredList(params, "Advanced List")

		}
	}

	const onReset = () => {
		setState(prevState => ({ ...prevState, selectedFilters: [] }))
	}

	const saveFilterItem = () => {
        setState(prevState => ({ ...prevState, showSaveLoading: true, responseMessage: ''}))
        let hasError = false
        if(state.searchName === '') {
			hasError = true
        }

		let params = {}
		params.provider = state.selectedProvider.toLowerCase()
		params.title = state.searchName
		let parameters = {}
		if(state.selectedFilters && state.selectedFilters.length) {
			parameters.provider = state.selectedProvider.toLowerCase()
			if(state.selectedFilters.filter(e => e.label === "Account").length) {
				let data = []
				state.selectedFilters.filter(e => e.label === "Account").forEach(row => {
					data.push(row.value)
				})
				parameters.account_id = data
			}
			if(state.selectedFilters.filter(e => e.label === "Region").length) {
				let data = []
				state.selectedFilters.filter(e => e.label === "Region").forEach(row => {
					data.push(row.value)
				})
				parameters.region = data
			}
			if(state.selectedFilters.filter(e => e.label === "Category").length) {
				let data = []
				state.selectedFilters.filter(e => e.label === "Category").forEach(row => {
					data.push(row.value)
				})
				parameters.category = data
			}
			if(state.selectedFilters.filter(e => e.label === "Duration").length) {
				state.selectedFilters.filter(e => e.label === "Duration").forEach(row => {
					if(row.value) {
						parameters.duration = row.value
					} else {
						parameters.start_time = row.start_time
						parameters.end_time = row.end_time
					}
				})
			}
			if(state.selectedFilters.filter(e => e.label === "Events").length) {
				let groupData = _.groupBy(state.selectedFilters.filter(e => e.label === "Events"), 'type')
				let dataRow = {}
				Object.entries(groupData).forEach(([key, gValue]) => {
					let gData = []
					gValue.forEach(item => {
						gData.push(item.value)
					})
					dataRow[key] = gData
				})
				
				parameters.event_name = dataRow
			}
			if(state.selectedFilters.filter(e => e.label === "IP Address").length) {
				let groupData = _.groupBy(state.selectedFilters.filter(e => e.label === "IP Address"), 'type')

				let dataRow = {}
				Object.entries(groupData).forEach(([key, gValue]) => {
					let gData = []
					gValue.forEach(item => {
						gData.push(item.value)
					})
					dataRow[key] = gData
				})
				
				parameters.ip = dataRow
			}
			if(state.selectedFilters.filter(e => e.label === "Networking").length) {
				let groupData = _.groupBy(state.selectedFilters.filter(e => e.label === "Networking"), 'type')

				let dataRow = {}
				Object.entries(groupData).forEach(([key, gValue]) => {
					let gData = []
					gValue.forEach(item => {
						gData.push(item.value)
					})
					dataRow[key] = gData
				})
				
				parameters.vpc_id = dataRow
			}
			if(state.selectedFilters.filter(e => e.label === "Resources").length) {
				let groupType = _.groupBy(state.selectedFilters.filter(e => e.label === "Resources"), 'type')
				let dataRow = {}
				Object.entries(groupType).forEach(([key, gValue]) => {
					
					let gData = []
					let groupResources = _.groupBy(gValue, 'resources')
					Object.entries(groupResources).forEach(([rKey, rValue]) => {
						let rData = []
						rValue.forEach(item => {
							rData.push(item.value)
						})
						let groupRow = {}
						groupRow[rKey] = rData

						gData.push(groupRow)
					})
					
					dataRow[key] = gData
				})
				parameters.resources = dataRow
			}
			if(state.selectedFilters.filter(e => e.label === "Services").length) {
				let groupData = state.selectedFilters.filter(e => e.label === "Services")
				let gData = []
				groupData.forEach(row => {
					gData.push(row.value)
				})
				
				parameters.service_name = gData
			}
			if(state.selectedFilters.filter(e => e.label === "Tags").length) {
				let data = []
				state.selectedFilters.filter(e => e.label === "Tags").forEach(row => {
					let dataRow = {}
					dataRow.label = row.key
					dataRow.value = row.value
					data.push(dataRow)
				})				
				parameters.tags = data
			}
			if(state.selectedFilters.filter(e => e.label === "Instance Type").length) {

				let groupData = _.groupBy(state.selectedFilters.filter(e => e.label === "Instance Type"), 'service')
				let rData = []
				Object.entries(groupData).forEach(([key, gValue]) => {
					let value = []
					gValue.forEach(row => {
						value.push(row.value)
					})
					let rRow = {}
					rRow.resource_type = key
					rRow.instance_type = value

					rData.push(rRow)
				})
				
				parameters.instance_type = rData
			}
			if(state.selectedFilters.filter(e => e.label === "Accessed By").length) {
				let groupData = state.selectedFilters.filter(e => e.label === "Accessed By")
				let gData = []
				groupData.forEach(row => {
					gData.push(row.value)
				})
				parameters.users = gData
			}
		}
		params.parameters = parameters
		if(!hasError) {
			dispatch(saveSearchMetaResults(params))
				.then((response) => {
					if(response) {
						setState(prevState => ({ ...prevState, showSaveOption: false, showSaveLoading: false }))
	
						let messageType = 'danger'
						let message = response && response.message ? response.message : ""
	
						if(response && response.status) {
							messageType = 'success'
							setState(prevState => ({ ...prevState, selectedFilters: [], searchName: '', }))
						}
			
						// setState(prevState => ({ ...prevState, saveLoading: false, message, messageType, createErrorMessage }))
		
						CommonNotification.addNotification({
							//title: 'Wonderful!',
							message: message,
							type: messageType,
							insert: 'top',
							container: 'top-center',
							// animationIn: ['animate__animated', 'animate__fadeIn'],
							// animationOut: ['animate__animated', 'animate__fadeOut'],
							dismiss: {
								duration: 5000,
								onScreen: false,
								pauseOnHover: true,
								showIcon: true,
							}
						});
					}
				})
        }

		setState(prevState => ({ ...prevState, hasError }))
    }

	useEffect(() => {
		if(state.selectedProvider) {
			let mainDropdown = defaultDropdown
			if(state.selectedProvider.toLowerCase() === "gcp") {
				mainDropdown = defaultDropdown.filter(e => e !== "Instance Type")
			}

			setState(prevState => ({ ...prevState, mainDropdown }))



			let providerPramas = {}
			providerPramas.provider = state.selectedProvider.toLowerCase()
			
			dispatch(listAllAccounts(providerPramas))
				.then((accounts) => {
					if(accounts) {
						let allAccounts = []
						accounts.length && accounts.forEach((item, i) => {
							if(i === 0) {
								let accRow = {}
								accRow.account_id = "All"
								accRow.account_name = "All"
								accRow.date = null
								accRow.disabled = false
								accRow.provider = "All"
								allAccounts.push(accRow);
							}
			
							allAccounts.push(item);
						})
						if(state.selectedProvider) {
							setState(prevState => ({ ...prevState, accounts: accounts.filter(e => e.provider === state.selectedProvider.toLowerCase()), allAccounts: allAccounts.filter(e => e.provider === state.selectedProvider.toLowerCase()) }))
						} else {
							setState(prevState => ({ ...prevState, accounts, allAccounts }))
						}
					}
				})

			dispatch(listAllRegions(providerPramas))
				.then((regions) => {
					if(regions) {
						let providerRegions = regions[0] && regions[0].provider_regions
						if(providerRegions) {
							let provider_regions_row = {}
							provider_regions_row.availability_zones = []
							provider_regions_row.city = "All"
							provider_regions_row.country = "All"
							provider_regions_row.geography = "All"
							provider_regions_row.name = "All"
							provider_regions_row.region = "All"
							provider_regions_row.region_id = "All"
							provider_regions_row.state = "All"
							providerRegions.unshift(provider_regions_row);
						}
						if (regions) {
							setState(prevState => ({ ...prevState, regions: providerRegions, tableRowRegions: providerRegions }))
						} else {
							setState(prevState => ({ ...prevState, regions: [], tableRowRegions: [] }))
						}
					}
				})

			dispatch(listAllServices(providerPramas))
				.then((services) => {
					if(services) {
						let serviceResourceArray = []
						let serviceArray = []
						services && services.length &&
						_.chain(services)
						.groupBy('resource_type')
						.map((value, key) => ({ label: key, sub: value }))
						.value()
						.forEach((service, i) => {
							if(i === 0) {
								let dataRow = {}						
								dataRow.category = 'All'
								dataRow.default_region = 'All'
								dataRow.provider_name = 'All'
								dataRow.resource_type = 'All'
								dataRow.service_id = 'All'
								dataRow.service_name = 'All'
								serviceArray.push(dataRow)

								let serRow = {}
								serRow.label = 'All'
								serRow.value = 'All'
								serRow.expanded = true
								let serArray = []
								serRow.children = serArray
								serviceResourceArray.push(serRow)
							}
							
							let serRow = {}
							serRow.label = service.label
							serRow.value = service.label
							serRow.expanded = true
							let serArray = []
							//serviceResourceArray.push(service)
							service.sub.forEach((item, i) => {
								let serItem = {}
								serItem.label = item.service_name
								serItem.value = item.service_name
								serItem.expanded = true
								serArray.push(serItem)
								serviceArray.push(item)
							})
							serRow.children = serArray
							serviceResourceArray.push(serRow)
						})
						setState(prevState => ({ ...prevState, services, serviceArray, serviceResourceArray, filteredServiceResourceArray: serviceResourceArray  }))
					} else {
						setState(prevState => ({ ...prevState, services }))
					}
				})

			let saveParams = {}
			saveParams.provider = state.selectedProvider.toLowerCase()

			dispatch(getSearchMetaResults(providerPramas))
				.then((response) => {
					if (response) {
						setState(prevState => ({ ...prevState, savedFilters: response }))
					} else {
						setState(prevState => ({ ...prevState, savedFilters: [] }))
					}
				})
		}

	}, [state.selectedProvider, dispatch])

	const onChangeServiceDropdown  = (currentNode, selectedNodes) => {
		let filteredServiceResourceArray = []	
		let selectedTreeService = []
		let selectedService = []
		if(selectedNodes.length && currentNode.label !== 'All') {
			selectedNodes.forEach(item => {
				if(item._depth === 0) {
					let child = {}
					child.label = item.label
					child.parent = 1
					selectedTreeService.push(child)
					state.serviceResourceArray.forEach(service => {
						if(service.label === item.label) {
							service.children.forEach(child => {
								selectedService.push(child.label)
							})
						}
					})
				} else {
					let child = {}
					child.label = item.label
					child.parent = 0
					selectedTreeService.push(child)
					selectedService.push(item.label)
				}
			})

			filteredServiceResourceArray = []
			state.serviceResourceArray.forEach(item => {
				let dataRow = {}
				dataRow.label = item.label
				dataRow.expanded = true
				let childArray = []
				item.children.forEach(child => {
					let childRow = {}
					childRow.label = child.label
					childRow.value = child.label
					childRow.expanded = true
					if(selectedTreeService.length) {					
						selectedTreeService.forEach(ser => {
							if(ser.parent === 1 && item.label === ser.label) {
								dataRow.checked = true
							}						
							if(ser.parent === 1 && item.label === ser.label) {
								childRow.checked = true
							} else if(ser.label === child.label){
								childRow.checked = true
							}			
						})
					}
					childArray.push(childRow)
					dataRow.children = childArray
				})		
				
				if(item.label === 'All' && currentNode.label === 'All') {
					dataRow.checked = true
				}
	
				filteredServiceResourceArray.push(dataRow)
			})
		} else if(currentNode.label === 'All' || !selectedNodes.length) {
			selectedTreeService= []
			selectedService = []
			filteredServiceResourceArray = []
			state.serviceResourceArray.forEach(item => {
				let dataRow = {}
				dataRow.label = item.label
				dataRow.expanded = true
				if(currentNode.checked) {
					dataRow.checked = true
				} else if(!currentNode.checked) {
					dataRow.checked = false
				}
				let childArray = []
				item.children.forEach(child => {
					let childRow = {}
					childRow.label = child.label
					childRow.value = child.label
					childRow.expanded = true
					childRow.parent = 1
					if(currentNode.checked) {
						childRow.checked = true
						selectedService.push(childRow.value)
					} else if(!currentNode.checked) {
						childRow.checked = false
					}
					childArray.push(childRow)
					selectedTreeService.push(childRow)
					dataRow.children = childArray					
				})
				filteredServiceResourceArray.push(dataRow)
			})
		}	

		let selectedServiceContent = selectedService.length === 1 ? selectedService : selectedService.length > 2 ? selectedService.length +' Selected' : 'All'
		setState(prevState => ({ ...prevState, filteredServiceResourceArray, selectedService, selectedServiceContent }))		
	}

	const onClickSavedItem = (row) => {
		// let selectedFilters = row.parameters
		let selectedFilters = []

		let selectedProvider = row.provider
		let parameters = row.parameters && !Array.isArray(row.parameters) ? row.parameters : {}
		
		if(row.parameters) {
			if(parameters.hasOwnProperty("account_id")) {
				parameters["account_id"].forEach(item => {
					let dataRow = {
						label: "Account",
						value: item,
						userView: "Account : "+ item
					}
					selectedFilters.push(dataRow)
				})
			}
			if(parameters.hasOwnProperty("region")) {
				parameters["region"].forEach(item => {
					let dataRow = {
						label: "Region",
						value: item,
						userView: "Region : "+ item
					}
					selectedFilters.push(dataRow)
				})
			}
			if(parameters.hasOwnProperty("category")) {
				parameters["category"].forEach(item => {
					let dataRow = {
						label: "Category",
						value: item,
						userView: "Category : "+ item
					}
					selectedFilters.push(dataRow)
				})
			}
			if(parameters.hasOwnProperty("service_name")) {
				parameters["service_name"].forEach(item => {
					let dataRow = {
						label: "Services",
						value: item,
						userView: "Services : "+ item
					}
					selectedFilters.push(dataRow)
				})
			}
			if(parameters.hasOwnProperty("users")) {
				parameters["users"].forEach(item => {
					let dataRow = {
						label: "Accessed By",
						value: item,
						userView: "Accessed By : "+ item
					}
					selectedFilters.push(dataRow)
				})
			}
			if(parameters.hasOwnProperty("event_name")) {
				Object.entries(parameters.event_name).forEach(([key, value]) => {
					value.forEach(item => {
						let dataRow = {}
						dataRow.label = "Events"
						dataRow.type = key
						dataRow.value = item
						dataRow.userView = "Events: "+key+" "+item
						selectedFilters.push(dataRow)	
					})
				})
			}
			if(parameters.hasOwnProperty("vpc_id")) {
				Object.entries(parameters.vpc_id).forEach(([key, value]) => {
					value.forEach(item => {
						let dataRow = {}
						dataRow.label = "Networking"
						dataRow.type = key
						dataRow.value = item
						dataRow.userView = "Networking: "+key+" "+item
						selectedFilters.push(dataRow)	
					})
				})
			}
			if(parameters.hasOwnProperty("ip")) {
				Object.entries(parameters.ip).forEach(([key, value]) => {
					value.forEach(item => {
						let dataRow = {}
						dataRow.label = "IP Address"
						dataRow.type = key
						dataRow.value = item
						dataRow.userView = "IP Address: "+key+" "+item
						selectedFilters.push(dataRow)	
					})
				})
			}
			if(parameters.hasOwnProperty("instance_type")) {
				parameters.instance_type.forEach(item => {
					item.instance_type.forEach(ins => {
						let dataRow = {}
						dataRow.label = "Instance Type"
						dataRow.service = item.resource_type
						dataRow.value = ins
						dataRow.userView = "Instance Type : " +item.resource_type+" : "+ins
						selectedFilters.push(dataRow)
					})
				})
			}			
			if(parameters.hasOwnProperty("tags")) {
				parameters.tags.forEach(item => {
					let dataRow = {}
					dataRow.label = "Tags"
					dataRow.key = item.label
					dataRow.value = item.value
					dataRow.userView = "Tags : " +item.label+" : "+item.value
					selectedFilters.push(dataRow)
				})
			}
		}

		setState(prevState => ({ ...prevState, selectedProvider, selectedFilters }))
	}

	const handleChildClick = (event, type, dropdownType, section) => {	
		event.stopPropagation();

		if (advanceMultiSearchRef && advanceMultiSearchRef.current && !advanceMultiSearchRef.current.contains(event.target)) {
			setState(prevState => ({ ...prevState, inputComponent: [] }))
		}

		if (servicesRef && servicesRef.current && !servicesRef.current.contains(event.target)) {
			setState(prevState => ({ ...prevState, selectedPage: false }))
		}

		let clickedChild = []
		if(type === 'child') {
            if(state.clickedChild && state.clickedChild.length) {
                state.clickedChild.forEach(row => {
                    setState(prevState => ({ ...prevState, [row]: false }))
                })
            }
            clickedChild.push(section)
            setState(prevState => ({ ...prevState, [section]: dropdownType === "singleDropDown" && state[section] ? false : true, clickedChild }))
        } else if(type === 'parent') {
            if(state.clickedChild && state.clickedChild.length) {
                state.clickedChild.forEach(row => {
                    setState(prevState => ({ ...prevState, [row]: false }))
                })
            }
            
            setState(prevState => ({ ...prevState, clickedChild }))
        }
	}

	return (
		<div className='advanced-search' style={{ zIndex: 999 }} onClick={ (event) => handleChildClick(event, 'parent')}>
			<div className="search bg-muted w-4/5 overflow-auto">
				<div className="header-search bg-black bd-highlight flex justify-between p-2">
					<div className="flex-fill bd-highlight text-white">
						<h5>Advanced Search</h5>
						<p className='m-0'>
							Search Cloud resources database by using advanced and custom queries
						</p>
					</div>
					<div className='text-right flex-fill bd-highlight cursor-pointer'>
						<Icon icon="jam:close" className='text-white' width="25" height="25" onClick={() => props.closeSidePanel()} />
					</div>
				</div>
				<div className='m-2'>
					<div className=''>
						<p className='mb-0 self-center mr-2 text-white'>Provider</p>
						<div className='w-24' onClick={(event) => {
							if(!state.isProviderOpen) {
								event.preventDefault();
								handleChildClick(event, 'child', 'singleDropDown', 'isProviderOpen')
							}
						}}>
							<p className={`flex bg-transparent border border-lightGray rounded-md px-2 py-1 justify-between mt-2 px-2 mb-2 truncate ${state.selectedProvider ? 'text-white' : 'placeholder '}`}>
								{state.selectedProvider ? state.selectedProvider.toUpperCase() : 'Select'}
								<Icon icon="icon-park-solid:down-one" className={`${state.selectedProvider ? "text-white" : "text-lightGray"} self-center`} width="16" height="16" />
							</p>
							{state.isProviderOpen ?
								<MultiSelectSection
									fields={["provider_name", "provider_name"]}
									options={props.providers ? props.providers : []}
									selectedValues={state.selectedProvider ? state.selectedProvider : ''}
									callbackMultiSelect={(value) => {
										if(!value || typeof(value) === 'string') {
											setState(prevState => ({ ...prevState, selectedProvider: value, selectedFilters: [], selectedPage: "", selectedOption: "" }))
										} else {
											value.preventDefault()
											handleChildClick(value, "search", 'singleDropDown', "")
										}
									}}
									singleSelection={true}
									removeTopOptions={true}
									hideSearch={false}
									topClass={'auto'}
									widthClass={'minWidth220'}
								/>
							: null}
						</div>
					</div>
					{state.selectedProvider ? 
					<React.Fragment>
						<div className="flex justify-between">
							<p className='font-semibold m-0 text-md'>Filters</p>
							<p className='text-info m-0 text-right cursor-pointer w-1/3' onClick={() => {
								setState(prevState => ({ ...prevState, showSavedFilters: state.showSavedFilters ? false : true }))
							}}
							>{state.showSavedFilters ? 'Hide Saved Filters' : 'Show Saved Filters' }</p>
						</div>
						{state.showSavedFilters ?
							<div className='mt-2'>
								<p className='text-white'>Saved Filters</p>
								<div className="w-full bg-white">
									<ResizeableDarkThemeTable
										columns={[
											{
												Header: 'Name',
												accessor: 'title'
											},
											{
												Header: 'Created By',
												accessor: 'created_by'
											},
											{
												Header: 'Date',
												accessor: 'timestamp',
												Cell: cellInfo => (
													<span>{cellInfo.row.original.created_at ? momentConvertionUtcToLocalTime(cellInfo.row.original.created_at, "DD MM YYYY HH:mm") : <span>&nbsp;</span>}</span>
												),
												width: 200
											},
											{
												Header: ' ',
												// accessor: 'filters',
												Cell: cellInfo => (
													<span>
														{cellInfo.row.original.parameters && !Array.isArray(cellInfo.row.original.parameters) ? Object.entries(cellInfo.row.original.parameters).length : <span>&nbsp;</span>} Filters
													</span>
												)
											},
										]}
										data={state.savedFilters ? state.savedFilters : []}
										onClickRow={tableRow => {
											setState(prevState => ({ ...prevState, selectedFilters: [] }))
											onClickSavedItem(tableRow)
										}}										
										tableHead={'bg-black text-white'}
										tableBody={'bg-white'}
										tableType={'table-light-hover'}
										perPageClass={`bg-transparent text-lightGray`}
										textColor={'text-black'}
										selectedColor={'bg-backGroundGray'}

										dashboard={state.savedFilters && state.savedFilters.length}
										perPage={3}
									/>
								</div>
							</div>
						: null}
						{state.selectedFilters && state.selectedFilters.length ? 
							<div className="flex flex-wrap">
								{state.selectedFilters.map((row, rIndex) => {
									return(
										<p key={'row1_'+rIndex} className={`flex px-2 py-1 rounded-full border border-info text-white mr-1 mb-1`}>{row.userView}
											{/* <Icon icon="ion:close-circle-sharp" width="18" height="18" className='text-white ml-2 self-center cursor-pointer' /> */}
											<span class="w-4 h-4 bg-lightGray rounded-full ml-3 text-xs self-center cursor-pointer" onClick={() => removeFilterData(row)}><span class="flex justify-center text-xs">X</span></span>
										</p>
										
									)
								})}
							</div>
						: null}
						<div className='justify-between mt-4'>
							<div className="w-full p-0 m-0 relative">
								{state.selectedOption && state.selectedOption !== "" ? 
									<p className="mb-2 text-white">{state.selectedOption}</p>
								: null}
								
								<div className="flex w-1/2">
									<input
										// ref={searchRef}
										type='text'
										value={state.searchText ? state.searchText : ""}
										onChange={e => {
											setState(prevState => ({ ...prevState, searchText: e.target.value }))
											filterOnchange(e.target.value)
										}}
										className='border border-lightGray rounded-md bg-transparent px-2 py-1 w-full text-white'
										placeholder={"Filters"}
										onClick={ () => setState(prevState => ({ ...prevState, showMainDropdownOption: true, inputComponent: [], selectedPage: ""  }))}
									/>
										
									<div className={`ml-3 flex self-center ${state.selectedFilters && state.selectedFilters.length ? '' : 'disabled'}`}>
										<div className="btn-group" role="group" aria-label="status-builder">
											<button type="button" className={`cursor-pointer btn bg-cyon px-2 py-1 rounded-md text-white`} onClick={()=> onSearch()}>Search</button>
											<button type="button" className={`cursor-pointer btn bg-cyon text-white`} onClick={()=> onReset()}><i className='far fa-redo'></i></button>
										</div>
									</div>
									
									<p className={`text-info m-0 text-nowrap self-center cursor-pointer ml-4 ${state.selectedFilters && state.selectedFilters.length ? '' : 'hidden'}`}
										onClick={() => setState(prevState => ({ ...prevState, showSaveOption: true, selectedPage: "" }))}>
										Save Filter
									</p>
								</div>

								{state.showMainDropdownOption ? 
									<div className={`mt-2 bg-white border border-mediumDarkGray w-1/3 rounded-md`}>
										{state.mainDropdown && state.mainDropdown.map(row => {
											return(
												<p key={'roww_'+row} className="block px-5 py-2 text-base text-black cursor-pointer" 
													onClick={() => onSelectOptions(row)}
												>{row}</p>
											)
										})}
									</div>
								: null}
							</div>
							
							{state.showSaveOption ?
								<div className={`flex dlpDescriptionForm dlpDescriptionFormPlain mt-4`}>
									{state.showMessage ?
										<p className={` ${state.responseStatus ? 'text-success' : 'text-danger' }`}>{state.responseMessage}</p> 
									: null }
									<div className="flex w-100 mt-2">                                                
										<input type="text" 
											className={`inputField text-white mr-2 w-50`}
											onChange={e => setState(prevState => ({ ...prevState,searchName: e.target.value }))}
											value={state.searchName}
											placeholder={'Enter search name'}
										/>
										<button
											className={`btn bg-green px-2 py-1 rounded-md ml-3`}
											onClick={() => saveFilterItem()}
											disabled={state.showSaveLoading}
										>
											{state.showSaveLoading && !state.hasError ? 
												(<React.Fragment>
													{'Saving'}
													<svg className="animate-spin h-5 w-5 mr-2 text-white" fill="currentColor" viewBox="0 0 24 24">
														<circle cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="2" fill="none" />
														<path d="M4 12a8 8 0 0112-6.9" />
													</svg>
												</React.Fragment>) 
											: 'Save'
											}
										</button>
									</div>
									{state.hasError && state.searchName === '' ?
										<p className='text-danger f12 text-right'>{state.labelMessage}</p>
									: null}
								</div>
							: null}
							{state.inputComponent && state.inputComponent.length === 1 ?
								state.inputComponent[0].component === "multiselect" ?
									<React.Fragment>
									<div ref={advanceMultiSearchRef}>
                                        {/* <label className='my-1 flex'>Value</label> */}
                                        {/* <p className={`border-b border-lightGray px-2 py-1 flex justify-between mb-0 truncate ${state[inpCom.field] && state[inpCom.field].length ? 'text-white' : 'text-lightGray'}`}>
                                            {state[inpCom.field] && state[inpCom.field].length ? state[inpCom.field].length+' Selected' : 'Select'}
                                            <Icon icon="icon-park-solid:down-one" className={`${state[inpCom.field] && state[inpCom.field].length ? "text-white" : "text-lightGray"}`} width="16" height="16" />
                                        </p> */}
                                        {/* {state.isMultiSelect ? */}
                                            <MultiSelectSection 
                                                fields={["value", "label"]}
                                                className={'relative top80 left-0'}
                                                options={state["multiselect_"+state.inputComponent[0].field] ? state["multiselect_"+state.inputComponent[0].field] : (state.inputComponent[0].options ? state.inputComponent[0].options : [])}
                                                selectedValues={state.selectedMultiInput ? state.selectedMultiInput : []}
                                                callbackMultiSelect={(value) => {
													setState(prevState => ({ ...prevState, selectedRegion: value }))
													updateMultiSelectedOptionsNew(value, state.inputComponent[0])
												}}
                                                widthClass={'minWidth220'}
                                                removeTopOptions={true}
                                            />
                                        {/* : null} */}
                                    </div>
									<div className={`mt-1 bg-white border border-mediumDarkGray rounded-md w-1/3 px-2 hidden`}>
										{state.inputComponent[0].options && state.inputComponent[0].options.length > 2 ?
											<div className={`mb-1 py-2`}>
												<Search
													topClassName={'bg-transparent text-white border border-lightGray rounded-md cursor-pointer'}
													searchClassName={'px-1'}
													searchIconColor={'text-lightGray'}
													searchPlaceHolder={'Search....'}
													className={'bg-transparent text-black'}
													widthHeight={25}
													data={state.inputComponent[0].options ? state.inputComponent[0].options : []}
													filteredData={(filteredArray, searchText) => {
														setState(prevState => ({ ...prevState, ["multiselect_"+state.inputComponent[0].field]: searchText !== "" ? filteredArray : state.inputComponent[0].options }))
													}}
												/>
											</div>
										: null}
										{(state["multiselect_"+state.inputComponent[0].field] ? state["multiselect_"+state.inputComponent[0].field] : state.inputComponent[0].options).map((row, mIndex) => {
											return(
												<div key={'drop_'+mIndex} className="flex py-1 bg-transparent cursor-pointer mb-1" onClick={() => {
													setState(prevState => ({ ...prevState,["checked_"+state.inputComponent[0].field+"_"+mIndex]: !state["checked_"+state.inputComponent[0].field+"_"+mIndex] }))

													 updateMultiSelectedOptions(row.value, state.inputComponent[0], mIndex)
												}}>											
													<div className="ml-1 form-check">
														<input type="checkbox"
															className="form-check-input"
															checked={state["checked_"+state.inputComponent[0].field+"_"+mIndex]}
														/>
													</div>
													<p className="ml-1 mb-0 text-left text-black">{row.label}</p>
												</div>
											)
										})}
									</div>
									</React.Fragment>
								: state.inputComponent[0].component === "select" ?
									<div className={`mt-1 bg-white border border-mediumDarkGray rounded-md w-1/3`}>
										{state.inputComponent[0].options && state.inputComponent[0].options.map((row, sIndex) => {
											return(
												<button key={'inp_'+sIndex} className="flex justify-between mb-3 bg-transparent text-black cursor-pointer" onClick={() => updateSelectedFilter(row.value, state.inputComponent[0], state.inputComponent[0].options, sIndex)}>
													<p className="mb-0 text-left">{row.label}</p>
												</button>
											)
										})}
									</div>
								: state.inputComponent[0].component === "Custom_Duration" ?										
									<DurationFilterSection
										inputComponent={state.inputComponent}
										selectedInput={(result) => selectedDurationFunction(result)}
									/>
								: state.selectedPage === "selectTextList" ?
									<SingleInputSection
										inputComponent={state.inputComponent}
										selectedPage={state.selectedPage}
										selectedOption={state.selectedOption}
										selectedFilters={state.selectedFilters && state.selectedFilters.filter(e => e.label === state.selectedOption).length ? state.selectedFilters.filter(e => e.label === state.selectedOption) : []}
										selectedInput={(selectedOptions, result) => selectedTextListDetails(selectedOptions, result)}
									/>
								:  null
							: 
								<div className='mt-4'>
									{state.selectedPage === "selectTextList" ?
										<IpAddressess
											inputComponent={state.inputComponent}
											selectedPage={state.selectedPage}
											selectedOption={state.selectedOption}
											selectedFilters={state.selectedFilters && state.selectedFilters.filter(e => e.label === state.selectedOption).length ? state.selectedFilters.filter(e => e.label === state.selectedOption) : []}
											selectedInput={(selectedOptions, result) => selectedTextListDetails(selectedOptions, result)}
										/>
									: state.selectedPage === "Resources" ?
										<ResourcesInputSection
											inputComponent={state.inputComponent}
											selectedPage={state.selectedPage}
											selectedOption={state.selectedOption}
											selectedFilters={state.selectedFilters && state.selectedFilters.filter(e => e.label === state.selectedOption).length ? state.selectedFilters.filter(e => e.label === state.selectedOption) : []}
											selectedInput={(selectedOptions, result) => selectedTextListDetails(selectedOptions, result)}
										/>
									: state.selectedPage === "ServicesOld" ?
										<ServicesInputSection
											inputComponent={state.inputComponent}
											selectedPage={state.selectedPage}
											selectedOption={state.selectedOption}
											selectedFilters={state.selectedFilters && state.selectedFilters.filter(e => e.label === state.selectedOption).length ? state.selectedFilters.filter(e => e.label === state.selectedOption) : []}
											selectedInput={(selectedOptions, result) => selectedTextListDetails(selectedOptions, result)}
										/>
									: state.selectedPage === "Tags" ?
										<TagsSection
											selectedProvider={state.selectedProvider}
											inputComponent={state.inputComponent}
											selectedPage={state.selectedPage}
											selectedOption={state.selectedOption}
											selectedFilters={state.selectedFilters && state.selectedFilters.filter(e => e.label === state.selectedOption).length ? state.selectedFilters.filter(e => e.label === state.selectedOption) : []}
											selectedInput={(selectedOptions, result) => selectedTextListDetails(selectedOptions, result)}
										/>
									: state.selectedPage === "InstanceType" ?
										<InstanceTypeInputSection
											inputComponent={state.inputComponent}
											selectedPage={state.selectedPage}
											selectedOption={state.selectedOption}selectedFilters={state.selectedFilters && state.selectedFilters.filter(e => e.label === state.selectedOption).length ? state.selectedFilters.filter(e => e.label === state.selectedOption) : []}
											selectedInput={(selectedOptions, result) => selectedTextListDetails(selectedOptions, result)}
										/>
									: state.selectedPage === "Services" ?
										<div ref={servicesRef}>
											<DropdownTreeSelect 
												//values={state.selectedServiceContent}
												texts={{ placeholder: state.selectedServiceContent ? state.selectedServiceContent : "Select" }}
												showDropdown={"always"}
												data={state.filteredServiceResourceArray}
												onChange={onChangeServiceDropdown}
												className="bootstrap-demo"
												keepTreeOnSearch={true}
												keepChildrenOnSearch={true}
											/>
										</div>										
									: null}
								</div>
							}
							{/* {state.selectedFilters && state.selectedFilters.length ? 
								// <div className='w-30 bg-mediumDarkGray p-3 rounded'>
								<div className='w-30 border-lef-info hidden'>
									<p className='mb-2 text-black font-weight-bold f16'>Selected Filters</p>
									{state.selectedFilters.map((row, rindex) => {
										return(
											<div key={'dd_'+rindex} className="mt-2 w-100">
												<small className={`badge bg-lightGray mr-1 text-white`}>{row.userView}
												<i className='ml-2 f14 fas fa-times-circle cursor-pointer text-white' onClick={ () => removeFilterData(row) } ></i>
												</small>
											</div>
										)
									})}
								</div>
							: null} */}
						</div>
					</React.Fragment>
					: null}
				</div>
			</div>
		</div>
	)
}
export default ResourcesAdvancedSearch
