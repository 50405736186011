import React, { useCallback, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux"; // Import useDispatch hook to dispatch actions
import { setHpcPropsDetails } from "../../../../actions/Collider/HpcAction";
import MultiSelectSection from "../../../common/MultiSelectSection";
import { validateVolumeName, validateMountDir } from "../../../../utils/utility";
import { Icon } from '@iconify/react'


const ExistingStorageSection = (props) => {
  const [state, setState] = useState({storageArray: [0]});
  const createUpdateCluster = useSelector(
    (state) => state?.hpc?.hpcPropsDetails?.createUpdateCluster || false
  );
  const propsClusterFilters = useSelector(
    (state) => state?.hpc?.hpcPropsDetails?.propsClusterFilters || false
  );

  const dispatch = useDispatch(); // Create a dispatch function

  const callPageVist = useCallback(() => {
    let storageConfiguration = createUpdateCluster?.storage_configuration || [];
    let storageArray = [0];
  
    if (storageConfiguration.length) {
      storageArray = [];
      storageConfiguration.forEach((row, index) => {
        storageArray.push(index);
        setState((prevState) => ({
          ...prevState,
          [`storage_type_${index}`]: row.storage_type,
          ...Object.entries(row).reduce((acc, [key, value]) => {
            acc[`${key}_${index}`] = value;
            return acc;
          }, {})
        }));
      });

      setState((prevState) => ({
        ...prevState,
        storageArray
      }));
    }
  }, [createUpdateCluster]);

  useEffect(() => callPageVist(), [callPageVist]);

  const addSection = (array) => {
    let rowList = state[array];
    if (state[array]) {
      let value = state[array][state[array].length - 1];
      value = value + 1;
      rowList.push(value);

      setState((prevState) => ({ ...prevState, [array]: rowList }));
    }
  };

  const removeSection = (array, index, i) => {
    setState((prevState) => ({
      ...prevState,
      ["storage_id_" + index]: "",
      ["storage_name_" + index]: "",
      ["filesystem_name_" + index]: "",
      ["mount_dir_" + index]: ""
    }));

    let rowList = state[array];
    rowList.splice(i, 1);
    setState((prevState) => ({ ...prevState, [array]: rowList }));

    // setTimeout(() => {
    //   handleStorageInput();
    // }, 5000);
  };

  const handleStorageInput = (index, label, value, type) => {
    let obj = createUpdateCluster ? createUpdateCluster : {};
    let storageConfiguration = obj.storage_configuration && obj.storage_configuration.length ?  obj.storage_configuration : [];

    state.storageArray.forEach((sIndex) => {
      if (!storageConfiguration.filter((e) => e.arrayIndex === sIndex).length) {
        let dataRow = { arrayIndex: sIndex, storage_type: props.storageType};
        storageConfiguration.push(dataRow);
      }
      let selectedStorageInput = storageConfiguration.filter((e) => e.arrayIndex === sIndex)[0];
     
      if (sIndex === index) {
        setState((prevState) => ({
          ...prevState,
          [label + "_" + sIndex]: value
        }));
      }
      let inputValue = sIndex === index ? value : state[label + "_" + sIndex];
      selectedStorageInput[label] = type === "integer" ? parseInt(inputValue) : inputValue;
    });
    obj["storage_configuration"] = storageConfiguration;
    dispatch(setHpcPropsDetails("createUpdateCluster", obj));
  };

  const handleChildClick = (event, type, dropdownType, section) => {
    event.stopPropagation();

    let clickedChild = [];
    if (type === "child") {
      if (state.clickedChild && state.clickedChild.length) {
        state.clickedChild.forEach((row) => {
          setState((prevState) => ({ ...prevState, [row]: false }));
        });
      }
      clickedChild.push(section);
      setState((prevState) => ({
        ...prevState,
        [section]:
          dropdownType === "singleDropDown" && state[section] ? false : true,
        clickedChild,
      }));
    } else if (type === "parent") {
      if (state.clickedChild && state.clickedChild.length) {
        state.clickedChild.forEach((row) => {
          setState((prevState) => ({ ...prevState, [row]: false }));
        });
      }

      setState((prevState) => ({ ...prevState, clickedChild }));
    }
  };

  return (
    <div onClick={(event) => handleChildClick(event, "parent")}>
      {state.storageArray &&
        state.storageArray.map((index, j) => {
          return (
            <div className="bg-dark p-3 rounded mt-3">
              {props.storageType === "Efs" ? (
                <React.Fragment>
                  <div className={"flex mt-3"}>
                    <div className="py-1 w-full">
                      <div className="w-1/3">
                        <div className="flex justify-between">
                          <label className="self-center m-0 text-lightGray">Filesystem name</label>
                          {state.hasError && !state["filesystem_name_" + index] ? (
                            <small className="text-issues">required</small>
                          ) : null}
                        </div>
                        <div
                          className="text-white border-lightGray bg-transparent rounded-5"
                          onClick={(event) => {
                            if (!state["isFileSystemName_" + index]) {
                              event.preventDefault();
                              handleChildClick( event, "child", "singleDropDown", "isFileSystemName_" + index);
                            }
                          }}
                        >
                          <p className={`flex border border-lightGray rounded-md bg-transparent px-2 py-1 justify-between truncate ${
                              state["filesystem_name_" + index] && props.pageMode === "Create" ? "text-white" : "placeholder" }`}>
                            {state["filesystem_name_" + index]
                              ? propsClusterFilters.efs && propsClusterFilters.efs.filter((e) => e.value === state["filesystem_name_" + index]).length
                                ? propsClusterFilters.efs.filter((e) => e.value === state["filesystem_name_" + index])[0].key
                                : state["filesystem_name_" + index]
                              : "Select"}
                            <Icon icon="icon-park-solid:down-one" className={`${state["filesystem_name_" + index]  ? "text-white" : "text-lightGray"} self-center`} width="16" height="16" />
                          </p>
                          {state["isFileSystemName_" + index] &&
                          props.pageMode === "Create" ? (
                            <MultiSelectSection
                              fields={["value", "key"]}
                              options={ propsClusterFilters && propsClusterFilters.efs ? propsClusterFilters.efs.map((d) => ({ key: d, value: d})) : []}
                              selectedValues={state["filesystem_name_" + index] ? state["filesystem_name_" + index] : "" }
                              callbackMultiSelect={(value) => {
                                if (!value || typeof value === "string") {
                                  handleStorageInput( index, "filesystem_name", value);
                                  setState((prevState) => ({ ...prevState, ["filesystem_name_" + index]: value }));
                                } else {
                                  value.preventDefault();
                                  handleChildClick( value, "search", "singleDropDown", "");
                                }
                              }}
                              singleSelection={true}
                              widthClass={"minWidth220"}
                              removeTopOptions={true}
                            />
                          ) : null}
                        </div>
                      </div>
                      {propsClusterFilters && propsClusterFilters.description && propsClusterFilters.description.storage_configuration && propsClusterFilters.description.storage_configuration
                        .filesystem_name ? (
                        <p className="mt-1 mb-0 text-info">
                          {propsClusterFilters.description.storage_configuration.filesystem_name}
                        </p>
                      ) : null}
                    </div>
                  </div>
                  
                  <div className={"flex mt-3"}>
                    <div class="py-1 w-1/3">
                      <p className="b-block mb-0">Mount Directory</p>
                      <input
                        type="text"
                        placeholder="Enter mount directory (Eg. /data)"
                        className={`border border-lightGray rounded-md bg-transparent px-2 py-1 w-full ${
                          props.pageMode !== "Create" ? "disabled text-primary-color" : "text-white"
                        }`}
                        // maxlength='128'
                        value={state["mount_dir_" + index] ? state["mount_dir_" + index] : ""}
                        onChange={(e) => {
                          if (props.pageMode === "Create") {
                            handleStorageInput( index, "mount_dir", validateMountDir(e.target.value));
                          }
                        }}
                      />
                      {propsClusterFilters && propsClusterFilters.description && propsClusterFilters.description.storage_configuration && propsClusterFilters.description.storage_configuration.mount_dir ? (
                        <p className="mt-1 mb-0 text-info">
                          {propsClusterFilters.description.storage_configuration.mount_dir}
                        </p>
                      ) : null}
                    </div>
                  </div>
                </React.Fragment>
              
              ) : props.storageType === "FsxOntap" ? (
                <React.Fragment>
                  <div className={"flex mt-3"}>
                    <div className="py-1 w-full">
                      <div className="w-1/3">
                        <div className="flex justify-content-between">
                          <label className="self-center m-0 text-lightGray">
                            Storage Id
                          </label>
                          {state.hasError &&
                          !state["storage_id_" + index] ? (
                            <small className="text-issues">required</small>
                          ) : null}
                        </div>
                        <div
                          className="border-lightGray bg-transparent rounded-5"
                          onClick={(event) => {
                            if (!state["isFsxStorageId_" + index]) {
                              event.preventDefault();
                              handleChildClick(event, "child", "singleDropDown", "isFsxStorageId_" + index);
                            }
                          }}
                        >
                          <p
                            className={`flex border border-lightGray rounded-md bg-transparent px-2 py-1 justify-between truncate ${
                              state["storage_id_" + index] && props.pageMode === "Create"  ? "text-white" : "placeholder"
                            }`}
                          >
                            {state["storage_id_" + index]
                              ? propsClusterFilters.fsx && propsClusterFilters.fsx.filter((e) => e.value === state["storage_id_" + index]).length
                                ? propsClusterFilters.fsx.filter((e) => e.value === state["storage_id_" + index])[0].key
                                : state["storage_id_" + index]
                              : "Select"}
                            <Icon icon="icon-park-solid:down-one" className={`${state["storage_id_" + index]  ? "text-white" : "text-lightGray"} self-center`} width="16" height="16" />
                          </p>
                          {state["isFsxStorageId_" + index] &&
                          props.pageMode === "Create" ? (
                            <MultiSelectSection
                              fields={["value", "key"]}
                              options={propsClusterFilters && propsClusterFilters.fsx ? propsClusterFilters.fsx.map((d) => ({ key: d, value: d})) : []}
                              selectedValues={state["storage_id_" + index] ? state["storage_id_" + index] : ""}
                              callbackMultiSelect={(value) => {
                                if (!value || typeof value === "string") {
                                  handleStorageInput(index,"storage_id",value);
                                  setState((prevState) => ({ ...prevState,["storage_id_" + index]: value }));
                                } else {
                                  value.preventDefault();
                                  handleChildClick(value, "search", "singleDropDown", "");
                                }
                              }}
                              singleSelection={true}
                              widthClass={"minWidth220"}
                              removeTopOptions={true}
                            />
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className={"flex mt-3"}>
                    <div class="py-1 w-1/3">
                      <p className="b-block mb-0">Volume name</p>
                      <input
                        type="text"
                        placeholder="Enter volume name (Eg. data)"
                        className={`border border-lightGray rounded-md bg-transparent px-2 py-1 w-full ${
                          props.pageMode !== "Create"
                            ? "disabled text-primary-color"
                            : "text-white"
                        }`}
                        // maxlength='128'
                        value={state["storage_name_" + index] ? state["storage_name_" + index] : ""}
                        onChange={(e) => {
                          if (props.pageMode === "Create") {
                            handleStorageInput(index, "storage_name", validateVolumeName(e.target.value));
                          }
                        }}
                      />
                      {propsClusterFilters && propsClusterFilters.description && propsClusterFilters.description.storage_configuration && propsClusterFilters.description.storage_configuration.volume_name ? (
                        <p className="mt-1 mb-0 text-info">
                          {
                            propsClusterFilters.description.storage_configuration.volume_name
                          }
                        </p>
                      ) : null}
                    </div>
                  </div>
                </React.Fragment>
              ) : null}

              <div className="flex justify-end">
                {state.storageArray.length > 1 ? (
                  <span
                    className={`cursorPointer text-red f16 self-center ml-4`}
                    onClick={() => removeSection("storageArray", index, j)}
                  >
                    {" "}
                    Remove
                  </span>
                ) : null}
                {state.storageArray.length - 1 === j ? (
                  <span
                    className={`cursorPointer text-info f16 self-center ml-4`}
                    onClick={() => addSection("storageArray")}
                  >
                    {" "}
                    Add
                  </span>
                ) : null}
              </div>
            </div>
          );
        })}
    </div>
  );
};

export default ExistingStorageSection;
