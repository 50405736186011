/* eslint-disable react/jsx-curly-spacing */
/* eslint-disable no-mixed-spaces-and-tabs */
/*************************************************
 * Collider
 * @exports
 * @file BudgetLandingPage.js
 * @author Prakash // on 17/05/2023
 * @copyright © 2023 Collider. All rights reserved.
 **************************************************/
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux'; // Import useDispatch hook to dispatch actions
import { useLocation } from 'react-router-dom';
import { Link, useNavigate } from 'react-router-dom'
import { listBudgetDetails, deleteBudgetDetails, setHpcPropsDetails } from '../../../actions/Collider/HpcAction'

import { capitalizeTheFirstLetterOfEachWord, momentConvertionUtcToLocalTime } from '../../../utils/utility'
import Search from '../../common/SearchWithHiddenInput'

import ResizeableDarkThemeTable from '../../designComponents/Table/ResizeableDarkThemeTable';
import { URL_PATH } from '../../../config/urlPath';
import { CSVLink } from 'react-csv';
import { Store as CommonNotification } from 'react-notifications-component';
import {Icon} from "@iconify/react";

const BudgetLandingPage = () => {
	const[state, setState] = useState({
		showLoading: true,
		forecastOptions: [
			{label: "Monthly", value: "monthly"},
			{label: "Quarterly", value: "quarterly"},
			{label: "Half Yearly", value: "half-yearly"},
			{label: "Annual", value: "yearly"},
		],
		// selectedPeriod: "quarterly",
		totalItemShowing: 5,
		itemMovedLeft: 0,
		itemMovedRight: 0,

		emptyOptions: [],
		actionsAllowed: [],
		callSearch: true
	})
	
	const userMenu = useSelector(state => state?.filters?.userMenu || false);
	
	const navigate = useNavigate();
    const dispatch = useDispatch(); // Create a dispatch function\
    const location = useLocation();
	const currentPath = location.pathname;

	useEffect(() => {
        if(userMenu && Object.entries(userMenu).length) {
			let actionsAllowed = []
			userMenu.menu && userMenu.menu.forEach(row => {
                if(row.submenulist && row.submenulist.length) {
                    if(row.submenulist.filter(e => e.link === currentPath && e.action).length) {
                        actionsAllowed = row.submenulist.filter(e => e.link === currentPath)[0].action
                    }
				} else {
					if(row.action) {
						actionsAllowed = row.action
					}
				}
			})
            setState(prevState => ({ ...prevState, actionsAllowed }))
		}
    }, [userMenu, currentPath])

	useEffect(() => {
		if(state.callSearch) {
			setState(prevState => ({ ...prevState, callSearch: false }))
			let params = {}
			dispatch(listBudgetDetails(params))
				.then((response) => {
					if(response) {
						setState(prevState => ({ ...prevState, listBudgets: response, filterListBudgets: response, showLoading: false }))
					} else {
						setState(prevState => ({ ...prevState, listBudgets: [], filterListBudgets: [], showLoading: false }))
					}
				})
		}
	}, [state.callSearch, dispatch])

	useEffect(() => {
        let dataResult = state.filterListBudgets && state.filterListBudgets.length ? state.filterListBudgets : [];
		if(dataResult.length) {
			let headers = []
			Object.entries(dataResult[0]).forEach(([key, value]) => {
				if(typeof value === 'string') {
					let headerRow = {}
					headerRow.label = capitalizeTheFirstLetterOfEachWord(key)
					headerRow.key = key
					headers.push(headerRow)
				}
			})

			setState(prevState => ({ ...prevState, tableHeaders: headers }))
		}
    }, [state.filterListBudgets])	

    const navigateTo = () => {
        navigate(URL_PATH.HPC_CREATE_BUDGET); // Redirect to the specified path
    }

	const deleteRecord = () => {
		setState(prevState => ({ ...prevState, showDeleteLoading: true }))
        let params = {}
        params.budget_id = state.deleteDetails && state.deleteDetails.budget_id
        if(params.budget_id) {
            dispatch(deleteBudgetDetails(params)).then((response) => {
                // setState(prevState => ({ ...prevState, showActionLoading: false, showUpdateActionLoading: false }))
                let messageType = 'success'
                let message = response.message
                if(!response.status) {   
                    messageType = 'danger'
					setState(prevState => ({ ...prevState, showDeleteLoading: false }))
                } else {
                    setState(prevState => ({ ...prevState, deleteModal: false, callSearch: true, showDeleteLoading: false }))
                }
                // setState(prevState => ({ ...prevState, saveLoading: false, message, messageType, createErrorMessage }))

                CommonNotification.addNotification({
                    //title: 'Wonderful!',
                    message: message,
                    type: messageType,
                    insert: 'top',
                    container: 'top-center',
                    // animationIn: ['animate__animated', 'animate__fadeIn'],
                    // animationOut: ['animate__animated', 'animate__fadeOut'],
                    dismiss: {
                        duration: 5000,
                        onScreen: false,
                        pauseOnHover: true,
                        showIcon: true,
                    }
                });
            })   
        }
	}

	const handleChildClick = (event, type, dropdownType, section) => {	
		event.stopPropagation();
		let clickedChild = []
		if(type === 'child') {
            if(state.clickedChild && state.clickedChild.length) {
                state.clickedChild.forEach(row => {
                    setState(prevState => ({ ...prevState, [row]: false }))
                })
            }
            clickedChild.push(section)
            setState(prevState => ({ ...prevState, [section]: dropdownType === "singleDropDown" && state[section] ? false : true, clickedChild }))
        } else if(type === 'parent') {
            if(state.clickedChild && state.clickedChild.length) {
                state.clickedChild.forEach(row => {
                    setState(prevState => ({ ...prevState, [row]: false }))
                })
            }
            
            setState(prevState => ({ ...prevState, clickedChild }))
        }
	}

	/**
	 * Renders cost comparison Landing page
	*/
	return (
		<div onClick={(event) => handleChildClick(event, 'parent')}>
            <div className={`${state.showLoading ? '' : 'hidden'}`}>
                <div className='loading-wave'>
                    <div className='overlayEqualizerLoader'>
                        <div className="loading-bar"></div>
                        <div className="loading-bar"></div>
                        <div className="loading-bar"></div>
                        <div className="loading-bar"></div>
                    </div>
                </div>
            </div>
			
            <div className="bg-nero w-full h-fit relative stickyUiChange">
                <div className="grid grid-cols-12 items-center pb-4">
                    <div className="xl:col-span-12 col-span-12 self-center xl:mt-4">
                        <p className="text-white mx-4 text-xl md:mt-0 mt-4 font-semibold">Budgets</p>
						<p className="mx-4 text-white">Showing the list of Budgets</p>
					</div>			
				</div>
			</div>
			<div className='m-2'>
                <div className={`flex flex-wrap pb-2 justify-between`}> 
                    <div className="flex lg:w-3/5 w-full">
                        <p className="m-0 self-center pt-1">Showing {state.filterListBudgets && state.filterListBudgets.length} out of total {state.listBudgets && state.listBudgets.length} budget(s)</p>
                    </div>
                    <div className="flex flex-wrap justify-end lg:w-2/5 w-full mb-n2">
						{state.filterListBudgets && state.filterListBudgets.length ?
							<CSVLink 
								data={state.filterListBudgets ? state.filterListBudgets : []} 
								headers={state.tableHeaders ? state.tableHeaders : []}
								filename={'job-lists.csv'}
								className={'mr-2 self-center'}
								target='_blank'
							>
								<Icon icon="material-symbols:download" className='text-gray5 self-center' width="22" height="22"/>
							</CSVLink>
						: null}
                        {state.listBudgets && state.listBudgets.length && state.listBudgets.length ?
                            <Search
								data={state.listBudgets ? state.listBudgets : []}
                                topClassName={'text-black bg-transparent border border-mediumDarkGray rounded-md'}
                                searchClassName={'px-2'}
                                searchIconColor={'text-white'}
                                searchPlaceHolder={'Search....'}
                                className={'bg-transparent text-white px-2 py-1'}
                                widthHeight={25}
                                filteredData={(filterListBudgets) => setState(prevState => ({ ...prevState, filterListBudgets }))}
                            />
                        : null}
						<button className={`btn bg-green rounded-md px-2 py-1 text-white ml-2`} onClick={() => {
							dispatch(setHpcPropsDetails('createInputs', {}))
							dispatch(setHpcPropsDetails('selectedAplliesTo', {}))
							navigateTo()
						}}>Create&nbsp;Budget</button>
					</div>
				</div>

				{state.filterListBudgets && state.filterListBudgets.length ?
					<ResizeableDarkThemeTable
						columns={[
							{
								Header: 'Date',
								accessor: d => d.modified_at ? "modified_at" : 'created_at',
								Cell: cellInfo => (
								<span>
									{cellInfo.row.original.modified_at ? momentConvertionUtcToLocalTime(cellInfo.row.original.modified_at, 'DD MMM YYYY HH:mm') : (cellInfo.row.original.created_at ? momentConvertionUtcToLocalTime(cellInfo.row.original.created_at, 'DD MMM YYYY HH:mm') : '')}
								</span>
								),
								width: 200
							},
							{
								Header: 'Name',
								accessor: 'budget_name',
								// Cell: cellInfo => (
								// 	<div className="flex justify-between self-center">
								// 		{state.actionsAllowed && state.actionsAllowed.includes('update') ?
								// 			<Link 
								// 				to={URL_PATH.HPC_CREATE_BUDGET} 
								// 				state={{
								// 					selectedData: cellInfo.row.original,
								// 					pageType: 'edit',
								// 				}}
								// 				className='text-info'>
								// 				{cellInfo.row.original.budget_name}
								// 			</Link>
								// 		: null}
								// 	</div>
								// ),
								width: 200,
							},
							{
								Header: 'Year',
								accessor: 'financial_year',
								width: 100,
							},
							{
								Header: 'Department',
								accessor: 'department',
								width: 150,
							},
							{
								Header: 'Initiative',
								accessor: 'initiative',
								width: 150,
							},
							{
								Header: 'Project',
								accessor: 'project',
								width: 150,
							},
							{
								Header: 'Actions',
								Cell: cellInfo => (
									<div className="flex self-center">
										{state.actionsAllowed && state.actionsAllowed.includes('update') ?
											<Link 
												to={URL_PATH.HPC_CREATE_BUDGET} 
												state={{
													selectedData: cellInfo.row.original,
													pageType: 'edit',
												}}
												className='text-info'>
												Edit
											</Link>
										: null}
										{state.actionsAllowed && state.actionsAllowed.includes('delete') ?
											<p className='ml-3 mb-0 text-danger cursor-pointer' onClick={() => setState(prevState => ({ ...prevState, deleteDetails: cellInfo.row.original, deleteModal: true }))}>Delete</p>
										: null}
									</div>
								),
								width: 150
							},
							{
								Header: 'Description',
								accessor: 'description',
								width: 300
							},
						]}
						data={state.filterListBudgets}
						perPage={20}
						tableHead={'bg-black text-white'}
						tableBodyColor={'bg-dark2 text-white'}
						tableType={'table-dark-hover'}
						perPageClass={`bg-transparent text-lightGray`}
						textColor={'text-white'}
						selectedColor={'bg-transparent'}
						dashboard={state.filterListBudgets && state.filterListBudgets.length ? true : false}
						onClickRow={tableRow => setState(prevState => ({ ...prevState, selectedlistBudgets: tableRow }))}
						// tableSize={'table-sm'}
					/>
				: 
					<div className='flex justify-center m-4'>
						<p>There are no data on this criteria. Please try adjusting your filter.</p>
					</div>
				}
				<div id="popup-modal" tabIndex="-1" className={`flex ${state.deleteModal ? '' : 'hidden'} overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 h-[calc(100%-1rem)] max-h-full`}>
                    <div className="relative p-4 w-full max-w-md max-h-full">
                        <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
                            <button type="button" className="absolute top-3 end-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white" data-modal-hide="popup-modal" onClick={() => setState(prevstate => ({ ...prevstate, deleteModal: false, showDeleteLoading: false, deleteDetails: {} }))}>
                                <svg className="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
                                </svg>
                                <span className="sr-only">Close modal</span>
                            </button>
                            <div className="p-4 md:p-5 text-center">
                                <svg className="mx-auto mb-4 text-gray-400 w-12 h-12 dark:text-gray-200" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M10 11V6m0 8h.01M19 10a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"/>
                                </svg>
                                <h3 className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">Are you sure you want to delete the budget "{state.deleteDetails && state.deleteDetails.budget_name ? state.deleteDetails.budget_name : ''}"?</h3>
                                <button data-modal-hide="popup-modal" type="button" className="py-2 px-2 rounded-md text-white bg-green mr-2" 
                                    onClick={() => {
                                        setState(prevState => ({ ...prevState, showDeleteLoading: true }))
                                        if(!state.showDeleteLoading) {
                                            deleteRecord()
                                        }
                                    }}
                                >
                                    Yes, I'm sure
                                </button>
                                <button data-modal-hide="popup-modal" type="button" className="py-2 px-2 rounded-md text-white bg-red"onClick={() => {
                                    if(!state.showDeleteLoading) {
                                        setState(prevstate => ({ ...prevstate, deleteModal: false, showDeleteLoading: false }))
                                    }
                                }}>
                                No, cancel
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
			</div>
		</div>
	)
}

export default BudgetLandingPage