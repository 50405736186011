/* eslint-disable no-mixed-spaces-and-tabs */
/*************************************************
 * Collider
 * @exports
 * @file ShareSidePanel.js
 * @author Prakash // on 16/08/2024
 * @copyright © 2024 Collider. All rights reserved.
 *************************************************/
import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Store as CommonNotification } from 'react-notifications-component';
import {Icon} from "@iconify/react";
import { shareImage } from '../../../actions/ImagebuilderAction';
import { listAllAccounts, setCommonPropsDetails } from '../../../actions/commonActionNew'
import MultiSelectSection from '../../common/MultiSelectSection';
import _ from 'lodash'

const ShareSidePanel = ({ selectedDetails, closeSidePanel }) => {
    const clickOut = useRef();
    
    const [state, setState] = useState({
        showLoading: false,
        accountsArray: [0]
    })
    const dispatch = useDispatch(); // Create a dispatch function
    const propAccounts = useSelector(state => state?.filters?.commonPropsDetails?.propAccounts || false);
        
    const handleKeyDown = useCallback((event) => {
        if (event.keyCode === 27) {
            closeSidePanel();
        }
    }, [closeSidePanel]);

    useEffect(() => {
        document.addEventListener('keydown', handleKeyDown);
    }, [handleKeyDown]);

    useEffect(() => {
        if(selectedDetails && selectedDetails.share_details && selectedDetails.share_details.length) {
            let groupedAccount = _.groupBy(selectedDetails.share_details, "account_id")
            let selectedAccount = Object.keys(groupedAccount) && Object.keys(groupedAccount).length ? Object.keys(groupedAccount) : []
            setState(prevState => ({ ...prevState, groupedAccount: selectedAccount, selectedAccount  }))
        }
        setState(prevState => ({
            ...prevState, 
            selectedProvider: selectedDetails && selectedDetails.provider ? selectedDetails.provider : ''
        }))
    }, [selectedDetails])

    useEffect(() => {
        if (state.selectedProvider) {
            let params = {};
            params.provider = state.selectedProvider.toLowerCase();
            let label = state.selectedProvider

            if(propAccounts && propAccounts.hasOwnProperty(label)) {
                setState(prevState => ({ ...prevState, accounts: propAccounts[label] }));
            } else {
                dispatch(listAllAccounts(params))
                    .then((response) => {
                        if(response) {
                            if(response.length) {
                                let obj = propAccounts ? propAccounts : {}
                                obj[label] = response
                                dispatch(setCommonPropsDetails('propAccounts', obj))
                                setState(prevState => ({ ...prevState, accounts: response }));
                            }
                        }
                    })
            }
        }
    }, [state.selectedProvider, propAccounts, dispatch]);

    useEffect(() => {
        if(state.onSubmit) {
            setState(prevState => ({ ...prevState, onSubmit: false}))
            let hasError = false
            
            let params = {
                provider: selectedDetails.provider,
                account_id: selectedDetails.account_id,
                region: selectedDetails.region,
                image_id: selectedDetails.image_id,
                ami_id: selectedDetails.ami_id
            }

            let accountsList = []
            if(state.groupedAccount && state.groupedAccount.length) {
                state.groupedAccount.forEach(acc => {
                    if(!state.selectedAccount.includes(acc)) {
                        let accRow = {}
                        accRow.account_id = acc
                        accRow.operation = 'remove'
                        accountsList.push(accRow)
                    }
                })
            }

            if(state.selectedAccount && state.selectedAccount.length) {
                state.selectedAccount.forEach(acc => {
                    if(accountsList && accountsList.length) {
                        if(!accountsList.filter(e => e.account_id === acc).length) {
                            let accRow = {}
                            accRow.account_id = acc
                            accRow.operation = 'add'
                            accountsList.push(accRow)
                        }
                    } else {
                        let accRow = {}
                        accRow.account_id = acc
                        accRow.operation = 'add'
                        accountsList.push(accRow)
                    }
                    
                })
            }

            if(accountsList && accountsList.length) {
                params.account_list = accountsList
            } else {
                hasError = true
            }
            setState(prevState => ({ ...prevState, hasError, saveLoading: !hasError ? true : false }))
            if(!hasError) {
                dispatch(shareImage(params))
                    .then((response) => {
                        if(response) {

                            setState(prevState => ({ ...prevState, saveLoading: false }))
                            let messageType = 'danger'
                            let message = response.message ? response.message : 'Error in sharing'
                            if(response.status && response.status !== 500) {
                                messageType = 'success'
                                message = response.message ? response.message : 'Image shared successfully'
                                setTimeout(() => { closeSidePanel('refresh') }, 2000); 
                            }                        

                            CommonNotification.addNotification({
                                //title: 'Wonderful!',
                                message: message,
                                type: messageType,
                                insert: 'top',
                                container: 'top-center',
                                // animationIn: ['animate__animated', 'animate__fadeIn'],
                                // animationOut: ['animate__animated', 'animate__fadeOut'],
                                dismiss: {
                                    duration: 5000,
                                    onScreen: false,
                                    pauseOnHover: true,
                                    showIcon: true,
                                }
                            });
                        }
                    })
            }
        }
    }, [dispatch, state.onSubmit, closeSidePanel, selectedDetails, state])

    const addAccount = () => {
		let selectedAccount = state.selectedAccount ? state.selectedAccount : []

        selectedAccount.push(state.enteredAccount)

		setState(prevState => ({ ...prevState, selectedAccount, enteredAccount: '' }))
	}

    const handleChildClick = (event, type, dropdownType, section) => {
        if (clickOut.current && !clickOut.current.contains(event.target)) {
            closeSidePanel()
        }

		event.stopPropagation();
        
		let clickedChild = []
		if(type === 'child') {
            if(state.clickedChild && state.clickedChild.length) {
                state.clickedChild.forEach(row => {
                    setState(prevState => ({ ...prevState, [row]: false }))
                })
            }
            clickedChild.push(section)
            setState(prevState => ({ ...prevState, [section]: dropdownType === "singleDropDown" && state[section] ? false : true, clickedChild }))
        } else if(type === 'parent') {
            if(state.clickedChild && state.clickedChild.length) {
                state.clickedChild.forEach(row => {
                    setState(prevState => ({ ...prevState, [row]: false }))
                })
            }
            
            setState(prevState => ({ ...prevState, clickedChild }))
        }
	}

    return (
        <div className='advanced-search' style={{ zIndex: 999 }} onClick={(event) => handleChildClick(event, 'parent')}>
            <div className={`${state.showLoading ? '' : 'hidden'}`}>
                <div className='loading-wave'>
                    <div className='overlayEqualizerLoader'>
                        <div className="loading-bar"></div>
                        <div className="loading-bar"></div>
                        <div className="loading-bar"></div>
                        <div className="loading-bar"></div>
                    </div>
                </div>
            </div>
            <div className='search w-3/5 bg-muted min-h-screen overflow-auto' ref={clickOut}>
                <div className='header-search bg-black flex justify-between p-2'>
                    <div className='text-white lg:w-1/2 w-full self-center'>
                        <span className='font-bold text-xl text-white'>Shared Details</span>
                    </div>

                    <div className='self-center'>
                        <Icon icon="jam:close" className='text-white cursor-pointer' width="25" height="25" onClick={() => closeSidePanel()} />
                    </div>
                </div>
                <div className='m-2 overflow-auto'>
                    <div className='rounded bg-dark p-3 mt-3'>
                        <div className='flex py-1'>
                            <div className='lg:w-1/5 md:w-1/4 w-1/3'>
                                <p className='b-block mb-0'>Provider</p>
                                <p className={`bg-transparent border border-lightGray rounded-md px-2 py-1 justify-between px-2 mb-0 text-white`}>{state.selectedProvider ? state.selectedProvider.toUpperCase() : ''}</p>
                            </div>
                        </div>

                        <div className='mt-2 border-t border-darkGray'>
                            {state.hasError && (!state.selectedAccount || state.selectedAccount.length) ?
                                <p className='text-danger'>Please select Account</p>
                            : null}
                            
                            <div className='flex flex-wrap md:w-2/3 w-full'>
                                <div className='py-1 lg:w-5/12 md:w-2/5 w-full'>
                                    <p className="b-block mb-0 w-full">{state.selectedProvider === 'gcp' ? 'Service' : ''} Account</p>
                                    {state.selectedProvider === 'aws' ?
                                        <div onClick={(event) => {
                                                if(!state.isAccountOpen) {
                                                    event.preventDefault();
                                                    handleChildClick(event, 'child', 'muliselectDropDown', 'isAccountOpen')
                                                }
                                            }}
                                        >
                                            <p className={`flex border border-lightGray rounded-md bg-transparent px-2 py-1 justify-between mb-2 ${state.selectedAccount && state.selectedAccount.length ? 'text-white' : 'text-lightGray'}`}>
                                                {state.selectedAccount && state.selectedAccount.length ? state.selectedAccount.length +' Selected' : 'Select'}
                                                <Icon icon="icon-park-solid:down-one" className={`${state.selectedAccount && state.selectedAccount.length ? "text-white" : "text-lightGray"} self-center`} width="16" height="16" />
                                            </p>
                                            {state.isAccountOpen ?
                                                <MultiSelectSection
                                                    fields={['account_id', 'account_name']}
                                                    options={state.accounts}
                                                    selectedValues={state.selectedAccount ? state.selectedAccount : []}
                                                    callbackMultiSelect={(value) => 
                                                        setState(prevState => ({ ...prevState, selectedAccount: value }))
                                                    }
                                                    hideSearch={true}
                                                    topClass={'auto'}
                                                    widthClass={'minWidth220'}
                                                    removeTopOptions={true}
                                                />
                                            : null}
                                        </div>
                                    : 
                                        <div className='flex'>
                                        <input
                                            type='text'
                                            placeholder='Enter account'
                                            className={`border border-lightGray rounded-md bg-transparent px-2 py-1 w-full text-white`}
                                            value={state.enteredAccount ? state.enteredAccount : ''}
                                            onChange={e => setState(prevState => ({ ...prevState, enteredAccount: e.target.value}))}
                                        />
                                        <Icon icon="iconoir:plus" width="25" height="25"  className='text-white ml-3 self-center mt-0 cursor-pointer' onClick={() => addAccount()}/>
                                        </div>
                                    }
                                </div>
                                {/* {state.selectedAccount && state.selectedAccount.length ? 
                                    <div className='flex flex-wrap'>
                                        {state.selectedAccount.map((acc, tIndex) => {
                                            return(
                                                <span key={'acc_'+tIndex} className='flex border border-lightGray rounded-full mr-2 mt-2 self-center text-info pl-2 pr-1 breakWhiteSpace' id={'acc_'+tIndex}>
                                                    <div className='truncate'  style={{maxWidth: '250px'}}>
                                                        {acc}
                                                    </div>
                                                    <Icon icon="ion:close-circle-sharp" width="16" height="16"  className='text-white ml-2 self-center' onClick={() => removeAccount()}/>
                                                </span>
                                            )
                                        })}
                                    </div>
                                : null} */}
                            </div>
                        </div>
                        <div className='flex flex-wrap mt-2 gap-2'>
                            {state.selectedAccount && state.selectedAccount.length ? 
                                state.selectedAccount.map((item, index) => {
                                    return(
                                        <span key={'item_'+index} className="flex border border-info rounded-full px-2 py-1 self-center text-white">{item}
                                            <Icon icon="ion:close-circle-sharp" width="16" height="16"  className='text-white ml-2 self-center cursor-pointer' onClick={() => {
                                                setState(prevState => ({ ...prevState, selectedAccount: state.selectedAccount.filter(e => e !== item) }))  
                                            }}/>
                                        </span>
                                    )
                                })
                            : null}
                        </div>
                    </div>

                    <div className={`flex mb-2 mt-4 justify-end`}>
                        {state.saveLoading ?
                            <button className='flex border border-green px-2 py-1 bg-green text-white rounded-md'>
                                <svg className="animate-spin h-5 w-5 mr-2 text-white" fill="currentColor" viewBox="0 0 24 24">
                                    <circle cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="2" fill="none" />
                                    <path d="M4 12a8 8 0 0112-6.9" />
                                </svg>
                                Submitting
                            </button>
                        : 
                            <button className={`flex justify-end cursor-pointer border border-green px-2 py-1 bg-green rounded-md text-white`} onClick={ () => setState(prevState => ({ ...prevState, saveLoading: true, onSubmit: true }))}>Submit</button>
                        }
                        <button className={`ml-2 flex justify-end cursor-pointer border border-lightGray bg-lightGray rounded-md px-2 py-1`} onClick={ () => closeSidePanel()}>Cancel</button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ShareSidePanel;