/* eslint-disable no-mixed-spaces-and-tabs */
/*************************************************
 * Collider
 * @exports
 * @file PermissionDenied.js
 * @author Prakash // on 06/02/2024
 * @copyright © 2024 Collider. All rights reserved.
 **************************************************/
import React from 'react';
const PermissionDenied = () => {

    // useEffect(() => {
    // }, [])

    /**
     * Renders permission denied
     */
    return (
        <div className='container-fluid overflow-auto flex-grow-1'>
            <div className='flex justify-center text-white h-100'>
                <h5 className='self-center'>Permission denied to access this page, contact administrator for more details.</h5>
            </div>
        </div>
    )
}

export default PermissionDenied