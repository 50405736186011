/*************************************************
 * Collider
 * @exports
 * @file StickyDarkThemeTable.js
 * @author Prakash //on 15/06/2023
 * @copyright © 2022 Collider. All rights reserved.
 *************************************************/
import React, { useState } from 'react'
import { useTable, useRowSelect, useBlockLayout, useResizeColumns, useSortBy, usePagination } from 'react-table'
import { UncontrolledTooltip } from 'reactstrap'
// import styled from "styled-components";
import { useSticky } from "react-table-sticky";
import {Icon} from "@iconify/react";

const toolTipModifiers = {
	flip: { behavior: ['top'] },
	preventOverflow: { boundariesElement: 'viewport' },
}

function StickyDarkThemeTable({
	columns,
	data,
	dashboard,
	perPage = 10,
	perPageClass=false,
	sortByColumn,
	sortByType = true,
	tooltipFor = false,
	riskTooltip = false,
	onClickRow,
	selectedRecord,
	tableHead = false,
	tableBody = false,
	tableSize = false,
	columnFontSize = false,
	rounded = false,
	tableType = false,
	textColor = false,
	selectedColor = false,
	deSelectBackground = false,
	emptyMessage = '',
	defaultSelectedRecord = 0,
}) {
	let sortBy = []
	// columns = React.useMemo(
	// 	() => columns, []
	// )
	const defaultColumn = React.useMemo(
		() => ({
			// When using the useFlexLayout:
			minWidth: 30, // minWidth is only used as a limit for resizing
			width: 150, // width is used for both the flex-basis and flex-grow
			maxWidth: 600, // maxWidth is only used as a limit for resizing
		}),
		[]
	)
	if (sortByColumn && sortByColumn !== '') {
		if (!sortByType) {
			sortBy = [
				{
					id: sortByColumn,
					asc: true,
				},
			]
		} else {
			sortBy = [
				{
					id: sortByColumn,
					desc: true,
				},
			]
		}
	}

	const [selectedRowIndex, setSelectedRowIndex] = useState(defaultSelectedRecord)
	const {
		getTableProps,
		getTableBodyProps,
		headerGroups,
		page,
		prepareRow,
		canPreviousPage,
		canNextPage,
		pageOptions,
		pageCount,
		gotoPage,
		nextPage,
		previousPage,
		setPageSize,
		// preGlobalFilteredRows,
		// setGlobalFilter,
		// state,
		// rows,
		// selectedFlatRows,
		// state: { pageIndex, pageSize, selectedRowIds },
		state: { pageIndex, pageSize },
	} = useTable(
		{
			columns,
			defaultColumn,
			data,
			initialState: {
				pageIndex: 0,
				pageSize: perPage,
				sortBy: sortBy,
			},
		},
		useBlockLayout,
		useResizeColumns,
		useSortBy,
		usePagination,
		useRowSelect,
		useSticky
	)

	return (
		<React.Fragment>
			<div className={`${rounded ? 'rounded' : ''} table-responsive overflow-auto`}>
				<table
					{...getTableProps()}
					className={`table w-full text-left table-sm
						${tableSize ? tableSize : 'table-sm'} 
						${tableType ? tableType : 'table-dark-hover'} 
						${textColor ? textColor : 'text-white'} 
					`}
				>
					<thead className={`${tableHead ? tableHead : 'bg-muted'}`}>
						{headerGroups.map((headerGroup, i) => (
							<tr key={i} {...headerGroup.getHeaderGroupProps()}>
								{headerGroup.headers.map((column, index) => (
									<th key={index} 
										{...column.getHeaderProps()} onClick={() => setSelectedRowIndex(0)} 
										className={`${column.textColor ? column.render("textColor") : ""}`}
									>
										<span
											{...column.getSortByToggleProps({ title: 'Sort' })}
											style={{ marginRight: 10, cursor: 'pointer' }}
											>
											<span className="w-100">
												{column.isSorted ?
													<Icon icon={`fa-solid:sort-${column.isSortedDesc ? 'down' : 'up'}`} color="white" width="16" height="16" className="pt-1" />
												: null}
												<span className={`${column.isSorted ? "ml-1" : ""}`}>{column.render('Header')}</span> 
												{column.HeaderUnit ?
													<span className="text-lightGray self-center ml-1">({column.HeaderUnit})</span>
												: null}
											</span>
										</span>
										{headerGroup.headers.length - 1 === index ? null : (
											<div {...column.getResizerProps()} className={`resizer`} />
										)}
									</th>
								))}
							</tr>
						))}
					</thead>
					<tbody className={`${tableBody ? tableBody : 'bg-secondary'}`} {...getTableBodyProps()}>
						{page.map((row, i) => {
							if (selectedRecord && !i && !selectedRowIndex && selectedRecord !== row.original) {
								onClickRow(row.original, '')
							}
							prepareRow(row)
							return (
								<tr className={`${deSelectBackground ? '' : selectedRowIndex === i ? (selectedColor ? selectedColor : 'bg-lightGray') : ''} w-full`} key={i}
									{...row.getRowProps()}
									onClick={() => {
										onClickRow(row.original, 'click')
										setSelectedRowIndex(i)
									}}
								>
									{row.cells.map((cell, index) => {
										return (
											<React.Fragment key={index}>
												{tooltipFor && tooltipFor.includes(index) && (
													<UncontrolledTooltip
														key={index}
														placement='top'
														modifiers={toolTipModifiers}
														target={`targetIdNotify${i}${index}`}
													>
														{cell.render('Cell')}
													</UncontrolledTooltip>
												)}
												{riskTooltip &&
													riskTooltip.includes(index) &&
													(cell.row.values.severity ? (
														<span className='uncontrolledTooltip'>
															<UncontrolledTooltip
																/* isOpen={true} */
																key={index}
																placement='top'
																modifiers={toolTipModifiers}
																target={`targetIdNotify${i}${index}`}
																//trigger="click"
																//className="uncontrolledTooltip"
																/* style={{backgroundColor:'red'}} */
															>
																{cell.row.values.severity}
															</UncontrolledTooltip>
														</span>
													) : null)}
												<td
													key={index}
													className={`${columnFontSize} ${cell.column.textColor} ${
														!cell.column.className || cell.column.className === ''
															? 'truncate'
															: cell.column.className
													} px-4 py-2 font-medium text-sm self-center`}
													{...cell.getCellProps()}
													id={`targetIdNotify${i}${index}`}
												>
													{cell.render('Cell')}
												</td>
											</React.Fragment>
										)
									})}
								</tr>
							)
						})}

						{data.length ? null : (
							<tr className='bg-lightGray text-center'>
								<td colSpan={columns.length} className='text-truncate border-0'>
									{emptyMessage !== '' ? (
										<span>{emptyMessage}</span>
									) : (
										<span>There are no data on this criteria. Please try adjusting your filter.</span>
									)}
								</td>
							</tr>
						)}
					</tbody>
				</table>
			</div>
			{dashboard && data.length > perPage ? (
				<div className='pagination f12'>
					Rows per page:{' '}
					<select
						className={`${perPageClass ? perPageClass : "bg-primary text-lightGray"}`}
						value={pageSize}
						onChange={e => {
							setPageSize(Number(e.target.value))
						}}
					>
						{[perPage * 1, perPage * 2, perPage * 3, perPage * 4, perPage * 5].map(pageSize => (
							<option className='text-lightGray' key={pageSize} value={pageSize}>
								{pageSize}
							</option>
						))}
					</select>
					<span className='mx-3 pt-1'>
						Page {pageIndex + 1} of {pageOptions.length}{' '}
					</span>
					<button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
						<Icon icon="system-uicons:push-left" className=' mr-1' />
					</button>{' '}
					<button onClick={() => previousPage()} disabled={!canPreviousPage}>
						<Icon icon="basil:arrow-left-outline" className=' mr-1' />
					</button>{' '}
					<button onClick={() => nextPage()} disabled={!canNextPage}>
						<Icon icon="basil:arrow-right-outline" className=' mr-1' />
					</button>{' '}
					<button onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
						<Icon icon="system-uicons:push-right" />
					</button>
				</div>
			) : null}
		</React.Fragment>
	)
}

export default StickyDarkThemeTable
