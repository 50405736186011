/*************************************************
 * Collider
 * @exports
 * @file LeftSection.js
 * @author Prakash // on 25/01/2024
 * @copyright © 2024 Collider. All rights reserved.
 *************************************************/
import React, { useCallback, useEffect, useState} from 'react';
import { useDispatch, useSelector } from 'react-redux'; // Import useDispatch hook to dispatch actions
import { useNavigate } from 'react-router-dom'
import MultiSelectSection from '../../../common/MultiSelectSection';
import { Store as CommonNotification } from 'react-notifications-component';
import { URL_PATH } from '../../../../config/urlPath';

import { getRegionName, capitalizeAllLetter, thousandSeparator, onlyNumeric } from '../../../../utils/utility'

import { setHpcPropsDetails, insertBudgetDetails, updateBudgetDetails, listBudgetDetails } from  '../../../../actions/Collider/HpcAction'
import { listClusterFilters, setCommonPropsDetails } from '../../../../actions/commonActionNew'
import { listDepartments, getDepartmentBudget } from '../../../../actions/cost/BudgetAction'
import {Icon} from "@iconify/react";

const LeftSection = (props) => {
	const[state, setState] = useState({
		periods: [
			{label: "Daily", value: "daily"},
			{label: "Monthly", value: "monthly"},
			{label: "Quarterly", value: "quarterly"},
			{label: "Half Yearly", value: "half-yearly"},
			{label: "Annual", value: "yearly"},
		]
	})

    const navigate = useNavigate();
	const dispatch = useDispatch(); // Create a dispatch function
	
    const createInputs = useSelector(state => state?.hpc?.hpcPropsDetails?.createInputs || false);
    const propProjRegions = useSelector(state => state?.filters?.commonPropsDetails?.propProjRegions || false);
    const departmentList = useSelector(state => state?.cost?.listDepartments || false);	
    const onClickSave = useSelector(state => state?.hpc?.hpcPropsDetails?.onClickSave || false);    

	const onClickEditBudget = useCallback((row) => {
        let selectedData = row.selectedData ? row.selectedData : {}
		let details = selectedData.details ? selectedData.details : []
		let selectedProvider = ''
        let providerDetails = []
		details.forEach((item, index) => {
            if(!index) {
                selectedProvider = item.provider
            }
            let proRow = {}
            proRow.provider = item.provider
            proRow.comitted = Math.round(item.budget)
            let spendAmount = 0
            let periodArray = [0]
            let initialArray = []
            if(item.applies_to && item.applies_to.length) {
                periodArray = []
                item.applies_to.forEach((app, appIndex) => {
                    initialArray.push(appIndex)
                    periodArray.push(appIndex)
                    setState(prevState => ({ 
                        ...prevState, 
                        ['selectedAccount_'+app.provider+'_'+appIndex]: app.account_id,
                        ['selectedRegion_'+app.provider+'_'+appIndex]: app.region,
                        ['selectedClusters_'+app.provider+'_'+appIndex]: app.cluster_name,
                        ['budgetAmount_'+app.provider+'_'+appIndex]: app.budget ? app.budget : 0
                    }))
                    spendAmount += Math.round(app.budget)
                })
            }
            setState(prevState => ({ ...prevState, ["period_array_"+item.provider]: periodArray, ["initital_array_"+item.provider]: initialArray}))
            
            proRow.allocated = spendAmount
            proRow.balance = Math.round(item.budget) - spendAmount
            providerDetails.push(proRow)
		})
        
		setState(prevState => ({ 
			...prevState,
            providerDetails, 
			selectedData,
            selectedProvider,
			budget_name: selectedData.budget_name,
			financial_year: selectedData.financial_year,
            description: selectedData.description,
			department: selectedData.department,
			selectedInitiative: selectedData.initiative,
			selectedProject: selectedData.project,
            budget_id: selectedData.budget_id,
		}))

        dispatch(setHpcPropsDetails('createInputs', selectedData))
	}, [dispatch])

    useEffect(() => {
        if(props.receivedData && Object.entries(props.receivedData).length) {
            setState(prevState => ({ 
                ...prevState, 
                selectedData: props.receivedData.selectedData ? props.receivedData.selectedData : '',
                pageType: props.receivedData.pageType ? props.receivedData.pageType : '',
            }))
            if(props.receivedData.pageType) {
                onClickEditBudget(props.receivedData)
            }
        }
    }, [props.receivedData, onClickEditBudget])

    // useEffect(() => {
    //     let hasError = false
    //     let details = createInputs && createInputs.details ? createInputs.details : []
    //     let providerDetails = []
    //     let providerError = []
    //     details.forEach(row => {
    //         let proRow = {}
    //         proRow.provider = row.provider
    //         proRow.comitted = Math.round(row.budget)
    //         let spendAmount = 0
    //         if(row.applies_to) {
    //             row.applies_to.forEach(app => {
    //                 spendAmount += Math.round(app.budget)
    //             })
    //         }
    //         proRow.allocated = spendAmount
    //         proRow.balance = Math.round(row.budget) - spendAmount
    //         providerDetails.push(proRow)
            
    //         if(proRow.balance < 0) {
    //             hasError = true
    //             let error = 'Allocated budget amount should not exceed '+ capitalizeAllLetter(row.provider)+' provider budget '
    //             providerError.push(error)
    //         }
    //         setState(prevState => ({ ...prevState, providerError }))

            
    //         let queueTotal = 0
    //         if(row.queues && row.queues.length) {
    //             queueTotal = row.queues.reduce((sum, queue) => sum + queue.budget, 0);
    //         }

    //         let queueError = []
    //         if(queueTotal > Math.round(row.budget)) {
    //             hasError = true
    //             let error = 'The total queue budget should not exceed cluster '+row.cluster_name+' budget of '+row.provider+' provider'
    //             queueError.push(error)

    //             setState(prevState => ({ ...prevState, queueError }))
    //         }
            
    //         let userTotal = 0
    //         if(row.users && row.users.length) {
    //             userTotal = row.users.reduce((sum, user) => sum + user.budget, 0);
    //         }

    //         let userError = []
    //         if(userTotal > Math.round(row.budget)) {
    //             hasError = true
    //             let error = 'The total user budget should not exceed cluster '+row.cluster_name+' budget of '+row.provider+' provider'
    //             userError.push(error)

    //             setState(prevState => ({ ...prevState, userError }))
    //         }

    //         setState(prevState => ({ ...prevState, 
    //             ['queueTotal_'+row.provider+'_'+row.cluster_name]: queueTotal,
    //             ['userTotal_'+row.provider+'_'+row.cluster_name]: userTotal, 
    //         }))
    //     })

    //     setState(prevState => ({ ...prevState, providerDetails, hasError }))
    // }, [createInputs])

	useEffect(() => {
        // let shortCurrentYear = momentDateFormat(new Date(), "YY") 
        const currentYear = new Date().getUTCFullYear();
        // Calculate the next 10 years
        const next10Years = Array.from({ length: 11 }, (_, index) => currentYear + index);
        setState(prevState => ({ ...prevState, yearOptions: next10Years }))

        if(!departmentList || !departmentList.length) {
            let depParams = {}
            dispatch(listDepartments(depParams))
                .then((response) => {
                    let results =  response && response.results ? response.results : []
                    setState(prevState => ({
                        ...prevState,
                        departmentsList: results,
                    }))
                })
        } else {
            setState(prevState => ({
                ...prevState,
                departmentsList: departmentList
            }))
        }

    }, [dispatch, departmentList])

	useEffect(() => {
        if(state.department && state.financial_year) {
            let params = {}
            params.department = state.department
            params.year = state.financial_year
            dispatch(getDepartmentBudget(params))
                .then((response) => {
                    setState(prevState => ({
                        ...prevState,
                        departmentDetails: response,
						initiativesList: [],
                        formatDepartmentResponse: true
                    }))
                })
        }
    }, [state.department, state.financial_year, dispatch])

    useEffect(() => {
        if(state.formatDepartmentResponse) {
            let res = state.departmentDetails
            
			let initiativesList = []
            let initiatives = res.initiatives ? res.initiatives : []
            if(initiatives && initiatives.length) {
				initiatives.forEach(row => {
					let initRows = {}
					initRows.label = row.initiative
					initRows.value = row.initiative
					initRows.allocation = row.allocation ? row.allocation : 0
					let projects = []
					if(row.projects && row.projects.length) {
						row.projects.forEach(proj => {
							let projRow = {}
							projRow.label = proj.project
							projRow.value = proj.project
							projRow.allocation = proj.allocation ? proj.allocation : 0

							let providers = []
							if(proj.cloud_budget && Object.entries(proj.cloud_budget).length) {
								Object.entries(proj.cloud_budget).forEach(([key, value]) => {
									let provRow = {}
									provRow.label = key
									provRow.value = key
									provRow.allocation = value.allocation ? value.allocation : 0
									// projRow.accounts = value.accounts ? value.accounts :[]
									provRow.accounts = proj.accounts && proj.accounts.filter(e => e.cloudProviderId === key).length ? proj.accounts.filter(e => e.cloudProviderId === key) : []
									providers.push(provRow)
								})
							}
							projRow.providers = providers
							projects.push(projRow)
						})
					}
					initRows.projects = projects
					initiativesList.push(initRows)
				})
            }

			setState(prevState => ({ ...prevState, initiativesList }))
        }
    }, [state.formatDepartmentResponse, state.departmentDetails])

	useEffect(() => {
        if(state.selectedInitiative && state.initiativesList) {
            let projectList = state.initiativesList.filter(e => e.label === state.selectedInitiative).length && state.initiativesList.filter(e => e.label === state.selectedInitiative)[0].projects ? state.initiativesList.filter(e => e.label === state.selectedInitiative)[0].projects : []
            setState(prevState => ({ ...prevState, projectList }))
		}
	}, [state.selectedInitiative, state.initiativesList])

	useEffect(() => {
        if(state.selectedProject && state.projectList) {
			let providerList = state.projectList.filter(e => e.label === state.selectedProject).length && state.projectList.filter(e => e.label === state.selectedProject)[0].providers ? state.projectList.filter(e => e.label === state.selectedProject)[0].providers : []
			setState(prevState => ({ ...prevState, providerList }))
		}
	}, [state.selectedProject, state.projectList])

    const onClickAppliesTo = useCallback((field, value, provider, arrIndex) => {
        if(field) {
            if(field === 'provider') {
                provider = value
            }
        }
        
        if(state['budgetAmount_'+provider+'_'+arrIndex]) {
            let obj = {}
            obj.selectedProvider = provider
            obj.selectedAccount = state['selectedAccount_'+provider+'_'+arrIndex]
            obj.selectedRegion = state['selectedRegion_'+provider+'_'+arrIndex]
            obj.selectedClusters = state['selectedClusters_'+provider+'_'+arrIndex]
            if(field === 'budgetAmount') {
                obj.budget = parseFloat(value)
            } else {
                obj.budget = parseFloat(state['budgetAmount_'+provider+'_'+arrIndex])
            }
            dispatch(setHpcPropsDetails('selectedAplliesTo', obj))
        } else {
            dispatch(setHpcPropsDetails('selectedAplliesTo', {}))
        }
    }, [dispatch, state])

    // useEffect(() => {
    //     if(state.selectedProvider) {
	// 		setState(prevState => ({ 
    //             ...prevState,
    //             selectedAppliesTo: 0
    //         }))
    //         // onClickAppliesTo('', '' , state.selectedProvider, 0)
	// 	}
	// }, [state.selectedProvider, onClickAppliesTo])

    // useEffect(() => {

    // }, [state.selectedAppliesTo])
    
	useEffect(() => {
		if(state.callInitializeFilterArray) {
			if(state.selectedProvider && state.pageType !== 'edit') {
				let rowList = state["period_array_"+state.selectedProvider] ? state["period_array_"+state.selectedProvider] : [0]
				setState(prevState => ({ ...prevState, ["period_array_"+state.selectedProvider]: rowList, callInitializeFilterArray: false }))
			} else {
				setState(prevState => ({ ...prevState, callInitializeFilterArray: false }))
			}
		}
	}, [state.callInitializeFilterArray, state])     

	const getClusterDetailsFunction = (account, region, provider, arrIndex) => {
		let params = {};
		params.provider = provider
		
		if(account) {
			params.account_id = account
		}
		if(region) {
			params.region = region
		}
        params.aggregate_by = 'cluster_name';
        if(provider) {
            dispatch(listClusterFilters(params))
                .then((response) => {
                    if(response) {
                        setState(prevState => ({ ...prevState, ['clusters_'+provider+'_'+arrIndex]: response, ['selectedClusters_'+provider+'_'+arrIndex]: '' }))
                    }
                })
        }
    }

	useEffect(() => {
		if(state.selectedProvider) {
			let params = {}
			params.provider = state.selectedProvider
            params.aggregate_by = 'region';
            let label = params.provider
            
            if(propProjRegions && propProjRegions.hasOwnProperty(label)) {
                setState(prevState => ({ ...prevState, ['regions_'+state.selectedProvider]: propProjRegions[label] }));
            } else {
                dispatch(listClusterFilters(params))
                    .then((response) => {
                        if(response && response.length) {
                            let obj = propProjRegions ? propProjRegions : {}
                            obj[label] = response
                            dispatch(setCommonPropsDetails('propProjRegions', obj))
                        }
                        setState(prevState => ({ ...prevState, ['regions_'+state.selectedProvider]: response }))
                    })
            }
		}
	}, [state.selectedProvider, dispatch, propProjRegions])

    useEffect(() => {
        if(state.selectedProvider && state.providerList) {
			let providerItems = state.providerList.filter(e => e.value === state.selectedProvider) && state.providerList.filter(e => e.value === state.selectedProvider).length ? state.providerList.filter(e => e.value === state.selectedProvider)[0] : {}
			setState(prevState => ({ 
                ...prevState, 
                ['budgetAmount_'+state.selectedProvider]: providerItems.allocation ? providerItems.allocation : 0,
                ['accounts_'+state.selectedProvider]: providerItems.accounts ? providerItems.accounts : [], callInitializeFilterArray: true, selectedAppliesTo: 0
            }))

            // onClickAppliesTo('', '', state.selectedProvider, 0)
		}
	}, [state.selectedProvider, state.providerList])

	const addFilterArray = (provider) => {
		let rowList = state["period_array_"+provider] ? state["period_array_"+provider] : [0]
        let selectedAppliesTo = 0
		if (state["period_array_"+provider]) {
			let value = state["period_array_"+provider][state["period_array_"+provider].length - 1]
			value = value + 1
			rowList.push(value)

            selectedAppliesTo = value
		}

		setState(prevState => ({ ...prevState, ["period_array_"+provider]: rowList, selectedAppliesTo }))
	}

	const removeFilterArray = (provider, index, item) => {
		let rowList = state["period_array_"+provider] ? state["period_array_"+provider] : [0]
		rowList.splice(index, 1);
        if(state["initital_array_"+provider]) {
            let initialArray = state["initital_array_"+provider].filter(e => e !== item)
            setState(prevState => ({ ...prevState, ["initital_array_"+provider]: initialArray }))
        }
		setState(prevState => ({ ...prevState, ["period_array_"+provider]: rowList }))

        setTimeout(() => { handleAppliesToInputChange() }, 500);
    }

    const initializeState = useCallback((label) => {
        // setState(prevState => ({ 
        //     ...prevState,
        //     selectedProject: '',
        //     providerDetails: [],
        //     providerError: [],
        //     'queueError' : [],
        //     'userError' : [],
        // }))
        // let obj = createInputs ? createInputs : {}
        // let details = obj.details ? obj.details : []
        // if(label === 'financial_year') {
        //     obj.department = ''
        //     obj.initiative = ''
        //     obj.project = ''
        //     setState(prevState => ({  ...prevState, department: '', selectedInitiative: '', selectedProject: '' }))
        // } else if(label === 'department') {
        //     obj.initiative = ''
        //     obj.project = ''
        //     setState(prevState => ({  ...prevState, selectedInitiative: '', selectedProject: '' }))
        // } else if(label === 'initiative') {
        //     obj.project = ''
        //     setState(prevState => ({  ...prevState, selectedProject: '' }))
        // }
        // details.forEach(row => {
        //     let proRow = {}
        //     proRow.provider = row.provider
        //     if(state["period_array_"+row.provider] && state["period_array_"+row.provider].length) {
        //         state["period_array_"+row.provider].forEach(row => {
        //             setState(prevState => ({ 
        //                 ...prevState,
        //                 ['selectedAccount_'+row.provider+'_'+row]:  '',
        //                 ['selectedRegion_'+row.provider+'_'+row]:  '',
        //                 ["selected_tags_"+row.provider+'_'+row]:  '',
        //                 ['selectedClusters_'+row.provider+'_'+row]: ''
        //             }))
        //         })
        //     }
        // })
        // obj.details = []

        // dispatch(setHpcPropsDetails('createInputs', obj))
    }, [])

    const validateFunction = useCallback(() => {
        let hasError = false
        let obj = createInputs ? createInputs : {}
        if(!obj.budget_name || !obj.financial_year || !obj.department || !obj.initiative || !obj.project) {
            hasError = true
        }

        let details = obj && obj.details ? obj.details : []
        let providerDetails = []
        let providerError = []
        details.forEach(row => {
            let proRow = {}
            proRow.provider = row.provider
            proRow.comitted = Math.round(row.budget)
            let spendAmount = 0
            if(row.applies_to) {
                row.applies_to.forEach(app => {
                    spendAmount += Math.round(app.budget)
                })
            }
            proRow.allocated = spendAmount
            proRow.balance = Math.round(row.budget) - spendAmount
            providerDetails.push(proRow)
            
            if(proRow.balance < 0) {
                hasError = true
                let error = 'Allocated budget amount should not exceed '+ capitalizeAllLetter(row.provider)+' provider budget '
                providerError.push(error)
            }
            
            setState(prevState => ({ ...prevState, providerError }))

            let queueTotal = 0
            if(row.queues && row.queues.length) {
                queueTotal = row.queues.reduce((sum, queue) => sum + queue.budget, 0);
            }

            let queueError = []
            if(queueTotal > Math.round(row.budget)) {
                hasError = true
                let error = 'The total queue budget should not exceed cluster '+row.cluster_name+' budget of '+row.provider+' provider'
                queueError.push(error)

                setState(prevState => ({ ...prevState, queueError }))
            }
                        
            let userTotal = 0
            if(row.users && row.users.length) {
                userTotal = row.users.reduce((sum, user) => sum + user.budget, 0);
            }

            let userError = []
            if(userTotal > Math.round(row.budget)) {
                hasError = true
                let error = 'The total user budget should not exceed cluster '+row.cluster_name+' budget of '+row.provider+' provider'
                userError.push(error)

                setState(prevState => ({ ...prevState, userError }))
            }

            setState(prevState => ({ ...prevState, 
                ['queueTotal_'+row.provider+'_'+row.cluster_name]: queueTotal,
                ['userTotal_'+row.provider+'_'+row.cluster_name]: userTotal, 
            }))
        })

        setState(prevState => ({ ...prevState, providerDetails }))
        if(onClickSave === 'save') {
            if(!hasError) {
                if(state.pageType === 'edit') {
                    setState(prevState => ({ ...prevState, callUpdateFunction: true }))
                } else {
                    setState(prevState => ({ ...prevState, callSaveFunction: true }))
                }
            } else {
                setState(prevState => ({ ...prevState, hasError }))
                dispatch(setHpcPropsDetails('onClickSave', 'stopLoading'))
            }
        }
    }, [createInputs, dispatch, state.pageType, onClickSave])

    const fetchEditData = () => {
        let obj = {}
        obj.pageType = 'edit'
        obj.selectedData = state.alreadyExistData
        onClickEditBudget(obj)

        setState(prevState => ({ ...prevState, confirmAction: false, pageType: 'edit' }))
    }

    const handleInputChange = (label, value) => {
		let obj = createInputs ? createInputs : {}
        obj[label] = value
        if(label === 'financial_year' || label === 'department' || label === 'initiative') {
            initializeState(label)
        }
        if(label === 'project') {
            let alreadyExist = false
            let alreadyExistMessage = ''
            if(state.department && state.selectedInitiative) {
                let params = {}
                params.financial_year = state.financial_year
                params.department = state.department
                params.initiative = state.selectedInitiative
                params.project = value
                dispatch(listBudgetDetails(params))
                    .then((response) => {
                        if(response && response.length) {
                            alreadyExist = true
                            // alreadyExistMessage = 'The selected combination already exist'
                            setState(prevState => ({ ...prevState, alreadyExistMessage, alreadyExist, alreadyExistData: response[0], confirmAction: true }))
                            // dispatch(setHpcPropsDetails('createInputs', response[0]))                            
                        } else {
                            setState(prevState => ({ ...prevState, alreadyExistMessage, alreadyExist }))
                        }
                    })
            }

            if(!alreadyExist) {
                let providerDetails = []
                let providerOptions = state.projectList.filter(e => e.label === value)[0].providers ? state.projectList.filter(e => e.label === value)[0].providers : []
                let details = []
                if(providerOptions && providerOptions.length) {
                    providerOptions.forEach(row => {
                        let detailRow = {}
                        detailRow.provider = row.label
                        detailRow.budget = row.allocation
                        detailRow.applies_to = []
                        details.push(detailRow)
    
                        let proRow = {}
                        proRow.provider = row.label
                        proRow.comitted = Math.round(row.allocation)
                        proRow.allocated = 0
                        proRow.balance = Math.round(row.allocation)
                        providerDetails.push(proRow)
                        setState(prevState => ({ ...prevState, ["period_array_"+row.label]: [0] }))
                    })
    
                }
                
                obj.details = details
    
                setState(prevState => ({ ...prevState, providerDetails }))
                dispatch(setHpcPropsDetails('createInputs', obj))
            }

        } else {
            dispatch(setHpcPropsDetails('createInputs', obj))
        }

        validateFunction()
    }

    const handleAppliesToInputChange = (label, value, index) => {
		let obj = createInputs ? createInputs : {}
        let details = obj.details
        if(details && details.length) {
            details.forEach(row => {
                let appliesTo = []
                state["period_array_"+row.provider].forEach(arrIndex => {
                    let appliesRow = {}
                    appliesRow.provider = row.provider
                    appliesRow.account_id = state['selectedAccount_'+row.provider+'_'+arrIndex] ? state['selectedAccount_'+row.provider+'_'+arrIndex] : ''
                    if(state['selectedRegion_'+row.provider+'_'+arrIndex]) {
                        appliesRow.region = state['selectedRegion_'+row.provider+'_'+arrIndex]
                    }
                    appliesRow.cluster_name = state['selectedClusters_'+row.provider+'_'+arrIndex] ? state['selectedClusters_'+row.provider+'_'+arrIndex] : ''
                    if(label === 'budget' && index === arrIndex) {
                        appliesRow.budget = Math.round(parseFloat(value))
                    } else {
                        appliesRow.budget = Math.round(state['budgetAmount_'+row.provider+'_'+arrIndex] ? parseFloat(state['budgetAmount_'+row.provider+'_'+arrIndex]) : 0)
                    }
                    if(appliesRow.account_id && appliesRow.cluster_name) {  
                        row.applies_to.forEach((app, arrIndex) => {
                            if(app.provider === row.provider && app.account_id === appliesRow.account_id && app.region === appliesRow.region && app.cluster_name === appliesRow.cluster_name) {
                                appliesRow.queues = app.queues ? app.queues : []
                                appliesRow.users = app.users ? app.users : []
                            }
                        })
                        appliesTo.push(appliesRow)
                    }
                })
                row.applies_to = appliesTo
            })

            dispatch(setHpcPropsDetails('createInputs', obj))
        }
        validateFunction()
    }

    // useEffect(() => {
    //     if(state['budgetAmount_'+state.selectedProvider+'_'+state.selectedAppliesTo]) {
    //         let obj = {}
    //         obj.selectedProvider = state.selectedProvider
    //         obj.selectedClusters = state['selectedClusters_'+state.selectedProvider+'_'+state.selectedAppliesTo]
    //         obj.budget = state['budgetAmount_'+state.selectedProvider+'_'+state.selectedAppliesTo]
    //         dispatch(setHpcPropsDetails('selectedAplliesTo', obj))
    //     } else {
    //         dispatch(setHpcPropsDetails('selectedAplliesTo', {}))
    //     }
    // }, [state.selectedAppliesTo, dispatch, state])

	const handleChildClick = (event, type, dropdownType, section) => {	
		event.stopPropagation();
		let clickedChild = []
		if(type === 'child') {
            if(state.clickedChild && state.clickedChild.length) {
                state.clickedChild.forEach(row => {
                    setState(prevState => ({ ...prevState, [row]: false }))
                })
            }
            clickedChild.push(section)
            setState(prevState => ({ ...prevState, [section]: dropdownType === "singleDropDown" && state[section] ? false : true, clickedChild }))
        } else if(type === 'parent') {
            if(state.clickedChild && state.clickedChild.length) {
                state.clickedChild.forEach(row => {
                    setState(prevState => ({ ...prevState, [row]: false }))
                })
            }
            
            setState(prevState => ({ ...prevState, clickedChild }))
        }
	}

    useEffect(() => {
        if(onClickSave) {
            if(onClickSave !== 'save' && state.hasError) {
                dispatch(setHpcPropsDetails('onClickSave', 'stopLoading'))
            } else if(onClickSave === 'save') {
                validateFunction()       
            }
        }
    }, [onClickSave, state.hasError, dispatch, validateFunction])    

	useEffect(() => {
		if(state.callSaveFunction) {
            setState(prevState => ({ ...prevState, callSaveFunction: false }))
			let params = createInputs
            params.budget_unit = state.departmentDetails.budget_unit ? state.departmentDetails.budget_unit : 'gbp'

			let messageType = 'danger'
			let message = 'Error in saving Budget details'
			dispatch(insertBudgetDetails(params))
				.then((response) => {
					if(response && !response.error) {
						if(response.status) {
							messageType = 'success'
							message = 'Budget details saved successfully'	
						}
                        if(response.message) {
                            message = response.message
                        }
					}

					CommonNotification.addNotification({
						//title: "Wonderful!",
						message: message,
						type: messageType,
						insert: "top",
						container: "top-center",
						// animationIn: ["animate__animated", "animate__fadeIn"],
						// animationOut: ["animate__animated", "animate__fadeOut"],
						dismiss: {
							duration: 5000,
							onScreen: false,
							pauseOnHover: true,
							showIcon: true,
						}
					})

					setState(prevState => ({ ...prevState, onSaveFunction: false }))

					if(messageType === "danger") {
                        dispatch(setHpcPropsDetails('onClickSave', 'stopLoading'))
					} else {
                        dispatch(setHpcPropsDetails('onClickSave', false))
                        navigate(URL_PATH.HPC_BUDGET); // Redirect to the specified path
                    }
				})
		}
	}, [state.callSaveFunction, dispatch, createInputs, navigate, state.departmentDetails])

	useEffect(() => {
		if(state.callUpdateFunction) {
            setState(prevState => ({ ...prevState, callUpdateFunction: false }))
			let params = createInputs
            params.budget_id = state.budget_id
            params.budget_unit = state.departmentDetails.budget_unit ? state.departmentDetails.budget_unit : 'gbp'
			let messageType = 'danger'
			let message = 'Error in saving Budget details'
			dispatch(updateBudgetDetails(params))
				.then((response) => {
					if(response && !response.error) {
                        if(response.status) {
                            messageType = 'success'
                            message = 'Budget details updated successfully'
                        }
                    }
                    if(response.message) {
                        message = response.message
                    }

					CommonNotification.addNotification({
						//title: "Wonderful!",
						message: message,
						type: messageType,
						insert: "top",
						container: "top-center",
						// animationIn: ["animate__animated", "animate__fadeIn"],
						// animationOut: ["animate__animated", "animate__fadeOut"],
						dismiss: {
							duration: 5000,
							onScreen: false,
							pauseOnHover: true,
							showIcon: true,
						}
					})
                    
					setState(prevState => ({ ...prevState, onSaveFunction: false }))

					if(messageType === "danger") {
                        dispatch(setHpcPropsDetails('onClickSave', 'stopLoading'))
					} else {
                        dispatch(setHpcPropsDetails('onClickSave', false))
                        navigate(URL_PATH.HPC_BUDGET); // Redirect to the specified path
                    }
				})
		}
	}, [state.callUpdateFunction, dispatch, createInputs, state.budget_id, navigate, state.departmentDetails])
	
	return (
        <div className='w-full' onClick={(event) => handleChildClick(event, 'parent')}>
            <div className='flex flex-wrap text-white mx-0 p-2 bg-muted'>
                <p className='text-lg w-full text-white mb-2'>{state.pageType !== "edit" ?  "Create" : "Edit"} Budget</p>
                <div className="p-2 rounded-md bg-dark2 w-full">
                    <div className="flex flex-wrap mb-3">
                        <div className="py-1 lg:w-1/3 md:w-1/2 w-full">
                            <div className='flex justify-between'>
                                <p className="b-block mb-0 text-lightGray">Name</p>
                                {state.hasError && !state.budget_name ?
                                    <span className='text-danger self-center'>required</span>
                                : null}
                            </div>
                            <p className="mb-0 text-white">
                                {state.pageType === 'edit' ?
                                    state.budget_name
                                :
                                    <input
                                        type="text"
                                        placeholder="Enter budget name"
                                        className={`border border-lightGray rounded-md bg-transparent px-2 py-1 text-white w-full`}
                                        // maxlength="128"
                                        value={state.budget_name ? state.budget_name : ""}
                                        onChange={e => {
                                            setState(prevState => ({ ...prevState, budget_name: e.target.value }))
                                            handleInputChange("budget_name", e.target.value)
                                        }}
                                    />
                                }
                            </p>
                        </div>
                        <div className="py-1 lg:w-1/3 md:w-1/2 w-full lg:pl-3 md:pl-3 pl-0">
                            <div className='flex justify-between'>
                                <p className="b-block mb-0 text-lightGray">Year</p>
                                {state.hasError && !state.financial_year ?
                                    <span className='text-danger self-center'>required</span>
                                : null}
                            </div>
                            <div className={`${state.pageType === 'View' ? 'disabled' : ''}`} onClick={(event) => {
                                event.preventDefault();
                                handleChildClick(event, 'child', 'singleDropDown', "showYear")
                            }}>
                                <p className={`flex border border-lightGray rounded-md bg-transparent px-2 py-1 justify-between mb-2 truncate ${state.financial_year ? 'text-white' : 'text-lightGray'}`}>
                                    {state.financial_year ? state.financial_year : 'Select'}
                                    <Icon icon="icon-park-solid:down-one" className={`${state.financial_year ? "text-white" : "text-lightGray"} self-center`} width="16" height="16" />
                                </p>
                                {state.pageType !== 'edit' && state.showYear ?
                                    <div className='position-relative'>
                                        <MultiSelectSection 
                                            // fields={["value", "label"]}
                                            className={'w-70'}
                                            removeTop={true}    
                                            options={state.yearOptions}
                                            selectedValues={state.financial_year ? state.financial_year : ''}
                                            callbackMultiSelect={(value) => {
                                                setState(prevState => ({ ...prevState, financial_year: value }))
                                                handleInputChange("financial_year", value)
                                            }}
                                            singleSelection={true}
                                            hideSearch={true}
                                            widthClass={'minWidth220'}
                                            removeTopOptions={true}
                                        />
                                    </div>
                                : null}
                            </div>
                        </div>
                    </div>
                    <div className='flex mb-2'>
                        <div className="w-full">
                            <p className="b-block mb-0 text-lightGray">Description</p>
                            <p className="mb-0 text-white">
                                <textarea 
                                    id="comment" 
                                    name="comment" 
                                    rows="4" 
                                    readOnly={state.pageType === 'View' ? true : false}
                                    className={`w-full p-2 mb-4 bg-transparent border border-lightGray rounded-md text-white`}
                                    onChange={e => {
                                        if(state.pageType !== 'View') {
                                            setState(prevState => ({ ...prevState, description: e.target.value }))
                                            handleInputChange("description", e.target.value)
                                        }
                                    }}
                                    value={state.description ? state.description : ""}
                                    placeholder='Enter Policy Description'
                                />
                            </p>
                        </div>
                    </div>
                    {state.financial_year ?
                        <div className='bg-darkGray p-3 mb-2'>
                            <div className="flex flex-wrap mb-3">
                                <div className="py-1 lg:w-1/4 md:w-1/2 w-full">
                                    <div className='flex justify-between'>
                                        <p className="b-block mb-0 text-lightGray">Department</p>
                                        {/* {state.hasError && !state.department ?
                                            <span className='text-danger self-center'>required</span>
                                        : null} */}
                                    </div>
                                    <div className={`${state.pageType === 'View' ? 'disabled' : ''}`} onClick={(event) => {
                                        event.preventDefault();
                                        handleChildClick(event, 'child', 'singleDropDown', "showDepartment")
                                    }}>
                                        <p className={`flex border border-lightGray rounded-md bg-transparent px-2 py-1 justify-between mb-2 truncate ${state.department ? 'text-white' : 'text-lightGray'}`}>
                                            {state.department ? state.department : 'Select'}
                                            <Icon icon="icon-park-solid:down-one" className={`${state.department ? "text-white" : "text-lightGray"} self-center`} width="16" height="16" />
                                        </p>
                                        {state.pageType !== 'edit' && state.showDepartment ?
                                            <div className='position-relative'>
                                                <MultiSelectSection 
                                                    className={'w-70'}
                                                    removeTop={true}
                                                    options={state.departmentsList ? state.departmentsList : []}
                                                    selectedValues={state.department ? state.department : []}
                                                    callbackMultiSelect={(value) => {
                                                        if(value !== state.department) {
                                                            setState(prevState => ({
                                                                ...prevState,
                                                                department: value,
                                                                showInitiativeLoading: true,
                                                                departmentDetails: {},
                                                                initiativesList: [],
                                                                projectList: [],
                                                                providerList: [],
                                                                selectedInitiative: '',
                                                                selectedProject: '',
                                                                selectedProjectTags: [],
                                                                selectedProvider: '',
                                                                initializeState: true
                                                            }))
                                                            handleInputChange("department", value)
                                                        }
                                                    }}
                                                    singleSelection={true}
                                                    hideSearch={true}
                                                    widthClass={'minWidth220'}
                                                    removeTopOptions={true}
                                                />
                                            </div>
                                        : null}
                                    </div>
                                </div>
                                {state.departmentDetails && state.departmentDetails.initiatives ?
                                    <React.Fragment>
                                    <div className="py-1 lg:w-1/4 md:w-1/2 w-full md:pl-3">
                                        <div className='flex justify-between'>
                                            <p className="b-block mb-0 text-lightGray">Initiative</p>
                                        </div>
                                        <div className={`${state.pageType === 'View' ? 'disabled' : ''}`} onClick={(event) => {
                                            event.preventDefault();
                                            handleChildClick(event, 'child', 'singleDropDown', "showInitiative")
                                        }}>
                                            <p className={`flex border border-lightGray rounded-md bg-transparent px-2 py-1 justify-between mb-2 truncate ${state.selectedInitiative ? 'text-white' : 'text-lightGray'}`}>
                                                {state.selectedInitiative ? state.selectedInitiative : 'Select'}
                                                <Icon icon="icon-park-solid:down-one" className={`${state.selectedInitiative ? "text-white" : "text-lightGray"} self-center`} width="16" height="16" />
                                            </p>
                                            {state.pageType !== 'edit' && state.initiativesList && state.showInitiative ?
                                                <div className='position-relative'>
                                                    <MultiSelectSection 
                                                        fields={["value", "label"]}
                                                        className={'w-70'}
                                                        removeTop={true}
                                                        options={state.initiativesList ? state.initiativesList : []}
                                                        selectedValues={state.selectedInitiative ? state.selectedInitiative : ''}
                                                        callbackMultiSelect={(value) => {
                                                            if(value !== state.selectedInitiative) {
                                                                setState(prevState => ({ 
                                                                    ...prevState, 
                                                                    selectedInitiative: value,
                                                                    projectList: [],
                                                                    providerList: [],
                                                                    selectedProject: '',
                                                                    selectedProjectTags: [],
                                                                    selectedProvider: '',
                                                                    initializeState: true
                                                                }))
                                                                handleInputChange("initiative", value)
                                                            }
                                                        }}
                                                        singleSelection={true}
                                                        hideSearch={true}
                                                        widthClass={'minWidth220'}
                                                        removeTopOptions={true}
                                                    />
                                                </div>
                                            : null}
                                        </div>
                                    </div>
                                    <div className="py-1 lg:w-1/4 md:w-1/2 w-full lg:pl-3">
                                        <div className='flex justify-between'>
                                            <p className="b-block mb-0 text-lightGray">Project</p>
                                        </div>
                                        <div className={`${state.pageType === 'View' ? 'disabled' : ''}`} onClick={(event) => {
                                            event.preventDefault();
                                            handleChildClick(event, 'child', 'singleDropDown', "showProject")
                                        }}>
                                            <p className={`flex border border-lightGray rounded-md bg-transparent px-2 py-1 justify-between mb-2 truncate ${state.selectedProject ? 'text-white' : 'text-lightGray'}`}>
                                                {state.selectedProject ? state.selectedProject : 'Select'}
                                                <Icon icon="icon-park-solid:down-one" className={`${state.selectedProject ? "text-white" : "text-lightGray"} self-center`} width="16" height="16" />
                                            </p>
                                            {state.pageType !== 'edit' && state.projectList && state.showProject ?
                                                <div className='position-relative'>
                                                    <MultiSelectSection 
                                                        fields={["value", "label"]}
                                                        className={'w-70'}
                                                        removeTop={true}
                                                        options={state.projectList ? state.projectList : []}
                                                        selectedValues={state.selectedProject ? state.selectedProject : ''}
                                                        callbackMultiSelect={(value) => {
                                                            if(value !== state.selectedProject) {
                                                                setState(prevState => ({ 
                                                                    ...prevState, 
                                                                    selectedProject: value,
                                                                    selectedAppliesTo: 0
                                                                }))
                                                                handleInputChange("project", value)
                                                            }
                                                        }}
                                                        singleSelection={true}
                                                        hideSearch={true}
                                                        widthClass={'minWidth220'}
                                                        removeTopOptions={true}
                                                    />
                                                </div>
                                            : null}
                                        </div>
                                    </div>
                                    <div className="py-1 lg:w-1/4 md:w-1/2 w-full md:pl-3">
                                        <div className='flex justify-between'>
                                            <p className="b-block mb-0 text-lightGray">Provider</p>
                                        </div>
                                        <div className={`${state.pageType === 'View' ? 'disabled' : ''}`} onClick={(event) => {
                                            event.preventDefault();
                                            handleChildClick(event, 'child', 'singleDropDown', "showProvider")
                                        }}>
                                            <p className={`flex border border-lightGray rounded-md bg-transparent px-2 py-1 justify-between mb-2 truncate ${state.selectedProvider ? 'text-white' : 'text-lightGray'}`}>
                                                {state.selectedProvider ? capitalizeAllLetter(state.selectedProvider) : 'Select'}
                                                <Icon icon="icon-park-solid:down-one" className={`${state.selectedProvider ? "text-white" : "text-lightGray"} self-center`} width="16" height="16" />
                                            </p>
                                            {state.providerList && state.showProvider ?
                                                <div className='position-relative'>
                                                    <MultiSelectSection 
                                                        fields={["value", "label"]}
                                                        className={'w-70'}
                                                        removeTop={true}
                                                        options={state.providerList ? state.providerList : []}
                                                        selectedValues={state.selectedProvider ? state.selectedProvider : ''}
                                                        callbackMultiSelect={(value) => {
                                                            if(value !== state.selectedProvider) {
                                                                setState(prevState => ({ ...prevState, selectedProvider: value }))
                                                                onClickAppliesTo('provider', value, value, 0) 
                                                            }
                                                        }}
                                                        singleSelection={true}
                                                        hideSearch={true}
                                                        makeLabelUpperCase={'capitalizeAllLetter'}
                                                        widthClass={'minWidth220'}
                                                        removeTopOptions={true}
                                                    />
                                                </div>
                                            : null}
                                        </div>
                                    </div>
                                    </React.Fragment>
                                : null}
                            </div>
                            {state.alreadyExistMessage ? 
                                <p className='mb-1 text-danger'>{state.alreadyExistMessage}</p>
                            : null}
                            {state.providerDetails && state.providerDetails.length ?
                                <div className='bg-dark3 py-2 px-3 rounded-md w-full my-2'>
                                    <div className='flex'>
                                        <p className="b-block text-left mb-2 text-md self-center">Cloud Provider</p>
                                        {/* <p className="b-block text-left mb-2 f12 self-center text-info ml-3 cursor-pointer" onClick={() => setState(prevState => ({ ...prevState, showProviderSplitUp: false }))}>Hide Provider details</p> */}
                                    </div>
                                    {state.providerDetails.map((pro, proIndex) => {
                                        return(
                                            <div key={'dep_'+proIndex} className={`flex justify-between my-1 ${proIndex ? 'pt-2 border-t border-mediumDarkGray' : ''}`}>
                                                <div className='self-center w-10'>
                                                    <div className="py-1 pl-3">
                                                        <span className="ml-2 text-md text-center mb-0 text-info">{pro.provider ? capitalizeAllLetter(pro.provider) : ''}</span>
                                                    </div>
                                                </div>
                                                <div className='self-center w-30'>
                                                    <div className="flex justify-between py-1 pl-3">
                                                        <p className="b-block mb-0 text-md">Committed</p>
                                                        <span className="ml-2 text-md mb-0 text-warning">{pro.comitted ? thousandSeparator(pro.comitted) : 0}</span>
                                                    </div>
                                                </div>
                                                <div className='self-center w-30'>
                                                    <div className="flex justify-between py-1 pl-3">
                                                        <p className="b-block mb-0 text-md">Allocated</p>
                                                        <span className="ml-2 text-md mb-0 text-warning">{pro.allocated ? thousandSeparator(pro.allocated) : 0}</span>
                                                    </div>
                                                </div>
                                                <div className='self-center w-30'>
                                                    <div className="flex justify-between py-1 pl-3">
                                                        <p className="b-block mb-0 text-md">Balance</p>
                                                        <span className={`ml-2 text-md mb-0 ${pro.balance > 0 ? 'text-info' : 'text-danger'}`}>{pro.balance ? thousandSeparator(pro.balance) : 0}</span>
                                                    </div>
                                                </div>
                                            </div>  
                                        )
                                    })}
                                </div>
                            : null}
                            {state.providerError && state.providerError.length ?
                                state.providerError.map((err, errIndex) => {
                                    return(
                                        err ?
                                            <p className='mb-1 text-danger' key={'err_'+errIndex}>{err}</p>
                                        : null
                                    )
                                })
                            : null}

                            {state['period_array_'+state.selectedProvider] && state.selectedInitiative && state.selectedProject && state.selectedProvider ?
                                <React.Fragment>
                                <h5 className='mb-0 text-plum text-lg cursor-pointer flex flex-wrap'>Applies To</h5>                                    
                                {state["period_array_"+state.selectedProvider].map((arrIndex, aIndex) => {
                                    return(
                                        <React.Fragment>
                                        <div className='bg-black rounded-md p-3 mb-1' key={'prd_'+arrIndex} >
                                            <div className='flex justify-between cursor-pointer' 
                                                onClick={() => {
                                                    setState(prevState => ({ ...prevState, selectedAppliesTo: state.selectedAppliesTo !== arrIndex ? arrIndex : ''}))
                                                    onClickAppliesTo('', '', state.selectedProvider, arrIndex)
                                                }}
                                            >
                                                <React.Fragment>
                                                    {state.selectedAppliesTo === arrIndex ?
                                                        <Icon icon="zondicons:minus-outline" className='mr-1 self-center cursor-pointer' width={16} height={16} />
                                                    :
                                                        <Icon icon="tabler:circle-plus" className='mr-1 self-center cursor-pointer' width={20} height={20} />
                                                    }

                                                    {state.selectedAppliesTo !==  arrIndex ?
                                                        <React.Fragment>
                                                        {state['selectedAccount_'+state.selectedProvider+'_'+arrIndex] ?
                                                            <span className='ml-1 text-lightGray'>Account <span className='text-white'>{state['selectedAccount_'+state.selectedProvider+'_'+arrIndex]}</span></span>
                                                        : null}
                                                        {state['selectedRegion_'+state.selectedProvider+'_'+arrIndex] ?
                                                            <span className='ml-1 text-lightGray'>Region <span className='text-white'>{state['selectedRegion_'+state.selectedProvider+'_'+arrIndex]}</span></span>
                                                        : null}
                                                        {state['selectedClusters_'+state.selectedProvider+'_'+arrIndex] ?
                                                            <span className='ml-1 text-lightGray'>Cluster <span className='text-white'>{state['selectedClusters_'+state.selectedProvider+'_'+arrIndex]}</span></span>
                                                        : null}
                                                        </React.Fragment>
                                                    : null}
                                                </React.Fragment>
                                            </div>
                                            {state.selectedAppliesTo ===  arrIndex ?
                                                <React.Fragment>
                                                <div className={`flex flex-wrap ${arrIndex ? "mt-1 pt-1":  ""}`}>
                                                    <div className="py-1 lg:w-1/4 md:w-1/2 w-full lg:pl-3 md:pl-3">
                                                        <div className='flex justify-between'>
                                                            <p className="b-block mb-0">Account</p>
                                                            {/* {state.hasError && !state['selectedAccount_'+arrIndex] ?
                                                                <span className='text-danger self-center'>required</span>
                                                            : null} */}
                                                        </div>
                                                        <div onClick={(event) => {
                                                            event.preventDefault();
                                                            handleChildClick(event, 'child', 'singleDropDown', "show_account_"+state.selectedProvider+'_'+arrIndex)
                                                        }}>
                                                            <p className={`flex border border-lightGray rounded-md bg-transparent px-2 py-1 justify-between mb-2 truncate ${state['selectedAccount_'+state.selectedProvider+'_'+arrIndex] ? 'text-white' : 'text-lightGray'}`}>
                                                                {state['selectedAccount_'+state.selectedProvider+'_'+arrIndex] ? state['selectedAccount_'+state.selectedProvider+'_'+arrIndex] : 'Select'}
                                                                <Icon icon="icon-park-solid:down-one" className={`${state['selectedAccount_'+state.selectedProvider+'_'+arrIndex] ? "text-white" : "text-lightGray"} self-center`} width="16" height="16" />
                                                            </p>
                                                            
                                                            {(state.pageType !== 'edit' || !state["initital_array_"+state.selectedProvider].includes(arrIndex)) && state["show_account_"+state.selectedProvider+'_'+arrIndex] ?
                                                                <div className='position-relative'>
                                                                    <MultiSelectSection 
                                                                        fields={["billingUnit", "billingUnit"]}
                                                                        className={'w-70'}
                                                                        removeTop={true}
                                                                        options={state['accounts_'+state.selectedProvider] ? state['accounts_'+state.selectedProvider] : []}
                                                                        selectedValues={state['selectedAccount_'+state.selectedProvider+'_'+arrIndex] ? state['selectedAccount_'+state.selectedProvider+'_'+arrIndex] : []}
                                                                        callbackMultiSelect={(value) => {
                                                                            setState(prevState => ({ ...prevState, ['selectedAccount_'+state.selectedProvider+'_'+arrIndex]: value }))
                                                                            getClusterDetailsFunction(value, state['selectedRegion_'+state.selectedProvider+'_'+arrIndex], state.selectedProvider, arrIndex)
                                                                            setTimeout(() => {
                                                                                handleAppliesToInputChange("account_id", value, arrIndex)
                                                                            }, 1000);
                                                                        }}
                                                                        singleSelection={true}
                                                                        hideSearch={false}
                                                                        widthClass={'minWidth220'}
                                                                        removeTopOptions={true}
                                                                    />
                                                                </div>
                                                            : null}
                                                        </div>
                                                    </div>
                                                    {state['selectedAccount_'+state.selectedProvider+'_'+arrIndex] ?
                                                        <React.Fragment>
                                                        <div className="py-1 lg:w-1/4 md:w-1/2 w-full lg:pl-3 md:pl-3 pl-0 lg:mt-0 md:mt-0 mt-2">
                                                            <p className="b-block mb-0">Region</p>
                                                            <div onClick={(event) => {
                                                                event.preventDefault();
                                                                handleChildClick(event, 'child', 'singleDropDown', "show_region_"+state.selectedProvider+'_'+arrIndex)
                                                            }}>
                                                                <p className={`flex border border-lightGray rounded-md bg-transparent px-2 py-1 justify-between mb-2 truncate ${state['selectedRegion_'+state.selectedProvider+'_'+arrIndex] ? 'text-white' : 'text-lightGray'}`}>
                                                                    {state['selectedRegion_'+state.selectedProvider+'_'+arrIndex] ? getRegionName(state['selectedRegion_'+state.selectedProvider+'_'+arrIndex], state['regions_'+state.selectedProvider]) : 'Select'}
                                                                    <Icon icon="icon-park-solid:down-one" className={`${state['selectedRegion_'+state.selectedProvider+'_'+arrIndex] ? "text-white" : "text-lightGray"} self-center`} width="16" height="16" />
                                                                </p>
                                                                {(state.pageType !== 'edit' || !state["initital_array_"+state.selectedProvider].includes(arrIndex)) && state["show_region_"+state.selectedProvider+'_'+arrIndex] ?
                                                                    <div className='position-relative'>
                                                                        <MultiSelectSection 
                                                                            fields={["region", "name"]}
                                                                            className={'w-70'}
                                                                            removeTop={true}
                                                                            options={state['regions_'+state.selectedProvider] ? state['regions_'+state.selectedProvider] : []}
                                                                            selectedValues={state['selectedRegion_'+state.selectedProvider+'_'+arrIndex] ? state['selectedRegion_'+state.selectedProvider+'_'+arrIndex] : []}
                                                                            callbackMultiSelect={(value) => {
                                                                                setState(prevState => ({ ...prevState, ['selectedRegion_'+state.selectedProvider+'_'+arrIndex]: value }))
                                                                                getClusterDetailsFunction(state['selectedAccount_'+state.selectedProvider+'_'+arrIndex], value, state.selectedProvider, arrIndex)
                                                                                setTimeout(() => {
                                                                                    handleAppliesToInputChange("region", value, arrIndex)
                                                                                }, 1000);
                                                                            }}
                                                                            singleSelection={true}
                                                                            hideSearch={false}
                                                                            widthClass={'minWidth220'}
                                                                            removeTopOptions={true}
                                                                        />
                                                                    </div>
                                                                : null}
                                                            </div>
                                                        </div>
                                                        <div className='py-1 lg:w-1/4 md:w-1/2 w-full lg:pl-3 md:pl-3 pl-0 lg:mt-0 mt-2'>
                                                            <div className="flex w-100">
                                                                <p className="b-block mb-0">Cluster</p>
                                                            </div>
                                                            <div onClick={(event) => {
                                                                event.preventDefault();
                                                                handleChildClick(event, 'child', 'singleDropDown', "show_cluster_"+state.selectedProvider+'_'+arrIndex)
                                                            }}>
                                                                <p className={`flex border border-lightGray rounded-md bg-transparent px-2 py-1 justify-between mb-2 truncate ${state['selectedClusters_'+state.selectedProvider+'_'+arrIndex] ? 'text-white' : 'text-lightGray'}`}>
                                                                    {state['selectedClusters_'+state.selectedProvider+'_'+arrIndex] ? state['selectedClusters_'+state.selectedProvider+'_'+arrIndex] : 'Select'}
                                                                    <Icon icon="icon-park-solid:down-one" className={`${state['selectedClusters_'+state.selectedProvider+'_'+arrIndex] ? "text-white" : "text-lightGray"} self-center`} width="16" height="16" />
                                                                </p>

                                                                {(state.pageType !== 'edit' || !state["initital_array_"+state.selectedProvider].includes(arrIndex)) && state["show_cluster_"+state.selectedProvider+'_'+arrIndex] ?
                                                                    <div className='position-relative'>
                                                                        <MultiSelectSection 
                                                                            removeTop={true}
                                                                            options={state['clusters_'+state.selectedProvider+'_'+arrIndex]}
                                                                            selectedValues={state['selectedClusters_'+state.selectedProvider+'_'+arrIndex]}
                                                                            callbackMultiSelect={(value) => {
                                                                                setState(prevState => ({ ...prevState, ['selectedClusters_'+state.selectedProvider+'_'+arrIndex]: value, ["budgetAmount_"+state.selectedProvider+'_'+arrIndex]: 0 }))
                                                                                setTimeout(() => {
                                                                                    handleAppliesToInputChange("cluster_name", value, arrIndex)
                                                                                    handleAppliesToInputChange("budget", 0, arrIndex)
                                                                                }, 1000);
                                                                            }}
                                                                            singleSelection={true}
                                                                            widthClass={'minWidth220'}
                                                                            removeTopOptions={true}
                                                                        />
                                                                    </div>
                                                                : null}
                                                            </div>
                                                        </div>
                                                        {state['selectedClusters_'+state.selectedProvider+'_'+arrIndex] ?
                                                            <div className="py-1 lg:w-1/4 md:w-1/2 w-full lg:pl-3 md:pl-3 pl-0 lg:mt-0 mt-2">
                                                                <div className='flex justify-between'>
                                                                    <p className="b-block mb-0 text-lightGray">Budget Amount</p>
                                                                </div>
                                                                <p className="mb-0 text-white text-md ">
                                                                    <input
                                                                        type="text"
                                                                        placeholder="Enter amount"
                                                                        className={`border border-lightGray rounded-md bg-transparent px-2 py-1 text-white w-full`}
                                                                        // maxlength="128"
                                                                        value={state["budgetAmount_"+state.selectedProvider+'_'+arrIndex] ? state["budgetAmount_"+state.selectedProvider+'_'+arrIndex] : ""}
                                                                        onChange={e => {
                                                                            setState(prevState => ({ ...prevState, ["budgetAmount_"+state.selectedProvider+'_'+arrIndex]: onlyNumeric(e.target.value)}))
                                                                            onClickAppliesTo('budgetAmount', e.target.value, state.selectedProvider, arrIndex)

                                                                            setTimeout(() => {
                                                                                handleAppliesToInputChange("budget", e.target.value, arrIndex)
                                                                            }, 2000);
                                                                        }}
                                                                    />
                                                                </p>
                                                            </div>
                                                        : null}
                                                        </React.Fragment>
                                                    : null}
                                                </div>
                                                </React.Fragment>
                                            : null}
                                        </div>
                                        
                                        <div className='flex mb-2'>
                                            {state.pageType !== 'view' ?
                                            <React.Fragment>
                                            {state["period_array_"+state.selectedProvider].length === aIndex+1 && state['selectedAccount_'+state.selectedProvider+'_'+arrIndex] && state['selectedClusters_'+state.selectedProvider+'_'+arrIndex] ?
                                                <span className={`self-center mr-3 cursor-pointer text-info`} onClick={() => addFilterArray(state.selectedProvider)} >Add
                                                </span>
                                            : null}
                                            {state["period_array_"+state.selectedProvider].length > 1 ?
                                                <span className={`self-center mr-2 cursor-pointer text-danger`} onClick={() => removeFilterArray(state.selectedProvider, aIndex, arrIndex)} >Remove
                                                </span>
                                            : null}
                                            </React.Fragment>
                                            : null}
                                        </div>
                                        </React.Fragment>
                                    )
                                })}
                                {state.hasError && state["isDuplicate_"+state.selectedProvider] ?
                                    <span className='m-4 text-danger self-center'>Duplicate in applies to</span>
                                : null}
                                </React.Fragment>
                            : null}
                        </div>
                    : null}               
                </div>
			</div>
            <div id="popup-modal" tabIndex="-1" className={`flex ${state.confirmAction ? '' : 'hidden'} overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 h-[calc(100%-1rem)] max-h-full`}>
                <div className="relative p-4 w-full max-w-md max-h-full">
                    <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
                        <button type="button" className="absolute top-3 end-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white" data-modal-hide="popup-modal" onClick={() => setState(prevstate => ({ ...prevstate, confirmAction: false, showDeleteLoading: false, deleteWorksatationDetails: {} }))}>
                            <svg className="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
                            </svg>
                            <span className="sr-only">Close modal</span>
                        </button>
                        <div className="p-4 md:p-5 text-center">
                            <svg className="mx-auto mb-4 text-gray-400 w-12 h-12 dark:text-gray-200" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M10 11V6m0 8h.01M19 10a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"/>
                            </svg>
                            <h3 className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">Selected combination {state.department}&nbsp;{state.selectedInitiative}&nbsp;{state.selectedProject} already exist do you need to continue edit"?</h3>
                            <button data-modal-hide="popup-modal" type="button" className="py-2 px-2 rounded-md text-white bg-green mr-2" 
                                onClick={() => {
                                    fetchEditData()
                                }}
                            >
                                Yes, I'm sure
                            </button>
                            <button data-modal-hide="popup-modal" type="button" className="py-2 px-2 rounded-md text-white bg-red" 
                                onClick={() => { 
                                    setState(prevState => ({ ...prevState, confirmAction: false }))
                                }}
                            >
                            No, cancel
                            </button>
                        </div>
                    </div>
                </div>
            </div>
		</div>
	)
}

export default LeftSection