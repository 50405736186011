import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {Icon} from "@iconify/react";
import { getImageFilters } from '../../../actions/ImagebuilderAction'
import { setCommonPropsDetails } from '../../../actions/commonActionNew'
import MultiSelectSection from '../../common/MultiSelectSection';

const ImagebuilderFilterSection = (props) => {
    const clickOut = useRef()
    const [state, setState] = useState({
        selectedDuration: 'currentMonth',
        onLoad: true,        
    })

    const dispatch = useDispatch(); // Create a dispatch function
    const clickedonParent = useSelector(state => state?.filters?.commonPropsDetails?.clickedonParent || false);
    
    useEffect(() => {
        let params = {}
        dispatch(getImageFilters(params))
            .then((response) => {
                if(response) {
                    setState(prevState => ({ 
                        ...prevState, 
                        imageFIlters: response,
                        filterDetails: response,
                        selectedProvider: response && response.provider && response.provider.length ? response.provider[0] : "",
                        callSearch: true,
                        onChangeFilter: true
                    }))
                }
            })
    }, [dispatch])

    // useEffect(() => {
    //     if(state.onChangeImageType) {
    //         setState(prevState => ({ ...prevState, onChangeImageType: false }))
    //         let params = {};
    //         params.provider = state.selectedProvider;
    //         if(state.selectedAccount && state.selectedAccount.length) {
    //             params.account_id = state.selectedAccount
    //         }
    //         if(state.selectedRegion && state.selectedRegion.length) {
    //             params.region = state.selectedRegion
    //         }
    //         params.image_type = state.selectedImageType
    //         params.aggregate_by = ['os']
    //         dispatch(getImageFilters(params))
    //             .then((response) => {
    //                 if(response) {
    //                     setState(prevState => ({ ...prevState, osDetails: response, imageLoader: false }))
    //                 }
    //             })
    //     }
    // }, [state.onChangeImageType, state.selectedProvider, state.selectedAccount, state.selectedRegion, state.selectedImageType,  dispatch]);

    useEffect(() => {
        if(state.onChangeFilter)  {
            setState(prevState => ({ ...prevState, onChangeFilter: false }))

            let aggregate_by = ['image_name']
            if(state.selectedProvider !== 'docker') {
                aggregate_by = ['account_id', 'region', 'image_type']
            }
            let params = {};
            params.provider = state.selectedProvider
            if(state.selectedAccount && state.selectedAccount.length) {
                params.account_id = state.selectedAccount
            }
            if(state.selectedRegion && state.selectedRegion.length) {
                params.region = state.selectedRegion
            }
            if(state.selectedProvider !== 'docker') {
                params.image_type = state.selectedImageType
            }
            if(state.selectedImageType) {
                aggregate_by.push('os')
            }
            params.aggregate_by = aggregate_by
            dispatch(getImageFilters(params))
                .then((response) => {
                    if(response) {
                        setState(prevState => ({ ...prevState, filterDetails: response, imageLoader: false }))
                    }
                })
        }
    }, [state.onChangeFilter, state.selectedProvider, state.selectedAccount, state.selectedRegion, state.selectedImageType,  dispatch]);

    const onReset = () => {
        setState(prevState => ({ 
            ...prevState, 
            selectedProvider: state.filterDetails && state.filterDetails.provider && state.filterDetails.provider.length ? state.filterDetails.provider[0] : "", 
            selectedAccount: [], 
            selectedRegion: [], 
            selectedImageType: '', 
            selectedImage: '',
            callSearch: true 
        }));
    }

    useEffect(() => {
        if(state.callSearch && state.selectedProvider) {
            setState(prevState => ({ ...prevState, callSearch: false }))
            let obj = {
                selectedProvider: state.selectedProvider,
                selectedAccount: state.selectedAccount ? state.selectedAccount : [],
                selectedRegion: state.selectedRegion ? state.selectedRegion : [],
                selectedImageType: state.selectedImageType ? state.selectedImageType : '',
                selectedImage: state.selectedImage ? state.selectedImage : ''
            }
            props.selectedFilters(obj)
        }
    }, [state.callSearch, props, state.selectedAccount, state.selectedProvider, state.selectedRegion, state.selectedImageType, state.selectedImage])

    // useEffect(() => {
    //     if(props.changeManage && props.screen === 'QOS') {
    //         setState(prevState => ({ ...prevState, changeManage: props.changeManage, callSearch: true }))
    //     }
    // }, [props.changeManage, props.screen, state.changeManage])

	const handleChildClick = (event, type, dropdownType, section) => {
        if (clickOut.current && !clickOut.current.contains(event.target)) {
            setState(prevState => ({ ...prevState, showDateRangePicker: false }));
		}
        
		event.stopPropagation();
		let clickedChild = []
		if(type === 'child') {
            if(state.clickedChild && state.clickedChild.length) {
                state.clickedChild.forEach(row => {
                    setState(prevState => ({ ...prevState, [row]: false }))
                })
            }
            clickedChild.push(section)
            setState(prevState => ({ ...prevState, [section]: dropdownType === "singleDropDown" && state[section] ? false : true, clickedChild }))
        } else if(type === 'parent') {
            if(state.clickedChild && state.clickedChild.length) {
                state.clickedChild.forEach(row => {
                    setState(prevState => ({ ...prevState, [row]: false }))
                })
            }
            
            setState(prevState => ({ ...prevState, clickedChild }))
        }
	}

    useEffect(() => {
        if(clickedonParent) {
            if(state.clickedChild && state.clickedChild.length) {
                state.clickedChild.forEach(row => {
                    setState(prevState => ({ ...prevState, [row]: false }))
                })
            }
            setState(prevState => ({ ...prevState, clickedChild: [] }))

            dispatch(setCommonPropsDetails('clickedonParent', false))
        }

    }, [clickedonParent, dispatch, state.clickedChild])

    return (
        <div onClick={(event) => handleChildClick(event, 'parent')} className='flex flex-wrap justify-end gap-2'>
            {props.filters && props.filters.includes('provider') ?
                <div className={`bg-black5 h-fit p-2 text-white flex flex-col justify-between rounded-md cursor-pointer `} style={{minWidth: '150px', maxWidth: '150px'}} 
                    onClick={(event) => {
                        if(!state.isProviderOpen) {
                            event.preventDefault();
                            handleChildClick(event, 'child', 'singleDropDown', "isProviderOpen")
                        }
                    }}
                >
                    <p className="text-xs text-white font-semibold">Provider</p>
                    <p className={`flex justify-between text-sm font-medium mt-3`}>
                        <span className={`truncate text-sm font-bold ${state.selectedProvider ? "text-white" : "text-lightGray"}`}>{state.selectedProvider ? state.selectedProvider.toUpperCase() : 'Select'}</span>
                        <Icon icon="icon-park-solid:down-one" className={`${state.selectedProvider ? "text-white" : "text-lightGray"}`} width="16" height="16" />                        
                    </p>
                    {state.isProviderOpen ?
                        <div className='relative'>
                        <MultiSelectSection 
                            options={state.imageFIlters && state.imageFIlters.provider ? state.imageFIlters.provider : []}
                            selectedValues={state.selectedProvider ? state.selectedProvider : ''}
                            callbackMultiSelect={(value) => {
                                if(!value || typeof(value) === 'string') {
                                    setState(prevState => ({ ...prevState, selectedProvider: value, selectedAccount: [], selectedRegion: [], selectedImageType: '', selectedImage: '', onChangeFilter: true }))
                                } else {
                                    value.preventDefault()
                                    handleChildClick(value, "search", 'singleDropDown', "")
                                }
                            }}
                            widthClass={'minWidth220'}
                            singleSelection={true}
                            removeTopOptions={true}
                            makeLabelUpperCase={'capitalizeAllLetter'}
                        />
                        </div>
                    : null}
                </div>
            : null}
            {props.filters && props.filters.includes('account') && state.selectedProvider !== 'docker' ?
                <div className={`bg-black5 w-full h-fit p-2 text-white flex flex-col justify-between rounded-md cursor-pointer lg:right-auto right-0`} style={{minWidth: '120px', maxWidth: '120px'}}
                    onClick={(event) => {
                        event.preventDefault();
                        handleChildClick(event, 'child', 'muliselectDropDown', "isAccountOpen")
                    }}
                >
                    <p className="text-xs text-white font-semibold">Account</p>
                    <p className={`flex justify-between text-sm font-medium mt-3`}>
                        <span className={`truncate text-sm font-bold ${state.selectedAccount && state.selectedAccount.length ? "text-white" : "text-lightGray"}`}>{state.selectedAccount && state.selectedAccount.length ? state.selectedAccount.length+' Selected' : 'Select'}</span>
                        <Icon icon="icon-park-solid:down-one" className={`${state.selectedAccount && state.selectedAccount.length ? "text-white" : "text-lightGray"}`} width="16" height="16" />                        
                    </p>    
                    {state.isAccountOpen ?
                        <div className='relative'>
                            <MultiSelectSection 
                                options={state.filterDetails && state.filterDetails.account_id ? state.filterDetails.account_id : []}
                                widthClass={'minWidth220'}
                                selectedValues={state.selectedAccount ? state.selectedAccount : []}
                                callbackMultiSelect={(value) => setState(prevState => ({ ...prevState, selectedAccount: value, selectedRegion: [], selectedImageType: '', selectedImage: '', onChangeFilter: true }))}
                                removeTopOptions={true}
                                className={'lg:right-auto md:right-0'}
                            />
                        </div>
                    : null}
                </div>
            : null}
            {props.filters && props.filters.includes('region') && state.selectedProvider !== 'docker' ?
                <div className={`bg-black5 w-full h-fit p-2 text-white flex flex-col justify-between rounded-md cursor-pointer lg:right-auto right-0`} style={{minWidth: '120px', maxWidth: '120px'}}
                    onClick={(event) => {
                        event.preventDefault();
                        handleChildClick(event, 'child', 'muliselectDropDown', "isRegionOpen")
                    }}
                >
                    <p className="text-xs text-white font-semibold">Region</p>
                    <p className={`flex justify-between text-sm font-medium mt-3`}>
                        <span className={`truncate text-sm font-bold ${state.selectedRegion && state.selectedRegion.length ? "text-white" : "text-lightGray"}`}>{state.selectedRegion && state.selectedRegion.length ? state.selectedRegion.length+' Selected' : 'Select'}</span>
                        <Icon icon="icon-park-solid:down-one" className={`${state.selectedRegion && state.selectedRegion.length ? "text-white" : "text-lightGray"}`} width="16" height="16" />                        
                    </p>
                    {state.isRegionOpen ?
                        <div className='relative'>
                            <MultiSelectSection 
                                options={state.filterDetails && state.filterDetails.region ? state.filterDetails.region : []}
                                widthClass={'minWidth220'}
                                selectedValues={state.selectedRegion ? state.selectedRegion : []}
                                callbackMultiSelect={(value) => setState(prevState => ({ ...prevState, selectedRegion: value, selectedImageType: '', selectedImage: '', onChangeFilter: true }))}
                                removeTopOptions={true}
                                className={'lg:right-auto md:right-0'}
                            />
                        </div>
                    : null}
                </div>
            : null}

            {props.filters && props.filters.includes('type') && state.selectedProvider !== 'docker' ?
                <div className={`bg-black5 w-full h-fit p-2 text-white flex flex-col justify-between rounded-md cursor-pointer`} style={{minWidth: '120px', maxWidth: '120px'}} 
                    onClick={(event) => {
                        if(!state.isimageBuilderTypeOpen) {
                            event.preventDefault();
                            handleChildClick(event, 'child', 'singleDropDown', "isimageBuilderTypeOpen")
                        }
                }}>
                    <p className="text-xs text-white font-semibold">Type</p>
                    <p className={`flex justify-between text-sm font-medium mt-3`}>
                        <span className={`truncate text-sm font-bold ${state.selectedImageType ? "text-white" : "text-lightGray"}`}>{state.selectedImageType ? state.selectedImageType : 'Select'}</span>
                        <Icon icon="icon-park-solid:down-one" className={`${state.selectedImageType ? "text-white" : "text-lightGray"}`} width="16" height="16" />                        
                    </p>
                    {state.isimageBuilderTypeOpen ?
                        <div className='relative'>
                            <MultiSelectSection
                                options={state.filterDetails && state.filterDetails.image_type ? state.filterDetails.image_type : []}
                                selectedValues={state.selectedImageType ? state.selectedImageType : ''}
                                callbackMultiSelect={(value) => {
                                    if(!value || typeof(value) === 'string') {
                                        setState(prevState => ({ ...prevState, selectedImageType: value, selectedImage: '', imageLoader: true, onChangeFilter: true }))
                                    } else {
                                        value.preventDefault()
                                        handleChildClick(value, "search", 'singleDropDown', "")
                                    }
                                }}
                                singleSelection={true}
                                removeTopOptions={true}
                                widthClass={'minWidth220'}
                                className={'lg:right-auto md:right-0'}
                            />
                        </div>
                    : null}
                </div>
            : null}

            {state.selectedImageType || state.selectedProvider === 'docker' ?
                <div className={`bg-black5 w-full h-fit p-2 text-white flex flex-col justify-between rounded-md cursor-pointer`} style={{minWidth: '120px', maxWidth: '120px'}} 
                    onClick={(event) => {
                        if(!state.isImageOpen) {
                            event.preventDefault();
                            handleChildClick(event, 'child', 'singleDropDown', "isImageOpen")
                        }
                }}>
                    <p className="text-xs text-white font-semibold">{state.selectedProvider !== 'docker' ? 'OS' : 'Image'}</p>
                    
                    <p className={`flex justify-between text-sm font-medium mt-3`}>
                        {state.imageLoader ? 
                            <svg className="animate-spin h-5 w-5 mr-3 text-blue-500" fill="currentColor" viewBox="0 0 24 24">
                                <circle cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="2" fill="none" />
                                <path d="M4 12a8 8 0 0112-6.9" />
                            </svg>
                        :
                            <span className={`truncate text-sm font-bold ${state.selectedImage ? "text-white" : "text-lightGray"}`}>{state.selectedImage ? state.selectedImage : 'Select'}</span>
                        }
                        <Icon icon="icon-park-solid:down-one" className={`${state.selectedRegion && state.selectedRegion.length ? "text-white" : "text-lightGray"}`} width="16" height="16" />                        
                    </p>
                    {state.isImageOpen && !state.imageLoader ?
                        <div className='relative'>
                            <MultiSelectSection
                                options={
                                    state.selectedProvider === 'docker' ?
                                        state.filterDetails && state.filterDetails.image_name ? state.filterDetails.image_name : []
                                    :
                                        state.filterDetails && state.filterDetails.os ? state.filterDetails.os : []

                                }
                                selectedValues={state.selectedImage ? state.selectedImage : ''}
                                callbackMultiSelect={(value) => {
                                    if(!value || typeof(value) === 'string') {
                                        setState(prevState => ({ ...prevState, selectedImage: value }))
                                    } else {
                                        value.preventDefault()
                                        handleChildClick(value, "search", 'singleDropDown', "")
                                    }
                                }}
                                singleSelection={true}
                                removeTopOptions={true}
                                widthClass={'minWidth220'}
                                className={'lg:right-auto md:right-0'}
                            />
                        </div>
                    : null}
                </div>
            : null}

            <div className='self-center mr-3'>
                <div className="flex space-x-0 justify-center" role="group">
                    <button type="button" className="bg-info hover:bg-blue-700 text-white border-r-2 font-bold px-2 py-1 rounded-l inline-flex items-center"
                        onClick={() => setState(prevState => ({ ...prevState, callSearch: true }))}
                    >
                        <span className='text-white'>Search</span>
                    </button>
                    <button type="button" className="bg-info hover:bg-red-700 text-white font-bold px-2 py-1 rounded-r inline-flex items-center"
                        onClick={() => onReset()}
                    >
                        <Icon icon="mdi:reload" className="text-white" width="18" height="18" />
                    </button>
                </div>
            </div>
        </div>
    );
};

export default ImagebuilderFilterSection;