/* eslint-disable no-mixed-spaces-and-tabs */
/*************************************************
 * collider
 * @exports
 * @file Evetns.js
 * @author Prakash // on 03/09/2024
 * @copyright © 2024 collider. All rights reserved.
 *************************************************/
import React, { useEffect, useState} from 'react';
import { useDispatch } from 'react-redux'; // Import useDispatch hook to dispatch actions
import { listStackEvents } from '../../../actions/CatalogAction';
import { momentConvertionUtcToLocalTime } from '../../../utils/utility'
import Search from '../../common/SearchWithHiddenInput'
import {Icon} from "@iconify/react";

const Events = ({ selectedClusterDetails }) => {
    const [state, setState] = useState({
        showEventsLoading: true,
        startRecord: 0, 
        currentPage: 1,
        totalPages: 1,
        perPage: 100,
    })

    const dispatch = useDispatch(); 

    useEffect(() => {
        let params = {}
        params.provider = selectedClusterDetails && selectedClusterDetails.provider
        params.account_id = selectedClusterDetails && selectedClusterDetails.account_id
        params.region = selectedClusterDetails && selectedClusterDetails.region
        params.cluster_name = selectedClusterDetails && selectedClusterDetails.cluster_name
        // Fetch job metrics data here using params and update the state
        if(params.cluster_name) {
            dispatch(listStackEvents(params))
                .then((response) => {
                    if(response) {
                        let results = response.results ? response.results : []

                        let totalPages = 1
                        if(results.length > state.perPage) {
                            totalPages = Math.ceil(results.length / state.perPage)
                        }
                        setState(prevState => ({ ...prevState, listEvents: results, filteredListEvents: results, showEventsLoading: false, totalPages }));
                    }
                })
        } else {
            setState(prevState => ({ ...prevState, listEvents: [], filteredListEvents: [], showEventsLoading: false  }))
        }
    }, [dispatch, selectedClusterDetails, state.perPage])

	const navigatePage = (action, currentPage) => {
        // Update startRecord and currentPage based on action
        let startRecord = state.startRecord
        if(action === 'next' && currentPage !== state.totalPages) {
            startRecord = startRecord + state.perPage;
            setState(prevState => ({ ...prevState, currentPage: currentPage+ 1 }));
        } else if(action === 'previous' && currentPage !== 0) {
            setState(prevState => ({ ...prevState, currentPage: currentPage - 1 }));
            startRecord = startRecord - state.perPage;
        } else if(action === 'start' && currentPage !== 0) {
            setState(prevState => ({ ...prevState, currentPage: 1 }));
            startRecord = 0;
        } else if(action === 'end' && currentPage !== 0) {
            setState(prevState => ({ ...prevState, currentPage: state.totalPages }));
            startRecord = (state.totalPages - 1) * state.perPage;
        }
        setState(prevState => ({ ...prevState, startRecord }))
    }

    return (
        <div className='m-2 flex-grow'>
            {state.showEventsLoading ?
                <div className='flex justify-center m-4'>
                    <svg className="animate-spin h-5 w-5 mr-3 text-white" fill="currentColor" viewBox="0 0 24 24">
                        <circle cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="2" fill="none" />
                        <path d="M4 12a8 8 0 0112-6.9" />
                    </svg>
                </div>
            : state.listEvents && state.listEvents.length ?
                <React.Fragment>
                    <div className="flex flex-wrap mb-2 justify-between">
                        <div className="w-4/6">
                            <p className="text-sm m-0 self-center pt-1">Showing {state.filteredListEvents && state.filteredListEvents.length} of total {state.listEvents && state.listEvents.length} event(s)</p>
                        </div>
                        <div className="justify-end w-2/6">
                            {state.listEvents && state.listEvents.length ?
                                <Search
                                    data={state.listEvents ? state.listEvents : []}
                                    topClassName={'text-black bg-transparent border border-mediumDarkGray rounded-md'}
                                    searchClassName={'px-2'}
                                    searchIconColor={'text-white '}
                                    searchPlaceHolder={'Search....'}
                                    className={'bg-transparent text-white'}
                                    widthHeight={25}
                                    filteredData={(filteredListEvents) => {
                                        let totalPages = 1
                                        if(filteredListEvents.length > state.perPage) {
                                            totalPages = Math.ceil(filteredListEvents.length / state.perPage)
                                        }
                                        setState(prevState => ({ ...prevState, filteredListEvents, totalPages }))
                                    }}
                                />
                            : null}
                            {state.listEvents && state.listEvents.length > state.perPage ?
                                <div className='flex justify-end text-white w-full mt-2'>
                                    <span className='mx-3'>Page <strong>{state.currentPage} of {Math.ceil(state.listEvents.length / state.perPage)}</strong> </span>
                                    <div className="pagination text-sm">
                                        <button><Icon icon="system-uicons:push-left" width="20" height="20" className={`ml-2 cursor-pointer ${state.currentPage === 1 ? 'pointer-events-none text-lightGray' : 'text-info'}`} onClick={() => navigatePage('start', state.currentPage)} /></button> 
                                        <button><Icon icon="prime:angle-left" width="20" height="20" className={`ml-2 cursor-pointer ${state.currentPage === 1 ? 'pointer-events-none text-lightGray' : 'text-info'}`} onClick={() => navigatePage('previous', state.currentPage)} /></button> 
                                        {/* <span>
                                            Go to
                                            <input
                                                type='number'
                                                value={state.pageNumber || state.pageNumber === "" ? state.pageNumber : state.currentUpcoming}
                                                onChange={e => {
                                                    const page = e.target.value ? Number(e.target.value) : ""
                                                    gotoPage(page)
                                                }}
                                                className="ml-1 inputClass"
                                            />
                                        </span> */}
                                        <button><Icon icon="prime:angle-right" width="20" height="20" className={`ml-3 cursor-pointer ${state.currentPage === state.totalPages ? 'pointer-events-none text-lightGray' : 'text-info'}`} onClick={() => navigatePage('next', state.currentPage)}/></button> 
                                        <button><Icon icon="system-uicons:push-right" width="20" height="20" className={`ml-2 cursor-pointer ${state.currentPage === state.totalPages ? 'pointer-events-none text-lightGray' : 'text-info'}`} onClick={() => navigatePage('end', state.currentPage)} /></button>
                                    </div>
                                </div>
                            : null}
                        </div>
                    </div>
                    {state.filteredListEvents && state.filteredListEvents.length ?
                        state.filteredListEvents.slice(state.startRecord, state.startRecord + state.perPage).map((item, i) => {
                            return(
                                <div key={"fil_"+i} className={`px-2 mb-2 pb-1 ${!i ? "" : "border-t border-mediumDarkGray"}`}> 
                                    <div className="flex cursor-pointer text-wrap">
                                        <p className="mb-0">
                                            <span className="mb-0 text-white text-sm font-semibold">{item.timestamp ? momentConvertionUtcToLocalTime(item.timestamp, 'DD MMM YYYY HH:mm') : ""} </span>
                                            {item.resource_type ?
                                                <span className={`mb-0 text-sm ml-1`}>{item.resource_type}</span>
                                            : null}
                                            {item.resource_status ?
                                                <span className={`mb-0 text-sm text-info ml-1`}>{item.resource_status}</span>
                                            : null}
                                            {item.user_id ?
                                                <span className={`mb-0 text-sm ml-1`}>{item.user_id}</span>
                                            : null}
                                            {item.resource_status_reason ?
                                                <span className="mb-0 ml-1 text-sm text-white font-semibold">{item.resource_status_reason}</span>
                                            : null}
                                        </p>
                                    </div>
                                </div>
                            )
                        })
                    :
                        <div className='flex justify-center m-4 text-white'>
                            There are no events on this criteria. Please try adjusting your filter.
                        </div>
                    }
                </React.Fragment>
            :
                <div className='flex justify-center m-4 text-white'>
                    Therer are no events on this criteria.
                </div>
            }
        </div>
    )
}

export default Events