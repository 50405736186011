/* eslint-disable no-mixed-spaces-and-tabs */
/*************************************************
 * Collider
 * @exports
 * @file CreateMapping.js
 * @author Prakash // on 25/01/2024
 * @copyright © 2024 Collider. All rights reserved.
 *************************************************/
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux'; // Import useDispatch hook to dispatch actions
import { useLocation, useNavigate } from 'react-router-dom'
import { setHpcPropsDetails } from '../../../actions/Collider/HpcAction';
import { createDepartment, editDepartment } from '../../../actions/cost/BudgetAction'
import { URL_PATH } from '../../../config/urlPath';
import CreateMappingLeft from './CreateMappingLeft';
import CreateMappingRight from './CreateMappingRight';
import { Store as CommonNotification } from 'react-notifications-component';

const CreateMapping = () => {

    const [state, setState] = useState({})
    
    const location = useLocation();
    const receivedData = location.state;
    
    const navigate = useNavigate();
    const dispatch = useDispatch(); // Create a dispatch function
    const createMappingInputs = useSelector(state => state?.hpc?.hpcPropsDetails?.createMappingInputs || false);

    useEffect(() => {
        const handleBeforeUnload = (event) => {
        const message = 'Are you sure you want to leave the form? Your changes may not be saved.';
        event.returnValue = message; // Standard for most browsers
        return message; // For some older browsers
        };

        const handlePopstate = () => {
        const message = 'Are you sure you want to leave the form? Your changes may not be saved.';
        if (window.confirm(message)) {
            // Handle user confirmation, e.g., navigate away or perform other actions
        }
        };

        const handleBeforeRedirect = (location) => {
        const message = 'Are you sure you want to leave the form? Your changes may not be saved.';
        if (window.confirm(message)) {
            // Handle user confirmation, e.g., navigate away or perform other actions
            navigate(location.pathname); // Redirect manually after confirmation
        } else {
            // Prevent the redirect if the user cancels
            return false;
        }
        };

        window.addEventListener('beforeunload', handleBeforeUnload);
        window.addEventListener('popstate', handlePopstate);

        // Use window.onbeforeunload to handle redirects
        const unblock = () => {};
        window.onbeforeunload = () => handleBeforeRedirect({ pathname: '/' });

        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
            window.removeEventListener('popstate', handlePopstate);
            window.onbeforeunload = null; // Remove the onbeforeunload handler when the component is unmounted
            unblock(); // Remove the history.block when the component is unmounted
        };
    }, [navigate]); // Re-run the effect when navigate changes
    
    useEffect(() => {
		if(receivedData && Object.entries(receivedData).length) {
            setState(prevState => ({ 
                ...prevState,
				selectedData: receivedData.selectedData ? receivedData.selectedData : '',
				pageType: receivedData.pageType ? receivedData.pageType : '',
                formatEditStructure: true
			}))
            dispatch(setHpcPropsDetails('createMappingInputs', receivedData.selectedData ? receivedData.selectedData : {}))
		} else {
			setState(prevState => ({ 
				...prevState,
				pageType: 'Create',
			}))
            dispatch(setHpcPropsDetails('createMappingInputs', {}))
		}
	}, [receivedData, dispatch])

    const navigateTo = useCallback(() => {
        navigate(URL_PATH.ADMIN_DEPARTMENT_MAPPING); // Redirect to the specified path
    }, [navigate])

    useEffect(() => {
        if(state.callOnSaveFunction) {
            setState(prevState => ({ ...prevState, callOnSaveFunction: false }))
            let hasError = false
            
            if(!createMappingInputs.department) {
                hasError = true
            }

            let initiatives = createMappingInputs.initiatives ? createMappingInputs.initiatives : []
            if(initiatives.length) {
                createMappingInputs.initiatives.forEach(init => {
                    if(!init.initiative) {
                        hasError = true
                    }
                    let projects = init.projects ? init.projects : []
                    if(!hasError && !projects.length) {
                        hasError = true
                    } else {
                        projects.forEach(proj => {
                            if(!proj.project) {
                                hasError = true
                            }
                            if(proj.accounts) {
                                proj.accounts.forEach(acc => {
                                    if(!acc.cloudProviderId || !acc.billingUnit) {
                                        hasError = true
                                    }
                                })
                            }
                        })
                    }
                })
            } else {
                hasError = true
            }

            setState(prevState => ({ ...prevState, hasError }))
            if(!hasError) {
                let params = createMappingInputs ? createMappingInputs : {}
                if(state.pageType === 'Edit') {
                    dispatch(editDepartment(params))
                        .then((response) => {
                            if(response) {
                                let messageType = 'danger'
                                let message = response.message ? response.message : 'Error in updating department'
                                if(response.status) {
                                    messageType = 'success'
                                    message = response.message ? response.message : 'Department updated successfully'
                                    navigateTo()
                                }
                                setState(prevState => ({ ...prevState, onSaveLoading: false }))
    
                                CommonNotification.addNotification({
                                    //title: 'Wonderful!',
                                    message: message,
                                    type: messageType,
                                    insert: 'top',
                                    container: 'top-center',
                                    // animationIn: ['animate__animated', 'animate__fadeIn'],
                                    // animationOut: ['animate__animated', 'animate__fadeOut'],
                                    dismiss: {
                                        duration: 5000,
                                        onScreen: false,
                                        pauseOnHover: true,
                                        showIcon: true,
                                    }
                                });
                            }
                        })
    
                } else {
                    dispatch(createDepartment(params))
                        .then((response) => {
                            if(response) {
                                let messageType = 'danger'
                                let message = response.message ? response.message : 'Error in creating department'
                                if(response.status) {
                                    messageType = 'success'
                                    message = response.message ? response.message : 'Department created successfully'
                                    navigateTo()
                                }
                                setState(prevState => ({ ...prevState, onSaveLoading: false }))
        
                                CommonNotification.addNotification({
                                    //title: 'Wonderful!',
                                    message: message,
                                    type: messageType,
                                    insert: 'top',
                                    container: 'top-center',
                                    // animationIn: ['animate__animated', 'animate__fadeIn'],
                                    // animationOut: ['animate__animated', 'animate__fadeOut'],
                                    dismiss: {
                                        duration: 5000,
                                        onScreen: false,
                                        pauseOnHover: true,
                                        showIcon: true,
                                    }
                                });                                
                            }
                        })
                }
            } else {
                setState(prevState => ({ ...prevState, onSaveLoading: false }))
            }
        }
    }, [state, createMappingInputs, dispatch, navigateTo])

    return (
        <div className={`flex flex-wrap m-2`}>
            <div className='flex w-full text-white'>
                <h5 className='mb-1'>Create Department</h5>                
            </div>
            <div className='flex flex-wrap mb-5 w-full item-stretch'>
                <div className={`lg:w-1/2 w-full p-0 flex flex-col flex-grow`}>
                    <CreateMappingLeft 
                        receivedData={receivedData}
                    />
                </div>
                <div className={`lg:w-1/2 w-full lg:pl-3 flex flex-col flex-grow`}>
                    <CreateMappingRight 
                        receivedData={receivedData}
                    />
                </div>
            </div>
            <div className='flex fixed bottom-4 right-4 justify-end bg-transparent'>
                {state.onSaveLoading ?
                    <button className={`btn bg-info text-lg px-2 py-1 rounded-md mr-2`}>
                        <svg className="animate-spin h-5 w-5 mr-2 text-white" fill="currentColor" viewBox="0 0 24 24">
                            <circle cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="2" fill="none" />
                            <path d="M4 12a8 8 0 0112-6.9" />
                        </svg>
                        {state.pageType !== "Edit" ?  "Saving"  : "Updating"}
                    </button>
                :
                    <React.Fragment>
                         {state.hasError ?
                            <p className='text-center mr-5 self-center mb-0 text-danger'>Please fill mandiatory fields</p>
                        : null}
                        <button className={`btn bg-info text-lg px-2 py-1 rounded-md mr-2 text-white`} onClick={() => {
                            setState(prevState => ({ ...prevState, onSaveLoading: true, callOnSaveFunction: true }))
                        }}>
                            {state.pageType !== "edit" ?  "Save"  : "Update"}
                        </button>
                    </React.Fragment>
                
                }
                <button className={`btn bg-lightGray px-2 py-1 text-lg rounded-md text-white ${state.onSaveLoading ? "disbaled" : ""}`} onClick={() => navigateTo()}>Cancel</button>
            </div>
        </div>
    )
}

export default CreateMapping