import React, { useRef } from 'react';
import { useDispatch } from 'react-redux'; // Import useDispatch hook to dispatch actions
import FileList from "./file-list";
import FileFilter from './file-filter';
import { setCommonPropsDetails } from '../../../actions/commonActionNew'

export default function Files() {
	const filterRef = useRef();
	const clickOutside = useRef();

	const dispatch = useDispatch(); // Create a dispatch function

	const handleClickOutside = (event) => {
        
		if (clickOutside.current && !clickOutside.current.contains(event.target)) {
			dispatch(setCommonPropsDetails('clickedonParent', true))
		}
	}
	return (
		<div className="file-browser" onClick={(event) => handleClickOutside(event)}>
			<div className="flex justify-between">
				<div className="lg:1/5 w-full pl-2 self-center">
					<h6 className="text-white m-0">File Manager</h6>
				</div>
				<div className='lg:4/5 w-full'>
					<div className="flex justify-end">
						<div ref={filterRef}>
							<FileFilter 
								onFilterChange={(cluster) => {
									// setSelectedCluster(cluster)
									// setState(prevState => ({
									// ...prevState,
									// selectedClusters: cluster
									// }))
								}}
							/>
						</div>
					</div>
				</div>
			</div>
			<div className="col-xl-12 col-lg-12 col-md-12 col-12">
				<div className="row" ref={clickOutside}>
					<FileList />
				</div>
			</div>
		</div>
	);
}