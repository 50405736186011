/* eslint-disable react/jsx-curly-spacing */
/* eslint-disable no-mixed-spaces-and-tabs */
/*************************************************
 * Collider
 * @exports
 * @file ManageBudget.js
 * @author Prakash // on 08/01/2024
 * @copyright © 2024 Collider. All rights reserved.
 **************************************************/
import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux'; // Import useDispatch hook to dispatch actions
import { useNavigate } from 'react-router-dom'
import { Link } from 'react-router-dom';
import { listBudgets, getCloudProviderBudget, getCurrencyConversionDetails } from '../../../actions/cost/BudgetAction'
import { capitalizeAllLetter, capitalizeTheFirstLetterOfEachWord, currentLocaltime, momentConvertionUtcToLocalTime, momentDateFormat, thousandSeparator } from '../../../utils/utility'
import Search from '../../common/SearchWithHiddenInput'

import ResizeableDarkThemeTable from '../../designComponents/Table/ResizeableDarkThemeTable';
import { URL_PATH } from '../../../config/urlPath';
import { CSVLink } from 'react-csv';
import CreateProviderAllcoation from './CreateProviderAllcoation';
import {Icon} from "@iconify/react";

const ManageBudget = () => {	
	const[state, setState] = useState({
		showLoading: true,
		currencyUnit: 'usd'
	})

    // const providers = useSelector(state => state.filters.providers);
	const navigate = useNavigate();
	const dispatch = useDispatch(); // Create a dispatch function

	useEffect(() => {
		setState(prevState => ({ ...prevState, callSearch: true, callCloudProviderBudget: true }));
		let params={
			convert_from: 'usd', 
			convert_to: 'gbp'
		}
		dispatch(getCurrencyConversionDetails(params))
			.then((response) => {
				setState(prevState => ({ ...prevState, conversionDetails: response.conversion_details ? response.conversion_details : '' }))
			})
	}, [dispatch])

	useEffect(() => {
		if(state.callSearch) {
			let params = {}
	
			dispatch(listBudgets(params))
				.then((response) => {
					if(response) {
						setState(prevState => ({ ...prevState, listBudgets: response, filterListBudgets: response, callSearch: false, showLoading: false }))
					} else {
						setState(prevState => ({ ...prevState, listBudgets: [], filterListBudgets: [], callSearch: false, showLoading: false }))
					}
				})
		}
	}, [state.callSearch, dispatch])

	useEffect(() => {
		if(state.callCloudProviderBudget) {
			setState(prevState => ({ ...prevState, callCloudProviderBudget: false }))
			let params = {
				year: parseInt(momentDateFormat(currentLocaltime(), 'YYYY')),
				currency_unit: state.currencyUnit
			}
			// if(!state.allocationDetails || !state.allocationDetails.length) {
				dispatch(getCloudProviderBudget(params))
					.then((response) => {
						if(response && !response.error) {
							let results = response.results ? response.results : []
							if(results && results.length) {
								results.forEach(row => {
									setState(prevState => ({ ...prevState, [row.cloudProviderId]: row.allocation ? row.allocation : '', showLoading: false }))
								})
							}
							setState(prevState => ({ ...prevState, allocationDetails: results, showLoading: false }))
						} else {
							setState(prevState => ({ ...prevState, allocationDetails: [], showLoading: false }))
						}
					})
			// }
		}
	}, [state.callCloudProviderBudget, dispatch, state.allocationDetails, state.currencyUnit])

	useEffect(() => {
        let dataResult = state.filterListBudgets && state.filterListBudgets.length ? state.filterListBudgets : [];
		if(dataResult.length) {
			let headers = []
			Object.entries(dataResult[0]).forEach(([key, value]) => {
				if(typeof value === 'string') {
					let headerRow = {}
					headerRow.label = capitalizeTheFirstLetterOfEachWord(key)
					headerRow.key = key
					headers.push(headerRow)
				}
			})

			setState(prevState => ({ ...prevState, tableHeaders: headers }))
		}
    }, [state.filterListBudgets])

    const navigateTo = () => {
        navigate(URL_PATH.COST_BUDGET_CREATE); // Redirect to the specified path
    }

	/**
	 * Renders cost comparison Landing page
	*/
	return (
		<div className='px-0'>
			<div className={`${state.showLoading ? '' : 'hidden'}`}>
				<div className='loading-wave'>
					<div className='overlayEqualizerLoader'>
						<div className="loading-bar"></div>
						<div className="loading-bar"></div>
						<div className="loading-bar"></div>
						<div className="loading-bar"></div>
					</div>
				</div>
			</div>
			
			<div className="bg-nero w-full h-fit relative stickyUiChange">
                <div className="grid grid-cols-12 items-center pb-4">
					<div className="xl:col-span-3 col-span-12 self-center xl:mt-4">
						<p className="text-white mx-4 text-xl md:mt-0 mt-4 font-semibold">Manage Budget</p>
						<p className="mx-4 text-white">Manage list of budget</p>
					</div>
					<div className="xl:col-span-9 col-span-12 items-center lg:mt-4 mt-6 lg:ml-4 flex flex-wrap justify-end pr-2">
						<div className='p-2 flex flex-wrap rounded-md justify-center'>
							<div className='w-full flex flex-wrap justify-end'>
							{state.allocationDetails && state.allocationDetails.length ?
								state.allocationDetails.map((row, iRow) => {
									return(
										<div key={row.cloudProviderId}>
											{!iRow ?
												<div className='flex mb-1 text-right text-lightGray'>Currency is in
													<div className="flex mx-1 self-center">
														<input type="radio"
															className=""
															checked={state.currencyUnit === 'usd' ? true : false}  
															onChange={() => setState(prevState => ({ ...prevState, currencyUnit: 'usd', showLoading: true, callCloudProviderBudget: true }))}
															value={state.currencyUnit ? true : false}
														/>
													</div>
													<p className="mb-0 mr-1 text-white self-center text-lightGray" onClick={() => setState(prevState => ({ ...prevState, currencyUnit: 'usd', showLoading: true }))}>USD</p>
													<div className="flex mx-1 self-center">
														<input type="radio"
															className=""
															checked={state.currencyUnit === 'gbp' ? true : false}  
															onChange={() => setState(prevState => ({ ...prevState, currencyUnit: 'gbp', showLoading: true, callCloudProviderBudget: true }))}
															value={state.currencyUnit ? true : false}
														/>
													</div>
													<p className="mb-0 mr-1 text-white self-center text-lightGray" onClick={() => setState(prevState => ({ ...prevState, currencyUnit: 'gbp', showLoading: true }))}>GBP</p>
												</div>
											: iRow === 1 ?
												state.conversionDetails ?
													<div className='flex mb-1 text-right text-lightGray'>{state.conversionDetails}</div>
												: 
													<div className='flex mb-1 text-right text-lightGray'>&nbsp;</div>
											: 
												<div className='flex mb-1 text-right text-lightGray'>&nbsp;</div>
											}
											<div className='bg-dark3 border border-mediumDarkGray rounded-md h-100 px-5 py-3 mr-3 w-fit'>
												<div className='flex flex-wrap justify-center'>
													<p className='text-white text-center mb-1 w-full'>{capitalizeAllLetter(row.cloudProviderId)}&nbsp;Commitment<span className='ml-1 text-lightGray'>({momentDateFormat(currentLocaltime(), 'YYYY')})</span></p>
													<p className='text-center f18 mb-0 text-warning w-full'>{thousandSeparator(row.allocation)} <span className='ml-1 text-lightGray'>{capitalizeAllLetter(state.currencyUnit)}</span></p>
												</div>
											</div>
										</div>
									)
								})	
							: null}
							<button className={`btn bg-green px-2 py-1 rounded-md text-white self-center`} onClick={() => {
								setState(prevState => ({ ...prevState, showProviderBudgetSection: true }))
							}}>Provider&nbsp;Budget</button>
							</div>
						</div>
					</div>				
				</div>
			</div>
			{state.showProviderBudgetSection ?
				<CreateProviderAllcoation 
					closeSidePanel={() => setState(prevState => ({...prevState, showProviderBudgetSection: false }))}
					refreshApi={() => setState(prevState => ({...prevState, allocationDetails: [], callCloudProviderBudget: true }))}
				/>
			: null}
			<div className='m-2'>
				{/* <div className='flex justify-end'>
					<p className='ml-3 mb-1 text-right text-lightGray'>Currency is in usd.</p>
				</div> */}
				<div className='flex justify-between pt-2'>
					<div className=''>
						<p className='mb-0'>
							<small>Showing {state.filterListBudgets && state.filterListBudgets.length} out of total {state.listBudgets && state.listBudgets.length} budget(s), Currency is in usd</small>
							{state.selectedProvider ?
								<React.Fragment>
								<small className='ml-1'>obtained from the</small>
								<small className='ml-1 text-info'>{state.selectedProvider.toUpperCase()}</small>
								<small className='ml-1'>provider</small>
								</React.Fragment>
							: null}
							{state.selectedAccount && state.selectedAccount.length ?
								<React.Fragment>
								<small className='ml-1'>with the account</small>
								<small className='badge badge-outline-info mx-1'>{state.selectedAccount[0]}</small>
								{state.account_id.length > 1 ?
									<small className='ml-1 text-info'>more</small>
								: null}
								</React.Fragment>
							: null}
							{state.selectedRegion && state.selectedRegion.length ?
								<React.Fragment>
								<small className='ml-1'>in the</small>
								<small className='badge badge-outline-info mx-1'>{state.selectedRegion[0]}</small>
								{state.selectedRegion.length > 1 ?
									<small className='ml-1 text-info'>more</small>
								: null}
								<small className='ml-1'>region</small>
								</React.Fragment>
							: null}
							{/* {state.selectedFilters && state.selectedFilters.start_time ?
								<React.Fragment>
									<small className='ml-1'>for the period starting from</small>
									<small className='ml-1 text-info'>{momentConvertionUtcToLocalTime(state.selectedFilters.start_time, 'YYYY-MM-DD HH:mm')}</small> 
									<small className='ml-1'>to</small>
									<small className='ml-1 text-info'>{momentConvertionUtcToLocalTime(state.selectedFilters.end_time, 'YYYY-MM-DD HH:mm')}</small>
									<small className='ml-1'>(local time)</small>
								</React.Fragment>
							: null} */}
						</p>
					</div>
					
					<div className='self-center w-30'>
						<div className='flex justify-end'>
							{state.filterListBudgets && state.filterListBudgets.length ?
								<CSVLink
									data={state.filterListBudgets ? state.filterListBudgets : []} 
									headers={state.tableHeaders ? state.tableHeaders : []}
									filename={'manage-budget.csv'}
									className={'mr-2 self-center'}
									target='_blank'
								>
									<Icon icon="material-symbols:download" className='text-gray5 self-center' width="22" height="22"/>
								</CSVLink>
							: null}
							{state.listBudgets && state.listBudgets.length ?
								<Search
									data={state.listBudgets ? state.listBudgets : []}
									topClassName={'text-black bg-transparent border border-mediumDarkGray rounded-md'}
									searchClassName={'px-2'}
									searchIconColor={'text-white'}
									searchPlaceHolder={'Search....'}
									className={'bg-transparent text-white px-2 py-1'}
									widthHeight={25}
									filteredData={(filterListBudgets) => setState(prevState => ({ ...prevState, filterListBudgets }))}
								/>
							: null}
							<button className={`btn bg-green px-2 py-1 rounded-md text-white ml-2`} onClick={navigateTo}>Create&nbsp;Budget</button>
						</div>                                    
					</div>
				</div>

				<div className='row mx-0'>
					<div className='col-lg-12 col-12 px-0 py-1'>
						{state.filterListBudgets && state.filterListBudgets.length ?
							<ResizeableDarkThemeTable
								columns={[
									{
										Header: 'Date',
										accessor: d => d.modified_at ? "modified_at" : 'created_at',
										Cell: cellInfo => (
										<span>
											{cellInfo.row.original.modified_at ? momentConvertionUtcToLocalTime(cellInfo.row.original.modified_at, 'DD MMM YYYY HH:mm') : (cellInfo.row.original.created_at ? momentConvertionUtcToLocalTime(cellInfo.row.original.created_at, 'DD MMM YYYY HH:mm') : '')}
										</span>
										),
										width: 200
									},
									{
										Header: 'Name',
										accessor: 'budget_name',
										width: 200,
									},
									{
										Header: 'Allocation',
										accessor: 'allocation',
										width: 200,
									},
									{
										Header: 'Department',
										accessor: 'department',
										width: 200,
									},
									{
										Header: 'Year',
										accessor: 'year',
										width: 100,
									},
									{
										Header: 'Actions',
										Cell: cellInfo => (
											<div className="flex justify-between self-center">
												<Link 
													to={URL_PATH.COST_BUDGET_CREATE} 
													state={{
														department: cellInfo.row.original.department,
														year: cellInfo.row.original.year,
														pageType: 'View',

													}}
													className='text-info'>
													View
												</Link>
												<Link 
													to={URL_PATH.COST_BUDGET_CREATE} 
													state={{
														department: cellInfo.row.original.department,
														year: cellInfo.row.original.year,
														pageType: 'Edit',

													}}
													className='text-info ml-3'>
													Edit
												</Link>
											</div>
										),
										width: 100
									},
									{
										Header: 'Description',
										accessor: 'description',
										width: 450
									},
								]}
								data={state.filterListBudgets}
								perPage={10}
								tableHead={'bg-black text-white'}
								tableBodyColor={'bg-dark3 text-white'}
								tableType={'table-dark-hover'}
								perPageClass={`bg-transparent text-lightGray`}
								textColor={'text-white'}
								selectedColor={'bg-transparent'}
								// tableHead={'bg-black text-white'}
								// tableBody={'bg-dark3 text-white'}
								// tableType={'table-dark-hover'}
								// perPageClass={`bg-transparent text-lightGray`}
								// textColor={'text-black'}
								// selectedColor={'bg-transparent'}
								dashboard={state.filterListBudgets && state.filterListBudgets.length ? true : false}
								sortByColumn={''}
								riskTooltip={[0]}
								onClickRow={tableRow => setState(prevState => ({ ...prevState, selectedlistBudgets: tableRow }))}
								// tableSize={'table-sm'}
							/>
						: 
							<div className='flex justify-center m-4'>
								<p>There are no data on this criteria. Please try adjusting your filter.</p>
							</div>
						}
					</div>
				</div>
			</div>
		</div>
	)
}

export default ManageBudget