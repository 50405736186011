/*************************************************
 * Collider
 * @exports
 * @file HpcReportAction.js
 * @author parakash // on 20-02-2023
 * @copyright © 2024 Collider. All rights reserved.
 *************************************************/

import ApiCalls from '../../components/ApiCalls'

import {
    GET_HPC_REPORT_QUERIES,
    GET_HPC_REPORTS
} from '../../config'
// import { ACTION_TYPES } from '../types'

/**
 * Action to get-hpc-report-queries
 * @param {Object} body
 * @param {Function} callback
*/
export const getHpcReportQueries = (params) => {
	return async () => {
		try {

			const url = GET_HPC_REPORT_QUERIES;
			// Assuming your API endpoint for the POST request is 'YOUR_API_ENDPOINT'
			const response = await ApiCalls.post(url, params)

			// You can dispatch an action with the fetched data here
			//   dispatch({ type: 'FETCH_TROUBLESHOOT_RESULTS_SUCCESS', payload: response.data });

			// Return the response data so you can use it in the component
			return response.data;
		} catch (error) {
			return error.response && error.response.data ? error.response.data : error.response && error.response.data ? error.response.data : error.response
		}
	};
}

/**
 * Action to get-hpc-reports
 * @param {Object} body
 * @param {Function} callback
*/
export const getHpcReorts = (params) => {
	return async () => {
		try {

			const url = GET_HPC_REPORTS;
			// Assuming your API endpoint for the POST request is 'YOUR_API_ENDPOINT'
			const response = await ApiCalls.post(url, params)

			// You can dispatch an action with the fetched data here
			//   dispatch({ type: 'FETCH_TROUBLESHOOT_RESULTS_SUCCESS', payload: response.data });

			// Return the response data so you can use it in the component
			return response.data;
		} catch (error) {
			return error.response && error.response.data ? error.response.data : error.response && error.response.data ? error.response.data : error.response
		}
	};
}