/*************************************************
 * Collider
 * @exports
 * @file ApexListLineChart.js
 * @author Prakash // on 12/06/2023
 * @copyright © 2022 Collider. All rights reserved.
 *************************************************/
import React, { useCallback, useEffect, useState } from 'react'
import { momentConvertionUtcToLocalTime } from '../../../../utils/utility'
import Chart from 'react-apexcharts'


const ApexListLineChart = (props) => {    

    const [state, setState] = useState({})

    const drawGraph = useCallback((graphData) => {
        let graphLables = graphData.labels
        let axisColors = []
        let data = []
        let labels = []
        let series = []
        if (Array.isArray(graphData.data)) {
            graphLables.forEach((lab, i) => {
                axisColors.push('#969AA7')
                if(props.xaxisFormat === 'datetime') {
                    labels.push(momentConvertionUtcToLocalTime(lab, 'DD MMM Y HH:mm'))
                } else if(props.xaxisFormat === 'date') {
                    labels.push(momentConvertionUtcToLocalTime(lab, 'DD MMM Y'))
                } else {
                    labels.push(lab)
                }
                
                data.push(graphData.data[i] ? graphData.data[i] : 0)
            })
            let dataRow = {}
            dataRow.name = ""
            dataRow.data = data
            series.push(dataRow)
        } else {
            Object.entries(graphData.data).forEach(([key, value], index) => {
                let data = []
                graphLables.forEach((lab, i) => {
                    axisColors.push('#969AA7')
                    if(props.xaxisFormat === 'datetime') {
                        labels.push(momentConvertionUtcToLocalTime(lab, 'DD MMM Y HH:mm'))
                    } else if(props.xaxisFormat === 'date') {
                        labels.push(momentConvertionUtcToLocalTime(lab, 'DD MMM Y'))
                    } else {
                        labels.push(lab)
                    }
                    data.push(value[i] ? value[i] : 0)
                })
                let dataRow = {}
                dataRow.name = key
                dataRow.data = data
                series.push(dataRow)
            })
        }

        let fillData = {}
        if(props.gradient) {
            fillData = {
                type: "gradient",
                gradient: {
                    // shadeIntensity: 1,
                    // opacityFrom: 0.7,
                    // opacityTo: 0.9,
                    // stops: [0, 90, 100]
                    gradientToColors: [props.gradientColor ? props.gradientColor[0] : '#bee9de'],
                    shadeIntensity: 0,
                    opacityFrom: .3,
                    opacityTo: 1,
                    stops: [50, 90]
                }
            }
        }

        let options = {
            chart: {
                height: 160,
                type: 'line',
                toolbar: {
                    show: false,
                },
                zoom: {
                    enabled: props.zoom,
                },
                sparkline: {
                    enabled: props.sparkline
                },                
                stacked: props.stacked,
            },
            colors: props.colors,
            dataLabels: {
                enabled: false
            },
            stroke: {
                curve: 'smooth',
                width: 2,
            },
            xaxis: {
                show: false,
                categories: labels,
                labels: {
                    show: props.xaxis,
                    style: {
                        fontSize: '9px'
                    },
                },
                axisBorder: {
                    show: props.axisBorder,
                    // color:'#434959',
                    color: '#343947',
                    height: 2,
                    width: '100%',
                    offsetX: 0,
                    offsetY: 0
                },
                axisTicks: {
                    show: false
                },
                tooltip: {
                    enabled: false
                },
            },            
            yaxis: {
                show: props.yaxis,
                axisBorder: {
                    show: false,
                    // color:'#434959',
                    color: '#343947',
                    widht: 2,
                    offsetX: -15,
                    // offsetY: 0
                },
                axisTicks: {
                    show: false
                },
                labels: {
                    show: props.yaxisLabel
                },
            },
            tooltip: {
                enabled: props.tooltip,
                custom: function({series, seriesIndex, dataPointIndex, w}) { 
                    let returnData = ''
                    returnData += '<div class="metricsDetailCurrentTooltip"><div class="apexcharts-tooltip-title f12">'+w.globals.categoryLabels[dataPointIndex]+'</div>'
                    if(w.globals.initialSeries && Object.entries(w.globals.initialSeries).length) {
                        Object.entries(w.globals.initialSeries).forEach(([key, value], index) => {
                            returnData += '<div class="apexcharts-tooltip-series-group apexcharts-active flex"><span class="apexcharts-tooltip-marker" style="background-color:'+ w.globals.colors[index] +'"></span><div class="apexcharts-tooltip-text"><div class="apexcharts-tooltip-y-group"><span class="apexcharts-tooltip-text-label">'+value.name+'</span><span class="apexcharts-tooltip-text-value ml-2">'+value.data[dataPointIndex]+'</span></div></div></div>'

                        })
                    }

                    returnData += '</div>'
                    return returnData

                    // let val  = series[seriesIndex][dataPointIndex];
                    // let labelName =  momentConvertionUtcToLocalTime(w.globals.categoryLabels[dataPointIndex], "HH:mm")
                    // let name = ""
                    // return '<div class="flex arrow_box"> '+ labelName +' <span style="color:'+ w.globals.colors[seriesIndex] +'">' +  capitalizeFirstLetter(name) + '  '+ val + '</span> </div>'
                },
            },
            fill: fillData,
            grid: {
                show: false
            }
        }

        setState(prevState => ({ ...prevState, series, options }))
    }, [props])
    
    useEffect(() => {
        let graphData = props.graphData;
        if(graphData === 'mockData') {
            graphData = {}
            graphData.data = [45,67,34,71,41,93,64,77,36,34,44,10,1,3,91,45,32,3,37,21,65,45,38,59,16,3,66,85,30,62,49]
            graphData.labels = ["2021-05-12 00:00:00", "2021-05-13 00:00:00", "2021-05-14 00:00:00", "2021-05-15 00:00:00", "2021-05-16 00:00:00", "2021-05-17 00:00:00", "2021-05-18 00:00:00", "2021-05-19 00:00:00", "2021-05-20 00:00:00", "2021-05-21 00:00:00", "2021-05-22 00:00:00", "2021-05-23 00:00:00", "2021-05-24 00:00:00", "2021-05-25 00:00:00", "2021-05-26 00:00:00", "2021-05-27 00:00:00", "2021-05-28 00:00:00", "2021-05-29 00:00:00", "2021-05-30 00:00:00", "2021-05-31 00:00:00", "2021-06-01 00:00:00", "2021-06-02 00:00:00", "2021-06-03 00:00:00", "2021-06-04 00:00:00", "2021-06-05 00:00:00", "2021-06-06 00:00:00", "2021-06-07 00:00:00", "2021-06-08 00:00:00", "2021-06-09 00:00:00", "2021-06-10 00:00:00", "2021-06-11 00:00:00"]
        } else if(graphData === 'mockDataDisk') {
            graphData = {}
            graphData.data = [3,37,21,65,45,38,59,16,3,66,85,30,62,49,20,85,5,12,41,93,64,77,36,34,44,10,1,3,91,45,32]
            graphData.labels = ["2021-05-12 00:00:00", "2021-05-13 00:00:00", "2021-05-14 00:00:00", "2021-05-15 00:00:00", "2021-05-16 00:00:00", "2021-05-17 00:00:00", "2021-05-18 00:00:00", "2021-05-19 00:00:00", "2021-05-20 00:00:00", "2021-05-21 00:00:00", "2021-05-22 00:00:00", "2021-05-23 00:00:00", "2021-05-24 00:00:00", "2021-05-25 00:00:00", "2021-05-26 00:00:00", "2021-05-27 00:00:00", "2021-05-28 00:00:00", "2021-05-29 00:00:00", "2021-05-30 00:00:00", "2021-05-31 00:00:00", "2021-06-01 00:00:00", "2021-06-02 00:00:00", "2021-06-03 00:00:00", "2021-06-04 00:00:00", "2021-06-05 00:00:00", "2021-06-06 00:00:00", "2021-06-07 00:00:00", "2021-06-08 00:00:00", "2021-06-09 00:00:00", "2021-06-10 00:00:00", "2021-06-11 00:00:00"]
        }
        if(graphData && Object.entries(graphData).length) {
            drawGraph(graphData)
        }
    }, [props, drawGraph]);

    return (
        state.series ?
            <div className={`${props.className}`}>
                <Chart options={state.options} series={state.series} type="line" height={props.height} width={props.width ? props.width : "100%"}/>
            </div>
        : null
    )
}

export default ApexListLineChart